import * as FilePreviewActionTypes from '../../constants/shared/filePreviewConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    filePreview:
    {
        files: []
    }
}

export default (state = initState, action) => {
    switch (action.type) {
        case FilePreviewActionTypes.TOPIC_FILES_API_VIEW_REQUEST:
            let file =
            {
                downloading: true,
                itemCode: action.itemCode,
                fileName: action.fileName,
                error: null,
                response: null
            }
            return {
                ...state,
                filePreview:
                {
                    files: [...state.filePreview.files, file]
                }
            };
        case FilePreviewActionTypes.TOPIC_FILES_API_VIEW_SUCCESS:
            return {
                ...state,
                filePreview:
                {
                    files: state.filePreview.files.map((item) => (
                        item.itemCode === action.itemCode ? {
                            ...item,
                            downloading: false, //change
                            error: null,
                            response: action.response,
                            contenttype: action.contenttype
                        } : item
                    ))
                }
            };
        case FilePreviewActionTypes.TOPIC_FILES_API_VIEW_FAILURE:
            return {
                ...state,
                filePreview:
                {
                    files: state.filePreview.files.map((item) => (
                        item.itemCode === action.itemCode ? {
                            ...item,
                            error: action.error.response != null ? action.error.response.data : {},
                            downloading: false
                        } : item
                    ))
                }
            };
        case FilePreviewActionTypes.TOPIC_FILES_API_VIEW_CLEAR:
            return {
                ...state,
                filePreview:
                {
                    files: []
                }
            };
        default:
            return state;
    }
}
