import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import IconButton from '@material-ui/core/IconButton';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: '#d3d4d5 !important',
            '& .MuiListItemText-primary': {
                color: theme.palette.common.black,
            },
        },
    },
}))(MenuItem);

const StyledButton = withStyles(theme => ({
    root: {
       padding :'0px',
       '&:hover': {
        backgroundColor: '#d3d4d5 !important',
       }
    },
}))(IconButton);


class breadcrumbcollapsed extends Component {

    state = {
        anchorEl: null,
    }

    showActionMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    // handleClick = event => {
    //     this.setState({ anchorEl: event.currentTarget });
    // };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    render() {
        return (
            <div data-test="breadcrumbcollapsed">
                <StyledButton data-test="styleBtn"
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => this.showActionMenu(event)}>
                    <MoreHorizIcon />
                </StyledButton>
                <StyledMenu data-test='styleMenu'
                    id="customized-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}>
                    {
                        this.props.items.map((item, index) => {
                            return <StyledMenuItem data-test={`styleMenuItem${index}`}
                                key={`Strippeditem-${index}`}
                                onClick={(event) => this.props.onItemSelectInCollapse(event, item)}>
                                <ListItemIcon>
                                    <FolderIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={item.itemName} />
                            </StyledMenuItem>
                        })
                    }
                </StyledMenu>
            </div>
        );
    }
}

export default breadcrumbcollapsed;