import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogContent, Paper, DialogActions, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Fade, Modal, IconButton, Button, Link, Radio, RadioGroup, FormControlLabel, Chip, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ceremonyTypeConstants from '../ceremonyTypeConstants.json';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ESignConfirm from '../eSignConfirm/ESignConfirm';
import { CommonModal } from '../../../../ui';

type ESignProgressprops = {
    selectedItem: any,
    children?: any,
    classes?: any
    ceremonyDetails: any;
    openConfirmationModal:any;
}

export const styles = (theme: any) => ({
    main: {
        fontFamily: theme.homefontfamilyregular,
        lineHeight: '24px'
    },
    content: {
        padding: '16px 0px',
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    subtitle: {
        fontSize: '1.0rem',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        letterSpacing: '0.15px',
        paddingBottom: '16px'
    },
    title: {
        color: '#000000',
        fontSize: '1.65rem',
        fontWeight: 'bold' as 'bold',
        letterSpacing: '0',
        lineHeight: '32.5px'
    },
    commonModalActions: {
        justifyContent: 'center',
        padding: '0px',
        height: '40px'
    },
    primarybtn: {
        textTransform: 'none' as 'none',
        width: '170px'
    },
    documentLinkCancel: {
        fontSize: '1rem',
        paddingLeft: '8px',
        position: 'absolute' as 'absolute',
        left: '2rem'
    },
    btnContainer:
    {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center'
    },
    astyle:
    {
        color: '#444557',
        height: '30px',
        width: '273.24px',
        fontFamily: theme.homefontfamilyregular,
        fontSize: '.875rem',
        letterSpacing: '0.17px',
        lineHeight: '30px',
    },
    footer:
    {
        fontSize: '12px',
        color: '#000000',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    footerContent:
    {
        marginLeft: '8px'
    }
});


class ESignProgress extends Component<ESignProgressprops> {

    state = {
        selectedCeremony: {
            type: null,
            progressText: null,
            linkSentTo: null,
            message: null,
            progressTextBold: null
        },
        isModalOpen: false
    }

    componentDidMount() {
        let selectedCeremony = ceremonyTypeConstants.ceremony.find((ceremony) => ceremony.type === this.props.ceremonyDetails.ceremonyTypeCode)
        this.setState({ selectedCeremony: selectedCeremony })
    }

    render(): JSX.Element {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.main} data-test="ESignProgress">
                    <div className={classes.title}>
                        Electronic consent in progress
                </div>
                    <DialogContent
                        classes={{ root: classes.content }}>
                        <div className={classes.subtitle}>
                            <b>{this.state.selectedCeremony.progressTextBold}</b>{this.state.selectedCeremony.progressText}
                        </div>
                        {/* <div className={classes.subtitle}>
                            {this.state.selectedCeremony.linkSentTo != null && <div>
                                {this.state.selectedCeremony.linkSentTo}<a className={classes.astyle}>Resend</a>
                            </div>
                            }
                            <div className={classes.footer}>
                                <CheckCircleIcon fontSize="small" color="primary" />
                                <div className={classes.footerContent}>
                                    {this.state.selectedCeremony.message}
                                </div>
                            </div>
                        </div> */}
                    </DialogContent>
                    <DialogActions className={classes.commonModalActions}  >
                        <Link id="cancelInvite" data-test="cancelBtn" className={classes.documentLinkCancel} href="#" onClick={() => this.props.openConfirmationModal()}>
                            CLOSE
                    </Link>
                    </DialogActions>
                </div>
            </>
        )

    }
}

export default (withStyles(styles)(ESignProgress));