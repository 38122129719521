import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

type FabButtonProps = {
    id: string;
    onClick: any;
    disabled: any;
    text: any;
    classes: any;
}

export const styles = (theme: any) => ({
    fabButtonActive: {
        marginLeft: theme.spacing(1),
        letterSpacing: 0.25,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,
        backgroundColor: theme.loginbuttonbgcolor,
        borderRadius:'2px',
        minWidth:'140px',
        textTransform: 'none' as 'none',
        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        '&:disabled': {
            backgroundColor: theme.fabbuttonbgcolor,
        }
    }
});

class FabButton extends Component<FabButtonProps> {
    render() {
        const { classes, onClick, disabled, text } = this.props;
        return (
            <Fab data-test='fabButton' variant="extended" color="primary" aria-label="add" className={classes.fabButtonActive}
                onClick={() => onClick()}
                disabled={disabled}>
                {text}
            </Fab>
        )
    }
}


export default withStyles(styles)(FabButton);