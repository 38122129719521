import React, { Component } from 'react';
import GroupDetailProfileCard from './components/groupDetailProfileCard';
import { AppBar, Tabs, Tab, Typography, Box, Paper, IconButton, Hidden } from '@material-ui/core';
import RemoveGroupContent from '../contacts/components/popUpContent/RemoveGroups';
import * as ContactActionTypes from '../../../redux/constants/personalVault/contactsConstants';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { CommonModal } from '../../../shared/ui';
import InviteGroup from '../contacts/components/inviteGroup/InviteGroup'
import Error from '../../../shared/errors/Error';
import { CommonFileItemList, CommonFileActionsBar, CommonFileGridList } from '../../../shared/ui';
import { ReactComponent as GridIcon } from '../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../assets/list-view.svg';
import { fsFileSharing } from '../../../shared/core/utils/paginationDefaults';
import * as GroupDetailActionTypes from '../../../redux/constants/personalVault/groupDetailConstants';
import * as AppStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import { getContactsInGroupAPI } from '../../../shared/core/utils/apiFunctions';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface PaginationType {
    direction: string,
    orderBy: string,
    page: number;
    size: number;
}

type GroupType = {
    createdAt: string,
    modifiedAt: string,
    cgCode: string,
    cgName: string,
    displayName: string,
    status: string,
    contactCount: number
}

type GroupContactType = {
    createdAt: string,
    modifiedAt: string,
    contactCode: string,
    contactFirstName: string,
    contactLastName: string,
    contactEmail: string,
    status: string,
    statusUpdated: string
}

type GroupDetailStateProps = {
    groupData: GroupType[],
    selectedGroupContacts: GroupContactType[],
    groupSharedByMeFiles: any,
    snackbarData: any,
    filesFetching: boolean,
    fileListHeaders: any
}

type GroupDetailDispatchProps = {
    deleteGroup: (groupCode: string) => any,
    getGroupItemsSharedByMe: (groupCode: string|undefined) => any,
    getGroupContacts: (groupCode: string) => any,
    updateSnackbar: (snackbarVal: any) => any,
    selectFileToPreview: (item: any) => any,
    updateFileHeaders: (fileHeaders: any) => any
    
}

type GroupDetailPageProps = RouteComponentProps<any> & {
    components: string[],
}

type GroupDetailProps = GroupDetailPageProps & GroupDetailDispatchProps & GroupDetailStateProps

type GroupDetailPageState = {
    value: number,
    modalOpenState: boolean,
    error: any,
    listView: boolean,
    pagination: PaginationType,
    selectedFiles: any[],
    groupContacts: GroupContactType[],
    initPage: number,
    // snackbarOpenState: boolean,
    // snackbarContent: string
}


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`contact-list-tabpanel-${index}`}
            aria-labelledby={`contact-list-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

class FsGroupsDetail extends Component<GroupDetailProps, GroupDetailPageState> {

    content = {
        fileTableTabOneLabel: 'SHARED BY ME'
    }

    groupCode = window.location.pathname.split('/').pop();
    selectedGroup: GroupType;
    modalContent: any;
    groupContacts: GroupContactType[];

    constructor(props: GroupDetailProps) {
        super(props);
        this.selectedGroup = {
            createdAt: '',
            modifiedAt: '',
            cgCode: '',
            cgName: '',
            displayName: '',
            status: '',
            contactCount: 0
        }
        this.groupContacts = [{
            createdAt: '',
            modifiedAt: '',
            contactCode: '',
            contactFirstName: '',
            contactLastName: '',
            contactEmail: '',
            status: '',
            statusUpdated: ''
        }]
    }

    initState() {
        this.setState({
            value: 0,
            pagination: {
                direction: fsFileSharing.direction,
                orderBy: fsFileSharing.orderBy,
                page: fsFileSharing.page,
                size: fsFileSharing.size
            },
            // sharedByMeFiles: [],
            listView: true,
            selectedFiles: [],
            initPage: 0
        });
    }

    UNSAFE_componentWillMount() {
        this.groupCode = window.location.pathname.split('/').pop();
        this.selectedGroup = this.props.groupData.filter((group: any) => {
            return group.cgCode === this.groupCode;
        })[0];
        this.setState({
            modalOpenState: false
        })
        this.props.getGroupContacts(this.selectedGroup.cgCode);
    }

    componentDidMount() {
        this.initState();
        this.props.getGroupItemsSharedByMe(this.groupCode);

    }

    cancelConfirm = () => {
        this.setState({ modalOpenState: false });
    }

    // getGroupContacts = (grpCode: string) => {
    //     if (grpCode) {
    //         //If the Contact Group exists, read the existing contacts from the group
    //         getContactsInGroupAPI(grpCode).then((result: any) => {
    //             console.log(result.data.content)
    //             this.groupContacts = result.data.content
    //         }).catch((err: any) => {
    //             console.log(err)
    //         });
    //       }
    // }

    onActionComplete = (isSuccess: boolean, snackbarMessage: string) => {
        if (isSuccess) {
            //Should add the snackbar messaging here
            this.setState({ modalOpenState: false });
        }
        else
            this.setState({ modalOpenState: false });
    }

    handleError = (error: any) => {
        this.setState({ error: error })
    }

    handleCloseErrorDisplay = () => {
        this.setState({ error: null })
    }

    removeGroupConfirm = (groupCode: string) => {
        this.setState({ modalOpenState: false }, () => {
            this.props.deleteGroup(groupCode);
        });
        // this.setState({ snackbarOpenState: true });
        // this.setState({ snackbarContent: `Group has been removed.` });
        this.props.updateSnackbar({
            modalOpen: true,
            content: `Group ${this.selectedGroup.cgName} has been removed.`
        })
        this.props.history.push('/personal/contacts');
    }

    deleteGroupOnClick = () => {
        this.setState({ modalOpenState: true })
        this.modalContent =
            <RemoveGroupContent
                cancelConfirm={this.cancelConfirm}
                removeGroupConfirm={() => this.removeGroupConfirm(this.selectedGroup.cgCode)} />
    }

    editGroupOnClick = () => {
        this.setState({ modalOpenState: true });
        this.modalContent =
            <InviteGroup modalWidth="350px" onActionComplete={this.onActionComplete}
                handleError={this.handleError}
                contactGroupId={this.selectedGroup.cgCode}
                contactGroupName={this.selectedGroup.cgName} />
    }

    modalClose = () => {
        this.setState({ modalOpenState: false });
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
    };

    a11yProps = (index: any) => {
        return {
            id: `file-share-${index}`,
            'aria-controls': `file-share-${index}`,
        };
    }

    toggleViews = () => {
        this.setState({ listView: !this.state.listView })
    }

    onFileSelection = (selectedIds: string[]) => {
        var selectedFiles = []
        selectedFiles = this.props.groupSharedByMeFiles.files.filter((I: any) =>
            selectedIds.findIndex((x: string) => x === I.itemCode) > -1
        );
        this.setState({ selectedFiles: selectedFiles });
    }

    onFileOpen = (file: any) => {
        this.props.selectFileToPreview(file);
        this.props.history.push(`/personal/file-detail/${file.itemCode}`);
    }

    onFolderOpen = (items: any) => {
        console.log('Folder Open')
    }

    sortOnClick = (id: string) => {
        console.log('Sort on clicked!', id);
    }

    handleSnackbarClose = () => {
        // this.setState({ snackbarOpenState: false })
        this.props.updateSnackbar({
            modalOpen: false,
            content: ''
        })
    }

    fetchMoreData = () => {
        this.setState(prevState => {
            return {initPage: prevState.initPage + 1}
        })

        let paginationNew = {
            direction: fsFileSharing.direction,
            orderBy: fsFileSharing.orderBy,
            page: this.state.initPage,
            size: fsFileSharing.size
        }
        
        setTimeout(() => {
            console.log('Fetch More Data!')
            // this.props.getMoreItemsSharedWithMe( 'FILE', paginationNew );
        }, 2000)
    }

    onHeaderFiltering = (filteredHeaders: string[]) => {
        this.props.updateFileHeaders(filteredHeaders)
    }

    render() {
        return (
            <>
                <GroupDetailProfileCard
                    group={this.selectedGroup}
                    deleteGroupOnClick={this.deleteGroupOnClick}
                    editGroupOnClick={this.editGroupOnClick}
                    groupContacts={this.props.selectedGroupContacts}
                />
                <CommonModal modalOpen={this.state.modalOpenState}
                    modalClose={this.modalClose}
                    content={this.modalContent}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                   
                    open={this.props.snackbarData.modalOpen}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarClose}
                    message={this.props.snackbarData.content}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                {/* {this.state.error !== null ? (
                    <Error
                        errorDisplayOpen={true}
                        error={this.state.error}
                        handleCloseErrorDisplay={this.handleCloseErrorDisplay}>
                    </Error>
                ) : (
                    null
                )} */}
                <AppBar position="static" className="share-file-tabs">
                    <Tabs className="share-file-tabs"
                        TabIndicatorProps={{ style: { height: '6px' } }}
                        textColor='primary'
                        indicatorColor='primary'
                        value={0}
                        onChange={this.handleChange}
                        aria-label="share-file">
                        <Tab className="tab-btn" label={
                            <span className='tab-label'>{this.content.fileTableTabOneLabel} </span>
                        } {...this.a11yProps(0)} />
                    </Tabs>
                    <div className="share-file-actions">
                        <CommonFileActionsBar
                            selectedFiles={this.state.selectedFiles}
                            isShareEnabled={this.state.value === 0 ? true : false}
                        />
                        <Hidden xsDown>
                            {this.state.listView &&
                                <IconButton aria-label="views" className="iconBtn-wrapper" onClick={this.toggleViews}>
                                    <GridIcon className="iconBtn" />
                                </IconButton>
                            }
                            {!this.state.listView &&
                                <IconButton aria-label="views" className="iconBtn-wrapper" onClick={this.toggleViews}>
                                    <ListViewIcon className="iconBtn" />
                                </IconButton>
                            }
                        </Hidden>
                    </div>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>

                    {this.props.groupSharedByMeFiles.files.length === 0 &&
                        <div>You have not shared any files with this group.</div>
                    }
                    {this.props.groupSharedByMeFiles.files.length > 0 &&
                        <div>
                            <Hidden xsDown>
                                {this.state.listView &&
                                    <CommonFileItemList files={this.props.groupSharedByMeFiles.files}
                                        curSorting={this.props.groupSharedByMeFiles.files}
                                        filesFetching={this.props.filesFetching}
                                        lazyLoading={this.props.groupSharedByMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreData}
                                        onSelection={this.onFileSelection}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        onSorting={this.sortOnClick}
                                        showOwner={false}
                                        showMeAsOnwer={false}
                                        allowChangePermission={true}
                                        showPermission={true}
                                        showUploaded={false}
                                        showFileSize={false}
                                        tableMarginHeight={435} 
                                        fileListHeaders={this.props.fileListHeaders}
                                        onFiltering={this.onHeaderFiltering}
                                    />
                                }
                                {!this.state.listView &&
                                    <CommonFileGridList
                                        files={this.props.groupSharedByMeFiles.files}
                                        filesFetching={this.props.filesFetching}
                                        lazyLoading={this.props.groupSharedByMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreData}
                                        allowChangePermission={true}
                                        onSelection={this.onFileSelection}
                                        tableMarginMaxHeight={480}
                                        tableMarginMinHeight={480}
                                        showPermission={true}
                                        showFileSize={false}
                                        showOnwer={false}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        showMeAsOnwer={true}
                                        showUploaded={false} />
                                }
                            </Hidden>
                            <Hidden smUp>
                                <CommonFileGridList
                                    files={this.props.groupSharedByMeFiles.files}
                                    filesFetching={this.props.filesFetching}
                                    lazyLoading={this.props.groupSharedByMeFiles.lazyLoading}
                                    fetchMoreData={this.fetchMoreData}
                                    allowChangePermission={true}
                                    onSelection={this.onFileSelection}
                                    tableMarginMinHeight={480}
                                        tableMarginMaxHeight={480}
                                    showPermission={true}
                                    showFileSize={false}
                                    showOnwer={false}
                                    onFileOpen={this.onFileOpen}
                                    onFolderOpen={this.onFolderOpen}
                                    showMeAsOnwer={true}
                                    showUploaded={false} />
                            </Hidden>
                        </div>
                    }
                </TabPanel>

            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        groupData: state.contactsReducer.groups.groupsData.content,
        groupSharedByMeFiles: state.fileSharingReducer.groupItemsSharedByMe,
        selectedGroupContacts: state.appUIStateReducer.selectedGroupContacts,
        snackbarData: state.appUIStateReducer.snackbar,
        filesFetching: state.fileSharingReducer.groupItemsSharedByMe.fetching,
        fileListHeaders: state.appUIStateReducer.fileListHeaders
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteGroup: (groupCode: string) => dispatch({
            type: ContactActionTypes.GROUP_API_DELETE_GROUPS_REQUEST,
            groupCode: groupCode
        }),
        getGroupItemsSharedByMe: (groupCode: string|undefined) => dispatch({
            type: GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST,
            groupCode: groupCode
        }),
        getGroupContacts: (groupCode: string) => dispatch({
            type: AppStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_REQUEST,
            groupCode: groupCode
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        selectFileToPreview: (item: any) => dispatch({ type: AppUIStateActionTypes.PREVIEW_FILE, selectedFile: item }),
        updateFileHeaders: (filteredHeaders: string[]) => dispatch({
            type: AppUIStateActionTypes.UPDATE_FILE_LIST_HEADERS,
            filteredHeaders: filteredHeaders
        })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FsGroupsDetail));