import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import CommonCollapseItem from './commonCollapseItem';

type CollapseProps = {
    title: string,
    data: InvitedContact[],
    fetching: boolean,
    btnOneOnClick: any,
    btnTwoOnClick: any
}

interface InvitedContact {
    contactCode: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    modifiedAt: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
          width: '100%',
          margin: '1em 0'
        },
        summary: {
          borderBottom: '1px solid #ccc'
        },
        summaryContent: {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          fontWeight: theme.typography.fontWeightBold,
        },
        headerDescription: {
          fontSize: theme.typography.pxToRem(10),
          fontWeight: theme.typography.fontWeightBold,
        },
        detail: {
          display: 'block',
          padding: '0'
        }
    }),
);

const CommonCollapse = ( props: CollapseProps ) => {
    const classes = useStyles();
    const [ panelOpen, changePanelOpen ] = useState(false);

    return(
        <div className={ classes.root } data-test='commonCollapse'>
            <ExpansionPanel data-test='expansionPanelCollapse' onChange={ () => changePanelOpen(!panelOpen) }>
                <ExpansionPanelSummary 
                  className={ classes.summary }
                  aria-controls="common-panel-content"
                  id="common-panel-header">
                    <div className={ classes.summaryContent }>
                    <Typography className={ classes.heading }>{ props.title }</Typography>
                    { props.data.length > 1 && 
                      <Typography className={ classes.headerDescription }>{ panelOpen ? 'Show Less' : `Show All (${ props.data.length})`}</Typography>
                    }
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={ classes.detail }>
                  { props.data.map( (contact: any, index: number) => (
                     <CommonCollapseItem key={index} data={ contact } btnOneOnClick={ props.btnOneOnClick } btnTwoOnClick={ props.btnTwoOnClick }/>
                  ))}
                </ExpansionPanelDetails>
            </ExpansionPanel>
            { !panelOpen && 
              <CommonCollapseItem data={ props.data[0] } btnOneOnClick={ props.btnOneOnClick } btnTwoOnClick={ props.btnTwoOnClick }/>
            }
        </div>
    )
}

export default CommonCollapse;