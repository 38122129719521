import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogContent, Paper, DialogActions, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Fade, Modal, IconButton, Button, Link, Radio, RadioGroup, FormControlLabel, Chip, Checkbox } from '@material-ui/core';
import CircularProgressWithLabel from '../../../circularProgressWithLabel/CircularProgressWithLabel';
import * as CeremonyTypes from '../../../../../redux/constants/shared/eSignConstants';
import { withStyles } from '@material-ui/core/styles';
import { CeremonyStatus, CeremonyTokenStatus, ItemCeremonyStatus } from '../../../../core/CeremonyStatus';
import { formattedDateTime } from '../../../../core/utils/functions';
import { ReactComponent as InfoFileIcon } from '../../../../../assets/info-esign.svg';
import { ReactComponent as DeclinedFileIcon } from '../../../../../assets/alert-red-filled.svg';
import { ReactComponent as CancelledFileIcon } from '../../../../../assets/cancelled_status.svg';
import { ReactComponent as SignedFileIcon } from '../../../../../assets/total-complete-success-filled.svg';

type ESignProgressprops = {
    selectedItem: any,
    topicCode: any,
    isEndUser: any,
    children?: any,
    topicContacts: any,
    topicContactsFetching: any,
    ceremonyDetails: any,
    cancelCermonyAction: any,
    classes?: any
    onCeremonyModalClose: any;
    currentUserCode: any
}

export const styles = (theme: any) => ({

    recipienttable: {
        minWidth: '650px'
    },
    recipientheader: {
        backgroundColor: '#F1F2F5'
    },
    recipientheaderitem:
    {
        fontFamily: 'Fidelity Sans',
        fontSize: '12px',
        fontWeight: 'bold' as 'bold'
    },
    main: {
        fontFamily: 'Fidelity Sans',
    },
    content: {
        padding: '0px 0px 16px 0px'
    },
    itemlist: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        fontize: '0.875rem',
        fontWeight: 'bold' as 'bold',
        marginTop: '8px',
        width: '100%'
    },
    windowtitle: {
        color: '#000000',
        fontSize: '26px',
        fontWeight: 700,
        letterSpacing: '0',
        lineHeight: '32px'
    },
    documentlist: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        padding: '0px 8px 0px 0px'
    },
    subtitleProgress: {
        fontSize: '0.875rem',
        width: '20%',
        color: 'rgba(0, 0, 0, 0.87)',
        letterSpacing: '0.15px',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-end'
    },
    signatureStatus: {
        color: '#000000',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px'
    },
    documentStatus: {
        fontSize: '16px',
        color: '#000000',
        fontWeight: 400,
        lineHeight: '21px'
    },
    signatureHeader: {
        color: '#000000',
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '25px',
    },
    endUserDescription: {
        marginTop: 0,
        fontWeight: 'normal' as 'normal',
        marginBottom: '2rem'
    },
    documentTitle: {
        margin: '0px',
        minWidth: '50%',
        color: '#000000',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        fontWeight: 'normal' as 'normal'
    },
    recipientsubtitle:
    {
        fontFamily: theme.homefontfamilyregular,
        color: '#666666',
        fontSize: '12px',
        paddingLeft: '16px',
        marginTop: '4px'
    },
    cancelceremonyconfirmation: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        color: '#4D4F63',
        fontSize: '0.9rem'
    },
    commonModalActions: {
        justifyContent: 'center',
        padding: '0px'
    },
    primarybtn: {
        textTransform: 'none' as 'none'
    },
    iconBtn: {
        width: '16px',
        height: '16px',
        verticalAlign: 'middle',
        marginBottom: '3px'
    },
    documentLinkCancel: {
        fontSize: '1rem',
        position: 'absolute' as 'absolute',
        left: '2rem'
    },
    btnContainer:
    {
        marginTop: '1.5rem',
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center'
    }
})

class ESignProgress extends Component<ESignProgressprops> {

    state = {
        loading: true,
        isEsignCeremony: false,
        isCheckboxOn: false,
        isCancelEnabled: true,
        title: 'Electronic consent request',
        signatureStatus: 'All responses have been successfully recorded for this document.'
    }

    componentDidMount() {
        if (this.props.ceremonyDetails.error != null)
            this.props.onCeremonyModalClose(false, null, true);
        else {
            this.checkForEsignCeremony();
            this.checkForCancelable();
        }
    }

    componentDidUpdate(prevProps: any) {

        if (prevProps.ceremonyDetails.requesting != this.props.ceremonyDetails.requesting && !this.props.ceremonyDetails.requesting) {
            if (this.props.ceremonyDetails.error != null)
                this.props.onCeremonyModalClose(false, null, true);
            else {
                this.checkForEsignCeremony();
                this.checkForCancelable();
            }
        }
        if (prevProps.ceremonyDetails.cancelling != this.props.ceremonyDetails.cancelling &&
            !this.props.ceremonyDetails.cancelling) {
            let isError = false;
            isError = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.sysMsg != null) != undefined
            if (isError || this.props.ceremonyDetails.error != null)
                this.props.onCeremonyModalClose(false, CeremonyStatus.CEREMONY_CANCEL_INSTUSER);
            else
                this.props.onCeremonyModalClose(true, CeremonyStatus.CEREMONY_CANCEL_INSTUSER);
        }
    }

    checkForEsignCeremony = () => {
        let esign = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.ceremonyTypeCode === 'esign');
        if (esign != null) {
            this.setState({ isEsignCeremony: true });
            setTimeout(() => {
                this.getTitle();
                this.getSignatureStatus();
            }, 100);
        }
        else {
            this.getTitle();
        }
    }

    checkForCancelable = () => {
        let temp = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.status.toLowerCase() != 'requested');
        if (temp != null)
            this.setState({ isCancelEnabled: false });
    }

    handleClick = (event: any) => {
        this.setState({ isCheckboxOn: !this.state.isCheckboxOn })
    }

    toTitleCase = (userCode: string, attr: string) => {
        let contact = this.props.topicContacts.find((contact: any) => contact.userCode == userCode)
        if (contact != null && contact[attr] != null) {
            return contact[attr].replace(
                /\w\S*/g,
                function (txt: any) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        }
        else {
            return ''
        }
    }

    getEmail = (userCode: string) => {
        let contact = this.props.topicContacts.find((contact: any) => contact.userCode == userCode)
        if (contact != null) {
            return contact.email;
        }
        else {
            return '';
        }
    }

    cancelCermony = () => {
        if (this.props.ceremonyDetails != null) {
            let ceremonyCodes: any = [];
            this.props.ceremonyDetails.content.map((ceremony: any) => {
                ceremonyCodes.push(ceremony.stcCode);
            });
            this.props.cancelCermonyAction(ceremonyCodes);
        }
    }

    areAllSignaturesDone = () => {
        let allSignaturesDone = false;
        if (this.props.ceremonyDetails.content.every((ceremony: any) => ceremony.status === ItemCeremonyStatus.AGREED)) {
            allSignaturesDone = true;
        }
        return allSignaturesDone;
    }

    userDeclinedSigning = () => {
        let userDeclined = false;
        if (this.props.ceremonyDetails.content.some((ceremony: any) => ceremony.status === ItemCeremonyStatus.DECLINED)) {
            userDeclined = true;
        }
        return userDeclined;
    }

    isWaitingForSignedDoc = () => {
        let waitingSignedDoc = false;
        if (this.props.ceremonyDetails.content.every((ceremony: any) => (ceremony.status === ItemCeremonyStatus.AGREED || ceremony.status === ItemCeremonyStatus.WAITING_SIGNED_DOC))) {
            waitingSignedDoc = true;
        }
        return waitingSignedDoc;
    }

    getStatus = (ceremony: any) => {
        if (ceremony.status == ItemCeremonyStatus.WAITING_SIGNED_DOC)
            return 'Waiting Signed Document';
        if (ceremony.status == ItemCeremonyStatus.IN_PROGRESS)
            return 'In Progress';
        return ceremony.status;
    }

    getEsignStatus = (ceremony: any) => {
        const { classes } = this.props;
        if (ceremony.status == ItemCeremonyStatus.CANCELLED || ceremony.status == ItemCeremonyStatus.REVOKED)
            return (<><CancelledFileIcon className={classes.iconBtn} data-test="cancelled" data-testid="cancelled" aria-label="Waiting for the signed doc" />{' Cancelled'}</>);
        if (ceremony.status == ItemCeremonyStatus.DECLINED)
            return (<><DeclinedFileIcon className={classes.iconBtn} data-test="declined" data-testid="declined" aria-label="Declined sigin" />{' Declined to sign'}</>);
        if (ceremony.status == ItemCeremonyStatus.IN_PROGRESS || ceremony.status == ItemCeremonyStatus.REQUESTED)
            if (this.props.isEndUser)
                return 'Pending';
            else
                return 'Requested';
        if (ceremony.status == ItemCeremonyStatus.FAILED)
            return 'Failed';
        if (ceremony.status == ItemCeremonyStatus.WAITING_SIGNED_DOC || ceremony.status == ItemCeremonyStatus.AGREED)
            return (<><SignedFileIcon className={classes.iconBtn} data-test="agreed" data-testid="agreed" aria-label="Accepted sigin" />{' Signed'}</>);
        return ceremony.status;
    }

    renderTable() {
        const { classes } = this.props;
        let ceremonyError = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.sysMsg != null) !== undefined;
        return (
            <TableContainer component={Paper}>
                <Table className={classes.recipienttable} aria-label="Recipients">
                    <TableHead className={classes.recipientheader}>
                        <TableRow>
                            {!this.props.isEndUser && <TableCell className={classes.recipientheaderitem}>Recipients</TableCell>}
                            {this.props.isEndUser && !this.state.isEsignCeremony && <TableCell className={classes.recipientheaderitem}>Contacts</TableCell>}
                            {this.props.isEndUser && this.state.isEsignCeremony && <TableCell className={classes.recipientheaderitem}>Name</TableCell>}
                            <TableCell className={classes.recipientheaderitem}>Email</TableCell>
                            <TableCell className={classes.recipientheaderitem}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.ceremonyDetails && !ceremonyError && this.props.ceremonyDetails.content.map((ceremony: any) => (
                            <TableRow key={ceremony.userCode}>
                                {!this.props.isEndUser &&
                                    <TableCell component="th" scope="row">
                                        {this.toTitleCase(ceremony.userCode, "firstName")} {this.toTitleCase(ceremony.userCode, "lastName")}
                                    </TableCell>
                                }
                                {this.props.isEndUser &&
                                    <TableCell component="th" scope="row">
                                        {ceremony.userCode === this.props.currentUserCode && <span>You ({this.toTitleCase(ceremony.userCode, "firstName")} {this.toTitleCase(ceremony.userCode, "lastName")}) </span>}
                                        {ceremony.userCode !== this.props.currentUserCode && <span>{this.toTitleCase(ceremony.userCode, "firstName")} {this.toTitleCase(ceremony.userCode, "lastName")}</span>}
                                    </TableCell>
                                }
                                <TableCell>{this.getEmail(ceremony.userCode)}</TableCell>
                                {!this.state.isEsignCeremony && <TableCell style={{ textTransform: 'capitalize' }}>{this.getStatus(ceremony).toLowerCase()}</TableCell>}
                                {this.state.isEsignCeremony && <TableCell>{this.getEsignStatus(ceremony)}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    getTitle() {
        if (this.props.ceremonyDetails.content.some((ceremony: any) => (ceremony.status === ItemCeremonyStatus.CANCELLED || ceremony.status === ItemCeremonyStatus.REVOKED || ceremony.status === ItemCeremonyStatus.DECLINED))) {
            if (this.state.isEsignCeremony === true && this.props.isEndUser) {
                this.setState({ title: 'Request cancelled' })
            }
            else {
                this.setState({ title: 'Electronic consent cancelled' })
            }
        }
        else if (this.props.ceremonyDetails.content.some((ceremony: any) => (ceremony.status === ItemCeremonyStatus.REQUESTED || ceremony.status === ItemCeremonyStatus.IN_PROGRESS))) {
            if (this.state.isEsignCeremony) {
                if (this.props.isEndUser)
                    this.setState({ title: 'Track request status' })
                else
                    this.setState({ title: 'Electronic consent request in progress' })
            }
            else
                this.setState({ title: 'Electronic consent request' })
        }
        if (this.props.isEndUser) {
            const singleSigner = this.props.ceremonyDetails.content.length === 1

            this.props.ceremonyDetails.content.map((ceremony: any) => {
                if (singleSigner){
                    if (ceremony.userCode === this.props.currentUserCode && (ceremony.status === ItemCeremonyStatus.AGREED
                        || ceremony.status === ItemCeremonyStatus.WAITING_SIGNED_DOC)) {
                        if (this.state.isEsignCeremony) {
                            this.setState({ title: 'Track request status' })
                        }
                        else
                            this.setState({ title: 'Electronic consent complete' })
                        return;
                    }
                }
                else if ((ceremony.status === ItemCeremonyStatus.DECLINED
                            || ceremony.status === ItemCeremonyStatus.REVOKED)) {
                            if (this.state.isEsignCeremony) {
                                this.setState({ title: 'Request cancelled' })
                            }
                            return;
                        }
            });

        } else {
            if (this.props.ceremonyDetails.content.every((ceremony: any) => ceremony.status === ItemCeremonyStatus.AGREED
                || ceremony.status === ItemCeremonyStatus.DECLINED))
                this.setState({ title: 'Electronic consent complete' })
        }
    }

    getSignatureStatus() {
        const singleSigner = this.props.ceremonyDetails.content.length === 1
        if (this.userDeclinedSigning())
            this.setState({ signatureStatus: 'The request for this document was cancelled. No further action is required.' })
        if ((this.areAllSignaturesDone() || this.isWaitingForSignedDoc()) && !this.userDeclinedSigning())
            this.setState({ signatureStatus: (singleSigner ? 'Your response was' : 'All responses have been') + ' successfully recorded for this document.' })
        else if (this.props.ceremonyDetails.content.some((ceremony: any) => ceremony.status === ItemCeremonyStatus.CANCELLED))
            this.setState({ signatureStatus: 'The request for this document was cancelled by Fidelity.' })
        else this.props.ceremonyDetails.content.map((ceremony: any) => {
            if (!singleSigner) {
                if ((ceremony.status === ItemCeremonyStatus.DECLINED || ceremony.status === ItemCeremonyStatus.REVOKED)) {
                    this.setState({ signatureStatus: 'The request for this document was cancelled. No further action is required.' })
                    return;
                }
            }
            else
                if (ceremony.userCode === this.props.currentUserCode && (ceremony.status === ItemCeremonyStatus.AGREED || ceremony.status === ItemCeremonyStatus.WAITING_SIGNED_DOC)) {
                    this.setState({ signatureStatus: 'Your response was successfully recorded for this document.' + (singleSigner ? '' : ' We\'re waiting for other recipients to respond before completing the request. We\'ll automatically update the file when it\'s available.') })
                    return;
                }
        });
    }

    render(): JSX.Element {
        const { classes } = this.props;
        let ceremonyError = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.sysMsg != null) !== undefined;
        return (
            <div className={classes.main} data-test="eSignProgress" >
                <div className={classes.windowtitle}>
                    {this.state.title}
                </div>
                <DialogContent className={classes.content}>
                    <div className={classes.itemlist}>
                        {!this.props.isEndUser &&
                            <div>
                                <div className={classes.documentlist}>
                                    <div className={classes.subtitleProgress}>
                                        Document
                                    </div>
                                    <p className={classes.documentTitle}>
                                        {this.props.selectedItem.itemName}
                                    </p>
                                </div>
                                <div className={classes.documentlist} style={{ marginBottom: '1rem' }}>
                                    <div className={classes.subtitleProgress}>
                                    </div>
                                    <p className={classes.recipientsubtitle}>
                                        {`Uploaded `}
                                        {formattedDateTime(this.props.selectedItem.createdAt)}
                                    </p>
                                </div>
                                {this.props.ceremonyDetails && this.props.ceremonyDetails.content.length > 0 && !ceremonyError && <div className={classes.documentlist}>
                                    <div className={classes.subtitleProgress}>
                                        Request type
                                    </div>
                                    {
                                        <p className={classes.documentTitle}>
                                            <div> {this.props.ceremonyDetails.content[0].ceremonyTypeCode.toUpperCase()} </div>
                                        </p>
                                    }
                                </div>}
                                <div className={classes.documentlist} style={{ marginBottom: '1rem' }}>
                                    <div className={classes.subtitleProgress}>
                                    </div>
                                    {this.props.ceremonyDetails && this.props.ceremonyDetails.content.length > 0 && !ceremonyError && <p className={classes.recipientsubtitle}>
                                        {`Request created `}
                                        {formattedDateTime(this.props.ceremonyDetails.content[0].createdAt)}
                                    </p>
                                    }
                                </div>
                            </div>
                        }
                        {this.state.isEsignCeremony && this.props.isEndUser &&
                            <div>
                                <p className={classes.signatureStatus}>
                                    {this.state.signatureStatus}
                                </p>
                                {!this.areAllSignaturesDone() && this.isWaitingForSignedDoc() && <div className={classes.documentStatus}>
                                    <InfoFileIcon className={classes.iconBtn} aria-label="Waiting for the signed doc" />
                                    {` Waiting for the signed copy from DocuSign. We'll update the document as soon as it's received.`}
                                </div>
                                }
                                {this.props.ceremonyDetails && this.props.ceremonyDetails.content.length > 0 && !ceremonyError && <p className={classes.signatureHeader}>
                                    {`Track signatures`}
                                </p>
                                }
                            </div>
                        }
                        {!this.state.isEsignCeremony && this.props.isEndUser &&
                            <p className={classes.endUserDescription}>Your request has been recorded for this document, and no futher action is required. We'll update the document when the other contacts have recorded their response.</p>
                        }

                        {this.renderTable()}
                    </div>
                </DialogContent>
                {!this.props.isEndUser && this.state.isCancelEnabled &&
                    <div className={classes.cancelceremonyconfirmation}>
                        <Checkbox checked={this.state.isCheckboxOn} color='primary' data-test="onCheckbox"
                            onClick={(event: any) => this.handleClick(event)} />
                        <p className="cancelLabel">Cancel request. Recipients will not be notified, and changes to the document will not be saved. </p>
                    </div>
                }
                <DialogActions className={classes.commonModalActions}>
                    <Link className={classes.documentLinkCancel} data-test="cancelLink" href="#" onClick={() => this.props.onCeremonyModalClose(false)} id="cancel">
                        Close
                    </Link>
                    <div className={classes.btnContainer}>
                        {!this.props.isEndUser && this.state.isCancelEnabled && <Button
                            id="sendrequest"
                            data-test="cancelButton"
                            className={classes.primarybtn}
                            variant="contained"
                            onClick={() => this.cancelCermony()}
                            color="primary"
                            disabled={!this.state.isCheckboxOn}
                            disableElevation>
                            Cancel Request
                        </Button>
                        }
                    </div>
                </DialogActions>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        files: state.topicFilesReducer.filesUpload.files,
        topicContacts: state.topicReducer.topicContacts.contactsData.content,
        topicContactsFetching: state.topicReducer.topicContacts.fetching,
        ceremonyDetails: state.eSignReducer.ceremonyDetails,
        currentUserCode: state.login.user_profile.userCode
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        cancelCermonyAction: (ceremonyCode: any) => dispatch({
            type: CeremonyTypes.CEREMONY_API_CANCEL_INITIATION,
            ceremonyCode: ceremonyCode
        })
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ESignProgress));