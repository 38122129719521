import React, { useState, useEffect, useRef } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import { Card, Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as AddContactIcon } from '../../../assets/add-contact.svg';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formattedDate } from '../../core/utils/functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
            backgroundColor: '#F9F9F9',
            padding: '7px',
            flexDirection: 'row',
            alignContent: 'flex-start'
        },
        toolBar: {
            backgroundColor: '#fff',
            padding: '0 3px',
            borderBottom: '1px solid #c5c5c5'
        },
        topicCard: {
            width: '258px !important',
            height: '140px !important',
            margin: '7px',
            transition: 'all ease 0.3s',
            display: 'flex',
            padding: '20px 16px 20px 0 !important',
            flexDirection: 'row',
            '&:hover': {
                transform: 'scale(1.02)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100% !important'
            },
            [theme.breakpoints.between('sm', 'md')]: {
                // width: 'calc(50% - 30px) !important'
            }
        },
        topicCardSelected: {
            backgroundColor: 'rgba(212,243,182,0.42)'
        },
        leftSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
        rightSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
        contentWrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        topicIconWrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                margin: '0 16px',
            }
        },
        typeIcon: {
            marginRight: '0',
            width: '40px'
        },
        checkbox: {
            marginLeft: '10px',
            marginTop: '4px'
        },
        topicIcon: {
            borderRadius: '4px',
            backgroundColor: '#333',
            width: '30px',
            height: '30px',
            padding: '5px',
            fontSize: '12px',
            margin: '0 1rem',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
        },
        topicTitle: {
            width: '196px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 700,
            fontSize: 14,
            margin: 0,
            textTransform: 'capitalize',
            color: 'rgba(0, 0, 0, 0.72)',
            cursor: 'pointer',
            '&:hover': {
                color: '#0E67A9',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
            }
        },
        momentContent: {
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '20px',
            color: 'rgba(68,70,87,0.75)',
            margin: 0
        },
        sizeContent: {
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '20px',
            color: 'rgba(68,70,87,0.75)',
            margin: 0
        },
        permissionBtn: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem',
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            fontFamily: 'Fidelity Sans',
            marginTop: '1rem'
        },
        permissionStatic: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem',
            display: 'flex',
            width: 'calc(100% - 16px)',
            justifyContent: 'flex-end',
            padding: '8px 8px',
            marginTop: '1rem'
        },
        progressbar: {
            height: '4px'
        },
        loadingContext: {
            color: '#8a8a8a',
            fontWeight: 700,
            fontSize: '11px',
            marginLeft: '10px'
        },
        loadingWrapper: {
            width: '100%',
            textAlign: 'center'
        },
        filterMenuBtn: {
            width: '20px',
            height: '20px',
        },
    }),
);

interface TopicType {
    stCode: string,
    stName: string,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number
}

interface MenuItemType {
    id: string,
    label: string,
    show: boolean
}

interface MenuType {
    count: number,
    menu: MenuItemType[]
}

type CommonTopicGridListProps = {
    topics: TopicType[],
    tableMarginHeight: number,
    onFileOpen: any,
    topicsFetching: boolean,
    lazyLoading: boolean,
    noMoreData?: boolean
    menuActions: MenuType,
    menuActionOnClick: any
}

const CommonTopicGridList = (props: CommonTopicGridListProps) => {
    const classes = useStyles();
    const [selectedTopic, setTopic] = useState(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [gridHeight, setgridHeight] = useState<number>(0);
    const gridContainer: any = useRef<HTMLDivElement>(null);
    const [hasReachedToBottom, setReachToBottom] = useState(false);
    const [timeUp, setTimer] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize);
        setgridHeight(gridHeight);
        return () => window.removeEventListener("resize", handleResize);
    })

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>, topic: any) => {
        setAnchorEl(event.currentTarget);
        setTopic(topic);
    };

    const itemOnOpen = (event: React.MouseEvent<unknown>, topic: any) => {
        event.stopPropagation();
        props.onFileOpen(topic)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {!props.topicsFetching &&
                <>
                    <div className={classes.root} ref={gridContainer} data-test='commonTopicGridListNotFetching'>
                        <GridList cellHeight={160}>
                            {props.topics.map((topic: TopicType, index: number) => {
                                const isItemSelected = false;
                                return (
                                    <Card className={`${classes.topicCard} ${isItemSelected ? classes.topicCardSelected : null}`}
                                        key={index} >
                                        <section className={classes.leftSection}>
                                            <div className={classes.topicIconWrapper}>
                                                <div className={classes.topicIcon}>
                                                    {topic.catgeoryIconLabel}
                                                </div>

                                                {props.menuActions.count > 1 && <IconButton
                                                    data-test='moreMenuBtn'
                                                    color="primary"
                                                    aria-label="item actions"
                                                    component="span"
                                                    aria-controls="action-menu"
                                                    onClick={(e: any) => openMenu(e, topic)}
                                                    aria-haspopup="false">
                                                    <MoreVertIcon />
                                                </IconButton>
                                                }
                                                {props.menuActions.count === 1 && props.menuActions.menu.map((item: MenuItemType, mIndex: number) => (
                                                    item.show && <IconButton
                                                        data-test={`menuBtn${mIndex}`}
                                                        key={mIndex}
                                                        color="primary"
                                                        aria-label="item actions"
                                                        component="span"
                                                        aria-controls="action-menu"
                                                        onClick={() => props.menuActionOnClick('updateContact', topic)}
                                                        aria-haspopup="false">
                                                        {item.id === 'updateContact' && <AddContactIcon className={classes.filterMenuBtn} />}
                                                    </IconButton>
                                                ))}
                                            </div>
                                        </section>
                                        <section className={classes.rightSection}>
                                            <div className={classes.contentWrapper}>
                                                <div>
                                                    <Tooltip disableFocusListener disableTouchListener title={topic.stName}>
                                                        <p data-test='itemOpenBtn' className={classes.topicTitle} onClick={event => itemOnOpen(event, topic)}>
                                                            {topic.stName}
                                                        </p>
                                                    </Tooltip>
                                                    <p className={classes.momentContent}><span className={classes.momentContent}>{topic.categoryName}</span></p>
                                                    <p className={classes.momentContent}>Created: <span className={classes.momentContent}>{formattedDate(topic.createdAt)}</span></p>
                                                    <p className={classes.momentContent}>Modified: <span className={classes.momentContent}>{formattedDate(topic.modifiedAt)}</span></p>
                                                    <p className={classes.momentContent}>Contacts: <span className={classes.momentContent}>{topic.sharedTopicUsersCount}</span></p>
                                                    <p className={classes.momentContent}>Files: <span className={classes.momentContent}>{topic.sharedTopicItemsCount}</span></p>
                                                </div>
                                            </div>
                                        </section>
                                    </Card>
                                )
                            })}
                        </GridList>
                        <Menu data-test='topicMenu'
                            id="topic-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            {props.menuActions.menu.map((item: MenuItemType, mIndex: number) => (
                                item.show && <MenuItem key={mIndex} onClick={() => props.menuActionOnClick(item.id, selectedTopic)}>{item.label}</MenuItem>
                            ))}
                        </Menu>
                        {props.lazyLoading && <div className={classes.loadingWrapper}>
                            <CircularProgress size={12} /> <span className={classes.loadingContext}>LOADING MORE</span>
                        </div>
                        }
                        {props.noMoreData && <div className={classes.loadingWrapper}>
                            <span className={classes.loadingContext}>END OF LIST</span>
                        </div>
                        }
                    </div>
                </>
            }
            {props.topicsFetching &&
                <>
                    <LinearProgress data-test='commonTopicGridListFetching' className={classes.progressbar} variant="query" />
                </>
            }
        </>

    )
}

export default CommonTopicGridList;