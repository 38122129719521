import React, { useState, useCallback, Component } from 'react';
import { IconButton, Modal, withStyles } from '@material-ui/core';
import ShareContent from '../../../shared/components/ShareContent';
import DownloadFile from '../../../../../components/personalVault/downloadFile/DownloadFile';
import CommonModal from '../../../../../shared/ui/commonModal/commonModal';
import MoveItem from '../moveItem/MoveItem';
import CommonItemsActionsBar from '../../../../../shared/ui/commonFileItemList/CommonItemsAction';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import { connect } from 'react-redux';
import RenameItem from '../renameItem/RenameItem';
import DeleteItem from '../deleteItem/DeleteItem';

export const styles = (theme: any) => ({
    fileActionBar: {
        borderRight: '1px solid #c5c5c5',
        paddingRight: '.5em',
        marginRight: '.5em',
        [theme.breakpoints.down('md')]: {
            borderRight: 'none'
        }
    },
    iconBtn: {
        width: '20px',
        height: '20px',
    }
});


type ItemActionsBarProps = {
    selectedFiles?: any[],
    currentFolder: string,
    updateSnackbar: any;
    filePreview: any;
    onActionComplete: any;
}

class ItemActionsBar extends Component<ItemActionsBarProps>
{
    state = {
        isModalOpen: false,
        modalName: ''
    }

    setModalOpen = (modalState: boolean) => {
        this.setState({ isModalOpen: modalState })
    }

    setmodalName = (modalName: string) => {
        this.setState({ modalName: modalName })
    }

    isSingleItemSelected = () => (this.props.selectedFiles != null && this.props.selectedFiles.length === 1) ? true : false
    isMultipleItemsSelected = () => (this.props.selectedFiles != null && this.props.selectedFiles.length > 0) ? true : false
    isSingleFileSelected = () => (this.props.selectedFiles != null && this.props.selectedFiles.length === 1 && this.props.selectedFiles[0].itemType === 'FILE') ? true : false
    isMultipleFilesSelected = () => {
        var valid = (this.props.selectedFiles != null && this.props.selectedFiles.length > 0)
        this.props.selectedFiles != null && this.props.selectedFiles.forEach(x => {
            if (x.itemType === 'FOLDER')
                valid = false;
        }
        )
        return valid;
    }


    handleActionDialogClose = () => {
        this.setState({ isModalOpen: false })
    }

    onActionComplete = (issuccess: boolean, message: string) => {
        if (null != message)
            if (issuccess)
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: message
                })
            else
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: message,
                    isError: true
                })
        //Todo: Reselect the file selection on action complete.
        this.props.onActionComplete();
        this.setState({ isModalOpen: false })
    }

    loadShareContent = () => {
        return <ShareContent modalOpen={this.state.isModalOpen}
            handleModalClose={this.handleActionDialogClose}
            onActionComplete={this.onActionComplete}
            selectedItems={(this.props.selectedFiles != null) ? this.props.selectedFiles : []} ></ShareContent>
    }

    loadMoveContent = (folder: any) => {
        return <MoveItem open={this.state.isModalOpen}
            handleModalClose={this.handleActionDialogClose}
            currentFolder={folder}
            onActionComplete={this.onActionComplete}
            selectedItems={(this.props.selectedFiles != null) ? this.props.selectedFiles : []}
        > </MoveItem>
    }

    modalContentCreate = (name: string) => {
        const { currentFolder } = this.props;
        var temp = currentFolder[currentFolder.length - 1];
        switch (name) {
            case 'share':
                return <CommonModal modalOpen={this.state.isModalOpen}
                    modalClose={this.handleActionDialogClose}
                    content={this.loadShareContent()} />
            case 'download':
                return <Modal
                    aria-describedby="simple-modal-description"
                    open={this.state.isModalOpen}
                    onClose={this.handleActionDialogClose}
                >
                    <div style={{ top: '40%', left: '40%', position: 'absolute' }}>
                        <DownloadFile
                            selectedItems={(this.props.selectedFiles != null) ? this.props.selectedFiles : []} >
                        </DownloadFile>
                    </div>
                </Modal>
            case 'move':
                return <CommonModal modalOpen={this.state.isModalOpen}
                    modalClose={this.handleActionDialogClose}
                    content={this.loadMoveContent(temp)} />
            case 'rename':
                return <RenameItem
                    modalOpen={this.state.isModalOpen}
                    currentFolder={temp}
                    isRename={true}
                    selectedItem={(this.props.selectedFiles != null && this.props.selectedFiles.length > 0) ? this.props.selectedFiles[0] : null}
                    onActionComplete={this.onActionComplete}
                    handleModalClose={this.handleActionDialogClose}
                >
                </RenameItem>
            case 'delete':
                return <Modal
                    aria-describedby="simple-modal-description"
                    open={this.state.isModalOpen}
                    onClose={this.handleActionDialogClose}>
                    <DeleteItem
                        currentFolder={temp}
                        selectedItems={(this.props.selectedFiles != null) ? this.props.selectedFiles : []}
                        handleModalClose={this.handleActionDialogClose}
                        onActionComplete={this.onActionComplete}
                    >
                    </DeleteItem>
                </ Modal>
            default:
                return null
        }
    }

    onActionClick = (name: string) => {
        this.setState({ isModalOpen: true })
        this.setState({ modalName: name })
        switch (name) {
            case 'download':
                setTimeout(() => {
                    this.setState({ isModalOpen: false })
                }, 5000);
            default:
                return null
        }
    }

    render() {
        return (
            <>
                <CommonItemsActionsBar
                    showView={this.isSingleFileSelected()}
                    showDownload={this.isMultipleFilesSelected()}
                    showMove={this.isMultipleItemsSelected()}
                    showShare={this.isMultipleFilesSelected()}
                    showDelete={this.isMultipleItemsSelected()}
                    showRename={this.isSingleItemSelected()}
                    onActionClick={this.onActionClick}
                    filePreview={this.props.filePreview}>
                </CommonItemsActionsBar>
                {this.modalContentCreate(this.state.modalName)}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        })
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ItemActionsBar));