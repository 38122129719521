import { takeLatest, takeEvery, call, put, all, select } from "redux-saga/effects";
import * as TopicContactActionTypes from '../../constants/customerVault/topicConstants';
import * as  appActionTypes from '../../constants/shared/appStatesConstants';
import {
  getTopics, getUserProfileByTopicCode, getTopicsForUser, associateUser, createTopic,
  getCategories, getTopicDetail, addMetadata, updateMetadata
} from '../../../shared/core/utils/apiFunctions'

export function* topicWatcher() {
  yield takeLatest(TopicContactActionTypes.USER_API_GET_TOPIC_USERS_REQUEST, topicUsersSaga);
  yield takeLatest(TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_REQUEST, editTopicUsersSaga);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_REQUEST, topicsLazyLoadSaga);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_ADD_TOPICS_REQUEST, addTopicSaga);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_REQUEST, getTopicCategories);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_GET_TOPICS_REQUEST, topicsSaga);
  yield takeEvery(TopicContactActionTypes.TOPICS_API_GET_ALL_TOPICS_REQUEST, allTopicsSaga);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_REQUEST, getTopicDetailSaga);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_ADD_METADATA_REQUEST, addTopicMetadataSaga);
  yield takeLatest(TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_REQUEST, updateTopicMetadataSaga);
}

function* topicUsersSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const contacts = yield call(getUserProfileByTopicCode, grpCode, action.topicCode);
      yield put({ type: TopicContactActionTypes.USER_API_GET_TOPIC_USERS_SUCCESS, contacts: contacts });
    }
  }
  catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.USER_API_GET_TOPIC_USERS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.USER_API_GET_TOPIC_USERS_FAILURE, error });
  }
}

function* getTopicCategories() {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const categories = yield call(getCategories, grpCode);
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_SUCCESS, categories: categories });
    }
  }
  catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_FAILURE, error });
  }
}

function* editTopicUsersSaga(action) {
  try {
    //Todo: Get the Group code from the state
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const associateUserResponse = yield call(associateUser, grpCode, action.topicName, action.topicCode, action.contactCodes);
      yield put({ type: TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_SUCCESS, responseInfo: associateUserResponse })
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_FAILURE, error });
  }
}

function* topicsSaga(action) {
  try {
    let isEndUser = true;
    let grpCode;
    let userCode;
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      if (action.grpCode != null) {
        grpCode = action.grpCode;
      }
      else {
        grpCode = appInfo.selectedGroup.grpCode;
      }
      if (action.userCode) {
        userCode = action.userCode;
      }
      else {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
          userAccessInfo: select(getUser)
        })
        if (userAccessInfo !== undefined) {
          var temp = userAccessInfo.user_profile.roles.filter(x => x === 'BUS_ADMIN' || x === 'INST_USER')
          if (temp != null && temp.length > 0)
            isEndUser = false;
          userCode = userAccessInfo.user_profile.userCode;
        }
      }

      const categoryCode = action.filter ? action.filter.categoryCode ? action.filter.categoryCode : null : null;
      const createdAt = action.filter ? action.filter.createdAt ? action.filter.createdAt : null : null;
      const modifiedAt = action.filter ? action.filter.modifiedAt ? action.filter.modifiedAt : null : null;
      let topics = null;
      if (isEndUser)
        topics = yield call(getTopicsForUser, grpCode, userCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy, categoryCode, createdAt, modifiedAt);
      else
        topics = yield call(getTopics, grpCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy, categoryCode, createdAt, modifiedAt);
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_SUCCESS, responseInfo: topics });
    }
  }
  catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_FAILURE, error });
  }
}

function* getTopicDetailSaga(action) {
  try {
    let topicCode = action.topicCode;
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      let topic = yield call(getTopicDetail, grpCode, topicCode);
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_SUCCESS, responseInfo: topic });
    }
  }
  catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_FAILURE, error });
  }
}




function* allTopicsSaga(action) {
  try {
    let isEndUser = true;
    let grpCode;
    let userCode;
    let grpName;
    let productCode = action.productCode;
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      if (action.grpCode != null) {
        grpCode = action.grpCode;
        grpName = action.grpName;
      }
      else {
        grpCode = appInfo.selectedGroup.grpCode;
        grpName = appInfo.selectedGroup.grpName;
      }
      if (action.userCode) {
        userCode = action.userCode;
      }
      else {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
          userAccessInfo: select(getUser)
        })
        if (userAccessInfo !== undefined) {
          var temp = userAccessInfo.user_profile.roles.filter(x => x === 'BUS_ADMIN' || x === 'INST_USER')
          if (temp != null && temp.length > 0)
            isEndUser = false;
          userCode = userAccessInfo.user_profile.userCode;
        }
      }
      const categoryCode = action.filter ? action.filter.categoryCode ? action.filter.categoryCode : null : null;
      const createdAt = action.filter ? action.filter.createdAt ? action.filter.createdAt : null : null;
      const modifiedAt = action.filter ? action.filter.modifiedAt ? action.filter.modifiedAt : null : null;
      let topics = null;
      if (isEndUser)
        topics = yield call(getTopicsForUser, grpCode, userCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy, categoryCode, createdAt, modifiedAt);
      else
        topics = yield call(getTopics, grpCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy, categoryCode, createdAt, modifiedAt);
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_ALL_TOPICS_SUCCESS, responseInfo: topics, grpCode: grpCode, grpName: grpName, productCode: productCode });
    }
  }
  catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_GET_TOPICS_FAILURE, error });
  }
}


function* topicsLazyLoadSaga(action) {
  try {
    let grpCode;
    let userCode;
    let isEndUser = true;
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      grpCode = appInfo.selectedGroup.grpCode;
      if (action.userCode) {
        userCode = action.userCode;
      }
      else {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
          userAccessInfo: select(getUser)
        })
        if (userAccessInfo !== undefined) {
          var temp = userAccessInfo.user_profile.roles.filter(x => x === 'BUS_ADMIN' || x === 'INST_USER')
          if (temp != null && temp.length > 0)
            isEndUser = false;
          userCode = userAccessInfo.user_profile.userCode;
        }
      }
      const categoryCode = action.filter ? action.filter.categoryCode ? action.filter.categoryCode : null : null;
      const createdAt = action.filter ? action.filter.createdAt ? action.filter.createdAt : null : null;
      const modifiedAt = action.filter ? action.filter.modifiedAt ? action.filter.modifiedAt : null : null;
      let topics = null;
      if (isEndUser) {
        topics = yield call(getTopicsForUser, grpCode, userCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy, categoryCode, createdAt, modifiedAt);
      } else {
        topics = yield call(getTopics, grpCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy, categoryCode, createdAt, modifiedAt);
      }
      yield put({ type: TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_SUCCESS, responseInfo: topics });
    }
  }
  catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_FAILURE, error });
  }
}

function* addTopicSaga(action) {
  try {
    //Todo: Get the Group code from the state
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState)
    })
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const createdTopic = yield call(createTopic, grpCode, action.topicName, action.categoryCode, action.users, action.date);
      yield put({ type: TopicContactActionTypes.TOPICS_API_ADD_TOPICS_SUCCESS, topic: createdTopic })
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_ADD_TOPICS_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_ADD_TOPICS_FAILURE, error });
  }
}

function* addTopicMetadataSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const metadataResponse = yield call(addMetadata, grpCode, action.topicCode, action.metadata);
      yield put({ type: TopicContactActionTypes.TOPICS_API_ADD_METADATA_SUCCESS, topic: metadataResponse })
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_ADD_METADATA_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_ADD_METADATA_FAILURE, error });
  }
}

function* updateTopicMetadataSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const metadataResponse = yield call(updateMetadata, grpCode, action.topicCode, action.metadata);
      yield put({ type: TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_SUCCESS, topic: metadataResponse })
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if ((errorResponse.status === 403 || errorResponse.status === 401))
        yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, errorResponse });
      else
        yield put({ type: TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_FAILURE, error });
    }
    else
      yield put({ type: TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_FAILURE, error });
  }
}

export default topicWatcher;

