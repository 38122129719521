import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { GET_API_HOME_FOLDER } from '../../redux/constants/personalVault/itemsConstants'
import ConfirmDialog from '../../components/personalVault/confirmDialog/ConfirmDialog';
import Layout from '../../shared/ui/layout/Layout';
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import FsFileDetailPage from './fileDetail/Fs.fileDetail.page';

export const base_path = '/personal'

const styles = theme => ({
  mainWrapper: {
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  mainDiv:
  {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    backgroundColor: '#F1F2F5',
    overFlow: 'scroll',
    height: '100vh'
  },
  footer:
  {
    height: '60px'
  },
  pageMain: {
    display: 'flex',
    flex: '1',
    flexGrow: '1',
    backgroundColor: '#F5F5F5'

  },
  pageHeader:
  {
    zIndex: '1500'
  },

  mainContentMobile:
  {
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    marginTop: '56px',
    height: 'calc(100vh - 56px)',
    backgroundColor: '#F1F2F5'
  },
  ItemDetailsWrapper: {
    width: '100%'
  }
});

class PvHomePage extends React.Component {

  state = {
    groupDetails: { "createdAt": "2019-05-14T06:49:28.440725", "grpCode": "GRP01", "grpDisplayCode": "GrpDisp01", "grpName": "Anderson Advisors Group 2.0", "modifiedAt": "2019-06-11T14:17:15.832", "userCount": 51 },
    selectedTopic: { "categoryIconColor": "black", "categoryName": "Functional Testing", "catgeoryIconLabel": "FT", "createdAt": "2019-09-09T09:54:29.664", "modifiedAt": "2019-11-26T17:23:01.004", "sharedTopicItemsCount": 192, "sharedTopicUsersCount": 2, "stCode": "7480720190909095429587A2E8uNRp6btUlWV" },
    mobile: false,
    navBarOpen: true,
    snackbarcount: 0,
    snackbarMessage: ''
  }
  constructor(props) {
    super(props);
    this.props.fetchHomeFolder();
  }
  componentDidMount() {
  }

  showSnackbar = (message) => {
    this.setState(
      { snackbarMessage: message, snackbarcount: this.state.snackbarcount + 1 }
    )
  }

  renderContent(islarge) {
    return (
      <Switch>
        <Route strict path={`${base_path}/myfiles`} render={
          () => (
            <Layout pageName='pv-myfiles' location={this.props.location} />
          )
        } />
        <Route strict path={`${base_path}/file-detail/:fileId`} render={
          () => ( <FsFileDetailPage /> )
        } />
        <Route strict path={`${base_path}/notifications`} render={
          () => (
            <Layout pageName='pv-notifications' location={this.props.location} />
          )
        } />
        <Route strict path={`${base_path}/contacts`} render={
          () => (
            <Layout pageName='pv-contacts' location={this.props.location} />
          )
        } />
        <Route strict path={`${base_path}/contacts-detail/:contactCode`} render={
          () => (
            <Layout pageName='pv-contacts-detail' location={this.props.location} />
          )
        } />
        <Route strict path={`${base_path}/contacts-groups-detail/:groupCode`} render={
          () => (
            <Layout pageName='pv-contacts-groups-detail' location={this.props.location} />
          )
        } />
        <Route strict path={`${base_path}/recents`} render={
          () => (
            <Layout pageName='pv-recents' location={this.props.location} />
          )
        } />
        <Route strict path={`${base_path}/shared`} render={
          () => (
            <Layout pageName='pv-shared' location={this.props.location} />
          )
        } />
        <Route exact path={base_path}>
          <Redirect to={`${base_path}/myfiles`} />
        </Route>
      </Switch>);
  }

  render() {
    const { classes } = this.props;
    if (!this.props.loggedIn.successful && !this.props.loggedIn.user)
      return <Redirect to='/login' />
    return (
      <div className={ classes.mainWrapper }>
        {this.renderContent(true)}
        {(this.state.snackbarMessage) ?
          <ConfirmDialog key={`key${this.state.snackbarcount + 1}`} open={true} message={this.state.snackbarMessage} /> : null}
      </div >
    );
  }
};
const mapStateToProps = state => {
  return {
    baseUrl: state.appUIStateReducer.baseUrl,
    loggedIn : state.login,
    foldersPath: state.itemsReducer.breadcrumbInfo.folders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchHomeFolder: () => dispatch({ type: GET_API_HOME_FOLDER }),

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PvHomePage)));
