import React, { Component } from 'react';
import { 
    AppBar,
    Tabs,
    Tab,
    Typography,
    IconButton, 
    Box,
    Hidden 
} from '@material-ui/core';
import { CommonFileItemList, CommonFileActionsBar, CommonFileGridList } from '../../../../shared/ui';
import { ReactComponent as GridIcon } from '../../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../../assets/list-view.svg';
import { fsFileSharing } from '../../../../shared/core/utils/paginationDefaults';
import * as ContactDetailActionTypes from '../../../../redux/constants/personalVault/contactDetailConstants';
import { connect } from 'react-redux';

import './contactDetailFileSharingTable.scss';


type ContactType = {
    createdAt: string,
    modifiedAt: string,
    contactCode: string,
    contactFirstName: string,
    contactLastName: string,
    contactEmail: string,
    status: string,
    statusUpdated: string,
}

interface PaginationType {
    direction: string,
    orderBy: string,
    page: number;
    size: number;
}


type fileItemType = {
    itemCode: string,
    itemName: string,
    itemType: string,
    size: number,
    mineType: string,
    title: string,
    createdAt: string,
    modifiedAt: string,
    owner: any,
    status: string,
    permissionName: string
}

type ContactDetailFileSharingTableProps = {
    contact: ContactType,
    getItemsSharedByMe: any,
    getItemsSharedWithMe: any,
    sharedByMeFiles: any,
    sharedWithMeFiles: any,
    selectFileToPreview: any,
    history: any,
    sharedByMeFilesFetching: boolean,
    sharedWithMeFilesFetching: boolean,
    fileListHeaders: string[],
    onFiltering: any
}

type ContactDetailFileSharingTableState = {
    value: number,
    pagination: PaginationType,
    sharedWithMeFiles: fileItemType[],
    sharedByMeFiles: fileItemType[],
    listView: boolean,
    selectedFiles: any[],
    sharedByMeInitPage: number,
    sharedWithMeInitPage: number,
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`file-share-tabpanel-${index}`}
            aria-labelledby={`file-share-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

// const columns = [
//     {
//         id: 'NAME',
//         label: 'File name',
//         sortable: true,
//         mustShow: true,
//     }, {
//         id: 'CREATED_AT',
//         label: 'Modified',
//         sortable: true,
//         mustShow: false,
//     }, {
//         id: 'Permission',
//         label: 'Permission',
//         sortable: false,
//         mustShow: true,
//     }
// ];

// const selectable = true;

// const batchAction = {
//     enable: false,
//     menu: {}
// }

// const itemAction = {
//     enable: true,
//     menu: []
// }

// const pagination = {
//     pageSize: 5,
//     pageNumber: 0
// }


class ContactDetailFileSharingTable extends Component<ContactDetailFileSharingTableProps, ContactDetailFileSharingTableState> {
    content = {
        fileTableTabOneLabel: 'SHARED BY ME',
        fileTableTabTwoLabel: 'SHARED WITH ME',
    }

    state = {
        value: 0,
        pagination: {
            direction: fsFileSharing.direction,
            orderBy: fsFileSharing.orderBy,
            page: fsFileSharing.page,
            size: fsFileSharing.size
        },
        sharedWithMeFiles: [],
        sharedByMeFiles: [],
        listView: true,
        selectedFiles: [],
        sharedByMeInitPage: 0,
        sharedWithMeInitPage: 0,
    }

    componentDidMount() {
        this.props.getItemsSharedByMe(this.props.contact.contactCode, this.state.pagination);
        this.props.getItemsSharedWithMe(this.props.contact.contactCode, 'FILE', this.state.pagination);
    }

    a11yProps = (index: any) => {
        return {
            id: `file-share-${index}`,
            'aria-controls': `file-share-${index}`,
        };
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue, selectedFiles: [] });
    };

    fileSharedWithMeDataProc = (data: fileItemType) => {

    }

    sortOnClick = (id: string) => {
        let tmp_orderBy: string = ''
        switch(id) {
            case 'fileName':
                tmp_orderBy = 'NAME';
                break;
            case 'modifiedAt':
                tmp_orderBy = 'MODIFIED_AT';
                break;
            default:
                tmp_orderBy = 'NAME'
        }

        if (tmp_orderBy === this.state.pagination.orderBy) {
            this.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    direction: prevState.pagination.direction === 'ASC' ? 'DESC' : 'ASC'
                }
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    orderBy: tmp_orderBy
                }
            }))
        }

        this.state.value === 0 ?
            this.props.getItemsSharedByMe(this.props.contact.contactCode, this.state.pagination) :
            this.props.getItemsSharedWithMe('FILE', this.state.pagination)
    }

    onFileSelection = (selectedIds: string[]) => {
        var selectedFiles = []
        if (this.state.value === 0)
            selectedFiles = this.props.sharedByMeFiles.files.filter((I: any) =>
                selectedIds.findIndex((x: string) => x === I.itemCode) > -1
            );
        else
            selectedFiles = this.props.sharedWithMeFiles.files.filter((I: any) =>
                selectedIds.findIndex((x: string) => x === I.itemCode) > -1
            );
        this.setState({ selectedFiles: selectedFiles });
    }

    onFileOpen = (file: any) => {
        this.props.selectFileToPreview(file);
        this.props.history.push(`/personal/file-detail/${file.itemCode}`);
    }

    onFolderOpen = (items: any) => {
        console.log('Folder Open')
    }

    toggleViews = () => {
        this.setState({ listView: !this.state.listView })
    }

    fetchMoreSharedByMeData = () => {
        this.setState(prevState => {
            return {sharedByMeInitPage: prevState.sharedByMeInitPage + 1}
        })

        
        setTimeout(() => {
            console.log('Fetch More Data!')
        }, 2000)
    }

    fetchMoreSharedWithMeData = () => {
        this.setState(prevState => {
            return {sharedWithMeInitPage: prevState.sharedWithMeInitPage + 1}
        })

        
        setTimeout(() => {
            console.log('Fetch More Data!')
            // this.props.getMoreItemsSharedWithMe( 'FILE', paginationNew );
        }, 2000)
    }

    render() {
        return (
            <>
                <AppBar position="static" className="share-file-tabs">
                    <Tabs className="share-file-tabs"
                        TabIndicatorProps={{ style: { height: '6px' } }}
                        textColor='primary'
                        indicatorColor='primary'
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="share-file">
                        <Tab className="tab-btn" label={
                            <span className='tab-label'>{this.content.fileTableTabOneLabel} </span>
                        } {...this.a11yProps(0)} />
                        <Tab className="tab-btn" label={
                            <span className={this.props.contact.status === 'PENDING' ? 'pending-label' : 'tab-label'}>{this.content.fileTableTabTwoLabel}</span>
                        }
                            {...this.a11yProps(1)}
                            disabled={this.props.contact.status === 'PENDING' ? true : false} />
                    </Tabs>
                    <div className="share-file-actions">
                        <CommonFileActionsBar
                            selectedFiles={this.state.selectedFiles}
                            isShareEnabled={this.state.value === 0 ? true : false}
                        />
                        <Hidden xsDown>
                            {this.state.listView &&
                                <IconButton aria-label="views" className="iconBtn-wrapper" onClick={this.toggleViews}>
                                    <GridIcon className="iconBtn" />
                                </IconButton>
                            }
                            {!this.state.listView &&
                                <IconButton aria-label="views" className="iconBtn-wrapper" onClick={this.toggleViews}>
                                    <ListViewIcon className="iconBtn" />
                                </IconButton>
                            }
                        </Hidden>
                    </div>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    { this.props.sharedByMeFiles.files.length === 0 &&
                        <div>You have not shared any files with this contact.</div>
                    }
                    { this.props.sharedByMeFiles.files.length > 0 &&
                        <div>
                            <Hidden xsDown>
                                { this.state.listView &&
                                    <CommonFileItemList 
                                        files={this.props.sharedByMeFiles.files}
                                        curSorting={this.props.sharedByMeFiles.files}
                                        filesFetching={this.props.sharedByMeFilesFetching}
                                        lazyLoading={this.props.sharedByMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreSharedByMeData}
                                        onSelection={this.onFileSelection}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        onSorting={this.sortOnClick} 
                                        showOwner={ false }
                                        showMeAsOnwer = { false }
                                        allowChangePermission={true}
                                        showPermission={true}
                                        showUploaded={false}
                                        showFileSize={false}
                                        tableMarginHeight={435} 
                                        fileListHeaders={this.props.fileListHeaders}
                                        onFiltering={this.props.onFiltering}
                                    />
                                }
                                { !this.state.listView &&
                                    <CommonFileGridList 
                                        files={this.props.sharedByMeFiles.files}
                                        filesFetching={this.props.sharedByMeFilesFetching}
                                        lazyLoading={this.props.sharedByMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreSharedByMeData}
                                        allowChangePermission={true} 
                                        onSelection={ this.onFileSelection }
                                        tableMarginMinHeight={480}
                                        tableMarginMaxHeight={480}
                                        showPermission={true}
                                        showFileSize={false}
                                        showOnwer={ false } 
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        showMeAsOnwer = { true }
                                        showUploaded={false} />
                                }
                            </Hidden>
                            <Hidden smUp>
                                <CommonFileGridList 
                                    files={this.props.sharedByMeFiles.files} 
                                    filesFetching={this.props.sharedByMeFilesFetching}
                                    lazyLoading={this.props.sharedByMeFiles.lazyLoading}
                                    fetchMoreData={this.fetchMoreSharedByMeData}
                                    allowChangePermission={true} 
                                    onSelection={ this.onFileSelection }
                                    tableMarginMinHeight={480}
                                    tableMarginMaxHeight={480}
                                    showPermission={true}
                                    showFileSize={false}
                                    showOnwer={ false }
                                    onFileOpen={this.onFileOpen}
                                    onFolderOpen={this.onFolderOpen}
                                    showMeAsOnwer = { true }
                                    showUploaded={false} />
                            </Hidden>
                        </div>
                    }
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    { this.props.sharedWithMeFiles.files.length === 0 &&
                        <div>This contact has not shared any files with you.</div>
                    }
                    { this.props.sharedWithMeFiles.files.length > 0 &&
                        <div>
                            <Hidden xsDown>
                                {this.state.listView &&
                                    <CommonFileItemList files={this.props.sharedWithMeFiles.files}
                                        filesFetching={this.props.sharedWithMeFilesFetching}
                                        lazyLoading={this.props.sharedWithMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreSharedWithMeData}
                                        onSelection={this.onFileSelection} 
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        onSorting={this.sortOnClick} 
                                        showOwner={ false }
                                        showMeAsOnwer = { false }
                                        allowChangePermission={ false }
                                        showPermission={true}
                                        showUploaded={false}
                                        showFileSize={false}
                                        tableMarginHeight={435} 
                                        fileListHeaders={this.props.fileListHeaders}
                                        onFiltering={this.props.onFiltering}    
                                    />
                                }
                                {!this.state.listView &&
                                    <CommonFileGridList 
                                        files={this.props.sharedWithMeFiles.files} 
                                        filesFetching={this.props.sharedWithMeFilesFetching}
                                        lazyLoading={this.props.sharedWithMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreSharedWithMeData}
                                        allowChangePermission={ false } 
                                        onSelection={ this.onFileSelection }
                                        tableMarginMinHeight={480}
                                        tableMarginMaxHeight={480}
                                        showPermission={true}
                                        showFileSize={false}
                                        showOnwer={ false } 
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        showMeAsOnwer = { true }
                                        showUploaded={false} />
                                }
                            </Hidden>
                            <Hidden smUp>
                                <CommonFileGridList 
                                    files={this.props.sharedWithMeFiles.files}
                                    filesFetching={this.props.sharedWithMeFilesFetching}
                                    lazyLoading={this.props.sharedWithMeFiles.lazyLoading}
                                    fetchMoreData={this.fetchMoreSharedWithMeData}
                                    allowChangePermission={ false } 
                                    onSelection={ this.onFileSelection }
                                    tableMarginMinHeight={480}
                                    tableMarginMaxHeight={480}
                                    showPermission={true}
                                    showFileSize={false}
                                    showOnwer={ false } 
                                    onFileOpen={this.onFileOpen}
                                    onFolderOpen={this.onFolderOpen}
                                    showMeAsOnwer = { true }
                                    showUploaded={false} />
                            </Hidden>
                        </div>
                    }
                </TabPanel>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        sharedByMeFiles: state.fileSharingReducer.itemsSharedByMe,
        sharedWithMeFiles: state.fileSharingReducer.itemsSharedWithMe,
        sharedByMeFilesFetching: state.fileSharingReducer.itemsSharedByMe.fetching,
        sharedWithMeFilesFetching: state.fileSharingReducer.itemsSharedWithMe.fetching
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getItemsSharedByMe: (userCode: string, pagination: any) => dispatch({
            type: ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST,
            userCode: userCode,
            pagination: pagination
        }),
        getItemsSharedWithMe: (userCode: string, itemType: any, pagination: any) => dispatch({
            type: ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_REQUEST,
            userCode: userCode,
            itemType: itemType,
            pagination: pagination
        }),
        selectFileToPreview: (item: any) => dispatch({ type: ContactDetailActionTypes.PREVIEW_FILE, selectedFile: item })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactDetailFileSharingTable);