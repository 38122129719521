//Import for IE  
import 'react-app-polyfill/ie11';
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import { con } from './shared/config/config'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { Router } from 'react-router-dom';
import { history } from './shared/core/history';
import { ThroughProvider } from 'react-through';
import ReactGA from 'react-ga';
import store from './index-store';

if ((con.ENVIRONMENT === 'PROD' || con.ENVIRONMENT === 'PRODBLUE') && con.GA_ID_PROD) {
  ReactGA.initialize(con.GA_ID_PROD);
}
else if ((con.ENVIRONMENT === 'QA' || con.ENVIRONMENT === 'QABLUE') && con.GA_ID_QA) {
  ReactGA.initialize(con.GA_ID_QA);
}
else if ((con.ENVIRONMENT === 'DEV' || con.ENVIRONMENT === 'LOCAL') && con.GA_ID_DEV) {
  ReactGA.initialize(con.GA_ID_DEV);
}


if ((con.ENVIRONMENT === 'PROD' && con.GA_ID_PROD) || (con.ENVIRONMENT === 'QA' || con.ENVIRONMENT === 'DEV')) {
  history.listen((location, action) => {
    ReactGA.pageview(location.pathname)
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router forceRefresh={true} history={history}>
      <ThroughProvider>
        <App />
      </ThroughProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change 
// unregister() to register() below. Note this comes with some pitfalls. 
// Learn more about service workers: http://bit.ly/CRA-PWA 
serviceWorker.register();

