import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as TopicFollowUsersActionTypes from '../../../../../redux/constants/customerVault/topicFollowConstants';
import { connect } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import { CommonModal } from '../../../../../shared/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import formIcon from '../../../../../assets/smart-form-icon.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type smartIntakeProps = {
    classes: any;
    smartIntake: any;
    onClose: any;
    item: any;
}

export const styles = (theme: any) => ({
    pagewrapper: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#505050',
        zIndex: 1500,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    actionBar: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#333638',
        color: 'white',
        fontSize: 14,
        fontWeight: 700,
        padding: '1rem',
        position: 'fixed' as 'fixed',
        top: 0,
        width: 'calc(100% - 2rem)',
        zIndex: 99
    },
    btnIcon: {
        color: 'white'
    },
    actionTitle: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
    },
    typeIcon: {
        marginRight: '.5em'
    },
    iframeEle:
    {
        marginTop: '80px',
        width: '100%',
        minHeight: '100%',
        flexGrow: 1,
        display: 'flex',
        backgroundColor: '#505050'
    }


});

class SmartIntake extends Component<smartIntakeProps> {

    componentDidMount() {
    }

    handleActionDialogClose = () => {
        this.setState({ isModalOpen: false })
    }

    render() {
        const { classes } = this.props;
        return (
            <div data-test='smartintake-component' className={classes.pagewrapper}>
                <div className={classes.actionBar}>
                    <div className={classes.actionTitle}>
                        <IconButton data-test='files-btn' onClick={this.props.onClose} aria-label='go back'>
                            <ArrowBackIcon className={classes.btnIcon} />
                        </IconButton>
                        <span>{this.props.item != null ? this.props.item.itemName : ''}</span>
                    </div>
                </div>
                {this.props.smartIntake.userLink != null ?
                    <div className={classes.iframeEle} dangerouslySetInnerHTML={{ __html: `<iframe style='width:100%' frameborder="0" src='${this.props.smartIntake.userLink}' />` }} />
                    : this.props.smartIntake.error != null ?
                        <div> Error in opening the file</div>
                        : <CircularProgress data-test="circularProgress" style={{ position: 'absolute', top: '40%', left: '50%' }} ></CircularProgress>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        smartIntake: state.topicFilesReducer.smartIntake,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SmartIntake));










