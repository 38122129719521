import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import './Uploader.scss';
import * as TopicFileActionTypes from '../../../../../redux/constants/customerVault/topicFileConstants';
import ReactGA from 'react-ga';
import * as GAConstants from '../../../../../shared/core/GAConstants';

type UploaderProps = {
    UploadFile: any;
    FileNotSupported: any;
    topic: any;
    uploaderkey: any;
    onFileUploadAction: any;
    children?: never[];
    features: any;
    isEndUser: any;
    selectedGroup: any;
    user_sid?: any;
}

type UploaderStates = {
    files: any;
    isDragEnter: any;
    maxFileSize: any;
    allowedFiles: any;
    isAutoSubmit: any;
}

const extRegexp = /^(?!\.[^.]+\.$).+\.(?:bat|cmd|exe|jar|js|sh|ade|adp|apk|appx|appxbundle|cab|chm|com|cpl|dll|dmg|hta|ins|isp|iso|jar|jse|lib|lnk|mde|msc|msi|msix|msixbundle|msp|mst|nsh|pif|ps1|scr|sct|shb|sys|vb|vbe|vbs|vxd|wsc|wsfwsh)$/;
const maxFileSize = 209715200;

class Uploader extends Component<UploaderProps, UploaderStates> {

    state = {
        files: [],
        isDragEnter: false,
        maxFileSize: maxFileSize,
        allowedFiles: [] as any,
        isAutoSubmit: false
    };

    uploader = createRef<HTMLInputElement>();

    componentDidUpdate(prevProps: any) {
        if (prevProps.topic != this.props.topic && this.props.topic != null) {
            this.validateFiles();
        }
    }

    componentDidMount() {
        if (this.props.topic != null && this.props.topic.categoryCode != null) {
            this.validateFiles();
        }
    }

    validateFiles() {
        const { features } = this.props;
        let that = this;
        let autoSubmit = false;
        if (Array.isArray(this.props.features.isTopicSubmitRequired) && this.props.features.isTopicSubmitRequired.length) {
            this.props.features.isTopicSubmitRequired.map(function (element: any) {
                //Check the topic is of Autosubmit category/group
                if (element.categoryCode != null && element.categoryCode == that.props.topic.categoryCode)
                    autoSubmit = true;
                else if (element.groupCode != null && element.groupCode == that.props.selectedGroup.grpCode)
                    autoSubmit = true;
            });
        }
        that.setState({ isAutoSubmit: autoSubmit });
        if (Array.isArray(features.allowedFiles) && features.allowedFiles.length) {
            let fileMimeTypes = '';
            let fileSize = this.state.maxFileSize;
            features.allowedFiles.map(function (element: any) {
                let key = Object.keys(element)[0]
                if (key === 'fileMimeTypes')
                    fileMimeTypes = element[key];
                if (key === 'maxFileSize')
                    fileSize = element[key];
            });
            const maxSize = Math.min(fileSize, maxFileSize);
            this.setState({ maxFileSize: maxSize });
            this.setState({ allowedFiles: fileMimeTypes });
        }
    }


    uploaderOnClick = (event: any) => {
        if (this.uploader.current != null)
            this.uploader.current.click();
    }

    onFileDrop = (e: React.DragEvent<unknown>) => {
        e.preventDefault();
        let files = e.dataTransfer ? e.dataTransfer.files : null
        this.uploadfilesUI(files);
    };

    fileTypeAcceptable(file: any) {
        let fileExtension = this.fileExtension(file)
        fileExtension = "file." + fileExtension
        return null != fileExtension.match(extRegexp) ? false : true;
    }

    fileExtension(file: any) {
        let extensionSplit = file.name.split('.')
        if (extensionSplit.length > 1) {
            return extensionSplit[extensionSplit.length - 1]
        } else {
            return 'none'
        }
    }

    fileSizeAcceptable(file: any) {
        return file.size <= this.state.maxFileSize ? true : false;
    }

    fileMimeTypeAcceptable(file: any) {
        if (this.state.allowedFiles.length > 0) {
            return this.state.allowedFiles.includes(file.type);
        }
        return true;

    }

    uploadOnChange = (e: any) => {
        e.preventDefault();
        let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
        this.uploadfilesUI(files);
        e.target.value = '';
    }

    uploadfilesUI = (files: any) => {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let isFileTypeAccepted = this.fileTypeAcceptable(file);
            let isFileSizeAccepted = this.fileSizeAcceptable(file);
            let isFileMimeTypeAccepted = this.fileMimeTypeAcceptable(file);

            if (isFileTypeAccepted && isFileSizeAccepted && isFileMimeTypeAccepted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        files: [...prevState.files, file]
                    }
                })
                if (this.state.isAutoSubmit)
                    this.props.UploadFile(this.props.topic.stCode, file, Math.floor(Math.random() * 1000), this.props.user_sid, true)
                else
                    this.props.UploadFile(this.props.topic.stCode, file, Math.floor(Math.random() * 1000), this.props.user_sid)
            }
            else {
                if (!isFileTypeAccepted) {
                    this.props.FileNotSupported(file, i, "Sorry, this file type is not permitted for security reasons.")
                    if (this.props.isEndUser)
                        ReactGA.event({
                            category: GAConstants.USER_EVENT_CATEGORY,
                            action: GAConstants.FILE_UPLOAD_ERROR,
                            label: this.fileExtension(file)
                        });
                    else ReactGA.event({
                        category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                        action: GAConstants.FILE_UPLOAD_ERROR,
                        label: this.fileExtension(file)
                    });
                }
                else if (!isFileSizeAccepted) {
                    this.props.FileNotSupported(file, i, "This file can't be uploaded because it exceeds the file size limit.")
                    if (this.props.isEndUser)
                        ReactGA.event({
                            category: GAConstants.USER_EVENT_CATEGORY,
                            action: GAConstants.FILE_UPLOAD_ERROR,
                        });
                    else ReactGA.event({
                        category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                        action: GAConstants.FILE_UPLOAD_ERROR,
                    });
                }
                else if (!isFileMimeTypeAccepted) {
                    this.props.FileNotSupported(file, i, "This file type is not supported.")
                    if (this.props.isEndUser)
                        ReactGA.event({
                            category: GAConstants.USER_EVENT_CATEGORY,
                            action: GAConstants.FILE_UPLOAD_ERROR,
                            label: file.type
                        });
                    else ReactGA.event({
                        category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                        action: GAConstants.FILE_UPLOAD_ERROR,
                        label: file.type
                    });
                }
            }
        }
        if (files.length > 0)
            this.props.onFileUploadAction()
    }

    onDragOver(event: any) {
        event.preventDefault()
        event.stopPropagation()
    }

    render() {
        return (
            <div className="uploader-wrapper" data-test='uploaderComponent'>
                <div key={this.props.uploaderkey} className={this.state.isDragEnter ? 'uploader-on-hover' : 'uploader'}
                    data-test='uploadBtn'
                    onClick={(event) => this.uploaderOnClick(event)}
                    onDragEnter={(event) => this.onDragOver(event)}
                    onDragLeave={() => this.setState({ isDragEnter: false })}
                    onDragOver={(event) => this.onDragOver(event)}
                    onDrop={(e) => this.onFileDrop(e)}>
                    <form>
                        <input type="file"
                            name="xvxcv"
                            ref={this.uploader}
                            data-test='uploadBox'
                            id={`${this.props.uploaderkey}files`}
                            onChange={(e) => this.uploadOnChange(e)}
                            multiple
                            style={{ display: 'none' }} />
                        <label>
                            <span>Drag a file here or <a className="uploader-link">browse</a> for a file to upload</span>
                        </label>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        features: state.homeReducer.features,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        user_sid: state.login.user_sid

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        UploadFile: (topicCode: any, file: any, fileIndex: any,user_sid: string, isSubmitRequired?: boolean) => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_API_UPLOAD_FILE_REQUEST,
            topicCode: topicCode,
            file: file,
            fileId: fileIndex,
            user_sid: user_sid,
            isSubmitRequired: isSubmitRequired
        }),
        FileNotSupported: (file: any, fileIndex: any, errMsg: any) => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_UPLOAD_ADD_FILE,
            file: file,
            fileId: fileIndex,
            errMsg: errMsg
        })

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploader);
