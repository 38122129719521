import React, { Component } from 'react';
import { Divider, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';

type ResetPasswordSuccessProps = {
    onLogin: any;
    classes: any;
    username: any;
}

export const styles = (theme: any) => ({
    mainContainer:
    {
        maxWidth: '420px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none'
        }
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
    },
    title: {
        fontSize: '1.9rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(2),
        fontWeight: 'lighter' as 'lighter'
    },
    description: {
        fontSize: '0.875rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(1),
        fontWeight: 300
    },
    inputContainer:
    {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'left',
        alignItems: 'center',
        padding: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            alignItems: 'left',
            flexDirection: 'column' as 'column',
        },
    },
    nameLabel:
    {
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'center',
        fontFamily: theme.homefontfamilybold,
        fontSize: '0.9rem',
        width: '20%',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            justifyContent: 'flex-start',
            width: '100%',
        }
    },
    inputtextField:
    {
        width: '100%',
        marginLeft: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            marginRight: theme.spacing(4)
        }
    },
    fabBtn: {
        width: 120,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
    row: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-evenly',
        paddingTop: theme.spacing(5)
    },
    errorMessage: {
        color: 'red',
        fontSize: '13px',
        textAlign: 'center' as 'center'
    },
});

class ResetPasswordSuccess extends Component<ResetPasswordSuccessProps> {

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.mainContainer} data-test="resetPasswordSuccess">
                <div className={classes.titleContainer}>
                    <div className={classes.title}>You've successfully reset your password</div>
                    <div className={classes.description}>Use this login information to access fidsafe.</div>
                </div>
                <Divider variant="middle" />
                <div className={classes.inputContainer}>
                    <div className={classes.nameLabel}>Your Email</div>
                    <div className={classes.inputtextField}>
                        <div>
                            {this.props.username}
                        </div>
                    </div>
                </div>
                <Divider variant="middle" />
                <div className={classes.row}>
                    <Fab variant='extended'  data-test='submitButton' color='primary' className={classes.fabBtn} onClick={this.props.onLogin} >
                        Submit
                    </Fab>
                </div>
            </Paper >)
    }
}


export default (withStyles(styles)(ResetPasswordSuccess));