import { combineReducers } from  'redux';
import appReducer from './shared/appRedux';
import login from './shared/loginUserRedux'
import legalsReducer from './shared/legalsRedux';
import themeReducer from './shared/themeRedux'
import featureReducer from './shared/featureRedux';
import itemsReducer from './personalVault/itemsRedux';
import contactsReducer from './personalVault/contactsRedux';
import invitesReducer from './personalVault/invitationRedux';
import shareContactReducer from './personalVault/shareContactRedux';
import fileSharingReducer from './personalVault/contactDetailRedux';
import allShareItemsReducer from './personalVault/shareItemsRedux';
import myFilesReducer from './personalVault/myFilesRedux';
import appUIStateReducer from './shared/appStatesRedux';
import inviteVerificationReducer from './shared/inviteVerificationRedux';
import cognitoLoginReducer from './shared/cognitoLoginRedux';
import topicReducer from './customerVault/topicRedux';
import userReducer from './customerVault/userRedux';
import topicFilesReducer from './customerVault/topicFilesRedux';
import filePreviewReducer from './shared/filePreviewRedux';
import eSignReducer from './shared/eSignRedux';
import topicFollowReducer from './customerVault/topicFollowRedux';
import instUserReducer from './customerVault/instUserRedux';

//Retiring
import homeReducer from  '../../retired/home/homeRedux';
import pageComponentsReducer from './shared/pageComponentsRedux';
import userDetailsReducer from './shared/UserDetailsRedux';



const  IndexReducer  =  combineReducers({
    login,
    homeReducer,
    appReducer,
    legalsReducer,
    themeReducer,
    featureReducer,
    appUIStateReducer,
    itemsReducer,
    contactsReducer,
    invitesReducer,
    shareContactReducer,
    fileSharingReducer,
    allShareItemsReducer,
    cognitoLoginReducer,
    myFilesReducer,
    inviteVerificationReducer,
    topicReducer,
    userReducer,
    topicFilesReducer,
    pageComponentsReducer,
    userDetailsReducer,
    filePreviewReducer,
    eSignReducer,
    topicFollowReducer,
    instUserReducer
})
export  default  IndexReducer