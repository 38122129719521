import React, { Component } from 'react';
import { content_type, UserContent, TableData, TableDataCell } from "../shared/contactListTypes";
import { ItemList } from '../../../../../shared/ui';
import * as ContactListActions from '../../../../../redux/constants/customerVault/userConstants';
import { connect } from 'react-redux';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import { withRouter } from 'react-router';
import './ContactList.scss';
import { AppBar, TextField, List, ListItem, ListItemText, CircularProgress, LinearProgress, TablePagination, Paper } from '@material-ui/core';
import debounce from 'lodash/debounce';
import * as GAConstants from '../../../../../shared/core/GAConstants';
import ReactGA from 'react-ga';
import TablePaginationActions from '../../../../../shared/ui/tablePaginationActions/TablePaginationActions';

type SearchedContactType = {
  userCode: string,
  firstName: string,
  lastName: string,
  userStatus: string,
  email: string
}

type ContactListProps = {
  error?: any,
  fetchContacts?: any,
  contacts: any,
  contactsFetching: any,
  addBreadcrumbItem: any,
  history: any,
  groupContacts: any,
  lazyLoading?: boolean,
  loadMoreContacts: any,
  loadMoreContactsStart: any,
  selectedGroup: any,
  groupsInfo: any,
  groupsFetching: any,
  groupError: any,
  addTopic?: any,
  searchContact: any
  searchedContact: any,
  clearSearchContent: any,
  loggedIn: any
}

const NO_CONTACTS_MSG = 'There are no contacts yet.';


class ContactList extends Component<ContactListProps> {
  state = {
    selectable: false,
    batchAction: {
      enable: false,
      menu: []
    },
    itemAction: {
      enable: true,
      // menu: itemActionMenuProc()
      menu: []
    },
    pagination: {
      page: 0,
      size: 20,
      direction: "ASC",
      orderBy: "NAME"
    },
    searchText: '',
    searchContext: {
      userCode: '',
      firstName: '',
      lastName: '',
      userStatus: ''
    },
    selectedOptionIndex: -1,
    windowWidth: window.innerWidth
  }

  componentDidMount() {
    //fecth contacts only if user is successfully loggedin
    if (this.props.loggedIn.successful) {
      this.props.fetchContacts('', this.state.pagination);
    }
    this.searchContact = debounce(this.searchContact, 500);
    document.addEventListener('keydown', this.selectOptionByKeys);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.selectOptionByKeys);
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate() {
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    })
  }

  columns = [
    {
      id: 'NAME',
      label: 'Contact Name',
      sortable: true,
      mustShow: true,
    }, {
      id: 'CREATED_AT',
      label: 'Added',
      sortable: true,
      mustShow: true,
    }, {
      id: 'TOPIC',
      label: 'Topics',
      sortable: false,
      mustShow: false,
      align: 'right'
    }
  ];

  contactsDataProc = (data: UserContent[]) => {
    let filteredContacts: TableData[] = [];
    data.forEach((el) => {
      const cell1: TableDataCell = {
        content: {
          s1: {
            type: content_type.LINK,
            content: {
              data: el.lastName + ', ' + el.firstName,
              link: `/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/${el.userCode}`
            },
            bold: true
          },
          s2: {
            type: content_type.ICON,
            content: el.status === 'PENDING' ? 'PENDING' : 'ACTIVE',
            bold: false
          },
          s3: {
            type: content_type.DATE,
            content: el.addedToGroup,
            bold: false
          },
          s4: {
            type: content_type.TEXT,
            content: '',
            bold: false
          }
        },
        resizeShown: true
      }

      const cell2: TableDataCell = {
        content: {
          s1: {
            type: content_type.DATE,
            content: el.addedToGroup,
            bold: false
          },
          s2: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s3: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s4: {
            type: content_type.TEXT,
            content: '',
            bold: false
          }
        },
        resizeShown: false
      }

      const cell3: TableDataCell = {
        content: {
          s1: {
            type: content_type.TEXT,
            content: el.connectedTopics,
            bold: false
          },
          s2: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s3: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s4: {
            type: content_type.TEXT,
            content: '',
            bold: false
          }
        },
        resizeShown: false,
        align: 'right'
      }

      filteredContacts.push({
        id: el.userCode,
        cells: [
          cell1,
          cell2,
          cell3
        ]
      })
    })

    return filteredContacts;
  }

  itemOnClick = (value: any) => {
    var selectedContact = this.props.contacts.content.filter((x: any) => x.userCode === value.itemId);
    switch (value.eventId) {
      case "addTopic":
        this.props.addTopic(selectedContact);
        break;
      default:
        break;
    }
  }

  rowOnClick = (value: any) => {
    this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/${value.id}`);
    ReactGA.event({
      category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
      action: GAConstants.CONTACT_SELECTED,
    });
  }

  sortingOnClick = (id: string) => {
    switch (id) {
      case 'NAME':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "NAME"
          }
        }, () => {
          this.props.fetchContacts('', this.state.pagination);
        })
        break;
      case 'MODIFIED_AT':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "MODIFIED_AT"
          }
        }, () => {
          this.props.fetchContacts('', this.state.pagination);
        })
        break;
      case 'CREATED_AT':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "CREATED_AT"
          }
        }, () => {
          this.props.fetchContacts('', this.state.pagination);
        })
        break;
      case 'TOPIC':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "TOPIC"
          }
        }, () => {
          this.props.fetchContacts('', this.state.pagination);
        })
        break;
    }
  }

  batchAction = {
    enable: false,
    menu: []
  }

  procActionMenu = (data: UserContent[]) => {
    let action_menu: any[] = [];
    data.forEach((el) => {
      action_menu.push({
        id: el.userCode,
        menu: [
          {
            id: 'addTopic',
            label: 'Add a Topic'
          }
        ]
      })
    })

    return {
      enable: true,
      menu: action_menu
    };
  }

  searchContact = (val: string) => {
    this.props.searchContact(val);
  }

  filterContact = (val: string) => {
    let val_updated = val.toString().toLocaleLowerCase().trim().replace(/[0-9]/g, '').replace(/[^\w\s]/gi, '');
    if (val_updated !== '') {
      this.setState({ searchText: val_updated });
      this.searchContact(val_updated);
    } else {
      this.setState({ searchText: '', selectedOptionIndex: -1 });
      this.props.clearSearchContent();
    }
  }

  optionOnSelect = (contact: SearchedContactType) => {
    this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/${contact.userCode}`);
    this.setState({ searchText: '', selectedOptionIndex: -1 });
    //this.props.clearSearchContent();
  }

  searchBarOnBlur = () => {
    setTimeout(() => {
      this.setState({ searchText: '', selectedOptionIndex: -1 });
      this.props.clearSearchContent();
    }, 500)
  }

  selectOptionByKeys = (e: any) => {
    const len = this.props.searchedContact.contactsData.length;
    if (this.props.searchedContact.contactsData.length > -1) {
      switch (e.keyCode) {
        case 38:
          this.state.selectedOptionIndex > 0 ?
            this.setState({ selectedOptionIndex: this.state.selectedOptionIndex - 1 }) :
            this.setState({ selectedOptionIndex: len - 1 })
          break;
        case 40:
          this.state.selectedOptionIndex < len - 1 ?
            this.setState({ selectedOptionIndex: this.state.selectedOptionIndex + 1 }) :
            this.setState({ selectedOptionIndex: -1 })
          break;
        case 13:
          if (this.state.selectedOptionIndex !== -1) {
            this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/${this.props.searchedContact.contactsData[this.state.selectedOptionIndex].userCode}`);
            this.setState({ searchText: '', selectedOptionIndex: -1 });
            this.props.clearSearchContent();
          }
          break;
      }
    }
  }

  handlePageChange = (event: any, pageNumber: any) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: pageNumber
        }
      }, () => {
        this.props.fetchContacts('', this.state.pagination);
      });
  }

  handleRowsPerPageChange = (event: any) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          size: event.target.value,
          page: 0
        }
      }, () => {
        this.props.fetchContacts('', this.state.pagination);
      });
  }

  getDisplayedRows: ({ from, to, count }: { from: number, to: number, count: any }) => string = ({ from, to, count }) => {
    return (count && !this.props.contactsFetching) ? `${from}-${to} of ${count} total contacts` : '';
  }

  getDisplayedRowsParams = () => {
    const count = this.props.contacts.page.totalElements;
    const page = this.props.contacts.page.pageNumber;
    const rowsPerPage = this.state.pagination.size;
    const from = count === 0 ? 0 : page * rowsPerPage + 1;
    const to = count !== -1 ? Math.min(count, (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage;

    return { from, to, count };
  }



  render() {
    return (
      <div className='contact-list-wrapper' data-test='contactListWrapper'>
        {this.props.contacts.content.length > 0 &&
          <AppBar position='static' className='contact-filter-wrapper' data-test='withdata'>
            <TextField id="contact-filter"
              className="search-text-field"
              label="Search Contact..."
              variant="outlined"
              type="search"
              margin="normal"
              autoComplete='off'
              inputProps={{ pattern: "[a-z]{1,100}" }}
              value={this.state.searchText}
              onChange={event => {
                const { value } = event.target;
                this.filterContact(value)
              }}
              onBlur={this.searchBarOnBlur}
            />
            <div className='total-count-label-wrapper'>
              <p className='MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit total-count-label'>
                {this.getDisplayedRows(this.getDisplayedRowsParams())}
              </p>
            </div>
          </AppBar>
        }
        {this.props.contacts.content.length > 0 &&
          <Paper>
            <ItemList data={this.contactsDataProc(this.props.contacts.content)}
              data-test='itemlist'
              dataFetching={this.props.contactsFetching}
              columns={this.columns}
              selectable={false}
              rowOnClick={this.rowOnClick}
              batchAction={this.batchAction}
              itemAction={this.procActionMenu(this.props.contacts.content)}
              pagination={null}
              itemOnClick={this.itemOnClick}
              sortingOnClick={this.sortingOnClick}
              // tableMarginHeight={this.state.windowWidth < 820 ? 31 : 305}   //For future reference
              tableMarginHeight={0}
            />
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              labelRowsPerPage="Results per page:"
              count={this.props.contacts.page.totalElements}
              rowsPerPage={this.state.pagination.size}
              page={this.props.contacts.page.pageNumber}
              component="div"
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              data-test="tablepagination"
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={this.getDisplayedRows}
            />
          </Paper>
        }
        {this.state.searchText !== '' && this.props.searchedContact.contactsData.length === 0 && this.props.searchedContact.fetching &&
          <div className="search-result-panel" data-test='nosearchData'>
            <List component="nav" aria-label="search-contact-item">
              <ListItem className="loading-item-row">
                <CircularProgress size={20} />
              </ListItem>
            </List>
          </div>
        }
        {this.props.searchedContact.contactsData.length !== 0 &&
          <div className="search-result-panel" data-test='searchContact'>
            <List component="nav" aria-label="search-contact-item" >
              {this.props.searchedContact.contactsData.map((contact: SearchedContactType, index: number) => (
                <ListItem key={contact.userCode} id={contact.userCode} button onClick={() => this.optionOnSelect(contact)} className={index === this.state.selectedOptionIndex ? 'search-item-active' : 'search-item'}>
                  <ListItemText primary={contact.lastName + ', ' + contact.firstName} secondary={contact.email} />
                </ListItem>
              ))}
            </List>
          </div>
        }
        {this.props.contacts.content.length <= 0 && !this.props.contactsFetching &&
          <AppBar position='static' className='contact-filter-wrapper' data-test='noData'>
            <p style={{ 'padding': '1em', 'margin': 0, 'color': '#000' }}>{NO_CONTACTS_MSG}</p>
          </AppBar>
        }
        {this.props.contacts.content.length <= 0 && this.props.contactsFetching &&
          <>
            <AppBar position='static' className='contact-filter-wrapper' data-test='noData'>
              <p style={{ 'padding': '1em', 'margin': 0, 'color': '#000' }}>
              </p>
            </AppBar>
            <LinearProgress data-test='dataIsFetchingWrapper' />
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    selectedGroup: state.appUIStateReducer.selectedGroup,
    contacts: state.userReducer.groupContacts.contactsData,
    contactsFetching: state.userReducer.groupContacts.fetching,
    groupContacts: state.userReducer.groupContacts,
    groupsInfo: state.homeReducer.groupsInfo.response,
    groupsFetching: state.homeReducer.groupsInfo.fetching,
    groupError: state.homeReducer.error,
    searchedContact: state.userReducer.searchContacts,
    loggedIn: state.login
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchContacts: (pattern: any, pagination: any) => dispatch({ type: ContactListActions.USER_API_GET_USERS_REQUEST, pattern, pagination }),
    loadMoreContacts: (pattern: any, pagination: any) => dispatch({ type: ContactListActions.USER_API_LOAD_MORE_USERS_REQUEST, pattern, pagination }),
    loadMoreContactsStart: () => dispatch({
      type: ContactListActions.USER_API_LOAD_MORE_USERS_START,
    }),
    addBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
      type: AppUIStateActionTypes.ADD_BREADCRUMB_ITEM,
      itemName: itemName,
      itemLink: itemLink
    }),
    searchContact: (searchString: string) => dispatch({
      type: ContactListActions.USER_API_CONTACT_SEARCH_REQUEST,
      searchString: searchString
    }),
    clearSearchContent: () => dispatch({
      type: ContactListActions.USER_API_CLEAR_SEARCH_REQUEST,
    })
  };
};

export const ContactListComponent = connect(mapStateToProps, mapDispatchToProps)(ContactList);

export default withRouter(ContactListComponent);