import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { styles } from './RenameItemStyles';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import { CREATE_FOLDER_API_CREATE_REQUEST, FOLDER_API_UPDATE_ITEM } from '../../../../../redux/constants/personalVault/itemsConstants';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type RenameItemProps = {
    selectedItem: any,
    isRename: any,
    modalOpen: any,
    handleModalClose: any,
    currentFolder: any,
    onActionComplete: any,
    addFolderResponse: any,
    addFolderProgress: any,
    addFolderError: any,
    updateItemResponseError: any,
    updateItemProgress: any,
    createFolderAPI: any,
    updateItemAPI: any,
    classes: any,
    children: never[]
}

class RenameItem extends Component<RenameItemProps> {

    state = {
        headerTitle: 'Add a Folder',
        actionButton: 'Add',
        characterLimit: 50,
        errorMessage: '',
        itemType: '',
        allowedName: '^[a-zA-Z0-9 ()/@&\'".,-]*$',
        folderName: '',
        folderCode: null
    }


    componentDidMount() {
        const { selectedItem, isRename } = this.props;
        if (selectedItem != null && isRename) {
            let itemType = selectedItem.itemType;
            let itemName = selectedItem.itemName;
            let itemCode = selectedItem.itemCode;
            switch (itemType) {
                case 'FOLDER': this.setState({ headerTitle: 'Rename folder', itemType: 'folder' }); break;
                case 'FILE': this.setState({ headerTitle: 'Rename file', itemType: 'file' }); break;
            }
            this.setState({ folderName: itemName, folderCode: itemCode, actionButton: 'Save' })
        }
    }

    componentDidUpdate(prevProps: any) {
        const { addFolderResponse, addFolderProgress, updateItemResponseError, updateItemProgress } = this.props;
        if (prevProps.updateItemProgress !== updateItemProgress && updateItemProgress === false) {
            if (updateItemResponseError) {
                const errCode = updateItemResponseError.data.errCode
                this.setErrorMessage(errCode);
            }
            else {
                if (this.state.itemType === 'folder')
                    this.props.onActionComplete(true, `Your folder has been renamed to ${this.state.folderName}.`);
                else
                    this.props.onActionComplete(true, `Your file has been renamed to ${this.state.folderName}.`);
            }
        }

        if (prevProps.addFolderProgress !== addFolderProgress && addFolderProgress === false) {
            if (addFolderResponse != null) {
                this.props.onActionComplete(true, addFolderResponse.itemName);
            } else {
                if (this.props.addFolderError) {
                    const errCode = this.props.addFolderError.data.errCode
                    this.setErrorMessage(errCode);
                }
            }
        }
    }


    setErrorMessage = (errCode: any) => {
        switch (errCode) {
            case 675:
                this.setState({ errorMessage: 'A ' + this.state.itemType + ' with same name already exists' });
                break;
            default:
                this.setState({ errorMessage: 'There was an issue. Please try again' })
        }
    }

    handleCancelClick = (event: any) => {
        this.props.onActionComplete(false);
    }

    renameFolder = (newFolderName: any) => {
        this.props.updateItemAPI(newFolderName, this.state.folderCode, this.props.currentFolder.itemCode, '')
    }

    handleSaveClick = () => {
        const { isRename } = this.props;
        var error = 0
        if (this.state.folderName.length > this.state.characterLimit) {
            error = 1
            this.setState({ errorMessage: "Folder name cannot be longer than 50 characters" })
        }
        else if (!this.state.folderName.match(this.state.allowedName)) {
            error = 1
            this.setState({ errorMessage: "This folder name is not allowed" })
        }
        else {
            if (!isRename)
                this.createFolder(this.state.folderName);
            else
                this.renameFolder(this.state.folderName)
        }

    }
    handleChange = (event: any) => {
        this.setState({ errorMessage: '' })
        this.setState({ folderName: event.target.value });
    };

    createFolder(folderName: any) {
        this.props.createFolderAPI(folderName, this.props.currentFolder)
    }

    render() {
        const { classes } = this.props;
        return (
            <Modal
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.modalOpen}
                onClose={this.props.handleModalClose}
            >
                <div className={classes.createFolderModalView}>
                    <div className={classes.paper}>
                        <div className={classes.heading}>
                            <div>
                                <h2 className={classes.folderText}>{this.state.headerTitle}</h2>
                                <IconButton aria-label="close-btn" className={classes.closeBtn} onClick={this.handleCancelClick}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </div>
                            <div className={classes.errorMessage}>{this.state.errorMessage}</div>
                        </div>
                        <div>
                            <div className={classes.inputRoot}>
                                <p style={{ height: '0px' }}></p>
                                <TextField id="outlined-basic" label="New Folder" variant="outlined" value={this.state.folderName}
                                    onChange={this.handleChange} autoFocus />
                            </div>
                        </div>
                        <div className={classes.buttons}>
                            <Button onClick={this.handleCancelClick} className={classes.buttonCancel}>Cancel</Button>
                            <Button className={classes.buttonSave} onClick={this.handleSaveClick} variant="contained" color="primary">{this.state.actionButton}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        addFolderResponse: state.itemsReducer.createdFolderInfo.response,
        addFolderError: state.itemsReducer.createdFolderInfo.error,
        addFolderProgress: state.itemsReducer.createdFolderInfo.fetching,
        updateItemProgress: state.itemsReducer.updateItem.updating,
        updateItemResponseError: state.itemsReducer.updateItem.error,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createFolderAPI: (folderName: any, currentFolderCode: any) => dispatch({ type: CREATE_FOLDER_API_CREATE_REQUEST, folderName, currentFolderCode }),
        updateItemAPI: (itemName: any, itemCode: any, destinationCode: any, description: any) => dispatch({ type: FOLDER_API_UPDATE_ITEM, itemName, itemCode, destinationCode, description }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RenameItem));
