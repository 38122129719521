export const styles = (theme: any) => ({

    root:
    {
        backgroundColor: '#fff',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        paddingBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 90,
    },
    signUp:
    {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        width: 1024,
        fontFamily: theme.homefontfamilyregular,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),
       
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : landscape)`]: {
            marginLeft: '0px',
            width: '100%'
        },
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : portrait)`]: {
            marginLeft: '0px',
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: '100%'
        },
    },
    title: {
        fontSize: '2rem',
        fontFamily: theme.homefontfamilylight,
        display: 'flex',
        justifyContent: 'left',
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
    },
    description: {
        fontSize: '1.3rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        fontWeight: 300
    },
    subheader: {
        fontSize: '1.5rem',
        display: 'flex',
        justifyContent: 'left',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        fontFamily: theme.homefontfamilyregular
    },
    subregion:
    {
       paddingLeft: theme.spacing(4)
    },
    nameContainer:
    {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'left',
        alignItems: 'center',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            alignItems: 'left',
            flexDirection: 'column' as 'column',
        },
    },
    textfields:
    {
        display: 'flex',
        flexDirection: 'row' as 'row',
        flexWrap: 'wrap' as 'wrap',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            display: 'flex',
            flexDirection: 'column' as 'column'
        }
    },
    nameTextInput: {
        width: '30%',
        marginRight: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            width: '100%'
        }
    },
    nameTextfield: {
        width: '80%',
        marginLeft: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            width: '100%',
            marginRight: theme.spacing(4)
        }
    },
    emailTextField:
    {
        width: '360px',
        marginLeft: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            width: '100%',
            marginRight: theme.spacing(4)
        }
    },
    phoneTextField:
    {
        width: '360px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        marginLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [`${theme.breakpoints.down('sm')}`]: {
            width: '90%'
        }
    },
    nameLabel:
    {
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'center',
        fontFamily: theme.homefontfamilybold,
        fontSize: '0.8rem',
        width: '20%',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            justifyContent: 'flex-start',
            width: '100%',
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-evenly',
        paddingBottom: theme.spacing(2),
                width: 1024,
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : landscape)`]: {
            width: '100%'
        },
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : portrait)`]: {
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    fabBtn: {
        width: 186,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
    errorMessage: {
        color: 'red',
        fontSize: '13px',
        textAlign: 'center' as 'center'
    },
    cancelLink:
    {
        alignSelf: 'center',
        cursor: "pointer"
    },
    dob: {
        marginLeft: theme.spacing(2),
        width: '15%',
        minHeight: '60px',
        maxHeight: '120px',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            width: '90%'
        }
    },
    passwordContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        width: '360px',
        minHeight: '80px',
        maxHeight: '120px',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            width: '90%',
            marginTop: theme.spacing(1),
        }
    },
    passwordControl:
    {
        margin: theme.spacing(1),
    }
});