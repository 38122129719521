export const HOME_API_GET_PRODUCT_REQUEST = "HOME_API_GET_PRODUCT_REQUEST" ;
export const HOME_API_GET_PRODUCT_SUCCCESS = "HOME_API_GET_PRODUCT_SUCCCESS";
export const HOME_API_GET_PRODUCT_FAILURE = "HOME_API_GET_PRODUCT_FAILURE";

export const HOME_API_CALL_REQUEST = "HOME_API_CALL_REQUEST";
export const HOME_API_CALL_SUCCESS = "HOME_API_CALL_SUCCESS";
export const HOME_API_CALL_FAILURE = "HOME_API_CALL_FAILURE";

export const HOME_ERROR_CLEAR = "HOME_ERROR_CLEAR"

export const HOME_API_CONSENT_REQUEST = "HOME_API_CONSENT_REQUEST";
export const HOME_API_CONSENT_SUCCESS = "HOME_API_CONSENT_SUCCESS";
export const HOME_API_CONSENT_FAILURE = "HOME_API_CONSENT_FAILURE";

export const topicRows = [
    { id: 'NAME', numeric: false, disablePadding: false, label: 'Category', width: 550, sortEnabled: true },
    { id: 'CREATED_AT', numeric: false, disablePadding: false, label: 'Created', width: 250, sortEnabled: false },
    { id: 'MODIFIED_AT', numeric: false, disablePadding: false, label: 'Modified', width: 250, sortEnabled: true },
    { id: 'contacts', numeric: true, disablePadding: false, label: 'Contacts', width: 40, sortEnabled: false },
    { id: 'files', numeric: true, disablePadding: false, label: 'Files', width: 40, sortEnabled: false },
];

export const contactRows = [
    { id: 'NAME', numeric: false, disablePadding: false, label: 'Contact Name', width: 500, sortEnabled: true },
    { id: 'CREATED_AT', numeric: false, disablePadding: false, label: 'Added', width: 300, sortEnabled: true },
    { id: 'topics', numeric: true, disablePadding: false, label: 'Topics', width: 40, sortEnabled: false },
];

export const getModalStyle = () => {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}
