import store from '../../../index-store';
import { logoutSuccessful } from '../../../pages/shared/loginuser/actions';
import { unsetUserInfo } from '../user/userActions';

export const optionHandle = (option, isTimeOut) => {
	switch (option) {
		case 'Log Out': {
			console.debug("LOCAL STORAGE CLEARED")
			store.dispatch(unsetUserInfo());
			store.dispatch(logoutSuccessful(isTimeOut));
			localStorage.clear();
			break;
		}
		default:
			break;
	}
};
