export enum CeremonyStatus {
  CEREMONY_INITIATE_INSTUSER = "CEREMONY_INITIATE_INSTUSER",
  CEREMONY_REQUESTED_INSTUSER = "CEREMONY_REQUESTED_INSTUSER",
  CEREMONY_REQUESTED_ENDUSER = "CEREMONY_REQUESTED_ENDUSER",
  CEREMONY_PROGRESS_ENDUSER = "CEREMONY_PROGRESS_ENDUSER",
  CEREMONY_CANCEL_INSTUSER = "CEREMONY_CANCEL_INSTUSER",
  CEREMONY_PROGRESS_ENDUSER_ABORT = "CEREMONY_PROGRESS_ENDUSER_ABORT",
  CEREMONY_ACCEPTED_ENDUSER = "CEREMONY_ACCEPTED_ENDUSER",
  CEREMONY_REJECTED_ENDUSER = "CEREMONY_REJECTED_ENDUSER",
  CEREMONY_COMPLETE_ENDUSER = "CEREMONY_COMPLETE_ENDUSER"
}

export enum CeremonyTokenStatus {
  REQUESTED = "REQUESTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  REJECTED = "REJECTED",
  WAITING_SIGNED_DOC = "WAITING_SIGNED_DOC",
}

export enum ItemCeremonyStatus {
  REQUESTED = "REQUESTED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  AGREED = "AGREED",
  DECLINED = "DECLINED",
  IN_PROGRESS = "IN_PROGRESS",
  WAITING_SIGNED_DOC = "WAITING_SIGNED_DOC",
  REVOKED = "REVOKED"
}
