import React from 'react';

const AddContactGroup = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st7{stroke:${props.stroke}}`}
        </style>
        <g id="files-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Users-/-Add-Group" className="st7">
                <g id="Group">
                    <g id="Regular" strokeWidth="1.5">
                        <circle id="Oval" cx="17.25" cy="17.25" r="6"></circle>
                        <line x1="17.25" y1="14.25" x2="17.25" y2="20.25" id="Shape"></line>
                        <line x1="14.25" y1="17.25" x2="20.25" y2="17.25" id="Shape"></line>
                        <path d="M10.782,10.513 C9.66896817,9.53136408 8.23404809,8.99291317 6.75,9 C3.7083747,8.97615479 1.13012138,11.2321264 0.75,14.25" id="Shape"></path>
                        <circle id="Oval" cx="6.75" cy="4.125" r="3.375"></circle>
                        <circle id="Oval" cx="16.5" cy="3.375" r="2.625"></circle>
                        <path d="M20.4,9 C19.6736308,7.74269724 18.3852076,6.91315094 16.9400142,6.77230162 C15.4948208,6.6314523 14.0704689,7.19661008 13.115,8.29" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}
export default AddContactGroup