/**
 * @Author: Vipul Grover
 */

import React from 'react';
import { connect } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = (props) => (
	(props.loggedIn && props.loggedIn.user && props.loggedIn.exp > Date.now()/1000) ?
			<Route {...props} />            
            : <Redirect to={{ pathname: (props.loggedIn.logoutresponse?.data?.redirect ? '/logout' : '/login'), state: { from: props.location } }} />
);

export default connect(
  state => ({
    loggedIn: state.login,
  })
)(ProtectedRoute);