import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import './PvToolbarActions.scss';

type ToolbarActionsProps = {
    openSearchbar: any,
    showSearchIcon: boolean,
    onLogoutClick:any
}

type ToolbarActionsStates = {}

class PvToolbarActions extends Component<ToolbarActionsProps, ToolbarActionsStates> {
    handleToggleSearchbar = (open: boolean) => {
        this.props.openSearchbar(open)
    }

    logout = () => {
        this.props.onLogoutClick();
    }

    render() {
        return (
            <div>
                { this.props.showSearchIcon && 
                    <div className='actions-wrapper'>
                       {/*  <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={() => this.handleToggleSearchbar(true)}
                            edge='start'
                            className='menu-button'>
                            <SearchIcon />
                        </IconButton> */}
                        <IconButton
                            color='inherit'
                            aria-label='Log Out'
                            title='Log Out'
                            onClick={() => this.logout()}
                            edge='start'
                            className='menu-button'>
                            <ExitToAppIcon />
                        </IconButton>
                    </div>
                }
            </div>
        )
    }
}
const mapStateToProps = () => {
    return {
        //   selectedGroup: state.appUIStateReducer.selectedGroup,
    };
}

const mapDispatchToProps = () => {
    return {
        //   updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
        //     type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
        //     itemName: itemName,
        //     itemLink: itemLink
        //   })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PvToolbarActions);
