export const paginationContacts = {
    page: 0,
    size: 20,
    direction: "ASC",
    orderBy: "NAME"
}

export const paginationTopics = {
    page: 0,
    size: 10,
    direction: "DESC",
    orderBy: "MODIFIED_AT"
}

export const paginationUserTopics = {
    page: 0,
    size: 5000,
    direction: "DESC",
    orderBy: "MODIFIED_AT"
}

export const paginationItems = {
    page: 0,
    size: 20,
    direction: "DESC",
    orderBy: "MODIFIED_AT"
}

export const cvpaginationAllContacts = {
    page: null,
    size: null,
    direction: "ASC",
    orderBy: "NAME"
}

export const fidsafePaginationItems = {
    page: 0,
    size: 20,
    direction: "ASC",
    orderBy: "NAME"
}

export const fsPaginationContacts = {
    page: null,
    size: null,
    direction: "ASC",
    orderBy: "NAME"
}

export const fsPaginationGroups = {
    page: 0,
    size: 20,
    direction: "ASC",
    orderBy: "NAME"
}

export const fsFileSharing = {
    page: 0,
    size: 100,
    direction: "ASC",
    orderBy: "NAME"
}

export const fsPaginationFiles = {
    page: 0,
    size: 15,
    direction: "ASC",
    orderBy: "NAME"
}
