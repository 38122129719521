export const CONTACT_DETAIL_GET_CONTACT_REQUEST = 'CONTACT_DETAIL_GET_CONTACT_REQUEST';
export const CONTACT_DETAIL_GET_CONTACT_SUCCESS = 'CONTACT_DETAIL_GET_CONTACT_SUCCESS';
export const CONTACT_DETAIL_GET_CONTACT_FAILURE = 'CONTACT_DETAIL_GET_CONTACT_FAILURE';

export const CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST = 'CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST';
export const CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_SUCCESS = 'CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_SUCCESS';
export const CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_FAILURE = 'CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_FAILURE';

export const CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_REQUEST = 'CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_REQUEST';
export const CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_SUCCESS = 'CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_SUCCESS';
export const CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_FAILURE = 'CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_FAILURE';

export const PREVIEW_FILE = 'PREVIEW_FILE'