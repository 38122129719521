// action types
import {
  STRIP_CURRENT_FOLDERPATH_SUCCESS,ADDTO_CURRENT_FOLDER_SUCCESS,
  GET_ITEMS_FILES_REQUEST, GET_ITEMS_FILES_SUCCESS, GET_ITEMS_FILES_FAILURE, GET_API_HOME_FOLDER_SUCCESS, GET_API_HOME_FOLDER_FALIURE,
  GET_FILE_DOWNLOAD_API_SUCCESS, GET_FILE_DOWNLOAD_API_REQUEST, FOLDER_API_UPDATE_ITEM, FOLDER_API_UPDATE_ITEM_SUCCESS, FOLDER_API_UPDATE_ITEM_FAILED,
  ITEM_API_DELETE, ITEM_API_DELETE_SUCCESS, ITEM_API_DELETE_FAILED, GET_ITEMS_CURRENT, GET_ITEMS_CURRENT_SUCCESS, GET_ITEMS_CURRENT_FAILURE, LOAD_CURRENT_ITEMS_SUCCESS,
  CREATE_FOLDER_API_CREATE_REQUEST, CREATE_FOLDER_API_CREATE_SUCCESS, CREATE_FOLDER_API_CREATE_FAILED, FILES_ERROR_CLEAR, 
  GET_MULTIPLE_FILES_DOWNLOAD_FAILURE, GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS, GET_MULTIPLE_FILES_DOWNLOAD_REQUEST,
}
  from '../../constants/personalVault/itemsConstants'

import { UNABLE_TO_FETCH_FILES } from '../../../shared/errors/ErrorActions';


const initialState = {
  itemsInfo: {
    fetching: false,
    itemList: [
      {
        itemCode: '',
        itemName: '',
        itemType: 'FILE',
        parentCode: '',
        size: 0,
        mimeType: '',
        title: '',
        createdAt: '',
        modifiedAt: '',
        owner: {
          isOwner: false,
          isGroupOwner: false,
          grpCode: '',
          userCode: ''
        },
        status: 'SCANNED',
        permissionName: 'VIEW'
      }
    ],
    itemsPage: {
      paged: false,
      pageNumber: 0,
      pageSize: 0,
      totalPages: 0,
      totalElements: 0,
      first: false,
      last: false
    },
    itemsSort: null,
    error: null
  },
  downloadInfo: {
    response: null,
    fetching: false,
  },
  updateItem: {
    response: null,
    updating: false,
    error: null
  },
  deleteItem: {
    response: null,
    deleting: false,
    error: false
  },
  breadcrumbInfo:
  {
    folders: []
  },

  createdFolderInfo: {
    response: null,
    fetching: null,
    error: null
  },
  currentItemInfo: {
    itemList: [],
    fetching: false,
    error: null,
    currentItem: null
  },
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_ITEMS_FILES_REQUEST:
      return { ...state, itemsInfo: { ...state.itemsInfo, fetching: true } };

    case GET_API_HOME_FOLDER_SUCCESS:
      return {
        ...state, breadcrumbInfo:
          { folders: [action.responseInfo] }
      };
    case ADDTO_CURRENT_FOLDER_SUCCESS:
      return {
        ...state, breadcrumbInfo:
          { folders: [...state.breadcrumbInfo.folders, action.responseInfo] }
      };
    case GET_API_HOME_FOLDER_FALIURE:
      return {
        ...state, itemsInfo: { fetching: false, itemList: state.itemsInfo },
        breadcrumbInfo:
        {
          folders: []
        },
        error: { ...action.error, action: UNABLE_TO_FETCH_FILES }
      };
    case STRIP_CURRENT_FOLDERPATH_SUCCESS:
      return {
        ...state, breadcrumbInfo:
          { folders: [...state.breadcrumbInfo.folders.slice(0, action.payload)] }
      };

    case GET_ITEMS_FILES_SUCCESS:
      return {
        ...state, itemsInfo: {
          fetching: false, itemList: action.responseInfo.data.content,
          itemsPage: action.responseInfo.data.page, itemsSort: action.responseInfo.data.sort
        }
      };
    case GET_ITEMS_FILES_FAILURE:
      return { ...state, itemsInfo: { fetching: false, itemList: [] }, error: { ...action.error, action: UNABLE_TO_FETCH_FILES } };

    case FOLDER_API_UPDATE_ITEM:
      return { ...state, updateItem: { updating: true }, itemsInfo: { ...state.itemsInfo } };

    case FOLDER_API_UPDATE_ITEM_SUCCESS:
      return {
        ...state, updateItem: { updating: false, response: { ...action.responseInfo }, error: null },
        itemsInfo: { ...state.itemsInfo, fetching: false, itemList: getUpdatedItemList(action, state.itemsInfo) }
      }
    case FOLDER_API_UPDATE_ITEM_FAILED:
      return { ...state, updateItem: { updating: false, response: null, error: { ...action.error.response } } }
    case GET_FILE_DOWNLOAD_API_REQUEST:
      return { ...state, downloadInfo: { fetching: true } };
    case GET_FILE_DOWNLOAD_API_SUCCESS:
      return { ...state, downloadInfo: { fetching: false, response: { ...action.responseInfo } } };

    case GET_MULTIPLE_FILES_DOWNLOAD_REQUEST:
      return { ...state, downloadInfo: { fetching: true } };
    case GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS:
      return { ...state, downloadInfo: { fetching: false, response: { ...action.responseInfo } } };
    case GET_MULTIPLE_FILES_DOWNLOAD_FAILURE:
      return { ...state, downloadInfo: { fetching: false, response: null, error: { ...action.error.response }}}

    case ITEM_API_DELETE:
      return { ...state, deleteItem: { deleting: true } };
    case ITEM_API_DELETE_SUCCESS:
      return { ...state, deleteItem: { deleting: false, response: { ...action.responseInfo } } };
    case ITEM_API_DELETE_FAILED:
      return { ...state, deleteItem: { deleting: false, response: null, error: { ...action.error.response } } }
    case GET_ITEMS_CURRENT:
      return { ...state, currentItemInfo: { fetching: true } };

    case CREATE_FOLDER_API_CREATE_REQUEST:
      return { ...state, createdFolderInfo: { ...state.createdFolderInfo, fetching: true } }
    case CREATE_FOLDER_API_CREATE_SUCCESS:
      return { ...state, createdFolderInfo: { fetching: false, response: { ...action.responseInfo }, error: null }, itemsInfo: { ...state.itemsInfo, itemList: addItem(action, state.itemsInfo, state.breadcrumbInfo.folders) } }
    case CREATE_FOLDER_API_CREATE_FAILED:
      return { ...state, createdFolderInfo: { fetching: false, response: null, error: { ...action.error.response } } }


    case GET_ITEMS_CURRENT_SUCCESS:
      return {
        ...state, currentItemInfo: {
          fetching: false,
          itemList: action.responseInfo.data.content,
          currentItem: (null != action.currentItem) ? action.currentItem.data : action.currentItem
        }
      };
    case GET_ITEMS_CURRENT_FAILURE:
      return { ...state, currentItemInfo: { fetching: false, itemList: [], currentItem: null }, error: { ...action.error, action: UNABLE_TO_FETCH_FILES } };
    case LOAD_CURRENT_ITEMS_SUCCESS:
      return {
        ...state, currentItemInfo: {
          fetching: false, itemList: [...state.itemsInfo.itemList.slice()],
          currentItem: null
        }
      };
    case FILES_ERROR_CLEAR:
      return { ...state, error: null }
    default:
      return state;
  }
}

const addItem = (action, itemsInfo, currentFolders) => {
  let addedFolderDetails = action.responseInfo;
  let exisitngArray = itemsInfo.itemList.slice()
  let exisitngArrayLength = exisitngArray.length
  if (addedFolderDetails && currentFolders[currentFolders.length - 1].itemCode === addedFolderDetails.parentCode) {
      exisitngArray.unshift(addedFolderDetails);
    if (exisitngArrayLength >= 20) {
      exisitngArray.pop();
    }
  }
  return exisitngArray;
}

const getUpdatedItemList = (action, itemsInfo) => {
  let updatedResponse = action.responseInfo.data;
  let newArray = itemsInfo.itemList.slice()
  // Check if the itemcode exists in the iteminfo state  and get the index.
  var updatedItemIndex = itemsInfo.itemList.findIndex((x) =>
    x.itemCode === updatedResponse.itemCode
  );

  //update the details.
  if (updatedItemIndex >= 0) {
    var updatedItem = itemsInfo.itemList[updatedItemIndex];
    //Check if the destination folder id different then the item moved from the current folder.
    if (updatedItem.parentCode !== updatedResponse.destination) {
      // remove the moved item from the list
      if (newArray.length > 1)
        newArray.splice(updatedItemIndex, 1)
      else
        newArray.splice(updatedItemIndex)
    }
    else //Consider it as a rename and update the item name
    {
      newArray[updatedItemIndex].itemName = updatedResponse.itemName;
    }
  }
  return newArray;
}