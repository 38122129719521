import { takeLatest, call, put, all, select } from "redux-saga/effects";
import * as ceremonyActionTypes from "../../constants/shared/eSignConstants";
import * as appActionTypes from "../../constants/shared/appStatesConstants";
import {
  HttpMethod,
  HttpRequest,
} from "../../../shared/core/utils/requestHandler";
import { con } from "../../../shared/config/config";
import {
  getCeremonyTypes,
  intiateCeremonyProcess,
  getCeremonyDetails,
  startCeremonyProcess,
  updateCeremonyDetails,
  cancelCeremonyProcess,
  getFormRoles,
  getCeremonyDisclosures
} from "../../../shared/core/utils/apiFunctions";

function* createCeremony(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        intiateCeremonyProcess,
        grpCode,
        action.topicCode,
        action.ceremonyType,
        action.ceremonies,
        action.formCode
      );
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_CREATION_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.CEREMONY_API_CREATION_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_CREATION_FAILURE,
        error,
      });
    }
  }
}

function* getCeremonyDetailsAction(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        getCeremonyDetails,
        grpCode,
        action.topicCode,
        action.itemCode
      );
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_GET_DETAILS_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.CEREMONY_API_GET_DETAILS_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_GET_DETAILS_FAILURE,
        error,
      });
    }
  }
}

// NOTE: same function can be used for all other form types stored in the database.
function* getEsignFormTypesAction(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        getFormRoles,
        grpCode,
        action.categoryCode,
        action.formType
      );
      yield put({
        type: ceremonyActionTypes.GET_ESIGN_FORM_TYPES_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.GET_ESIGN_FORM_TYPES_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.GET_ESIGN_FORM_TYPES_FAILURE,
        error,
      });
    }
  }
}

function* getCeremonyTypesAction(action) {
  try {
    const response = yield call(getCeremonyTypes, action.categoryCode);
    yield put({
      type: ceremonyActionTypes.CEREMONY_API_GET_TYPES_SUCCESS,
      ceremonies: response.data.ceremonies,
    });
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.CEREMONY_API_GET_TYPES_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_GET_TYPES_FAILURE,
        error,
      });
    }
  }
}

function* startCeremony(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(startCeremonyProcess, grpCode, action.topicCode, action.ceremonyCode,
        action.isForceInit);
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_INITIATION_SUCCESS,
        response: {
          ceremony_claims: response.data.ceremony_claims,
          esignEmbadedUrl: response.data.esignEmbadedUrl
        },
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.CEREMONY_API_INITIATION_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_INITIATION_FAILURE,
        error,
      });
    }
  }
}

function* updateCeremony(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        updateCeremonyDetails,
        grpCode,
        action.ceremonyCode,
        action.ceremonyStatus
      );
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_STATUS_CHANGE_SUCCESS,
        response: response,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.CEREMONY_API_STATUS_CHANGE_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_STATUS_CHANGE_FAILURE,
        error,
      });
    }
  }
}

function* cancelCeremony(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        cancelCeremonyProcess,
        grpCode,
        action.ceremonyCode
      );
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_CANCEL_INITIATION_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.CEREMONY_API_CANCEL_INITIATION_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.CEREMONY_API_CANCEL_INITIATION_FAILURE,
        error,
      });
    }
  }
}

function* getCeremonyDisclosuresAction(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(getCeremonyDisclosures, grpCode, action.ceremonyCode);
      yield put({
        type: ceremonyActionTypes.GET_CEREMONY_DISCLOSURES_SUCCESS,
        disclosures: response.data.disclosures,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: ceremonyActionTypes.GET_CEREMONY_DISCLOSURES_FAILURE,
          error,
        });
    } else {
      yield put({
        type: ceremonyActionTypes.GET_CEREMONY_DISCLOSURES_FAILURE,
        error,
      });
    }
  }
}

function* eSignWatcher() {
  yield takeLatest(ceremonyActionTypes.CEREMONY_API_CREATION, createCeremony);
  yield takeLatest(ceremonyActionTypes.CEREMONY_API_CANCEL_INITIATION, cancelCeremony);
  yield takeLatest(
    ceremonyActionTypes.CEREMONY_API_GET_DETAILS,
    getCeremonyDetailsAction
  );
  yield takeLatest(
    ceremonyActionTypes.CEREMONY_API_GET_TYPES,
    getCeremonyTypesAction
  );
  yield takeLatest(ceremonyActionTypes.CEREMONY_API_INITIATION, startCeremony);
  yield takeLatest(
    ceremonyActionTypes.CEREMONY_API_STATUS_CHANGE,
    updateCeremony
  );
  yield takeLatest(
    ceremonyActionTypes.GET_ESIGN_FORM_TYPES,
    getEsignFormTypesAction
  )
  yield takeLatest(
    ceremonyActionTypes.GET_CEREMONY_DISCLOSURES,
    getCeremonyDisclosuresAction
  )
}

export default eSignWatcher;
