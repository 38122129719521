// Get Contacts
export const GET_SHARE_CONTACTS = 'GET_SHARE_CONTACTS';
export const ADD_SHARE_CONTACTS = 'ADD_SHARE_CONTACTS';
export const CLEAR_SHARE_CONTACTS = 'CLEAR_SHARE_CONTACTS';
export const EDIT_SHARE_CONTACT_PERMISSION = 'EDIT_SHARE_CONTACT_PERMISSION';
export const REMOVE_SHARE_CONTACT = 'REMOVE_SHARE_CONTACT';
export const GET_SHARE_CONTACTS_SUCCESS='GET_SHARE_CONTACTS_SUCCESS';
export const GET_SHARE_CONTACTS_FAILURE='GET_SHARE_CONTACTS_FAILURE';

export const UPDATE_SHARE_CONTACTS_REQUEST = 'UPDATE_SHARE_CONTACTS_REQUEST';
export const UPDATE_SHARE_CONTACTS_SUCCESS='UPDATE_SHARE_CONTACTS_SUCCESS';
export const UPDATE_SHARE_CONTACTS_FAILURE='UPDATE_SHARE_CONTACTS_FAILURE';
