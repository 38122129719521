export const APP_STATE_UPDATE_CONTACTS_PAGE_TABS = "APP_STATE_UPDATE_CONTACTS_PAGE_TABS";

export const APP_STATE_UPDATE_FEEDBACK_UPDATE = "APP_STATE_UPDATE_FEEDBACK_UPDATE";

export const GROUP_DETAIL_API_GET_CONTACTS_REQUEST = "GROUP_DETAIL_API_GET_CONTACTS_REQUEST";
export const GROUP_DETAIL_API_GET_CONTACTS_SUCCESS = "GROUP_DETAIL_API_GET_CONTACTS_SUCCESS";
export const GROUP_DETAIL_API_GET_CONTACTS_FAILURE = "GROUP_DETAIL_API_GET_CONTACTS_FAILURE";

export const APP_STATE_UPDATE_SNACKBAR = 'APP_STATE_UPDATE_SNACKBAR';

export const PREVIEW_FILE = 'PREVIEW_FILE';
export const VIEW_FILE_DETAILS = 'VIEW_FILE_DETAILS';

export const TOGGLE_FILE_DETAIL_MENU = 'TOGGLE_FILE_DETAIL_MENU';

export const APP_STATE_UPDATE_GROUP = 'APP_STATE_UPDATE_GROUP';

export const TOPIC_API_SELECT_TOPIC = 'TOPIC_API_SELECT_TOPIC';

export const GET_BREADCRUMB_LIST = 'GET_BREADCRUMB_LIST';
export const GET_BREADCRUMB_LIST_SUCCESS = 'GET_BREADCRUMB_LIST_SUCCESS';
export const GET_BREADCRUMB_LIST_FAILURE = 'GET_BREADCRUMB_LIST_FAILURE';

export const ADD_BREADCRUMB_ITEM = 'ADD_BREADCRUMB_ITEM';
export const UPDATE_BREADCRUMB_ROOT_ITEM = 'UPDATE_BREADCRUMB_ROOT_ITEM';
export const REMOVE_BREABCRUMB_ITEMS = 'REMOVE_BREABCRUMB_ITEMS';
export const EMPTY_BREADCRUMB_ITEM = 'EMPTY_BREADCRUMB_ITEM';

export const INIT_DRAWER = 'INIT_DRAWER';
export const UPDATE_DRAWER_MENU = 'UPDATE_DRAWER_MENU';
export const APP_STATE_GENERIC_ERROR='APP_STATE_GENERIC_ERROR';

export const UPDATE_COMMON_MODAL = 'UPDATE_COMMON_MODAL';
export const UPDATE_COGNITO_LOGIN_STATE="UPDATE_COGNITO_LOGIN_STATE";
export const UPDATE_COGNITO_ERROR='UPDATE_COGNITO_ERROR';
export const UPDATE_COMMON_SWIPEABLE_DRAWER = 'UPDATE_COMMON_SWIPEABLE_DRAWER';
export const UPDATE_GACONFIG='UPDATE_GACONFIG';

export const UPDATE_TOPIC_LIST_HEADERS = 'UPDATE_TOPIC_LIST_HEADERS';
export const UPDATE_FILE_LIST_HEADERS = 'UPDATE_FILE_LIST_HEADERS';