import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { fade, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { HOME_API_CALL_REQUEST, HOME_API_GET_PRODUCT_REQUEST } from '../../redux/constants/shared/homeConstants';
import * as AppUIStateActionTypes from '../../redux/constants/shared/appStatesConstants';
import * as UserActionTypes from '../../redux/constants/customerVault/userConstants';
import { paginationContacts } from '../../shared/core/utils/paginationDefaults';
import Loading from '../../shared/ui/loading/Loading';
import Breadcrumb from '../../shared/ui/breadcrumb/Breadcrumb';
import { base_path } from '../../redux/constants/customerVault/constants'
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import * as TopicActionTypes from '../../redux/constants/customerVault/topicConstants';
import * as LegalActionTypes from '../../redux/constants/shared/legalsConstants';
import { optionHandle } from '../../shared/core/utils/helper';
import { con } from '../../shared/config/config';
import Layout from '../../shared/ui/layout/Layout';
import ConditionalRoute from "./ConditionalRoute";
import CvFooter from '../../components/customerVault/footer/Cvfooter'
import './index.scss';
import ReactGA from 'react-ga';

type GroupType = {
    userCount: number,
    createdAt: string,
    modifiedAt: string,
    grpCode: string,
    grpDisplayCode: string,
    grpName: string
}

type CvHomePageProps = {
    classes: any,
    theme: any,
    history: any,
    productInfo: any,
    fetchGroups: any,
    fetchProductInfo: any,
    groupsFetching: any,
    groupError: any,
    groupsInfo: any,
    updateGroup: any,
    groupUsers: any,
    loadUsersForGroup: any,
    selectedGroup: GroupType,
    breadcrumbItemList: any,
    addBreadcrumbItem: any,
    updateBreadcrumbItem: any,
    RemoveBreadcrumbItems: any,
    snackbarData: any,
    updateSnackbar: any,
    fetchTopics: any,
    topicsError: any,
    topicsFetching: any,
    drawerMenus: any,
    updateDrawerMenu: any,
    loggedIn: any,
    error: any,
    fetchEndUserDisclaimerContent: any,
    fetchDeleteFileDisclaimerContent: any,
    feedbackFetching: boolean,
    feedbackFeatures: any,
    feedbackError: any,
    GAConfig: any,
    reactGaInitialised: any,
    intializeGAConfig: any
}


type withStyleProps = 'root'
    | 'appBar' | 'appBarShift' | 'searchWrapper' | 'searchIcon' | 'inputInput' | 'inputRoot'
    | 'menuButton' | 'hide' | 'drawer' | 'drawerPaper' | 'drawerHeader' | 'content'
    | 'contentShift' | 'formControl' | 'selectEmpty' | 'actionContainer' | 'rightActionContainer' | 'logoutBtn';

type CvHomePageProps2 = CvHomePageProps & RouteComponentProps<{}> & WithStyles<withStyleProps>;

type CvHomePageStates = {
}


const drawerWidth = 351;
const styles = (theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        // maxWidth: 1280

    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: 'cubic-bezier(0.4, 0, 0.6, 1)',
            duration: 195,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: 'cubic-bezier(0.0, 0, 0.2, 1)',
            duration: 195,
        }),
    },
    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            // marginLeft: theme.spacing(3),
            width: '50%',
        },
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    rightActionContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    logoutBtn:
    {
        color: '#fff'
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1px'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        fontFamily: 'Fidelity Sans',
        fontSize: '14px',
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingTop: '10px',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: 'cubic-bezier(0.4, 0, 0.6, 1)',
            duration: 195,
        }),
        alignItems: 'center',
        marginLeft: -drawerWidth,
        // marginTop: '60px'
        [theme.breakpoints.down('sm')]: {
            paddingTop: '16px',
        },
        overflow: 'auto'
    },
    contentWrapper: {
        maxWidth: 1280,
        width: '100%',
        flex: '1 auto',
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${theme.spacing(2)}px)`,
        }
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: 'cubic-bezier(0.0, 0, 0.2, 1)',
            duration: 225,
        }),
        marginLeft: 0,
        //marginTop: 64,
        [theme.breakpoints.up('sm')]: {
            //marginTop: '60px',
        },
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    formControl: {
        width: 'calc(100% - 16px)',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    footerWrapper: {
        width: '100%'
    }
})

class CvHomePage extends Component<CvHomePageProps2, CvHomePageStates> {
    state = {
        open: true,
        loading: true,
        menuSelectedIndex: 1,
        modalOpen: false,
        modalName: '',
        isEndUser: true,
        showBreadCrumb: true
    }

    initReactGA = (userId: any) => {
        this.props.intializeGAConfig(this.props.GAConfig)
        // Send initial test view
        ReactGA.initialize(this.props.GAConfig);
        ReactGA.pageview('Customer Vault');
    };

    componentDidMount() {
        if (con.PRODUCT_CODE === 'fds') {
            this.props.history.replace('/personal')
        }
        const { fetchGroups, loggedIn, fetchProductInfo, fetchDeleteFileDisclaimerContent } = this.props;
        if (loggedIn && loggedIn.user && loggedIn.exp > Date.now() / 1000) {
            if (!this.props.reactGaInitialised)
                this.initReactGA(loggedIn.user_profile.userCode)
        }
        if (loggedIn.user_profile.roles.indexOf('USER') > -1) {
            // fetchTopics(paginationTopics, null, loggedIn.user_profile.userCode)
            this.setState({ isEndUser: true })
            this.props.fetchEndUserDisclaimerContent();
        }
        else {
            this.setState({ isEndUser: false })
        }
        fetchGroups();
        fetchDeleteFileDisclaimerContent();
        if(this.props.productInfo === null) {
           fetchProductInfo()
        }
    }

    componentDidUpdate(prevProps: any) {
        const { loggedIn, groupError, groupsInfo, updateGroup, fetchTopics, addBreadcrumbItem,
            topicsFetching, topicsError } = this.props;


        if (prevProps.groupError != groupError && groupError != null) {
            if (groupError.response && groupError.response.status === 501) {
                this.props.history.push("/error");
            }
        }

        if (prevProps.error != this.props.error && this.props.error != null) {
            //If the user is logged in then logout
            if (null != loggedIn.user && loggedIn.successful) {
                optionHandle('Log Out');
            }
            else {
                this.props.history.push('/login');
            }
        }
        else {

            if (!groupError && prevProps.groupsInfo !== groupsInfo) {
                let groupsResponse = JSON.stringify(groupsInfo)
                let groups = JSON.parse(groupsResponse);
                let selectedGroup = groups.data.content[0];

                //Read the group code from the URL else take the default to 0
                let groupId: RegExpMatchArray | null;
                // groupId = window.location.pathname.match('(?:/group/).*?(?=/)');
                groupId = window.location.pathname.match('\/group\/(.*?)\s*\/');
                if (groupId != null && groupId.length > 1) {
                    let groupInfo = groupId[1];
                    var groupFound = groups.data.content.find((grp: any) => grp.grpCode === groupInfo);
                    if (null != groupFound)
                        selectedGroup = groupFound;
                    else
                        this.props.history.push(`/customer/group/${selectedGroup.grpCode}/contacts`)

                }
                //Update the selected group to state
                updateGroup(selectedGroup);

                if (this.state.isEndUser) {
                    // fetchTopics(paginationTopics, null, loggedIn.user_profile.userCode)
                    addBreadcrumbItem('Customer Vault', `/customer`);
                    this.setState({ loading: false })
                }
                else {
                    //loadUsersForGroup();
                     this.setState({ loading: false })
                    // fetchTopics(paginationTopics, null, null)
                    addBreadcrumbItem(selectedGroup.grpName, `/customer/group/${selectedGroup.grpCode}/topics`);
                }
            }

            if (!this.state.isEndUser) {
                if (!topicsFetching && prevProps.topicsFetching !== topicsFetching) {
                    if (topicsError != null) {
                    } else {
                        this.setState({ topicsLoaded: true })
                        this.setState({ loading: false })
                    }
                }
            }

        }
    }

    onBreadcrumbItemSelect = (event: any, item: any) => {
        const { breadcrumbItemList } = this.props;
        const index = breadcrumbItemList.findIndex((x: any) => {
            return x.itemLink === item.itemLink
        });
        this.props.RemoveBreadcrumbItems(index + 1);
        //Navigate to item Link

        //Click on the Link only if it is not a last option.
        if (null != breadcrumbItemList && breadcrumbItemList.length > 1) {
            if (item.itemLink != null)
                this.props.history.push(item.itemLink)
        }
    }

    onMenuSelect = (index: number) => {
        this.props.RemoveBreadcrumbItems(1);
        this.setState({ menuSelectedIndex: index });
        if (index === 0) {
            this.props.updateBreadcrumbItem(this.props.selectedGroup.grpName, `/customer/group/${this.props.selectedGroup.grpCode}/topics`)
        } else {
            this.props.updateBreadcrumbItem(this.props.selectedGroup.grpName, `/customer/group/${this.props.selectedGroup.grpCode}/contacts`)
        }
    }

    menuSelect = (link: string) => {
        this.props.RemoveBreadcrumbItems(1);
        const baseUrl = `/customer/group/${this.props.selectedGroup.grpCode}/${link}`
        this.props.updateBreadcrumbItem(this.props.selectedGroup.grpName, baseUrl);
        this.props.history.push(baseUrl);
    }

    handleSnackbarClose = (event: any, reason?: any) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.updateSnackbar({
            modalOpen: false,
            content: ''
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} data-test="cvHome">
                {this.state.loading ? <Loading loadingText="Loading..."></Loading> :
                    <main className={clsx(classes.content, { [classes.contentShift]: this.state.open, })}>
                        {/* <div className={classes.drawerHeader} /> */}
                        <div className={classes.contentWrapper}>
                            {(this.props.location.pathname.includes('/topic-detail') ? !this.props.loggedIn.user_profile.roles.includes('DOWNGRADED_USER') : true) && 
                                <Breadcrumb data-test='breadcrumb-comp' textLength={90} history={this.props.history} items={this.props.breadcrumbItemList} onItemSelect={this.onBreadcrumbItemSelect}></Breadcrumb>
                            }
                            {/* {this.renderSurveyMessage()} */}
                            <Switch>
                                <ConditionalRoute
                                    authKey="Contacts"
                                    strict
                                    redirectTo="/customer"
                                    path={`/customer/group/${this.props.selectedGroup.grpCode}/contacts`}
                                    component={() => (
                                        <Layout pageName='cv-contacts' location={this.props.location} />
                                    )}
                                />
                                <ConditionalRoute
                                    authKey="Contacts"
                                    strict
                                    redirectTo="/customer"
                                    path={`/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/:contactId`}
                                    component={() => (
                                        <Layout pageName='cv-contact-detail' location={this.props.location} />
                                    )}
                                />

                                {this.state.isEndUser ? <ConditionalRoute
                                    authKey="Topics"
                                    strict
                                    redirectTo="/customer"
                                    path={`/customer/topics`}
                                    component={() => (
                                        <Layout pageName='cv-topics' location={this.props.location} />
                                    )}
                                /> : <ConditionalRoute
                                        authKey="Topics"
                                        strict
                                        redirectTo="/customer"
                                        path={`/customer/group/${this.props.selectedGroup.grpCode}/topics`}
                                        component={() => (
                                            <Layout pageName='cv-topics' location={this.props.location} />
                                        )}
                                    />}

                                {/*  <ConditionalRoute
                                    authKey="Topics"
                                    strict
                                    redirectTo="/customer"
                                    path={`/customer/group/${this.props.selectedGroup.grpCode}/topic-detail/:topicId/file-detail/:fileId`}
                                    component={() => (
                                        <Layout pageName='file-detail' location={this.props.location} />
                                    )}
                                /> */}
                                <ConditionalRoute
                                    authKey="Topics"
                                    strict
                                    redirectTo="/customer"
                                    path={`/customer/group/${this.props.selectedGroup.grpCode}/topic-detail/:topicId`}
                                    component={() => (
                                        <Layout pageName='cv-topic-detail' location={this.props.location} />
                                    )}
                                />
                                <Route path={`${base_path}(/.*)?`} render={() => <Redirect to={
                                    this.state.isEndUser ? `/customer/topics` : `/customer/group/${this.props.selectedGroup.grpCode}/contacts`
                                } />} ></Route>

                            </Switch>
                        </div>
                        <CvFooter />
                        {/* <Hidden smUp>
                            <CvFooter />
                        </Hidden> */}
                    </main>
                }
                <Snackbar data-test='snackBar'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.props.snackbarData.modalOpen}
                    autoHideDuration={this.props.snackbarData.autoHideDisable ? null : 3000}
                    onClose={this.handleSnackbarClose}
                    message={this.props.snackbarData.content}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                {!this.state.isEndUser && !this.props.feedbackFetching && this.props.feedbackFeatures.feedbackConfig.enable && this.props.feedbackError === null && <div className='feedback-wrapper'>
                    <a className='feedback-label' href={this.props.feedbackFeatures.feedbackConfig.link}>Feedback</a>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        productInfo: state.homeReducer.productInfo.response,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        breadcrumbItemList: state.appUIStateReducer.breadcrumbItemList,
        groupsInfo: state.homeReducer.groupsInfo.response,
        groupsFetching: state.homeReducer.groupsInfo.fetching,
        groupError: state.homeReducer.error,
        groupUsers: state.userReducer.groupContacts,
        topicsError: state.topicReducer.topicList.error,
        topicsFetching: state.topicReducer.topicList.fetching,
        snackbarData: state.appUIStateReducer.snackbar,
        drawerMenus: state.appUIStateReducer.drawerMenuItems,
        loggedIn: state.login,
        error: state.appUIStateReducer.error,
        feedbackFetching: state.featureReducer.fetching,
        feedbackFeatures: state.featureReducer.response,
        feedbackError: state.featureReducer.error,
        GAConfig: state.appUIStateReducer.GAConfig,
        reactGaInitialised: state.appUIStateReducer.reactGaInitialised
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchProductInfo: () => dispatch({ type: HOME_API_GET_PRODUCT_REQUEST }),
        fetchGroups: () => dispatch({ type: HOME_API_CALL_REQUEST }),
        updateGroup: (group: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_GROUP,
            group: group
        }),
        fetchTopics: (pagination: any, filter: any, userCode?: any) => dispatch({
            type: TopicActionTypes.TOPICS_API_GET_TOPICS_REQUEST,
            pagination, filter,
            userCode: userCode
        }),
        loadUsersForGroup: () => dispatch({
            type: UserActionTypes.USER_API_GET_USERS_REQUEST,
            pagination: paginationContacts,
            pattern: ''
        }),
        addBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.ADD_BREADCRUMB_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        updateDrawerMenu: (selectedMenu: string) => dispatch({
            type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
            selectedMenu: selectedMenu
        }),
        fetchEndUserDisclaimerContent: () => dispatch({ type: LegalActionTypes.FETCH_ENDUSER_DISCLAIMER_REQUEST }),
        fetchDeleteFileDisclaimerContent: () => dispatch({ type: LegalActionTypes.FETCH_DELETE_FILE_DISCLAIMER_REQUEST }),
        intializeGAConfig: (GAConfig: any) => dispatch({ type: AppUIStateActionTypes.UPDATE_GACONFIG, GAConfig })
    }
}

export const CvHomePageComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(CvHomePage);

const CvHomePageComponentStyles:any = withStyles(styles)(CvHomePageComponent);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CvHomePageComponentStyles));