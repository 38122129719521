import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ContactActionTypes from '../../../../../redux/constants/personalVault/contactsConstants';
import { withStyles } from '@material-ui/core/styles';
import TextInput from '../../../../../shared/core/TextInput';
import Button from '@material-ui/core/Button';
import InviteHeader from '../../shared/InviteHeader';
import FabButton from '../../../../../shared/core/FabButton';
import ContactListSearch from '../../shared/ContactListSearch';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';

type InviteGroupProps = {
    addContactGroup: any;
    classes: any;
    updating: boolean
    onActionComplete: any;
    addContactGroupError: any;
    handleError: any,
    contactGroupName?: string,
    contactGroupId?: string,
    modalWidth?: any,
    updateSnackbar: any,
    snackbarData: any,
    error: any
}

export const styles = (theme: any) => ({
    root: {
        fontWeight: 500,
        fontFamily: 'Fidelity Sans',
        fontSize: '1rem'
    },
    textfield: {
        width: '90%',
        height: 56,
        margin: theme.spacing(2)
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(2),
    },
    cancelBtn: {
        color: "#646D7D"
    },
    errorMsg: {
        fontSize: '0.8rem',
        width: '100%',
        color: 'red',
        marginLeft: theme.spacing(2),
        whiteSpace: 'pre-wrap' as 'pre-wrap'
    },
    initAvatar: {
        backgroundColor: '#4A4A4A',
        color: 'white',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.spacing(5),
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    },
    title:
    {
        display: 'flex',
        alignItems: 'center',
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    textinput:
    {
        marginLeft:theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingBottom:theme.spacing(2)
    }
});

class InviteGroup extends Component<InviteGroupProps> {

    state = {
        groupName: '',
        contactCodes: [],
        errorMsg: ''
    }

    handleError = (err: any) => {
        this.props.handleError(err);
    }

    componentDidMount() {
        if (this.props.contactGroupName)
            this.setState({ groupName: this.props.contactGroupName })
    }

    componentDidUpdate(prevProps: any) {
        const { updating, onActionComplete, addContactGroupError } = this.props;
        if (prevProps.updating !== updating) {
            if (!updating && addContactGroupError === null) {
                this.setState({ errorMsg: '' })
                onActionComplete("Create a Group", true, `Contact Group ${this.state.groupName} created`)
            }
            else {
                if (addContactGroupError && addContactGroupError.response && addContactGroupError.response.data.errCode) {
                    this.setState({ errorMsg: addContactGroupError.response.data.errMsg })
                    if (addContactGroupError.response.data.errCode === 401) {
                        this.props.handleError(addContactGroupError);
                    }
                }
                else {
                    this.props.handleError(addContactGroupError);
                }
            }
        }
    }

    handleGroupNameChange = (event: any, value: any) => {
        this.setState({ groupName: value, errorMsg: '' })
    }

    handleContactChange = (value: any) => {
        this.setState({ contactCodes: value, errorMsg: '' })
    }

    onAddContactGroupClick = () => {
        if (this.props.contactGroupId) {
            this.props.addContactGroup(
                this.state.groupName,
                this.state.contactCodes,
                this.props.contactGroupId
            )
        }
        else {
            this.props.addContactGroup(
                this.state.groupName,
                this.state.contactCodes,
                null
            )
        }

        if ( this.props.error === null ) {
            this.props.updateSnackbar({
                modalOpen: true,
                content: `Group ${this.props.contactGroupName} has been modified.`
            })
        } else {
            this.props.updateSnackbar({
                modalOpen: true,
                content: `Unable to edit Group ${this.props.contactGroupName}. Please try again later.`
            })
        }
    }

    onCancelClick = () => {
        this.props.onActionComplete(null, false, null)
    }

    render() {
        const { classes, contactGroupId, contactGroupName, modalWidth } = this.props;

        return (
            <div className={classes.root} style={modalWidth ? { width: modalWidth } : { width: 'auto' }}>
                {contactGroupName ?
                    <div className={classes.title}>
                        <div className={classes.initAvatar} >
                            {(contactGroupName || '').charAt(0).toUpperCase()}
                        </div>
                        {contactGroupName}
                    </div>
                    :
                    <InviteHeader title="Create a Group" description="Groups contain more than one Contact." />}
                <div className={classes.textinput}>
                    <TextInput
                        size="medium"
                        isFocus={true}
                        defaultValue={contactGroupName}
                        id={contactGroupName ? "Change Group Name" : "Group Name"}
                        validateRegex="^[A-Za-z0-9_]+$"
                        handleInPutChange={this.handleGroupNameChange}
                        assistiveText="Name accepts only alphanumeric and underscore." >
                    </TextInput>
                </div>
                <ContactListSearch contactGroupId={contactGroupId} handleChange={this.handleContactChange} handleError={this.handleError} />
                {this.state.errorMsg ? <div className={classes.errorMsg}>{this.state.errorMsg} </div> : null}
                <div className={classes.buttonGroup}>
                    <Button className={classes.cancelBtn} onClick={this.onCancelClick.bind(this)}>Cancel</Button>
                    <FabButton id="sendInvite" disabled={(this.state.groupName === '')}
                        text={contactGroupId ? "SAVE" : "CREATE GROUP"}
                        onClick={this.onAddContactGroupClick.bind(this)} />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        updating: state.contactsReducer.groups.updating,
        addContactGroupError: state.contactsReducer.groups.error,
        snackbarData: state.appUIStateReducer.snackbar,
        error: state.contactsReducer.groups.error
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addContactGroup: (groupName: string, contactCodes: [], contactGroupId: string) => dispatch({
            type: ContactActionTypes.GROUP_API_ADD_GROUPS_REQUEST,
            groupName: groupName,
            contactCodes: contactCodes,
            contactGroupId: contactGroupId
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(InviteGroup));