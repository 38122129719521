import React from 'react';
import { content_type, ContactContent, TableData, TableDataCell } from "../../shared/contactListTypes";
import { ItemList } from '../../../../../shared/ui';

const NO_CONTACTS_MSG = 'There are no contacts yet. Please invite them using \'Add New > Invite a contact\'.';

const contactsDataProc = ( data: ContactContent[] ) => {
    let filteredContacts: TableData[] = [];
    data.forEach((el) => {
        const cell1:TableDataCell = {
          content: {
            s1: {
              type: content_type.LINK,
              content: {
                data: el.status === 'PENDING' ? el.contactNickname : el.contactFirstName + ' ' + el.contactLastName,
                link: `/personal/contacts-detail/${el.contactCode}`
              },
              bold: true
            },
            s2: {
              type: content_type.ICON,
              content: el.status === 'PENDING' ? 'PENDING': 'ACTIVE',
              bold: false
            },
            s3: {
              type: content_type.TEXT,
              content: el.contactEmail,
              bold: false
            },
            s4: {
              type: content_type.TEXT,
              content: '',
              bold: false
            }
          },
          resizeShown: true
        }
  
        const cell2:TableDataCell = {
          content: {
            s1: {
              type: content_type.TEXT,
              content: el.contactEmail,
              bold: false
            },
            s2: {
              type: content_type.TEXT,
              content: '',
              bold: false
            },
            s3: {
              type: content_type.TEXT,
              content: '',
              bold: false
            },
            s4: {
              type: content_type.TEXT,
              content: '',
              bold: false
            }
          },
          resizeShown: false
        }
  
        const cell3:TableDataCell = {
          content: {
            s1: {
              type: content_type.DATE,
              content: el.createdAt,
              bold: false
            },
            s2: {
              type: content_type.TEXT,
              content: '',
              bold: false
            },
            s3: {
              type: content_type.TEXT,
              content: '',
              bold: false
            },
            s4: {
              type: content_type.TEXT,
              content: '',
              bold: false
            }
          },
          resizeShown: false
        }
  
        filteredContacts.push({
          id: el.contactCode,
          cells: [
            cell1,
            cell2,
            cell3
          ]
        })
    })
  
    return filteredContacts;
}

const itemActionMenuProc = ( data: any ) => {
    let action_menu : any[] = [];
    data.forEach( (el:any) => {
        if (el.status === 'PENDING') {
            action_menu.push({
                id: el.contactCode,
                menu: [
                    {
                        id: 'resendRequest',
                        label: 'Resend Request'
                    },
                    {
                        id: 'cancelRequest',
                        label: 'Cancel Request'
                    },
                    {
                        id: 'viewDetail',
                        label: 'View Details'
                    }
                ]
            })
        } else {
            action_menu.push({
                id: el.contactCode,
                menu: [
                    {
                        id: 'removeContact',
                        label: 'Remove Contact'
                    },
                    {
                        id: 'viewDetail',
                        label: 'View Details'
                    }
                ]
            })
        }
    })

    return action_menu;
}
  
const ContactList = (props: any) => {
    const columns = [
      {
          id: 'NAME',
          label: 'Name',
          sortable: true,
          mustShow: true,
      },{
          id: 'EMAIL',
          label: 'Email',
          sortable: false,
          mustShow: true,
      },{
          id: 'CREATED_AT',
          label: 'Added',
          sortable: true,
          mustShow: false,
      }
    ];
  
    const selectable = false;
  
    const batchAction = {
        enable: false,
        menu: []
    }
  
    const itemAction = {
        enable: true,
        menu: itemActionMenuProc( props.data )
    }
  
    const pagination = {
        pageSize: 5,
        pageNumber: 0
    }

    const rowOnClick = () => {
      console.log('rowOnClick!')
    }

    if( props.data.length > 0 ) {
      return (
        <ItemList data={ contactsDataProc(props.data) } 
                  columns={ columns } 
                  selectable={ selectable } 
                  rowOnClick={rowOnClick}
                  batchAction={ batchAction } 
                  itemAction={ itemAction }
                  pagination= { pagination }
                  itemOnClick={ props.itemOnClick }
                  sortingOnClick={ props.sortingOnClick }
                  // fetchMoreData={this.fetchMoreData }
                  // lazyLoading={this.props.groupContacts.lazyloading}
                  // noMoreData={this.state.noMoreData }
                  tableMarginHeight = { props.tableMarginHeight } />
      );
    } else {
      return ( <p style={{'padding': '1em', 'margin': 0}}>{ NO_CONTACTS_MSG }</p> )
    }
}

export default ContactList;