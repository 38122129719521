import React, { Component } from 'react';
import { Divider, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fab, Link } from '@material-ui/core';
import TextInput from '../../../../../shared/core/TextInput';
import UserType from '../../../../../shared/core/UserType';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import clsx from 'clsx';
import { styles } from './signupstyles';
import Password from '../Password';
import { connect } from 'react-redux';

type SignupProps = {
    classes: any;
    onSignup: any;
    errorMessage: any;
    onCancel: any;
    loading: any;
    defaultUser?: UserType;
    features?: any;
}

type SignupState = {
    user: UserType,
    showPassword: boolean,
    showConfirmPassword: boolean,
    confirmpassword: string,
    ispasswordMatch: boolean,
    validpassword: boolean,
    errorMessage: string,
    given_name_valid: boolean,
    middle_name_valid: boolean,
    family_name_valid: boolean,
    email_valid: boolean,
    requireFieldsValidate: boolean,
    required: boolean,
    dob_empty: boolean,
    readOnly?: boolean,
    disabled?: boolean
}

class Signup extends Component<SignupProps, SignupState> {

    // constructor(props: SignupProps) {
    //     super(props);
    //     let { defaultUser } = this.props;
    //     this.initState(defaultUser);
    // }

    initState(defaultUser: any) {
        this.setState({
            user: defaultUser ? defaultUser : new UserType(),
            showPassword: false,
            showConfirmPassword: false,
            confirmpassword: '',
            validpassword: true,
            ispasswordMatch: true,
            errorMessage: this.props.errorMessage,
            given_name_valid: defaultUser ? true : false,
            middle_name_valid: defaultUser ? true : true,
            family_name_valid: defaultUser ? true : false,
            email_valid: defaultUser ? true : false,
            requireFieldsValidate: false,
            required: true,
            dob_empty: false,
            readOnly: defaultUser ? true : false,
            disabled: defaultUser ? true : false,
        });
    }

    componentDidMount() {
        this.initState(this.props.defaultUser);
    }

    UNSAFE_componentWillMount() {
        let { defaultUser } = this.props;
        this.initState(defaultUser);
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        if (newProps.errorMessage !== this.props.errorMessage) {
            this.setState({ errorMessage: newProps.errorMessage })
        }
    }

    validate = (id: string, isvalid: boolean) => {
        switch (id) {
            case "given_name":
                this.setState(
                    {
                        given_name_valid: isvalid
                    })
                break;
            case "middle_name":
                this.setState(
                    {
                        middle_name_valid: isvalid
                    })
                break;
            case "family_name":
                this.setState(
                    {
                        family_name_valid: isvalid
                    })
                break;
            case "email":
                this.setState(
                    {
                        email_valid: isvalid
                    })
                break;
            default:
                break;
        }
    }

    handleChange = (id: string, value: string) => {
        switch (id) {
            case "given_name":
                this.setState(prevState => {
                    return {
                        ...prevState,
                        user:
                        {
                            ...prevState.user,
                            given_name: value
                        }
                    }
                })
                break;
            case "middle_name":
                this.setState(prevState => {
                    return {
                        ...prevState,
                        user:
                        {
                            ...prevState.user,
                            middle_name: value
                        }
                    }
                })
                break;
            case "family_name":
                this.setState(prevState => {
                    return {
                        ...prevState,
                        user:
                        {
                            ...prevState.user,
                            family_name: value
                        }
                    }
                })
                break;
            case "email":
                this.setState(prevState => {
                    return {
                        ...prevState,
                        user:
                        {
                            ...prevState.user,
                            username: value,
                            email: value
                        }
                    }
                })
                break;
            case "password":
                this.setState(prevState => {
                    return {
                        ...prevState,
                        user:
                        {
                            ...prevState.user,
                            password: value,
                        }
                    }
                })
                break;
            default:
                break;
        }

        //Check if all the required fields were filled
    }

    pad = (number: any) => {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }


    handleDateChange = (date: any, value: any) => {
        this.setState(prevState => {
            return {
                ...prevState,
                user:
                {
                    ...prevState.user,
                    birthdate: value,
                }
            }
        });
    }

    handleSignUpClick = () => {
        //Check for all the mandatory fields 
        var temp = { ...this.state.user }
        //Validate all the fields
        this.setState({ requireFieldsValidate: true });
        if (this.state.confirmpassword != this.state.user.password)
            this.setState({ ispasswordMatch: false });
        if (this.state.user.password === null || this.state.user.password === '')
            this.setState({ validpassword: false });

        if (!this.state.given_name_valid ||
            !this.state.middle_name_valid ||
            !this.state.family_name_valid ||
            !this.state.email_valid) {
            this.setState({ errorMessage: "Please fill the required fields." })
            return;
        }
        else {
            this.setState({ errorMessage: "" })
        }
        // DOB Code Commented out , so that this can be used in future.
        // Check if the data entered is valif
        // if (!this.props.features.dobNotRequired) {

        //     if (null === this.state.user.birthdate) {
        //         this.setState({ dob_empty: true })
        //     }
        //     else {
        //         this.setState({ dob_empty: false })
        //     }

        //     if (null == this.state.user.birthdate || (null != this.state.user.birthdate && isNaN(new Date(this.state.user.birthdate).getDate()))) {
        //         this.setState({ errorMessage: "Please fill the required fields." })
        //         return;
        //     }

        //     if (new Date(this.state.user.birthdate) <= new Date('1900-01-01')) {
        //         this.setState({ errorMessage: "Date of birth should be later than 01/01/1900" })
        //     }

        //     if (new Date(this.state.user.birthdate) > new Date()) {
        //         this.setState({ errorMessage: "Date of birth should be earlier than today's date" })
        //     }
        // }


        if (this.state.validpassword && this.state.confirmpassword === this.state.user.password) {
            this.setState({ errorMessage: "" })
            this.props.onSignup(temp);
        }
        else {
            this.setState({ errorMessage: "Please fill the required fields." })
        }
    }

    handlePasswordChange = (event: any, prop: any) => {
        var temp = event.target.value;
        switch (prop) {
            case "password":
                this.setState(prevState => {
                    return {
                        ...prevState,
                        user:
                        {
                            ...prevState.user,
                            password: temp
                        }
                    }
                })
                break;
            case "confirmpassword":
                this.setState({
                    confirmpassword: temp
                })
                break;
            default:
                break;
        }
    }

    handleShowPassword = (prop: any) => () => {
        switch (prop) {
            case "password":
                this.setState({
                    showPassword: !this.state.showPassword
                })
                break;
            case "confirmpassword":
                this.setState({
                    showConfirmPassword: !this.state.showConfirmPassword
                })
                break;
            default:
                break;
        }
    }

    isValidPassword = () => {
        const regex = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).*$/;
        if (this.state.user.password != null &&
            this.state.user.password.match(regex))
            this.setState({
                validpassword: true
            })
        else {
            this.setState({
                validpassword: false
            })
        }
    }

    ispasswordMatch = () => {
        if (this.state.confirmpassword === this.state.user.password)
            this.setState({
                ispasswordMatch: true
            })
        else
            this.setState({
                ispasswordMatch: false
            })
    }

    render() {

        const { classes, features } = this.props
        return (
            <div className={classes.root} data-test="signup">
                <div className={classes.signUp}>
                    <div className={classes.title}>Sign Up for Fidsafe</div>
                    <div className={classes.description}>Let's get started. Please fill out the following information.</div>
                    <Divider variant="middle" />
                    <div className={classes.subheader}>Account Information</div>
                    <div className={classes.subregion}>
                        <div className={classes.nameContainer}>
                            <div className={classes.nameLabel}>
                                Your Name
                            </div>
                            <div className={clsx(classes.textfields, classes.nameTextfield)}>
                                <div className={classes.nameTextInput}>
                                    <TextInput
                                        defaultValue={this.state.user.given_name}
                                        forceValidate={this.state.requireFieldsValidate}
                                        size="small"
                                        data-test="validNameTextInput"
                                        isFocus={true}
                                        id="given_name"
                                        validate={this.validate}
                                        validateRegex="^[a-zA-Z &amp;'.,-]*$"
                                        handleInPutChange={this.handleChange}
                                        assistiveText={"Please enter a valid name."}
                                        label="First"
                                        length={64}
                                        onSubmit={this.handleSignUpClick} >
                                    </TextInput>
                                </div>
                                <div className={classes.nameTextInput}>
                                    <TextInput
                                        defaultValue={this.state.user.middle_name}
                                        size="small"
                                        id="middle_name"
                                        validate={this.validate}
                                        validateRegex="^[a-zA-Z &amp;'.,-]*$"
                                        handleInPutChange={this.handleChange}
                                        label="Middle(optional)"
                                        assistiveText="Please enter a valid name."
                                        length={64} >
                                    </TextInput>
                                </div>
                                <div className={classes.nameTextInput}>
                                    <TextInput
                                        defaultValue={this.state.user.family_name}
                                        forceValidate={this.state.requireFieldsValidate}
                                        size="small"
                                        id="family_name"
                                        validate={this.validate}
                                        validateRegex="^[a-zA-Z &amp;'.,-]*$"
                                        handleInPutChange={this.handleChange}
                                        label="Last"
                                        assistiveText={"Please enter a valid name."}
                                        length={64}
                                        onSubmit={this.handleSignUpClick}>
                                    </TextInput>
                                </div>
                            </div>
                        </div>
                        <div className={classes.nameContainer} >
                            <div className={classes.nameLabel}>
                                Email
                            </div >
                            <div className={clsx(classes.textfields, classes.emailTextField)}>
                                <TextInput
                                    defaultValue={this.state.user.email}
                                    forceValidate={this.state.requireFieldsValidate}
                                    size="small"
                                    id="email"
                                    validate={this.validate}
                                    validateRegex="^[a-zA-Z0-9._%+-\\-`\']+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$"
                                    handleInPutChange={this.handleChange}
                                    label="Email"
                                    assistiveText={"Please enter a valid email."}
                                    readOnlyValid={this.state.readOnly}
                                    disabled={this.state.disabled}
                                    onSubmit={this.handleSignUpClick}>
                                </TextInput>
                            </div>
                        </div>

                        {/*  DOB Code Commented out , so that this can be used in future.
                        {
                            !features.dobNotRequired &&
                            <div className={classes.nameContainer}>
                                <div className={classes.nameLabel}>
                                    Date of Birth
                                </div>
                                <div className={classes.dob}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            style={{ marginTop: 0 }}
                                            emptyLabel=''
                                            variant="inline"
                                            autoOk
                                            format="MM/dd/yyyy"
                                            required={this.state.required}
                                            margin="normal"
                                            id="dob"
                                            value={this.state.user.birthdate ? this.state.user.birthdate : null}
                                            label="mm/dd/yyyy"
                                            onChange={this.handleDateChange}
                                            error={this.state.dob_empty}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            onSubmit={this.handleSignUpClick}
                                            disableFuture={true}
                                            maxDateMessage="Date of birth should be earlier than today's date"
                                            minDateMessage="Date of birth should be later than 01/01/1900"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        } */}
                    </div>
                    <Divider variant="middle" />
                    <div className={classes.subheader}>Password</div>
                    <div className={classes.subregion}>
                        <div className={classes.nameContainer}>
                            <div className={classes.nameLabel}>
                                Set Password
                            </div>
                            <div className={classes.passwordContainer}>
                                <Password
                                    id="password"
                                    guptshabd={this.state.user.password}
                                    handlePasswordChange={this.handlePasswordChange}
                                    validpassword={this.state.validpassword}
                                    isValidPassword={this.isValidPassword}
                                    helperText={"Please create a strong password: Use a minimum of 8 characters, using numbers, special characters and letters, both uppercase and lowercase. Special characters include % ' ( ) + , . - / : ; = ? \ ^ _ | ~ ! @ $"}
                                    onSubmit={this.handleSignUpClick}
                                />
                            </div>
                        </div>
                        <div className={classes.nameContainer}>
                            <div className={classes.nameLabel}>
                                Confirm Password
                            </div>
                            <div className={classes.passwordContainer}>
                                <Password
                                    id="confirmpassword"
                                    guptshabd={this.state.confirmpassword}
                                    handlePasswordChange={this.handlePasswordChange}
                                    validpassword={this.state.ispasswordMatch}
                                    isValidPassword={this.ispasswordMatch}
                                    helperText="Passwords do not match."
                                    onSubmit={this.handleSignUpClick}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider variant="middle" />
                </div>
                <Typography className={classes.errorMessage}>{this.state.errorMessage}</Typography>
                <div className={classes.row}>
                    <Link data-test="cancelLink" className={classes.cancelLink} onClick={() => { this.props.onCancel() }}>Cancel &amp; Exit</Link>
                    <Fab data-test="signupBtn" disabled={this.props.loading} variant='extended' color='primary' className={classes.fabBtn} onClick={this.handleSignUpClick} >
                        Sign Up
                    </Fab>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state: any) => {
    return {
        features: state.homeReducer.features
    }
}

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(Signup));
