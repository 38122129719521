import { takeLatest, call, put } from "redux-saga/effects";
import { INVITE_VERIFICATION_REQUEST, INVITE_VERIFICATION_SUCCESS, INVITE_VERIFICATION_FAILURE } from '../../constants/shared/inviteVerificationConstants'
import { getVerificationAPI } from '../../../shared/core/utils/apiFunctions'

export function* inviteVerificationWatcher() {
  yield takeLatest(INVITE_VERIFICATION_REQUEST, inviteVerificationSaga);
}

function* inviteVerificationSaga(action) {
  try {
      const dob = action.dob;
      const ssn = action.ssn;
      const inviteVerificationResp = yield call(getVerificationAPI, dob, ssn);
      if(inviteVerificationResp.status === 200) {
          yield put({ type: INVITE_VERIFICATION_SUCCESS, responseInfo: inviteVerificationResp });
      } else {
          yield put({ type: INVITE_VERIFICATION_FAILURE, error: 'FAILED TO VERIFY USER.' });
      }
  }
  catch (error) {
    yield put({ type: INVITE_VERIFICATION_FAILURE, error });
  }
}

export default inviteVerificationWatcher;
export { inviteVerificationSaga };
