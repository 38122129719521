import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as ReactLink } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Link from '@material-ui/core/Link';
import moment from 'moment-timezone';
import pendingIcon from '../../../../assets/pending.svg';

type ContactType = {
    createdAt: string,
    modifiedAt: string,
    contactCode: string,
    contactFirstName: string,
    contactLastName: string,
    contactNickname: string,
    contactEmail: string,
    status: string,
    statusUpdated: string
}

type ContactDetailProfileCardProps = {
    contact: ContactType;
    removeContactOnClick: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: `${theme.spacing(2)}px`
    },
    backBtnRow: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        borderBottom: '1px solid #e8e8e8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    backBtnIcon: {
        color: '#0978A4'
    },
    linkContainer: {
        fontSize: theme.typography.pxToRem(15),
        display: 'flex',
        alignItems: 'center'
    },
    profileContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        display: 'flex',
        flexDirection: 'row',
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center',
        flex: '1 auto'
    },
    leftIcon: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            marginTop: '.25em'
        }
    },
    initAvatar: {
        backgroundColor: '#4A4A4A',
        color: 'white',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.spacing(5),
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pendingLabel: {
        fontSize: '12px',
        color: '#7A7B7C',
        margin: '0 1em',
        display: 'flex',
        alignItems: 'center'
    },
    pendingIcon: {
        width: '16px',
        height: 'auto',
        marginRight: '0.5em'
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: '1 auto',
        marginLeft: '0.8em',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    contactName: {
        margin: 0,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: '0.5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    groups: {
        color: '#0978A4',
        margin: '.5em 0',
        fontSize: theme.typography.pxToRem(13)
    },
    groupLabel: {
        color: '#353636',
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(13),
    },
    detailsContainer: {
        display: 'flex',  
        flexDirection: 'column', 
        justifyContent: 'center',    
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
        }
    },
    contactEmail: {
        margin: 0,
        fontSize: theme.typography.pxToRem(13),
        letterSpacing: '0.1px',
        color: '#353636',
        marginBottom: theme.spacing(1)
    },
    emailLink: {
        color: '#0978A4',
        textDecoration: 'underline'
    },
    removeContactBtn: {
        color: '#0978A4',
        fontSize: theme.typography.pxToRem(15)
    }
  }),
);

const ContactDetailProfileCard = (props: ContactDetailProfileCardProps) => {
    const classes = useStyles();
    const content: any = {
        backBtnLabel: 'Back to All Contacts'
    }
    
    return(
        <>
            <div className={ classes.root }>
                <div className={ classes.backBtnRow }>
                    <ReactLink to={'/personal/contacts'} style={{color: '#0978A4'}} className={ classes.linkContainer }>
                        <ArrowBackIosIcon className={ classes.backBtnIcon }/> { content.backBtnLabel }
                    </ReactLink>
                </div>
                <div className={ classes.profileContainer }>
                    <div className={ classes.leftIcon }>
                        { props.contact.status === 'PENDING' && 
                            <div className={ classes.initAvatar } >
                                { (props.contact.contactNickname || '').charAt(0).toUpperCase() }
                            </div>
                        }
                        { props.contact.status !== 'PENDING' &&
                            <div className={ classes.initAvatar } >
                                { (props.contact.contactFirstName || '').charAt(0).toUpperCase() }
                                { (props.contact.contactLastName || '').charAt(0).toUpperCase() }
                            </div>
                        }
                    </div>
                    <div className={ classes.flexWrapper }>
                        <div className={ classes.leftSection }>
                            <div>
                                <p className={ classes.contactName }>
                                    { props.contact.status === 'PENDING' &&
                                        <span>{ props.contact.contactNickname }</span>
                                    }
                                    { props.contact.status !== 'PENDING' &&
                                        <span>{ props.contact.contactFirstName } { props.contact.contactLastName }</span>
                                    }
                                    { props.contact.status === 'PENDING' &&
                                        <div className={classes.pendingLabel}><img className={classes.pendingIcon} src={pendingIcon} alt="Pending"/> Request pending</div>
                                    }
                                </p>
                                {/* <p className={ classes.groups }><span className={ classes.groupLabel }>Groups:</span> Testing Groups... See more</p> */}
                            </div>
                        </div>
                        <div className={ classes.detailsContainer }>   
                            <p className={ classes.contactEmail }><b>Email:</b> <a className={ classes.emailLink }>{ props.contact.contactEmail }</a></p>
                            {/* <p className={ classes.contactEmail }><b>Added:</b> <Moment format="MMM D, YYYY h:mm:ss a">{ props.contact.createdAt }</Moment></p> */}
                            <p className={ classes.contactEmail }><b>Added:</b> { moment.utc((props.contact.createdAt)).local().format('MMM D, YYYY h:mm:ss A') }</p>
                            <Link className={ classes.removeContactBtn } onClick={ props.removeContactOnClick }>Remove Contact</Link> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactDetailProfileCard;