import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import './SessionTimeoutReload.scss';

type sessionTimeoutReloadContentProps = {
    yesAction: any
}

class SessionTimeoutReloadContent extends Component<sessionTimeoutReloadContentProps, {}>{
    content: any = {
        iconLink: '',
        title: 'Session timed out',
        body: 'Your session has timed out. Please refresh the page to continue.',
        buttonTwoLabel: 'Refresh Page'
    }

    render () {
        return (
            <div data-test='sessionTimeoutReloadComponent'>
                <DialogTitle id="common-modal-title" className="common-modal-title">
                    <span className="dialog-title">{ this.content.title }</span>
                </DialogTitle>
                <DialogContent className="common-modal-description-root">
                    <DialogContentText className="common-modal-description" id="common-modal-description">
                    <span className="description-content">{ this.content.body }</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="common-modal-actions">
                    <Button className="common-modal-button" variant="contained" onClick={ this.props.yesAction } color="primary" disableElevation>
                        { this.content.buttonTwoLabel }
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

export default SessionTimeoutReloadContent;