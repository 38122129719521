import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IconButton, Typography, Box, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TOGGLE_FILE_DETAIL_MENU, GET_SHARE_CONTACTS } from '../../../../../redux/constants/personalVault/myFilesConstants';
import { TOPIC_FILES_API_ACTIVITY_REQUEST } from '../../../../../redux/constants/customerVault/topicFileConstants.js'
import './FileDetailMenu.scss';
import CircularIndeterminate from '../../../../../shared/core/utils/CircularIndeterminate'
import { formattedDateTime } from '../../../../../shared/core/utils/functions';

enum FileActivityType {
    FILE_PREVIEW = "FILE_PREVIEW_TOPIC",
    FILE_DOWNLOAD = "FILE_DOWNLOAD_TOPIC",
    FILE_UPLOAD = "FILE_UPLOAD_TOPIC",
    FILE_DOWNLOAD_FOLDER = "FILE_DOWNLOAD_FOLDER",
    FILE_DELETE_TOPIC = "FILE_DELETE_TOPIC",
    CANCEL_CEREMONY = "CANCEL_CEREMONY",
    CREATE_CEREMONY = "CREATE_CEREMONY",
    INITIATE_CEREMONY = "INITIATE_CEREMONY",
    AGREE_CEREMONY = "AGREE_CEREMONY",
    DECLINE_CEREMONY = "DECLINE_CEREMONY",
    FILE_COPY = "FILE_COPY_TOPIC"
}

type FileDetailMenuProps = {
    toggleFileDetailMenu: any,
    selectedFileForPreview: any,
    selectedFileForViewDetails: any,
    getContactsSelected: any,
    getFileActivities: any,
    sharedContacts: any,
    menuOpen: any,
    children?: any,
    fileActivities: any,
    errorActivities: any
}

type FileDetailMenuStates = {}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`file-detail-tabpanel-${index}`}
            aria-labelledby={`file-detail-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}


class FileDetailMenu extends Component<FileDetailMenuProps, FileDetailMenuStates> {

    state = {
        value: 0
    }

    componentDidMount() {
        let topicCode = window.location.pathname.split('/').pop()
        let fileInfo = this.props.selectedFileForViewDetails ? this.props.selectedFileForViewDetails : this.props.selectedFileForPreview ? this.props.selectedFileForPreview : null
        if (fileInfo)
            this.props.getFileActivities(topicCode, fileInfo.itemCode)
    }

    closeMenu = () => {
        this.props.toggleFileDetailMenu(false)
    }

    handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
    };

    a11yProps = (index: any) => {
        return {
            id: `file-detail-menu-${index}`,
            'aria-controls': `file-detail-menu-${index}`,
        };
    }

    sizeConverter = (size: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '-';
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return Math.round(size / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    mapFileActivity = (value: any, fileInfo: any) => {
        let fileActivity: FileActivityType = value.eventType;
        let signedActivity: any = value.signedItemCode ? value.signedItemCode : null;
        let activityFrom = null
        let activityType = null
        let activityStatement = null
        const thefile = "the file."
        const has = "has"
        console.log(fileInfo.signedItem);
        switch (fileActivity) {
            case FileActivityType.FILE_DOWNLOAD:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = (fileInfo.signedItem != null) ? ((signedActivity !== null) ? "downloaded the signed" : "downloaded the original") : "downloaded";
                activityStatement = ` ${thefile}`
                break
            case FileActivityType.FILE_PREVIEW:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = (fileInfo.signedItem != null) ? ((signedActivity !== null) ? "previewed the signed" : "previewed the original") : "previewed";
                activityStatement = ` ${thefile}`
                break
            case FileActivityType.FILE_UPLOAD:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "uploaded"
                activityStatement = ` ${thefile}`
                break
            case FileActivityType.FILE_DELETE_TOPIC:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "deleted"
                activityStatement = ` ${thefile}`
                break
            case FileActivityType.FILE_DOWNLOAD_FOLDER:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "downloaded folder"
                activityStatement = ` ${thefile}`
                break
            case FileActivityType.AGREE_CEREMONY:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "accepted"
                activityStatement = `electronic authorization for ${thefile}`
                break;
            case FileActivityType.CREATE_CEREMONY:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "requested"
                activityStatement = `electronic authorization for ${thefile}`
                break;
            case FileActivityType.INITIATE_CEREMONY:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "begun"
                activityStatement = `electronic authorization for ${thefile}`
                break;
            case FileActivityType.CANCEL_CEREMONY:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "cancelled"
                activityStatement = `electronic authorization for ${thefile}`
                break;
            case FileActivityType.DECLINE_CEREMONY:
                activityFrom = `${this.getOwner(value)} ${has} `
                activityType = "declined"
                activityStatement = `electronic authorization for ${thefile}`
                break;
            case FileActivityType.FILE_COPY:
                activityFrom = `${value.owner.destPrdName} ${has} `
                activityType = "copied"
                activityStatement = ` the file from ${value.owner.srcPrdName}.`
                break;
            default:
                break

        }

        return { activityFrom, activityType, activityStatement }

    }

    renderLoader = () => {
        return (
            <CircularIndeterminate ></CircularIndeterminate>
        )
    }

    renderErrorFileActivity = (error: any) => {
        return (
            <div data-test="error-div" className="errorMessage" >
                {error.errMsg ? error.errMsg : null}
            </div>)
    }

    renderFileActivity = (fileActivities: any) => {
        if (fileActivities) {
            if (this.props.fileActivities.fetching)
                return this.renderLoader()
            else if (fileActivities.error)
                return this.renderErrorFileActivity(fileActivities.error)
            else if (fileActivities.activities)
                return this.renderActivitiesInfo(fileActivities.activities.data)
        }
    }

    renderActivitiesInfo = (activityInfo: any) => {
        let fileInfo = this.props.selectedFileForViewDetails ? this.props.selectedFileForViewDetails : this.props.selectedFileForPreview ? this.props.selectedFileForPreview : null
        if (activityInfo.length === 0)
            return (
                <div className="infoData" key='nodata'>
                    <div data-test="noActInfoData" className="infoDateValue">
                        This file's activity is temporarily unavailable. Please try again later.
                    </div>
                </div>
            )
        else
            return activityInfo.map((value: any, i: number) => {
                let { activityFrom, activityType, activityStatement } = this.mapFileActivity(value, fileInfo);
                return (
                    <div className="infoData" key={i}>
                        <div data-test='actInfoVal' className="infoValueActivity">
                        {activityFrom} <b>{activityType}</b> {activityStatement}
                        </div>
                        <div className="infoDateValue">
                            {formattedDateTime(value.timestamp)}
                        </div>
                    </div>
                )
            });

    }

    getOwner = (activity:any) => {
        if (activity.owner.isGroupOwner) {
          return activity.owner.grpName;
        } else if (activity.owner.isTenantOwner){
          return activity.owner.grpName;
        }
        else {
          const name = activity.owner.userLastName + ' '
            + activity.owner.userFirstName + ' '
            + (activity.owner.userMiddleName === undefined ? '' : activity.owner.userMiddleName);
          return name;
        }
      }

    renderFileOwnerDetails = (fileOwner: any) => {
        if (fileOwner.isGroupOwner)
            return (
                <div className="infoData">
                    <div className="infoHeader">
                        Owner
                    </div>
                    <div className="infoValue">
                        {fileOwner.grpName}
                    </div>
                </div>
            )
        else
            return null
    }

    renderFileInfo = (fileInfo: any) => {
        return (<div className='tab-wrapper'>
            <div className='info-block'>
                <h3 className='info-title'>General information</h3>
                <div className="infoData">
                    <div className="infoHeader">
                        File Name
                    </div>
                    <div className="infoValue" title={fileInfo.itemName}>
                        {fileInfo.itemName}
                    </div>
                </div>
                {this.renderFileOwnerDetails(fileInfo.owner)}
                <div className="infoData">
                    <div className="infoHeader">
                        Size
                    </div>
                    <div className="infoValue">
                        {this.sizeConverter(fileInfo.size)}
                    </div>
                </div>
                <div className="infoData">
                    <div className="infoHeader">
                        Uploaded
                    </div>
                    <div className="infoValue">
                        {formattedDateTime(fileInfo.createdAt)}
                    </div>
                </div>
                <div className="infoData">
                    <div className="infoHeader">
                        Last Modified
                    </div>
                    <div className="infoValue">
                        {formattedDateTime(fileInfo.modifiedAt)}
                    </div>
                </div>
            </div>
            <Divider />
        </div>)
    }

    render() {
        let fileInfo = this.props.selectedFileForViewDetails ? this.props.selectedFileForViewDetails : this.props.selectedFileForPreview ? this.props.selectedFileForPreview : null
        if (fileInfo)
            return (
                <div className='menu-wrapper' data-test='file-detail-menu'>
                    <div className="file-header">
                        <div className="menu-heading">
                            File Details
                        </div>
                        {this.props.selectedFileForPreview && <IconButton data-test='close-menu-btn' title="Close" aria-label="Close Menu" className='menu-close-btn' onClick={this.closeMenu}>
                            <CloseIcon />
                        </IconButton>
                        }
                    </div>
                    {this.renderFileInfo(fileInfo)}
                    <div className='activity-content'>
                        <div className='tab-wrapper'>
                            <div className='info-block'>
                                <h3 className='info-title'>File activity</h3>
                                {this.renderFileActivity(this.props.fileActivities)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return null
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileForPreview: state.appUIStateReducer.selectedFileToPreview,
        selectedFileForViewDetails: state.appUIStateReducer.selectedFileToViewDetails,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen,
        sharedContacts: state.shareContactReducer.shareContacts,
        fileActivities: state.topicFilesReducer.fileActivities,
        errorActivities: state.topicFilesReducer.fileActivities.error
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleFileDetailMenu: (open: boolean) => dispatch({ type: TOGGLE_FILE_DETAIL_MENU, menuOpen: open }),
        getContactsSelected: (itemCode: any) => dispatch({
            type: GET_SHARE_CONTACTS,
            itemCode: itemCode
        }),
        getFileActivities: (topicCode: any, itemCode: any) => dispatch({ type: TOPIC_FILES_API_ACTIVITY_REQUEST, topicCode, itemCode })
    }
}


export const FileDetailMenuComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(FileDetailMenu);



export default withRouter(FileDetailMenuComponent);