import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as TopicActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import * as TopicFollowUsersActionTypes from '../../../../../redux/constants/customerVault/topicFollowConstants';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ContactsSearchBar from '../../../../../components/customerVault/contactsSearchBar/ContactsSearchBar';
import FollowersListSearchBar from '../../../../../components/customerVault/followersListSearchBar/FollowersListSearchBar';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import CircularIndeterminate from '../../../../../shared/core/utils/CircularIndeterminate';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Typography, Link } from '@material-ui/core';
import './AddTopic.scss';
import * as GAConstants from '../../../../../shared/core/GAConstants';
import ReactGA from 'react-ga';

type AddTopicProps = {
    classes: any;
    onActionComplete: any;
    handleError: any,
    addTopicProgress: any,
    addTopicError: any,
    snackbarData: any,
    updateSnackbar: any,
    addTopic: any,
    getTopicCategoriesFetching: any,
    getTopicCategoriesError: any,
    getTopicCategories: any,
    getCategories: any,
    topicContacts?: any,
    categoryExists: boolean,
    title?: string,
    topicFollowers: any,
    addedTopic: any,
    addTopicFollowers: any,
    contacts: {[key: string]: any}[];
    features: {[key: string]: any};
    addMetadata:Function;
    topicMetadata: {[key: string]: any};
    selectedGroup: string;
}

export const styles = (theme: any) => ({
    root: {
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
        fontFamily: 'Fidelity Sans',
        fontSize: '1rem',
        width: 300,
        minHeight: 200,
        //Check if it is not mobile
        [theme.breakpoints.up('sm')]: {
            width: 500,
        }
    },
    textfield: {
        width: '90%',
        height: 56,
        margin: theme.spacing(2)
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(2),
    },
    cancelBtn: {
        color: "#646D7D",
        textTransform: 'none' as 'none'
    },
    errorMsg: {
        fontSize: '0.8rem',
        width: '100%',
        color: 'red',
        marginLeft: theme.spacing(2),
        whiteSpace: 'pre-wrap' as 'pre-wrap'
    },
    title:
    {

        fontFamily: 'Fidelity Sans',
        display: 'flex',
        alignItems: 'center',
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    textinput:
    {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem'
    },
    loadingContent: {
        height: '35vh',
        zIndex: 999,
        backgroundColor:'white'
    },
    loaderTitle1: {
        position: 'absolute' as 'absolute',
        bottom: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '1.2rem'

    },
    loaderTitle2: {
        position: 'absolute' as 'absolute',
        bottom: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '0.75rem'

    }
});

class AddTopic extends Component<AddTopicProps> {

    state = {
        topicName: '',
        sharedTopicName: '',
        categoryCode: '',
        categories: [{
            categoryName: ''
        }],
        contactCodes: [],
        instUserCodes: [],
        errorMsg: '',
        loading: true,
        isTopicValid: true,
        topicNameBeenChanged: false,
        placeholderName: 'Add a Topic',
        showAutoFillHelperText: false,
        prevCatName: '',
        isUpdating: false,
        followersUpdateComplete: false,
        metadataUpdateComplete: false
    }

    handleError = (err: any) => {
        this.props.handleError(err);
    }

    componentDidMount() {
        if (!this.props.categoryExists)
            this.props.getCategories();
        else {
            this.setState({ loading: false })
            this.setState({ categories: this.props.getTopicCategories.content });
        }
    }

    componentDidUpdate(prevProps: any) {
        const { onActionComplete, addTopicProgress, addTopicError, getTopicCategoriesFetching, topicFollowers, addedTopic, topicMetadata } = this.props;

        if (!this.props.categoryExists) {
            if (prevProps.getTopicCategoriesFetching != getTopicCategoriesFetching && !getTopicCategoriesFetching) {
                this.setState({ loading: false })
                this.setState({ categories: this.props.getTopicCategories.content });
            }
        }

        if (prevProps.addTopicProgress != addTopicProgress && this.state.instUserCodes.length > 0) {
            if (addTopicProgress)
                this.setState({ isUpdating: true })
        }

        if (prevProps.addTopicProgress != addTopicProgress && !addTopicProgress) {
            this.setState({ isUpdating: true })
            if (addedTopic !== prevProps.addedTopic) {
                if (this.state.instUserCodes.length > 0) {
                    this.props.addTopicFollowers(addedTopic.stCode, this.state.instUserCodes)
                }
                else {
                    this.setState({followersUpdateComplete: true});
                }

                if(this.shouldAddMetadata()) {
                    this.props.addMetadata(addedTopic.stCode, this.getMetadata());
                }
                else {
                    this.setState({metadataUpdateComplete: true});
                }

            }

            if (addTopicError === null && this.state.instUserCodes.length === 0 && !this.shouldAddMetadata()) {
                this.setState({ errorMsg: '', isUpdating: false })
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Topic ${this.state.sharedTopicName} has been created.`
                })
                ReactGA.event({
                    category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                    action: GAConstants.ADD_TOPIC,
                    //label: this.state.topicName
                });

                onActionComplete(true);
            }
            else if (addTopicError && addTopicError.response && addTopicError.response.data.errCode) {
                this.setState({ errorMsg: addTopicError.response.data.errMsg, isUpdating: false })
                this.props.handleError(addTopicError);
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Unable to create Topic ${this.state.topicName}. Please try again later.`
                })
            }
        }
        if (prevProps.topicFollowers !== topicFollowers){
            if (!topicFollowers.updating && topicFollowers.error && this.state.instUserCodes.length > 0) {
                this.setState({followersUpdateComplete: true});
                this.setState({ isUpdating: false })

                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Topic ${this.state.sharedTopicName} has been created. There was a problem in adding Followers. Please try again.`
                })
                ReactGA.event({
                    category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                    action: GAConstants.ADD_TOPIC_FOLLOWERS_ERROR,
                    //label: this.state.topicName
                });
                onActionComplete(true);
                
            }
            else if(topicFollowers.followers && !topicFollowers.updating) {
                this.setState({followersUpdateComplete: true});
                if(this.state.metadataUpdateComplete) {
                    this.setState({ errorMsg: '', isUpdating: false })

                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: `Topic ${this.state.sharedTopicName} has been created.`
                    })
                    ReactGA.event({
                        category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                        action: GAConstants.ADD_TOPIC,
                        //label: this.state.topicName
                    });

                    onActionComplete(true);
                }
            }
        }
        if (prevProps.topicMetadata.updating != topicMetadata.updating && !topicMetadata.updating) {
            this.setState({metadataUpdateComplete: true});
            if(topicMetadata.error) {
                this.setState({ isUpdating: false })

                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Topic ${this.state.sharedTopicName} has been created. There was a problem in adding metadata. Please try again.`
                })
                ReactGA.event({
                    category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                    action: GAConstants.ADD_TOPIC_METADATA_ERROR,
                    //label: this.state.topicName
                });
                onActionComplete(true);
            }
            else {
                if(this.state.followersUpdateComplete) {
                    this.setState({ errorMsg: '', isUpdating: false })

                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: `Topic ${this.state.sharedTopicName} has been created.`
                    })
                    ReactGA.event({
                        category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                        action: GAConstants.ADD_TOPIC,
                        //label: this.state.topicName
                    });

                    onActionComplete(true);
                }
            }
        }
    }

    shouldAddMetadata = () => {
        let addMetadata = false;
        if (Array.isArray(this.props.features.metadata) && this.props.features.metadata.length) {
            let self = this;
            this.props.features.metadata.forEach(function (element: any) {
                //Check the topic is of AddMetadata category/group
                const groupCode = element.groupCode;
                const categoryCode = element.categoryCode
                if (groupCode != undefined && categoryCode != undefined) {
                    if(groupCode === self.props.selectedGroup && categoryCode === self.state.categoryCode) {
                        addMetadata = true;
                    }
                }
                else if (groupCode != undefined && groupCode === self.props.selectedGroup) {
                    addMetadata = true;
                }
                else if (categoryCode != undefined && categoryCode === self.state.categoryCode) {
                    addMetadata = true;
                }
            });
        }
        return addMetadata;
    }
    
    getMetadata = () => {
        let metaData = {};
        const userCode = this.state.contactCodes[0];
        const user = this.props.contacts.find(contact => contact.userCode === userCode);
        if(user !== undefined) {
            metaData =  {"formData":{"firstName":user.firstName, "lastName":user.lastName}};
        }
        return metaData
    }

    handleContactChange = (value: any) => {
        this.setState({ contactCodes: value, errorMsg: '' })
    }

    handleInstUsersChange = (value: any) => {
        this.setState({ instUserCodes: value, errorMsg: '' })
    }

    onEditContacts = () => {
        this.props.addTopic(
            this.state.sharedTopicName,
            this.state.categoryCode,
            this.state.contactCodes,
            new Date(),
            this.state.instUserCodes
        )
    }

    onCancelClick = () => {
        this.props.onActionComplete(false)
    }

    changeTopicName = (event: { target: { value: any; }; }) => {
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9 ()\/@&'.,_[\]$-]*$/sg
        if (event.target.value.match(regex)) {
            this.setState({ isTopicValid: true })
        }
        else {
            this.setState({ isTopicValid: false });
        }
        if (event.target.value.length > 100) {
            this.setState({ isTopicValid: false })
        }
        this.setState({ sharedTopicName: event.target.value, errorMessage: null })

        if (event.target.value.length === 0) {
            this.setState({
                topicNameBeenChanged: false,
                showAutoFillHelperText: false
            })
        } else {
            this.setState({
                topicNameBeenChanged: true,
            })
            if (this.state.topicName.includes(event.target.value)) {
                this.setState({
                    showAutoFillHelperText: true
                })
            } else {
                this.setState({
                    showAutoFillHelperText: false
                })
            }
        }
    }

    handleCategoryChange = (category: any) => {
        if (category != null)
            this.setState({
                topicName: category.categoryName,
                categoryCode: category.categoryCode
            })
        else
            this.setState({
                topicName: '',
                categoryCode: ''
            })
    }

    renderLoaderContent = () => {
        const { classes } = this.props;
        return (
            <div className={classes.root} data-test='addTopicWrapper'>
                <div className={classes.loadingContent}>
                    <CircularIndeterminate></CircularIndeterminate>
                    <div className={classes.loaderTitle1}>
                        Saving
                </div>
                    <div className={classes.loaderTitle2}>
                        This could take a minute.
                </div>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        if (this.state.isUpdating)
            return this.renderLoaderContent()
        else
            return (
                <div className={classes.root} data-test='addTopicWrapper'>
                    <Typography variant="h6" className={classes.title} data-test='title1'>
                        {this.props.title ? this.props.title : 'Add To Topic'}
                    </Typography>
                    <Typography variant="caption" className={classes.title} data-test='title2'>
                        Note: You will be automatically added to this Topic as a follower.
                     </Typography>
                    {this.state.loading ?
                        <CircularIndeterminate></CircularIndeterminate> :
                        <>
                            <TextField
                                //inputProps={{ pattern: "^[-@.\/()\/@&'.,_\-*$.A-Za-z0-9]*$" }}
                                inputProps={{ maxLength: 101 }}
                                error={!this.state.isTopicValid} className='add-topic-textfield' label="Add a Topic Name" variant="outlined"
                                // helperText="Topic Name must be AlphaNumeric and can have symbols ()/@&'.,_-"
                                value={this.state.sharedTopicName} onChange={this.changeTopicName.bind(this)} />
                            { !this.state.isTopicValid && <div className='helper-text-wrapper'>
                                <p className="topic-helper-text">Topic name should contain letters, numbers, and/or symbols ()/@&'.,_-$</p>
                                <p className="topic-helper-text">Topic name must be less than 100 characters.</p>
                            </div>}
                            <Autocomplete
                                id="combo-box-demo"
                                options={this.state.categories}
                                getOptionLabel={(option) => option.categoryName}
                                renderInput={(params) => <TextField {...params}
                                    className='add-topic-textfield' label="Select a Category" variant="outlined" />}
                                onChange={(event: any, option: any) => this.handleCategoryChange(option)}
                            />
                            {/* <ContactListSearchBar handleChange={this.handleContactChange} data-test='contactsearchbar'
                                topicContacts={this.props.topicContacts != null ? this.props.topicContacts : []} /> */}
                            <ContactsSearchBar handleChange={this.handleContactChange} data-test='contactsearchbar'
                                topicContacts={this.props.topicContacts != null ? this.props.topicContacts : []} />
                            <FollowersListSearchBar handleInstUsersChange={this.handleInstUsersChange} data-test='followerssearchbar' />
                        </>
                    }
                    {this.state.errorMsg ? <div className={classes.errorMsg}>{this.state.errorMsg} </div> : null}
                    <div className='add-topic-modal-actions'>
                        <Link id="cancelInvite" className='topic-link-cancel' href="#" onClick={this.onCancelClick.bind(this)}>
                            Cancel
                    </Link>
                        <Button className="common-modal-button"
                            id="sendInvite"
                            variant="contained"
                            onClick={this.onEditContacts.bind(this)}
                            disabled={this.state.loading || this.state.contactCodes.length === 0 || this.state.topicName === '' || this.state.sharedTopicName === ''
                                || this.props.addTopicProgress || !this.state.isTopicValid
                                || this.state.sharedTopicName.trim() === '' || this.props.topicFollowers.updating}
                            color="primary"
                            disableElevation>
                            Save Topic
                    </Button>
                    </div>
                </div >
            )
    }
}

const mapStateToProps = (state: any) => {
    return {
        addTopicProgress: state.topicReducer.topicList.updating,
        addTopicError: state.topicReducer.topicList.error,
        addedTopic: state.topicReducer.topicList.addedTopic,
        getTopicCategoriesFetching: state.topicReducer.topicCategories.fetching,
        getTopicCategoriesError: state.topicReducer.topicCategories.error,
        getTopicCategories: state.topicReducer.topicCategories.categoryData,
        topicFollowers: state.topicFollowReducer.topicFollowUsers,
        snackbarData: state.appUIStateReducer.snackbar,
        selectedGroup: state.appUIStateReducer.selectedGroup.grpCode,
        contacts: state.userReducer.groupContacts.contactsData.content,
        topicMetadata: state.topicReducer.topicMetadata,
        features: state.homeReducer.features
    };
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        addTopic: (topicName: any, categoryCode: any, users: [], date: any, followers: []) => dispatch({
            type: TopicActionTypes.TOPICS_API_ADD_TOPICS_REQUEST,
            topicName: topicName,
            categoryCode: categoryCode,
            users: users,
            date: date,
            followers: followers
        }),
        addTopicFollowers: (topicCode: any, userCode: any) => dispatch({
            type: TopicFollowUsersActionTypes.FOLLOW_API_ADD_USERS_REQUEST,
            topicCode: topicCode,
            userCodes: userCode
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        getCategories: () => dispatch({
            type: TopicActionTypes.TOPICS_API_GET_CATEGORIES_REQUEST,
        }),
        addMetadata:(topicCode: string, metadata:{[key: string]: any}) => dispatch({
            type: TopicActionTypes.TOPICS_API_ADD_METADATA_REQUEST,
            topicCode: topicCode,
            metadata: metadata
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AddTopic));