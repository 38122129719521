import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ContactActionTypes from '../../../../../redux/constants/personalVault/contactsConstants';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import { fsPaginationContacts, fsPaginationGroups } from '../../../../../shared/core/utils/paginationDefaults';
import { AppBar, Tabs, Tab, Typography, Box, Paper, IconButton } from '@material-ui/core';
import ContactList from '../contactList/ContactList';
import GroupList from '../groupList/GroupList';
import { CommonModal } from '../../../../../shared/ui';
import RemoveContactContent from '../popUpContent/RemoveContacts';
import CancelInviteRequestContent from '../popUpContent/CancelInviteRequest';
import RemoveGroupContent from '../popUpContent/RemoveGroups';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import './ContactsView.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface PaginationType {
    page: any;
    size: any;
    direction: string;
    orderBy: string;
}

type ContactsViewProps = {
    contactPageData: any;
    contactsData: any;
    contactTabIndex: number;
    getContacts: any;
    deleteContact: any;
    getGroups: any,
    groupsData: any;
    deleteGroup: any;
    history: any;
    changeContactTab: any;
    snackbarData: any;
    updateSnackbar: any
}

type ContactsViewState = {
    value: number, 
    modalOpenState: boolean,
    pagination: PaginationType,
    snackbarOpenState: boolean,
    snackbarContent: string
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`contact-list-tabpanel-${index}`}
        aria-labelledby={`contact-list-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
}

class ContactsView extends Component< ContactsViewProps, ContactsViewState > {
    state = { 
        value: 0, 
        modalOpenState: false,
        pagination: {
            page: fsPaginationContacts.page,
            size: fsPaginationContacts.size,
            direction: fsPaginationContacts.direction,
            orderBy: fsPaginationContacts.orderBy
        },
        snackbarOpenState: false,
        snackbarContent: ''
    }

    modalContent: any;

    indicatorStyle = {
        height: '6px'
    }

    a11yProps = (index: any) => {
        return {
            id: `contact-list-${index}`,
            'aria-controls': `contact-list-${index}`,
        };
    }

    handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({value: newValue});
        this.props.changeContactTab( newValue )
    };

    componentDidMount() {
        this.props.getContacts( this.state.pagination );
        this.props.getGroups();
        this.setState({ value: this.props.contactTabIndex })
    }

    contactItemOnClick = (contactCode: any) => {
        switch ( contactCode.eventId ) {
            case 'removeContact':
                this.setState({ modalOpenState: true});
                this.modalContent =  
                    <RemoveContactContent 
                        cancelConfirm = { this.cancelConfirm }
                        removeContactConfirm = { () => this.removeContactConfirm( contactCode.itemId ) }/>
                break;
            case 'resendRequest':
                // this.setState({ snackbarOpenState: true });
                // this.setState({ snackbarContent: `Request has been resend.` });
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Your invite to ${this.findContactName(contactCode.itemId, true)} has been resent.`
                })
                break;
            case 'cancelRequest':
                this.setState({ modalOpenState: true})
                this.modalContent = 
                    <CancelInviteRequestContent
                        cancelConfirm = { this.cancelConfirm }
                        cancelIniviteRequestConfirm = { () => this.cancelIniviteRequestConfirm( contactCode.itemId ) } />
                break;
            case 'viewDetail':
                this.props.history.push(`/personal/contacts-detail/${contactCode.itemId}`);
                break;
            default:
                this.modalContent = null;
                this.setState({ modalOpenState: false })
        }
    }

    directionBoolean: boolean = true;
    new_sortBy: string = 'NAME';

    sortingContactOnClick = (sortBy: string) => {
        if (sortBy === this.new_sortBy) {
            this.directionBoolean = !this.directionBoolean;
        } else {
            this.new_sortBy = sortBy;
        }

        this.setState({
            pagination: {
                page: fsPaginationContacts.page,
                size: fsPaginationContacts.size,
                direction: this.directionBoolean ? 'ASC' : 'DESC',
                orderBy: this.new_sortBy
            }
        })
        
        this.props.getContacts(this.state.pagination);
    }

    sortingGroupOnClick = (sortBy: string) => {
        console.log(sortBy);
    }

    groupItemOnClick = (groupCode: any) => {
        if (groupCode.eventId === 'removeGroup') {
            this.setState({ modalOpenState: true });
            this.modalContent = 
                <RemoveGroupContent
                    cancelConfirm = { this.cancelConfirm }
                    removeGroupConfirm = { () => this.removeGroupConfirm( groupCode.itemId ) }/>
        } else {
          this.props.history.push(`/personal/contacts-groups-detail/${groupCode.itemId}`);
        }
    }

    modalOnClick = (data: any) => {
        console.log(data)
    }

    modalClose = () => {
        this.setState({ modalOpenState: false});
    }
    
    cancelConfirm = () => {
        this.setState({ modalOpenState: false});
    }

    removeContactConfirm = (contactCode: string) => {
        this.props.deleteContact(contactCode);
        this.setState({ modalOpenState : false });
        this.props.updateSnackbar({
            modalOpen: true,
            content: `Contact ${this.findContactName(contactCode, false)} has been removed.`
        })
        // this.setState({ snackbarOpenState: true });
        // this.setState({ snackbarContent: `Contact has been removed.` });
    }

    cancelIniviteRequestConfirm = ( contactCode: string) => {
        this.props.deleteContact(contactCode);
        this.setState({ modalOpenState: false });
        this.props.updateSnackbar({
            modalOpen: true,
            content: `Request ${this.findContactName(contactCode, true)} has been canceled.`
        })
        // this.setState({ snackbarOpenState: true });
        // this.setState({ snackbarContent: `Request has been canceled.` });
    }

    removeGroupConfirm = (groupCode: string) => {
        this.props.deleteGroup(groupCode);
        this.setState({ modalOpenState: false });
        // this.setState({ snackbarOpenState: true });
        // this.setState({ snackbarContent: `Group has been removed.` });
        this.props.updateSnackbar({
            modalOpen: true,
            content: `Group ${this.findGroupName(groupCode)} has been removed.`
        })
    }

    handleSnackbarClose = () => {
        // this.setState({ snackbarOpenState: false })
        this.props.updateSnackbar({
            modalOpen: false,
            content: ''
        })
    }

    findContactName = (contactCode: string, pending: boolean) => {
        const contact =  this.props.contactsData.contactsData.content.filter((contact: any) => {
            return contact.contactCode === contactCode
        })[0]
        let name = '';
        pending ? name = contact.contactFirstName : name = contact.contactFirstName + ' ' + contact.contactLastName;
        
        return name;
    }

    findGroupName = (groupCode: string) => {
        const group =  this.props.groupsData.groupsData.content.filter((group: any) => {
            return group.cgCode === groupCode
        })[0]

        return group.cgName;
    }

    render() {
        return(
            <div className="contactListWrapper">
                <CommonModal
                    modalOpen = { this.state.modalOpenState }
                    modalClose = { this.modalClose }
                    content={ this.modalContent }
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={ this.props.snackbarData.modalOpen }
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClose}
                    message={ this.props.snackbarData.content }
                    action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                    }
                />
                <Paper className="tabs-wrapper">
                    <AppBar position="static" className="tabs-appbar">
                        <Tabs className="contact-list-tabs"
                            TabIndicatorProps={{style: {height: '6px'}}}
                            value={ this.state.value } 
                            onChange={ this.handleTabsChange }
                            indicatorColor='primary'
                            aria-label="contact-list">
                            <Tab className="tab-btn" label={<span className="tab-label">ALL CONTACTS</span>} {...this.a11yProps(0)}></Tab>
                            <Tab className="tab-btn" label={<span className="tab-label">ALL GROUPS</span>} {...this.a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.value } index={0}>
                        { this.props.contactsData.contactsData.content.length === 0 && 
                            <div className="contact-list-no-data-wrapper">There are no contacts yet. Please invite them using 'Add New &gt; Invite a contact'.</div>
                        }
                        { this.props.contactsData.contactsData.content.length > 0 &&
                            <ContactList 
                                fetching={ this.props.contactsData.fetching } 
                                data={ this.props.contactsData.contactsData.content } 
                                itemOnClick={ this.contactItemOnClick } 
                                sortingOnClick={ this.sortingContactOnClick }
                                tableMarginHeight={400}
                            />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value } index={1}>
                        { this.props.groupsData.groupsData.content.length === 0 && 
                            <div className="contact-list-no-data-wrapper">There are no contact groups yet. Please invite them using 'Add New &gt; Create a group'.</div>
                        }
                        { this.props.groupsData.groupsData.content.length > 0 &&
                            <GroupList 
                                fetching={ this.props.groupsData.fetching } 
                                data={ this.props.groupsData.groupsData.content } 
                                itemOnClick={ this.groupItemOnClick } 
                                sortingOnClick={ this.sortingGroupOnClick }
                                tableMarginHeight={400}
                            />
                        }
                    </TabPanel>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        contactPageData: state.contactsReducer,
        contactsData: state.contactsReducer.contacts,
        groupsData: state.contactsReducer.groups,
        contactTabIndex: state.appUIStateReducer.contactTabIndex,
        snackbarData: state.appUIStateReducer.snackbar
    }; 
}
  
const mapDispatchToProps = (dispatch: any) => {
    return {
        getContacts: (pagination: PaginationType) => dispatch({
            type: ContactActionTypes.CONTACT_API_GET_CONTACTS_REQUEST,
            pagination: pagination
        }),

        getGroups: () => dispatch({
            type: ContactActionTypes.GROUP_API_GET_GROUPS_REQUEST,
            pagination: fsPaginationGroups
        }),

        deleteContact: ( userCode: string ) => dispatch({
            type: ContactActionTypes.CONTACT_API_DELETE_CONTACTS_REQUEST,
            userCode: userCode
        }),

        deleteGroup: ( groupCode: string ) => dispatch({
            type: ContactActionTypes.GROUP_API_DELETE_GROUPS_REQUEST,
            groupCode: groupCode
        }),

        changeContactTab: (tabIndex: number) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_CONTACTS_PAGE_TABS,
            tabIndex: tabIndex
        }),

        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        })
    }
}
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactsView));