export default class  UserType {

    constructor(user?: any) {
        if(user != null) {
            this.username = user.email;
            this.given_name = user.firstName;
            this.family_name = user.lastName;
            this.middle_name = user.middleName;
            this.email = user.email;
            this.userCode = user.userCode;
            this.signUpCode = user.signUpCode;
        }
    }

    username?: string
    given_name?: string;
    family_name?: string;
    middle_name?: string;
    email?: string;
    birthdate?: string;         
    phone_number?: string;
    password?: string;
    signUpCode?: string;
    userCode?: string;
}
