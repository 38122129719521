import React, { Component, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as TopicFollowUsersActionTypes from '../../../../../redux/constants/customerVault/topicFollowConstants';
import * as InstUserActionTypes from '../../../../../redux/constants/customerVault/instUserConstants';
import { connect } from 'react-redux';
import { Button, DialogActions, DialogContent, ListItemSecondaryAction, DialogContentText, IconButton, List, Paper, Popper, TextField, ListItem, ListItemText, ClickAwayListener, InputBase } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { yellow } from '@material-ui/core/colors';

type followProps = {
    topicFollowers: any;
    unFollowTopic: any;
    addTopicFollowers: any;
    userProfile: any;
    classes: any;
    topicCode: any;
    getTopicFollowers: any;
    getInstUserList: any;
    instUserList: any;
    loggedIn: any;
}

type followState = {
    openPopper: boolean;
    selectedValues: any[];
    searchTerm: string;
}

export const styles = (theme: any) => ({
    dialogContent:
    {
        height: '300px',
        overflow: 'scroll',
        padding: '8px 8px'
    },
    followBtnRoot: {
        color: '#024A7A',
        border: '1px solid #024A7A',
        textTransform: 'none' as 'none'
    },
    followBtnLabel: {
        fontFamily: "Fidelity Sans",
        fontWeight: 'bold' as 'bold',
    },
    followingBtnRoot:
    {
        color: '#024A7A',
        borderColor: '#001D30',
        border: '2px solid #FFFFFF',
        textTransform: 'none' as 'none',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#e0e0e0'
        },
    },
    followingBtnLabel: {
        fontFamily: "Fidelity Sans",
        fontWeight: 'bold' as 'bold'
    },
    endIconStyle:
    {
        padding: "0px !important",
    },

    popper: {
        zIndex: 999,
        top: '10px !important',
        left: "-50px !important",
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    childpopper: {
        zIndex: 999,
    },
    arrow: {
        width: '15px',
        height: '15px',
        marginBottom: '-10px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey transparent transparent grey',
        transform: 'rotate(45deg)',
        backgroundColor: 'white',
        marginLeft: '35px'
    },
    paper: {
        maxWidth: 500,
        overflow: 'auto',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey',
        boxShadow: '0 1px 12px 0 rgba(0,0,0,0.2)'
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
        color: '#000000',
        fontFamily: "Fidelity Sans",
        fontSize: '16px',
        fontWeight: 'bold' as 'bold'
    },
    closeButton: {
        position: 'absolute' as 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    autocomplete: {
        display: 'flex',
        alignSelf: 'flex-start',
        margin: theme.spacing(2)
    },
    ListItem: {
        "&:hover $listItemSecondaryAction": {
            visibility: "inherit" as "inherit",
            //backgroundColor: '#d3d4d5 !important',
        }
    },
    listItemSecondaryAction: {
        visibility: "hidden" as "hidden",
    },
    listitemroot: {
        '&$selected, &$selected:hover, &$selected:focus': {
            backgroundColor: '#d3d4d5 !important',
        },
    },
    /* Styles applied to the root element if `selected={true}`. */
    menuItemSelected: {},
    unfollowbtn:
    {
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <div className="classes.title">{children}</div>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
});



class Follow extends Component<followProps, followState> {
    arrowRef: any;
    inputField: any;
    state: followState = {
        openPopper: false,
        selectedValues: [],
        searchTerm: '',
    }

    constructor(props: any) {
        super(props);
        this.arrowRef = React.createRef();
        this.inputField = React.createRef();
    }

    componentDidMount() {
        if(this.props.loggedIn.successful) {
            this.props.getTopicFollowers(this.props.topicCode);
            this.props.getInstUserList();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.topicFollowers.fetching &&
            !this.props.topicFollowers.fetching &&
            null == this.props.topicFollowers.error) {
            let selectedValues = this.state.selectedValues;
            this.props.topicFollowers.followers.map((x: any) => {
                selectedValues.push(x)
            })
            this.setState({ selectedValues: selectedValues })
        }
    }

    //Component Did update : add the selected values to the state
    onOpenPopper = (event: any) => {
        this.setState({ openPopper: !this.state.openPopper });
        this.arrowRef = event.currentTarget;
        event.stopPropagation();
        //Sort the selected values
        this.state.selectedValues.sort(function (a, b) {
            var nameA = a.lastName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.lastName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
    }


    handleInputChange = (option: any) => {
        this.setState({ searchTerm: '' });
        if (option != null) {
            let selectedList: string[] = this.state.selectedValues;
            selectedList.unshift(option)
            this.setState({ selectedValues: selectedList },
                () => {
                    this.props.addTopicFollowers(this.props.topicCode,
                        option.userCode);
                });

        }
    }

    onUnfollow = (option: any) => {
        if (option != null) {
            let selectedList: any[] = this.state.selectedValues;
            selectedList = selectedList.filter(user => user.userCode !== option.userCode)
            this.setState({ selectedValues: selectedList },
                () => {
                    this.props.unFollowTopic(this.props.topicCode,
                        option.userCode);
                });
        }
    }

    handleClickAway = () => {
        this.setState({ openPopper: false });
    };

    clearSelection = (event: any) => {
        this.setState({ searchTerm: event.target.value });
    }

    render() {
        const { classes } = this.props;
        return (
            <div data-test="followComp">
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div className='followBtnDiv'>
                        {
                            (null != this.state.selectedValues &&
                                this.state.selectedValues.filter(
                                    (x: any) => x.userCode == this.props.userProfile.userCode
                                ).length > 0) ?

                                <Button variant="contained" classes={{
                                    root: classes.followingBtnRoot,
                                    label: classes.followingBtnLabel
                                }}
                                    className='followbtn'
                                    data-test="followingbtn"
                                    startIcon={<CheckIcon fontSize="large" style={{ color: '#024A7A' }} />}
                                    disabled={this.props.topicFollowers.fetching}
                                    endIcon={<IconButton
                                        data-test="openpopperfollowing"
                                        className={classes.endIconStyle}
                                        disableRipple={true}
                                        onClick={(event) => this.onOpenPopper(event)}> <KeyboardArrowDownOutlinedIcon fontSize="large" style={{ color: '#024A7A' }} /></IconButton>}
                                    onClick={() => this.onUnfollow(this.props.userProfile)}>Following</Button>

                                :
                                <Button variant="outlined"
                                    buttonRef={this.arrowRef}
                                    classes={{
                                        root: classes.followBtnRoot,
                                        label: classes.followBtnLabel
                                    }}
                                    className='followbtn'
                                    startIcon={<AddIcon fontSize="large" style={{ color: '#024A7A' }} />}
                                    disabled={this.props.topicFollowers.fetching}
                                    data-test="followbtn"
                                    onClick={() => this.handleInputChange(this.props.userProfile)}
                                    endIcon={<IconButton
                                        data-test="openPopper_follow"
                                        className={classes.endIconStyle}
                                        disableRipple={true}
                                        style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
                                        onClick={(event) => this.onOpenPopper(event)}> <KeyboardArrowDownOutlinedIcon fontSize="large" style={{ color: '#024A7A' }} /></IconButton>}
                                >Follow</Button>
                        }
                        <Popper
                            open={this.state.openPopper}
                            placement="bottom"
                            anchorEl={this.arrowRef}
                            disablePortal={false}
                            className={classes.popper}
                            data-test="popper"
                            modifiers={{
                                flip: {
                                    enabled: true,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: 'scrollParent',
                                },
                                arrow: {
                                    enabled: true,
                                    element: this.arrowRef
                                },
                            }}
                        >
                            <div className={classes.arrow}></div>
                            <Paper className={classes.paper}>
                                <DialogTitle onClose={this.onOpenPopper}>{`Followers (${this.state.selectedValues != null ? this.state.selectedValues.length : 0} total)`}</DialogTitle>
                                <DialogContent
                                    classes={{
                                        root: classes.dialogContent
                                    }}>
                                    <Autocomplete data-test='instuserSerach'
                                        id="user-select"
                                        classes={{
                                            root: classes.autocomplete,
                                            paper: classes.paper
                                        }}
                                        PopperComponent={props => (<Popper {...props} className={classes.childpopper} placement="bottom-start" />)}
                                        disableClearable
                                        style={{ width: "350px" }}
                                        size="medium"
                                        renderOption={(option: any) => (
                                            <React.Fragment>
                                                <div className={classes.text}>
                                                    <div className={classes.textfield}>
                                                        {option.lastName != null ? `${option.lastName}, ` : ''}{option.firstName != null ? `${option.firstName}` : ''}
                                                    </div>
                                                    {option.email != null && <div className={classes.emailtext}>
                                                        {option.email}
                                                    </div>}
                                                </div>
                                            </React.Fragment>
                                        )}
                                        onChange={(option: any, value: any) => this.handleInputChange(value)}
                                        options={this.props.instUserList.content.
                                            filter((x: any) => (x.userCode != null && this.state.selectedValues.findIndex(user => user.userCode == x.userCode) < 0))}
                                        getOptionLabel={(option: any) => option.lastName + ', ' + option.firstName}
                                        disabled={false}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    value: this.state.searchTerm
                                                }}
                                                inputRef={this.inputField}
                                                label="Add Followers"
                                                variant="outlined"
                                                className={classes.textfield}
                                                autoFocus
                                                onChange={(event) => { this.clearSelection(event) }}
                                            />
                                        )}
                                    />
                                    <List>
                                        {
                                            this.state.selectedValues.
                                                map((value: any) => {
                                                    return this.props.instUserList.content
                                                        .filter((x: any) => (x.userCode != null && x.userCode === value.userCode))
                                                }).map((user: any) => {
                                                    return user.length > 0 && <ListItem
                                                        data-test={user[0].userCode} key={user[0].userCode} id={user[0].userCode} button
                                                        //onClick={() => this.optionOnSelect(contact)} 
                                                        classes={{
                                                            container: classes.ListItem,
                                                            root: classes.listitemroot,
                                                            selected: classes.menuItemSelected
                                                        }}>
                                                        <ListItemText primary={user[0].lastName + ', ' + user[0].firstName} secondary={user[0].email} />
                                                        <ListItemSecondaryAction
                                                            data-test={`${user[0].userCode}_unfollow`}
                                                            className={classes.listItemSecondaryAction}>
                                                            <span style={{ cursor: 'not-allowed' }}>
                                                                <IconButton edge="end" aria-label="UnFollow"
                                                                    className={classes.unfollowbtn}
                                                                    disableRipple={true}
                                                                    style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
                                                                    onClick={() => this.onUnfollow(user[0])}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </span>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                })
                                        }
                                    </List>
                                </DialogContent>
                            </Paper>
                        </Popper>


                    </div>
                </ClickAwayListener>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        topicFollowers: state.topicFollowReducer.topicFollowUsers,
        instUserList: state.instUserReducer.groupInstUsers.usersData,
        loggedIn: state.login
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getInstUserList: (topicCode: any, userCode: any) => dispatch({
            type: InstUserActionTypes.ADMIN_API_GET_INSTUSERS_REQUEST,
        }),
        addTopicFollowers: (topicCode: any, userCode: any) => dispatch({
            type: TopicFollowUsersActionTypes.FOLLOW_API_ADD_USERS_REQUEST,
            topicCode: topicCode,
            userCodes: userCode
        }),
        unFollowTopic: (topicCode: any, userCode: any) => dispatch({
            type: TopicFollowUsersActionTypes.FOLLOW_API_REMOVE_USERS_REQUEST,
            topicCode: topicCode,
            userCodes: userCode
        }),
        getTopicFollowers: (topicCode: any) => dispatch({
            type: TopicFollowUsersActionTypes.FOLLOW_API_GET_USERS_REQUEST,
            topicCode: topicCode
        })
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Follow));










