import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, Modal } from '@material-ui/core';
import { ReactComponent as ViewIcon } from '../../../assets/preview.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/download.svg';
import { ReactComponent as ShareIcon } from '../../../assets/share.svg';

import ShareContent from '../../../pages/personalVault/shared/components/ShareContent';
import DownloadFile from '../../../components/personalVault/downloadFile/DownloadFile'
import CommonModal from '../commonModal/commonModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileActionBar: {
            borderRight: '1px solid #c5c5c5',
            paddingRight: '.5em',
            marginRight: '.5em',
            [theme.breakpoints.down('md')]: {
                borderRight: 'none'
            }
        },
        iconBtn: {
            width: '20px',
            height: '20px',
        },
        iconBtnWrapper: {
            width: '44px',
            height: '44px'
        }
    }),
);


type CommonFileActionsBarProps = {
    selectedFiles?: any[],
    isShareEnabled?: boolean
}


const CommonFileActionsBar = (props: CommonFileActionsBarProps) => {
    const [ isModalOpen, setModalOpen ] = useState(false);
    const [ modalName, setmodalName ] = useState('');
    const classes = useStyles();
    var isSelected = (props.selectedFiles != null && props.selectedFiles.length > 0) ? true : false

    const openShareModal = (event: any) => {
        setModalOpen(true)
        setmodalName('share')
    }

    const openDownloadModal = (event: any) => {
        setModalOpen(true)
        setmodalName('download')
        setTimeout(function () {
            handleActionDialogClose();
        }, 5000);
    }

    const handleActionDialogClose = () => {
        setModalOpen(false)
    }

    const onActionComplete = () => {
        setModalOpen(false)
    }

    // const renderShareFolderModal = () => {
    //     return (<ShareContent modalOpen={isModalOpen}
    //         handleModalClose={handleActionDialogClose}
    //         onActionComplete={onActionComplete}
    //         selectedItems={(props.selectedFiles != null) ? props.selectedFiles : []} ></ShareContent>)
    // }

    const modalContentCreate = (name: string) => {
        switch (name) {
            case 'share':
                return <ShareContent modalOpen={isModalOpen}
                    handleModalClose={handleActionDialogClose}
                    onActionComplete={onActionComplete}
                    selectedItems={(props.selectedFiles != null) ? props.selectedFiles : []} ></ShareContent>
            case 'download':
                return <Modal
                    aria-describedby="simple-modal-description"
                    open={isModalOpen}
                    onClose={handleActionDialogClose}
                >
                    <div style={{ top: '40%', left: '40%', position: 'absolute' }}>
                        <DownloadFile 
                            selectedItems={(props.selectedFiles != null) ? props.selectedFiles : []} >
                        </DownloadFile>
                    </div>
                </Modal>
            default:
                return null
        }
    }

    const modalClose = () => {
        setModalOpen(false);
    }

    return (
        <div data-test='commonFileItemActions'>
            {isSelected &&
                <div className={classes.fileActionBar}>
                    {props.selectedFiles != null && props.selectedFiles.length === 1
                        && <IconButton aria-label="View" className={classes.iconBtnWrapper}>
                            <ViewIcon className={classes.iconBtn} aria-label="View" />
                        </IconButton>}
                    {props.isShareEnabled && isSelected && 
                        <IconButton data-test='shareBtn' aria-label="Share" className={classes.iconBtnWrapper} onClick={(event) => openShareModal(event)}>
                            <ShareIcon className={classes.iconBtn} aria-label="Share" />
                        </IconButton>
                    }
                    {props.selectedFiles != null && props.selectedFiles.length === 1
                        && props.selectedFiles[0].permissionName === 'DOWNLOAD' && <IconButton data-test='downloadBtn' aria-label="Download"
                            onClick={(event) => openDownloadModal(event)} >
                            <DownloadIcon className={classes.iconBtn} aria-label="Download" />
                        </IconButton>}
                </div>
            }
            {/* {isModalOpen && modalName === 'share' && renderShareFolderModal()} */}
            <CommonModal modalOpen = { isModalOpen }
                    modalClose = { modalClose }
                    content = {modalContentCreate(modalName)}/>
        </div>
    )
}

CommonFileActionsBar.defaultProps =
{
    isShareEnabled: false
}


export default CommonFileActionsBar;