import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ContactActionTypes from '../../../../../redux/constants/personalVault/contactsConstants';
import { withStyles } from '@material-ui/core/styles';
import TextInput from '../../../../../shared/core/TextInput';
import Button from '@material-ui/core/Button';
import InviteHeader from '../../shared/InviteHeader';
import FabButton from '../../../../../shared/core/FabButton';

type InviteContactProps = {
  addContact: any;
  classes: any;
  updating: boolean
  onActionComplete: any;
  addContactError: any;
  handleError: any;
}

export const styles = (theme: any) => ({
  root: {
    fontWeight: 500,
    fontFamily: 'Fidelity Sans',
    fontSize: '1rem'
  },
  textfield: {
    width: '90%',
    height: 56,
    margin: theme.spacing(2)
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(2),
  },
  cancelBtn: {
    color: "#646D7D"
  },
  errorMsg: {
    fontSize: '0.8rem',
    width: '100%',
    color: 'red',
    marginLeft: theme.spacing(2),
    whiteSpace: 'pre-wrap' as 'pre-wrap'
  },
  textinput:
  {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom:theme.spacing(2)
  }
});

class InviteContact extends Component<InviteContactProps> {

  state = {
    nickname: '',
    email: '',
    errorMsg: ''
  }

  componentDidUpdate(prevProps: any) {
    const { updating, onActionComplete, addContactError } = this.props;
    if (prevProps.updating !== updating) {
      if (!updating && addContactError === null) {
        this.setState({ errorMsg: '' })
        onActionComplete("Invite a Contact", true, "The contact has been sent an invite")
      }
      else {
        if (addContactError && addContactError.response && addContactError.response.data.errCode) {
          this.setState({ errorMsg: addContactError.response.data.errMsg })
          if (addContactError.response.data.errCode === 401) {
            this.props.handleError(addContactError);
          }
        }
        else {
          this.props.handleError(addContactError);
        }
      }
    }
  }

  handleNameInputChange = (event: any, value: any) => {
    this.setState({ nickname: value, errorMsg: '' })
  }

  handleEmailInputChange = (vevent: any, value: any) => {
    this.setState({ email: value, errorMsg: '' })
  }

  onAddContactClick = () => {
    this.props.addContact({
      "email": this.state.email,
      "nickName": this.state.nickname
    })
  }

  onCancelClick = () => {
    this.props.onActionComplete(null, false, null)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <InviteHeader title="Invite a Contact" description="Add contacts with an email invite." />
        <div className={classes.textinput}>
          <TextInput
            isFocus={true}
            id="Name"
            size="medium"
            validateRegex="^[A-Za-z0-9_ ]+$"
            handleInPutChange={this.handleNameInputChange}
            assistiveText="Please use letters,numbers and underscores only." >
          </TextInput>
        </div>
        <div className={classes.textinput}>
          <TextInput
            isFocus={false}
            size="medium"
            id="Email Address"
            validateRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            handleInPutChange={this.handleEmailInputChange}
            assistiveText="please use a valid email address." >
          </TextInput>
        </div>
        {(this.state.errorMsg) ? <div className={classes.errorMsg}>{this.state.errorMsg} </div> : null}
        <div className={classes.buttonGroup}>
          <Button className={classes.cancelBtn} onClick={this.onCancelClick.bind(this)}>Cancel</Button>
          <FabButton id="sendInvite" disabled={!this.state.nickname || !this.state.email} text="Send Invite"
            onClick={this.onAddContactClick.bind(this)} />
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    updating: state.contactsReducer.contacts.updating,
    addContactError: state.contactsReducer.contacts.error,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addContact: (contact: any) => dispatch({
      type: ContactActionTypes.CONTACT_API_ADD_CONTACT_REQUEST,
      contact: contact
    })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InviteContact));