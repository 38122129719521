export const content_type = {
    TEXT: 'text',
    DATE: 'date',
    ICON: 'icon',
    LINK: 'link'
} 

export type UserContent = {
    userCode: string,
    email: string,
    firstName: string,
    lastName: string,
    countryPhoneCode: number,
    phoneNumber: string,
    addedToGroup: string,
    connectedTopics: number,
    status: string,
    externalId: number,
    sharedTopicNames: string[]
}
  
export type GroupContent = {
    cgCode: string,
    cgName: string,
    createdAt: number,
    displayName: string,
    modifiedAt: number,
    ownerUserId: number,
    status: string
}
  
export type TableDataCell = {
    content: {
      s1: {
        type: string,
        content: any,
        bold: boolean
      },
      s2: {
        type: string,
        content: any,
        bold: boolean
      },
      s3: {
        type: string,
        content: any,
        bold: boolean
      },
      s4: {
        type: string,
        content: any,
        bold: boolean
      }
    },
    resizeShown: boolean,
    align?: string
}
  
export type TableData = {
    id: string,
    cells: TableDataCell[],
}

export type ActionMenu = {
    enable: boolean,
    menu: Menu[]
}

export type Menu = {
    id: string,
    label: string,
}