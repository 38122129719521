import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    icon: {
        fontSize: 20,
    },
    iconVar: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // fontFamily: 'Lato',
        fontSize: '14px'
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: theme.spacing(3)
    }
});

function getModalStyle() {
    const top = 40;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

class ErrorModal extends React.Component {

    state = {
        modalOpen: true
    }

    handleClose = () => {
        this.setState({modalOpen: false})
        this.props.handleCloseErrorDisplay()
    }

    retryAction = () => {
        const {error} = this.props
        this.props.retryAction(error.action)
    }

    render(){
        const {classes, error} = this.props
        let errorMessage = ''
        if (error) {
            if (error.response && error.response.data && error.response.data.errMsg) {
              errorMessage = error.response.data.errMsg;
              }
            else if (error.response.statusText) {
              errorMessage = error.response.statusText;
            } else{
              errorMessage = "Unknown Error occured , please try again !!"
            }
        }
        return (
            <Modal
                data-test='ErrorModal'
                className={classes.createTopicModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.modalOpen}
                onClose={this.handleClose}
            >
                <div style={getModalStyle()} className={classes.paper}> 
                    <span className={classes.message}>
                    <ErrorIcon className={classNames(classes.icon, classes.iconVar)} />
                    {errorMessage}
                    </span>
                    <div className={classes.buttons}>
                    <Button data-test='ErrorModalRetry' variant="contained" className={classes.message} onClick={this.retryAction} >
                        Retry 
                    </Button>
                    <Button variant="contained" className={classes.message} onClick={this.handleClose}>
                        Cancel
                    </Button>
                    </div>
                </div>
            </Modal>
        )
    }

}

export default withStyles(styles)(ErrorModal);