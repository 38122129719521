import React from 'react';
import * as mime from 'react-native-mime-types';
import FileIcon, { defaultStyles } from 'react-file-icon';

class FileTypeIcon extends React.Component {

  getfileTypeIcon(size, fileType) {
    switch (fileType) {
      case 'doc':
      case 'docx':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.docx} />;
      case 'txt':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.txt} />;
      case 'ppt':
      case 'pptx':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.ppt} />;
      case 'xls':
      case 'xlsx':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.xls} />;
      case 'png':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.png} />;
      case 'jpg':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.jpg} />;
      case 'jpeg':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.jpeg} />;
      case 'pdf':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.pdf} />;
      case 'zip':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.zip} />;
      case 'mov':
      case 'wav':
      case 'mp4':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.mov} />;
      case 'mp3':
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} {...defaultStyles.mp3} />;

      default:
        return <FileIcon glyphColor="#585858" color="#585858" size={size} extension={fileType} />;
    }
  }


  render() {
    const { size, type, title } = this.props
    let fileType = mime.extension(type);
    if (fileType === false && title) fileType = title.split('.').pop();
    return (
      <div style={{ width: '30px' }}>{this.getfileTypeIcon(size, fileType)}</div>
    )
  }
}

export default FileTypeIcon;