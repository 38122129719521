import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Hidden, AppBar, IconButton, Snackbar, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as GridIcon } from '../../../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../../../assets/list-view.svg';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import { MY_FILES_GET_ITEMS_FILES_REQUEST, ADDTO_CURRENT_FOLDER, STRIP_CURRENT_FOLDERPATH, PREVIEW_FILE, MY_FILES_LAZY_LOAD_MORE_FILES_REQUEST } from '../../../../../redux/constants/personalVault/myFilesConstants'
import { CommonFileItemList, CommonFileGridList } from '../../../../../shared/ui';
import Breadcrumb from '../../../../../shared/ui/breadcrumb/Breadcrumb';
import ItemActionsBar from '../itemActionsBar/ItemActionsBar';
import { fsPaginationFiles } from '../../../../../shared/core/utils/paginationDefaults';
import './MyFilesListView.scss';


export const styles = (theme: any) => ({
    errorSnackBar:
    {
        background: 'red'
    },
    snackbar:
    {
        background: 'red'
    }
});

type MyFilesListViewProps = {
    loggedIn: any,
    updateItemProgress: any,
    updateItemResponse: any,
    updateItemError: any,
    deleteItemProgress: any,
    deleteItemResponse: any,
    fetchFiles: any,
    lazyLoadMoreFiles: any,
    files: any,
    updateBreadCrumb: any,
    stripFolders: any,
    foldersPath: any,
    currentFolder: any,
    history: any,
    selectFileToPreview: any,
    snackbarData: any
    updateSnackbar: any,
    classes: any,
    filesFetching: any,
    itemsPage: any,
    lazyLoading: any
    fileSorting: any,
    updateFileHeaders: any,
    fileListHeaders: any
}

type MyFilesListViewState = {
    listView: boolean,
    pagination: PaginationType,
    selectedFiles: string[],
    resetSelection: boolean,
    noMoreData: boolean,
}

interface PaginationType {
    direction: string,
    orderBy: string,
    page: number;
    size: number;
}


class MyFilesListView extends Component<MyFilesListViewProps, MyFilesListViewState> {

    state = {
        listView: true,
        pagination: {
            direction: fsPaginationFiles.direction,
            orderBy: fsPaginationFiles.orderBy,
            page: fsPaginationFiles.page,
            size: fsPaginationFiles.size
        },
        selectedFiles: [],
        resetSelection: false,
        noMoreData: false
    }

    toggleViews = () => {
        this.setState({ listView: !this.state.listView })
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { fetchFiles, foldersPath } = this.props;
        let currentFolder = foldersPath[this.props.foldersPath.length - 1];
        if (currentFolder !== prevProps.foldersPath[this.props.foldersPath.length - 1]) {
            fetchFiles(currentFolder.itemCode, fsPaginationFiles);
        }
    }

    componentDidMount() {
        const { fetchFiles, foldersPath } = this.props;
        let currentFolder = foldersPath[foldersPath.length - 1]

        if (currentFolder.itemCode !== '') {
            fetchFiles(currentFolder.itemCode, fsPaginationFiles);
        }
    }

    onFileSelection = (selectedIds: string[]) => {
        let selectedFiles = []
        selectedFiles = this.props.files.filter((I: any) =>
            selectedIds.findIndex((x: string) => x === I.itemCode) > -1
        );
        this.setState({ selectedFiles: selectedFiles });
    }

    onFileOpen = (file: any) => {
        //this.props.selectFileToPreview(file);
        //this.props.history.push(`/personal/file-detail/${file.itemCode}`);
    }

    filePreview = () => {
        this.onFileOpen(this.state.selectedFiles[0]);
    }

    onFolderOpen = (item: any) => {
        this.props.updateBreadCrumb(item);
        if (this.props.foldersPath[this.props.foldersPath.length - 1].itemCode !== item.itemCode) {
            this.props.fetchFiles(item.itemCode, fsPaginationFiles);
        }
    }

    onFolderSelectInBreadcrumb = (event: any, item: any) => {
        const { foldersPath } = this.props;
        const index = foldersPath.findIndex((x: any) => {
            return x.itemCode === item.itemCode
        });
        this.props.stripFolders(index + 1);
        if (this.props.foldersPath[this.props.foldersPath.length - 1].itemCode !== item.itemCode) {
            this.props.fetchFiles(item.itemCode, fsPaginationFiles);
        }
    }

    sortOnClick = (id: string) => {
        let tmp_orderBy: string = ''
        switch (id) {
            case 'fileName':
                tmp_orderBy = 'NAME';
                break;
            case 'modifiedAt':
                tmp_orderBy = 'MODIFIED_AT';
                break;
            default:
                tmp_orderBy = 'NAME'
        }

        if (tmp_orderBy === this.state.pagination.orderBy) {
            this.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    direction: prevState.pagination.direction === 'ASC' ? 'DESC' : 'ASC'
                }
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    orderBy: tmp_orderBy
                }
            }))
        }

        const { fetchFiles, foldersPath } = this.props;
        let currentFolder = foldersPath[foldersPath.length - 1]
        fetchFiles(currentFolder.itemCode, this.state.pagination);
    }

    handleSnackbarClose = () => {
        this.props.updateSnackbar({
            modalOpen: false,
            content: ''
        })
    }

    fetchMoreData = () => {
        if (this.props.itemsPage.pageNumber < this.props.itemsPage.totalPages - 1) {
            let paginationNew = {
                direction: fsPaginationFiles.direction,
                orderBy: fsPaginationFiles.orderBy,
                page: this.props.itemsPage.pageNumber + 1,
                size: fsPaginationFiles.size
            }

            setTimeout(() => {
                const { foldersPath } = this.props;
                let currentFolder = foldersPath[foldersPath.length - 1]
                if (currentFolder.itemCode !== '') {
                    this.props.lazyLoadMoreFiles(currentFolder.itemCode, paginationNew);
                }
            }, 2000)
        } else {
            this.setState({ noMoreData: true })
        }
    }


    resetSelection = (refreshRequired: boolean) => {
        this.setState({ resetSelection: true }, () => {
            setTimeout(() => {
                this.setState({ resetSelection: false });
            }, 1500);
        });
        if (refreshRequired != null && refreshRequired) {
            // if (this.state.topic.stCode) {
            //     this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination)
            // }
        }
    }

    onHeaderFiltering = (filteredHeaders: string[]) => {
        this.props.updateFileHeaders(filteredHeaders)
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    ContentProps={{
                        classes: {
                            root: this.props.snackbarData.isError ? classes.errorSnackBar : null
                        }
                    }}
                    open={this.props.snackbarData.modalOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClose}
                    message={this.props.snackbarData.content}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                <Breadcrumb textLength={90} history={this.props.history} items={this.props.foldersPath} onItemSelect={this.onFolderSelectInBreadcrumb}></Breadcrumb>
                {this.props.files.length > 0 &&
                    <div>
                        <AppBar position="static" className="app-bar-wrapper">
                            <div className="action-bar">
                                <ItemActionsBar
                                    filePreview={this.filePreview}
                                    selectedFiles={this.state.selectedFiles}
                                    currentFolder={this.props.currentFolder}
                                    onActionComplete={this.resetSelection}
                                />
                                <Hidden xsDown>
                                    {this.state.listView &&
                                        <IconButton className="iconBtn-wrapper" aria-label="views" onClick={this.toggleViews}>
                                            <GridIcon className="iconBtn" />
                                        </IconButton>
                                    }
                                    {!this.state.listView &&
                                        <IconButton className="iconBtn-wrapper" aria-label="views" onClick={this.toggleViews}>
                                            <ListViewIcon className="iconBtn" />
                                        </IconButton>
                                    }
                                </Hidden>
                            </div>
                        </AppBar>
                        <div>
                            <Hidden xsDown>
                                {this.state.listView &&
                                    <CommonFileItemList
                                        files={this.props.files}
                                        curSorting={this.props.fileSorting}
                                        filesFetching={this.props.filesFetching}
                                        lazyLoading={this.props.lazyLoading}
                                        fetchMoreData={this.fetchMoreData}
                                        allFetched={this.props.itemsPage.pageNumber === this.props.itemsPage.totalPages - 1}
                                        allowChangePermission={true}
                                        onSelection={this.onFileSelection}
                                        showPermission={false}
                                        showFileSize={true}
                                        showOwner={false}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        onSorting={this.sortOnClick}
                                        showMeAsOnwer={true}
                                        showUploaded={false}

                                        noMoreData={this.state.noMoreData}
                                        resetSelection={this.state.resetSelection}

                                        tableMarginHeight={240}
                                        fileListHeaders={this.props.fileListHeaders}
                                        onFiltering={this.onHeaderFiltering}
                                    />

                                }
                                {!this.state.listView &&
                                    <CommonFileGridList
                                        files={this.props.files}
                                        filesFetching={this.props.filesFetching}
                                        lazyLoading={this.props.lazyLoading}
                                        fetchMoreData={this.fetchMoreData}
                                        allFetched={this.props.itemsPage.pageNumber === this.props.itemsPage.totalPages - 1}
                                        allowChangePermission={false}
                                        onSelection={this.onFileSelection}
                                        showPermission={false}
                                        showFileSize={true}
                                        showOnwer={false}
                                        tableMarginMinHeight={300}
                                        tableMarginMaxHeight={300}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        showMeAsOnwer={true}
                                        resetSelection={this.state.resetSelection}
                                        showUploaded={false} />
                                }
                            </Hidden>
                            <Hidden smUp>
                                <CommonFileGridList
                                    files={this.props.files}
                                    filesFetching={this.props.filesFetching}
                                    lazyLoading={this.props.lazyLoading}
                                    fetchMoreData={this.fetchMoreData}
                                    allFetched={this.props.itemsPage.pageNumber === this.props.itemsPage.totalPages - 1}
                                    allowChangePermission={true}
                                    onSelection={this.onFileSelection}
                                    tableMarginMinHeight={300}
                                    tableMarginMaxHeight={300}
                                    showPermission={false}
                                    showFileSize={true}
                                    showOnwer={false}
                                    onFileOpen={this.onFileOpen}
                                    onFolderOpen={this.onFolderOpen}
                                    showMeAsOnwer={true}
                                    resetSelection={this.state.resetSelection}
                                    showUploaded={false} />
                            </Hidden>
                        </div>
                    </div>
                }
                {this.props.files.length === 0 && !this.props.filesFetching &&
                    <div className="no-file-block" >
                        Please start by uploading your files by clicking on 'Add New &gt; Upload a File'.
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.login,
        updateItemProgress: state.itemsReducer.updateItem.updating,
        updateItemResponse: state.itemsReducer.updateItem.response,
        updateItemError: state.itemsReducer.updateItem.error,
        deleteItemProgress: state.itemsReducer.deleteItem.deleting,
        deleteItemResponse: state.itemsReducer.deleteItem.response,
        foldersPath: state.myFilesReducer.breadcrumbInfo.folders,
        files: state.myFilesReducer.itemsInfo.itemList,
        fileSorting: state.myFilesReducer.itemsInfo,
        filesFetching: state.myFilesReducer.itemsInfo.fetching,
        lazyLoading: state.myFilesReducer.itemsInfo.lazyLoading,
        itemsPage: state.myFilesReducer.itemsInfo.itemsPage,
        currentFolder: state.myFilesReducer.breadcrumbInfo.folders,
        snackbarData: state.appUIStateReducer.snackbar,
        fileListHeaders: state.appUIStateReducer.fileListHeaders
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchFiles: (itemCode: string, pagination: any) => dispatch({ type: MY_FILES_GET_ITEMS_FILES_REQUEST, itemCode, pagination }),
        lazyLoadMoreFiles: (itemCode: string, pagination: any) => dispatch({ type: MY_FILES_LAZY_LOAD_MORE_FILES_REQUEST, itemCode, pagination }),
        updateBreadCrumb: (x: any) => dispatch({ type: ADDTO_CURRENT_FOLDER, item: x }),
        stripFolders: (currentIndex: number) => dispatch({ type: STRIP_CURRENT_FOLDERPATH, currentIndex }),
        selectFileToPreview: (item: any) => dispatch({ type: PREVIEW_FILE, selectedFile: item }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        updateFileHeaders: (filteredHeaders: string[]) => dispatch({
            type: AppUIStateActionTypes.UPDATE_FILE_LIST_HEADERS,
            filteredHeaders: filteredHeaders
        })
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MyFilesListView)))