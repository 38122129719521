import { combineReducers } from 'redux';
import * as UserDetailTypes from '../../constants/shared/UserDetailsConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    userDetails:
    {
        getUserDetailsProgress: false,
        getUserDetailsResponse: null,
        error: null
    }
}

function userDetails(state = initState.userDetails, action) {
    switch (action.type) {
        case UserDetailTypes.USER_DETAILS_REQUEST:
            return {
                ...state,
                getUserDetailsProgress: true,
                error: null
            }
        case UserDetailTypes.USER_DETAILS_SUCCESS:
            return {
                ...state,
                getUserDetailsProgress: false,
                getUserDetailsResponse: action.response.data,
                error: null
            };
        case UserDetailTypes.USER_DETAILS_FAILURE:
            return {
                ...state,
                getUserDetailsProgress: false,
                error: { ...action.error, action: ErrorTypes.SDV_401_MISSING_TOKEN }
            };
        default:
            return state;
    }
}

export default combineReducers({
    userDetails
})