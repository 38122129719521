import { combineReducers } from 'redux';
import * as InstUserActionTypes from '../../constants/customerVault/instUserConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    instUsers: {
        fetching: true,
        usersData: {
            content: [],
            page: {},
            sort: []
        },
        error: null
    }
}

function groupInstUsers(state = initState.instUsers, action) {
    switch (action.type) {

        // Get Contacts
        case InstUserActionTypes.ADMIN_API_GET_INSTUSERS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case InstUserActionTypes.ADMIN_API_GET_INSTUSERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                usersData: { ...action.users.data }
            };
        case InstUserActionTypes.ADMIN_API_GET_INSTUSERS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;
    }
}

export default combineReducers({
    groupInstUsers
})