import { put, takeLatest, select } from 'redux-saga/effects';
import { HttpMethod, HttpRequest } from '../../../shared/core/utils/requestHandler';
import { LOGIN_REQUESTING, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT_SUCCESSFUL, LOGOUT_ERROR, LOGOUT_REQUESTING } from '../../constants/shared/loginUserConstants';
import { SDV_401_MISSING_TOKEN } from '../../../shared/errors/ErrorMessages';
import { HOME_API_CALL_FAILURE } from '../../constants/shared/homeConstants'

function* loginFlow(action) {
	try {
		const response = yield (new HttpRequest(HttpMethod.GET, '/current_user').invoke());
		if (response.status === 200 && response.data !== null) {
			yield put({ type: LOGIN_SUCCESS, user: response.data.openid, exp: response.data.exp, user_profile: response.data.user_profile, sid: response.data.sid, discriminator: response.data.discriminator });
		}
	} catch (error) {
		yield put({ type: LOGIN_ERROR, error });
	}
}

function* logoutFlow(action) {
	try {
		const getUser = (state) => state.login;
		let user = yield select(getUser)
		//Trying to logout when session has expired
		// console.log(user.exp, Date.now()/1000)
		if (user.exp < Date.now() / 1000) {
			const error = { response: { data: { message: SDV_401_MISSING_TOKEN }, status: 401 } }
			yield put({ type: HOME_API_CALL_FAILURE, error });
		} else {
			const savedRedirectionParams = sessionStorage.getItem('redirectionParams');
			const body = { 
				isTimeOut : action.isTimeOut,
				...(savedRedirectionParams != undefined && {redirectionParams: JSON.parse(savedRedirectionParams)})
			};
			const response = yield (new HttpRequest(HttpMethod.POST, `/logout`, null, body).invoke());
			if (response.status < 400) {
				console.debug("Logged Out", response);
				sessionStorage.removeItem('redirectionParams');
				yield put({ type: LOGOUT_SUCCESSFUL, response });
				//yield put({ type: LOGIN_UNSUCCESS, response });
			}
		}
	} catch (error) {
		console.error(error);
		sessionStorage.removeItem('redirectionParams');
		yield put({ type: LOGOUT_ERROR, error });
		// optionHandle('Log Out');
	}
}

function* loginWatcher() {
	yield takeLatest(LOGIN_REQUESTING, loginFlow);
	yield takeLatest(LOGOUT_REQUESTING, logoutFlow);
}

export default loginWatcher;
