import React, { Component } from 'react';
import Term from './Terms';
import { optionHandle } from '../../../../shared/core/utils/helper';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from '../css/legalDocStyles';
import Error from '../../../../shared/errors/Error';
import ProgressBar from '../../../../shared/core/utils/CircularIndeterminate'

import { FETCH_TRMSOFSVC_ERROR_CLEAR } from '../../../../redux/constants/shared/legalsConstants';

class TermsOfService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: this.props.trmsOfSvcContent ? this.props.trmsOfSvcContent : ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetching && !this.props.fetching && !this.props.error)
            this.setState({ content: this.props.trmsOfSvcContent });
    }

    renderLoader() {
        return (
          <ProgressBar></ProgressBar>
        )
    }

    renderContent() {
        const { props } = this;
        const { classes, error } = this.props;
        const html = { __html: this.state.content }

        return <>
            {
                error && <Error
                    error={error}
                    handleCloseErrorDisplay={() => this.props.errorToNull()}
                />
            }
            <Term
                title='Terms of Service'
                data-test="termsOfService"
                open={props.openModal !== undefined ? props.openModal : true}
                showAction={props.showAction !== undefined ? props.showAction : true}
                declineCallback={() => optionHandle('Log Out')}
                handleClose={props.handleCloseCallback} >
                <div dangerouslySetInnerHTML={html} className={classes.legalDocStyles} />
            </Term>
        </>
      }

    render() {
        return ( this.props.fetching ? this.renderLoader() : this.renderContent())

    }
}

const mapStateToProps = state => {
    return {
        trmsOfSvcContent: state.legalsReducer.trmsOfService.content,
        fetching: state.legalsReducer.trmsOfService.fetching,
        error: state.legalsReducer.trmsOfService.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        errorToNull: () => dispatch({ type: FETCH_TRMSOFSVC_ERROR_CLEAR })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TermsOfService));