import React, { Component } from 'react';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fab, Link } from '@material-ui/core';
import TextInput from '../../../../../shared/core/TextInput';
import UserType from '../../../../../shared/core/UserType';


type SignupConfirmProps = {
    onSignupConfirm: any;
    resendLinkName: string
    classes: any;
    onResend: any;
    onCancel: any;
    errorMessage: any;
    title: any;
    description: any;
    loading: any;
    resendLoading?: any;
    defaultUser?: UserType;
    onSubmit?: any;
}

type SignUpConfirmState = {
    code: string;
    username: string;
    codeRequiredError: boolean;
    user: UserType;
}

export const styles = (theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: '#fff',
        width:'100%',
        flexDirection: 'column' as 'column',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        height: `calc(100% - 183px)`,
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : landscape)`]: {
            height: `calc(100% - 80px)`
        },
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : portrait)`]: {
            height: `calc(100% - 80px)`
        },
        [theme.breakpoints.down('sm')]: {
            height: `calc(100% - 80px)`
        }
    },
    titleContainer: {
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
        width: '1024px',

        [`${theme.breakpoints.between('sm', 'md')} and (orientation : landscape)`]: {
            marginLeft: '0px',
            width: '100%'
        },
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : portrait)`]: {
            marginLeft: '0px',
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: '100%'
        }
    },
    inputContainerLabel: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignSelf: 'left',
        paddingTop: theme.spacing(2),
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '50%',

        [`${theme.breakpoints.between('sm', 'md')} and (orientation : landscape)`]: {
            width: '80%'
        },
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : portrait)`]: {
            width: '80%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    title: {
        fontSize: '2rem',
        fontFamily: theme.homefontfamilylight,
        display: 'flex',
        justifyContent: 'left',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    description: {
        fontSize: '1.3rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontWeight: 300,
        paddingLeft: theme.spacing(2)
    },
    codeText:
    {
        paddingBottom: theme.spacing(3)
    },

    cancelLink:
    {
        alignSelf: 'center',
        cursor: "pointer"
    },
    resendlinkcontainer:
    {
        display:'flex',
        justifyContent:'center'
    },
    resendLink:
    {
        fontSize: '1rem',
        fontFamily: theme.homefontfamilybold,
        color: '#3376a0',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2),
        textAlign: 'center' as 'center',
        cursor:'pointer'
    },
    fabBtn: {
        width: 186,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
    row: {
        width: '1024px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-evenly',
        paddingTop: theme.spacing(2),
        alignSelf: 'center',

        [`${theme.breakpoints.between('sm', 'md')} and (orientation : landscape)`]: {
            marginLeft: '0px',
            width: '100%'
        },
        [`${theme.breakpoints.between('sm', 'md')} and (orientation : portrait)`]: {
            marginLeft: '0px',
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: '100%'
        }
    },
    errorMessage: {
        color: 'red',
        fontSize: '13px',
        textAlign: 'center' as 'center'
    },
});

class SignupConfirm extends Component<SignupConfirmProps, SignUpConfirmState> {

    // constructor(props: SignupConfirmProps) {
    //     super(props);
    //     let { defaultUser } = this.props;
    //     if(!defaultUser) {
    //         defaultUser = new UserType()
    //     }
    //     this.initState(defaultUser);
    // }

    initState(defaultUser: any) {

        this.setState({
            code: '',
            username: '',
            codeRequiredError: false,
            user: defaultUser ? defaultUser : new UserType()
        });
    }

    componentDidMount() {
        this.initState(this.props.defaultUser);
    }

    UNSAFE_componentWillMount() {
        let { defaultUser } = this.props;
        if (!defaultUser) {
            defaultUser = new UserType()
        }
        this.initState(defaultUser);
    }


    handleChange = (id: any, value: string) => {
        this.setState({ codeRequiredError: false, code: value })
    }

    handleConfirm = () => {
        if (this.state.code === null || this.state.code === '')
            this.setState({ codeRequiredError: true })
        else
            this.props.onSignupConfirm(this.state.code, this.state.user.userCode, this.state.user.signUpCode);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} data-test="signupConfirm">
                <div className={classes.titleContainer}>
                    <div className={classes.title}>{this.props.title}</div>
                    <div className={classes.description}>{this.props.description}</div>
                    <Divider variant="middle" />
                    <div className={classes.inputContainerLabel}>
                        <div className={classes.description}>Please enter your verification code below:</div>
                    </div>
                    <div className={classes.inputContainer}>
                        <div className={classes.codeText}>
                            <TextInput
                                forceValidate={this.state.codeRequiredError}
                                size="medium"
                                isFocus={true}
                                id="code"
                                validateRegex="^[0-9]{4,6}$|^$"
                                handleInPutChange={this.handleChange}
                                assistiveText={this.state.codeRequiredError ? "required" : "Please enter the valid code."}
                                label="Code"
                                onSubmit={this.handleConfirm}
                                length={100} >
                            </TextInput>
                            <p>Code will expire in 3 minutes.</p>
                        </div>
                        <div className={classes.resendlinkcontainer}>
                            <Link className={classes.resendLink}
                                onClick={!this.props.resendLoading ? () => { this.props.onResend() } : (event: any) => event.preventDefault()}>{this.props.resendLinkName}</Link>
                        </div>
                    </div>
                    <Divider variant="middle" />
                    <Typography className={classes.errorMessage}>{this.props.errorMessage}</Typography>
                </div>
                <div className={classes.row}>
                    <Link data-test="cancelButton" className={classes.cancelLink} onClick={() => { this.props.onCancel() }}>Cancel &amp; Exit</Link>
                    <Fab data-test="handleConfirm" disabled={this.props.loading} variant='extended' color='primary' className={classes.fabBtn} onClick={this.handleConfirm} >
                        Confirm
                    </Fab>
                </div>
            </div>)
    }
}


export default (withStyles(styles)(SignupConfirm));