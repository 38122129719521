export const cvTheme = {
    applogoheight: 80,
    applogowidth: 120,
    appbarbgcolor: '#F2F2F2',
    appbarshadow: 'none',
    homerootbgcolor: '#F2F2F2',
    homecontentbgcolor: '#F2F2F2',
    homefontfamilyregular: 'Fidelity Sans',
    homefontfamilyitalic: 'Fidelity Sans Italic',
    homefontfamilybold: 'Fidelity Sans Bold',
    homefontfamilybolditlalic: 'Fidelity Sans Bold Italic',
    homefontfamilyextrabold: 'Fidelity Sans Extra Bold',
    homefontfamilylight: 'Fidelity Sans Light',
    homefontfamilyultralight: 'Fidelity Sans Ultra Light',
    homefontfamilycondensedmedium: 'Fidelity Sans Condensed Medium',
    gridheaderbgcolor: '#9c27b0',
    actionbarbgcolor: '#9c27b0',
    actionbarselectedbgcolor: '#9c27b0',
    actionbartextcolor: 'white',
    fabbuttonbgcolor: '#e0e0e0',
    fabbuttonboxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    fabbuttonmobilebgcolor: '#9c27b0',
    fabbuttonmobilecolor: 'white',
    fabbuttonmobilehovercolor: '#6B9120',
    fabbuttonmobileshadowcolor: '0 3px 6px rgba(86,130,0,0.16), 0 3px 6px rgba(86,130,0,0.23)',
    defaultdropdownitemcolor: '#9c27b0',
    errordropdownitemcolor: '#f44336',
    contacttablecellcolor: '#3e2723',
    contacttablecellfontsize: 14,
    contacttablecellfontweight: 'bold',
    drawerbgcolor: '#F2F2f2',
    drawermobilebgcolor: '#9c27b0',
    drawerpaperbgcolor: '#F2F2F2',
    drawerpapermobilebgcolor: '#9c27b0',
    drawercontainerbgcolor: '#9c27b0',
    personiconcolor: 'white',
    dropdowninputbordercolor: '#9c27b0',
    dropdownselectedbgcolor: '#9c27b0',
    loadercolor: '#9c27b0',
    loaderbarcolor: '#9c27b0',
    loaderbarcolorprimary: '#e9ffbd',
    defaulttextcolor: '#444657',
    productnamecolor: 'black',
    productnamecolormobile: 'white',
    loginlinkcolor: '#9c27b0',
    loginbuttonbgcolor: '#9c27b0',
    loginbuttontextcolor: '#9c27b0',
    loginstatementcolor: '#727272',
    topmessagebgcolor: '#F2F2F2',
    topmessageiconbgcolor: '#9c27b0',
    topmessagebordercolor: '#9c27b0',
    topmessageiconcolor: '#ffffff',
    topmessageiconbuttoncolor: '#9c27b0',
    typography: {
        body1: {
            fontWeight: 500,
            fontFamily: 'Fidelity Sans'
        },
        body2: {
            fontWeight: 500,
            fontFamily: 'Fidelity Sans'
        },
        h5: {
            fontFamily: 'Fidelity Sans'
        },
        caption: {
            fontFamily: 'Fidelity Sans'
        },
        subtitle1: {
            fontFamily: 'Fidelity Sans'
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                boxShadow: 'grey',
                backgroundColor: 'white'
            }
        },
        MuiTab: {
            textColorInherit: {
                color: 'white'
            },
            root: {
                textTransform: 'none',
                fontFamily: 'Fidelity Sans'
            }
        },
        MuiFab: {
            root: {
                fontFamily: 'Fidelity Sans',
                fontWeight: 'normal'
            }
        },
        MuiList: {
            root: {
                padding: 0
            },
            padding: {
                padding: 0
            }
        },
        MuiListItem: {
            root: {
                '&:hover': {
                    backgroundColor: '#9c27b0'
                },
                '&$active': {
                    backgroundColor: '#9c27b0',
                    color: 'white'
                },
                '&$selected': {
                    backgroundColor: '#9c27b0',
                    color: 'white'
                },
                '&$selected:hover': {
                    backgroundColor: '#9c27b0',
                    color: 'white'
                }
            },
            button: {
                '&:hover': {
                    backgroundColor: '#9c27b0',
                    color: 'white'
                },
                '&:selected': {
                    backgroundColor: '#9c27b0',
                    color: 'white'
                },
                '&:active': {
                    backgroundColor: '#9c27b0',
                    color: 'white'
                }
            }
        }
    },
    MuiMenu: {
        backgroundColor: '#9c27b0',
        paper: {
            border: '1px solid',
            borderColor: '#9c27b0'
        }
    },
    button: {
        shadows: [
            'none'
        ]
    },
    navlink: {
        fontSize: 10
    },
    palette: {
        primary: {
            main: '#9c27b0'
        },
        secondary: {
            main: '#F2F2F2'
        }
    }
}