import {
    FETCH_TRM_OF_SVC_REQUEST,
    FETCH_TRM_OF_SVC_SUCCESSFUL,
    FETCH_TRM_OF_SVC_FAILURE,
    FETCH_ENDUSER_DISCLAIMER_SUCCESS,
    FETCH_ENDUSER_DISCLAIMER_FAILURE,
    FETCH_ENDUSER_DISCLAIMER_REQUEST,
    FETCH_DELETE_FILE_DISCLAIMER_REQUEST,
    FETCH_DELETE_FILE_DISCLAIMER_SUCCESS,
    FETCH_DELETE_FILE_DISCLAIMER_FAILURE,
} from '../../constants/shared/legalsConstants';

import {
    GET_FORM_DISCLOSURE,
    GET_FORM_DISCLOSURE_SUCCESS,
    GET_FORM_DISCLOSURE_FAILURE
} from '../../constants/shared/eSignConstants'

import { getLegalDocus, getDisclosureText, getF4BDisclosure } from '../../../shared/core/utils/apiFunctions';
import { takeLatest, call, put } from "redux-saga/effects";

function* legalsWatcher() {
    yield takeLatest(FETCH_TRM_OF_SVC_REQUEST, fetchTrmsOfSvc);
    yield takeLatest(FETCH_ENDUSER_DISCLAIMER_REQUEST, fetchEndUserDisclaimer);
    yield takeLatest(GET_FORM_DISCLOSURE, fetchFormTypeDisclosure);
    yield takeLatest(FETCH_DELETE_FILE_DISCLAIMER_REQUEST, fetchDeleteFilesDisclaimer);
}

function* fetchTrmsOfSvc() {
    try {
        const result = yield call(getLegalDocus, 'termsOfService.html');
        if (result && result.data) {
            yield put({ type: FETCH_TRM_OF_SVC_SUCCESSFUL, responseInfo: result });
        }
    } catch (error) {
        yield put({ type: FETCH_TRM_OF_SVC_FAILURE, error: error });
    }
}

function* fetchEndUserDisclaimer() {
    try {
        const result = yield call(getLegalDocus, 'endUserTopicDisclaimer_v1.html');
        if (result && result.data) {
            yield put({ type: FETCH_ENDUSER_DISCLAIMER_SUCCESS, responseInfo: result });
        }
    } catch (error) {
        yield put({ type: FETCH_ENDUSER_DISCLAIMER_FAILURE, error: error });
    }
}

function* fetchFormTypeDisclosure(action) {
    try {
        let result = [];
        for (let i = 0; i < action.formType.length; i++) {
            let element = action.formType[i];
            const _result = yield call(getDisclosureText, element);
            result.push(_result);
        }
        if (result && result.length) {
            yield put({ type: GET_FORM_DISCLOSURE_SUCCESS, responseInfo: result });
        }
    } catch (error) {
        yield put({ type: GET_FORM_DISCLOSURE_FAILURE, error: error });
    }
}

function* fetchDeleteFilesDisclaimer() {
    try {
        const result = yield call(getF4BDisclosure, 'deleteFileDisclaimer.json');
        if (result && result.data) {
            yield put({
                type: FETCH_DELETE_FILE_DISCLAIMER_SUCCESS,
                responseInfo: result
            });
        }
    } catch (error) {
        yield put({
            type: FETCH_DELETE_FILE_DISCLAIMER_FAILURE,
            error: error
        });
    }
}

export default legalsWatcher;
export { fetchTrmsOfSvc, fetchEndUserDisclaimer, fetchFormTypeDisclosure, fetchDeleteFilesDisclaimer };


