import React from "react";
import "./Disclosure.scss";

interface DisclosureProps {
    disclosure: {
        content: string;
        address: string;
        eReview: string;
    };
}

const Disclosure: React.FC<DisclosureProps> = (props) => {
    const { disclosure } = props;
    return (
        <>
            {disclosure && (
                <div className="disclosure-root" data-test="disclosureRoot">
                    <div className="disclosure-wrapper">{disclosure.content}</div>
                    <div className="address-wrapper">{disclosure.address}</div>
                    <div className="eReview-wrapper">{disclosure.eReview}</div>
                </div>
            )}
        </>
    );
};

export default Disclosure;
