import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { DialogContent, DialogActions, Link, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

type ESignEndprops = {
  onActionComplete: any;
  classes?: any;
  isMultiSigner: boolean;
  ceremonyStatus: string;
};

export const styles = (theme: any) => ({
  main: {
    fontFamily: theme.homefontfamilyregular,
    lineHeight: "24px",
    minWidth: "500px"
  },
  content: {
    padding: "16px 0px",
    display: "flex",
    flexDirection: "column" as "column"
  },
  subtitle: {
    fontSize: "1.05rem",
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    letterSpacing: "0.15px",
    paddingBottom: "16px"
  },
  title: {
    color: "#000000",
    fontSize: "1.65rem",
    fontWeight: "bold" as "bold",
    letterSpacing: "0",
    lineHeight: "32.5px"
  },
  commonModalActions: {
    justifyContent: "center",
    padding: "0px"
  },
  primarybtn: {
    textTransform: "none" as "none",
    width: "170px"
  },
  documentLinkCancel: {
    fontSize: "1rem",
    paddingLeft: "8px",
    position: "absolute" as "absolute",
    left: "2rem"
  },
  btnContainer: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "center"
  }
});

class ESignEnd extends Component<ESignEndprops> {
  state = {};

  componentDidMount() { }

  renderDialogContent: () => JSX.Element = () => {
    const { classes, ceremonyStatus } = this.props;
    const singleSignerCeremonyStatusTextMap: { [key: string]: ReactNode } = {
      SUCCESS: (
        <>
          <p>
            Thank you. Your response was recorded. No further action is required at this time.
          </p>
          <p>
            We’ll update the document as soon as we receive the signed copy from DocuSign.
          </p>
        </>
      ),
      DECLINE: (
        <p>
          Thank you. Your response was recorded. No further action is required at this time.
        </p>
      ),
      CANCEL: <p>Thank you. Your response was recorded.</p>
    };
    const multiSignerTextMap: { [key: string]: ReactNode } = {
      SUCCESS: (
        <>
          <p>
            Thank you. Your response was recorded. No further action is required at this time.
          </p>
          <p>
            We’ll update the document as soon as we receive the signed copy from DocuSign.
          </p>
        </>
      ),
      DECLINE: (
        <p>
          Thank you. Your response was recorded. No further action is required at this time.
        </p>
      ),
      CANCEL: <p>Thank you. Your response was recorded.</p>
    };
    return (
      <div className={classes.subtitle}>
        {this.props.isMultiSigner && <p>{multiSignerTextMap[ceremonyStatus]}</p>}
        {(!this.props.isMultiSigner &&
          singleSignerCeremonyStatusTextMap[ceremonyStatus]) ||
          ""}
      </div>
    );
  };

  render(): JSX.Element {
    const { classes } = this.props;
    return (
      <div className={classes.main} data-test="ESignEnd">
        <div className={classes.title}>Electronic response received</div>
        <DialogContent className={classes.content}>
          {this.renderDialogContent()}
        </DialogContent>
        <DialogActions className={classes.commonModalActions}>
          <Button
            id="sendrequest"
            className={classes.primarybtn}
            data-test="closebuttonceremonyend"
            variant="contained"
            onClick={() => this.props.onActionComplete(true)}
            color="primary"
            disableElevation
          >
            Close
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(ESignEnd));
