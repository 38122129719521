export const pvTheme = {
    applogoheight: 80,
    applogowidth: 120,
    appbarbgcolor: "#F2F2F2",
    appbarshadow: "none",
    homerootbgcolor: "#F2F2F2",
    homecontentbgcolor: "#F2F2F2",
    homefontfamilyregular: "Fidelity Sans",
    homefontfamilyitalic: "Fidelity Sans Italic",
    homefontfamilybold: "Fidelity Sans Bold",
    homefontfamilybolditlalic: "Fidelity Sans Bold Italic",
    homefontfamilyextrabold: "Fidelity Sans Extra Bold",
    homefontfamilylight: "Fidelity Sans Light",
    homefontfamilyultralight: "Fidelity Sans Ultra Light",
    homefontfamilycondensedmedium: "Fidelity Sans Condensed Medium",
    gridheaderbgcolor: "#568200",
    actionbarbgcolor: "#568200",
    actionbarselectedbgcolor: "#568200",
    actionbartextcolor: "white",
    fabbuttonbgcolor: "#e0e0e0",
    fabbuttonboxshadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    fabbuttonmobilebgcolor: "#568200",
    fabbuttonmobilecolor: "white",
    fabbuttonmobilehovercolor: "#6B9120",
    fabbuttonmobileshadowcolor: "0 3px 6px rgba(86,130,0,0.16), 0 3px 6px rgba(86,130,0,0.23)",
    defaultdropdownitemcolor: "#568200",
    errordropdownitemcolor: "#f44336",
    contacttablecellcolor: "#3e2723",
    contacttablecellfontsize: 14,
    contacttablecellfontweight: "bold",
    drawerbgcolor: "#F2F2f2",
    drawermobilebgcolor: "#568200",
    drawerpaperbgcolor: "#F2F2F2",
    drawerpapermobilebgcolor: "#568200",
    drawercontainerbgcolor: "#568200",
    personiconcolor: "white",
    dropdowninputbordercolor: "#568200",
    dropdownselectedbgcolor: "#568200",
    loadercolor: "#568200",
    loaderbarcolor: "#568200",
    loaderbarcolorprimary: "#e9ffbd",
    defaulttextcolor: "#444657",
    productnamecolor: "black",
    productnamecolormobile: "white",
    loginlinkcolor: "#568200",
    loginbuttonbgcolor: "#568200",
    loginbuttontextcolor: "#568200",
    loginstatementcolor: "#727272",
    topmessagebgcolor: "#F2F2F2",
    topmessageiconbgcolor: "#568200",
    topmessagebordercolor: "#568200",
    topmessageiconcolor: "#ffffff",
    topmessageiconbuttoncolor: "#568200",
    typography: {
        body1: {
            fontWeight: 500,
            fontFamily: "Fidelity Sans"
        },
        body2: {
            fontWeight: 500,
            fontFamily: "Fidelity Sans"

        },
        h5: {
            fontFamily: "Fidelity Sans"
        },
        caption: {
            fontFamily: "Fidelity Sans"
        },
        subtitle1: {
            fontFamily: "Fidelity Sans"
        }
    },
    overrides: {
        MuiSnackbarContent:
        {
            root: {
                flexWrap: "nowrap"
            }
        },
        MuiPaper: {
            root: {
                boxShadow: "grey",
                backgroundColor: "white"
            }
        },
        MuiTab: {
            textColorInherit: {
                color: "white"
            },
            root: {
                textTransform: "none",
                fontFamily: "Fidelity Sans"
            }
        },
        MuiFab: {
            root: {
                fontFamily: "Fidelity Sans",
                fontWeight: "normal"
            }
        },
        MuiList: {
            root: {
                padding: 0
            },
            padding: {
                padding: 0
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "#568200",
                borderWidth: "1px"
            },
            adornedEnd:
            {
                paddingRight: 0
            }
        },
        MuiListItem: {
            root: {
                '&:hover': {
                    backgroundColor: "#568200"
                },
                '&$active': {
                    backgroundColor: "#568200",
                    color: "white"
                },
                '&$selected': {
                    backgroundColor: "#568200",
                    color: "white"
                },
                '&$selected:hover': {
                    backgroundColor: "#568200",
                    color: "white"
                }
            },
            button: {
                '&: hover': {
                    backgroundColor: "#568200",
                    color: "white"
                },
                '&: selected': {
                    backgroundColor: "#568200",
                    color: "white"
                },
                '&: active': {
                    backgroundColor: "#568200",
                    color: "white"
                }
            }
        }
    },

    MuiMenu: {
        backgroundColor: "#568200",
        paper: {
            border: "1px solid",
            borderColor: "#568200"
        }
    },
    button: {
        shadows: ["none"]
    },

    navlink: {
        fontSize: 10
    }

}