import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Icon from '../../core/utils/icon';
import './SideDrawer.scss';
import { connect } from 'react-redux';

interface MenuItem {
    id: string,
    label: string,
    link: string,
    active: boolean
}

type SideDrawerProps = {
    classes: any,
    menuItems: MenuItem[],
    menuSelect: any,
    drawerMenus: any
}

type SideDrawerStates = {}

export const styles = (theme: any) => ({
    menuItem: {
        color: '#000',
        transition: 'all 0.2s ease',
        height: '64px',
        padding: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.appbarbgcolor,
            color: '#000'
        },
    },
    menuActive: {
        height: '64px',
        padding: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.loginbuttonbgcolor,
        color: '#fff',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
            color: '#fff'
        },
    },
    listIcon: {
        width: '24px',
        padding: '0 10px 0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listText: {
        fontWeight: 500,
        flex: '1 1 auto',
        marginTop: '4px',
        marginBottom: '4px',
    },
    listTextActive: {
        fontWeight: 800,
        flex: '1 1 auto',
        marginTop: '4px',
        marginBottom: '4px',
    }
})

class SideDrawer extends Component<SideDrawerProps, SideDrawerStates> {
    state = {
        activeIndex: 0
    }

    menuOnClick = (link: string, index: number) => {
        this.props.menuSelect(link);
        this.setState({ activeIndex: index });
    }

    render() {
        const { classes } = this.props;
        return (
            <div data-test='sideDrawer'>
                <List className="menu-list-wrapper">
                    {this.props.drawerMenus.map((item: MenuItem, index: number) => (
                        <ListItem data-test='menuListItem' button key={item.id} onClick={() => this.menuOnClick(item.link, index)}
                            className={ item.active ? classes.menuActive : classes.menuItem }>
                            <ListItemIcon title={item.label} className={ classes.listIcon }>
                                <Icon  name={item.label} stroke={ item.active ? '#ffffff' : '#000000'} />
                            </ListItemIcon>
                            <span className={ item.active ? classes.listTextActive : classes.listText }>{ item.label }</span>
                        </ListItem>
                    ))}
                </List>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        drawerMenus: state.appUIStateReducer.drawerMenuItems
    };
}

const mapDispatchToProps = (state: any) => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideDrawer));