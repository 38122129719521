import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AppBar, Tabs, Tab, IconButton, Typography, Box, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { TOGGLE_FILE_DETAIL_MENU, GET_SHARE_CONTACTS } from '../../../../../redux/constants/personalVault/myFilesConstants';
import Moment from 'react-moment';

import './FileDetailMenu.scss';

type FileDetailMenuProps = {
    toggleFileDetailMenu: any,
    selectedFileForPreview: any,
    getContactsSelected: any,
    sharedContacts: any,
    menuOpen:any,
    children?:any
}

type FileDetailMenuStates = {}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`file-detail-tabpanel-${index}`}
        aria-labelledby={`file-detail-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
}


class FileDetailMenu extends Component<FileDetailMenuProps, FileDetailMenuStates> {

    state = {
        value: 0
    }

    componentDidMount() {
        this.props.getContactsSelected(this.props.selectedFileForPreview.itemCode);
    }

    closeMenu = () => {
        this.props.toggleFileDetailMenu(false)
    }

    handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({value: newValue});
    };

    a11yProps = (index: any) => {
        return {
            id: `file-detail-menu-${index}`,
            'aria-controls': `file-detail-menu-${index}`,
        };
    }

    sizeConverter = (size: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '-';
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return Math.round(size / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    render() {
        return(
            <div className='menu-wrapper'>
                <IconButton aria-label="Close Menu" className='menu-close-btn' onClick={this.closeMenu}>
                    <CloseIcon />
                </IconButton>
                <AppBar position="static" className="menu-appbar">
                    <Tabs className="file-detail-tabs"
                        TabIndicatorProps={{style: {height: '6px'}}}
                        value={ this.state.value } 
                        onChange={ this.handleTabsChange }
                        indicatorColor='primary'
                        aria-label="file-detail">
                        <Tab className="file-detail-tab-btn" label={<span className="tab-label">FILE DETAILS</span>} {...this.a11yProps(0)}></Tab>
                        <Tab className="file-detail-tab-btn" label={<span className="tab-label">FILE ACTIVITY</span>} {...this.a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value } index={0}>
                    <div className='tab-wrapper'>
                        <div className='info-block'>
                            <h4 className='info-title'>GENERAL INFO</h4>
                            <table className='info-table'>
                                <tr className='info-table-row'>
                                    <td className='info-table-cell'><b>Title</b></td>
                                    <td className='info-table-cell'>{ this.props.selectedFileForPreview.itemName }</td>
                                </tr>
                                <tr className='info-table-row'>
                                    <td className='info-table-cell'><b>Size</b></td>
                                    <td className='info-table-cell'>{ this.sizeConverter(this.props.selectedFileForPreview.size) }</td>
                                </tr>
                                <tr className='info-table-row'>
                                    <td className='info-table-cell'><b>Location</b></td>
                                    <td className='info-table-cell'> / </td>
                                </tr>
                                <tr className='info-table-row'>
                                    <td className='info-table-cell'><b>Modified</b></td>
                                    <td className='info-table-cell'><Moment format="MMM DD, YYYY HH:mm A">{ this.props.selectedFileForPreview.modifiedAt }</Moment></td>
                                </tr>
                                <tr className='info-table-row'>
                                    <td className='info-table-cell'><b>Created</b></td>
                                    <td className='info-table-cell'><Moment format="MMM DD, YYYY HH:mm A">{ this.props.selectedFileForPreview.createdAt }</Moment></td>
                                </tr>
                            </table>
                        </div>
                        <Divider />
                        <div className='info-block'>
                            <div className='info-block-title-wrapper'>
                                <h4>SHARING && PERMISSIONs</h4>
                                <EditIcon fontSize="small" className='editBtn' />
                            </div>
                            <div className='info-table'>
                                { this.props.sharedContacts.map((contact: any, index: number) => {
                                    return(
                                        <div key={index} className='info-item'>
                                            <span className='info-contact-name'>{ contact.name }</span>
                                            <span>{ contact.permission }</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <Divider />
                    </div>
                </TabPanel>
                <TabPanel value={this.state.value } index={1}>
                    <div className='tab-wrapper'>
                        - No activity -
                    </div>
                </TabPanel>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileForPreview: state.appUIStateReducer.selectedFileToPreview,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen,
        sharedContacts: state.shareContactReducer.shareContacts
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleFileDetailMenu: (open: boolean) => dispatch({ type: TOGGLE_FILE_DETAIL_MENU, menuOpen: open }),
        getContactsSelected: (itemCode: any) => dispatch({
            type: GET_SHARE_CONTACTS,
            itemCode: itemCode
        }),
    }
}


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FileDetailMenu));