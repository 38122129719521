import React, { Component } from 'react';
import { Divider, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fab, Link } from '@material-ui/core';
import TextInput from '../../../../../shared/core/TextInput';
import Password from '../Password';
import clsx from 'clsx';

type ResetPasswordConfirmProps = {
    onResetConfirm: any;
    onResetPasswordAnother: any;
    email: string;
    username: any;
    classes: any;
    errorMessage: any;
    onCancel: any;
    loading: any;
}

export const styles = (theme: any) => ({
    root: {
        maxWidth: '420px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none'
        }
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
    },
    title: {
        fontSize: '2rem',
        fontFamily: theme.homefontfamilylight,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(1),
    },
    description: {
        fontSize: '0.875rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(1),
        fontWeight: 300
    },
    inputContainer:
    {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'left',
        alignItems: 'flex-start',
        minHeight: 65,
        paddingBottom: 0,
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            alignItems: 'left',
            flexDirection: 'column' as 'column',
        },
    },
    passwordContainer:
    {
        marginTop: theme.spacing(2),
    },
    resendinputContainer:
    {
        minHeight: 20
    },
    nameLabel:
    {
        display: 'flex',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        fontFamily: theme.homefontfamilybold,
        fontSize: '0.9rem',
        width: '40%',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            justifyContent: 'flex-start',
            width: '100%',
            marginBottom:theme.spacing(2)
        }
    },
    inputtextField:
    {
        width: '100%',
        marginLeft: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            width: '100%',
            marginRight: theme.spacing(4)
        }
    },
    codeText:
    {
        paddingBottom: theme.spacing(3)
    },
    cancelLink:
    {
        alignSelf: 'center',
        cursor: "pointer"
    },
    fabBtn: {
        width: 120,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
    row: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-evenly',
        paddingTop: theme.spacing(5)
    },
    errorMessage: {
        color: 'red',
        fontSize: '13px',
        textAlign: 'center' as 'center'
    },
    newcode:
    {
        width: '100%',
        marginLeft: theme.spacing(2),
        paddingBottom: '0.15em',
        paddingTop: '0.25em',
        fontSize: '0.8rem'
    },
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        paddingRight: theme.spacing(3)
    },
    passwordHelperText:
    {
        display: 'flex',
        flexDirection: 'column' as 'column',
        fontSize: '0.8rem',
        color: 'grey'
    },
    passwordHelperList:
    {
        listStyleType: 'square'
    },
    requestanother:
    {
        cursor: "pointer"
    }
});

class ResetPasswordConfirm extends Component<ResetPasswordConfirmProps> {

    state = {
        guptshabd: '',
        confirmpassword: null,
        code: null,
        validpassword: true,
        ispasswordMatch: true,
        errorMessage: ''
    }

    handleCodeChange = (id: any, value: string) => {
        this.setState({ code: value })
    }



    UNSAFE_componentWillReceiveProps(newProps: any) {
        if (newProps.errorMessage !== this.props.errorMessage) {
            this.setState({ errorMessage: newProps.errorMessage })
        }
    }

    handleSubmit = () => {
        if (this.state.confirmpassword != this.state.guptshabd)
            this.setState({ ispasswordMatch: false });
        if (this.state.guptshabd === null || this.state.guptshabd === '')
            this.setState({ validpassword: false });

        if (this.state.validpassword && this.state.confirmpassword === this.state.guptshabd) {
            this.setState({ errorMessage: "" })
            this.props.onResetConfirm(this.state.code, this.state.guptshabd);
        }
        else {
            this.setState({ errorMessage: "Please fill the required fields." })
        }
    }

    hasSequentialChars = (password:string , sequenceLength:number) => {
        if (password.length < sequenceLength) {
            return false;
        }
        for (let i = 0; i <= password.length - sequenceLength; i++) {
            let isSequential = true;
            for (let j = 1; j < sequenceLength; j++) {
                if (password.charCodeAt(i + j) !== password.charCodeAt(i + j - 1) + 1) {
                    isSequential = false;
                    break;
                }
            }
            if (isSequential) {
                return true;
            }
        }
        return false;
     }


    isValidPassword = () => {
        const regex = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).*$/;
        if (this.state.guptshabd !== null && this.state.guptshabd.match(regex) &&
         !this.hasSequentialChars(this.state.guptshabd, 3))
            this.setState({
                validpassword: true
            })
        else {
            this.setState({
                validpassword: false
            })
        }
        this.isContainingSubstringOfUsername();
    }

    isContainingSubstringOfUsername = () => {
        const minLength = 5;
        for(let i =0; i<=this.props.username.length - minLength; i++) {
            const subStringToCheck = this.props.username.substring(i, minLength+i).toLowerCase();
            if (this.state.guptshabd.toLowerCase().includes(subStringToCheck)) {
                this.setState({
                    validpassword: false
                })
            }
        }
    }


    ispasswordMatch = () => {
        if (this.state.confirmpassword === this.state.guptshabd)
            this.setState({
                ispasswordMatch: true
            })
        else
            this.setState({
                ispasswordMatch: false
            })
    }
    handlePasswordChange = (event: any, prop: any) => {
        var temp = event.target.value;
        switch (prop) {
            case "password":
                this.setState({
                    guptshabd: temp
                });
                break;
            case "confirmpassword":
                this.setState({
                    confirmpassword: temp
                })
                break;
            default:
                break;
        }
    }
    
    render() {
        const { classes, email } = this.props;
        return (
            <Paper className={classes.root} data-test="resetPasswordConfirm">
                <div className={classes.titleContainer}>
                    <div className={classes.title}>Reset Password</div>
                    <div className={classes.description}>Look for a security code we have just send to {email} and enter it below.</div>
                </div>
                <Divider variant="middle" />
                <div className={classes.container}>
                    <div className={classes.inputContainer}>
                        <div className={classes.nameLabel} style={{ marginTop: '24px' }}>Security Code</div>
                        <div className={classes.inputtextField}>
                            <TextInput
                                forceValidate={true}
                                size="small"
                                isFocus={true}
                                id="user"
                                validateRegex="^[0-9]{4,6}$"
                                handleInPutChange={this.handleCodeChange}
                                assistiveText={"Please enter valid Code."}
                                label="Confirmation Code"
                                onSubmit={this.handleSubmit} >
                                    
                            </TextInput>
                        </div>
                    </div>
                    <div
                        className={clsx(classes.inputContainer, classes.resendinputContainer)}>
                        <div className={classes.nameLabel}></div>
                        <div className={classes.newcode}>Didn't get the code?
                        <Link data-test="resetPasswordAnotherLink" className={classes.requestanother} onClick={() => this.props.onResetPasswordAnother()}> Request another one</Link>
                        </div>
                    </div>
                    <div className={clsx(classes.inputContainer, classes.passwordContainer)}>
                        <div className={classes.nameLabel}>Create a new password</div>
                        <div className={classes.inputtextField}>
                            <Password
                                id="password"
                                data-test="passwordConfirmFirst"
                                guptshabd={this.state.guptshabd}
                                handlePasswordChange={this.handlePasswordChange}
                                validpassword={this.state.validpassword}
                                isValidPassword={this.isValidPassword}
                                helperText={"Please enter the valid password."}
                                onSubmit={this.handleSubmit}
                            />
                        </div>
                    </div>
                    <div className={classes.inputContainer}>
                        <div className={classes.nameLabel}></div>
                        <div className={classes.inputtextField}>
                            <div className={classes.passwordHelperText}>
                                Use minimum 8 characters. Letters are case sensitive.
                                <div>Your password must include:</div>
                                <ul className={classes.passwordHelperList}>
                                    <li>A lowercase letter (a-z)</li>
                                    <li>An uppercase letter (A-Z)</li>
                                    <li>A number (0-9)</li>
                                    <li>A special character. Special characters include % ' ( ) + , . - / : ; = ? \ ^ _ | ~ ! @ $ </li>
                                </ul>
                                <div>Your password should not:</div>
                                <ul className={classes.passwordHelperList}>
                                    <li>Be common sequence characters or number. (abc or 123)</li>
                                    <li>Share 5 or more characters with your userID/email.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={clsx(classes.inputContainer, classes.passwordContainer)}>
                        <div className={classes.nameLabel}>Confirm your password</div>
                        <div className={classes.inputtextField}>
                            <Password
                                id="confirmpassword"
                                data-test="passwordConfirm"
                                guptshabd={this.state.confirmpassword}
                                handlePasswordChange={this.handlePasswordChange}
                                validpassword={this.state.ispasswordMatch}
                                isValidPassword={this.ispasswordMatch}
                                helperText="Passwords do not match."
                                onSubmit={this.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
                <Divider variant="middle" />
                <Typography className={classes.errorMessage}>{this.state.errorMessage}</Typography>
                <div className={classes.row}>
                    <Link data-test="cancelLink" className={classes.cancelLink} onClick={() => { this.props.onCancel() }}>Cancel</Link>
                    <Fab data-test="submitBtn" disabled={this.props.loading} variant='extended' color='primary' className={classes.fabBtn} onClick={this.handleSubmit} >
                        Submit
                    </Fab>
                </div>
            </Paper>)
    }
}


export default (withStyles(styles)(ResetPasswordConfirm));