import React from 'react';

const Note = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st12{stroke:${props.stroke}}`}
        </style>
        <g id="Page-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Note" class="st12">
            <g id="Group">
                <g id="pen-write-paper" stroke-width="1.5">
                    <polygon id="Shape" points="8.492 11.265 7.432 15.508 11.674 14.447 18.038 8.083 14.856 4.901"></polygon>
                    <path d="M21.751,4.371 L22.811,5.431 C23.0925125,5.71233215 23.2506798,6.0940087 23.2506798,6.492 C23.2506798,6.8899913 23.0925125,7.27166785 22.811,7.553 L19.5,10.863" id="Shape"></path>
                    <path d="M18.038,8.083 L21.751,4.371 C22.0325125,4.08966785 22.1906798,3.7079913 22.1906798,3.31 C22.1906798,2.9120087 22.0325125,2.53033215 21.751,2.249 L20.69,1.189 C20.1042502,0.603427093 19.1547498,0.603427093 18.569,1.189 L14.856,4.9" id="Shape"></path>
                    <path d="M20.251,13.5 L20.251,16.628 C20.251,17.0260373 20.09263,17.4077203 19.811,17.689 L14.69,22.81 C14.4087203,23.09163 14.0270373,23.25 13.629,23.25 L2.25,23.25 C1.42157288,23.25 0.75,22.5784271 0.75,21.75 L0.75,2.25 C0.75,1.42157288 1.42157288,0.75 2.25,0.75 L13.5,0.75" id="Shape"></path>
                    <path d="M14.251,23.115 L14.251,18.75 C14.251,17.9215729 14.9225729,17.25 15.751,17.25 L20.116,17.25" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
    </svg >
}
export default Note