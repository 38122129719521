import { takeEvery, call, put, all, select } from "redux-saga/effects";
import * as FilePreviewActionTypes from '../../constants/shared/filePreviewConstants';
import * as  appActionTypes from '../../constants/shared/appStatesConstants';
import {
    previewFile
} from '../../../shared/core/utils/apiFunctions';

export function* topicFilesWatcher() {
    yield takeEvery(FilePreviewActionTypes.TOPIC_FILES_API_VIEW_REQUEST, viewFileSaga);
}

function* viewFileSaga(action) {
    try {
        const getAppState = (state) => state.appUIStateReducer;
        const appInfo  = yield select(getAppState);

        if (appInfo !== undefined) {
            let grpCode = appInfo.selectedGroup.grpCode;
            const fileResponse = yield call(previewFile, grpCode, action.topicCode, action.itemCode);
            if (fileResponse.status === 200) {
                //saveAs(new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] }), action.itemCode)
                const blob = new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] });
                yield put({
                    type: FilePreviewActionTypes.TOPIC_FILES_API_VIEW_SUCCESS, response: blob,
                    itemCode: action.itemCode,
                    contenttype: fileResponse.headers['content-type']
                })
            }
        }
    }
    catch (error) {
        if (error.response != null && error.response.data != null) {
            const errorResponse = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
            const jsonResponse = JSON.parse(errorResponse);
            if (jsonResponse != null && (jsonResponse.status === 403 || jsonResponse.status === 401)) {
                yield put({ type: appActionTypes.APP_STATE_GENERIC_ERROR, itemCode: action.itemCode, errorResponse: jsonResponse });
            }
            yield put({ type: FilePreviewActionTypes.TOPIC_FILES_API_VIEW_FAILURE, itemCode: action.itemCode, error: { response: { data: jsonResponse, status: error.response.status } } })
        }
        else {
            yield put({ type: FilePreviewActionTypes.TOPIC_FILES_API_VIEW_FAILURE, itemCode: action.itemCode, error: {} })
        }
    }
}


export default topicFilesWatcher;
