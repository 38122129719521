import { takeLatest, call, put, all, delay, takeEvery } from "redux-saga/effects";
import { downloadItem, downloadMultipleItems, downloadMultipleFiles, getFileList, personalVaultUploadFile, updateItemAPI, deleteItemAPI, getItemsListAPI, getItemsDetailsAPI } from '../../../shared/core/utils/apiFunctions'
import { saveAs } from "file-saver";
import {
    MY_FILES_GET_ITEMS_FILES_REQUEST,
    MY_FILES_GET_ITEMS_FILES_SUCCESS,
    MY_FILES_GET_ITEMS_FILES_FAILURE,
    MY_FILES_LAZY_LOAD_MORE_FILES_REQUEST,
    MY_FILES_LAZY_LOAD_MORE_FILES_SUCCESS,
    MY_FILES_LAZY_LOAD_MORE_FILES_FAILURE,
    MY_FILES_UPLOAD_FILES_REQUEST,
    MY_FILES_UPLOAD_FILES_SUCCESS,
    MY_FILES_UPLOAD_FILES_FAILURE
} from '../../constants/personalVault/myFilesConstants'

export function* myFilesWatcher() {
    // yield takeLatest(GET_FILE_DOWNLOAD_API_REQUEST, downloadSaga);
    yield takeLatest(MY_FILES_GET_ITEMS_FILES_REQUEST, filesSaga);
    yield takeLatest(MY_FILES_LAZY_LOAD_MORE_FILES_REQUEST, filesLazyLoadSaga);
    // yield takeLatest(GET_MULTIPLE_FILES_DOWNLOAD_REQUEST, downloadMultipleItemsSaga);
    // yield takeLatest(ADDTO_CURRENT_FOLDER, addBreadCrumb);
    // yield takeLatest(STRIP_CURRENT_FOLDERPATH, stripBreadCrumb);
    // yield takeEvery(FOLDER_API_UPDATE_ITEM, updateItem)
    // yield takeLatest(ITEM_API_DELETE, deleteItem);
    // yield takeLatest(GET_ITEMS_CURRENT, getCurrentFilesSaga);
    // yield takeLatest(LOAD_CURRENT_ITEMS, loadCurrentItems);
    yield takeLatest(MY_FILES_UPLOAD_FILES_REQUEST, filesUploadSaga);
}

function* filesSaga(action) {
    try {
        const items = yield call(getFileList, action.itemCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy)
        yield put({ type: MY_FILES_GET_ITEMS_FILES_SUCCESS, responseInfo: items })
    } catch (error) {
        yield put({ type: MY_FILES_GET_ITEMS_FILES_FAILURE, error });
    }
}

function* filesLazyLoadSaga(action) {
    try {
        const items = yield call(getFileList, action.itemCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy)
        yield put({ type: MY_FILES_LAZY_LOAD_MORE_FILES_SUCCESS, responseInfo: items })
    } catch (error) {
        yield put({ type: MY_FILES_LAZY_LOAD_MORE_FILES_FAILURE, error });
    }
}

function* filesUploadSaga(action) {
    try {
        const files = yield call(personalVaultUploadFile, action.files);
        yield put({ type: MY_FILES_UPLOAD_FILES_SUCCESS, files: files});
    } catch (err) {
        yield put({ type: MY_FILES_UPLOAD_FILES_FAILURE, err });
    }
}

export default myFilesWatcher;