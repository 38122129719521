import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import DownloadItems from '../downloadItems/DownloadItems';
import CommonModal from '../../../shared/ui/commonModal/commonModal';
import CommonItemsActionsBar from '../../../shared/ui/commonFileItemList/CommonItemsAction';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import { connect } from 'react-redux';
import DeleteItems from '../deleteItems/DeleteItems';
import * as GAConstants from '../../../shared/core/GAConstants';
import ReactGA from 'react-ga';
import { CeremonyStatus , ItemCeremonyStatus } from '../../../shared/core/CeremonyStatus';

export const styles = (theme: any) => ({
    fileActionBar: {
        borderRight: '1px solid #c5c5c5',
        paddingRight: '.5em',
        marginRight: '.5em',
        [theme.breakpoints.down('md')]: {
            borderRight: 'none'
        }
    },
    iconBtn: {
        width: '20px',
        height: '20px',
    }
});


type ItemActionsBarProps = {
    selectedFiles?: any[],
    updateSnackbar: any;
    filePreview?: any;
    topic: any;
    onActionComplete: any;
    isEndUser: boolean;
    isFilePreviewable: boolean;
    isFileValidForEsign: any;
    showfileDetails: any;
    ceremonyTypes: any;
    onCermonyAction: any;
}

class ItemActionsBar extends Component<ItemActionsBarProps>
{
    state = {
        isModalOpen: false,
        modalName: '',
        isDownloadIntiated: false
    }

    setModalOpen = (modalState: boolean) => {
        this.setState({ isModalOpen: modalState })
    }

    setmodalName = (modalName: string) => {
        this.setState({ modalName: modalName })
    }

    isSingleItemSelected = () => (this.props.selectedFiles != null && this.props.selectedFiles.length === 1) ? true : false
    isMultipleItemsSelected = () => (this.props.selectedFiles != null && this.props.selectedFiles.length > 0) ? true : false
    isSingleFileSelected = () => (this.props.selectedFiles != null && this.props.selectedFiles.length === 1 && this.props.selectedFiles[0].itemType === 'FILE') ? true : false
    isMultipleFilesSelected = () => {
        var valid = (this.props.selectedFiles != null && this.props.selectedFiles.length > 0)
        this.props.selectedFiles != null && this.props.selectedFiles.forEach(x => {
            if (x.itemType === 'FOLDER' || x.itemType === 'FORM')
                valid = false;
        }
        )
        return valid;
    }
 
    IsSigningCeremonyInProgress = (ceremonyStatus:string) => {
        return (
            ceremonyStatus == ItemCeremonyStatus.REQUESTED ||
            ceremonyStatus == ItemCeremonyStatus.CANCELLED ||
            ceremonyStatus == ItemCeremonyStatus.AGREED ||
            ceremonyStatus == ItemCeremonyStatus.DECLINED ||
            ceremonyStatus == ItemCeremonyStatus.FAILED ||
            ceremonyStatus == ItemCeremonyStatus.IN_PROGRESS ||
            ceremonyStatus == ItemCeremonyStatus.WAITING_SIGNED_DOC
        )
    }

    IsDeleteOptionForEndUser = () => {
        var valid = (this.props.selectedFiles != null && this.props.selectedFiles.length > 0)
        this.props.selectedFiles != null && this.props.selectedFiles.forEach(x => {
            if (x.ceremonyStatus !== undefined) {
                if(this.IsSigningCeremonyInProgress(x.ceremonyStatus))
                    valid = false;
            }
            else
                if (x.itemType === 'FOLDER' || !x.owner.isOwner)
                    valid = false;
        }
        )
        return valid;
    }

    IsDeleteOptionForAdvisor = () => {
        var valid = (this.props.selectedFiles != null && this.props.selectedFiles.length > 0)
        this.props.selectedFiles != null && this.props.selectedFiles.forEach(x => {
            if(x.ceremonyStatus !== undefined){
                if(this.IsSigningCeremonyInProgress(x.ceremonyStatus))
                   valid = false;

            }
            else if (x.itemType === 'FOLDER' || !x.owner.isGroupOwner)
                valid = false;
        }
        )
        return valid;
    }

    isPreviewable = () => {
        let previewable = false;
        if (this.isSingleItemSelected() && this.props.selectedFiles) {
            if (this.props.isFilePreviewable) {
                previewable = true;
            }
        }
        return previewable
    }

    validEsignCermony = () => {
        let validEsign = false;
        if (!this.props.isEndUser && this.props.selectedFiles != null &&
            this.props.selectedFiles.length === 1 &&
            this.props.selectedFiles[0].ceremonyStatus == null &&
            this.props.ceremonyTypes != null &&
            this.props.ceremonyTypes.ceremonies.length > 0) {
            if (this.props.isFileValidForEsign) {
                validEsign = true;
            }
        }
        return validEsign
    }

    validEsignProgressCermony = () => {
        let validEsign = false;
        if (this.props.selectedFiles != null &&
            this.props.selectedFiles.length === 1 &&
            (this.props.selectedFiles[0].ceremonyStatus == 'REQUESTED' || this.props.selectedFiles[0].ceremonyStatus == 'IN_PROGRESS')) {
            if (this.props.isFileValidForEsign) {
                validEsign = true;
            }
        }
        return validEsign
    }



    handleActionDialogClose = () => {
        this.setState({ isModalOpen: false })
    }

    onActionComplete = (issuccess: boolean, message: string) => {
        if (null != message) {
            if (issuccess)
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: message
                })
            else
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: message,
                    isError: true
                })
        }
        switch (this.state.modalName) {
            case 'delete':
                let category = '';
                if (null != this.props.selectedFiles && this.props.selectedFiles.length > 0) {
                    this.props.selectedFiles?.forEach((file: any) => {
                        if (this.props.isEndUser)
                            category = GAConstants.USER_EVENT_CATEGORY;
                        else
                            category = GAConstants.ASSOCIATE_EVENT_CATEGORY;
                        ReactGA.event({
                            category: category,
                            action: GAConstants.FILE_DELETED,
                        });
                    })
                }
                this.props.onActionComplete(true);
                break;
            default:
                this.props.onActionComplete(false);
        }
        this.setState({ isModalOpen: false, modalName: '' })
    }

    modalContentCreate = (name: string) => {
        switch (name) {
            case 'delete':
                return <CommonModal
                    modalOpen={this.state.isModalOpen}
                    modalClose={this.handleActionDialogClose}
                    content={<DeleteItems
                        onActionComplete={this.onActionComplete}
                        topicCode={this.props.topic.stCode}
                        handleModalClose={this.handleActionDialogClose}
                        selectedItems={(this.props.selectedFiles != null) ? this.props.selectedFiles : []}
                    >
                    </DeleteItems>}
                />
            default:
                return null
        }
    }

    onActionClick = (name: string) => {
        this.setState({ isModalOpen: true })
        this.setState({ modalName: name })
        var item = (this.props.selectedFiles != null && this.props.selectedFiles.length === 1) ? this.props.selectedFiles[0] : null
        switch (name) {
            case 'download':
                this.setState({ isDownloadIntiated: true });
                break;
            case "esignInitiation":
                this.props.onCermonyAction(item, CeremonyStatus.CEREMONY_INITIATE_INSTUSER,);
                break;
            case "esignProgress":
                if (!this.props.isEndUser)
                    this.props.onCermonyAction(item, CeremonyStatus.CEREMONY_REQUESTED_INSTUSER);
                else
                    this.props.onCermonyAction(item, CeremonyStatus.CEREMONY_REQUESTED_ENDUSER);
                break;

            default:
                return null
        }
    }

    resetDownload = () => {
        this.setState({ isDownloadIntiated: false })
    }

    render() {
        return (
            <>
                <CommonItemsActionsBar data-test='itemActionBar'
                    showEsign={this.validEsignCermony()}
                    showEsignProgress={this.validEsignProgressCermony()}
                    showDownload={this.isMultipleFilesSelected()}
                    showDelete={this.props.isEndUser ? this.IsDeleteOptionForEndUser() : this.IsDeleteOptionForAdvisor()}
                    showView={this.isPreviewable()}
                    onActionClick={this.onActionClick}
                    filePreview={this.props.filePreview}
                    showfileDetails={this.props.showfileDetails}
                    showDetails={this.isSingleFileSelected()}
                >
                </CommonItemsActionsBar>
                { this.modalContentCreate(this.state.modalName)}
                <DownloadItems
                    isDownloadIntiated={this.state.isDownloadIntiated}
                    resetDownload={this.resetDownload}
                    onActionComplete={this.onActionComplete}
                    topicCode={this.props.topic.stCode}
                    selectedItems={(this.props.selectedFiles != null) ? this.props.selectedFiles : []}
                    isEndUser={this.props.isEndUser} >
                </DownloadItems>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        ceremonyTypes: state.eSignReducer.ceremonyTypes
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ItemActionsBar));