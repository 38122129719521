import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

const withAuthorizationComponent = WrappedComponent => props => {
  const { loggedIn, dispatch, ...rest } = props;
  let authKey = WrappedComponent.name;
  
  authKey =
	  rest && rest.authorization
	  	? rest.authorization
	  	: (authKey && authKey === "AuthorizeChildren" && rest && rest.component)
	  		? rest.component.name
	  		: (!authKey)
	  			? "DEFAULT"
	  			: authKey;

  let hasRole = false;
  
  if (loggedIn && loggedIn.authorizations && loggedIn.authorizations[authKey]) {

    if (loggedIn.user_profile && loggedIn.user_profile.roles) {
      hasRole =
        [...loggedIn.authorizations[authKey]].filter(
          anAuth => loggedIn.user_profile.roles.indexOf(anAuth) >= 0
        ).length > 0;

    }
  } else {
    hasRole = false;
  }
  if (hasRole) return <WrappedComponent {...rest} />;
  else return null;
};

const AuthorizeChildren = props => {
  const { component: Component, children, ...rest } = props;
  if (Component)
    return (
      <Component _displayName={Component.name} {...rest}>
        {children}
      </Component>
    );
  else return children;
};
export const withAuthorization = compose(
  connect(
    state => ({
      loggedIn: state.login
    }),
    null
  ),
  withAuthorizationComponent
);
export const Authorization = withAuthorization(AuthorizeChildren);
