import { combineReducers } from 'redux';
import * as CognitoActionTypes from '../../constants/shared/cognitoLoginConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    signin:
    {
        signinProgress: false,
        signinResendCodeProgress: false,
        mfaValidationProgress: false,
        mfaMedium: '',
        mfaDestination: '',
        mfaResponse: null,
        error: null,
        mfaRequired: true
    },
    signup:
    {
        signupProgress: false,
        signupVerifyProgress: false,
        signupResendCodeProgress: false,
        mfaMedium: '',
        mfaDestination: '',
        mfaResponse: null,
        username: '',
        error: null
    },
    reset:
    {
        resetPasswordProgress: false,
        mfaMedium: '',
        mfaDestination: '',
        resetPasswordVerifyProgress: false,
        resetPasswordResendProgress: false,
        error: null,
    },
}

function signin(state = initState.signin, action) {
    switch (action.type) {
        case CognitoActionTypes.COGNITO_API_LOGIN_REQUEST:
            return {
                ...state,
                signinProgress: true,
                error: null
            }
        case CognitoActionTypes.COGNITO_API_LOGIN_MFA_REQUEST:
            return {
                ...state,
                mfaValidationProgress: true,
                error: null
            }
        case CognitoActionTypes.COGNITO_API_LOGIN_SUCCESS:
            return {
                ...state,
                signinProgress: false,
                mfaRequired: action.response.data.CODE_DELIVERY_DELIVERY_MEDIUM!= null ? true : false,
                mfaMedium: action.response.data.CODE_DELIVERY_DELIVERY_MEDIUM,
                mfaDestination: action.response.data.CODE_DELIVERY_DESTINATION,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_LOGIN_MFA_SUCCESS:
            return {
                ...state,
                mfaValidationProgress: false,
                mfaResponse: action.response,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_LOGIN_FAILURE:
            return {
                ...state,
                signinProgress: false,
                error: { ...action.error }
            };
        case CognitoActionTypes.COGNITO_API_LOGIN_MFA_FAILURE:
            return {
                ...state,
                mfaValidationProgress: false,
                error: { ...action.error, action: ErrorTypes.SDV_401_MISSING_TOKEN }
            };

        case CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE:
            return {
                ...state,
                signinResendCodeProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE_SUCCESS:
            return {
                ...state,
                signinResendCodeProgress: false,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE_FAILURE:
            return {
                ...state,
                signinResendCodeProgress: false,
                error: { ...action.error }
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_REQUEST:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupProgress: true
                },
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_SUCCESS:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupProgress: false,
                    //username: action.response.user.username,
                    //mfaMedium: action.response.codeDeliveryDetails.DeliveryMedium,
                    //mfaDestination: action.response.codeDeliveryDetails.Destination
                },
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_FAILURE:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupProgress: false
                },
                error: { ...action.error }
            };

        case CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_REQUEST:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupVerifyProgress: true
                },
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_SUCCESS:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupVerifyProgress: false
                },
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_FAILURE:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupVerifyProgress: false
                },
                error: { ...action.error }
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupResendCodeProgress: true
                },
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE_SUCCESS:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupResendCodeProgress: false
                },
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE_FAILURE:
            return {
                ...state,
                signup:
                {
                    ...state.signup,
                    signupResendCodeProgress: false
                },
                error: { ...action.error }
            };
        default:
            return state;
    }
}

function signup(state = initState.signup, action) {
    switch (action.type) {
        case CognitoActionTypes.COGNITO_API_SIGNUP_REQUEST:
            return {
                ...state,
                signupProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_SUCCESS:
            return {
                ...state,
                signupProgress: false,
                //username: action.response.user.username,
                //mfaMedium: action.response.codeDeliveryDetails.DeliveryMedium,
                //mfaDestination: action.response.codeDeliveryDetails.Destination
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_FAILURE:
            return {
                ...state,
                signupProgress: false,
                error: { ...action.error }
            };

        case CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_REQUEST:
            return {
                ...state,
                signupVerifyProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_SUCCESS:
            return {
                ...state,
                signupVerifyProgress: false,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_FAILURE:
            return {
                ...state,
                signupVerifyProgress: false,
                error: { ...action.error }
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE:
            return {
                ...state,
                signupResendCodeProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE_SUCCESS:
            return {
                ...state,
                signupResendCodeProgress: false,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE_FAILURE:
            return {
                ...state,
                signupResendCodeProgress: false,
                error: { ...action.error }
            };
        default:
            return state;
    }
}

function reset(state = initState.reset, action) {
    switch (action.type) {
        case CognitoActionTypes.COGNITO_API_RESET_PASSWORD:
            return {
                ...state,
                resetPasswordProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordProgress: false,
                mfaMedium: action.response.CodeDeliveryDetails.DeliveryMedium,
                mfaDestination: action.response.CodeDeliveryDetails.Destination,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordProgress: false,
                error: { ...action.error }
            };

        case CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD:
            return {
                ...state,
                resetPasswordVerifyProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordVerifyProgress: false,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordVerifyProgress: false,
                error: { ...action.error }
            };
        case CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD:
            return {
                ...state,
                resetPasswordResendProgress: true,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordResendProgress: false,
                mfaMedium: action.response.CodeDeliveryDetails.DeliveryMedium,
                mfaDestination: action.response.CodeDeliveryDetails.Destination,
                error: null
            };
        case CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordResendProgress: false,
                error: { ...action.error }
            };
        default:
            return state;
    }
}

export default combineReducers({
    signup,
    signin,
    reset
})