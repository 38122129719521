import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const CircularProgressWithLabel = (props: any) => {
   return (
      <Box position="relative" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} display="inline-flex" >
         <CircularProgress size="2rem" variant="static"  {...props
         } />
         <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
         >
            <Typography variant="caption" style={{ fontSize:'0.65rem'}} component="div" color="textSecondary" >{`${Math.round(props.value,)}%`}
            </Typography>
         </Box>
      </Box >
   );
};

export default CircularProgressWithLabel;