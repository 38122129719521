import React, { Component } from 'react';
import * as TopicActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import EditIcon from '@material-ui/icons/Edit';
import './TopicDetailProfileCard.scss';
import { connect } from 'react-redux';
import AddContact from '../../../contacts/components/addContact/AddContact';
import { CommonModal } from '../../../../../shared/ui';
import * as TopicDetailActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import Follow from '../follow/Follow';
import { Authorization } from '../../../../../shared/core/AuthorizationComponent';
import { formattedDate } from '../../../../../shared/core/utils/functions';

type TopicContactType = {
    userCode: string,
    firstName: string,
    lastName: string,
    userStatus: string
}

type TopicDetailProfileCardProps = {
    topic: TopicType;
    topicContacts: any;
    topicDetails: any;
    backTopicOnClick: any;
    getTopicContacts: any;
    getTopicDetails: any;
    isEndUser: any;
    onActionComplete: any;
    loggedIn: any;
    features: any;
}

type TopicType = {
    stCode: any,
    stName: any,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number
}

const content: any = {
    backBtnLabel: 'Back to All Topics',
    createdLabel: 'Created',
    // deleteTopicLabel: 'Delete Topic'
}



class TopicDetailProfileCard extends Component<TopicDetailProfileCardProps> {
    state = {
        isShowMore: false,
        isModalOpen: false,
        modalName: ''
    }

    componentDidMount() {
        //Don't call api's if logout is in-progress or successful
        if(this.props.loggedIn.successful) {
            this.props.getTopicDetails(this.props.topic.stCode)
            this.props.getTopicContacts(this.props.topic.stCode);
        }
    }

    editTopicOnClick = () => {
        this.setState({ isModalOpen: true, modalName: 'addcontacts' })
    }

    handleActionDialogClose = () => {
        this.setState({ isModalOpen: false, modalName: '' })
    }

    onActionComplete = (success: boolean) => {
        if (success) {
            //fetch the topics again
            this.props.getTopicContacts(this.props.topic.stCode);
            this.props.onActionComplete(success);
        }
        this.setState({ isModalOpen: false, modalName: '' })
    }

    getModalContent = (name: string) => {
        switch (name) {
            case 'addcontacts':
                return <AddContact
                    data-test="addcontact"
                    onActionComplete={this.onActionComplete}
                    handleError={this.handleActionDialogClose}
                    loadTopicContacts={false} 
                    />
            default:
                return null
        }
    }

    render() {
        return (
            <>
                <div className='root' data-test='topicDetailProfileCardComponent'>
                    <div className='profileContainer'>
                        <div className='TopicDetailleftIcon'>
                            <div className='topicProfileCardIcon' style={{ 'borderColor': this.props.topic.categoryIconColor, 'color': this.props.topic.categoryIconColor }}>
                                {this.props.topic.catgeoryIconLabel}
                            </div>
                        </div>
                        <div className='flexWrapper'>
                            <div className='TopicDetailleftSection'>
                                <div className='topicNameContainer'>
                                    <div className='topicNameWrapper'>
                                        <p className='TopicName'>{this.props.topic.stName}</p>
                                        {!this.props.isEndUser ?
                                            <button aria-label='Edit' title="Edit Topic" className='editBtn'>
                                                <EditIcon fontSize='small' aria-label='Edit' onClick={this.editTopicOnClick} />
                                            </button> : null}
                                    </div>
                                    {this.props.topic.categoryName &&
                                        <div className='memberWrapper'>
                                            <div className='contacts-wrapper'>
                                                <span className='memberLabel'>Category:</span>
                                                <span className='contactLabel'>{this.props.topic.categoryName}</span>
                                            </div>
                                        </div>
                                    }
                                    {this.props.topicContacts.length > 0 &&
                                        <div className='memberWrapper'>
                                            {!this.state.isShowMore &&
                                                <div className='contacts-wrapper'>
                                                    <span className='memberLabel'>Contacts:</span>
                                                    <span className='contactLabel'>
                                                        {this.props.topicContacts[0].userStatus === 'PENDING' ?
                                                            <span>{this.props.topicContacts[0].firstName} {this.props.topicContacts[0].lastName}(PENDING){this.props.topicContacts.length > 1 ? ';' : ''}</span> :
                                                            <span>{this.props.topicContacts[0].firstName} {this.props.topicContacts[0].lastName}{this.props.topicContacts.length > 1 ? ';' : ''}</span>
                                                        }
                                                    </span>
                                                    <span className='contactLabel'>
                                                        {this.props.topicContacts.length > 1 ? (this.props.topicContacts[1].userStatus === 'PENDING' ?
                                                            <span>{this.props.topicContacts[1].firstName} {this.props.topicContacts[1].lastName} (PENDING)</span> :
                                                            <span>{this.props.topicContacts[1].firstName} {this.props.topicContacts[1].lastName} </span>) :
                                                            null
                                                        }
                                                    </span>
                                                    <span className='showContactsWrapper'>
                                                        {this.props.topicContacts.length > 2 &&
                                                            <span className='showContactsWrapper'>
                                                                <a className='showContacts' onClick={() => this.setState({ isShowMore: true })}>... <span className='see-more-tag'>See more</span></a>
                                                            </span>
                                                        }
                                                    </span>
                                                </div>
                                            }
                                            {this.state.isShowMore &&
                                                <div className='contacts-wrapper'>
                                                    <span className='memberLabel'>Contacts:</span>
                                                    {this.props.topicContacts.map((contact: TopicContactType, index: number) => (
                                                        <span key={index} className='contactLabel'>
                                                            {contact.userStatus === 'PENDING' ?
                                                                <span>{contact.firstName} {contact.lastName} (PENDING);</span> :
                                                                <span>{contact.firstName} {contact.lastName}
                                                                    {index !== this.props.topicContacts.length - 1 ? <span>;</span> : <span></span>}
                                                                </span>
                                                            }
                                                        </span>
                                                    ))}
                                                    <span className='showContactsWrapper'>
                                                        <a className='showContacts less-contacts' onClick={() => this.setState({ isShowMore: false })}> <span className='see-more-tag'>See less</span></a>
                                                    </span>
                                                </div>
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='topicDetailsContainer'>
                                {
                                Boolean(this.props.features['followTopic']) &&
                                    <Authorization authorization="Follow" >
                                        <Follow data-test="follow" topicCode={this.props.topic.stCode} userProfile={this.props.loggedIn.user_profile} ></Follow>
                                    </Authorization>
                                }
                                <p className='TopicCreatedTime'>
                                    <b>{content.createdLabel}:</b> <span>{formattedDate(this.props.topic.createdAt)}</span>
                                </p>

                            </div>
                        </div>
                    </div>
                    <CommonModal modalOpen={this.state.isModalOpen}
                        modalClose={this.handleActionDialogClose}
                        content={this.getModalContent(this.state.modalName)} />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        topicContacts: state.topicReducer.topicContacts.contactsData.content,
        topicDetails: state.topicReducer.topicDetails,
        loggedIn: state.login,
        features: state.homeReducer.features,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getTopicContacts: (topicCode: any) => dispatch({
            type: TopicActionTypes.USER_API_GET_TOPIC_USERS_REQUEST,
            topicCode: topicCode
        }),
        getTopicDetails: (topicCode: string) => dispatch({
            type: TopicDetailActionTypes.TOPICS_API_GET_TOPICS_DETAILS_REQUEST,
            topicCode: topicCode,
        })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetailProfileCard)