import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import './Fs.fileDetail.page.scss';
import PreviewWindow from './components/previewWindow/PreviewWindow';
import FileDetailMenu from './components/fileDetailMenu/FileDetailMenu';

interface FileDetailPageProps extends RouteComponentProps<{}>{
    selectedFileForPreview: any,
    menuOpen: boolean,
}

type FileDetailPageStates = {}

class FsFileDetailPage extends Component<FileDetailPageProps, FileDetailPageStates> {
    
    state = {
        menuOpen: true
    }
    
    componentDidMount() {}

    openMenuToggle() {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    render() {
        return(
            <div className='page-wrapper'>
                <div className='main-window'>
                    <PreviewWindow />
                </div>
                <div className={ this.props.menuOpen ? 'menu-wrapper-open' : 'menu-wrapper-close'}>
                    { this.props.menuOpen && <FileDetailMenu />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileForPreview: state.appUIStateReducer.selectedFileToPreview,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FsFileDetailPage));