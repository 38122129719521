const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.homerootbgcolor,
        minHeight: '100vh',
        color: '#444657',
        fontFamily:theme.homefontfamilyregular,
    },
    sideBar: {
        width: 64,
        height: '100vh',
        backgroundColor: theme.actionbarbgcolor,
        position: 'absolute',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: theme.spacing(2),
            top: 0
        },
    },
    main: {
        padding: '2rem 0 2rem 7rem',
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 0 2rem 2rem',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 0 1rem 0',
        },
    },
    loginContent: {
        maxWidth: 600,
        width: '50%',
        padding: '0 1em 2em 1em',
        backgroundColor: '#fff',
        borderRadius: 6,
        fontFamily:theme.homefontfamilyregular,
        boxShadow: '0 0 8px 0 #F2F2F2',
        [theme.breakpoints.down('sm')]: {
            width: 470,
            padding: 0
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
            padding: 0
        },
    },
    namingWrapper: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    nameInput: {
        flex: '1 auto',
        margin: theme.spacing(2)
    },
    actionWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3em'
    },
    subtitles: {
        padding: '2rem',
    },
    subtitle: {
        padding: '0 0 16px 0',
        fontWeight: 'lighter',
         fontFamily: theme.homefontfamilyregular,
        letterSpacing: 0.2,
        lineHeight: 1.2,
        fontSize: theme.spacing(3) + 1,
    },
    description: {
        fontSize: 16,
        fontFamily: theme.homefontfamilyregular,
        letterSpacing: 0.5,
        // maxHeight: 56,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
            maxHeight: 'none',
        },
    },
    button: {
        width: 186,
        color: '#FFFFFF',
        fontWeight: 'normal',
        letterSpacing: 1.25,
        borderRadius:5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
          },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
          },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
          },
        textTransform: 'none',
        fontSize: 14,
        backgroundColor:theme.loginbuttonbgcolor,
        
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    statement: {
        marginTop: '1.5rem',
        width: 416,
        fontSize: 12,
        fontFamily: theme.homefontfamilyregular,
        lineHeight: 1.4,
        color: theme.loginstatementcolor,
        [theme.breakpoints.down('sm')]: {
            width: 470,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(100vw - 2rem)',
            height: 'auto',
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
    },
    link: {
        color: '#005F7C',
        cursor: 'pointer',
    },
    error: {
        marginTop: 10,
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVar: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        // fontFamily: 'Lato',
        fontSize: '14px'
    },
});

export default styles; 