String.prototype.replaceAt = function (index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + replacement.length)
  );
};

function openInNewTab(link) {
  var win = window.open(link, "_blank");
  win.focus();
}

export function openForm(fileResponse) {
  var dec = new TextDecoder();
  var urlLink = dec.decode(fileResponse.request.response);
  try {
    urlLink = urlLink.replaceAt(0, "");
    urlLink = urlLink.replaceAt(urlLink.length - 1, "");
    var urlLink = urlLink.replace("url", '"url"');
    var urlLink = urlLink.replace("name", '"name"');
    const JSONobj = JSON.parse(urlLink);
    const url = JSONobj["url"];
    openInNewTab(url);
  } catch (error) {
    console.log(error);
  }
}

export function getFormId(fileResponse) {
  try {
    var decodedString = String.fromCharCode.apply(null, new Uint8Array(fileResponse.data));
    var jsonObj = JSON.parse(decodedString);
    return jsonObj["formType"]["formTypeCode"]
  } catch (error) {
    return null;
  }
}

export function getSIGroupId(fileResponse) {
  try {
    var decodedString = String.fromCharCode.apply(null, new Uint8Array(fileResponse.data));
    var jsonObj = JSON.parse(decodedString);
    return jsonObj["formType"]["formTypeGroup"]
  } catch (error) {
    return null;
  }
}
