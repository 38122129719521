import { combineReducers } from 'redux';
import * as TopicContactActionTypes from '../../constants/customerVault/topicConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    allTopics:
    {
        fetching: false,
        error: null,
        products:
            [
                /*  {
                     productCode: '',
                     productName: '',
                     groups: [
                         {
                             groupCode: '',
                             groupName: '',
                             topics: []
                         }
                     ]
                 } */
            ]
    },
    topicList: {
        fetching: false,
        updating: false,
        lazyLoading: false,
        topicData: {
            content: [],
            page: {},
            sort: []
        },
        addedTopic:null,
        error: null
    },
    topicContacts: {
        fetching: false,
        updating: false,
        lazyLoading: false,
        error: null,
        contactsData: {
            content: [],
            page: {},
            sort: []
        },
    },
    topicMetadata: {
        updating: false,
        topicMetadata: {
            content: []
        },
        error: null
    },
    topicCategories:
    {
        fetching: false,
        categoryData: {
            content: [],
            page: {},
            sort: []
        },
        error: null
    },
    topicDetails:
    {
        fetching: true,
        topicData: null,
        error: null
    }
    
}

function userTopicList(state = initState.allTopics, action) {
    switch (action.type) {
        // Get Contacts
        case TopicContactActionTypes.TOPICS_API_GET_ALL_TOPICS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case TopicContactActionTypes.TOPICS_API_GET_ALL_TOPICS_SUCCESS:
            var grpCode = action.grpCode;
            var grpName = action.grpName;
            var productCode = action.productCode;
            // var productName = action.productName;
            var index = state.products && state.products.findIndex(x =>
                x.productCode === productCode
            )

            if (action.responseInfo.data.content.length > 0) {
                if (index < 0) { //product doesn't exist
                    return {
                        ...state,
                        fetching: false,
                        products:
                            [...state.products,
                            {
                                productCode: productCode,
                                groups: [
                                    {
                                        groupCode: grpCode,
                                        groupName: grpName,
                                        topics: action.responseInfo.data.content.length > 0 ? action.responseInfo.data.content.sort(
                                            (a, b) => a.categoryName.localeCompare(b.categoryName, undefined, { caseFirst: "upper" })
                                        ) : []
                                    }
                                ]
                            }]
                    }
                }
                else { //If product exists add a group
                    return {
                        ...state,
                        fetching: false,
                        products: state.products.map((item) => (
                            item.productCode === action.productCode ? {
                                ...item,
                                groups: [
                                    ...item.groups,
                                    {
                                        groupCode: grpCode,
                                        groupName: grpName,
                                        topics: action.responseInfo.data.content.length > 0 ? action.responseInfo.data.content.sort(
                                            (a, b) => a.categoryName.localeCompare(b.categoryName, undefined, { caseFirst: "upper" })
                                        ) : []
                                    }
                                ]
                            } : item))
                    }
                }
            }
            else {
                return {
                    ...state,
                    fetching: false,
                };
            }
        case TopicContactActionTypes.TOPICS_API_GET_ALL_TOPICS_FAILURE:
            return {
                ...state,
                fetching: false,
                products: [],
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        case TopicContactActionTypes.CLEAR_TOPICS:
            return {
                ...state,
                fetching: false,
                products: [],
                error: null
            };
        default:
            return state;
    }
}

function topicContacts(state = initState.topicContacts, action) {
    switch (action.type) {
        // Get Contacts
        case TopicContactActionTypes.USER_API_GET_TOPIC_USERS_REQUEST:
            return {
                ...state,
                fetching: true,
                error: null,
                contactsData: {
                    content: [],
                    page: {},
                    sort: []
                }
            };
        case TopicContactActionTypes.USER_API_GET_TOPIC_USERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                contactsData: { ...action.contacts.data }
            };
        case TopicContactActionTypes.USER_API_GET_TOPIC_USERS_FAILURE:
            return {
                ...state,
                fetching: false,
                contactsData: {
                    content: [],
                    page: {},
                    sort: []
                },
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;
    }
}

function topicList(state = initState.topicList, action) {
    switch (action.type) {
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_SUCCESS:
            return {
                ...state,
                fetching: false,
                topicData: {
                    ...state.topicData,
                    content: action.responseInfo.data.content,
                    page: action.responseInfo.data.page,
                    sort: action.responseInfo.data.sort
                }
            };
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_FAILURE:
            return {
                ...state,
                updating: false,
                topicData: {
                    grpCode: '',
                    content: [],
                    page: {},
                    sort: []
                },
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };

        case TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_START:
            return {
                ...state,
                lazyLoading: true,
            };
        case TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_REQUEST:
            return {
                ...state,
                lazyLoading: true,
            };
        case TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_SUCCESS:
            return {
                ...state,
                lazyLoading: false,
                topicData: {
                    ...state.topicData,
                    content: [
                        ...state.topicData.content,
                        ...action.responseInfo.data.content
                    ],
                    page: {
                        ...state.topicData.page,
                        ...action.responseInfo.data.page
                    },
                    sort: action.responseInfo.data.sort
                }
            };
        case TopicContactActionTypes.TOPICS_API_LOAD_MORE_TOPICS_FAILURE:
            return {
                ...state,
                updating: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };

        case TopicContactActionTypes.TOPICS_API_ADD_TOPICS_REQUEST:
            return {
                ...state,
                updating: true
            };
        case TopicContactActionTypes.TOPICS_API_ADD_TOPICS_SUCCESS:
            return {
                ...state,
                updating: false,
                addedTopic:action.topic.data,
                error: null,
            };
        case TopicContactActionTypes.TOPICS_API_ADD_TOPICS_FAILURE:
            return {
                ...state,
                updating: false,
                error: { ...action.error }
            };
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_FAILURE:
            return {
                ...state,
                updating: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };

        default:
            return state;
    }
}

function topicCategories(state = initState.topicCategories, action) {
    switch (action.type) {
        // Get Contacts
        case TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                fetching: false,
                categoryData: { ...action.categories.data }
            };
        case TopicContactActionTypes.TOPICS_API_GET_CATEGORIES_FAILURE:
            return {
                ...state,
                fetching: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;
    }
}


function topicDetails(state = initState.topicDetails, action) {
    switch (action.type) {
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
                error: null
            };
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                topicData: action.responseInfo.data,
	            error: null
            };
        case TopicContactActionTypes.TOPICS_API_GET_TOPICS_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                topicData: null,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        case TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_REQUEST:
            return {
                ...state,
                updating: true,
                error: null
            };
        case TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_SUCCESS:
            return {
                ...state,
                updating: false,
                topicData: action.responseInfo.data,
                error: null
            };
        case TopicContactActionTypes.USER_API_EDIT_TOPIC_USERS_FAILURE:
            return {
                ...state,
                updating: false,
                error: { ...action.error }
            };
        default:
            return state;
    }
}

function topicMetadata(state = initState.topicMetadata, action) {
    switch (action.type) {
        // Add Metadata
        case TopicContactActionTypes.TOPICS_API_ADD_METADATA_REQUEST:
            return {
                ...state,
                error: null,
                updating: true
            };
        case TopicContactActionTypes.TOPICS_API_ADD_METADATA_SUCCESS:
            return {
                ...state,
                updating: false,
                topicMetadata: { ...action.data }
            };
        case TopicContactActionTypes.TOPICS_API_ADD_METADATA_FAILURE:
            return {
                ...state,
                updating: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        // Update Metadata
        case TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_REQUEST:
            return {
                ...state,
                error: null,
                updating: true
            };
        case TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_SUCCESS:
            return {
                ...state,
                updating: false,
                topicMetadata: { ...action.data }
            };
        case TopicContactActionTypes.TOPICS_API_UPDATE_METADATA_FAILURE:
            return {
                ...state,
                updating: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;
    }
}

export default combineReducers({
    topicContacts,
    topicList,
    topicCategories,
    userTopicList,
    topicDetails,
    topicMetadata
})
