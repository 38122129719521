import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopicListView from '../topics/components/topicListView/TopicListView';
import ContactDetailProfileCard from './contactDetailProfileCard/ContactDetailProfileCard';
import { RouteComponentProps, withRouter } from 'react-router';
import { Authorization } from '../../../shared/core/AuthorizationComponent';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as TopicActionTypes from '../../../redux/constants/customerVault/topicConstants';
import * as ContactListActions from '../../../redux/constants/customerVault/userConstants';
import { CommonModal } from '../../../shared/ui';
import AddTopic from '../topics/components/addTopic/AddTopic';
import { paginationTopics } from '../../../shared/core/utils/paginationDefaults';

interface ContactDetailPageProps extends RouteComponentProps<{}> {
    addBreadcrumbItem: any,
    selectedGroup: any,
    contacts: any,
    RemoveBreadcrumbItems: any,
    fetchContact: any,
    selectedContact: any,
    fetchTopics: any
}

type ContactDetailPageStates = {}

class ContactDetailPage extends Component<ContactDetailPageProps, ContactDetailPageStates> {

    constructor(props: ContactDetailPageProps) {
        super(props);
        this.userCode = window.location.pathname.split('/').pop();
    }

    userCode = window.location.pathname.split('/').pop();

    state = {
        selectedUser: {
            addedToGroup: '',
            connectedTopics: 0,
            countryPhoneCode: 0,
            email: '',
            externalId: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            sharedTopicNames: [],
            status: '',
            userCode: '',
        },
        isModalOpen: false,
        modalName: '',
        selectedContact: '',
        pagination: { ...paginationTopics },
        filter: {
            categoryCode: '',
            createdAt: '',
            modifiedAt: '',
        },
    }

    componentDidMount() {
        //Get the contact name from the first users list
        let user = null;
        user = this.props.contacts.content.find((x: any) => x.userCode === this.userCode)
        this.setState({ selectedUser: user })
        if (user != null) {
            let contactName = user.firstName + ' ' + user.lastName;
            this.props.RemoveBreadcrumbItems(1);
            this.props.addBreadcrumbItem(contactName, `/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/${this.userCode}`);
        }
        else {
            this.props.fetchContact(this.userCode);
        }
    }

    componentDidUpdate(prevProps: any) {
        const { selectedContact } = this.props;
        if (null != selectedContact && prevProps.selectedContact.fetching != selectedContact.fetching && !selectedContact.fetching) {
            if (selectedContact.error != null) {
                this.props.history.push('/customer');
            }
            else {
                this.setState({ selectedUser: selectedContact.contact })
                let contactName = selectedContact.contact.firstName + ' ' + selectedContact.contact.lastName;
                this.props.RemoveBreadcrumbItems(1);
                this.props.addBreadcrumbItem(contactName, `/customer/group/${this.props.selectedGroup.grpCode}/contact-detail/${this.userCode}`);
            }
        }
    }

    topicListViewProps = {
        selectedUser: this.userCode,
        tableMarginHeightExtra: 90
    }

    openAddTopic = (contact: any) => {
        this.setState({ selectedContact: contact });
        this.setState({ isModalOpen: true, modalName: 'addtopic' })
    }

    onModalClose = () => {
        this.setState({ isModalOpen: false, modalName: '' })
    }

    onActionComplete = () => {
        //Fetch all the topics again
        this.setState({ isModalOpen: false, modalName: '' });
        this.setState({ updateTopics: true });
        this.props.fetchTopics(this.state.pagination, this.state.filter, this.state.selectedUser.userCode);
    }

    handleError = () => {
        console.log("Error handler")
    }

    getModalContent = (name: String) => {
        switch (name) {
            case 'addtopic':
                return <AddTopic
                    data-test='addtopic'
                    topicContacts={[this.state.selectedUser]}
                    onActionComplete={this.onActionComplete}
                    handleError={this.handleError}
                    categoryExists={false}
                    title={'Create A Topic'} />
            default:
                return null
        }
    }

    render() {
        return (
            <div data-test='contactDetailWrapper'>
                <div className="breadcrumb-wrapper">
                    <Authorization authorization="AddTopic">
                        <div>
                            <Button className="add-topic-btn"
                                data-test='addtopicbtn'
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={this.openAddTopic}>
                                Add Topic
                        </Button>
                        </div>
                    </Authorization>
                </div>
                <ContactDetailProfileCard contact={this.state.selectedUser} />
                <TopicListView {...this.topicListViewProps} />
                <CommonModal  data-test='commonmodal' modalOpen={this.state.isModalOpen}
                    modalClose={this.onModalClose}
                    content={this.getModalContent(this.state.modalName)} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedGroup: state.appUIStateReducer.selectedGroup,
        contacts: state.userReducer.groupContacts.contactsData,
        selectedContact: state.userReducer.selectedContact
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
        addBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.ADD_BREADCRUMB_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        fetchContact: (userCode: any) => dispatch({
            type: ContactListActions.USER_API_GET_USER_REQUEST,
            userCode: userCode
        }),
        fetchTopics: (pagination: any, filter: any, userCode?: any) => dispatch({
            type: TopicActionTypes.TOPICS_API_GET_TOPICS_REQUEST,
            pagination, filter,
            userCode: userCode
        }),
    };
};

export const ContactDetailComponent = connect(mapStateToProps, mapDispatchToProps)(ContactDetailPage)

export default withRouter(ContactDetailComponent);