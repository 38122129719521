import { takeLatest, call, put, all, select } from "redux-saga/effects";
import {
  HOME_API_CALL_REQUEST, HOME_API_CALL_SUCCESS, HOME_API_CALL_FAILURE,
  HOME_API_CONSENT_REQUEST, HOME_API_CONSENT_SUCCESS, HOME_API_CONSENT_FAILURE,
  HOME_API_GET_PRODUCT_REQUEST, HOME_API_GET_PRODUCT_SUCCCESS, HOME_API_GET_PRODUCT_FAILURE,
} from '../../redux/constants/shared/homeConstants'
import { SDV_501_INST_USER, SDV_501_END_USER, SDV_404_NOT_FOUND } from '../../shared/errors/ErrorMessages';
import { getProduct, getGroupsForUser, updateUserConsentTrmSvc } from '../../shared/core/utils/apiFunctions';

export function* homeWatcher() {
  yield takeLatest(HOME_API_GET_PRODUCT_REQUEST, productSaga);
  yield takeLatest(HOME_API_CALL_REQUEST, workerSaga);
  yield takeLatest(HOME_API_CONSENT_REQUEST, consentSaga);
}

function* productSaga() {
  try {
    const getUser = (state) => state.login;
    const { userAccessInfo, product } = yield all({
      userAccessInfo: select(getUser),
      product: call(getProduct),
    })

    if (userAccessInfo !== undefined) {
      yield put({ type: HOME_API_GET_PRODUCT_SUCCCESS, userAccessInfo: userAccessInfo, productInfo: product });
    }
  } catch (error) {
    yield put({ type: HOME_API_GET_PRODUCT_FAILURE, error });
  }
}

function* workerSaga() {
  try {
    const getUser = (state) => state.login;
    // const getProductInfo = (state) => state.homeReducer.productInfo;
    const { userAccessInfo } = yield all({
      userAccessInfo: select(getUser),
      // product: select(getProductInfo),
    })

    if (userAccessInfo !== undefined) {
      const userCode = userAccessInfo.user_profile.userCode;
      const groups = yield call(getGroupsForUser, userCode);

      if (groups.data.page.totalElements >= 1) {
        yield put({ type: HOME_API_CALL_SUCCESS, userAccessInfo: userAccessInfo, groupsInfo: groups });
      } else {
        const error = { response: { data: { errMsg: userAccessInfo.user_profile.roles.indexOf('USER') > -1 ? SDV_501_END_USER : SDV_501_INST_USER }, status: 501 } }
        yield put({ type: HOME_API_CALL_FAILURE, error });
      }
    }
  } catch (error) {
    yield put({ type: HOME_API_CALL_FAILURE, error });
  }
}

function* consentSaga(action) {
  try {
    const getUser = (state) => state.login;
    const { userAccessInfo } = yield all({
      userAccessInfo: select(getUser),
    })
    if (userAccessInfo !== undefined) {

      const externalId = userAccessInfo.user.sub;
      const consentToTrmSvcInfo = yield call(updateUserConsentTrmSvc, externalId, action.consentFlag);

      if (consentToTrmSvcInfo !== undefined) {
        yield put({ type: HOME_API_CONSENT_SUCCESS, userTrmSvcInfo: consentToTrmSvcInfo });
      } else {
        const error = { response: { data: { errMsg: SDV_404_NOT_FOUND }, status: 501 } }
        yield put({ type: HOME_API_CONSENT_FAILURE, error });
      }
    }
  } catch (error) {
    yield put({ type: HOME_API_CONSENT_FAILURE, error });
  }
}

export default homeWatcher;