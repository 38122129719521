
export const GET_ITEMS_FILES_REQUEST = "GET_ITEMS_FILES_REQUEST"
export const GET_ITEMS_FILES_SUCCESS = "GET_ITEMS_FILES_SUCCESS"
export const GET_ITEMS_FILES_FAILURE = "GET_ITEMS_FILES_FAILURE"

export const FILES_ERROR_CLEAR="FILES_ERROR_CLEAR"

export const GET_API_HOME_FOLDER = "GET_API_HOME_FOLDER"
export const GET_API_HOME_FOLDER_SUCCESS = "GET_API_HOME_FOLDER_SUCCESS"
export const GET_API_HOME_FOLDER_FALIURE =  "GET_API_HOME_FOLDER_FALIURE"
export const ADDTO_CURRENT_FOLDER= "ADDTO_CURRENT_FOLDER"
export const ADDTO_CURRENT_FOLDER_SUCCESS= "ADDTO_CURRENT_FOLDER_SUCCESS"
export const STRIP_CURRENT_FOLDERPATH= "STRIP_CURRENT_FOLDERPATH"
export const STRIP_CURRENT_FOLDERPATH_SUCCESS ="STRIP_CURRENT_FOLDERPATH_SUCCESS"

export const ITEMS_API_GET_FILES_SUCCESS = "ITEMS_API_GET_FILES_SUCCESS"
export const ITEMS_API_GET_FILES_FAILURE = "ITEMS_API_GET_FILES_FAILURE"

export const GET_FILE_DOWNLOAD_API_REQUEST = "GET_FILE_DOWNLOAD_API_REQUEST"
export const GET_FILE_DOWNLOAD_API_FAILURE = "GET_FILE_DOWNLOAD_API_FAILURE"
export const GET_FILE_DOWNLOAD_API_SUCCESS = "GET_FILE_DOWNLOAD_API_SUCCESS"

export const FOLDER_API_UPDATE_ITEM = 'FOLDER_API_UPDATE_ITEM'
export const FOLDER_API_UPDATE_ITEM_SUCCESS = 'FOLDER_API_UPDATE_ITEM_SUCCESS'
export const FOLDER_API_UPDATE_ITEM_FAILED = 'FOLDER_API_UPDATE_ITEM_FAILED'
export const FOLDER_API_UPDATE_ITEMS ='FOLDER_API_UPDATE_ITEMS'

export const ITEM_API_DELETE = 'ITEM_API_DELETE'
export const ITEM_API_DELETE_SUCCESS = 'ITEM_API_DELETE_SUCCESS'
export const ITEM_API_DELETE_FAILED = 'ITEM_API_DELETE_FAILED'

export const CREATE_FOLDER_API_CREATE_REQUEST = "CREATE_FOLDER_API_CREATE_REQUEST"
export const CREATE_FOLDER_API_CREATE_SUCCESS = "CREATE_FOLDER_API_CREATE_SUCCESS"
export const CREATE_FOLDER_API_CREATE_FAILED = "CREATE_FOLDER_API_CREATE_FAILED"

export const GET_ITEMS_CURRENT = "GET_ITEMS_CURRENT"
export const GET_ITEMS_CURRENT_SUCCESS = "GET_ITEMS_CURRENT_SUCCESS"
export const GET_ITEMS_CURRENT_FAILURE = "GET_ITEMS_CURRENT_FAILURE"
export const LOAD_CURRENT_ITEMS_SUCCESS="LOAD_CURRENT_ITEMS_SUCCESS"
export const LOAD_CURRENT_ITEMS="LOAD_CURRENT_ITEMS"

export const GET_MULTIPLE_FILES_DOWNLOAD_REQUEST = "GET_MULTIPLE_FILES_DOWNLOAD_REQUEST"
export const GET_MULTIPLE_FILES_DOWNLOAD_FAILURE = "GET_MULTIPLE_FILES_DOWNLOAD_FAILURE"
export const GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS = "GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS"

export const itemRows = [
    { id: 'NAME', numeric: false, disablePadding: false, label: 'Name', width: '50%' ,sortEnabled: true },
    { id: 'CREATED_AT', numeric: false, disablePadding: false, label: 'Last Uploaded', width: 200,sortEnabled: true  },
    { id: 'Shared', numeric: false, disablePadding: false, label: 'Shared', width: 180 ,sortEnabled: false },
    { id: 'Tags', numeric: true, disablePadding: false, label: 'Tags', width: 180,sortEnabled: false },
];

export const getModalStyle = () => {
    const top = 80;
    const left = 20;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}