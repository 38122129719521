import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as HorizontalMenu } from '../../../assets/horizontal-menu.svg';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Tooltip } from '@material-ui/core';
import { formattedDate } from '../../core/utils/functions';


const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            height: "inherit"
        },
        container: {
            height: "inherit"
        },
        customTable: {

        },
        table: {
            minWidth: 750,
        },
        tableBody: {
            overflow: 'auto',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHeadRow: {
            backgroundColor: '#FAFAFA',
            height: '57px'
        },
        checkboxCell: {
            borderBottom: '1px solid #e8e8e8',
        },
        headerWrapper: {
            display: 'flex',
            justifyContent: 'flex-start'
        },
        headerTopic: {
            cursor: 'pointer',
        },
        headerTopicDefault: {
            cursor: 'default',
        },
        headWithIcon: {
            color: 'rgba(81, 81, 81, 0.87)',
            paddingLeft: '62px !important',
            fontSize: '.785em',
            fontWeight: 'bold'
        },
        headWithoutIcon: {
            color: 'rgba(81, 81, 81, 0.87)',
            paddingLeft: '16px',
            fontSize: '.785em',
            // width: '300px',
            fontWeight: 'bold'
        },
        headAlignRight: {
            textAlign: 'right',
            paddingLeft: 34
        },
        hiddenCol: {
            display: 'none'
        },
        shownCol: {
            display: 'flex'
        },
        itemListRow: {
            height: '53px',
            borderBottom: '1px solid #e8e8e8',
            borderCollapse: 'collapse',
            '&.Mui-selected': {
                backgroundColor: 'rgba(212,243,182,0.42)'
            }
        },
        filterBtnCell: {
            padding: '0 .5rem',
            width: '40px',
            textAlign: 'right'
        },
        filterMenuBtn: {
            width: '20px',
            height: '20px',
        },
        filterMenuItem: {
            paddingLeft: '10px',
            '&:hover': {
                color: '#000',
                backgroundColor: 'rgba(220,220,220,1)',
                cursor: 'pointer'
            }
        },
        typeIcon: {
            marginRight: '1rem'
        },
        avatarCell: {

            // borderBottom: 'none',
            // minHeight: '73px'
        },
        avatarCellContentWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        itemListCell: {
            // borderBottom: 'none',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 100,
            fontSize: '.875rem'
        },
        momentListCell: {
            borderBottom: 'none',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddngRight: '0 !important',
        },
        momentCell: {
            fontWeight: 200,
            fontSize: '.875rem',
            color: 'rbga(0, 0, 0, 0.54)',
            margin: 0,
            width: 125
        },
        categoryCell: {
            fontWeight: 200,
            fontSize: '.875rem',
            color: 'rbga(0, 0, 0, 0.54)',
            margin: 0,
            width: 170
        },
        actionCell: {
            color: 'rbga(0, 0, 0, 0.54)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'row'
        },
        topicIcon: {
            borderRadius: '4px',
            backgroundColor: '#333',
            width: '30px',
            height: '30px',
            padding: '5px',
            fontSize: '12px',
            margin: '0 1rem',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
        },
        topicTitle: {
            width: '20vw',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 700,
            fontSize: 14,
            margin: 0,
            textTransform: 'capitalize',
            color: '#0978A4',
            cursor: 'pointer',
            '&:hover': {
                color: '#0E67A9',
            }
        },
        permissionBtn: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem'
        },
        permissionStatic: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem'
        },
        progressbar: {
            height: '4px'
        },
        loadingWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        loadingContext: {
            color: '#8a8a8a',
            fontWeight: 700,
            fontSize: '11px',
            marginLeft: '10px'
        },
        groupName:
        {
            fontFamily: 'Fidelity Sans Bold',
            fontSize: '0.8rem',
            textTransform: 'uppercase',
            color: '#666666'
        },
        noTopics:
        {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: '#fff !important',
            padding: '8px 13px',
            boxShadow: '0 3px 6px rgba(0, 0, 0,0.16), 0 3px 2px rgba(0, 0, 0,0.23) !important'
        }

    }),
);

interface Data {
    stName: string,
    categoryName: string;
    createdAt: string;
    modifiedAt: string;
    contactsCount: number;
    topicsCount: number;
}


interface TopicType {
    stName: string,
    stCode: string,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number,
}

interface groupType {
    groupCode: string,
    groupName: string,
    topics: TopicType[]
}

interface productType {
    productCode: string,
    groups: groupType[],
}

type CommonUserTopicListProps = {
    productTopics: productType[];
    tableMarginHeight: number,
    onFileOpen: any,
    topicsFetching: boolean,
    userTopicListHeaders: string[],
    onUserHeadersFiltering: any
}

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onSelectFilters: any;
    sortOnClick: any;
    width: any;
    userTopicListHeaders: string[],
    onUserHeadersFiltering: any
}

interface HeadCell {
    id: keyof Data;
    label: string;
    filterable: boolean;
    withIcon: boolean;
    alignRight: boolean;
    sortable: boolean;
    showInTablet: boolean;
}

let headerCols: HeadCell[] = [
    { id: 'stName', label: 'Topic', withIcon: false, alignRight: false, filterable: false, sortable: true, showInTablet: true },
    { id: 'categoryName', label: 'Category', withIcon: false, alignRight: false, filterable: true, sortable: true, showInTablet: true },
    { id: 'createdAt', label: 'Created', withIcon: false, alignRight: false, filterable: true, sortable: true, showInTablet: true },
    { id: 'modifiedAt', label: 'Modified', withIcon: false, alignRight: false, filterable: true, sortable: true, showInTablet: true },
    { id: 'topicsCount', label: 'Files', withIcon: false, alignRight: true, filterable: true, sortable: false, showInTablet: false },
    { id: 'contactsCount', label: 'Contacts', withIcon: false, alignRight: true, filterable: true, sortable: false, showInTablet: false },
];

let headerColsArr = ['stName', 'categoryName', 'modifiedAt', 'createdAt', 'contactsCount', 'topicsCount'];

const EnhancedTableHead = withWidth()((props: EnhancedTableProps) => {
    const { classes, onSelectFilters, userTopicListHeaders } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterHeads, setFilterHeads] = useState<string[]>(userTopicListHeaders);
    const [sortBy, setSortBy] = useState<string>("categoryName");
    const [sortOrder, setSortOrder] = useState<any>("asc");

    useEffect(() => {
        if (isWidthDown('md', props.width)) {
            let copy_filterUserTopicHeads: string[] = [];
            filterHeads.forEach(el => {
                copy_filterUserTopicHeads.push(el)
            })
            props.onUserHeadersFiltering(copy_filterUserTopicHeads);
            let newSelected: string[] = selected;
            headerCols.forEach((x: HeadCell) => {
                if (props.userTopicListHeaders.includes(x.id)) {
                    x.showInTablet = true;
                } else {
                    x.showInTablet = false;
                }
            })
            headerCols.forEach((x: HeadCell) => {
                if (!x.showInTablet) {
                    let name = x.id;
                    const selectedIndex = newSelected.indexOf(name);
                    if (selectedIndex > -1) {
                        newSelected.splice(selectedIndex, 1);
                    }
                }
            })
            setFilterHeads(copy_filterUserTopicHeads);
            setSelected(newSelected);
            onSelectFilters(copy_filterUserTopicHeads);
        }
    }, [props.width])

    const filterMenusProc = () => {
        let filterable_menus: string[] = [];
        filterable_menus = props.userTopicListHeaders;
        return filterable_menus;
    }

    const [selected, setSelected] = useState<string[]>(filterMenusProc());

    const isSelected = (name: string) => {
        if (selected.indexOf(name) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    const filterMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const filterMenuOnClose = () => {
        setAnchorEl(null);
    };

    const filterItemOnClick = (event: React.MouseEvent<unknown>, name: string) => {
        let itemIndex = filterHeads.indexOf(name);
        let copy_filterUserTopicHeads: string[] = [];
        filterHeads.forEach(el => {
            copy_filterUserTopicHeads.push(el)
        })

        if (itemIndex > -1) {
            copy_filterUserTopicHeads.splice(itemIndex, 1)
        } else {
            copy_filterUserTopicHeads.push(name)
        }

        setFilterHeads(copy_filterUserTopicHeads);

        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        onSelectFilters(copy_filterUserTopicHeads);
        props.onUserHeadersFiltering(copy_filterUserTopicHeads);
    };


    const sortingOnClick = (column: any) => {
        const newsortBy = column.id;
        let newsortOrder = "desc";
        if (column.sortable) {
            if (sortBy === column.id && sortOrder === "desc") {
                newsortOrder = "asc";
            }
            setSortOrder(newsortOrder);
            setSortBy(newsortBy);
            props.sortOnClick(newsortBy, newsortOrder);
        }
    }

    return (
        <TableHead>
            <TableRow className={classes.tableHeadRow}>
                {
                    headerCols.map((column: HeadCell) => {
                        return (column.withIcon) ?
                            <TableCell className={`${classes.headWithIcon}`} key={column.id}>
                                <div className={classes.headerWrapper}>
                                    <div className={classes.headerTopic} onClick={() => sortingOnClick(column)}> {column.label}</div>
                                    {(column.sortable) ? <TableSortLabel
                                        onClick={() => sortingOnClick(column)}
                                        direction={sortOrder}
                                        active={sortBy === column.id}
                                        title={sortOrder === 'desc' ? 'Descending' : 'Ascending'}
                                    >
                                    </TableSortLabel> : null
                                    }
                                </div>
                            </TableCell>
                            : ((column.alignRight) ?
                                <TableCell className={`${classes.headWithoutIcon} ${classes.headAlignRight} ${filterHeads.indexOf(column.id) > -1 ? null : classes.hiddenCol}`} key={column.id}>
                                    <div className={classes.headerWrapper}>
                                        <div className={`${column.sortable ? classes.headerTopic : classes.headerTopicDefault}`} onClick={() => sortingOnClick(column)}> {column.label}</div>
                                        {(column.sortable) ? <TableSortLabel
                                            onClick={() => sortingOnClick(column)}
                                            direction={sortOrder}
                                            active={sortBy === column.id}
                                            title={sortOrder === 'desc' ? 'Descending' : 'Ascending'}
                                        >
                                        </TableSortLabel> : null
                                        }
                                    </div>
                                </TableCell> :
                                <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf(column.id) > -1 ? null : classes.hiddenCol}`} key={column.id}>
                                    <div className={classes.headerWrapper}>
                                        <div className={classes.headerTopic} onClick={() => sortingOnClick(column)}> {column.label}</div>
                                        {(column.sortable) ? <TableSortLabel
                                            data-test='sort-btn'
                                            onClick={() => sortingOnClick(column)}
                                            direction={sortOrder}
                                            active={sortBy === column.id}
                                            title={sortOrder === 'desc' ? 'Descending' : 'Ascending'}
                                        >
                                        </TableSortLabel> : null
                                        }
                                    </div>
                                </TableCell>)

                    })
                }

                <TableCell className={classes.filterBtnCell}>
                    <IconButton title="Filter" aria-label="Filter" onClick={filterMenuOnClick}>
                        <HorizontalMenu className={classes.filterMenuBtn} />
                    </IconButton>
                    <Menu id="filter-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={filterMenuOnClose}>
                        {headerCols.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} data-test='filter-itm' className={classes.filterMenuItem} onClick={event => filterItemOnClick(event, col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                    </Menu>
                </TableCell>
            </TableRow>
        </TableHead>
    );
})

const CommonUserTopicList = (props: CommonUserTopicListProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string[]>([]);
    const [groups, setgroups] = useState<productType[]>([]);
    // const [, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filters, setFilters] = useState<string[]>(props.userTopicListHeaders);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [tableHeight, setTableHeight] = useState<number>(0);
    const tableContainer: any = useRef<HTMLDivElement>(null);
    // const [, setTopic] = useState({});
    const [noTopics, setNoTopics] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize);
        setTableHeight(tableHeight);
        return () => window.removeEventListener("resize", handleResize);
    }, [filters])

    useEffect(() => {
        setgroups(props.productTopics);
        props.productTopics.forEach((item: productType) => {
            item.groups.forEach((group: groupType) => {
                if (group.topics.length > 0)
                    setNoTopics(false)
            });
        });
    }, [props.productTopics]);


    const itemOnClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const itemOnOpen = (event: React.MouseEvent<unknown>, topic: any, groupCode: string) => {
        event.stopPropagation();
        props.onFileOpen(topic, groupCode)
        setSelected([]);
    }


    const onSelectFilters = (updatedFilters: any) => {
        setFilters(updatedFilters);
    }


    if (!props.productTopics) {
        return null;
    }

    const onSorting = (sortBy: string, sortOrder: string) => {
        var temp = [...groups];
        groups.forEach((item: productType) => {
            item.groups.forEach((group: groupType) => {
                if (group.topics.length > 0)
                    setNoTopics(false)
                group.topics.sort(getSorting(sortOrder, sortBy));
            });
        });
        setgroups(temp);
    }

    const colorArray = [
        '#191970',
        '#cd5c5c',
        '#414040',
        '#48b4e0',
        '#006400'
    ];

    const getTopicstyle = (color: any) => {
        return {
            borderRadius: '4px',
            width: '12px',
            height: '12px',
            padding: '5px',
            fontSize: '10px',
            margin: '0 1rem',
            color: color,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
            borderWidth: '2px',
            borderStyle: 'solid'
        }
    }

    function getSorting(order: string, orderBy: string) {
        return order === "desc"
            ? (a: any, b: any) => (a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ? -1 : 1)
            : (a: any, b: any) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1);
    }

    return (
        <>
            {!props.topicsFetching &&
                <Paper elevation={0} className={classes.paper} data-test='commonUserTopicListNoDataFetching'>
                    <TableContainer ref={tableContainer}
                        className={classes.container}>
                        {noTopics && <div className={classes.noTopics} >No Topics to display. </div>}
                        {!noTopics && <Table className={classes.customTable} size="small" stickyHeader>
                            <EnhancedTableHead
                                classes={classes}
                                data-test='tableContainerUserTopicList'
                                onSelectFilters={onSelectFilters}
                                sortOnClick={onSorting}
                                userTopicListHeaders={props.userTopicListHeaders}
                                onUserHeadersFiltering={props.onUserHeadersFiltering} />
                            <TableBody className={classes.tableBody}>
                                {
                                    groups.map((product: productType) => {
                                        var index = 0
                                        return (<React.Fragment key={product.productCode} >
                                            {
                                                product.groups.sort(
                                                    (a, b) => a.groupName.localeCompare(b.groupName, undefined, { caseFirst: "upper" })
                                                ).map((group: groupType) => {
                                                    if (group.topics.length > 0) {
                                                        index = colorArray.length > index ? index : 0
                                                        const color = colorArray[index++];
                                                        const key = group.groupCode
                                                        return (
                                                            <React.Fragment key={key} >
                                                                <TableRow
                                                                    key={key} >
                                                                    <TableCell
                                                                        colSpan={7}
                                                                        style={{ fontWeight: "bold", cursor: "pointer" }}
                                                                    >
                                                                        <span className={classes.groupName}>{group.groupName}</span>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {
                                                                    group.topics.map((topic: TopicType, rIndex: any) => {
                                                                        const isItemSelected = false;
                                                                        const labelId = `enhanced-table-checkbox-${rIndex}`;
                                                                        return (<TableRow className={classes.itemListRow}
                                                                            data-test='item-list'
                                                                            key={rIndex}
                                                                            hover
                                                                            onClick={event => itemOnClick(event, topic.stCode)}
                                                                            aria-checked={isItemSelected}
                                                                            tabIndex={-1}
                                                                            selected={isItemSelected}>
                                                                            <TableCell id={labelId} scope="row" padding="none" className={classes.avatarCell}>
                                                                                <div className={classes.avatarCellContentWrapper}>
                                                                                    <div style={getTopicstyle(color)}>
                                                                                        {topic.catgeoryIconLabel}
                                                                                    </div>
                                                                                    <Tooltip disableFocusListener disableTouchListener title={topic.stName}>
                                                                                        <p className={classes.topicTitle}
                                                                                            data-test='open-item'
                                                                                            onClick={event => itemOnOpen(event, topic, group.groupCode)}>
                                                                                            {topic.stName}
                                                                                        </p>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={`classes.momentListCell ${filters.indexOf('categoryName') > -1 ? null : classes.hiddenCol} `} align="left">
                                                                                <p className={classes.categoryCell}>{topic.categoryName}</p>
                                                                            </TableCell>
                                                                            <TableCell className={`classes.momentListCell ${filters.indexOf('createdAt') > -1 ? null : classes.hiddenCol} `} align="left">
                                                                                <p className={classes.momentCell}>{formattedDate(topic.createdAt)}</p>
                                                                            </TableCell>
                                                                            <TableCell className={`classes.momentListCell ${filters.indexOf('modifiedAt') > -1 ? null : classes.hiddenCol} `} align="left">
                                                                                <p className={classes.momentCell}>{formattedDate(topic.modifiedAt)}</p>
                                                                            </TableCell>
                                                                            <TableCell className={`classes.momentListCell ${filters.indexOf('topicsCount') > -1 ? null : classes.hiddenCol} `} align="right">
                                                                                {topic.sharedTopicItemsCount}
                                                                            </TableCell>
                                                                            <TableCell className={`classes.momentListCell ${filters.indexOf('contactsCount') > -1 ? null : classes.hiddenCol} `} align="right">
                                                                                {topic.sharedTopicUsersCount}
                                                                            </TableCell>
                                                                            <TableCell className={`classes.momentListCell`} align="right">
                                                                            </TableCell>
                                                                        </TableRow>);

                                                                    })
                                                                }
                                                            </React.Fragment>)

                                                    }
                                                })
                                            }
                                        </React.Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        }
                    </TableContainer>
                </Paper>
            }
            {props.topicsFetching &&
                <Paper data-test='commonUserTopicListDataFetching' className={classes.progressbar}>
                    <LinearProgress className={classes.progressbar} variant="query" />
                </Paper>
            }
        </>
    )
}

export default CommonUserTopicList;