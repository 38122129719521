import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as MyFilesActionTypes from '../../../redux/constants/personalVault/myFilesConstants';

type FileUploaderProps = {
    updateCommonModal: any,
    UploadFile: any,
    FileNotSupported: any;
    onFileUploadAction: any;
}

type FileUploaderStates = {
    files: any;
    isDragEnter: any;
}

const extRegexp = /^(?!\.[^.]+\.$).+\.(?:bat|cmd|exe|jar|js|sh)$/;
const maxFileSize = Infinity;

class FileUploader extends Component<FileUploaderProps, FileUploaderStates> {
    state = {
        files: [],
        isDragEnter: false
    }

    uploader = createRef<HTMLInputElement>();

    uploaderOnClick = () => {
        if (this.uploader.current != null)
            this.uploader.current.click();
    }

    onFileDrop = (e: React.DragEvent<unknown>) => {
        e.preventDefault();
        let files = e.dataTransfer ? e.dataTransfer.files : null
        this.uploadfilesUI(files);
    };

    fileTypeAcceptable(file: any) {
        let fileExtension = this.fileExtension(file)
        fileExtension = "file." + fileExtension
        return null != fileExtension.match(extRegexp) ? false : true;
    }

    fileExtension(file: any) {
        let extensionSplit = file.name.split('.')
        if (extensionSplit.length > 1) {
            return extensionSplit[extensionSplit.length - 1]
        } else {
            return 'none'
        }
    }

    fileSizeAcceptable(file: any) {
        return file.size <= maxFileSize ? true : false;
    }

    uploadOnChange = (e: any) => {
        e.preventDefault();
        let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
        this.uploadfilesUI(files);
    }

    uploadfilesUI = (files: any) => {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let isFileTypeAccepted = this.fileTypeAcceptable(file);
            let isFileSizeAccepted = this.fileSizeAcceptable(file);

            if (isFileTypeAccepted && isFileSizeAccepted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        files: [...prevState.files, file]
                    }
                })

                this.props.UploadFile('', file, Math.floor(Math.random() * 1000))
            }
            else {
                if (!isFileTypeAccepted) {
                    this.props.FileNotSupported(file, i, "Sorry, this filetype is not permitted for security reasons.")
                }
                else if (!isFileSizeAccepted)
                    this.props.FileNotSupported(file, i, "This file can't be uploaded because it exceeds the file size limit.")
            }
        }
        if (files.length > 0)
            this.props.onFileUploadAction()
    }

    onDragOver(event: any) {
        event.preventDefault()
        event.stopPropagation()
    }

    handleCancelClick = () => {
        this.props.updateCommonModal(false, null);
    }

    render() {
        return (
            <div className='add-folder-wrapper'>
                <div className='add-folder-title'>Add New File</div>
                <div>
                    <div className='folder-input-wrapper'>
                        <div className={this.state.isDragEnter ? 'uploader-on-hover' : 'uploader'}
                            onClick={() => this.uploaderOnClick()}
                            onDragEnter={(event) => this.onDragOver(event)}
                            onDragLeave={() => this.setState({ isDragEnter: false })}
                            onDragOver={(event) => this.onDragOver(event)}
                            onDrop={(e) => this.onFileDrop(e)}>
                            <form>
                                <input type="file"
                                    name="files[]"
                                    ref={this.uploader}
                                    id="files"
                                    onChange={(e) => this.uploadOnChange(e)}
                                    multiple
                                    style={{ display: 'none' }} />
                                <label>
                                    <span>Drag a file here or <a className="uploader-link">browse</a> for a file to upload</span>
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='add-folder-action-bar'>
                    <Button className='add-folder-btn-save' onClick={this.handleCancelClick} variant="contained" color="primary">Done</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCommonModal: (openState: boolean, content: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_COMMON_MODAL,
            openState: openState,
            content: content
        }),
        UploadFile: (topicCode: any, file: any, fileIndex: any) => dispatch({
            type: MyFilesActionTypes.MY_FILES_UPLOAD_FILES_REQUEST,
            topicCode: topicCode,
            file: file,
            fileId: fileIndex
        }),
        FileNotSupported: (file: any, fileIndex: any, errMsg: any) => dispatch({
            // type: TopicFileActionTypes.TOPIC_FILES_UPLOAD_ADD_FILE,
            // file: file,
            // fileId: fileIndex,
            // errMsg: errMsg
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);