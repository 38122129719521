export const USER_EVENT_CATEGORY = 'User';
export const ASSOCIATE_EVENT_CATEGORY = 'Associate';

export const GROUP_SELECTION_ACTION = 'Group Selection';
export const TOPIC_SELECTION_ACTION = 'Topic Selection';
export const FILE_SELECTION_ACTION = 'FILE Selection';
export const FILE_UPLOADED = 'File Upload';
export const FILE_DOWNLOADED = 'File Downloaded';
export const FILE_VIEWED = 'File Viewed';
export const FILE_DELETED = "File Deleted";

export const ADD_TOPIC = "Add a Topic";
export const CONTACT_SELECTED="Contact Selected";
export const ADD_TOPIC_FOLLOWERS_ERROR = "Added a Topic, Error Adding Followers";
export const ADD_TOPIC_METADATA_ERROR = "Added a Topic, Error Adding Metadata";

export const FILE_PREVIEW_AV_SCAN = 'File preview during AV scan';
export const FILE_PREVIEW_ERROR = 'File preview error';
export const FILE_PREVIEW = 'File preview';
export const FILE_PREVIEW_SUCCESS = 'File preview success';
export const FILE_UPLOAD_ERROR = 'File upload error';