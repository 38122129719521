import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogContent, Paper, DialogActions, Button, Link, Radio, RadioGroup, FormControlLabel, Chip, CircularProgress, TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import * as CeremonyTypes from '../../../../../redux/constants/shared/eSignConstants';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import pendingIcon from '../../../../../assets/pending.svg';
import { formattedDateTime } from '../../../../core/utils/functions';

type ESignInitiationprops = {
    selectedItem: any,
    topic: any,
    isEndUser: any,
    CeremonyTypes: any,
    children?: any,
    topicContacts: any,
    intiateEsignCermony: any,
    topicContactsFetching: any,
    classes?: any,
    onCeremonyModalClose: any,
    getEsignFormTypes: any,
    esignFormTypes: any,
    esignFormTypesRequest: any,
    ceremonyDetails: any
}

export const styles = (theme: any) => ({
    title: {
        color: '#000000',
        fontSize: '1.6rem',
        fontWeight: 'bold' as 'bold',
        letterSpacing: '0',
        lineHeight: '32.5px'
    },
    listitem: {
        fontSize: '0.8rem',
        width: '20%',
        color: 'rgba(0,0,0,0.87)',
        letterSpacing: '0.15px'
    },
    main: {
        fontFamily: 'Fidelity Sans',
    },
    content: {
        padding: '16px 0px'
    },
    commonModalActions: {
        justifyContent: 'center',
        padding: '0px'
    },
    itemlist: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        fontSize: '0.875rem',
        fontWeight: 'bold' as 'bold',
        marginTop: '8px',
        alignItems: 'stretch'
    },
    filelist: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    typeIcon: {
        marginRight: '1rem'
    },
    authlabel: {
        color: '#000000',
        fontSize: '1.05rem',
        fontWeight: 'bold' as 'bold',
        letterSpacing: '0',
        lineHeight: '32.5px'
    },
    options: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        padding: '0px'
    },
    ceremonymethod: {
        fontSize: '0.875rem',
        fontWeight: 'bold' as 'bold',
        marginRight: '16px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'flex-start',
        paddingTop: '1.5rem',
        paddingLeft: '2rem'
    },
    option: {
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    label: {
        marginTop: '20px'
    },
    primarybtn: {
        textTransform: 'none' as 'none'
    },
    topicLinkCancel: {
        fontSize: '1.1rem'
    },
    fileTitle: {
        margin: '0px 0px 0px 32px',
        width: '85%',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '0.9rem'
    },
    btnContainer:
    {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center'
    },
    documentlist: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        padding: '0px 8px 0px 0px'
    },
    subtitleProgress: {
        fontSize: '0.875rem',
        width: '20%',
        letterSpacing: '0.15px',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-end',
        fontWeight: 'bold' as 'bold'
    },
    documentTitle: {
        margin: '0px',
        minWidth: '50%',
        color: '#000000',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        fontWeight: 'normal' as 'normal'
    },
    recipientsubtitle:
    {
        fontFamily: theme.homefontfamilyregular,
        color: '#666666',
        fontSize: '12px',
        paddingLeft: '16px !important',
        marginTop: '4px'
    },
    optionsubtitle:
    {
        fontFamily: theme.homefontfamilyregular,
        color: '#666666',
        fontSize: '12px',
        paddingLeft: '0px !important',
        marginTop: '4px'
    },
    contactList: {
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-start',
        flex: '1 1 0'
    },
    autocompleteroot: {
        minWidth: '90%',
        paddingLeft: '1rem'
    },
    autocompleteContacts: {
        minWidth: '70%',
        paddingLeft: '1rem',
        marginTop: '1rem'
    },
    autocomplete: {
        marginTop: '0px !important',
    },
    text: {
    },
    textfield: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between' as 'space-between',
    },
    pendingIcon: {
        width: '1em',
        height: 'auto',
        verticalAlign: 'bottom',
        marginLeft: '0.5rem'
    },
    emailtext:
    {
        fontSize: '0.9rem',
        fontFamily: 'Fidelity Sans Italic'
    },
})

class ESignInitiation extends Component<ESignInitiationprops> {

    state = {
        selectedCeremonytype: {},
        selectedValues: [],
        selectedForm: { formCode: null, roles: [], formTypeCode: null },
        esignUserRole: [], //mapping of type userCode: roleCode,
        esignSelectedValues: [],
        esignDisabled: false,
        showRecipients: true,
        formsList: []
    }

    componentDidMount() {
        this.setState({ selectedCeremonytype: this.props.CeremonyTypes.ceremonies[0].ceremonyTypeCode }, () => {
            if (this.state.selectedCeremonytype === 'esign') {
                this.props.getEsignFormTypes(this.props.topic.categoryCode, "ESIGN");
                this.setState({ esignSelectedValues: this.state.selectedValues, esignDisabled: true, showRecipients: false });
            }
        })
    }

    onCancelClick = () => {
        this.props.onCeremonyModalClose(false)
    }

    getStyledRadio(title: string, subTitle: string) {
        const { classes } = this.props;
        return <div style={{ marginTop: '1rem' }}>
            <div className={classes.documentlist}> {title} </div>
            <div className={classes.optionsubtitle} style={{ paddingLeft: '0px !important' }}>{subTitle}
            </div>
        </div>
    }

    intiateEsignCermonyProcess = () => {
        let ceremonyType = this.state.selectedCeremonytype
        if (ceremonyType === 'esign') {
            let ceremonies: any = [];
            for (let el in this.state.esignUserRole) {
                ceremonies.push({
                    "itemCode": this.props.selectedItem.itemCode,
                    "userCode": el,
                    "roleCode": this.state.esignUserRole[el]
                })
            }
            this.props.intiateEsignCermony(this.props.topic.stCode, ceremonyType, ceremonies, this.state.selectedForm.formTypeCode);
        }
        // else (this.state.selectedCeremonytype != null) {
        else {
            let ceremonies = this.state.selectedValues.map((contact: any) => {
                return {
                    "itemCode": this.props.selectedItem.itemCode,
                    "userCode": contact.userCode
                }
            })
            this.props.intiateEsignCermony(this.props.topic.stCode, ceremonyType, ceremonies, null)
        }
    }

    onChangeEisgnType = (event: any) => {
        this.setState({ showRecipients: false, selectedCeremonytype: event.target.value }, () => {
            let formsAvailable = this.props.esignFormTypesRequest || this.props.esignFormTypes.length;
            if (this.state.selectedCeremonytype === 'esign') {
                this.setState({ esignSelectedValues: this.state.selectedValues, esignDisabled: true })
                if(!formsAvailable){
                    this.props.getEsignFormTypes(this.props.topic.categoryCode, "ESIGN");
                }
            } else {
                this.setState({ showRecipients: true, esignSelectedValues: [], selectedForm: null, esignUserRole: [], esignDisabled: false })
            }
        })        
    }

    handleContactChange = (options: any) => {
        this.setState({ selectedValues: options, selectedForm: null, formsList: [] }, () => {
            // set the forms list for esign based on number of recipients selected
            let filteredForms = this.props.esignFormTypes.filter(this.checkMinSigners);
            this.setState({ formsList: filteredForms });
        });
    }

    checkMinSigners = (arr: any) => {
        return this.state.selectedValues.length > 0 && arr.roles.length >= this.state.selectedValues.length;
    } 

    handleFormChange = (event: any, option: any) => {
        this.setState({ selectedForm: null }, () => {
            this.setState({ selectedForm: option, esignSelectedValues: this.state.selectedValues, esignUserRole: [], esignDisabled: true });
        })
    }

    handleEsignUserChange = (event: any, user: any, esignRoleCode: any) => {
        let userid_: any = null;
        let newUserRole: any = this.state.esignUserRole;
        let newarr: any = this.state.esignSelectedValues;
        let len = 0;
        for (let userRole in this.state.esignUserRole) {
            if (this.state.esignUserRole[userRole] === esignRoleCode) {
                userid_ = userRole;
            }
            ++len;
        }
        if (userid_ !== null) {
            delete newUserRole[userid_];
            --len;
            let user_: any;
            for (user_ in this.state.selectedValues) {
                if (this.state.selectedValues[user_]['userCode'] === userid_) {
                    newarr.push(this.state.selectedValues[user_]);
                }
            }
        }
        if (user !== null) {
            newUserRole[user.userCode] = esignRoleCode;
            ++len;
            newarr = newarr.filter((user_: any) => user_.userCode !== user.userCode);
        }
        this.setState({ esignSelectedValues: newarr, esignUserRole: newUserRole, esignDisabled: (len !== this.state.selectedValues.length) });
    }

    render(): JSX.Element {
        const { classes } = this.props;
        return (
            <div className={classes.main} data-test="eSignInitiation">

                <div className={classes.title}>
                    Create electronic consent request
                </div>
                <DialogContent className={classes.content}>
                    <Paper elevation={0}>
                        <div className={classes.documentlist}>
                            <div className={classes.subtitleProgress}>
                                Document
                            </div>
                            <p className={classes.documentTitle}>
                                {this.props.selectedItem.itemName}
                            </p>
                        </div>
                        <div className={classes.documentlist} style={{ marginBottom: '1rem' }}>
                            <div className={classes.subtitleProgress}>
                            </div>
                            <p className={classes.recipientsubtitle}>
                                {`Uploaded `}
                                {formattedDateTime(this.props.selectedItem.createdAt)}
                            </p>
                        </div>
                        <div className={classes.documentlist}>
                            <div className={classes.subtitleProgress}>
                                Recipient(s)
                            </div>
                            <div className={classes.contactList} style={{ marginBottom: '1rem' }}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    multiple
                                    limitTags={2}
                                    ChipProps={{ 'size': 'small' }}
                                    classes={{
                                        root: classes.autocompleteroot,
                                    }}
                                    filterSelectedOptions
                                    options={this.props.topicContacts}
                                    getOptionLabel={option => option.lastName + ', ' + option.firstName}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <div className={classes.text}>
                                                <div className={classes.textfield}>
                                                    {option.lastName != null ? `${option.lastName}, ` : ''}{option.firstName != null ? `${option.firstName}` : ''}
                                                    {option.status === 'PENDING' ? <img className={classes.pendingIcon} src={pendingIcon} data-test='pendingIcon' /> : null}
                                                </div>
                                                {option.email != null && <div className={classes.emailtext}>
                                                    {option.email}
                                                </div>}
                                            </div>
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => <TextField {...params}
                                        className={classes.autocomplete} label="Select a Contact" variant="outlined" />}
                                    onChange={(event: any, option: any) => this.handleContactChange(option)}
                                />
                            </div>
                        </div>
                        
                        <div>
                            <Typography className={classes.authlabel}>
                                Authentication method
                            </Typography>
                            {
                                <div className={classes.options}>
                                    <div className={classes.ceremonymethod}>
                                        Select method
                                    </div>
                                    <div className={classes.documentlist} style={{ marginBottom: '1rem' }}>
                                        {
                                            this.props.CeremonyTypes.ceremonies.length > 0 && <RadioGroup
                                                defaultValue={this.props.CeremonyTypes.ceremonies[0].ceremonyTypeCode}
                                                aria-label="gender"
                                                name="customized-radios"
                                                onChange={this.onChangeEisgnType}>
                                                {
                                                    this.props.CeremonyTypes.ceremonies.map((type: any) => {
                                                        return <FormControlLabel
                                                            className={classes.optionsubtitle} key={type.ceremonyTypeCode} value={type.ceremonyTypeCode} control={<Radio
                                                                color="primary" />} label={
                                                                    this.getStyledRadio(type.displayName, type.description)
                                                                } />
                                                    })
                                                }
                                            </RadioGroup>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {this.state.selectedCeremonytype === 'esign' && this.props.esignFormTypes != null && this.state.formsList.length > 0 && <div>
                            <Autocomplete
                                data-test="form-types-list"
                                id="form-types-list"
                                multiple={false}
                                ChipProps={{ 'size': 'small' }}
                                classes={{
                                    root: classes.autocompleteroot,
                                }}
                                filterSelectedOptions
                                options={this.state.formsList}
                                getOptionLabel={option => option.formTypeName}
                                renderInput={(params) => <TextField {...params}
                                className={classes.autocomplete} label="Select a Form Type" variant="outlined" />}
                                onChange={(event: any, option: any) => this.handleFormChange(event, option)}
                            />
                            {this.state.selectedForm != null && this.state.selectedForm.roles != null && this.state.selectedForm.roles.length > 0 && this.state.selectedValues.map((object: any, index: number) => {
                                return <Autocomplete
                                    data-test={`users-list-${index}`}
                                    id={`users-list-${index}`}
                                    multiple={false}
                                    ChipProps={{ 'size': 'small' }}
                                    classes={{
                                        root: classes.autocompleteContacts,
                                    }}
                                    filterSelectedOptions
                                    options={this.state.esignSelectedValues}
                                    getOptionLabel={option => option.lastName + ', ' + option.firstName}
                                    getOptionSelected={(option, value) => option.userCode == value.userCode}
                                    renderInput={(params) => <TextField {...params}
                                        className={classes.autocomplete} label={"Signer " + (index + 1)} variant="outlined" />}
                                    onChange={(event: any, option: any) => this.handleEsignUserChange(event, option, "SIGNER"+(index+1))}
                                />
                            })}
                        </div>
                        }
                    </Paper>
                </DialogContent>
                <DialogActions className={classes.commonModalActions}>
                    <Link id="cancelInvite" data-test="cancelBtn" className={classes.topicLinkCancel} href="#" onClick={this.onCancelClick.bind(this)}>
                        CANCEL
                    </Link>
                    <div className={classes.btnContainer}>
                        <Button
                            id="sendrequest"
                            className={classes.primarybtn}
                            data-test="eSignBtn"
                            variant="contained"
                            onClick={() => this.intiateEsignCermonyProcess()}
                            color="primary"
                            disabled={this.props.topicContactsFetching || this.state.selectedCeremonytype == null
                                || this.state.selectedValues.length == 0 || this.state.esignDisabled || this.props.ceremonyDetails.creating}
                            disableElevation>
                            Send Request
                        </Button>
                    </div>
                </DialogActions>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        files: state.topicFilesReducer.filesUpload.files,
        CeremonyTypes: state.eSignReducer.ceremonyTypes,
        topicContacts: state.topicReducer.topicContacts.contactsData.content,
        topicContactsFetching: state.topicReducer.topicContacts.fetching,
        esignFormTypes: state.eSignReducer.esignFormTypes.content,
        esignFormTypesRequest: state.eSignReducer.esignFormTypes.requesting,
        ceremonyDetails: state.eSignReducer.ceremonyDetails,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

        intiateEsignCermony: (topicCode: any, CeremonyType: any, Ceremonies: any, formCode: any) => dispatch({
            type: CeremonyTypes.CEREMONY_API_CREATION,
            topicCode: topicCode,
            ceremonyType: CeremonyType,
            ceremonies: Ceremonies,
            formCode: formCode
        }),
        getEsignFormTypes: (categoryCode: any, formType: any) => dispatch({
            type: CeremonyTypes.GET_ESIGN_FORM_TYPES,
            categoryCode: categoryCode,
            formType: formType
        })
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ESignInitiation));