import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as ContactListActions from '../../../redux/constants/customerVault/userConstants';
import ContactList from './components/contactList/ContactList';
import AddTopic from '../topics/components/addTopic/AddTopic';
import { CommonModal } from '../../../shared/ui';

interface MenuItem {
    id: string,
    label: string,
    link: string,
    active: boolean
}

type SortItem = {
    direction: number,
    property: string
}

type ContactsPageProps = {
    updateDrawerMenu: any,
    RemoveBreadcrumbItems: any,
    contacts: any,
    fetchContacts: any
}

type ContactsPageStates = {
    isModalOpen: boolean,
    modalName: string,
    selectedContact: any
}

class ContactsPage extends Component<ContactsPageProps, ContactsPageStates> {

    ContactListChildren = {}

    state = {
        isModalOpen: false,
        modalName: '',
        selectedContact: '',
        reFetchUser: false,
        topicAdded: false,
    }

    componentDidMount() {
        this.props.updateDrawerMenu('contacts');
        this.props.RemoveBreadcrumbItems(1);
    }

    procConatctSort = (sort: SortItem) => {
        if(sort.property === 'last_name' || sort.property === 'first_name' || sort.property === 'middle_name') {
            return 'NAME'
        } else if (sort.property === 'created') {
            return 'CREATED_AT'
        }
    }

    openAddTopic = (contact: any) => {
        this.setState({ selectedContact: contact });
        this.setState({ isModalOpen: true, modalName: 'addtopic' });
    }

    onActionComplete = () => {
        this.setState({ isModalOpen: false, modalName: '' });
        this.props.fetchContacts('', this.procConatctSort(this.props.contacts.sort[0]));
    }

    handleError = () => {
        this.setState({ isModalOpen: false, modalName: '' });
    }

    onModalClose = () => {
        this.setState({ isModalOpen: false, modalName: '' })
    }

    getModalContent = (name: String) => {
        switch (name) {
            case 'addtopic':
                return <AddTopic
                    data-test='addtopic'
                    topicContacts={this.state.selectedContact}
                    onActionComplete={this.onActionComplete}
                    handleError={this.handleError}
                    categoryExists={false} />
            default:
                return null
        }
    }


    render() {

        let ContactListViewProps = {
            addTopic: this.openAddTopic
        }

        return (
            <div data-test='contactPageWrapper'>
                <ContactList data-test='contactlist' {...ContactListViewProps} />
                <CommonModal data-test='commonmodal' modalOpen={this.state.isModalOpen}
                    modalClose={this.onModalClose}
                    content={this.getModalContent(this.state.modalName)} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        contacts: state.userReducer.groupContacts.contactsData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDrawerMenu: (selectedMenu: string) => dispatch({
            type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
            selectedMenu: selectedMenu
        }),
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
        fetchContacts: (pattern: any, pagination: any) => dispatch({ 
            type: ContactListActions.USER_API_GET_USERS_REQUEST, 
            pattern, pagination 
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
