import React, { Component } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { convertPhoneNumber, formattedDateTime } from '../../core/utils/functions';
import './commonSwipeableDrawer.scss';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      // width: 400,
    },
    fullList: {
      width: 'auto',
      top: '64px'
    },
    label: {
      marginTop: '5px',
      '& span': {
        fontSize: '0.875em'
      }
    },
    labelValue: {
      color: 'grey',
      // fontSize: 8,
      textAlign: 'right',
      marginRight: 10,
      lineHeight: '1.5em',
      overflow: "hidden",
      // height: "3em",
      marginLeft: "20px",
      '& span': {
        fontSize: '0.875em'
      }
    },
    swiperDrawer: {
      position: 'fixed',
      height: '100%',
      // width: '20%',
      background: 'white',
      zIndex: 999,
      top: 64,
      right: 0,
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
    },
    swipeableListItem: {
      '&:hover': {
        backgroundColor: 'white',
        color: 'black'
      }
    }
  }),
);

const CommonSwipeableDrawer = (props: any) => {
  const classes = useStyles();
  var name: string | null = null
  if (props.content.firstName) {
    name = props.content.firstName
    if (props.content.lastName) {
      name += " " + props.content.lastName
    }
  }

  const list = (anchor: any) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={props.drawerClose}
    >
      <div className='panel-title-wrapper'>
        <h3 className='panel-title'>My Profile</h3>
        <IconButton aria-label="close-btn" className='close-btn' onClick={ props.modalClose }>
            <CloseIcon fontSize="large"/>
        </IconButton>
      </div>
      <List>
        {
          name && <ListItem className={classes.swipeableListItem} key={'name'} onClick={props.onClickedCallback}>
            <ListItemText className={classes.label} primary="Name" />
            <ListItemText className={classes.labelValue} primary={name} />
          </ListItem>
        }
        {
          props.content.email &&
          <ListItem className={classes.swipeableListItem} key={'email'} onClick={props.onClickedCallback}>
            <ListItemText className={classes.label} primary="Email" />
            <ListItemText className={classes.labelValue} primary={props.content.email} />
          </ListItem>
        }

        {
          props.content.phoneNumber && props.content.phoneNumber !== null && props.content.phoneNumber !== '' && <ListItem className={classes.swipeableListItem} key={'mobile'} onClick={props.onClickedCallback}>
            <ListItemText className={classes.label} primary="Phone Number" />
            <ListItemText className={classes.labelValue} primary={convertPhoneNumber(props.content.phoneNumber || '')} />
          </ListItem>
        }
        {props.content.createDateTime &&
          <ListItem className={classes.swipeableListItem} key={'userSince'} onClick={props.onClickedCallback}>
            <ListItemText className={classes.label} primary="User Since" />
            <ListItemText className={classes.labelValue} primary={formattedDateTime(props.content.createDateTime)} />
          </ListItem>
        }
        {
          props.content.lastLogin &&
          <ListItem className={classes.swipeableListItem} key={'lastLogin'} onClick={props.onClickedCallback}>
            <ListItemText className={classes.label} primary="Last Login" />
            <ListItemText className={classes.labelValue} primary={formattedDateTime(props.content.lastLogin)} />
          </ListItem>
        }

      </List>
      <Divider />
    </div>
  );

  return (
    <>  {
      props.swipeDrawer && <div data-test="commonSiwpeable" className={classes.swiperDrawer}>
        {list(props.anchor)}
      </div>
    }
    </>
  )
}

export default CommonSwipeableDrawer;