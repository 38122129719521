import { takeLatest, call, put, all, select, takeEvery } from 'redux-saga/effects';
import * as ContactActionTypes from '../../constants/personalVault/contactsConstants';
import { 
    getActiveContactsAPI, 
    removeContactAPI,
    getGroupsAPI,
    removeGroupAPI,
    addContactAPI,
    addGroupAPI} from '../../../shared/core/utils/apiFunctions';

export function* contactsWatcher() {
    yield takeEvery(ContactActionTypes.CONTACT_API_GET_CONTACTS_REQUEST, getContactsSaga);
    yield takeLatest(ContactActionTypes.CONTACT_API_ADD_CONTACT_REQUEST, addContactSaga);
    yield takeLatest(ContactActionTypes.CONTACT_API_DELETE_CONTACTS_REQUEST, deleteContactSaga);
    yield takeLatest(ContactActionTypes.GROUP_API_GET_GROUPS_REQUEST, getGroupsSaga);
    yield takeLatest(ContactActionTypes.GROUP_API_ADD_GROUPS_REQUEST, addGroupSaga);
    yield takeLatest(ContactActionTypes.GROUP_API_DELETE_GROUPS_REQUEST, deleteGroupSaga);
}

function* getContactsSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        })

        if (userAccessInfo !== undefined) {
            const userCode = userAccessInfo.user_profile.userCode;
            let contacts = yield call(
                getActiveContactsAPI,
                userCode,
                action.pagination.direction,
                action.pagination.orderBy,
                action.pagination.page,
                action.pagination.size
            )
            yield put({
                type: ContactActionTypes.CONTACT_API_GET_CONTACTS_SUCCESS,
                contacts: contacts
            });
        }

    } catch (err) {
        yield put({
            type: ContactActionTypes.CONTACT_API_GET_CONTACTS_FAILURE,
            err
        })
    }
}

function* deleteContactSaga(action) {
    try {
        yield call(
            removeContactAPI,
            action.userCode
        )
        yield put({
            type: ContactActionTypes.CONTACT_API_DELETE_CONTACTS_SUCCESS,
            deletedContacts: action.userCode
        })
    } catch (err) {
        yield put({
            type: ContactActionTypes.CONTACT_API_DELETE_CONTACTS_FAILURE,
            err
        })
    }
}

function* getGroupsSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        })

        if (userAccessInfo !== undefined) {
            const userCode = userAccessInfo.user_profile.userCode;
            const groups = yield call(
                getGroupsAPI,
                userCode,
                action.pagination.direction,
                action.pagination.orderBy,
                action.pagination.page,
                action.pagination.size
            )
            yield put({
                type: ContactActionTypes.GROUP_API_GET_GROUPS_SUCCESS,
                groups: groups
            });
        }

    } catch (err) {
        yield put({
            type: ContactActionTypes.GROUP_API_GET_GROUPS_FAILURE,
            err
        })
    }
}

function* deleteGroupSaga(action) {
    try {
        yield call(
            removeGroupAPI,
            action.groupCode
        )
        yield put({
            type: ContactActionTypes.GROUP_API_DELETE_GROUPS_SUCCESS,
            deletedGroups: action.groupCode
        })
    } catch (err) {
        yield put({
            type: ContactActionTypes.GROUP_API_DELETE_GROUPS_FAILURE,
            err
        })
    }
}

// function* getInvitesSaga(action) {
//     try {
//         const getUser = (state) => state.login;
//         const { userAccessInfo } = yield all({
//             userAccessInfo: select(getUser)
//         })

//         if (userAccessInfo !== undefined) {
//             const userCode = userAccessInfo.user_profile.userCode;
//             let contacts = yield call(
//                 getPendingContactsAPI,
//                 userCode,
//                 action.pagination.direction,
//                 action.pagination.orderBy,
//                 action.pagination.page,
//                 action.pagination.size
//             )

//             yield put({
//                 type: ContactActionTypes.CONTACT_API_GET_CONTACTS_SUCCESS,
//                 contacts: contacts
//             });
//         }

//     } catch (err) {
//         yield put({
//             type: ContactActionTypes.CONTACT_API_GET_CONTACTS_FAILURE,
//             err
//         })
//     }
// }

function * addContactSaga(action) {
    try {
        const contact = yield call(
            addContactAPI,
            action.contact
        )
        yield put({
            type: ContactActionTypes.CONTACT_API_ADD_CONTACT_SUCCESS,
            addContact: contact
        })
    } catch (err) {
        yield put({
            type: ContactActionTypes.CONTACT_API_ADD_CONTACT_FAILURE,
            error: err
        })
    }
}

function * addGroupSaga(action)
{
    try {
        const contactGroup = yield call(
            addGroupAPI,
            action.groupName,
            action.contactCodes,
            action.contactGroupId
        )
        yield put({
            type: ContactActionTypes.GROUP_API_ADD_GROUPS_SUCCESS,
            addContactGroup: contactGroup
        })
    } catch (err) {
        yield put({
            type: ContactActionTypes.GROUP_API_ADD_GROUPS_FAILURE,
            error: err,
        })
    } 
}

export default contactsWatcher;