import { takeLatest, call, put, all, select } from "redux-saga/effects";
import * as TopicFollowActionTypes from "../../constants/customerVault/topicFollowConstants";
import * as appActionTypes from "../../constants/shared/appStatesConstants";
import {
  getTopicFollowAPI,
  addTopicFollowAPI,
  removeTopicFollowAPI,
} from "../../../shared/core/utils/apiFunctions";

export function* topicFollowWatcher() {
  yield takeLatest(
    TopicFollowActionTypes.FOLLOW_API_GET_USERS_REQUEST,
    topicfollowUsersSaga
  );
  yield takeLatest(
    TopicFollowActionTypes.FOLLOW_API_ADD_USERS_REQUEST,
    addTopicfollowUsersSaga
  );
  yield takeLatest(
    TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_REQUEST,
    removeTopicfollowUsersSaga
  );
}

function* topicfollowUsersSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(getTopicFollowAPI, grpCode, action.topicCode);
      yield put({
        type: TopicFollowActionTypes.FOLLOW_API_GET_USERS_SUCCESS,
        followers: response.data.followers,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFollowActionTypes.FOLLOW_API_GET_USERS_FAILURE,
          error,
        });
    } else
    {
      yield put({
        type: TopicFollowActionTypes.FOLLOW_API_GET_USERS_FAILURE,
        error,
      });
    }
  }
}

function* addTopicfollowUsersSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        addTopicFollowAPI,
        grpCode,
        action.topicCode,
        action.userCodes
      );
      yield put({
        type: TopicFollowActionTypes.FOLLOW_API_ADD_USERS_SUCCESS,
        followers: response.data.followers,
      });
    }
  } catch (error) {
    console.log(error);
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFollowActionTypes.FOLLOW_API_ADD_USERS_FAILURE,
          error,
        });
    } else {
      yield put({
        type: TopicFollowActionTypes.FOLLOW_API_ADD_USERS_FAILURE,
        error,
      });
    }
  }
}

function* removeTopicfollowUsersSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const response = yield call(
        removeTopicFollowAPI,
        grpCode,
        action.topicCode,
        action.userCodes
      );
      yield put({
        type: TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_SUCCESS,
        followers: response.data.followers,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_FAILURE,
          error,
        });
    } else
    {
      yield put({
        type: TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_FAILURE,
        error,
      });
    }
  }
}

export default topicFollowWatcher;
