import React from 'react';
import { 
    Searchbar, 
    ItemList, 
    NotificationList
} from '../ui';
import { createBrowserHistory } from 'history';

// Personalvault Components
import { default as PvContactsView } from '../../pages/personalVault/contacts/components/contactListView/ContactsView';
import { default as PvInvitationPanel } from '../../components/personalVault/invitationPanel/InvitationPanel';
import { default as PvShareListView } from '../../pages/personalVault/shared/components/ShareListView';
import { default as PvRecentsListView } from '../../pages/personalVault/recents/components/RecentsListView';
import { default as PvMyFilesListView } from '../../pages/personalVault/myFiles/components/myFilesListView/MyFilesListView';

// CustomerVault Components
import { default as CvContactsList } from '../../pages/customerVault/contacts/components/contactList/ContactList';
import { default as CvTopicListView } from '../../pages/customerVault/topics/components/topicListView/TopicListView';

const history = createBrowserHistory();

const componentLookUp = (name: string) => {
    switch (name) {
        //Shared Components
        case 'searchbar':
            return (<Searchbar />);
        //PersonalVault Components
        case 'myFilesListView':
            return <PvMyFilesListView />;
        case 'fileListView':
            return <ItemList />;
        case 'notificationListView':
            return <NotificationList />;
        case 'sharedListView':
            return <PvShareListView history={history}/>
        case 'recentsListView':
            return <PvRecentsListView />
        case 'contactListView':
            return <PvContactsView />
        case 'invitationPanel':
            return <PvInvitationPanel />
        
        // CustomerVault Components
        case 'cvContactsList':
            return <CvContactsList />
        case 'CvTopicListView':
            return <CvTopicListView />

        default:
            return null;
    }
}

export default componentLookUp;

