import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { GET_MULTIPLE_FILES_DOWNLOAD_REQUEST, GET_FILE_DOWNLOAD_API_REQUEST } from '../../../redux/constants/personalVault/itemsConstants'

type DownloadFileProps = {
  downloadFile: any;
  downloadMultiFiles: any;
  classes: any;
  selectedItems: any[];
  children?: any;
}

export const styles = (theme: any) => ({
  successPaper: {
    position: 'absolute' as 'absolute',
    minWidth: '320px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  successDownloadPaper: {
    position: 'absolute' as 'absolute',
    minWidth: '320px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
  },
  successDownloadMessage: {
    fontFamily: 'Fidelity Sans',
    fontSize: '18px',
    color: '#000000',
    justifyContent: 'center',
    textAlign: 'center' as 'center',
    marginBottom: '40px'
  },
  iconHeading: {
    justifyContent: 'space-between',
    fontSize: '17px',
    width: '100%',
  },
  iconSection: {
    textAlign: 'center' as 'center',
    color: 'green'
  },
  sucessIcon: {
    fontSize: '40px'
  },
});

class DownloadFile extends Component<any>{
  state = {
    downloadDialogOpen: false
  }

  componentDidMount() {
    const { selectedItems, downloadFile, downloadMultiFiles } = this.props;
    if (selectedItems && selectedItems.length === 1){ 
      downloadFile(selectedItems[0].itemCode, selectedItems[0].parentCode, selectedItems[0].itemName) 
    } else {
      let tmp_itemCodes: string[] = [];
      selectedItems.forEach((item: any) => {
        tmp_itemCodes.push(item.itemCode);
      })
      downloadMultiFiles(tmp_itemCodes, selectedItems[0].parentCode, selectedItems[0].itemName)
    } 
  }
  handleDownloadClickDialogOpen = () => {
    this.setState({
      downloadDialogOpen: true
    });
  };
  handleDownloadDialogClose = () => {
    this.setState({ downloadDialogOpen: false })
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.successDownloadPaper}>
        <div className={classes.iconHeading}>
          <p className={classes.iconSection}> <GetAppIcon className={classes.sucessIcon} /></p>
        </div>
        <div className={classes.successDownloadMessage}>
          File Downloading </div>
      </div>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    downloadingProgress: state.itemsReducer.downloadInfo.fetching,
    downloadingFile: state.itemsReducer.downloadInfo.response,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFile: (itemCode: any, parentCode: any, fileName: any) => dispatch({
      type: GET_FILE_DOWNLOAD_API_REQUEST,
      itemCode: itemCode,
      parentCode: parentCode,
      fileName: fileName
    }),
    downloadMultiFiles: (itemCodes: string[], parentCode: string, fileName: any) => dispatch({
      type: GET_MULTIPLE_FILES_DOWNLOAD_REQUEST,
      itemCodes: itemCodes,
      parentCode: parentCode,
      fileName: 'Fidsafe Documents'
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DownloadFile));
