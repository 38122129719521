import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import * as ContactDetailActionTypes from '../../constants/personalVault/contactDetailConstants';
import {
    getItemsSharedByMeAPI,
    getItemsSharedWithMeAPI
} from '../../../shared/core/utils/apiFunctions';

export function* contactDetailWatcher() {
    yield takeLatest(ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST, getItemsSharedByMeSaga);
    yield takeLatest(ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_REQUEST, getItemsSharedWithMeSaga);
}

function* getItemsSharedByMeSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        });

        if(userAccessInfo !== undefined) {
            // const userCode = userAccessInfo.user_profile.userCode;
            const files = yield call(
                getItemsSharedByMeAPI,
                action.userCode,
                action.pagination.direction,
                action.pagination.orderBy,
                action.pagination.page,
                action.pagination.size
            )
            yield put({
                type: ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_SUCCESS,
                files: files.data.content
            });
        }
    } catch(err) {
        yield put({
            type: ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_FAILURE,
            err
        })
    }
}

function* getItemsSharedWithMeSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        });

        if (userAccessInfo !== undefined) {
            // const userCode = userAccessInfo.user_profile.userCode;
            const files = yield call(
                getItemsSharedWithMeAPI,
                action.userCode,
                action.pagination.direction,
                action.itemType,
                action.pagination.orderBy,
                action.pagination.page,
                action.pagination.size
            )
            yield put({
                type: ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_SUCCESS,
                files: files.data.content
            });
        }
    } catch(err) {
        yield put({
            type: ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_FAILURE,
            err
        })
    }
}

export default contactDetailWatcher;