import React from 'react';

const UploadFile = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st99{stroke:${props.stroke}}`}
            {`.st10{stroke:${props.stroke}}`}
        </style>
        <defs>
            <path d="M12,14.999 L12,6.75 C12,3.02207794 9.3137085,0 6,0 C2.6862915,0 0,3.02207794 0,6.75 L0,18 C0,20.4852814 1.790861,22.5 4,22.5 C6.209139,22.5 8,20.4852814 8,18 L8,6.75 C8,5.50735931 7.1045695,4.5 6,4.5 C4.8954305,4.5 4,5.50735931 4,6.75 L4,16.5" id="filespath-1"></path>
        </defs>
        <g id="files-6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icon-/-attach" transform="translate(-4.000000, 0.000000)">
                <g id="Shape-2" transform="translate(5.250000, 0.750000)">
                    <mask id="mask-2" fill="white">
                        <use href="#filespath-1"></use>
                    </mask>
                    <use id="Shape" className="st99" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" href="#filespath-1"></use>
                </g>
            </g>
        </g >
    </svg >
}
export default UploadFile