import { combineReducers } from "redux";
import * as ceremonyConstants from "../../constants/shared/eSignConstants";
import * as ErrorTypes from "../../../shared/errors/ErrorMessages";

const initState = {
  ceremonyTypes: {
    fetching: false,
    ceremonies: [],
    error: null,
  },
  ceremony: {
    creating: false,
    requesting: false,
    cancelling: false,
    error: null,
    content: []
  },
  cermonyToken:
  {
    requesting: false,
    error: null,
    content: null,
    esignEmbadedUrl: null,
  },
  formTypes:
  {
    requesting: false,
    error: null,
    content: []
  },
  ceremonyDisclosure:
  {
    requesting: false,
    error: null,
    content: []
  }
};

function ceremonyTypes(state = initState.ceremonyTypes, action) {
  switch (action.type) {
    case ceremonyConstants.CEREMONY_API_GET_TYPES:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_GET_TYPES_SUCCESS:
      return {
        ...state,
        fetching: false,
        ceremonies: action.ceremonies,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_GET_TYPES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    default:
      return state;
  }
}

function ceremonyDetails(state = initState.ceremony, action) {
  switch (action.type) {
    case ceremonyConstants.CEREMONY_API_CREATION:
      return {
        ...state,
        creating: true,
        content: [],
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_CREATION_SUCCESS:
      return {
        ...state,
        creating: false,
        content: action.response,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_CREATION_FAILURE:
      return {
        ...state,
        creating: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    case ceremonyConstants.CEREMONY_API_GET_DETAILS:
      return {
        ...state,
        requesting: true,
        content: [],
        error: null
      };
    case ceremonyConstants.CEREMONY_API_GET_DETAILS_SUCCESS:
      return {
        ...state,
        requesting: false,
        content: action.response,
        error: null
      };
    case ceremonyConstants.CEREMONY_API_GET_DETAILS_FAILURE:
      return {
        ...state,
        requesting: false,
        content: [],
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
      };

    case ceremonyConstants.CEREMONY_API_CANCEL_INITIATION:
      return {
        ...state,
        cancelling: true,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_CANCEL_INITIATION_SUCCESS:
      return {
        ...state,
        cancelling: false,
        content: action.response.ceremonies,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_CANCEL_INITIATION_FAILURE:
      return {
        ...state,
        cancelling: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    case ceremonyConstants.CEREMONY_API_CLEAR_DETAILS:
      return {
        ...state,
        requesting: false,
        error: null,
      };
    default:
      return state;
  }
}

function ceremonyChange(state = initState.ceremony, action) {
  switch (action.type) {
    case ceremonyConstants.CEREMONY_API_STATUS_CHANGE:
      return {
        ...state,
        requesting: true,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        requesting: false,
        content: action.response,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    default:
      return state;
  }
}

function ceremonyToken(state = initState.cermonyToken, action) {
  switch (action.type) {
    case ceremonyConstants.CEREMONY_API_INITIATION:
      return {
        ...state,
        requesting: true,
        error: null,
        content: null,
        esignEmbadedUrl: null,
      };
    case ceremonyConstants.CEREMONY_API_INITIATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        content: action.response.ceremony_claims,
        esignEmbadedUrl: action.response.esignEmbadedUrl,
        error: null,
      };
    case ceremonyConstants.CEREMONY_API_INITIATION_FAILURE:
      return {
        ...state,
        requesting: false,
        content: null,
        esignEmbadedUrl: null,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
     case ceremonyConstants.CEREMONY_API_INITIATION_CLEAR_TOKEN:
      return {
        ...state,
        requesting: false,
        error: null,
        content: null,
        esignEmbadedUrl: null,
      };
    default:
      return state;
  }
}

function esignFormTypes(state = initState.formTypes, action) {
  switch (action.type) {
    case ceremonyConstants.GET_ESIGN_FORM_TYPES:
      return {
        ...state,
        requesting: true,
        error: null,
        content: []
      };
    case ceremonyConstants.GET_ESIGN_FORM_TYPES_SUCCESS:
      return {
        ...state,
        requesting: false,
        content: action.response,
        error: null
      };
    case ceremonyConstants.GET_ESIGN_FORM_TYPES_FAILURE:
      return {
        ...state,
        content: [],
        requesting: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    default:
      return state;
  }
}

function esignCeremonyDisclosure(state = initState.ceremonyDisclosure, action) {
  switch (action.type) {
    case ceremonyConstants.GET_CEREMONY_DISCLOSURES:
      return {
        ...state,
        requesting: true,
        error: null,
        content: []
      };
    case ceremonyConstants.GET_CEREMONY_DISCLOSURES_SUCCESS:
      return {
        ...state,
        requesting: false,
        content: action.disclosures,
        error: null
      };
    case ceremonyConstants.GET_CEREMONY_DISCLOSURES_FAILURE:
      return {
        ...state,
        content: [],
        requesting: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    default:
      return state;
  }
}

export default combineReducers({
  ceremonyTypes,
  ceremonyDetails,
  ceremonyChange,
  ceremonyToken,
  esignFormTypes,
  esignCeremonyDisclosure
});
