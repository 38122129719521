import axios from 'axios';
import store from  '../../../index-store';

// export const apiHandle = (apiOptions) => {
//  let response;
//  switch (apiOptions.method) {
//      case 'post': {
//          try {
//              response = axios.post(apiOptions.endpoint, apiOptions.body, apiOptions.config);
//          } catch (ex) {
//              if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
//              throw ex;
//          }
//          break;
//      }
//      default:
//          break;
//  }
//  return response;
// };
export const HttpMethod = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS',
    HEAD: 'HEAD',
    PATCH: 'PATCH'
};

export class HttpRequest
{
    constructor(method,endpoint,queryParams,body,config) {
        this.method = method;
        this.endpoint = endpoint;
        this.args = [];
        if (queryParams && Array.isArray(queryParams)) {
            this.args = queryParams; 
        } else if (queryParams) {
            this.args.push(queryParams);
        }
        this.url=this.endpoint+((this.args.length>0)?this.args.reduce((acc,curr)=>{return acc=((acc)?'&':'?')+curr;}):'');
        this.body = body;
        this.config = config;
        this.addHeaders();
    }
    
    randomString(length) {
        var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
        let result = ''

        while (length > 0) {
            var bytes = new Uint8Array(16);
            var random = window.crypto.getRandomValues(bytes);

            random.forEach(function(c) {
                if (length === 0) {
                    return;
                }
                if (c < charset.length) {
                    result += charset[c];
                    length--;
                }
            });
        }
        return result;
    }
    
    addHeaders() {
        if (!this.config) this.config = {};
        if (!this.config['headers']) this.config['headers']={};
        if (!this.config['headers']['Access-Control-Allow-Origin']) this.config['headers']['Access-Control-Allow-Origin']='*';
        if (!this.config['headers']['Content-Type']) this.config['headers']['Content-Type']='application/json';
        if (!this.config['withCredentials']) this.config['withCredentials']=false;
        if ((!this.config['headers']['X-Request-ID']) &&
            store && store.getState().login && store.getState().login.user_sid)
            this.config['headers']['X-Request-ID'] = this.randomString(4) + "-" + store.getState().login.user_sid
    }

    addCSRFHeader() {
        let csurf = document.cookie.split(';').filter((item) => item.trim().startsWith('_csrf=')) 
        if (csurf && csurf.length) this.config['headers']['X-CSRF-Token'] = csurf[0].split('=')[1]
    }

    post() {
        try {
            this.addCSRFHeader(); 
            return axios.post(this.url, this.body?this.body:{}, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
            throw ex;
        }       
    }

    get() {
        try {
            if (this.body)
                this.config['params']=this.body;
            return axios.get(this.url, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) {
                console.error("Data error present")
                throw ex.response.data.Errors;
            }
            console.error("Data error not present")
            throw ex;
        }       
    }

    head() {
        try {
            return axios.head(this.url, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
            throw ex;
        }       
    }
    
    options() {
        try {
            return axios.options(this.url, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
            throw ex;
        }       
    }


    delete() {
        try {
            this.addCSRFHeader(); 
            if (this.body)
                this.config['params']=this.body;            
            return axios.delete(this.url, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
            throw ex;
        }       
    }
    
    put() {
        try {
            this.addCSRFHeader(); 
            return axios.put(this.url, this.body, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
            throw ex;
        }       
    }
    
    patch() {
        try {
            this.addCSRFHeader(); 
            return axios.patch(this.url, this.body, this.config)
                    .catch(function (error) {
                        if (error.response) {
                            let errInfo = {error_status: error.response.status};
                            (error.response.data.Errors)
                                ?console.error(error.response.data.Errors,errInfo)
                                :console.error(error.response.data,errInfo);
                        }
                        throw error;
                    });
        } catch (ex) {
            if (ex && ex.response && ex.response.data && ex.response.data) throw ex.response.data.Errors;
            throw ex;
        }       
    }
    
    invoke() {
        switch(this.method) {
        case 'GET':
            return this.get();
        case 'HEAD':
            return this.head();
        case 'OPTIONS':
            return this.options();
        case 'PUT':
            return this.put();
        case 'PATCH':
            return this.patch();
        case 'DELETE':
            return this.delete();
        default:
            return this.post();
        }
    }
        
}

