const styles = theme => ({

    alertRoot: {
        width: 480,
        fontSize: 16,
        // fontFamily: 'Roboto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
            margin: '1rem',
        },
    },
    alertMain: {
        padding: '2.5rem 24px 2rem 24px',
        [theme.breakpoints.down('xs')]: {
            padding: '40px 24px 2rem 24px',
        },
    },
    title: {
        padding: 0,
        fontSize: 25,
        // fontFamily: 'Lato',
        fontWeight: 'lighter',
        color: 'rgba(0,0,0,0.87)',
        lineHeight: 1.3,
    },
    content: {
        padding: 0,
        marginTop: 24,
    },
    contentText: {
        padding: 0,
        margin: 0,
        color: '#444657',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: 1.8,
    },
    actionRoot:{
        padding: 0,
        paddingTop: '2rem',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '1rem',
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
    },
    button: {
        paddingLeft: 28,
        paddingRight: 28,
        margin: '0 4px',
        fontWeight: 'normal',
        letterSpacing: 1.25,
        lineHeight: 1.6,
        textTransform: 'none',
        fontSize: 14,
        boxShadow: '0px 4px 3px -4px black',
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            marginTop: '1rem',
        },
    },
})

export default styles; 