import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import TopicListView from './components/topicListView/TopicListView';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import './Topics.page.scss';
import AddTopic from './components/addTopic/AddTopic';
import { CommonModal } from '../../../shared/ui';
import { Authorization } from "../../../shared/core/AuthorizationComponent";
import TopicListUserView from './components/topicListUserView/TopicListUserView';
import Disclosure from '../../../components/customerVault/disclosure/Disclosure';

type TopicsPageProps = {
    updateDrawerMenu: any,
    loggedIn: any,
    RemoveBreadcrumbItems: any,
    deleteFileDisclaimerContent: any
}

type TopicsPageStates = {
    isModalOpen: boolean,
    modalName: string,
    updateTopics: boolean,
    isEndUser: boolean | null
}

class TopicsPage extends Component<TopicsPageProps, TopicsPageStates> {

    constructor(props: TopicsPageProps) {
        super(props);
        this.props.updateDrawerMenu('topics')
    }

    state = {
        isModalOpen: false,
        modalName: '',
        updateTopics: false,
        isEndUser: null
    }

    componentDidMount() {
        this.props.updateDrawerMenu('topics')
        this.props.RemoveBreadcrumbItems(1);
        if (this.props.loggedIn.user_profile.roles.indexOf('USER') > -1) //Todo : this may fail if the user has multiple roles
            this.setState({ isEndUser: true });
        else
            this.setState({ isEndUser: false });
    }

    openAddTopic = () => {
        this.setState({ isModalOpen: true, modalName: 'addtopic' })
    }

    onActionComplete = () => {
        //Fetch all the topics again
        this.setState({ isModalOpen: false, modalName: '' })
        this.setState({ updateTopics: true })
    }

    handleError = () => {
        this.setState({ isModalOpen: false, modalName: '' })
    }

    onModalClose = () => {
        this.setState({ isModalOpen: false, modalName: '' })
    }

    getModalContent = (name: String) => {
        switch (name) {
            case 'addtopic':
                return <AddTopic
                    onActionComplete={this.onActionComplete}
                    handleError={this.handleError}
                    categoryExists={true} />
            default:
                return null
        }
    }

    onUpdateComplete = () => {
        this.setState({ updateTopics: false })
    }


    render() {
        let topicListViewProps = {
            updateTopics: this.state.updateTopics,
            onUpdateComplete: this.onUpdateComplete
        }

        return (
            <div>
                <div className="breadcrumb-wrapper" data-test="topics">
                    <Authorization authorization="AddTopic">
                        <div>
                            <Button className="add-topic-btn"
                                variant="contained"
                                data-test="addTopicBtn"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={this.openAddTopic}>
                                Add Topic
                            </Button>
                        </div>
                    </Authorization>
                </div>
                {this.state.isEndUser != null ? (this.state.isEndUser ? <TopicListUserView {...topicListViewProps} /> :
                    <TopicListView {...topicListViewProps} />) : null
                }
                {(this.state.isEndUser != null && this.state.isEndUser) ? <Disclosure data-test='disclosureWrapper' disclosure={this.props.deleteFileDisclaimerContent.disclosure}/> : null}
                <CommonModal modalOpen={this.state.isModalOpen}
                    modalClose={this.onModalClose}
                    content={this.getModalContent(this.state.modalName)} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.login,
        deleteFileDisclaimerContent: state.legalsReducer.deleteFileDisclaimer.content
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDrawerMenu: (selectedMenu: string) => dispatch({
            type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
            selectedMenu: selectedMenu
        }),
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicsPage);