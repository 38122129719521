import { takeLatest, put, } from 'redux-saga/effects';
import * as UserDetailTypes from '../../constants/shared/UserDetailsConstants';
import { HttpMethod, HttpRequest } from '../../../shared/core/utils/requestHandler';
import { con } from '../../../shared/config/config'

function* getUserDetails(action) {
    try {
        const endpoint = `${con.adminUrl}/public/products/${con.PRODUCT_CODE}/users/signup/${action.signupCode}`;
        const response = yield (new HttpRequest(HttpMethod.GET, endpoint, null, null).invoke());
        if (response.status === 200) {
            yield put({
                type: UserDetailTypes.USER_DETAILS_SUCCESS,
                response: response
            });
        }
    } catch (error) {
        yield put({ type: UserDetailTypes.USER_DETAILS_FAILURE, error });
    }
}

export default function* userDetailsWatcher() {
    yield takeLatest(UserDetailTypes.USER_DETAILS_REQUEST, getUserDetails);
}

// export default userDetailsWatcher;
export { getUserDetails };
