import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation.svg';
import { ReactComponent as ErrorIcon } from '../../assets/alert-red-filled.svg';

type TextInputProps = {
  id: string;
  validateRegex: string;
  handleInPutChange: any;
  classes: any,
  assistiveText: string,
  defaultValue?: string
  isFocus?: boolean,
  label?: string,
  length?: number,
  size?: string
  isRequired?: boolean,
  fieldType?: string
  forceValidate?: boolean,
  validate?: any,
  readOnlyValid?: boolean,
  disabled?: boolean,
  onSubmit?: any
}

export const styles = (theme: { spacing: (arg0: number) => any; }) => ({
  textfield: {
    width: '100%',
    height: 60,
    margin: theme.spacing(2),
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  }
});

class TextInput extends Component<TextInputProps> {

  componentDidMount() {
    if (this.props.defaultValue)
      this.setState({ value: this.props.defaultValue })
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.forceValidate != this.props.forceValidate && this.props.forceValidate
      && this.state.value === '') {
      this.setState({ isvalid: false })
    }

    if (prevProps.defaultValue != this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue })
    }
  }

  state = { value: '', isvalid: true, assistiveText: '', readOnlyValid: this.props.readOnlyValid }

  handleInputChange = (event: any) => {
    this.setState({ value: event.target.value })
    this.props.handleInPutChange(event.target.id, event.target.value);
  }

  validate = (event: any) => {
    if (!this.state.value.match(this.props.validateRegex)) {
      this.setState({ isvalid: false })
      if (this.props.validate)
        this.props.validate(event.target.id, false)
    }
    else {
      this.setState({ isvalid: true });
      if (this.props.validate)
        this.props.validate(event.target.id, true)
    }
  }

  render() {
    const { classes, id, label, assistiveText, isFocus, length, size } = this.props;
    return (
      <TextField data-test= 'textInput'
        error={!this.state.isvalid}
        autoFocus={isFocus != null ? isFocus : false}
        type={this.props.fieldType || "text"}
        className={classes.textfield}
        label={label != null ? label : id}
        id={id}
        size={(size != null && size === 'small') ? 'small' : 'medium'}
        value={this.state.value}
        variant="outlined"
        color="primary"
        autoComplete="off"
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            if (this.props.onSubmit != undefined) {
              this.props.onSubmit()
            }
          }
        }}
        helperText={!this.state.isvalid ? assistiveText : null}
        onChange={this.handleInputChange.bind(this)}
        disabled={this.state.readOnlyValid}
        onBlur={this.validate.bind(this)}
        inputProps={{
          maxLength: length != null ? length : 50
        }}
        InputProps={{
          readOnly: this.state.readOnlyValid,
          endAdornment: <InputAdornment
            position="end"
            style={{ marginRight: '12px' }}>
            {
              this.state.value ?
                [(!this.state.isvalid ? <ErrorIcon key={id} /> : <ConfirmationIcon key={id} />)]
                : <div></div>
            }
          </InputAdornment>
        }}
      />
    )
  }
}


export default withStyles(styles)(TextInput);