import React, { Component } from 'react';
import { DialogContent, DialogActions, Button, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

type ESignConfirmprops = {
    onClose: any,
    children?: any,
    classes?: any
    title?: string,
    subTitle?: string,
    buttonTitle?: string
}

export const styles = (theme: any) => ({
    main: {
        fontFamily: theme.homefontfamilyregular,
        lineHeight: '24px'
    },
    content: {
        padding: '16px 0px',
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    subtitle: {
        fontSize: '1.0rem',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        letterSpacing: '0.15px',
        paddingBottom: '16px'
    },
    title: {
        color: '#000000',
        fontSize: '1.65rem',
        fontWeight: 'bold' as 'bold',
        letterSpacing: '0',
        lineHeight: '32.5px'
    },
    commonModalActions: {
        justifyContent: 'center',
        padding: '0px',
        height: '40px'
    },
    primarybtn: {
        textTransform: 'none' as 'none',
        width: '170px'
    },
    documentLinkCancel: {
        fontSize: '1rem',
        paddingLeft: '8px',
        position: 'absolute' as 'absolute',
        left: '2rem'
    },
    btnContainer:
    {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center'
    },
    
});


class ESignConfirm extends Component<ESignConfirmprops> {
    render(): JSX.Element {
        const { classes } = this.props;
        return (
            <div className={classes.main} data-test="ESignConfirm">
                <div className={classes.title} data-test="confirmTitle">
                    {this.props.title ? this.props.title : 'Are you sure you want to close?'}
                </div>
                <DialogContent
                    classes={{ root: classes.content }}>
                    <div className={classes.subtitle} data-test="confirmSubTitle">
                    {this.props.subTitle ? this.props.subTitle : 'Closing this window will stop the request. You’ll be required to verify yourself again before completing the process.'}
                    </div>
                </DialogContent>
                <DialogActions className={classes.commonModalActions}  >
                    <Link id="cancelInvite" data-test="cancelBtn" className={classes.documentLinkCancel} href="javascript:void(0)"
                        onClick={() => this.props.onClose(false)}>
                        GO BACK
                    </Link>
                    <Button
                        id="sendrequest"
                        className={classes.primarybtn}
                        data-test="proceedBtn"
                        variant="contained"
                        onClick={() => this.props.onClose(true)}
                        color="primary"
                        disableElevation>
                        {this.props.buttonTitle ? this.props.buttonTitle : 'CLOSE'}
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

export default (withStyles(styles)(ESignConfirm));