import React, { Component } from 'react';
import { List, ListItem, ListItemText, CircularProgress, Chip, Fab, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { cvpaginationAllContacts } from '../../../shared/core/utils/paginationDefaults';
import * as ContactListActions from '../../../redux/constants/customerVault/userConstants';
import debounce from 'lodash/debounce';
import SearchIcon from '@material-ui/icons/Search';
import './ContactsSearchBar.scss';

type SearchedContactType = {
  userCode: string,
  firstName: string,
  lastName: string,
  userStatus: string,
  email: string
}

type ContactsSearchBarProps = {
  handleChange: any,
  contacts: any[],
  topicContacts: any[],
  fetchContacts: any,
  contactPageInfo: any,
  searchContact: any,
  searchedContact: any,
  clearSearchedContact: any
}

type ContactsSearchBarStates = {
    selectedValues: SearchedContactType[],
    searchContext: string,
    placeHolder: string,
    onFocus: boolean,
    searchedContact: SearchedContactType[],
    searchBarWrapperOnFocus: boolean,
}

class ContactsSearchBar extends Component<ContactsSearchBarProps, ContactsSearchBarStates> {

  state = { 
      selectedValues: [
        ...this.props.topicContacts
      ], 
      searchContext: '',
      placeHolder: 'Add Contacts',
      onFocus: false,
      searchedContact: [],
      searchBarWrapperOnFocus: false
    }
    
  textInputRef: any;

  constructor(props: any) {
    super(props);
    this.textInputRef = React.createRef();
  }

  componentDidMount() {
    const { contacts, topicContacts, contactPageInfo, fetchContacts } = this.props;
    if (contactPageInfo.totalElements != contacts.length) {
      fetchContacts('', cvpaginationAllContacts)
    }
    this.searchContactAPI = debounce(this.searchContactAPI, 500);
    this.setState({ selectedValues: topicContacts },
      () => {
        this.props.handleChange(this.state.selectedValues.map((a: any) => a.userCode))
    });
  }

  componentDidUpdate(prevProps: ContactsSearchBarProps, prevState: any, snapshot: any) {
    if(prevProps.searchedContact.contactsData !== this.props.searchedContact.contactsData) {
        let filteredContacts: SearchedContactType[] = [];
        let currentSelectedContactArr: string[] = [];
        this.state.selectedValues.forEach(contact => currentSelectedContactArr.push(contact.userCode));

        this.props.searchedContact.contactsData.forEach((contact: SearchedContactType) => {
            if (this.state.selectedValues.length > 0) {
                if(!currentSelectedContactArr.includes(contact.userCode)) {
                    filteredContacts.push(contact)
                }
            } else {
                filteredContacts.push(contact)
            }
        })
        this.setState({
            searchedContact: filteredContacts
        })
    }
  }

  handleInputChange = (newvalue: any) => {
    this.setState({ selectedValues: newvalue },
      () => {
        this.props.handleChange(this.state.selectedValues.map((a: any) => a.userCode))
      });
  }

  searchContact = (event: any) => {
    if(event.target.value !== '') {
      this.setState({ searchContext: event.target.value}, () => this.searchContactAPI())
    } else {
      this.setState({ searchContext: '' });
      this.props.clearSearchedContact();
    }
  }

  searchContactAPI = () => {
    this.props.searchContact(this.state.searchContext);
  }

  handleDelete = (contact: any) => {
      this.setState({
          selectedValues: this.state.selectedValues.filter(val => val.userCode !== contact.userCode)
      }, () => {
        this.handleInputChange(this.state.selectedValues)
      })
  }

  inputOnFocus = () => {
      this.setState({ onFocus: true, placeHolder: '' })
  }

  inputOnBlur = () => {
    this.setState({ onFocus: false, placeHolder: '' })
  }

  wrapperOnBlur = () => {
    this.setState({ searchContext: ''});
    setTimeout(() => this.props.clearSearchedContact(), 1000)
  }

  optionOnSelect = (contact: SearchedContactType) => {
    this.setState({ 
        selectedValues: [...this.state.selectedValues, contact],
        searchContext: '',
        searchBarWrapperOnFocus: false
    }, () => {
        this.handleInputChange(this.state.selectedValues)
        this.textInputRef.current.focus()
    })
    this.props.clearSearchedContact();
  }

  clearSelectedContacts = () => {
      this.setState({ selectedValues: [] }, () => this.handleInputChange(this.state.selectedValues))
  }

  searchBarWrapperOnFocus = () => {
    this.setState({ searchBarWrapperOnFocus: true })
  }
  
  searchBarWrapperOnBlur = () => {
    setTimeout(() => {
      this.setState({ searchBarWrapperOnFocus: false })
    }, 500)
  }

  render() {
    return (
        <>
            <div data-test='ContactsSearchBarComp'
                className={ this.state.onFocus ? `contacts-searchbar-wrapper-onFocus contacts-searchbar-wrapper` : `contacts-searchbar-wrapper`} 
                onFocus={ this.searchBarWrapperOnFocus }
                onBlur ={ this.searchBarWrapperOnBlur }
                >
                <div className= { this.state.selectedValues.length === 0 && !this.state.onFocus ? 'placeholder-label-normal' : 'placeholder-label-top'}>Add Contacts</div>
                <div data-test='results-wrapper-searchbar' className='searchbar-results-wrapper' onBlur={ this.wrapperOnBlur }>
                    <div className='result-content-wrapper'>
                        { this.state.selectedValues.map((contact: SearchedContactType) => (
                            <Chip className='result-chip'
                                key = { contact.userCode }
                                label={ contact.lastName + ', ' + contact.firstName } 
                                onDelete={() => this.handleDelete(contact) } 
                                color="secondary" 
                            />
                        ))}
                        <input onFocus={this.inputOnFocus} 
                            onBlur={this.inputOnBlur} 
                            className='searchbar-input' 
                            type="text"
                            data-test='contactInputField' 
                            placeholder={ this.state.selectedValues.length > 0 ? '' : 'Add Contact' }
                            onChange={ (event) => this.searchContact(event) }
                            value = { this.state.searchContext }
                            ref = {this.textInputRef}
                        />
                        { this.state.selectedValues.length > 0 && this.state.searchBarWrapperOnFocus && 
                            <div className='search-clear-btn' onClick={this.clearSelectedContacts}>
                                <Tooltip title="Clear All Contacts"><CloseIcon className='close-icon'/></Tooltip>
                            </div>
                        }
                    </div>
                    <div className='result-positioner'> 
                        { this.state.searchedContact.length > 0 && 
                            <div className='search-contact-panel'>
                                <List component='nav' aria-label='search-result-item'>
                                    { this.state.searchedContact.map(( (contact: SearchedContactType, index: number) => 
                                        <ListItem key={contact.userCode} 
                                            id={contact.userCode}
                                            button
                                            onClick={() => this.optionOnSelect(contact)}
                                            className='search-item'
                                        >
                                            <ListItemText primary={ contact.lastName + ', ' + contact.firstName } 
                                                secondary={ contact.email } />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        }
                        { this.props.searchedContact.contactsData.length === 0 &&
                            this.state.searchContext !== '' && this.props.searchedContact.fetching &&
                            <div className='search-contact-panel'>
                                <List component='nav' aria-label='search-result-item'>
                                    <ListItem className="loading-item-row">
                                        <CircularProgress size={20} />
                                    </ListItem>
                                </List>
                            </div>
                        }
                    </div>
                </div>
                <div className='searchbar-icon-wrapper'><SearchIcon></SearchIcon></div>
            </div>
        </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    contacts: state.userReducer.groupContacts.contactsData.content,
    contactPageInfo: state.userReducer.groupContacts.contactsData.page,
    searchedContact: state.userReducer.searchContacts
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchContacts: (pattern: any, pagination: any) => dispatch({ type: ContactListActions.USER_API_GET_USERS_REQUEST, pattern, pagination }),
    searchContact: (searchString: string) => dispatch({
      type: ContactListActions.USER_API_CONTACT_SEARCH_REQUEST,
      searchString: searchString
    }),
    clearSearchedContact: () => dispatch({ type: ContactListActions.USER_API_CLEAR_SEARCH_REQUEST })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsSearchBar);
