
import React from 'react';

const TopicsIcon = (props) => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <style>{`.topicsIcon{stroke:${props.stroke}}`}</style>
            <g id="Topics-/-Black" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Topics-/-Black2" className="topicsIcon" >
                    <g id="Group" transform="translate(1.000000, 1.000000)" strokeWidth="1.5" >
                        <g id="tags">
                            <path d="M0.0609999784,1.55999998 L0.0609999784,7.817 C0.0609999784,8.61244093 0.37671858,9.37535812 0.939,9.938 L12.5,21.5 C13.0857498,22.0855729 14.0352502,22.0855729 14.621,21.5 L21.5,14.62 C22.0855729,14.0342502 22.0855729,13.0847498 21.5,12.499 L9.939,0.938 C9.37635812,0.37571858 8.61344093,0.0599999784 7.818,0.0599999784 L1.56099998,0.0599999784 C0.732572875,0.0599999784 0.0609999784,0.731572875 0.0609999784,1.55999998 Z" id="Shape"></path>
                            <circle id="Oval" cx="5.311" cy="5.31" r="1.5"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default TopicsIcon