import React from 'react';
import {
    PvContactsPage,
    PvRecentsPage,
    PvNotificationsPage,
    PvSharedPage,
    PvContactDetail,
    PvGroupsDetail,
    PvMyFilesPage
} from '../../pages/personalVault/pages';

import {
    CvContactsPage,
    CvContactDetailPage,
    CvTopicsPage,
    CvTopicDetailPage
} from '../../pages/customerVault/pages';
import FsFileDetailPage from '../../pages/shared/fileDetail/Fs.fileDetail.page';

const pageLookUp = (pageName: string, components: string[]) => {

    switch (pageName) {
        //Personal Vault
        case 'pv-myfiles':
            return (<PvMyFilesPage components={components} />);
        case 'pv-notifications':
            return (<PvNotificationsPage components={components} />);
        case 'pv-recents':
            return (<PvRecentsPage components={components} />);
        case 'pv-shared':
            return (<PvSharedPage components={components} />);
        case 'pv-contacts':
            return (<PvContactsPage components={components} />);
        case 'pv-contacts-detail':
            return (<PvContactDetail components={components} />);
        case 'pv-contacts-groups-detail':
            return (<PvGroupsDetail components={components} />);
        //Customer Vault
        case 'cv-contacts':
            return (<CvContactsPage />);
        case 'cv-contact-detail':
            return (<CvContactDetailPage />);
        case 'cv-topics':
            return (<CvTopicsPage />);
        case 'cv-topic-detail':
            return (<CvTopicDetailPage />);
        // case 'file-detail':
        //     return (<FsFileDetailPage />);
        default:
            return null;
    }
}

export default pageLookUp;

