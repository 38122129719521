import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Fab, withStyles, IconButton, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { withRouter } from "react-router-dom";
import { RouteProps, RouteComponentProps } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SideDrawer from '../../../shared/ui/sideDrawer/SideDrawer';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as MyFilesActionTypes from '../../../redux/constants/personalVault/myFilesConstants';

import './PvDrawer.scss';
import InviteGroup from '../../../pages/personalVault/contacts/components/inviteGroup/InviteGroup';
import InviteContact from '../../../pages/personalVault/contacts/components/inviteContact/InviteContact';
import AddFolder from '../addFolder/AddFolder';
import FileUploader from '../fileUploader/FileUploader';


type PvDrawerProps = {
  history: any;
  classes: any;
  drawerMenus: any;
  currentFolder: string;
  updateBreadcrumbItem: any;
  RemoveBreadcrumbItems: any;
  initDrawer: any;
  updateDrawerMenu: any;
  openDrawer: boolean;
  closeDrawer: any;
  updateCommonModal: any;
  showSnackbar: any;
  changeContactTab: any;
  uploadFiles: any;
}

type PvDrawerPropsWithRouter = PvDrawerProps & RouteProps & RouteComponentProps<{}>

type PvDrawerStates = {}

export const styles = (theme: any) => ({
  actionBtn: {
    backgroundColor: theme.loginbuttonbgcolor,
    '&:hover': {
      backgroundColor: theme.loginbuttonbgcolor,
    },
  },
  actionIcon: {
    marginLeft: '12px',
    color: '#fff',
    backgroundColor: theme.loginbuttonbgcolor,
    '&:hover': {
      backgroundColor: theme.loginbuttonbgcolor,
    },
  }
})

class PvDrawer extends Component<PvDrawerPropsWithRouter, PvDrawerStates> {
  state = {
    openSubMenu: false,
    folderModalOpen: false,
    formContent: null,
    showDrawerForm: false,
    uploadModalOpen: false
  }

  uploader = createRef<HTMLInputElement>();

  handleModalClose = (openState: boolean, itemCode: string) => {
    console.log(itemCode)
  }

  addFolderContent = () => {
    return <AddFolder selectedItem isRename={false} handleModalClose={this.handleModalClose} />
  }

  menuSelect = (link: string) => {
    this.props.updateDrawerMenu(link);
    if (!this.state.openSubMenu) {
      this.props.RemoveBreadcrumbItems(1);
      const baseUrl = `/personal/${link}`
      this.props.history.push(baseUrl);
      if (window.innerWidth < 600) {
        this.props.closeDrawer(false);
      }
    } else {
      switch (link) {
        case 'uploadfiles':
          this.uploaderOnClick();
          break;
        case 'addfolder':
          this.setState({ folderModalOpen: true });
          this.props.updateCommonModal(true, this.addFolderContent())
          break;
        case 'invitecontact':
          this.setState({
            showDrawerForm: true,
            formContent: <InviteContact onActionComplete={this.onFileActionComplete} handleError={this.handleError} />
          })
          break;
        case 'creategroup':
          this.setState({
            showDrawerForm: true,
            formContent: <InviteGroup onActionComplete={this.onFileActionComplete} handleError={this.handleError} />
          })
          break;
        default:
          return null
      }
    }
  }

  onFileActionComplete = (name: string, isSuccess: boolean, value: any) => {
    // this.getMainMenu()
    this.setState({ showDrawerForm: false })
    switch (name) {
      case 'Add a Folder':
        // show the snack bar
        if (isSuccess)
          this.props.showSnackbar(`Folder ${value} created.`);
        break;
      // case 'Upload New Files':
      //     this.refreshHomePage(isSuccess);
      //     break;
      // case 'Create a Note':
      //     break;
      // case 'Save a Password':
      //     break;
      case 'Invite a Contact':
        if (isSuccess) {
          this.props.showSnackbar(value);
          this.setState({ openSubMenu: false });
          this.props.initDrawer('pv');
          this.props.history.replace('/personal/contacts');
          this.props.changeContactTab(0);
        }
        break;
      case 'Create a Group':
        if (isSuccess) {
          this.props.showSnackbar(value);
          this.setState({ openSubMenu: false });
          this.props.initDrawer('pv');
          this.props.history.replace('/personal/contacts');
          this.props.changeContactTab(1);
        }
        break;
      default:
        break;
    }
    // if (!this.props.islarge)
    //     this.props.toggleLeftDrawer(false);
  }

  handleCreateModalClose = () => {
    this.setState({ folderModalOpen: false })
  }


  handleError = (error: any) => {
    this.setState({ error: error })
  }

  openSubMenu = (open: boolean) => {
    this.setState({
      showDrawerForm: false,
      formContent: null,
      openSubMenu: open
    })
    this.state.openSubMenu ? this.props.initDrawer('pv') : this.props.initDrawer('pv-sub');
  }

  uploaderOnClick = () => {
    if (this.uploader.current != null) {
      this.uploader.current.click();
    }
  }

  onFileUploadAction = () => {}

  uploaderContent = () => {
    // return <AddFolder selectedItem isRename={false} handleModalClose={this.handleModalClose} />
    return <FileUploader onFileUploadAction={ this.onFileUploadAction }/>
  }

  uploadOnChange = (e: any) => {
    e.preventDefault();
    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.props.uploadFiles(files);
    this.props.updateCommonModal(true, this.uploaderContent())
  }

  onFileUploaded = () => {
    this.setState({ uploadModalOpen: false })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="pv-drawer-wrapper">
        {!this.state.openSubMenu && this.props.openDrawer &&
          <Fab color='primary' className={clsx(classes.actionBtn, 'add-btn')} variant='extended' onClick={() => this.openSubMenu(true)}>
            <AddIcon className='action-icon' />
            Add New
          </Fab>}
        {!this.state.openSubMenu && !this.props.openDrawer &&
          <IconButton aria-label="add" className={classes.actionIcon} onClick={() => this.openSubMenu(true)}>
            <AddIcon />
          </IconButton>
        }
        {this.state.openSubMenu && this.props.openDrawer &&
          <div color='primary' className={clsx(classes.actionBtn, 'back-btn')} onClick={() => this.openSubMenu(false)}>
            <ChevronLeftIcon className='action-icon' />
            Back
          </div>}
        {this.state.openSubMenu && !this.props.openDrawer &&
          <IconButton aria-label="back" className={classes.actionIcon} onClick={() => this.openSubMenu(false)} >
            <ChevronLeftIcon />
          </IconButton>
        }
        <div className='menu-wrapper'>
          <SideDrawer menuItems={this.props.drawerMenus} menuSelect={this.menuSelect} />
          {this.state.showDrawerForm &&
            <div className='drawer-form'>{this.state.formContent}</div>
          }
        </div>
        <Hidden>
          <form>
            <input type="file"
              name="files[]"
              ref={this.uploader}
              id="files"
              onChange={(e) => this.uploadOnChange(e)}
              multiple
              style={{ display: 'none' }} />
          </form>
        </Hidden>
        {/* <Upload modalOpen={this.state.uploadModalOpen} topic={[]} onActionComplete={this.onFileUploaded} /> */}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentFolder: '',
    drawerMenus: state.appUIStateReducer.drawerMenuItems
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    initDrawer: (vaultName: string) => dispatch({
      type: AppUIStateActionTypes.INIT_DRAWER,
      vaultName: vaultName
    }),
    updateDrawerMenu: (selectedMenu: string) => dispatch({
      type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
      selectedMenu: selectedMenu
    }), 
    updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
      type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
      itemName: itemName,
      itemLink: itemLink
    }),
    RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
      type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
      lastIndex: lastIndex
    }),
    updateCommonModal: (openState: boolean, content: any) => dispatch({
      type: AppUIStateActionTypes.UPDATE_COMMON_MODAL,
      openState: openState,
      content: content
    }),
    showSnackbar: (snackbarVal: any) => dispatch({
      type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
      snackbar: snackbarVal
    }),
    changeContactTab: (tabIndex: number) => dispatch({
      type: AppUIStateActionTypes.APP_STATE_UPDATE_CONTACTS_PAGE_TABS,
      tabIndex: tabIndex
    }),
    uploadFiles: (files: any) => dispatch({
      type: MyFilesActionTypes.MY_FILES_UPLOAD_FILES_REQUEST,
      files: files
    })
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PvDrawer)));