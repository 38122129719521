import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Breadcrumbstyle';
import BreadcrumbCollapsed from './Breadcrumbcollapsed'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class breadcrumb extends Component {
    isCollapsed = false;
    internalHeight = 0;

    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false
        };
    }

    componentDidUpdate(prevProps) {
        const { items } = this.props
        if (prevProps.items !== items)
            (items.length > 4) ? this.setState({ isCollapsed: true }) : this.setState({ isCollapsed: false })
    }

    renderItem(classes, current, index, onItemSelect, isLastElement=false) {
        const title = current.itemName.length >= this.props.textLength ? current.itemName.substring(0, this.props.textLength) + "..." : current.itemName
        if (!isLastElement)
            return <li className={`${classes.listyle} ${classes.listhover}`} key={`item-${index}`} title={title}>
                <a className={classes.astyle} data-test={`list-item-${index}`} onClick={event => onItemSelect(event, current)}>{title}</a>
            </li>

        else
            return <li className={classes.listyle} key={`item-${index}`} title={title}>
                <a className={`${classes.astyle} ${classes.selectedastyle}`} data-test='last-list-item' onClick={event => onItemSelect(event, current)}>{title}</a>
            </li>
    }

    renderSeperator(classes,index) {
        return <div key={`seperator${index}`} className={classes.seperator}><NavigateNextIcon fontSize="default"></NavigateNextIcon></div>
    }

    renderBreadcrumb(strippedItems) {
        return <BreadcrumbCollapsed key="ellipsis" items={strippedItems} onItemSelectInCollapse={this.onItemSelectInCollapse} />
    }

    insertSeparators = (classes, items, onItemSelect) => {
        if (!this.state.isCollapsed) {
            return items.reduce((acc, current, index) => {
                if (index < items.length - 1)
                    acc = acc.concat(this.renderItem(classes, current, index, onItemSelect), this.renderSeperator(classes,index));
                else
                    acc = acc.concat(this.renderItem(classes, current, index, onItemSelect,true));
                return acc;
            }, []);
        }
        else {
            let preCollapse = [], collapse = [], postcollapse = [], strippedItems = []
            items.map((current, index) => {
                if (index === 0)
                    preCollapse = preCollapse.concat(this.renderItem(classes, current, index, onItemSelect), this.renderSeperator(classes,index));
                else if (index ===items.length - 2)
                    postcollapse = postcollapse.concat(this.renderItem(classes, current, index, onItemSelect), this.renderSeperator(classes,index));
                else if (index === items.length - 1)
                    postcollapse = postcollapse.concat(this.renderItem(classes, current, index, onItemSelect,true));
                else
                    strippedItems.push(items[index]);
            });
            collapse = collapse.concat(this.renderBreadcrumb(strippedItems, onItemSelect), this.renderSeperator(classes,'last'));
            return [preCollapse, collapse, postcollapse];
        }
    }

    onItemSelectInCollapse = (event, item) => {
        this.setState({ isCollapsed: false })
        this.props.onItemSelect(event, item);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.breadcrumbWrapper} data-test='breadcrumbComp'>
                <ol className={classes.olstyle} >
                    {this.insertSeparators(this.props.classes, this.props.items, this.props.onItemSelect)}
                </ol>
            </div>
        );
    }
}

export default withStyles(styles)(breadcrumb);


