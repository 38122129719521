import {
  HOME_API_CALL_REQUEST, HOME_API_CALL_SUCCESS, HOME_API_CALL_FAILURE,
  HOME_API_CONSENT_REQUEST, HOME_API_CONSENT_SUCCESS, HOME_API_CONSENT_FAILURE,
  HOME_API_GET_PRODUCT_REQUEST, HOME_API_GET_PRODUCT_SUCCCESS, HOME_API_GET_PRODUCT_FAILURE,
  HOME_ERROR_CLEAR
} from '../../redux/constants/shared/homeConstants'
import { UNABLE_TO_FETCH_USER_DETAILS, UNABLE_TO_FETCH_PRODUCT_DETAILS, UNABLE_TO_UPDATE_CONSENT_FLAG } from '../../shared/errors/ErrorActions';
import { con } from '../../shared/config/config';

//any sort of feature flag can be added here 
//These are the default values 
//proxySignup only features right now 
const Features = {
  'proxySignup': false,
  'proxyLogin': false,
  'nonFidelityLogin': false,
  'noDisclaimerContent': false,
  'passwordLess': false,
  'loginLayout': [],
  'mfaType': 'smsOnly',
 // 'dobNotRequired': false,
  'isTopicSubmitRequired': [],
  'isPreviewDisable': false,
  'allowedFiles': [],
  'followTopics':false,
  'metadata': [],
  'thirdPartyLoginLayout':[],
  'uploadDisabledCategories': [],
  'hideOtherContactsForEndUsers': []
}

const getFeatureArray = (name) => {
  const feature_arr = con.APP_FEATURES.filter(feature => typeof (feature) === 'object' && feature[name])
  return (feature_arr.length>0)?feature_arr[0][name]:Features[name]
}
//Features will be toggled right off the bat using the product code 
const toggleFeatures = () => {
  if (con.APP_FEATURES) {
    Features['proxySignup'] = con.APP_FEATURES.includes('proxySignup');
    Features['proxyLogin'] = con.APP_FEATURES.includes('proxyLogin');
    Features['nonFidelityLogin'] = con.APP_FEATURES.includes('nonFidelityLogin');
    Features['noDisclaimerContent'] = con.APP_FEATURES.includes('noDisclaimer');
    Features['isPreviewDisable'] = con.APP_FEATURES.includes('isPreviewDisable');
    Features['followTopic'] = con.APP_FEATURES.includes('followTopic');
    Features['isTopicSubmitRequired'] = getFeatureArray('isTopicSubmitRequired');
    Features['loginLayout'] = getFeatureArray('loginLayout');
    Features['passwordLess'] = con.APP_FEATURES.includes('passwordLess');
    Features['mfaType'] = getFeatureArray('mfaType');
    Features['allowedFiles'] = getFeatureArray('allowedFiles');
    Features['thirdPartyLoginLayout'] = getFeatureArray('thirdPartyLoginLayout');
    Features['metadata'] = getFeatureArray('metadata');
    Features['uploadDisabledCategories'] = getFeatureArray('uploadDisabledCategories');
    Features['hideOtherContactsForEndUsers'] = getFeatureArray('hideOtherContactsForEndUsers');
  }
  return Features
};


// reducer with initial state
//Features can then be accessed from any component using mapStatetoProps, then used to conditionally render components
const initialState = {
  userAccessInfo: {
    fetching: null,
    response: {
      requesting: false,
      successful: false,
      messages: [],
      errors: [],
      authorizations: null,
      user: {
        name: '',
        email: '',
        sub: ''
      },
      exp: 0,
      user_profile: {
        userCode: '',
        email: '',
        firstName: '',
        lastName: '',
        countryPhoneCode: 0,
        lastLogin: '',
        createDateTime: '',
        roles: [],
        consentFlag: false,
        status: '',
        trmSvcVersion: '',
        trmSvcDate: ''
      },
      user_sid: '',
      discriminator: ''
    }
  },
  productInfo: {
    fetching: null,
    response: {
      data: {
        createdAt: '',
        modifiedAt: '',
        prdCode: '',
        prdName: '',
        trmSvcDate: ''
      }
    }
  },
  groupsInfo: {
    fetching: false,
    response: {
      data: {
        content: [
          {
            userCount: 0,
            createdAt: '',
            modifiedAt: '',
            grpCode: '',
            grpDisplayCode: '',
            grpName: '',
          }
        ],
        page: {},
        sort: []
      }
    }
  },
  userTrmSvcInfo: {
    response: null,
    fetching: false,
  },
  features: toggleFeatures()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HOME_API_GET_PRODUCT_REQUEST:
      return {
        ...state,
        userAccessInfo: {
          ...state.userAccessInfo,
          fetching: true
        },
        productInfo: {
          ...state.productInfo,
          fetching: true
        }
      };
    case HOME_API_GET_PRODUCT_SUCCCESS:
      return {
        ...state,
        userAccessInfo: { fetching: false, response: { ...action.userAccessInfo } },
        productInfo: { fetching: false, response: { ...action.productInfo } },
      };
    case HOME_API_GET_PRODUCT_FAILURE:
      return {
        ...state, userAccessInfo: { fetching: false, response: null },
        productInfo: { fetching: false, response: null, error: { ...action.error, action: UNABLE_TO_FETCH_PRODUCT_DETAILS } },
      };
    case HOME_API_CALL_REQUEST:
      return {
        ...state,
        userAccessInfo: {
          ...state.userAccessInfo,
          fetching: true
        },
        groupsInfo: {
          ...state.groupsInfo,
          fetching: true
        }
      };
    case HOME_API_CALL_SUCCESS:
      return {
        ...state, userAccessInfo: { fetching: false, response: { ...action.userAccessInfo } },
        groupsInfo: { fetching: false, response: { ...action.groupsInfo } }
      };
    case HOME_API_CALL_FAILURE:
      return {
        ...state, userAccessInfo: { fetching: false, response: null },
        groupsInfo: { fetching: false, response: null }, error: { ...action.error, action: UNABLE_TO_FETCH_USER_DETAILS }
      };

    case HOME_ERROR_CLEAR:
      return { ...state, error: null }

    case HOME_API_CONSENT_REQUEST:
      return { ...state, userTrmSvcInfo: { fetching: true, response: null } }

    case HOME_API_CONSENT_SUCCESS:
      return { ...state, userTrmSvcInfo: { fetching: false, response: { ...action.response } } }

    case HOME_API_CONSENT_FAILURE:
      return { ...state, userTrmSvcInfo: { fetching: false, response: null }, error: { ...action.error, action: UNABLE_TO_UPDATE_CONSENT_FLAG } };
    default:
      return state;
  }
}