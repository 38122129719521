import { takeLatest, call, put, all, select, takeEvery } from 'redux-saga/effects';
import * as CognitoActionTypes from '../../constants/shared/cognitoLoginConstants';
import { HttpMethod, HttpRequest } from '../../../shared/core/utils/requestHandler';

function* cognitoLogin(action) {
    try {
        const object = { 'username': action.username, 'password': action.password };
        const response = yield (new HttpRequest(HttpMethod.POST, action.endpoint, null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_LOGIN_SUCCESS,
                response: response
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_LOGIN_FAILURE, error: error.response });
    }
}

function* cognitoLoginMFA(action) {
    try {
        const object = { "code": action.phoneCode, "mfaType": action.mfaType };
        const response = yield (new HttpRequest(HttpMethod.POST, action.endpoint, null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_LOGIN_MFA_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_LOGIN_MFA_FAILURE, error });
    }
}

function* cognitoSignup(action) {
    try {
        const object = action.user;
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/signup', null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_SIGNUP_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_SIGNUP_FAILURE, error });
    }
}

function* cognitoSignupVerify(action) {
    try {
        const object = { "username": action.username, "code": action.code, "signUpCode": action.signUpCode, "userCode": action.userCode };
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/signup/confirm', null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_FAILURE, error });
    }
}

function* cognitoSignupResendCode(action) {
    try {
        const object = { "username": action.username };
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/signup/resend', null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE_FAILURE, error });
    }
}

function* cognitoSignInResendCode(action) {
    try {
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/verify/phone_number/send', null, null).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE_FAILURE, error });
    }
}


function* cognitoPasswordReset(action) {
    try {
        const object = { "username": action.username };
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/password/forget', null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_RESET_PASSWORD_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_RESET_PASSWORD_FAILURE, error });
    }
}

function* cognitoPasswordResetConfirm(action) {
    try {
        const object = {
            "username": action.username,
            "code": action.code,
            "newPassword": action.password
        };
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/password/confirm', null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD_FAILURE, error });
    }
}

function* cognitoPasswordResetResend(action) {
    try {
        const object = { "username": action.username };
        const response = yield (new HttpRequest(HttpMethod.POST, '/auth/password/forget', null, object).invoke());
        if (response.status === 200) {
            yield put({
                type: CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD_SUCCESS,
                response: response.data
            });
        }
    } catch (error) {
        yield put({ type: CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD_FAILURE, error });
    }
}


function* cognitoWatcher() {
    yield takeLatest(CognitoActionTypes.COGNITO_API_LOGIN_REQUEST, cognitoLogin);
    yield takeLatest(CognitoActionTypes.COGNITO_API_LOGIN_MFA_REQUEST, cognitoLoginMFA);
    yield takeLatest(CognitoActionTypes.COGNITO_API_SIGNUP_REQUEST, cognitoSignup);
    yield takeLatest(CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_REQUEST, cognitoSignupVerify);
    yield takeLatest(CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE, cognitoSignupResendCode);
    yield takeLatest(CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE, cognitoSignInResendCode)
    yield takeLatest(CognitoActionTypes.COGNITO_API_RESET_PASSWORD, cognitoPasswordReset)
    yield takeLatest(CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD, cognitoPasswordResetConfirm)
    yield takeLatest(CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD, cognitoPasswordResetResend);
}

export default cognitoWatcher;

