
import React from 'react';

const ContactsIcon = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st6{stroke:${props.stroke}}`}</style>
        <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Users-/-Profile" className="st6">
                <g id="Group" transform="translate(0.000000, 3.000000)" strokeWidth="1.5">
                    <g id="Regular">
                        <circle id="Oval" cx="7.5" cy="4.875" r="4.125"></circle>
                        <path d="M0.75,17.25 C0.75,13.5220779 3.77207794,10.5 7.5,10.5 C11.2279221,10.5 14.25,13.5220779 14.25,17.25" id="Shape"></path>
                        <circle id="Oval" cx="17.727" cy="7.125" r="3.375"></circle>
                        <path d="M15.813,12.068 C17.5078995,11.4433283 19.4011141,11.686466 20.8831675,12.7191406 C22.365221,13.7518152 23.2490027,15.4436506 23.25,17.25" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}
export default ContactsIcon
