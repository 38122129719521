import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TermsOfService from '../../../pages/shared/loginuser/components/TermsOfService';
import { Link } from '@material-ui/core';
import { FETCH_TRM_OF_SVC_REQUEST } from '../../../redux/constants/shared/legalsConstants';
import { connect } from 'react-redux';
import styles from './footerStyles';
import { con } from "../../config/config";

class Footer extends React.Component {

    state = { openTerms: false };

    handleOpenModal = (type) => {
        if (type === 'openTerms') {
            if (!this.props.trmsOfSvcfetching && (!this.props.trmsOfSvcContent || this.props.trmsOfSvcContent.length === 0))
                this.props.fetchTrmsOfSvcContent();
            this.setState({ [type]: true });
        }
        else if (type === 'openPolicy') {
            //open fidelity privacy policy
            window.open(con.PRIVACY_POLICY,'_blank');
        }
    }

    handleCloseModal = (type) => {
        this.setState({ [type]: false });
    }

    generateInlineLink(flag, label) {
        const { classes } = this.props;
        return <Link data-test={`generateLink-${flag}`} className={classes.link} onClick={() => this.handleOpenModal(flag)}>{label}</Link>
    }

    render() {
        const { classes } = this.props;
        return <>
            <TermsOfService data-test='termsOfService'
                title='Terms of Service'
                openModal={this.state.openTerms}
                showAction={false}
                handleCloseCallback={() => { this.handleCloseModal('openTerms') }} />

            <footer data-test='footerComponent' className={`${this.props.layoutStyle === 'dashboard' ? classes.rootDashboard : classes.root}`}>
                <div className={classes.content}>
                    <span className={classes.disclaimer}>Copyright 2020 FMR LLC. All Rights Reserved.</span>
                    <span style={{ width: '100%' }}></span>
                    <span className={classes.policyLabel}>{this.generateInlineLink('openPolicy', 'Privacy Policy')}</span>
                    <span className={classes.tosLabel}>{this.generateInlineLink('openTerms', 'Terms of Service')}</span>
                </div>
            </footer>
        </>
    }
}

const mapStateToProps = state => {
    return {
        trmsOfSvcContent: state.legalsReducer.trmsOfService.content,
        trmsOfSvcfetching: state.legalsReducer.trmsOfService.fetching,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTrmsOfSvcContent: () => dispatch({ type: FETCH_TRM_OF_SVC_REQUEST }),
    };
};

export { mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Footer));
