// Cognito Login 
export const COGNITO_API_LOGIN_REQUEST = 'COGNITO_API_LOGIN_REQUEST';
export const COGNITO_API_LOGIN_SUCCESS = 'COGNITO_API_LOGIN_SUCCESS';
export const COGNITO_API_LOGIN_FAILURE = 'COGNITO_API_LOGIN_FAILURE';

export const COGNITO_API_LOGIN_MFA_REQUEST = 'COGNITO_API_LOGIN_MFA_REQUEST';
export const COGNITO_API_LOGIN_MFA_SUCCESS = 'COGNITO_API_LOGIN_MFA_SUCCESS';
export const COGNITO_API_LOGIN_MFA_FAILURE = 'COGNITO_API_LOGIN_MFA_FAILURE';

export const COGNITO_API_SIGNUP_REQUEST = 'COGNITO_API_SIGNUP_REQUEST';
export const COGNITO_API_SIGNUP_SUCCESS = 'COGNITO_API_SIGNUP_SUCCESS';
export const COGNITO_API_SIGNUP_FAILURE = 'COGNITO_API_SIGNUP_FAILURE';

export const COGNITO_API_SIGNUP_VERIFY_REQUEST = 'COGNITO_API_SIGNUP_VERIFY_REQUEST';
export const COGNITO_API_SIGNUP_VERIFY_SUCCESS = 'COGNITO_API_SIGNUP_VERIFY_SUCCESS';
export const COGNITO_API_SIGNUP_VERIFY_FAILURE = 'COGNITO_API_SIGNUP_VERIFY_FAILURE';

export const COGNITO_API_SIGNUP_RESEND_CODE = 'COGNITO_API_SIGNUP_RESEND_CODE';
export const COGNITO_API_SIGNUP_RESEND_CODE_SUCCESS = 'COGNITO_API_SIGNUP_RESEND_CODE_SUCCESS';
export const COGNITO_API_SIGNUP_RESEND_CODE_FAILURE = 'COGNITO_API_SIGNUP_RESEND_CODE_FAILURE';

export const COGNITO_API_SIGNIN_RESEND_CODE = 'COGNITO_API_SIGNIN_RESEND_CODE';
export const COGNITO_API_SIGNIN_RESEND_CODE_SUCCESS = 'COGNITO_API_SIGNIN_RESEND_CODE_SUCCESS';
export const COGNITO_API_SIGNIN_RESEND_CODE_FAILURE = 'COGNITO_API_SIGNIN_RESEND_CODE_FAILURE';

export const COGNITO_API_RESET_PASSWORD = 'COGNITO_API_RESET_PASSWORD';
export const COGNITO_API_RESET_PASSWORD_SUCCESS = 'COGNITO_API_RESET_PASSWORD_SUCCESS';
export const COGNITO_API_RESET_PASSWORD_FAILURE = 'COGNITO_API_RESET_PASSWORD_FAILURE';

export const COGNITO_API_RESET_RESEND_PASSWORD = 'COGNITO_API_RESET_RESEND_PASSWORD';
export const COGNITO_API_RESET_RESEND_PASSWORD_SUCCESS = 'COGNITO_API_RESET_RESEND_PASSWORD_SUCCESS';
export const COGNITO_API_RESET_RESEND_PASSWORD_FAILURE = 'COGNITO_API_RESET_RESEND_PASSWORD_FAILURE';

export const COGNITO_API_RESET_CONFIRM_PASSWORD = 'COGNITO_API_RESET_CONFIRM_PASSWORD';
export const COGNITO_API_RESET_CONFIRM_PASSWORD_SUCCESS = 'COGNITO_API_RESET_CONFIRM_PASSWORD_SUCCESS';
export const COGNITO_API_RESET_CONFIRM_PASSWORD_FAILURE = 'COGNITO_API_RESET_CONFIRM_PASSWORD_FAILURE';