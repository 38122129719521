import { takeLatest, call, put, all, select, takeEvery } from 'redux-saga/effects';
import * as shareContactActionTypes from '../../constants/personalVault/shareContactConstants';
import {
    editShareContactsAPI,
    getShareContactsAPI
} from '../../../shared/core/utils/apiFunctions';

export function* shareContactsWatcher() {
    yield takeEvery(shareContactActionTypes.UPDATE_SHARE_CONTACTS_REQUEST, updateShareContactsSaga);
    yield takeEvery(shareContactActionTypes.GET_SHARE_CONTACTS, getShareContactsSaga);
}

function* updateShareContactsSaga(action) {
    try {
        const response = yield call(
            editShareContactsAPI,
            action.contacts,
            action.itemCode
        )
        yield put({
            type: shareContactActionTypes.UPDATE_SHARE_CONTACTS_SUCCESS,
            response: response
        })
    } catch (err) {
        yield put({
            type: shareContactActionTypes.UPDATE_SHARE_CONTACTS_FAILURE,
            error: err,
        })
    }
}

function* getShareContactsSaga(action) {
    try {
        const response = yield call(
            getShareContactsAPI,
            action.itemCode
        )
        yield put({
            type: shareContactActionTypes.GET_SHARE_CONTACTS_SUCCESS,
            response: response
        })
    } catch (err) {
        yield put({
            type: shareContactActionTypes.GET_SHARE_CONTACTS_FAILURE,
            error: err,
        })
    }
}

export default shareContactsWatcher;