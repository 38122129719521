import { all } from 'redux-saga/effects';
import loginSaga from './shared/loginUserSagas'
import legalsSagas from './shared/legalsSagas';
import themeSagas from './shared/themeSagas'
import featureSagas from './shared/featureSagas';
import createFolderSagas from './personalVault/createFolderSagas';
import itemsSagas from './personalVault/itemsSagas';
import contactsSagas from './personalVault/contactsSagas';
import invitationSagas from './personalVault/invitationSagas';
import shareContactsSagas from './personalVault/shareContactSaga';
import contactDetailSagas from './personalVault/contactDetailSagas';
import groupDetailSagas from './personalVault/groupDetailSagas';
import shareAllItemsSagas from './personalVault/shareItemsSagas';
import appStatesSagas from './shared/appStatesSagas';
import inviteVerificationSagas from './shared/inviteVerificationSagas';
import CognitoLoginSagas from './shared/cognitoLoginSagas';
import myFilesSagas from './personalVault/myFilesSaga';
import topicSagas from './customerVault/topicSagas';
import topicFilesSaga from './customerVault/topicFilesSaga';
import userDetailsSagas from './shared/userDetailsSagas';
import userSagas from './customerVault/userSagas';
import filePreviewSagas from './shared/filePreviewSagas';
import eSignSagas from './shared/eSignSagas';
import topicFollowSagas from './customerVault/topicFollowSaga';
import instUserSagas from './customerVault/instUserSagas';

// Retiring
import homeSaga from '../../retired/home/homeSagas';

export default function* IndexSaga() {
	yield all([ 
		themeSagas(), 
		loginSaga(), 
		legalsSagas(), 
		homeSaga(), 
		featureSagas(),
		createFolderSagas(),
		itemsSagas(),
		contactsSagas(),
		invitationSagas(),
		shareContactsSagas(),
		contactDetailSagas(),
		shareAllItemsSagas(),
		groupDetailSagas(),
		appStatesSagas(),
		CognitoLoginSagas(),
		myFilesSagas(),
		inviteVerificationSagas(),
		topicSagas(),
		userSagas(),
		topicFilesSaga(),
		userDetailsSagas(),
		filePreviewSagas(),
		eSignSagas(),
		topicFollowSagas(),
		instUserSagas()
	]);
}
