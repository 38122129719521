import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogContent, Paper, DialogActions, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Fade, Modal, IconButton, Button, Link, Radio, RadioGroup, FormControlLabel, Chip, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ceremonyTypeConstants from '../ceremonyTypeConstants.json';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type ESignValidateprops = {
    onClose: any,
    isSuccess: boolean,
    children?: any,
    classes?: any
}

export const styles = (theme: any) => ({
    main: {
        fontFamily: theme.homefontfamilyregular,
        lineHeight: '24px'
    },
    content: {
        padding: '16px 0px',
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    subtitle: {
        fontSize: '1.0rem',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        letterSpacing: '0.15px',
        paddingBottom: '16px'
    },
    title: {
        color: '#000000',
        fontSize: '1.65rem',
        fontWeight: 'bold' as 'bold',
        letterSpacing: '0',
        lineHeight: '32.5px'
    },
    commonModalActions: {
        justifyContent: 'center',
        padding: '0px',
        height: '40px'
    },
    primarybtn: {
        textTransform: 'none' as 'none',
        width: '170px'
    },
    documentLinkCancel: {
        fontSize: '1rem',
        paddingLeft: '8px',
        position: 'absolute' as 'absolute',
        left: '2rem'
    },
    btnContainer:
    {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center'
    },

});

class ESignValidate extends Component<ESignValidateprops> {
    render(): JSX.Element {
        const { classes } = this.props;
        return (
            <div className={classes.main} data-test="ESignValidate">
                <div className={classes.title}>
                    {this.props.isSuccess ? 'Validation accepted' : 'Validation declined'}
                </div>
                <DialogContent
                    classes={{ root: classes.content }}>
                    <div className={classes.subtitle}>
                        {this.props.isSuccess ? 'Socure ID+ has accepted your verification. Please wait. When complete, this screen will refresh automatically.' : 'We’re sorry, Socure ID Check was unable to confirm your identity. Please try again with approved identification, and if you continue to have issues, contact your Fidelity representative.'}
                    </div>
                </DialogContent>
                <DialogActions className={classes.commonModalActions}  >
                    <Link id="cancelInvite" data-test="cancelBtn" className={classes.documentLinkCancel} href="#"
                        onClick={() => this.props.onClose(false)}>
                        {this.props.isSuccess ? 'CANCEL' : 'CLOSE'}
                    </Link>
                </DialogActions>
            </div>
        )
    }
}

export default (withStyles(styles)(ESignValidate));