import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { RouteProps, RouteComponentProps } from 'react-router';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { optionHandle } from '../../../shared/core/utils/helper';
import { con } from '../../../shared/config/config'

import './TimeOutCounter.scss';

type TimeOutCounterProps = {
    updateDrawerMenu: any;
    updateCommonModal:any;
    onClose:any;
}

type TimeOutCounterWithRouter = TimeOutCounterProps & RouteProps & RouteComponentProps<{}>

type TimeOutCounterStates = {}

class TimeOutCounter extends Component<TimeOutCounterWithRouter, TimeOutCounterStates> {
    
    state = {
        min: 4,
        sec: 59,
        warning: false,
        advisorFlow: false
    }
    
    content: any = {
        iconLink: '',
        title: 'Session Timeout',
        body: 'Please click "Continue" to keep working, or click "Log Out" to end your session now.',
        buttonOneLabel: 'Log Out',
        buttonTwoLabel: 'Continue'
    }

    secInterval = setInterval(() => {
        if(this.state.sec > 0) {
            this.setState({
                sec: this.state.sec - 1
            })
        } else {
            this.setState({
                sec: 59
            })
        }
    }, 1000)

    minInterval = setInterval(() => {

        if(this.state.min > 0) {
            this.setState({
                min: this.state.min - 1
            })
            if (this.state.min < 2) {
                this.setState({ warning: true })
            }
        } else {
            this.setState({
                min: 0,
                sec: 0
            })
            this.sessionTimeoutHandle();
        }
    }, 1000 * 60)

    componentDidMount() {
        // this.secInterval();
        // this.minInterval();
        const redirectDescriminator = con.REDIRECTION;
        if(redirectDescriminator === 'advisor') {
            this.setState({advisorFlow : true});
            this.content.body = 'Please click "Continue" to keep working, or click "Refresh Page" to reload your session.';
            this.content.buttonOneLabel = 'Refresh Page';
        }
    }

    componentWillUnmount() {
        clearInterval(this.secInterval);
        clearInterval(this.minInterval);
    }

    userIsBack = () => {
        this.props.updateDrawerMenu(false, null);
        this.props.updateCommonModal(false, null);
        clearInterval(this.secInterval);
        clearInterval(this.minInterval);
    }

    logout = () => {
        if(this.state.advisorFlow) {
            window.location.reload();
        }
        else {
            optionHandle('Log Out', false);
        }
    }

    sessionTimeoutHandle() {
        if(this.state.advisorFlow) {
            this.userIsBack()
            this.props.onClose();
        }
        else {
            optionHandle('Log Out', true);
        }
    }


    render () {
        return (
            <div className="time-out-wrapper" data-test='TimeOutCounter'>
                <DialogTitle id="common-modal-title" className="common-modal-title">
                    <span className="dialog-title">{ this.content.title }</span>
                </DialogTitle>
                <DialogContent className="common-modal-description-root">
                    <DialogContentText className="common-modal-description" id="common-modal-description">
                        <p className="time-out-label-1">Your session will expire in 
                            <span className={ this.state.warning ? 'warning-label' : 'non-warning-label'}>{ this.state.min < 10 ? `0${this.state.min}` : this.state.min }:{ this.state.sec < 10 ? `0${this.state.sec}` : this.state.sec }</span>
                        .</p>
                        <p className="time-out-label-2">{ this.content.body }</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="common-modal-actions time-out-action-wrapper">
                    <div className="logout-wrapper">
                        <a data-test='logoutLink' className="common-modal-button logout-link" onClick={ this.logout } >
                            { this.content.buttonOneLabel }
                        </a>
                    </div>
                    <Button data-test="btnTwo" className="common-modal-button" variant="contained" onClick={ this.userIsBack } color="primary" disableElevation>
                        { this.content.buttonTwoLabel }
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDrawerMenu: (selectedMenu: string) => dispatch({
            type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
            selectedMenu: selectedMenu
        }),
        updateCommonModal: (openState: boolean, content: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_COMMON_MODAL,
            openState: openState,
            content: content
        })
        }
}

export const TimeOutCounterComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeOutCounter);

export default withRouter(connect(
    mapStateToProps, 
    mapDispatchToProps)(TimeOutCounter));