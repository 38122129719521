import React, { Component } from 'react';

type RecentsListViewProps = {

}

const RecentsListView = (props: RecentsListViewProps) => {
    return(
        <div>There are no recent files yet</div>
    )
}

export default RecentsListView;

