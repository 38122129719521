import {
    FETCH_TRM_OF_SVC_REQUEST,
    FETCH_TRM_OF_SVC_SUCCESSFUL,
    FETCH_TRM_OF_SVC_FAILURE,
    FETCH_TRMSOFSVC_ERROR_CLEAR,
    FETCH_ENDUSER_DISCLAIMER_REQUEST,
    FETCH_ENDUSER_DISCLAIMER_SUCCESS,
    FETCH_ENDUSER_DISCLAIMER_FAILURE,
    FETCH_DELETE_FILE_DISCLAIMER_REQUEST,
    FETCH_DELETE_FILE_DISCLAIMER_SUCCESS,
    FETCH_DELETE_FILE_DISCLAIMER_FAILURE
} from '../../constants/shared/legalsConstants';

import {
    GET_FORM_DISCLOSURE,
    GET_FORM_DISCLOSURE_SUCCESS,
    GET_FORM_DISCLOSURE_FAILURE
} from '../../constants/shared/eSignConstants'

import {
    FETCH_TRM_OF_SVC_ERROR,
    FETCH_ENDUSER_DISCLAIMER_ERROR,
    FETCH_DELETE_FILE_DISCLAIMER_ERROR
} from '../../../shared/errors/ErrorActions';

const initialState = {
    trmsOfService: {
        fetching: false,
        content: '',
        error: null,
    },
    endUserDisclaimer: {
        fetching: false,
        content: '',
        error: null,
    },
    formTypeDisclosure: {
        fetching: false,
        content: '',
        error: null,
    },
    deleteFileDisclaimer: {
        fetching: false,
        content: '',
        error: null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRM_OF_SVC_REQUEST:
            return { ...state, trmsOfService: { fetching: true, content: '', error: null } }

        case FETCH_TRM_OF_SVC_SUCCESSFUL:
            return { ...state, trmsOfService: { fetching: false, content: action.responseInfo.data, error: null } }

        case FETCH_TRM_OF_SVC_FAILURE:
            const trmSvcError = { response: { data: { errMsg: "error while fetching terms of service" } } }
            return { ...state, trmsOfService: { fetching: false, content: '', error: { ...trmSvcError, action: FETCH_TRM_OF_SVC_ERROR } } }

        case FETCH_TRMSOFSVC_ERROR_CLEAR:
            return { ...state, trmsOfService: { ...state.trmsOfService, error: null } }

        case FETCH_ENDUSER_DISCLAIMER_REQUEST:
            return { ...state, endUserDisclaimer: { fetching: true, content: '', error: null } }

        case FETCH_ENDUSER_DISCLAIMER_SUCCESS:
            return { ...state, endUserDisclaimer: { fetching: false, content: action.responseInfo.data, error: null } }

        case FETCH_ENDUSER_DISCLAIMER_FAILURE:
            const endUserDisclaimerError = { response: { data: { errMsg: "error while fetching end user disclaimer text" } } }
            return { ...state, endUserDisclaimer: { fetching: false, content: '', error: { ...endUserDisclaimerError, action: FETCH_ENDUSER_DISCLAIMER_ERROR } } }

        case GET_FORM_DISCLOSURE:
            return { ...state, formTypeDisclosure: { fetching: true, content: '', error: null } }

        case GET_FORM_DISCLOSURE_SUCCESS:
            return { ...state, formTypeDisclosure: { fetching: false, content: action.responseInfo, error: null } }

        case GET_FORM_DISCLOSURE_FAILURE:
            const formTypeDisclosureError = { response: { data: { errMsg: "error while fetching form specific disclosure text" } } }
            return { ...state, formTypeDisclosure: { fetching: false, content: '', error: { ...formTypeDisclosureError } } }

        case FETCH_DELETE_FILE_DISCLAIMER_REQUEST:
            return { ...state, deleteFileDisclaimer: { fetching: true, content: '', error: null } }

        case FETCH_DELETE_FILE_DISCLAIMER_SUCCESS:
            return { ...state, deleteFileDisclaimer: { fetching: false, content: action.responseInfo.data, error: null } }

        case FETCH_DELETE_FILE_DISCLAIMER_FAILURE:
            const deleteFileDisclaimerError = { response: { data: { errMsg: "error while fetching delete file disclaimer text" } } }
            return { ...state, deleteFileDisclaimer: { fetching: false, content: '', error: { ...deleteFileDisclaimerError, action: FETCH_DELETE_FILE_DISCLAIMER_ERROR } } }

        default:
            return state;
    }
}
