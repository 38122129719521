import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TermsOfService from '../../../pages/shared/loginuser/components/TermsOfService';
import { Link } from '@material-ui/core';
import { FETCH_TRM_OF_SVC_REQUEST } from '../../../redux/constants/shared/legalsConstants';
import { connect } from 'react-redux';
import { con } from '../../../shared/config/config'

export const styles = (theme: any) => ({
    footerWrapper: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    root: {
        width: '100%',
        zIndex: 1200,
        padding: '1rem 0',
        backgroundColor: '#f0f0f0',
        borderTop: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-end',
        fontSize: 13,
        // position: 'fixed' as 'fixed',
        bottom: '0',
        position: 'static' as 'static',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            position: 'static' as 'static',
            justifyContent: 'center',
        },
        minHeight: '50px'
    },
    content: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap' as 'wrap',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            // padding: '0.75rem 0.5rem',
            justifyContent: 'space-evenly',
            width: '100%',
        }
    },
    disclaimer: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
    },
    link: {
        fontSize: 12,
        color: '#005F7C',
        cursor: 'pointer',
    },
    policyLabel: {
        paddingRight: '0.5rem',
    },
    tosLabel: {
        paddingLeft: '0.5rem',
        borderLeft: '1px solid rgba(68, 70, 87, 0.25)'
    },
    footerrightcontent : {
        height: '100%',
        justifyContent:'space-evenly',
        alignItems:'flex-end',
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginRight: '1.2rem',
        [theme.breakpoints.down('sm')]: {
            alignItems:'center',
            marginRight: 0
        }
    },
    footerlinks :
    {
        display: 'flex',
        flexDirection: 'row' as 'row',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0.5rem'
        }
    }
})

type CvFooterProps = {
    trmsOfSvcContent: any;
    trmsOfSvcfetching: any;
    fetchTrmsOfSvcContent: any;
    classes: any;
    endUserDisclaimerContent: string;
    features: any;
}

class CvFooter extends Component<CvFooterProps>
{
    state = { openTerms: false };

    handleOpenModal = (type: any) => {
        if (type === 'openTerms') {
            if (!this.props.trmsOfSvcfetching && (!this.props.trmsOfSvcContent || this.props.trmsOfSvcContent.length === 0))
                this.props.fetchTrmsOfSvcContent();
                this.setState({ [type]: true });
        }
        else if (type === 'openPolicy') {
            //open fidelity privacy policy
            window.open(con.PRIVACY_POLICY, '_blank');
        }
    }

    handleCloseModal = (type: any) => {
        this.setState({ [type]: false });
    }

    generateInlineLink(flag: any, label: any) {
        const { classes } = this.props;
        return <Link data-test={`linkBtn${flag}`} className={classes.link} onClick={() => this.handleOpenModal(flag)}>{label}</Link>
    }

    // getDisclaimerHTMLMarkup() {
    //     const { features } = this.props;
    //     if (features['noDisclaimerContent']) {
    //         return undefined;
    //     }
    //     return { __html: this.props.endUserDisclaimerContent };
    // }

    render() {
        const { classes } = this.props;
        return <div data-test='CvFooter' className={classes.footerWrapper}>
            <TermsOfService
                title='Terms of Service'
                openModal={this.state.openTerms}
                showAction={false}
                handleCloseCallback={() => { this.handleCloseModal('openTerms') }} />

            <footer className={classes.root}>
                {/* <div className={classes.enduserdisclaimer} dangerouslySetInnerHTML={this.getDisclaimerHTMLMarkup()}>
                </div> */}
                <div className={classes.content}>
                    <div className={classes.footerrightcontent}>
                        <span className={classes.disclaimer}>Copyright 2020 FMR LLC. All Rights Reserved.</span>
                        <div className={classes.footerlinks}>
                            <span className={classes.policyLabel}>{this.generateInlineLink('openPolicy', 'Privacy Policy')}</span>
                            <span className={classes.tosLabel}>{this.generateInlineLink('openTerms', 'Terms of Service')}</span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    }
}

const mapStateToProps = (state: any) => {
    return {
        trmsOfSvcContent: state.legalsReducer.trmsOfService.content,
        trmsOfSvcfetching: state.legalsReducer.trmsOfService.fetching,
        endUserDisclaimerfetching: state.legalsReducer.endUserDisclaimer.fetching,
        endUserDisclaimerContent: state.legalsReducer.endUserDisclaimer.content,
        features: state.homeReducer.features
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchTrmsOfSvcContent: () => dispatch({ type: FETCH_TRM_OF_SVC_REQUEST }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CvFooter));
