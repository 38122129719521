import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as HorizontalMenu } from '../../../assets/horizontal-menu.svg';
import { ReactComponent as AddContactIcon } from '../../../assets/add-contact.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { formattedDate } from '../../core/utils/functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        container: {
            display: 'flex'
        },
        customTable: {
            "& .MuiTableCell-sizeSmall": {
                padding: "6px 16px 2px 2px"
            }
        },
        table: {
            minWidth: 750,

        },
        tableBody: {
            overflow: 'auto',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHeadRow: {
            backgroundColor: '#FAFAFA',
            height: '57px'
        },
        checkboxCell: {
            borderBottom: '1px solid #e8e8e8',
        },
        headerWrapper: {
            display: 'flex',
            justifyContent: 'flex-start'
        },
        headerTitle: {
            cursor: 'pointer'
        },
        headerTopic: {
            cursor: 'pointer',
            paddingLeft: 70

        },
        sortLabel: {
            width: '25px'
        },
        headWithIcon: {
            color: 'rgba(81, 81, 81, 0.87)',
            paddingLeft: '72px',
            fontSize: '.875rem',
            fontWeight: 'bold',
        },
        headWithoutIcon: {
            color: 'rgba(81, 81, 81, 0.87)',
            fontSize: '.875em',
            fontWeight: 'bold',
        },
        headAlignRight: {
            textAlign: 'right'
        },
        shortCell: {
            maxWidth: '2.7rem',

        },
        hiddenCol: {
            display: 'none'
        },
        shownCol: {
            display: 'flex'
        },
        itemListRow: {
            height: '53px',
            borderBottom: '1px solid #e8e8e8',
            borderCollapse: 'collapse',
            '&.Mui-selected': {
                backgroundColor: 'rgba(212,243,182,0.42)'
            }
        },
        filterBtnCell: {
            padding: '0 .5rem',
            width: '40px',
            textAlign: 'right'
        },
        filterMenuBtn: {
            width: '20px',
            height: '20px',
        },
        filterMenuItem: {
            paddingLeft: '10px',
            '&:hover': {
                color: '#000',
                backgroundColor: 'rgba(220,220,220,1)',
                cursor: 'pointer'
            }
        },
        typeIcon: {
            marginRight: '1rem'
        },
        avatarCell: {
            // borderBottom: 'none',
            // minHeight: '73px'
        },
        avatarCellContentWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 300
        },
        itemListCell: {
            // borderBottom: 'none',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 100,
            fontSize: '.875rem'
        },
        momentListCell: {
            borderBottom: 'none',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddngRight: '0 !important',
        },
        momentCell: {
            fontWeight: 200,
            fontSize: '.875rem',
            color: 'rbga(0, 0, 0, 0.54)',
            margin: 0,
            width: '5.6rem'
        },
        categoryCell: {
            fontWeight: 200,
            fontSize: '.875rem',
            color: 'rbga(0, 0, 0, 0.54)',
            margin: 0,
            width: '8rem'
        },
        filesContactListCell: {
            fontWeight: 200,
            fontSize: '.875rem',
            color: 'rbga(0, 0, 0, 0.54)',
            margin: 0,
            width: '0.5rem',
        },
        actionCell: {
            color: 'rbga(0, 0, 0, 0.54)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        topicIcon: {
            borderRadius: '4px',
            border: '2px solid #888',
            width: '30px',
            height: '30px',
            padding: '2px',
            fontSize: '12px',
            margin: '0 1rem',
            color: '#888',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
        },
        topicTitle: {
            width: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 700,
            fontSize: 14,
            margin: 0,
            // textTransform: 'capitalize',
            color: 'rgba(0, 0, 0, 0.72)',
            cursor: 'pointer',
            '&:hover': {
                color: '#0E67A9',
            }
        },
        permissionBtn: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem'
        },
        permissionStatic: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem'
        },
        loadingWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '49px'
        },
        loadingContext: {
            color: '#8a8a8a',
            fontWeight: 700,
            fontSize: '11px',
            marginLeft: '10px'
        }
    }),
);

interface Data {
    stName: string,
    categoryName: string;
    createdAt: string;
    modifiedAt: string;
    contactsCount: number;
    topicsCount: number;
}

type Order = 'asc' | 'desc';

interface TopicType {
    stCode: string,
    stName: string,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number
}

interface SortType {
    direction: string,
    property: string
}

interface MenuItemType {
    id: string,
    label: string,
    show: boolean
}

interface MenuType {
    count: number,
    menu: MenuItemType[]
}


type CommonTopicListProps = {
    topics: {
        content: TopicType[],
        sort: SortType[]
    },
    menuActions: MenuType,
    menuActionOnClick: any,
    onSorting: any,
    tableMarginHeight: number,
    onFileOpen: any,
    topicsFetching: boolean,

    fetchMoreData?: any,
    lazyLoading: boolean,
    noMoreData?: boolean,
    topicListHeaders: string[],
    onFiltering: any
}

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    rowCount: number;
    topics: TopicType[];
    onSelectFilters: any;
    sortOnClick: any;
    width: any;
    sort: SortType;
    topicListHeaders: string[],
    onFiltering: any
}

interface HeadCell {
    id: keyof Data;
    label: string;
    filterable: boolean;
    withIcon: boolean;
    paddingRight: number;
    sortable: boolean;
    showInTablet: boolean
}

let headCells: HeadCell[] = [
    { id: 'stName', label: 'Topic', withIcon: true, paddingRight: 16, filterable: false, sortable: true, showInTablet: true },
    { id: 'categoryName', label: 'Categories', withIcon: false, paddingRight: 16, filterable: true, sortable: true, showInTablet: true },
    { id: 'createdAt', label: 'Created', withIcon: false, paddingRight: 16, filterable: true, sortable: true, showInTablet: true },
    { id: 'modifiedAt', label: 'Modified', withIcon: false, paddingRight: 16, filterable: true, sortable: true, showInTablet: true },
    { id: 'contactsCount', label: 'Contacts', withIcon: false, paddingRight: 16, filterable: true, sortable: false, showInTablet: false },
    { id: 'topicsCount', label: 'Files', withIcon: false, paddingRight: 16, filterable: true, sortable: false, showInTablet: false },
];

let headCellsArr = ['stName', 'categoryName', 'modifiedAt', 'createdAt', 'contactsCount', 'topicsCount'];

const EnhancedTableHead = withWidth()((props: EnhancedTableProps) => {
    const { classes, onSelectFilters, topicListHeaders } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterHeads, setFilterHeads] = useState<string[]>(topicListHeaders);
    const [topicSortBy, setTopicSortBy] = useState<boolean>(true);
    const [categoriesSortBy, setCategoriesSortBy] = useState<boolean>(true);
    const [modifiedSortBy, setModifiedSortBy] = useState<boolean>(true);
    const [createdSortBy, setCreatedSortBy] = useState<boolean>(true);
    const [currentSorting, setCurrentSorting] = useState<string>('modifiedAt');
    const filterMenusProc = () => {
        let filterable_menus: string[] = [];
        filterable_menus = props.topicListHeaders;
        // headCells.filter((col: HeadCell) => {
        //     return col.filterable
        // }).forEach(el => filterable_menus.push(el.id));
        return filterable_menus;
    }

    const [selected, setSelected] = useState<string[]>(filterMenusProc());

    useEffect(() => {
        if (isWidthDown('md', props.width)) {
            let copy_filterHeads: string[] = [];
            filterHeads.forEach(el => {
                copy_filterHeads.push(el)
            })
            props.onFiltering(copy_filterHeads);
            let newSelected: string[] = selected;
            headCells.forEach((x: HeadCell) => {
                if (props.topicListHeaders.includes(x.id)) {
                    x.showInTablet = true;
                } else {
                    x.showInTablet = false;
                }
            })
            headCells.forEach((x: HeadCell) => {
                if (!x.showInTablet) {
                    let name = x.id;
                    // let itemIndex = copy_filterHeads.indexOf(name);
                    // if (itemIndex > -1) {
                    //     copy_filterHeads.splice(itemIndex, 1)
                    // } else {
                    //     copy_filterHeads.push(name)
                    // }
                    // console.log(copy_filterHeads)
                    const selectedIndex = newSelected.indexOf(name);
                    if (selectedIndex > -1) {
                        newSelected.splice(selectedIndex, 1);
                    }
                }
            })
            setFilterHeads(copy_filterHeads);
            setSelected(newSelected);
            onSelectFilters(copy_filterHeads);
        }
    }, [props.width])

    const isSelected = (name: string) => {
        if (selected.indexOf(name) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    const filterMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const filterMenuOnClose = () => {
        setAnchorEl(null);
    };

    const filterItemOnClick = (name: string) => {
        let itemIndex = filterHeads.indexOf(name);
        let copy_filterHeads: string[] = [];
        filterHeads.forEach(el => {
            copy_filterHeads.push(el)
        })

        if (itemIndex > -1) {
            copy_filterHeads.splice(itemIndex, 1)
        } else {
            copy_filterHeads.push(name)
        }

        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setFilterHeads(copy_filterHeads);
        setSelected(newSelected);
        onSelectFilters(copy_filterHeads);

        props.onFiltering(copy_filterHeads);
    };

    const sortingOnClick = (name: string) => {
        setCurrentSorting(name);
        switch (name) {
            case 'stName':
                setTopicSortBy(!topicSortBy);
                break;
            case 'categoryName':
                setCategoriesSortBy(!categoriesSortBy);
                break;
            case 'modifiedAt':
                setModifiedSortBy(!modifiedSortBy);
                break;
            case 'createdAt':
                setCreatedSortBy(!createdSortBy);
                break;
        }
        props.sortOnClick(name);
    }

    return (
        <TableHead data-test='enhancedTableHead'>
            <TableRow className={classes.tableHeadRow}>
                <TableCell className={`${classes.headWithIcon} ${filterHeads.indexOf('stName') > -1 ? null : classes.hiddenCol}`} id="stName">
                    <div className={classes.headerWrapper}>
                        <div className={classes.headerTopic} onClick={() => sortingOnClick('stName')}>Topic</div>
                        <TableSortLabel onClick={() => sortingOnClick('stName')} className={classes.sortLabel} active={props.sort.property === 'st_name'}
                            direction={props.sort.direction === 'DESC' ? 'desc' : 'asc'} title={props.sort.direction === 'DESC' ? 'Descending' : 'Ascending'}>
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('categoryName') > -1 ? null : classes.hiddenCol}`} id="categoryName" >
                    <div className={classes.headerWrapper}>
                        <div className={classes.headerTitle} onClick={() => sortingOnClick('categoryName')}>Category</div>
                        <TableSortLabel onClick={() => sortingOnClick('categoryName')} className={classes.sortLabel} active={props.sort.property === 'pc.category_name'}
                            direction={props.sort.direction === 'DESC' ? 'desc' : 'asc'} title={props.sort.direction === 'DESC' ? 'Descending' : 'Ascending'}>
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('createdAt') > -1 ? null : classes.hiddenCol}`} id="createdAt">
                    <div className={classes.headerWrapper}>
                        <div className={classes.headerTitle} onClick={() => sortingOnClick('createdAt')}>Created</div>
                        <TableSortLabel onClick={() => sortingOnClick('createdAt')} className={classes.sortLabel} active={props.sort.property === 'created'}
                            direction={props.sort.direction === 'DESC' ? 'desc' : 'asc'} title={props.sort.direction === 'DESC' ? 'Descending' : 'Ascending'}>
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('modifiedAt') > -1 ? null : classes.hiddenCol}`} id="modifiedAt">
                    <div className={classes.headerWrapper}>
                        <div className={classes.headerTitle} onClick={() => sortingOnClick('modifiedAt')}>Modified</div>
                        <TableSortLabel className={classes.sortLabel} active={props.sort.property === 'modified'}
                            onClick={() => sortingOnClick('modifiedAt')}
                            direction={props.sort.direction === 'DESC' ? 'desc' : 'asc'} title={props.sort.direction === 'DESC' ? 'Descending' : 'Ascending'}>
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell className={`${classes.headWithoutIcon} ${classes.headAlignRight} ${classes.shortCell} ${filterHeads.indexOf('contactsCount') > -1 ? null : classes.hiddenCol}`} id="contactsCount">
                    Contacts
                </TableCell>
                <TableCell className={`${classes.headWithoutIcon} ${classes.headAlignRight} ${classes.shortCell} ${filterHeads.indexOf('topicsCount') > -1 ? null : classes.hiddenCol}`} id="topicsCount">
                    Files
                </TableCell>
                <TableCell className={classes.filterBtnCell}>
                    <IconButton aria-label="Columns Filter" onClick={filterMenuOnClick} title="Columns Filter">
                        <HorizontalMenu className={classes.filterMenuBtn} />
                    </IconButton>
                    <Menu id="filter-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={filterMenuOnClose}>
                        {headCells.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} className={classes.filterMenuItem} onClick={event => filterItemOnClick(col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                    </Menu>
                </TableCell>
            </TableRow>
        </TableHead>
    );
})

const CommonTopicList = (props: CommonTopicListProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string[]>([]);
    const [selectedTopic, setTopic] = useState({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [filters, setFilters] = useState<string[]>(headCellsArr);
    const [filters, setFilters] = useState<string[]>(props.topicListHeaders);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [tableHeight, setTableHeight] = useState<number>(0);
    const tableContainer: any = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize);
        setTableHeight(tableHeight);
        return () => window.removeEventListener("resize", handleResize);

    }, [filters])

    // const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const itemOnClick = (event: React.MouseEvent<unknown>, name: string, type: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const itemOnOpen = (event: React.MouseEvent<unknown>, topic: any) => {
        event.stopPropagation();
        props.onFileOpen(topic)
        setSelected([]);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectFilters = (updatedFilters: any) => {
        setFilters(updatedFilters);
    }

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>, topic: string) => {
        setAnchorEl(event.currentTarget);
        setTopic(topic);
    };

    if (!props.topics.content) {
        return null;
    }

    return (
        <>
            {!props.topicsFetching &&
                <Paper elevation={0} data-test='dataIsNotFetchingWrapper'>
                    <TableContainer ref={tableContainer} className={classes.container}>
                        <Table classes={{ root: classes.customTable }} size="small" stickyHeader>
                            <EnhancedTableHead data-test='enhancedTableHeadWrapper'
                                topics={props.topics.content}
                                sort={props.topics.sort[0]}
                                classes={classes}
                                rowCount={props.topics.content.length}
                                onSelectFilters={onSelectFilters}
                                sortOnClick={props.onSorting}
                                topicListHeaders={props.topicListHeaders}
                                onFiltering={props.onFiltering} />
                            <TableBody data-test='tableBodyWrapper' className={classes.tableBody}>
                                {props.topics.content.map((topic: any, rIndex: number) => {
                                    const isItemSelected = false;
                                    const labelId = `enhanced-table-checkbox-${rIndex}`;
                                    return (
                                        <TableRow className={classes.itemListRow}
                                            key={rIndex}
                                            hover
                                            onClick={event => itemOnClick(event, topic.itemCode, topic.itemType)}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}>
                                            <TableCell classes={{ sizeSmall: 'cellSmallSize' }} id={labelId} scope="row" padding="none" className={classes.avatarCell}>
                                                <div className={classes.avatarCellContentWrapper}>
                                                    <div className={classes.topicIcon} style={{ 'borderColor': topic.categoryIconColor, 'color': topic.categoryIconColor }}>
                                                        {topic.catgeoryIconLabel}
                                                    </div>
                                                    <Tooltip disableFocusListener disableTouchListener title={topic.stName}>
                                                        <p className={classes.topicTitle}
                                                            onClick={event => itemOnOpen(event, topic)}>
                                                            {topic.stName}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                            <TableCell className={`classes.momentListCell ${filters.indexOf('categoryName') > -1 ? null : classes.hiddenCol} `} align="left">
                                                <p className={classes.categoryCell}> {topic.categoryName}</p>
                                            </TableCell>
                                            <TableCell className={`classes.momentListCell ${filters.indexOf('createdAt') > -1 ? null : classes.hiddenCol} `} align="left">
                                                <p className={classes.momentCell}>{formattedDate(topic.createdAt)}</p>
                                            </TableCell>
                                            <TableCell className={`classes.momentListCell ${filters.indexOf('modifiedAt') > -1 ? null : classes.hiddenCol} `} align="left">
                                                <p className={classes.momentCell}>{formattedDate(topic.modifiedAt)}</p>
                                            </TableCell>
                                            <TableCell className={`classes.filesContactListCell ${filters.indexOf('contactsCount') > -1 ? null : classes.hiddenCol} `} align="right">
                                                {topic.sharedTopicUsersCount}
                                            </TableCell>
                                            <TableCell className={`classes.filesContactListCell ${filters.indexOf('topicsCount') > -1 ? null : classes.hiddenCol} `} align="right">
                                                {topic.sharedTopicItemsCount}
                                            </TableCell>
                                            <TableCell className={classes.actionCell} align="left">
                                                {props.menuActions.count > 2 && <IconButton
                                                    color="primary"
                                                    aria-label="item actions"
                                                    component="span"
                                                    aria-controls="action-menu"
                                                    onClick={(e: any) => openMenu(e, topic)}
                                                    aria-haspopup="false">
                                                    <MoreVertIcon />
                                                </IconButton>
                                                }
                                                {props.menuActions.count <= 2 && props.menuActions.menu.map((item: MenuItemType, mIndex: number) => (
                                                    item.show && <IconButton
                                                        key={mIndex}
                                                        color="primary"
                                                        aria-label="item actions"
                                                        component="span"
                                                        aria-controls="action-menu"
                                                        onClick={() => props.menuActionOnClick('updateContact', topic)}
                                                        aria-haspopup="false">
                                                        {item.id === 'updateContact' && <AddContactIcon className={classes.filterMenuBtn} />}
                                                    </IconButton>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {props.lazyLoading &&
                                    <TableRow >
                                        <TableCell colSpan={props.topics.content.length + 10}>
                                            <div className={classes.loadingWrapper}>
                                                <CircularProgress size={12} /> <span className={classes.loadingContext}>LOADING MORE TOPICS</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                                {props.noMoreData &&
                                    <TableRow >
                                        <TableCell colSpan={props.topics.content.length + 10}>
                                            <div className={classes.loadingWrapper}>
                                                <span className={classes.loadingContext}>END OF LIST</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                            {props.menuActions.count > 2 &&
                                <Menu
                                    id="topic-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}>
                                    {props.menuActions.menu.map((item: MenuItemType, mIndex: number) => (
                                        <MenuItem key={mIndex} onClick={() => props.menuActionOnClick(item.id, selectedTopic)}>{item.label}</MenuItem>
                                    ))}
                                </Menu>
                            }
                        </Table>
                    </TableContainer>
                </Paper>
            }
        </>
    )
}

export const EnhancedTableHeadTesting = {EnhancedTableHead}

export { useStyles };
export default CommonTopicList;