import React from "react";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withStyles } from "@material-ui/styles";
import { PropTypes } from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { classNames } from "react-select/src/utils";

const actionsStyles = (theme: any) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    }
  },
});

type TablePaginationActionWrappedProps = {
  classes?: any,
  theme?: any
}

type tableactions = TablePaginationActionWrappedProps & TablePaginationActionsProps

class TablePaginationActions extends React.Component<tableactions> {
  handleFirstPageButtonClick = (event: any) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event: any) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event: any) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event: any) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { count, page, rowsPerPage } = this.props;
    const { classes } = this.props;
    return (
      <div className={classes.root} data-test="tablepageactions">
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
          data-test="firstpagicon"
        >
          <FirstPageIcon />

        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
          data-test="keyboardleftarrow"
        >
          <KeyboardArrowLeft />

        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          data-test="keyboardrightarrow"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          data-test="lastpagicon"
        >
          <LastPageIcon />
        </IconButton>
      </div >
    );
  }
}

export default withStyles(actionsStyles)(TablePaginationActions);
