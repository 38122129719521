import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Info';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { con } from '../config/config'; 
import {SDV_404_NOT_FOUND} from './ErrorMessages';
import {styles} from './css/errorPageStyles';
import { selectLogo } from '../core/utils/functions'; 

class ErrorPage extends React.Component {

  state= {
    dialogOpen: true,
  }

 

  render() {
    const { classes, loggedIn, error } = this.props;

    let errorMessage;
    if (error) {
      if (error.response && error.response.data && error.response.data.errMsg) {
        errorMessage = error.response.data.errMsg;
      }
      else if(error.response.status === 404){
        errorMessage = SDV_404_NOT_FOUND
      }
      else if (error.response.statusText) {
        errorMessage = error.response.statusText;
      }else{
        errorMessage = "Unknown Error occured , please try again !!"
      }
    }
    
    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <div className={classes.drawerHeader}>
                  {selectLogo(con.PRODUCT_CODE)} 
                  <Typography variant="h6" color="inherit" className={classes.logoHeader}>
                    | Fidsafe for Business
                  </Typography>
                </div>
                <div className={classes.grow} />
            </Toolbar>
            </AppBar>
            <main
              className={classes.content}
            >
              <div style={{ marginTop: 100, textAlign: 'center' }}>
                <Paper className={classes.errPaper}>
                  <ErrorIcon></ErrorIcon>
                  <Typography variant="subtitle1" paragraph >
                       {errorMessage}
                  </Typography>
                  <Typography variant="subtitle2" paragraph >
                       We encountered an error while rendering this page.
                  </Typography>
              </Paper>
              </div>
            </main>
        </div>
      );
  }
}

export default withStyles(styles)(ErrorPage);