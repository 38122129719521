const styles = theme => ({

    appbar : {
        zIndex: '1300 !important',
    },
    toolbarTitle: {
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        height:'64px',
    },
    contentRoot: {
        padding: '1rem 5rem',
        maxWidth: 1024,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: '2rem',
            paddingBottom: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
            paddingTop: '2rem',
            paddingBottom: 0,
        },
    },
    actionsRoot: {
        width: '100%',
        position: 'sticky',
        bottom: 0,
        borderTop: '1px solid rgba(68, 70, 87, 0.25)',
        // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    actions: {
        // padding: '2rem 0 3rem 0',
        display: 'block',
        margin: '0 auto',
        padding: '1rem 5rem',
        maxWidth: 1024,
        // [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '2rem',
        // },
        [theme.breakpoints.down('sm')]: {
            padding: '2rem',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
    warningWrapper: {
        paddingBottom: '2rem',
    },
    warning: {
        fontWeight: 500,
        display: 'flex',
        letterSpacing: 0.20,
        lineHeight: 1.6,
        justifyContent: 'flex-end',
        color: '#444657',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        }
    },
    buttonWrapper: {
        display: 'flex',
        margin: 0,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
        }
    },
    button: {
        paddingLeft: 28,
        paddingRight: 28,
        margin: '0 4px',
        fontWeight: 'normal',
        letterSpacing: 1.25,
        lineHeight: 1.6,
        textTransform: 'none',
        fontSize: 14,
        boxShadow: '0px 4px 3px -4px black',
        [theme.breakpoints.down('xs')]: {
            width: '75%',
        },
    },
    agreeButton: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1rem',
        },
    },
    closeButton: {
        [theme.breakpoints.down('xs')]: {
            margin: '1rem',
        },
    },
})

export default styles; 