import React, { Component } from 'react';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';

type WelcomeProps = {
    onEnterVault: any
    classes: any;
    title: any;
    description: any;
}

export const styles = (theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: '#fff',
        flex: 1,
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '2.3rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8rem',
        }
    },
    description: {
        fontSize: '1.3rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem'
        }
    },
    row: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    fabBtn: {
        width: 186,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
});

class Welcome extends Component<WelcomeProps> {

    render() {
        const { classes, title, description } = this.props;
        return (
            <div className={classes.root} data-test="Welcome">
                <div className={classes.titleContainer}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.description}>{description}</div>
                    <div className={classes.row}>
                        <Fab variant='extended' color='primary' className={classes.fabBtn} onClick={this.props.onEnterVault} >
                            Login
                    </Fab>
                        <Divider variant="middle" />
                    </div>
                </div>
            </div>)
    }
}

export default (withStyles(styles)(Welcome));