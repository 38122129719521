import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import * as InvitationActionTypes from '../../constants/personalVault/invitationConstants';
import { getInvitesAPI, updateInviteStatusAPI } from '../../../shared/core/utils/apiFunctions';

export function* invitesWatcher() {
    yield takeLatest(InvitationActionTypes.INVITATION_API_GET_INVITES_REQUEST, getInvitesSaga);
    yield takeLatest(InvitationActionTypes.INVITATION_API_UPDATE_INVITE_REQUEST, updateInviteSaga);
}

function* getInvitesSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        })

        if (userAccessInfo !== undefined) {
            const userCode = userAccessInfo.user_profile.userCode;
            let invites = yield call(
                getInvitesAPI, 
                userCode,
                action.pagination.direction, 
                action.pagination.orderBy, 
                action.pagination.page, 
                action.pagination.size 
            )
            yield put({
                type: InvitationActionTypes.INVITATION_API_GET_INVITES_SUCCESS,
                invites: invites
            });
        }

    } catch(err) {
        yield put({
            type: InvitationActionTypes.INVITATION_API_GET_INVITES_FAILURE,
            err
        })
    }
}

function* updateInviteSaga(action) {
    try {
        yield call (
            updateInviteStatusAPI,
            action.contactCode,
            action.updatedStatus
        );

        yield put({
            type: InvitationActionTypes.INVITATION_API_UPDATE_INVITE_SUCCESS,
            updatedInvite: action.contactCode
        })
        
    } catch(err) {
        yield put({
            type: InvitationActionTypes.INVITATION_API_UPDATE_INVITE_FAILURE,
            err
        })
    }
}

export default invitesWatcher;