import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { withRouter, RouteComponentProps } from 'react-router';
import './Fs.fileDetail.page.scss';
import PreviewWindow from './components/previewWindow/PreviewWindow';
import FileDetailMenu from './components/fileDetailMenu/FileDetailMenu';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as TopicDetailActionTypes from '../../../redux/constants/customerVault/topicConstants';
import * as TopicActionTypes from '../../../redux/constants/customerVault/topicConstants';
import ESignAgreement from '../../../shared/ui/signingCermony/enduser/esignAgreement/ESignAgreement';
import * as FilePreviewActionTypes from '../../../redux/constants/shared/filePreviewConstants';
import * as CeremonyTypes from '../../../redux/constants/shared/eSignConstants';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

interface TopicType {
    stCode: any,
    stName: any,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number,
}

interface FileDetailPageProps extends RouteComponentProps<{}> {
    selectedFileForPreview: any,
    menuOpen: boolean,
    selectedTopic: any;
    loggedIn: any;
    usertopicData: any;
    addBreadcrumbItem: any;
    getTopicDetails: any;
    topicData: any;
    selectTopic: any;
    selectedGroup: any;
    topicDetails: any;
    updateSnackbar: any;
    //redirectPath: any
    selectFileToPreview: any;
    onClose: any;
    updateCeremony: any;
    isCTAPreview?: boolean;
    cermonyDetails: any;
    showMessage: any;
}

type FileDetailPageStates = {}

class FsFileDetailPage extends Component<FileDetailPageProps, FileDetailPageStates> {

    state = {
        menuOpen: true,
        isEndUser: false,
        isMenuOpen: false
    }

    componentDidMount() {
        if (this.props.loggedIn.user_profile.roles.indexOf('USER') > -1) {
            this.setState({ isEndUser: true });
        }
    }

    componentDidUpdate(prevProps: any) {
        let topic = null;
        const { topicDetails } = this.props;
        if (prevProps.topicDetails.fetching != topicDetails.fetching && !topicDetails.fetching) {
            if (topicDetails.error == null && topicDetails.topicData != null) {
                topic = topicDetails.topicData;
            }
            if (topic != null) {
                this.props.selectTopic(topicDetails.topicData)
            }
            else {
                let errorCode = topicDetails.error.response.data.errCode;
                if (errorCode === 671 || errorCode === 705 || errorCode === 706) {
                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: "Invalid link. You've been redirected home."
                    })
                }
                if (this.state.isEndUser)
                    this.props.history.push('/customer/topics');
                else
                    this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/topics`)
            }
        }
    }

    openMenuToggle() {
        this.setState({ menuOpen: !this.state.menuOpen })
    }

    handleClick = () => {
        this.setState({ isMenuOpen: true })
    };

    handleDrawerClose = () => {
        this.setState({ isMenuOpen: false })
    }

    onActionComplete = (agree: boolean) => {
        let stcCode = this.props.cermonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode).stcCode;
        if (agree)
            this.props.updateCeremony(
                stcCode,
                "AGREED");
        else
            this.props.updateCeremony(
                stcCode,
                "DECLINED")
        this.props.selectFileToPreview(null);
        setTimeout(() => {
            this.props.showMessage();
        }, 300);
    }

    isCeremonyCancelled = () => {
        let ceremonyCancelled = false
        let cancelledCeremony = this.props.cermonyDetails.content.find((ceremony: any) => ceremony.status.toLowerCase() === 'cancelled');
        if (cancelledCeremony !== null && cancelledCeremony !== undefined)
            ceremonyCancelled = true
        return ceremonyCancelled
    }

    render() {
        let showCeremonyCancelledError = ( this.props.isCTAPreview === true && this.isCeremonyCancelled())
        let showAwaitingDocMessage = (this.props.selectedFileForPreview.ceremonyStatus == "WAITING_SIGNED_DOC")
        return (
            <div data-test='fileDetail-component' className='page-wrapper'>
                <div className='main-window'>
                    <PreviewWindow topicCode={this.props.selectedTopic[0].stCode} onClose={this.props.onClose}
                        isEndUser={this.state.isEndUser} showCeremonyCancelledError={showCeremonyCancelledError} showAwaitingDocMessage={showAwaitingDocMessage} />
                </div>
                <div className={this.props.menuOpen ? 'menu-wrapper-open' : 'menu-wrapper-close'}>
                    {this.props.menuOpen && <FileDetailMenu />}
                </div>
                <div>
                    {this.state.isEndUser && !showCeremonyCancelledError && this.props.selectedFileForPreview.ceremonyStatus === 'REQUESTED'
                        && this.props.isCTAPreview
                        && <ESignAgreement topicCode={this.props.selectedTopic[0].stCode}
                            selectedItem={this.props.selectedFileForPreview}
                            onActionComplete={this.onActionComplete} />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileForPreview: state.appUIStateReducer.selectedFileToPreview,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen,
        selectedTopic: state.appUIStateReducer.selectedTopic,
        topicData: state.topicReducer.topicList.topicData,
        usertopicData: state.topicReducer.userTopicList.products,
        loggedIn: state.login,
        topicDetails: state.topicReducer.topicDetails,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        cermonyDetails: state.eSignReducer.ceremonyDetails
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectFileToPreview: (item: any) => dispatch({ type: AppUIStateActionTypes.PREVIEW_FILE, selectedFile: item }),
        clearPreview: () => dispatch({
            type: FilePreviewActionTypes.TOPIC_FILES_API_VIEW_CLEAR,
        }),
        addBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.ADD_BREADCRUMB_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        getTopicDetails: (topicCode: string) => dispatch({
            type: TopicDetailActionTypes.TOPICS_API_GET_TOPICS_DETAILS_REQUEST,
            topicCode: topicCode,
        }),
        selectTopic: (topic: TopicType) => dispatch({ type: TopicActionTypes.TOPIC_API_SELECT_TOPIC, selectedTopic: topic }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        updateCeremony: (ceremonyCode: string, ceremonyStatus: string) =>
            dispatch({
                type: CeremonyTypes.CEREMONY_API_STATUS_CHANGE,
                ceremonyCode: ceremonyCode,
                ceremonyStatus: ceremonyStatus
            }),
    }
}

export const FsFileDetailPageComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(FsFileDetailPage);

export default withRouter(FsFileDetailPageComponent);

