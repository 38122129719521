const leftContainerWidth = 0;

const styles = theme => ({
    root: {
        zIndex: 100,
        position: 'relative',
        bottom:0,
        width:'100%',
        backgroundColor: theme.homerootbgcolor,
        marginLeft: leftContainerWidth,
        fontSize: 13,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        }
    },
    rootDashboard: {
        zIndex: 100,
        position: 'relative',
        backgroundColor: theme.homerootbgcolor,
        marginLeft: '75px',
        paddingTop: '5rem',
        fontSize: 13,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '3rem',
        }
    },
    content: {
        padding: '1rem',
        borderTop: '1px solid rgba(68, 70, 87, 0.25)',
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        lineHeight: 2,
        [theme.breakpoints.down('sm')]: {
            padding: '0.75rem 0.5rem',
            justifyContent: 'center',
        }
    },
    disclaimer: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
    },
    link: {
        fontSize: 12,
        color: '#005F7C',
        cursor: 'pointer',
    },
    policyLabel: {
        paddingRight: '0.5rem',
    },
    tosLabel: {
        paddingLeft: '0.5rem',
        borderLeft: '1px solid rgba(68, 70, 87, 0.25)'
    }
})

export default styles;