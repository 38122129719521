import { takeLatest, call, put } from "redux-saga/effects";
import {
    
    CREATE_FOLDER_API_CREATE_REQUEST, CREATE_FOLDER_API_CREATE_SUCCESS, CREATE_FOLDER_API_CREATE_FAILED,
} from '../../constants/personalVault/itemsConstants'
import { createFolderAPI } from '../../../shared/core/utils/apiFunctions'

export function* createTopicWatcher() {
 
  yield takeLatest(CREATE_FOLDER_API_CREATE_REQUEST, createFolderSaga)
  
}

function* createFolderSaga(action){
  try{
    const createFolder= yield call(createFolderAPI, action.folderName, action.currentFolderCode);
    yield put({type: CREATE_FOLDER_API_CREATE_SUCCESS, responseInfo: createFolder.data })
  } catch (error) {
      
    yield put({ type: CREATE_FOLDER_API_CREATE_FAILED, error });
  }
}


export default createTopicWatcher;
