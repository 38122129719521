import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import './PopUpContent.scss';

type cancelInviteRequestProps = {
    cancelConfirm: any,
    cancelIniviteRequestConfirm: any
}

class CancelInviteRequestContent extends Component<cancelInviteRequestProps, {}>{
    content: any = {
        iconLink: '',
        title: 'Cancel Request',
        body: 'Are you sure you want to Cancel Request',
        buttonOneLabel: 'Keep Request',
        buttonTwoLabel: 'Cancel Request'
    }

    render () {
        return (
            <>
                <DialogTitle id="common-modal-title" className="common-modal-title">
                    <span className="dialog-title">{ this.content.title }</span>
                </DialogTitle>
                <DialogContent className="common-modal-description-root">
                    <DialogContentText className="common-modal-description" id="common-modal-description">
                        <span className="description-content">{ this.content.body }</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="common-modal-actions">
                    <Button className="common-modal-button" variant="outlined" onClick={ this.props.cancelConfirm } disableElevation>
                        { this.content.buttonOneLabel }
                    </Button>
                    <Button className="common-modal-button" variant="contained" onClick={ this.props.cancelIniviteRequestConfirm } color="primary" disableElevation>
                        { this.content.buttonTwoLabel }
                    </Button>
                </DialogActions>
            </>
        )
    }
}

export default CancelInviteRequestContent;