import { 
  INVITE_VERIFICATION_REQUEST, INVITE_VERIFICATION_SUCCESS, INVITE_VERIFICATION_FAILURE,
  INVITE_VERIFICATION_ERROR_CLEAR
} from '../../constants/shared/inviteVerificationConstants'

const initialState = {
  inviteVerificationInfo: {
    response: null,
    fetching: null
  },
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case INVITE_VERIFICATION_REQUEST:
      return { ...state, inviteVerificationInfo: { fetching: true }}
    case INVITE_VERIFICATION_SUCCESS:
      return { ...state, inviteVerificationInfo: { fetching: false, response: {...action.responseInfo}}}
    case INVITE_VERIFICATION_FAILURE:
      return {...state, inviteVerificationInfo: { fetching: false, response: null }, error: 'Verification Failed. Please contact your Advisor.'}
    
    case INVITE_VERIFICATION_ERROR_CLEAR:
      return {...state, error: null}
    
    default:
      return state;
  }
}