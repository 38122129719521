import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fsPaginationContacts, fsPaginationGroups } from '../../../../shared/core/utils/paginationDefaults';
import {
    getActiveContactsAPI
    , getGroupsAPI
} from '../../../../shared/core/utils/apiFunctions';
import * as Types from '../../../../shared/core/ContactType';


type ContactListGroupSearchProps = {
    classes: any,
    loggedIn: any,
    handleChange: any,
    handleError: any,
    islarge: boolean
}

export const styles = (theme: any) => ({
    textfield: {
        width: '90%',
        height: 76,
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    formTextInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem'
        }
    },
    clear: {
        paddingRight: '40px !important'
    }
});


class ContactListGroupSearch extends Component<ContactListGroupSearchProps> {

    state = { loading: false, contacts: new Map() }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({ loading: true, contacts: new Map() })
        if (this.props.loggedIn.user_profile) {
            this.setState({ loading: true })
            //Read all the Groups 
            getGroupsAPI(this.props.loggedIn.user_profile.userCode, fsPaginationGroups.direction,
                fsPaginationGroups.orderBy, fsPaginationGroups.page, fsPaginationGroups.size)?.then((result: any) => {
                    var output: Map<string, Types.ContactType> = new Map();
                    result.data.content.map((contact: any) => {
                        var contactObj: Types.ContactType = new Types.ContactType();
                        contactObj.code = contact.cgCode;
                        contactObj.type = "GROUP";
                        contactObj.name = contact.cgName;
                        output.set(contactObj.code, contactObj)
                    })
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            contacts: new Map([...Array.from(this.state.contacts.entries()), ...Array.from(output.entries())]),
                        }
                    },
                        () => {
                            getActiveContactsAPI(this.props.loggedIn.user_profile.userCode, fsPaginationContacts.direction,
                                fsPaginationContacts.orderBy, fsPaginationContacts.page, fsPaginationContacts.size)?.then((result: any) => {
                                    var output: Map<string, Types.ContactType> = new Map();
                                    
                                    result.data.content.map((contact: any) => {
                                        var contactObj: Types.ContactType = new Types.ContactType();
                                        contactObj.code = contact.contactCode;
                                        contactObj.type = "USER";
                                        if (contact.contactLastName)
                                            contactObj.name = `${contact.contactFirstName} ${contact.contactLastName}`;
                                        else
                                            contactObj.name = `${contact.contactNickname}`;
                                        output.set(contactObj.code, contactObj)
                                    })

                                    this.setState(prevState => {
                                        return {
                                            ...prevState,
                                            contacts: new Map([...Array.from(this.state.contacts.entries()), ...Array.from(output.entries())]),
                                            loading: false
                                        }
                                    });
                                }).catch((err: any) => {
                                    //If it is 401 take the user back to login page.
                                    this.props.handleError(err)
                                    this.setState({ loading: false })
                                });
                        });

                }).catch((err: any) => {
                    //If it is 401 take the user back to login page.
                    this.props.handleError(err)
                });


        }
    }

    render() {
        const { classes, handleChange, islarge } = this.props;
        return (
            <div>
                <Autocomplete
                    id="grouped-demo"
                    options={Array.from(this.state.contacts.values())}
                    groupBy={option => option.type}
                    getOptionLabel={option => option.name}
                    loading={this.state.loading}
                    noOptionsText="There are no contacts yet. Please invite them using 'Add New > Invite a contact'."
                    onChange={(option: any, value: any) => handleChange(value)}
                    renderInput={params => <TextField className={classes.textfield} {...params}
                        label={islarge ? "Search by contact name or contact group" : "Search by contact or group"} variant="outlined"
                        InputLabelProps={{
                            classes: {
                                root: classes.formTextInput
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }} />}
                />
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.login,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ContactListGroupSearch));
