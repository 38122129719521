import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogActions, Button, Checkbox } from '@material-ui/core';
import * as CeremonyTypes from '../../../../../redux/constants/shared/eSignConstants';
import { AppBar } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

interface ESignAcceptprops extends RouteComponentProps<{}> {
    history: any,
    selectedItem: any,
    topicCode: any,
    onActionComplete: any,
    ceremonyStatusChange?: any,
    classes?: any
}

export const styles = (theme: any) => ({

    agreeAppbar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#FFFFFF'
    },
    agreeTitle: {
        fontFamily: theme.homefontfamilyregular,
        fontSize: '16px',
        letterSpacing: '0',
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 1)',
        padding: '0px 10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
            padding: '10px 10px'
        }
    },
    agreeSection: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        padding: '2rem',
        //paddingTop: '2%',
        //paddingBottom: '2%',
        //paddingLeft: '2%',
        //paddingRight: '4%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            //height: '190px'
        }
    },
    agreeFirst: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    agreePrimarybtn: {
        textTransform: 'none' as 'none',
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            textTransform: 'none' as 'none',
            fontSize: '0.8rem'
        }
    },
    agreeCheckboxsection: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    agreeCommonModalActions: {
        justifyContent: 'center',
        //paddingTop: '2%'
    },
    spacer:
    {
        paddingRight: '1rem'
    }

    /* @media only screen and(max - width: 767px) {
            .agree - title {
            font - size: 10px;
            padding: 10px 10px;
        }
            .agree - section {
            flex - direction: column;
            height: 190px;
        }
            .agree - primarybtn {
            text - transform: none!important;
            font - size: 0.8rem!important;
        }
    }
          */

});

class ESignAgreement extends Component<ESignAcceptprops> {

    state = {
        hasAgreed: false
    }

    agreeTerms = () => {
        this.setState({ hasAgreed: !this.state.hasAgreed })
    }

    render(): JSX.Element {
        const { classes } = this.props;
        return (
            <div data-test='agreementmenu'>
                <AppBar position="fixed" color="secondary" className={classes.agreeAppbar}>
                    <div className={classes.agreeSection}>
                        <div className={classes.agreeFirst}>
                            <div className={classes.agreeCheckboxsection}>
                                <Checkbox color='primary'
                                    checked={this.state.hasAgreed}
                                    onChange={this.agreeTerms} />
                            </div>
                            <div className={classes.agreeTitle}>
                               By recording your response, “Agree” or “Decline”, you’re electronically authorizing the document and indicating that you have received and understand the information and disclosures within. If you’re unable to view the document, or don’t understand the information, please exit now and contact Fidelity.</div>
                        </div>
                        <DialogActions className={classes.agreeCommonModalActions}>
                            <Button
                                id="sendrequest"
                                className={classes.agreePrimarybtn}
                                variant="contained"
                                data-test="requestbtn"
                                onClick={() => this.props.onActionComplete(false)}
                                disabled={!this.state.hasAgreed}
                                disableElevation>
                                DECLINE
                            </Button>
                            <div className={classes.spacer}> </div>
                            <Button
                                id="sendrequest"
                                className={classes.agreePrimarybtn}
                                variant="contained"
                                data-test="acceptBtn"
                                onClick={() => this.props.onActionComplete(true)}
                                color="primary"
                                disabled={!this.state.hasAgreed}
                                disableElevation>
                                AGREE
                            </Button>
                        </DialogActions>
                    </div>
                </AppBar>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        ceremonyStatusChange: () => dispatch({
            type: CeremonyTypes.CEREMONY_API_STATUS_CHANGE,
        })
    };
};

export const ESignAgreementWithoutRouterComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(ESignAgreement)

export default withRouter(withStyles(styles)(ESignAgreementWithoutRouterComponent));
