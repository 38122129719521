import React from 'react';
import './login.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './css/loginStyles';
import { Paper, CircularProgress } from '@material-ui/core';
import { selectMessages } from './selector';
import { loginRequesting } from './actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoginContent from './components/Login';
import { HOME_API_GET_PRODUCT_REQUEST, HOME_API_CONSENT_REQUEST } from '../../../redux/constants/shared/homeConstants';
import { base_path } from '../../../shared/config/basePath'
import { FETCH_TRM_OF_SVC_REQUEST } from '../../../redux/constants/shared/legalsConstants';
import TermsOfService from './components/TermsOfService';
import { optionHandle } from '../../../shared/core/utils/helper';
import ProgressBar from '../../../shared/core/utils/linear-query'
import Error from '../../../shared/errors/Error';
import ReactGA from 'react-ga';
import { con } from '../../../shared/config/config';
import * as AppStateActionTypes from '../../../redux/constants/shared/appStatesConstants';

export class Login extends React.Component {

	state = {
		consentLoaded: false,
		redirecting: false
	}

	initReactGA = (userId) => {
		this.props.intializeGAConfig(this.props.GAConfig)
		// Send initial test view
		ReactGA.initialize(this.props.GAConfig);
		ReactGA.pageview('LoginPage');
	};

	componentDidMount() {
		const { loggedIn } = this.props;
		if (loggedIn && loggedIn.user && loggedIn.exp > Date.now() / 1000) {
			//reset to null, if already logged in
			this.props.fetchProductInfo();
			//if (!this.props.reactGaInitialised)
			this.initReactGA(loggedIn.user_profile.userCode)
		}
		else if(!loggedIn.requesting) {
			this.handleRedirect();
		}
	}

	componentDidUpdate(prevProps) {
		const { loggedIn } = this.props;
		
		if (prevProps.loggedIn && !prevProps.loggedIn.user_profile && this.props.loggedIn.user_profile) {
			this.props.fetchProductInfo();
			//if (!this.props.reactGaInitialised)
				this.initReactGA(this.props.loggedIn.user_profile.userCode)
		}
		
		if(prevProps.loggedIn.requesting && !loggedIn.requesting && !loggedIn.user_profile) {
			this.handleRedirect();
		}

		if (prevProps.productFetching && !this.props.productFetching) {
			if (this.checkNotConsentFlag()) this.props.fetchTrmsOfSvcContent();
		}

		if (prevProps.consentLoading && !this.props.consentLoading && !this.props.userTrmSvcError) {
			const { loggedIn } = this.props;
			if (loggedIn && loggedIn.user && loggedIn.exp > Date.now() / 1000) {
				this.setState({ consentLoaded: true });
				// window.location = discriminator === 'advisor' ? '/auth-login/advisor' : '/auth-login';
			}
		}
	}

	handleRedirect() {
		const { loggedIn } = this.props;
		if(loggedIn.logoutresponse?.data?.redirect) {
			this.setState({redirecting : true})
			window.location = loggedIn.logoutresponse.data.redirect;
		}
		else {
			const redirectDescriminator = con.REDIRECTION;
			const { discriminator, unauthorized } = this.props;
			if(redirectDescriminator !== null && redirectDescriminator !== undefined) {
				if(redirectDescriminator === 'clientvault') {
					var deepLinkPath = '';
					const pathComponents = window.location.pathname.split("/")
					if (pathComponents.includes('topics')) {
						deepLinkPath = 'topics'
					} else if (pathComponents.includes('topic-detail')) {
						deepLinkPath = 'topicdetails/' + pathComponents[pathComponents.length - 1];
					}
					const clientVaultAppPath = con.DIGITAL_HOST + '/ftgw/digital/clientvault/' + deepLinkPath;
					window.location.replace(clientVaultAppPath);
					this.setState({redirecting : true})
				}
				else {
					if(discriminator === undefined && unauthorized === undefined) {
						this.setState({redirecting : true})
						window.location.href = `auth-login/${redirectDescriminator}`;
					}
				}
			}
		}
	}

	handleCloseTos(consentFlag) {
		this.props.consentToTrmSvc(consentFlag);
	}

	renderProgressBar() {
		return <ProgressBar></ProgressBar>
	}

	renderCircularProgressBar() {
        const { classes } = this.props;
        return <div className={classes.loaderholder} >
            <Paper elevation={3} className={classes.loader}>
                <CircularProgress />
                <div className={classes.loadingtext}>Loading...</div>
            </Paper>
        </div>
    }

	checkNotConsentFlag() {
		let currTrmSvcVersion, consentFlag, consentVersion;
		if (this.props.productInfo && this.props.productInfo.data.prdName !== '' && this.props.loggedIn.user_profile) {
			currTrmSvcVersion = this.props.productInfo.data.trmSvcVersion;
			consentFlag = this.props.loggedIn.user_profile.consentFlag;
			consentVersion = this.props.loggedIn.user_profile.trmSvcVersion;

			return (currTrmSvcVersion && !(consentFlag && currTrmSvcVersion === consentVersion))
		}

		return true  // prevent the app from going to home page before prd info is loaded
	}

	render() {

		const { onSubmit, loggedIn, location, userAccessInfoFetching,
			productFetching, productInfoError, userAccessInfoError, unauthorized, discriminator } = this.props;

		if (unauthorized)
			return <LoginContent unauthorized={true} data-test="loginComponent"/>

		if (loggedIn && loggedIn.user && loggedIn.exp > Date.now() / 1000) {
			if (this.state.consentLoaded) {
				return <Redirect to={base_path} />;
			}

			if (userAccessInfoFetching || productFetching) return this.renderProgressBar()

			if (userAccessInfoError || productInfoError) {
				return <Error
					data-test="errorSec"
					error={userAccessInfoError || productInfoError}
					loggedIn={loggedIn}
				/>
			}

			const roles = this.props.loggedIn.user_profile.roles;
			const validUsers = roles.includes('INST_USER') || roles.includes('USER');
			if (validUsers && this.checkNotConsentFlag()) {
				return <TermsOfService
					title='Terms of Service'
					showAction={true}
					declineCallback={() => optionHandle('Log Out')}
					handleCloseCallback={() => this.handleCloseTos(true)} />
			}

			const { from } = (location && location.state) || { from: { pathname: '/' } }
			return <Redirect to={from} />
		} else {

			const props = {
				onSubmit
			};
			
			return (!loggedIn.requesting && !this.state.redirecting) ? (
				(loggedIn.logoutresponse?.data?.redirect == null) && <LoginContent {...props} />
			) : (this.state.redirecting) ? this.renderCircularProgressBar() : this.renderProgressBar();
		}
	}
}

Login.propTypes = {
	onSubmit: PropTypes.func,
	messages: PropTypes.array
};

const mapStateToProps = (state) => ({
	messages: selectMessages(state),
	loggedIn: state.login,
	productInfo: state.homeReducer.productInfo.response,
	consentLoading: state.homeReducer.userTrmSvcInfo.fetching,
	userAccessInfoFetching: state.homeReducer.userAccessInfo.fetching,
	productFetching: state.homeReducer.productInfo.fetching,
	productInfoError: state.homeReducer.productInfo.error,
	userAccessInfoError: state.homeReducer.userAccessInfo.error,
	userTrmSvcError: state.homeReducer.userTrmSvcInfo.error,
	features: state.homeReducer.features,
	GAConfig: state.appUIStateReducer.GAConfig,
	reactGaInitialised: state.appUIStateReducer.reactGaInitialised
});

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (value) => {
		dispatch(loginRequesting(value));
	},
	fetchProductInfo: () => dispatch({ type: HOME_API_GET_PRODUCT_REQUEST }),
	fetchTrmsOfSvcContent: () => dispatch({ type: FETCH_TRM_OF_SVC_REQUEST }),
	consentToTrmSvc: (consentFlag) => dispatch({ type: HOME_API_CONSENT_REQUEST, consentFlag }),
	intializeGAConfig: (GAConfig) => dispatch({ type: AppStateActionTypes.UPDATE_GACONFIG, GAConfig })
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));

//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Home));
