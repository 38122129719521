import { combineReducers } from 'redux';
import * as TopicFileActionTypes from '../../constants/customerVault/topicFileConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';


const initState = {
    topicFiles: {
        fetching: false,
        lazyloading: false,
        files: {
            content: [],
            page: {},
            sort: []
        },
        error: null
    },
    downloadInfo:
    {
        files: []
    },
    deleteInfo: {
        response: null,
        fetching: false,
        error: null
    },
    filesUpload:
    {
        files: []
    },
    smartIntake:
    {
        fetching: false,
        userLink: null,
        error: null
    },
    
    fileActivities:
    {
        fetching:false,
        activities:null,
        error:null
    }
}

export default (state = initState, action) => {
    switch (action.type) {
        // Get Contacts
        case TopicFileActionTypes.TOPIC_FILES_API_GET_REQUEST:
            return {
                ...state,
                topicFiles: {
                    ...state.topicFiles,
                    fetching: true
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_GET_SUCCESS:
            return {
                ...state,
                topicFiles: {
                    fetching: false,
                    files: { ...action.response.data }
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_GET_FAILURE:
            return {
                ...state,
                topicFiles: {
                    fetching: false,
                    error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
                }
            };

        // Handling LazyLoading
        case TopicFileActionTypes.TOPIC_FILES_API_LOAD_MORE_START:
            return {
                ...state,
                topicFiles: {
                    ...state.topicFiles,
                    lazyloading: true
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_LOAD_MORE_REQUEST:
            return {
                ...state,
                topicFiles: {
                    ...state.topicFiles,
                    lazyloading: true
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_LOAD_MORE_SUCCESS:
            return {
                ...state,
                topicFiles: {
                    lazyloading: false,
                    files: {
                        ...state.topicFiles.files,
                        content: [
                            ...state.topicFiles.files.content,
                            ...action.response.data.content,
                        ],
                        page: action.response.data.page,
                        sort: action.response.data.sort,
                    }
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_LOAD_MORE_FAILURE:
            return {
                ...state,
                topicFiles: {
                    lazyloading: false,
                    error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
                }
            };

        case TopicFileActionTypes.TOPIC_FILES_API_SINGLE_FILE_DOWNLOAD_REQUEST:
        case TopicFileActionTypes.TOPIC_FILES_API_MULTIPLE_FILE_DOWNLOAD_REQUEST:
            var file =
            {
                downloading: true,
                downloadId: action.downloadId,
                fileName: action.fileName,
                error: null,
            }
            return {
                ...state,
                downloadInfo:
                {
                    files: [...state.downloadInfo.files, file]
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloadInfo:
                {
                    files: state.downloadInfo.files.map((item) => (
                        item.downloadId === action.downloadId ? {
                            ...item,
                            downloading: false, //change
                            error: null
                        } : item
                    ))
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE:
            return {
                ...state,
                downloadInfo:
                {
                    files: state.downloadInfo.files.map((item) => (
                        item.downloadId === action.downloadId ? {
                            ...item,
                            error: action.error.response != null ? action.error.response.data : {},
                            downloading: false
                        } : item
                    ))
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_DELETE:
            return {
                ...state,
                downloadInfo:
                {
                    files: state.downloadInfo.files.filter((item) => (
                        item.downloadId != action.downloadId
                    ))
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_DELETE_FILE_REQUEST:
            return {
                ...state,
                deleteInfo: {
                    fetching: true,
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_DELETE_FILE_SUCCESS:
            return {
                ...state,
                deleteInfo: {
                    fetching: false,
                    error: null
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_DELETE_FILE_FAILURE:
            return {
                ...state,
                deleteInfo: {
                    fetching: false,
                    error: { ...action.error }
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_UPLOAD_FILE_REQUEST:
            var file =
            {
                uploading: true,
                progress: 0,
                fileId: action.fileId,
                itemInfo: {
                    title: action.file.name,
                    size: action.file.size
                },
                error: null,
            }
            return {
                ...state,
                filesUpload:
                {
                    files: [...state.filesUpload.files, file]
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_UPLOAD_FILE_PROGRESS:
            return {
                ...state,
                filesUpload:
                {
                    files: state.filesUpload.files.map((item) => (
                        item.fileId === action.fileId ? {
                            ...item,
                            progress: action.progress,
                        } : item
                    ))
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                filesUpload:
                {
                    files: state.filesUpload.files.map((item) => (
                        item.fileId === action.fileId ? {
                            ...item,
                            progress: 100,
                            itemInfo: action.response,
                            uploading: false, //change
                            error: null
                        } : item
                    ))
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_UPLOAD_FILE_FAILURE:
            return {
                ...state,
                filesUpload:
                {
                    files: state.filesUpload.files.map((item) => (
                        item.fileId === action.fileId ? {
                            ...item,
                            error: action.error.response != null ? action.error.response.data : {},
                            uploading: false
                        } : item
                    ))
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_UPLOAD_ADD_FILE:
            var file =
            {
                uploading: false,
                fileId: action.fileId,
                itemInfo: {
                    title: action.file.name,
                    size: action.file.size
                },
                error: { errMsg: action.errMsg },
            }
            return {
                ...state,
                filesUpload:
                {
                    files: [...state.filesUpload.files, file]
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_UPLOAD_CLEAR:
            return {
                ...state,
                filesUpload:
                {
                    files: []
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_SMART_FORM_REQUEST:
            return {
                ...state,
                smartIntake:
                {
                    fetching: true,
                    userLink: null,
                    error: null
                }
            };

        case TopicFileActionTypes.TOPIC_FILES_API_SMART_FORM_FAILURE:
            return {
                ...state,
                smartIntake:
                {
                    fetching: false,
                    userLink: null,
                    error: action.error
                }
            };
        case TopicFileActionTypes.TOPIC_FILES_API_SMART_FORM_SUCCESS:
            return {
                ...state,
                smartIntake:
                {
                    fetching: false,
                    userLink: action.userLink,
                    error: null
                }
            };
            case TopicFileActionTypes.TOPIC_FILES_API_ACTIVITY_REQUEST:
                return {
                    ...state,
                    fileActivities: {
                        ...state.fileActivities,
                        fetching: true
                    }
                };
            case TopicFileActionTypes.TOPIC_FILES_API_ACTIVITY_SUCCESS:
                return {
                    ...state,
                    fileActivities: {
                        fetching: false,
                        activities: { ...action.response }
                    }
                };
            case TopicFileActionTypes.TOPIC_FILES_API_ACTIVITY_FAILURE:
                return {
                    ...state,
                    fileActivities: {
                        fetching: false,
                        error: { ...action.error }
                    }
                };
    
        default:
            return state;
    }
}
