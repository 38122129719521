export const styles = (theme: any) => ({
    
    root: {
        margin: theme.spacing(4),
        height: '50vh'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px',
        borderRadius: '3px'
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    successPaper: {
        position: 'absolute' as 'absolute',
        minWidth: '320px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    successDownloadPaper: {
        position: 'absolute' as 'absolute',
        minWidth: '320px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
    },
    heading: {
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        fontSize: '17px',
    },
    deleteheading: {
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        fontSize: '17px',
        textAlign: 'center' as 'center'
    },
    iconHeading: {
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        fontSize: '17px',
        width: '100%',
    },
    iconSection: {
        textAlign: 'center' as 'center',
        color: 'green'
    },
    successMessage: {
        fontFamily: 'Fidelity Sans',
        fontSize: '24px',
        justifyContent: 'center',
        textAlign: 'center' as 'center',
        marginBottom: '40px'
    },
    successDownloadMessage: {
        fontFamily: 'Fidelity Sans',
        fontSize: '18px',
        color: '#000000',
        justifyContent: 'center',
        textAlign: 'center' as 'center',
        marginBottom: '40px'
    },
    folderIcon: {
        float: 'left' as 'left',
        marginTop: '5px'
    },
    warningIcon: {
        marginTop: '5px',
        color: '#A81C1F',
        height: '41px',
        width: '40px'
    },
    deleteTextTitle: {
        fontFamily: 'Fidelity Sans',
        fontSize: '24px',
        textAlign: 'left' as 'left',
        margin: 0,
        // [theme.breakpoints.down('sm')]: {
        //     width: '200px',
        //     height: '150px',
        //   },
    },
    deleteTextContent: {
        fontSize: '16px',
        padding: '25px 0 13px 0',
    },
    sucessIcon: {
        fontSize: '40px'
    },
    folderText: {
        fontSize: '24px',
        marginTop: '0',
        fontFamily: 'Fidelity Sans',
        marginBottom: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        }
    },
    closeBtn: {
        position: 'absolute' as 'absolute',
        top: '0',
        right: '0',
        color: '#444657'
    },
    buttons: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        width: '100%'
    },
    buttonCancel: {
        border: '1px solid',
        float: 'left' as 'left',
        borderColor: theme.fabbuttonmobilebgcolor,
        textTransform: 'none' as 'none',
        padding: '8px 16px',
        margin: '0px 5px',
        [theme.breakpoints.down('xs')]: {

        },
    },
    buttonSave: {

        backgroundColor: theme.fabbuttonmobilebgcolor,
        "&:hover": {
            backgroundColor: theme.fabbuttonmobilehovercolor
        },
        textTransform: 'none' as 'none',
        margin: '0px 5px',
        [theme.breakpoints.down('xs')]: {

        },
    },
    alert: {
        color: 'red',
        marginTop: '0px'
    },
    breadcrumb: {
        marginLeft: '10px',
        marginBottom: '0px',
    },
    createFolderModalView: {
        position: 'absolute' as 'absolute',
        width: '429px',
        top: '40%',
        left: '40%',
        marginLeft: '-108px',
        // [theme.breakpoints.down('md')]: {
        // left: '40%'
        // },
        [theme.breakpoints.down('xs')]: {
            width: '290px'
        },
    },
    errorMessage: {
        color: 'red',
        fontSize: '12px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '10px',
        }
    },
    inputRoot: {
        '& > *': {
            width: '385px',
            height: '48px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.down('xs')]: {
                margin: '0'
            }
        },
    },
});

