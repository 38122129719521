export const CEREMONY_API_CREATION = 'CEREMONY_API_CREATION';
export const CEREMONY_API_CREATION_SUCCESS = 'CEREMONY_API_CREATION_SUCCESS';
export const CEREMONY_API_CREATION_FAILURE = 'CEREMONY_API_CREATION_FAILURE';

export const CEREMONY_API_GET_TYPES = 'CEREMONY_API_GET_TYPES';
export const CEREMONY_API_GET_TYPES_SUCCESS = 'CEREMONY_API_GET_TYPES_SUCCESS';
export const CEREMONY_API_GET_TYPES_FAILURE = 'CEREMONY_API_GET_TYPES_FAILURE';

export const CEREMONY_API_GET_DETAILS = 'CEREMONY_API_GET_DETAILS';
export const CEREMONY_API_GET_DETAILS_SUCCESS = 'CEREMONY_API_GET_DETAILS_SUCCESS';
export const CEREMONY_API_GET_DETAILS_FAILURE = 'CEREMONY_API_GET_DETAILS_FAILURE';

export const CEREMONY_API_CANCEL_INITIATION = 'CEREMONY_API_CANCEL_INITIATION';
export const CEREMONY_API_CANCEL_INITIATION_SUCCESS = 'CEREMONY_API_CANCEL_INITIATION_SUCCESS';
export const CEREMONY_API_CANCEL_INITIATION_FAILURE = 'CEREMONY_API_CANCEL_INITIATION_FAILURE';

export const CEREMONY_API_INITIATION = 'CEREMONY_API_INITIATION';
export const CEREMONY_API_INITIATION_SUCCESS = 'CEREMONY_API_INITIATION_SUCCESS';
export const CEREMONY_API_INITIATION_FAILURE = 'CEREMONY_API_INITIATION_FAILURE';
export const CEREMONY_API_INITIATION_CLEAR_TOKEN = 'CEREMONY_API_INITIATION_CLEAR_TOKEN'

export const CEREMONY_API_STATUS_CHANGE = 'CEREMONY_API_STATUS_CHANGE';
export const CEREMONY_API_STATUS_CHANGE_SUCCESS = 'CEREMONY_API_STATUS_CHANGE_SUCCESS';
export const CEREMONY_API_STATUS_CHANGE_FAILURE = 'CEREMONY_API_STATUS_CHANGE_FAILURE';

export const GET_ESIGN_FORM_TYPES = 'GET_ESIGN_FORM_TYPES';
export const GET_ESIGN_FORM_TYPES_SUCCESS = 'GET_ESIGN_FORM_TYPES_SUCCESS';
export const GET_ESIGN_FORM_TYPES_FAILURE = 'GET_ESIGN_FORM_TYPES_FAILURE';

export const GET_CEREMONY_DISCLOSURES = 'GET_CEREMONY_DISCLOSURES';
export const GET_CEREMONY_DISCLOSURES_SUCCESS = 'GET_CEREMONY_DISCLOSURES_SUCCESS';
export const GET_CEREMONY_DISCLOSURES_FAILURE = 'GET_CEREMONY_DISCLOSURES_FAILURE';

export const GET_FORM_DISCLOSURE = 'GET_FORM_DISCLOSURE';
export const GET_FORM_DISCLOSURE_SUCCESS = 'GET_FORM_DISCLOSURE_SUCCESS';
export const GET_FORM_DISCLOSURE_FAILURE = 'GET_FORM_DISCLOSURE_FAILURE';

export const CEREMONY_API_CLEAR_DETAILS = 'CEREMONY_API_CLEAR_DETAILS';