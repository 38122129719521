import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { styles } from './DeleteItemStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ITEM_API_DELETE } from '../../../../../redux/constants/personalVault/itemsConstants';

type DeleteItemProps = {
    onActionComplete: any,
    handleModalClose:any,
    selectedItems: any,
    currentFolder: any,
    classes: any,
    deleteItemResponse: any,
    deleteItemProgress: any,
    deleteItemAPI: any,
    children?: never[]
}

class DeleteItem extends Component<DeleteItemProps> {

    state = {
        actionButton: 'Delete',
        errorMessage: '',
    }

    componentDidUpdate(prevProps: any) {
        const { deleteItemProgress, deleteItemResponse, currentFolder } = this.props;
        if (deleteItemProgress !== prevProps.deleteItemProgress && !deleteItemProgress) {
            if (deleteItemResponse !== null)
                this.props.onActionComplete(true, 'Your selected item(s) has been deleted successfully.');
            else
                this.props.onActionComplete(false, 'Your selected item(s) not deleted.please try again.');
        }
    }

    handleCancelClick = (event: any) => {
        this.props.handleModalClose();
    }

    handleConfirmClick = () => {
        const itemCodes = this.props.selectedItems.map((i: any) => i.itemCode);
        this.props.deleteItemAPI(this.props.currentFolder.itemCode, itemCodes)
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.createFolderModalView}>
                <div className={classes.paper}>
                    <div>
                        <h2 className={classes.deleteTextTitle}>Delete File(s)</h2>
                        <IconButton aria-label="close-btn" className={classes.closeBtn} onClick={this.handleCancelClick}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </div>
                    <div className={classes.deleteTextContent}> Are you sure you want to delete the selected Item(s) ?</div>
                    <div className={classes.buttons}>
                        <Button onClick={this.handleCancelClick} className={classes.buttonCancel}>Cancel</Button>
                        <Button className={classes.buttonSave} onClick={this.handleConfirmClick} variant="contained" color="primary">{this.state.actionButton}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        deleteItemProgress: state.itemsReducer.deleteItem.deleting,
        deleteItemResponse: state.itemsReducer.deleteItem.response,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteItemAPI: (folderCode: any, itemCodes: any) => dispatch({ type: ITEM_API_DELETE, folderCode, itemCodes }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeleteItem));