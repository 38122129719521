import { combineReducers } from 'redux';
import * as ContactDetailActionTypes from '../../constants/personalVault/contactDetailConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';
import groupItemsSharedByMe from './groupDetailRedux';

const initState = {
    itemsSharedByMe: {
        fetching: false,
        files: [],
        error: null
    },
    itemsSharedWithMe: {
        fetching: false,
        files: [],
        error: null
    }
}

function itemsSharedByMe (state = initState.itemsSharedByMe, action) {
    switch (action.type) {
        case ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST:
            return {
                ...state,
                fetching: true,
                files: []
            };
        case ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_SUCCESS:
            return {
                ...state,
                fetching: false,
                files: action.files
            };
        case ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_BY_ME_FAILURE:
            return {
                ...state,
                fetching: false,
                files: [],
                error: {
                    ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND
                }
            };
        default:
            return state;
    }
}

function itemsSharedWithMe (state = initState.itemsSharedWithMe, action) {
    switch (action.type) {
        case ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_REQUEST:
            return {
                ...state,
                fetching: true,
                files: []
            };
        case ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_SUCCESS:
            return {
                ...state,
                fetching: false,
                files: action.files
            };
        case ContactDetailActionTypes.CONTACT_DETAIL_GET_ITEM_SHARED_WITH_ME_FAILURE:
            return {
                ...state,
                fetching: false,
                files: [],
                error: {
                    ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND
                }
            };
        default:
            return state;
    }
}

export default combineReducers({
    itemsSharedByMe,
    itemsSharedWithMe,
    groupItemsSharedByMe
});