import React, { Component } from 'react';
import { CommonCollapse, CommonModal } from '../../../shared/ui';
import { fsPaginationContacts } from '../../../shared/core/utils/paginationDefaults';
import IgnoreInviteRequestContent from './IgnoreInviteRequest';
import * as InvitationActionTypes from '../../../redux/constants/personalVault/invitationConstants';
import * as ContactActionTypes from '../../../redux/constants/personalVault/contactsConstants';
import { connect } from 'react-redux';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';

type InvitationPanelProps = {
    invitesData: any,
    getInvites: any,
    updateInvite: any,
    getContacts: any,
    updateSnackbar: any
}

type InvitationPanelState = {
    modalOpenState: boolean,
    pagination: PaginationType
}

interface PaginationType {
    page: any;
    size: any;
    direction: string;
    orderBy: string;
}

interface PendingContact {
    createdAt: string;
    modifiedAt: string;
    contactCode: string;
    contactFirstName: string;
    contactLastName: string;
    contactNickname: string;
    contactEmail: string;
    status: string;
    statusUpdated: string;
}

interface InvitedContact {
    contactCode: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    modifiedAt: string;
}

const InvitationPanelContent = {
    title: 'INVITATIONS'
}

class InvitationPanel extends Component<InvitationPanelProps, InvitationPanelState> {
    state = { 
        modalOpenState: false,
        pagination: {
            page: fsPaginationContacts.page,
            size: fsPaginationContacts.size,
            direction: fsPaginationContacts.direction,
            orderBy: fsPaginationContacts.orderBy
        }
    }
    
    modalContent: any;

    componentDidMount() {
        this.props.getInvites( this.state.pagination )
    }

    invitationDataProc( data: PendingContact[]) {
        let invitedContacts : InvitedContact[] = [];
        data.forEach( contact => {
            invitedContacts.push({
                contactCode: contact.contactCode,
                contactFirstName: contact.contactFirstName,
                contactLastName: contact.contactLastName,
                contactEmail: contact.contactEmail,
                modifiedAt: contact.modifiedAt
            })
        })
        return invitedContacts
    }

    btnOneOnClick = ( contactCode: string) => {
        this.setState({ modalOpenState: true });
        this.modalContent = 
            <IgnoreInviteRequestContent 
                cancelConfirm = { this.cancelConfirm }
                cancelIniviteRequestConfirm = { () => this.ignoreInviteConfirm( contactCode ) }/>
    }

    btnTwoOnClick = ( contactCode: string ) => {
        this.updateInvitesAndContacts(contactCode);
    }   

    updateInvitesAndContacts = ( contactCode: string ) => {
        Promise.resolve(this.props.updateInvite(contactCode, true)).then(
           () => {
            this.props.updateSnackbar({
                modalOpen: true,
                content: `Invite from ${this.findContactName(contactCode)} has accepted.`
            })
            setTimeout(() => { this.props.getContacts() }, 500)
           }
        )
    }

    modalClose = () => {
        this.setState({ modalOpenState: false });
    }

    cancelConfirm = () => {
        this.modalClose();
    }

    ignoreInviteConfirm = (contactCode: string) => {
        this.modalClose();
        this.props.updateInvite(contactCode, false);
        this.props.updateSnackbar({
            modalOpen: true,
            content: `Invite from ${this.findContactName(contactCode)} has been ignored.`
        })
    }

    findContactName = (contactCode: string) => {
        const contact =  this.props.invitesData.invitesData.content.filter((contact: any) => {
            return contact.contactCode === contactCode
        })[0]

        return contact.contactFirstName + ' ' + contact.contactLastName;
    }

    render() {
        return (
            <>
                { this.props.invitesData.invitesData.content.length > 0 && 
                    <CommonCollapse 
                        title = { InvitationPanelContent.title }
                        fetching = { this.props.invitesData.fetching }
                        data = { this.invitationDataProc( this.props.invitesData.invitesData.content )}
                        btnOneOnClick={ ( id: string ) => this.btnOneOnClick( id ) } 
                        btnTwoOnClick={ ( id: string ) => this.btnTwoOnClick( id ) }
                    />
                }
                <CommonModal modalOpen = { this.state.modalOpenState }
                    modalClose = { this.modalClose }
                    content = { this.modalContent }
                />
            </>
        ) 
    }
}

const mapStateToProps = ( state: any ) => {
    return {
        invitesData: state.invitesReducer.invites
    }
}

const mapDispatchToProps = ( dispatch: any ) => {
    return {
        getInvites: ( pagination: PaginationType ) => dispatch({
            type: InvitationActionTypes.INVITATION_API_GET_INVITES_REQUEST,
            pagination: pagination
        }),
        updateInvite: ( contactCode: string, updatedStatus: boolean ) => dispatch({
            type: InvitationActionTypes.INVITATION_API_UPDATE_INVITE_REQUEST,
            contactCode: contactCode,
            updatedStatus: updatedStatus
        }),
        getContacts: () => dispatch({
            type: ContactActionTypes.CONTACT_API_GET_CONTACTS_REQUEST,
            pagination: fsPaginationContacts
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvitationPanel);