
//Get all the users associated with the topic
export const USER_API_GET_TOPIC_USERS_REQUEST = 'USER_API_GET_TOPIC_USERS_REQUEST';
export const USER_API_GET_TOPIC_USERS_SUCCESS = 'USER_API_GET_TOPIC_USERS_SUCCESS';
export const USER_API_GET_TOPIC_USERS_FAILURE = 'USER_API_GET_TOPIC_USERS_FAILURE';

//Get all Categories for the topic
export const TOPICS_API_GET_CATEGORIES_REQUEST = 'TOPICS_API_GET_CATEGORIES_REQUEST';
export const TOPICS_API_GET_CATEGORIES_SUCCESS = 'TOPICS_API_GET_CATEGORIES_SUCCESS';
export const TOPICS_API_GET_CATEGORIES_FAILURE = 'TOPICS_API_GET_CATEGORIES_FAILURE';

//Edit the user list associated with the topic
export const USER_API_EDIT_TOPIC_USERS_REQUEST = 'USER_API_EDIT_TOPIC_USERS_REQUEST';
export const USER_API_EDIT_TOPIC_USERS_SUCCESS = 'USER_API_EDIT_TOPIC_USERS_SUCCESS';
export const USER_API_EDIT_TOPIC_USERS_FAILURE = 'USER_API_EDIT_TOPIC_USERS_FAILURE';

//Set metadata for the topic
export const TOPICS_API_ADD_METADATA_REQUEST = 'TOPICS_API_ADD_METADATA_REQUEST';
export const TOPICS_API_ADD_METADATA_SUCCESS = 'TOPICS_API_ADD_METADATA_SUCCESS';
export const TOPICS_API_ADD_METADATA_FAILURE = 'TOPICS_API_ADD_METADATA_FAILURE';

//Update metadata for the topic
export const TOPICS_API_UPDATE_METADATA_REQUEST = 'TOPICS_API_UPDATE_METADATA_REQUEST';
export const TOPICS_API_UPDATE_METADATA_SUCCESS = 'TOPICS_API_UPDATE_METADATA_SUCCESS';
export const TOPICS_API_UPDATE_METADATA_FAILURE = 'TOPICS_API_UPDATE_METADATA_FAILURE';

//Get all the topics associated with the user
export const TOPICS_API_GET_TOPICS_REQUEST = 'TOPICS_API_GET_TOPICS_REQUEST';
export const TOPICS_API_GET_TOPICS_SUCCESS = 'TOPICS_API_GET_TOPICS_SUCCESS';
export const TOPICS_API_GET_TOPICS_FAILURE = 'TOPICS_API_GET_TOPICS_FAILURE';

//Lazyload more topics associated with the user
export const TOPICS_API_LOAD_MORE_TOPICS_START = 'TOPICS_API_LOAD_MORE_TOPICS_START';
export const TOPICS_API_LOAD_MORE_TOPICS_REQUEST = 'TOPICS_API_LOAD_MORE_TOPICS_REQUEST';
export const TOPICS_API_LOAD_MORE_TOPICS_SUCCESS = 'TOPICS_API_LOAD_MORE_TOPICS_SUCCESS';
export const TOPICS_API_LOAD_MORE_TOPICS_FAILURE = 'TOPICS_API_LOAD_MORE_TOPICS_FAILURE';

//Add a topic
export const TOPICS_API_ADD_TOPICS_REQUEST = 'TOPICS_API_ADD_TOPICS_REQUEST';
export const TOPICS_API_ADD_TOPICS_SUCCESS = 'TOPICS_API_ADD_TOPICS_SUCCESS';
export const TOPICS_API_ADD_TOPICS_FAILURE = 'TOPICS_API_ADD_TOPICS_FAILURE';

//Topic Filters
export const CONTACTS_LIST_API_SEARCH_REQUEST = 'CONTACTS_LIST_API_SEARCH_REQUEST';
export const CONTACTS_LIST_API_SEARCH_CLEAR_REQUEST = 'CONTACTS_LIST_API_SEARCH_CLEAR_REQUEST';

//Topic Selection
export const TOPIC_API_SELECT_TOPIC = 'TOPIC_API_SELECT_TOPIC';

export const TOPICS_API_GET_ALL_TOPICS_REQUEST = 'TOPICS_API_GET_ALL_TOPICS_REQUEST';
export const TOPICS_API_GET_ALL_TOPICS_SUCCESS = 'TOPICS_API_GET_ALL_TOPICS_SUCCESS';
export const TOPICS_API_GET_ALL_TOPICS_FAILURE = 'TOPICS_API_GET_ALL_TOPICS_FAILURE';
export const CLEAR_TOPICS = "CLEAR_TOPICS";


export const TOPICS_API_GET_TOPICS_DETAILS_REQUEST = 'TOPICS_API_GET_TOPICS_DETAILS_REQUEST';
export const TOPICS_API_GET_TOPICS_DETAILS_SUCCESS = 'TOPICS_API_GET_TOPICS_DETAILS_SUCCESS';
export const TOPICS_API_GET_TOPICS_DETAILS_FAILURE = 'TOPICS_API_GET_TOPICS_DETAILS_FAILURE';