import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import * as ShareItemsActionTypes from '../../constants/personalVault/shareItemsConstants';
import {
    getAllItemsSharedWithMeAPI
} from '../../../shared/core/utils/apiFunctions';

export function* shareItemsWatcher() {
    yield takeLatest(ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_REQUEST, getAllItemsSharedWithMeSaga);
}

function* getAllItemsSharedWithMeSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        });
        
        if (userAccessInfo !== undefined) {
            const files = yield call(
                getAllItemsSharedWithMeAPI,
                action.pagination.direction,
                action.itemType,
                action.pagination.orderBy,
                action.pagination.page,
                action.pagination.size
            )
            yield put({
                type: ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_SUCCESS,
                files: files.data.content
            });
        }
    } catch(err) {
        yield put({
            type: ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_FAILURE,
            err
        })
    }
}

export default shareItemsWatcher;