import React from 'react';

const RecentIcon = (props) => {
    return <svg width="24px" height="24px" viewBox="0 0 24 24">
        <style>{`.st4{stroke:${props.stroke}}`}
        </style>
        <g id="Page-8" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Recent" className="st4" >
                <g id="Group">
                    <g id="time-clock-circle" strokeWidth="1.5">
                        <circle id="Oval" cx="12" cy="12" r="10.5"></circle>
                        <line x1="12" y1="12" x2="12" y2="8.25" id="Shape"></line>
                        <line x1="12" y1="12" x2="16.687" y2="16.688" id="Shape"></line>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}
export default RecentIcon