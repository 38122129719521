export const FETCH_TRM_OF_SVC_REQUEST = 'FETCH_TRM_OF_SVC_REQUEST';
export const FETCH_TRM_OF_SVC_SUCCESSFUL = 'FETCH_TRM_OF_SVC_SUCCESSFUL';
export const FETCH_TRM_OF_SVC_FAILURE = 'FETCH_TRM_OF_SVC_FAILURE';
export const FETCH_TRMSOFSVC_ERROR_CLEAR = 'FETCH_TRMSOFSVC_ERROR_CLEAR';

export const FETCH_ENDUSER_DISCLAIMER_FAILURE = 'FETCH_ENDUSER_DISCLAIMER_FAILURE';
export const FETCH_ENDUSER_DISCLAIMER_REQUEST = 'FETCH_ENDUSER_DISCLAIMER_REQUEST';
export const FETCH_ENDUSER_DISCLAIMER_SUCCESS = 'FETCH_ENDUSER_DISCLAIMER_SUCCESS';

export const FETCH_DELETE_FILE_DISCLAIMER_REQUEST = 'FETCH_DELETE_FILE_DISCLAIMER_REQUEST';
export const FETCH_DELETE_FILE_DISCLAIMER_SUCCESS = 'FETCH_DELETE_FILE_DISCLAIMER_SUCCESS';
export const FETCH_DELETE_FILE_DISCLAIMER_FAILURE = 'FETCH_DELETE_FILE_DISCLAIMER_FAILURE';