import React from 'react';

const InviteContact = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st8{stroke:${props.stroke}}`}
        </style>
        <g id="files-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="Users-/-Add-Contact" className="st8">
            <g id="Group">
                <g id="Regular" strokeWidth="1.5">
                    <circle id="Oval" cx="17.25" cy="17.25" r="6"></circle>
                    <line x1="17.25" y1="14.25" x2="17.25" y2="20.25" id="Shape"></line>
                    <line x1="14.25" y1="17.25" x2="20.25" y2="17.25" id="Shape"></line>
                    <path d="M0.75,17.25 C0.751543788,14.9833735 1.89011273,12.8686992 3.78149087,11.6195848 C5.672869,10.3704703 8.06475703,10.1535408 10.15,11.042" id="Shape"></path>
                    <circle id="Oval" cx="7.5" cy="4.875" r="4.125"></circle>
                </g>
            </g>
        </g>
    </g>
    </svg>
}
export default InviteContact