export const styles = (theme: any) => ({
    itemlist: {
        width: '100%',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.fabbuttonmobilebgcolor,
        },
        '&:hover fieldset': {
            borderColor: theme.fabbuttonmobilebgcolor,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.fabbuttonmobilebgcolor,
        },
    },
    listitem:
    {
        //opacity: '0.9' as '0.9',
        color: 'rgba(0,0,0,0.6)',
        fontSize: '0.5rem',
        letterSpacing: '0.25px',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.2)'
        }
    },
    title:
    {
        fontFamily: 'Fidelity Sans',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: '6px'
    },
    iteminline: {
        display: 'inline',
    },
    liststyle: {
        height: '400px'
    },
    root: {
    },
    itemstitle: {
        backgroundColor: '#EBEAEA',
        width: '424px',
        [theme.breakpoints.up('sm')]: {
            width: '480px',
        },
        display: "flex",
        paddingTop: "8px",
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: '56px'
    },
    buttonClass: {
        margin: theme.spacing(1),
        backgroundColor: theme.fabbuttonmobilebgcolor,
        color: 'white',
        textTransform: 'none' as 'none',
        boxShadow: theme.shadows[5],
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.fabbuttonmobilebgcolor,

        },
        '&:active': {
            backgroundColor: theme.fabbuttonmobilebgcolor,

        },
        '&:focus': {
            backgroundColor: theme.fabbuttonmobilebgcolor,

        },
        '&:disabled': {
            backgroundColor: theme.fabbuttonbgcolor,

        },
    },
    closeButton: {
        position: 'absolute' as 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    iconColor: {
        color: '#627282',
        marginLeft: '5px'
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    bottomActions:
    {
        display: 'flex',
        justifyContent: 'space-between'
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "60%"
        },
        [theme.breakpoints.up('sm')]: {
            width: "80%"
        }
    },
    endAdornment:
    {
        paddingRight: 0,
        height: '100%',
        backgroundColor: theme.actionbarbgcolor,
        paddingTop: '4px',
        paddingBottom: '4px'
    },
    input: {
        fontSize: '0.8em'
    },
    createFolderText: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem'
    },
    folderIcon:
    {
        align: "right",
        padding: "0px"
    }
});