


import React, { Component } from 'react';
import * as ContactActionTypes from '../../../../redux/constants/personalVault/contactsConstants';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../../../../shared/core/utils/icon';

type InviteHeaderProps = {
    title: any;
    classes: any;
    description?: string;
}

export const styles = (theme: any) => ({
    root:
    {

    },
    header: {
        height: '56px',
        width: '100%',
        paddingLeft: theme.spacing(2),
        display: 'flex',
        backgroundColor: theme.fabbuttonbgcolor,
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '0.96rem',
        marginTop: 10
    },
    headerIcon: {
        width: '15%',

    },
    description:
    {
        color: '#444657',
        margin: '14px'
    }
});

class InviteHeader extends Component<InviteHeaderProps> {
    render() {
        const { classes, title, description } = this.props;
        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.headerIcon}>
                        <Icon name={title} stroke={'#000000'} />
                    </div>
                    <div>{title}</div>
                </div>
                {description ? <div className={classes.description} style={{ marginLeft:'16px', fontWeight: 'bold' }}>{description} </div>
                    : null}
            </div>
        )
    }
}
export default withStyles(styles)(InviteHeader);