import { takeLatest, call, put, all, delay,takeEvery } from "redux-saga/effects";
import {
  GET_MULTIPLE_FILES_DOWNLOAD_REQUEST, GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS, GET_MULTIPLE_FILES_DOWNLOAD_FAILURE,
  GET_FILE_DOWNLOAD_API_REQUEST, GET_FILE_DOWNLOAD_API_SUCCESS, GET_FILE_DOWNLOAD_API_FAILURE, GET_ITEMS_FILES_REQUEST, GET_ITEMS_FILES_SUCCESS, GET_ITEMS_FILES_FAILURE,
  GET_API_HOME_FOLDER, GET_API_HOME_FOLDER_SUCCESS, GET_API_HOME_FOLDER_FALIURE,
  ADDTO_CURRENT_FOLDER, STRIP_CURRENT_FOLDERPATH, STRIP_CURRENT_FOLDERPATH_SUCCESS, FOLDER_API_UPDATE_ITEM,
  FOLDER_API_UPDATE_ITEM_SUCCESS, FOLDER_API_UPDATE_ITEM_FAILED, LOAD_CURRENT_ITEMS, LOAD_CURRENT_ITEMS_SUCCESS,ADDTO_CURRENT_FOLDER_SUCCESS,
  ITEM_API_DELETE_SUCCESS, ITEM_API_DELETE, ITEM_API_DELETE_FAILED, GET_ITEMS_CURRENT_SUCCESS, GET_ITEMS_CURRENT_FAILURE, GET_ITEMS_CURRENT
} from '../../constants/personalVault/itemsConstants'
import { downloadItem, downloadMultipleItems, getFileList, getHomeFolder, updateItemAPI, deleteItemAPI, getItemsListAPI, getItemsDetailsAPI } from '../../../shared/core/utils/apiFunctions'
import { saveAs } from "file-saver";


export function* topicWatcher() {

  yield takeLatest(GET_FILE_DOWNLOAD_API_REQUEST, downloadSaga);
  yield takeLatest(GET_ITEMS_FILES_REQUEST, filesSaga);
  yield takeLatest(GET_API_HOME_FOLDER, homeFolderSaga);
  yield takeLatest(GET_MULTIPLE_FILES_DOWNLOAD_REQUEST, downloadMultipleItemsSaga);
  yield takeLatest(ADDTO_CURRENT_FOLDER, addBreadCrumb);
  yield takeLatest(STRIP_CURRENT_FOLDERPATH, stripBreadCrumb);
  yield takeEvery(FOLDER_API_UPDATE_ITEM, updateItem)
  yield takeLatest(ITEM_API_DELETE, deleteItem);
  yield takeLatest(GET_ITEMS_CURRENT, getCurrentFilesSaga);
  yield takeLatest(LOAD_CURRENT_ITEMS, loadCurrentItems);
}


function* filesSaga(action) {
  try {
    const items = yield call(getFileList,action.itemCode, action.pagination.page, action.pagination.size, action.pagination.direction, action.pagination.orderBy)
    yield put({ type: GET_ITEMS_FILES_SUCCESS, responseInfo: items })
  } catch (error) {
    yield put({ type: GET_ITEMS_FILES_FAILURE, error });
  }
}

function* updateItem(action) {
  try {
    const items = yield call(updateItemAPI, action.itemName, action.itemCode, action.destinationCode)
    yield delay(0);
    yield put({ type: FOLDER_API_UPDATE_ITEM_SUCCESS, responseInfo: items })
  } catch (error) {
    yield put({ type: FOLDER_API_UPDATE_ITEM_FAILED, error });
  }
}

function* deleteItem(action) {
  try {
    const items = yield call(deleteItemAPI, action.folderCode, action.itemCodes)
    yield put({ type: ITEM_API_DELETE_SUCCESS, responseInfo: items })
  } catch (error) {
    yield put({ type: ITEM_API_DELETE_FAILED, error });
  }
}

function* homeFolderSaga(action) {
  try {
    const folderResponse = yield call(getHomeFolder)
    yield put({
      type: GET_API_HOME_FOLDER_SUCCESS, responseInfo: {
        itemCode: folderResponse.data.itemCode,
        itemName: "Home",
        description: folderResponse.data.description,
        parentCode: null
      }
    })
  } catch (error) {
    yield put({ type: GET_API_HOME_FOLDER_FALIURE, error });
  }
}


function* addBreadCrumb(action) {
  try {
    yield put({
      type: ADDTO_CURRENT_FOLDER_SUCCESS, responseInfo: {
        itemCode: action.item.itemCode,
        itemName: action.item.title,
        description: action.item.description,
        parentCode: action.item.parentCode
      }
    })
  } catch (error) {
    yield put({ type: GET_API_HOME_FOLDER_FALIURE, error });
  }
}

function* loadCurrentItems() {
  try {
    yield put({
      type: LOAD_CURRENT_ITEMS_SUCCESS, responseInfo: {

      }
    })
  } catch (error) {
    yield put({ type: GET_ITEMS_CURRENT_FAILURE, error });
  }
}


function* stripBreadCrumb(action) {
  try {
    yield put({
      type: STRIP_CURRENT_FOLDERPATH_SUCCESS, payload: action.currentIndex
    })
  } catch (error) {
    yield put({ type: GET_API_HOME_FOLDER_FALIURE, error });
  }
}


function* downloadSaga(action) {

  try {
    const fileResponse = yield call(downloadItem, action.itemCode, action.parentCode);
    if (fileResponse.status === 200) {
      saveAs(new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] }), action.fileName)
      yield put({ type: GET_FILE_DOWNLOAD_API_SUCCESS, responseInfo: fileResponse })
    }
  }
  catch (error) {
    const errorResponse = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
    yield put({ type: GET_FILE_DOWNLOAD_API_FAILURE, error: { response: { data: JSON.parse(errorResponse), status: error.response.status } } })
  }
}


// function* downloadMutlipleFilesSaga(action) {
//   try {
//     const fileResponse = yield call(downloadMultipleFiles, action.grpCode, action.stCode, action.filePath);
//     if (fileResponse.status === 200) {
//       saveAs(new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] }), action.fileName)
//       yield put({ type: GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS, responseInfo: fileResponse })
//     }
//   }
//   catch (error) {
//     const errorResponse = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
//     yield put({ type:GET_MULTIPLE_FILES_DOWNLOAD_FAILURE, error: { response: { data: JSON.parse(errorResponse), status: error.response.status } } })
//   }
// }

function* downloadMultipleItemsSaga(action) {
  try {
    const fileResponse = yield call(downloadMultipleItems, action.parentCode, action.itemCodes);
    if (fileResponse.status === 200) {
      saveAs(new Blob([fileResponse.data], {type: fileResponse.headers['content-type']}), action.fileName);
      yield put({ type: GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS, responseInfo: fileResponse });
    }

  } catch(error) {
    const errorResponse = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
    yield put({ type:GET_MULTIPLE_FILES_DOWNLOAD_FAILURE, error: { response: { data: JSON.parse(errorResponse), status: error.response.status }}})
  }
}

function* getCurrentFilesSaga(action) {
  try {
    if (action.isParent) {
      const [items, currentItem] = yield all([
        call(getItemsListAPI, action.folderCode),
        call(getItemsDetailsAPI, action.folderCode)
      ])
      yield put({ type: GET_ITEMS_CURRENT_SUCCESS, responseInfo: items, currentItem: currentItem })
    }
    else {
      const items = yield call(getItemsListAPI, action.folderCode);
      yield put({ type: GET_ITEMS_CURRENT_SUCCESS, responseInfo: items, currentItem: null })
    }
  } catch (error) {
    yield put({ type: GET_ITEMS_CURRENT_FAILURE, error });
  }
}

export default topicWatcher;
