// Get All the users followed the topic
export const FOLLOW_API_GET_USERS_REQUEST = 'FOLLOW_API_GET_USERS_REQUEST';
export const FOLLOW_API_GET_USERS_SUCCESS= 'FOLLOW_API_GET_USERS_SUCCESS';
export const FOLLOW_API_GET_USERS_FAILURE = 'FOLLOW_API_GET_USERS_FAILURE';

//Add new users to follow a topic
export const FOLLOW_API_ADD_USERS_REQUEST = 'FOLLOW_API_ADD_USERS_REQUEST';
export const FOLLOW_API_ADD_USERS_SUCCESS= 'FOLLOW_API_ADD_USERS_SUCCESS';
export const FOLLOW_API_ADD_USERS_FAILURE = 'FOLLOW_API_ADD_USERS_FAILURE';

// remove users from the follow of topic
export const FOLLOW_API_REMOVE_USERS_REQUEST = 'FOLLOW_API_REMOVE_USERS_REQUEST';
export const FOLLOW_API_REMOVE_USERS_SUCCESS= 'FOLLOW_API_REMOVE_USERS_SUCCESS';
export const FOLLOW_API_REMOVE_USERS_FAILURE = 'FOLLOW_API_REMOVE_USERS_FAILURE';
