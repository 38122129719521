import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { cvpaginationAllContacts } from '../../../shared/core/utils/paginationDefaults';
import * as ContactListActions from '../../../redux/constants/customerVault/instUserConstants';
import pendingIcon from '../../../assets/pending.svg';

type FollowersListSearchBarProps = {
  classes: any,
  handleInstUsersChange: any,
  instUsers: any[],
  fetchInstUsers: any,
  user_profile: any
}

export const styles = (theme: any) => ({
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  pendingIcon: {
    width: '1em',
    height: 'auto',
    verticalAlign: 'bottom',
    marginLeft: '0.5rem'
  },
  text: {
  },
  textfield: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between' as 'space-between',
  },
  emailtext:
  {
    fontSize: '0.9rem',
    fontFamily: 'Fidelity Sans Italic'
  },
  autocomplete: {
    width: '90%',
    display: 'flex',
    alignSelf: 'flex-start',
    marginLeft: theme.spacing(2)
  },
  clear: {
    paddingRight: '40px !important'
  },
  paper:
  {
    border: '1px solid #dfe1e5',
    borderRadius: '8px',
    boxShadow: "0px 2px 38px rgba(0, 0, 0, 0.2)",
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
});

class FollowersListSearchBar extends Component<FollowersListSearchBarProps> {

  state = { selectedValues: [] }

  componentDidMount() {
    const { fetchInstUsers } = this.props;
    fetchInstUsers('', cvpaginationAllContacts)
  }

  handleInputChange = (newvalue: any) => {
    this.setState({ selectedValues: newvalue },
      () => {
        this.props.handleInstUsersChange(this.state.selectedValues.map((a: any) => a.userCode))
      });
  }

  clearSelection = (event: any) => {
    console.log(event);
  }

  render() {
    const { classes } = this.props;
    const filteredList = this.props.instUsers.filter((user) =>
      this.props.user_profile.userCode !== user.userCode)
    return (
      <Autocomplete data-test='followersListSearchBarComp'
        id="category-select"
        classes={{
          root: classes.autocomplete,
          paper: classes.paper
        }}
        size="medium"
        multiple
        filterSelectedOptions
        value={this.state.selectedValues}
        renderOption={(option) => (
          <React.Fragment>
            <div className={classes.text}>
              <div className={classes.textfield}>
                {option.lastName != null ? `${option.lastName}, ` : ''}{option.firstName != null ? `${option.firstName}` : ''}
                {option.status === 'PENDING' ? <img className={classes.pendingIcon} src={pendingIcon} data-test='pendingIcon' alt="Pending" /> : null}
              </div>
              {option.email != null && <div className={classes.emailtext}>
                {option.email}
              </div>}
            </div>
          </React.Fragment>
        )}
        onChange={(option: any, value: any) => this.handleInputChange(value)}
        getOptionSelected={(option, value) => option.userCode === value.userCode}
        options={filteredList}
        getOptionLabel={option => option.lastName + ', ' + option.firstName}
        renderInput={params => (
          <TextField
            {...params}
            label="Add Followers"
            variant="outlined"
            className={classes.textfield}
            onChange={(event) => { this.clearSelection(event) }}
          />
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    instUsers: state.instUserReducer.groupInstUsers.usersData.content,
    user_profile: state.login.user_profile,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchInstUsers: (pattern: any, pagination: any) => dispatch({ type: ContactListActions.ADMIN_API_GET_INSTUSERS_REQUEST, pattern, pagination }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FollowersListSearchBar));
