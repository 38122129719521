import { combineReducers } from "redux";
import * as TopicFollowActionTypes from "../../constants/customerVault/topicFollowConstants";
import * as ErrorTypes from "../../../shared/errors/ErrorMessages";

const initState = {
  fetching: false,
  updating:false,
  followers: [],
  error: null,
};

function topicFollowUsers(state = initState, action) {
  switch (action.type) {
    // Get follow users
    case TopicFollowActionTypes.FOLLOW_API_GET_USERS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case TopicFollowActionTypes.FOLLOW_API_GET_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        followers: action.followers,
      };
    case TopicFollowActionTypes.FOLLOW_API_GET_USERS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    case TopicFollowActionTypes.FOLLOW_API_ADD_USERS_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TopicFollowActionTypes.FOLLOW_API_ADD_USERS_SUCCESS:
      let newArray = action.followers.filter(
        (item) => item.message == "Successful"
      );
      if (null != newArray)
        return {
          ...state,
          updating: false,
          followers: [...state.followers, ...newArray],
        };
      else
        return {
          ...state,
          updating: false,
        };
    case TopicFollowActionTypes.FOLLOW_API_ADD_USERS_FAILURE:
      return {
        ...state,
        updating: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    case TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_SUCCESS:
      return {
        ...state,
        updating: false,
        followers: state.followers.filter(
          (ar) =>
            !action.followers.find(
              (rm) => rm.message == "Successful" && ar.userCode == rm.userCode
            )
        ),
      };
    case TopicFollowActionTypes.FOLLOW_API_REMOVE_USERS_FAILURE:
      return {
        ...state,
        updating: false,
        error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND },
      };
    default:
      return state;
  }
}

export default combineReducers({
  topicFollowUsers,
});
