export const content_type = {
    TEXT: 'text',
    DATE: 'date',
    ICON: 'icon',
    LINK: 'link'
} 

export type ContactContent = {
    contactCode: string,
    contactEmail: string,
    contactFirstName: string,
    contactLastName: string,
    contactNickname: string,
    createdAt: number,
    modifiedAt: number,
    status: string,
    statusUpdated: number
}
  
export type GroupContent = {
    cgCode: string,
    cgName: string,
    createdAt: number,
    displayName: string,
    modifiedAt: number,
    ownerUserId: number,
    status: string
}
  
export type TableDataCell = {
    content: {
      s1: {
        type: string,
        content: any,
        bold: boolean
      },
      s2: {
        type: string,
        content: any,
        bold: boolean
      },
      s3: {
        type: string,
        content: any,
        bold: boolean
      },
      s4: {
        type: string,
        content: any,
        bold: boolean
      }
    },
    resizeShown: boolean
}
  
export type TableData = {
    id: string,
    cells: TableDataCell[],
}