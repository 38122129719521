import {
	LOGIN_REQUESTING,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGIN_UNSUCCESS,
	LOGOUT_SUCCESSFUL,
	LOGOUT_ERROR,
	LOGOUT_REQUESTING
} from '../../constants/shared/loginUserConstants';

const initialState = {
	requesting: false,
	successful: false,
	logoutresponse: null,
	messages: [],
	errors: [],
	authorizations: {
		AdvisorVault: ["INST_USER"],
		Topics: ["INST_USER", "USER"],
		ViewTopic: ["INST_USER", "USER"],
		Contacts: ["INST_USER"],
		AddTopic: ["INST_USER"],
		MyVault: ["USER"],
		OverflowMenuInstUser: ["INST_USER"],
		OverflowMenuEndUser: ["USER"],
		TopicDisclosureText: ["USER"],
		Follow: ["FOLLOW_UNFOLLOW"],
		UploadFiles: ["UPLOAD_FILES"]
	},
	user: {
		name: '',
		email: '',
		sub: ''
	},
	exp: 0,
	user_profile: null,
	user_sid: '',
	discriminator: ''
};

export default (state = initialState, action) => {
	let outval = {}
	switch (action.type) {
		case LOGIN_REQUESTING:
			outval = {
				...state,
				requesting: true,
				successful: false,
				errors: []
			}
			// delete outval.user;
			// delete outval.exp;
			// delete outval.user_profile;
			// delete outval.user_sid;
			return outval;
		case LOGIN_SUCCESS:
			return {
				...state, requesting: false,
				successful: true, errors: [], user: action.user, exp: action.exp, user_profile: action.user_profile, user_sid: action.sid, discriminator: action.discriminator
			}
		case LOGIN_UNSUCCESS:
			outval = { ...state, requesting: false, successful: false, errors: [] }
			delete outval.user;
			delete outval.exp;
			delete outval.user_profile;
			delete outval.user_sid;
			return outval;
		case LOGIN_ERROR:
			outval = { ...state, requesting: false, successful: false, errors: [] }
			delete outval.user;
			delete outval.exp;
			delete outval.user_profile;
			delete outval.user_sid;
			return outval;
		case LOGOUT_ERROR:
			outval = { ...state, requesting: false, successful: false, errors: [] }
			return outval;
		case LOGOUT_REQUESTING:
			outval = { ...state, requesting: true, successful: false, errors: [], logoutresponse: null }
			return outval;
		case LOGOUT_SUCCESSFUL:
			outval = { ...state, requesting: false, successful: false, errors: [],logoutresponse: action.response }
			delete outval.user;
			delete outval.exp;
			delete outval.user_profile;
			delete outval.user_sid;
			return outval;
		default:
			return state;
	}
};
