import { con } from "../../config/config";
import { HttpMethod, HttpRequest } from "./requestHandler";
import { buffers, eventChannel, END } from "redux-saga";

// export const getProductList = () => {
//   try {
//     const endpoint = `${con.adminUrl}/institutions/${con.INSTITUTION_CODE}/products`;
//     return new HttpRequest(HttpMethod.GET, endpoint).invoke();
//   } catch (error) {
//     console.error(error)
//   }
// }

export const getProduct = () => {
  try {
    const endpoint = `${con.adminUrl}/institutions/${con.INSTITUTION_CODE}/products/${con.PRODUCT_CODE}`;
    return new HttpRequest(HttpMethod.GET, endpoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getLegalDocus = (docName) => {
  try {
    const endpoint = `${con.PUBLIC_URL}/${con.PRODUCT_CODE}/tos/${docName}`;
    return new HttpRequest(HttpMethod.GET, endpoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getDisclosureText = (formLinks) => {
  try {
    return new HttpRequest(HttpMethod.GET, formLinks).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getThemeFile = (docName) => {
  try {
    const endpoint = `${con.PUBLIC_URL}/${con.PRODUCT_CODE}/theme/${docName}`;
    return new HttpRequest(HttpMethod.GET, endpoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getFeatureConfig = () => {
  try {
    const endpoint = `${con.PUBLIC_URL}/${con.PRODUCT_CODE}/feature/featureConfig.json`;
    return new HttpRequest(HttpMethod.GET, endpoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const updateUserConsentTrmSvc = (externalId, consentFlag) => {
  try {
    const endpoint = `${con.adminUrl}/products/${con.PRODUCT_CODE}/users/${externalId}/termsOfService?consentFlag=${consentFlag}`;
    return new HttpRequest(HttpMethod.PUT, endpoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getGroupsForUser = (userCode) => {
  try {
    const endpoint = `${con.adminUrl}/products/${con.PRODUCT_CODE}/users/${userCode}/groups`;
    return new HttpRequest(HttpMethod.GET, endpoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getTopics = (
  grpCode,
  page,
  size,
  direction,
  orderBy,
  categoryCode,
  created,
  modified
) => {
  try {
    const endpoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics`;
    const object = {
      page,
      size,
      direction,
      orderBy,
      categoryCode,
      created,
      modified,
    };
    return new HttpRequest(HttpMethod.GET, endpoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getTopicsForUser = (
  grpCode,
  userCode,
  page,
  size,
  direction,
  orderBy,
  categoryCode,
  created,
  modified
) => {
  try {
    const endpoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/users/${userCode}/topics`;
    const object = {
      page,
      size,
      direction,
      orderBy,
      categoryCode,
      created,
      modified,
    };
    return new HttpRequest(HttpMethod.GET, endpoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getCategories = (grpCode) => {
  try {
    const endPoint = `${con.adminUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/categories`;
    return new HttpRequest(HttpMethod.GET, endPoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getTopicDetail = (grpCode, topicCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}`;
    return new HttpRequest(HttpMethod.GET, endPoint).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getUserProfileByTopicCode = (grpCode, topicCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/users`;
    return new HttpRequest(HttpMethod.GET, endPoint, null).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getUserProfileByUserCode = (grpCode, userCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/userProfiles/${userCode}`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getContacts = (
  grpCode,
  pattern,
  page,
  size,
  direction,
  orderBy
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/users`;
    let object = { page, size, direction, orderBy };
    if (pattern) {
      object.pattern = pattern;
    }
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const createTopic = (grpCode, topicName, categoryCode, users, date) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics`;
    const object = { topicName, categoryCode, users, date };
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const submitTopic = (grpCode, topicCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}`;
    return new HttpRequest(HttpMethod.PUT, endPoint, null, null).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const associateUser = (grpCode, topicName, topicCode, users) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/users`;
    const object = { topicName, topicCode, users };
    return new HttpRequest(HttpMethod.PUT, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const addMetadata = (grpCode, topicCode, metadata) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/metadata`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, metadata).invoke();
  } catch (error) {
    console.error(error);
  }
};


export const updateMetadata = (grpCode, topicCode, metadata) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/metadata/update`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, metadata).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getItems = (
  grpCode,
  topicCode,
  page,
  size,
  direction,
  orderBy
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files`;
    const object = { page, size, direction, orderBy };

    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const UploadFile = (grpCode, topicCode, file) => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, formData).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const createUploadFileChannel = (
  grpCode,
  topicCode,
  file,
  user_sid,
  isSubmitRequired
) => {
  const randomString = (length) => {
    var charset =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._";
    let result = "";

    while (length > 0) {
      var bytes = new Uint8Array(16);
      var random = window.crypto.getRandomValues(bytes);

      random.forEach(function (c) {
        if (length === 0) {
          return;
        }
        if (c < charset.length) {
          result += charset[c];
          length--;
        }
      });
    }
    return result;
  };

  const formData = new FormData();
  formData.append("file", file, file.name);
  const endpoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files`;
  let csurf = document.cookie
    .split(";")
    .filter((item) => item.trim().startsWith("_csrf="));
  let csrf = csurf[0].split("=")[1];

  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        emitter({ progress });
      }
    };
    const onFailure = (e) => {
      emitter({ success: false, error: e });
      emitter(END);
    };
    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 201) {
          if (isSubmitRequired) {
            const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}`;
            var temp = new HttpRequest(HttpMethod.PUT, endPoint, null, {})
              .invoke()
              .then(
                (resp) => {
                  emitter({ success: true, items: xhr.response });
                  emitter(END);
                },
                (err) => {
                  onFailure(err);
                }
              );
          } else {
            emitter({ success: true, items: xhr.response });
            emitter(END);
          }
        } else {
          onFailure(xhr.response);
        }
      }
    };
    xhr.open("POST", endpoint);
    xhr.setRequestHeader("x-csrf-token", csrf);
    xhr.setRequestHeader("X-Request-ID", randomString(4) + "-" + user_sid);
    xhr.send(formData);
    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
};

export const downloadFile = (grpCode, topicCode, path) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files/${path}`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null, {
      responseType: "arraybuffer",
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const downloadMultipleFiles = (grpCode, topicCode, path) => {
  try {
    var pathArray;
    if (Array.isArray(path)) {
      pathArray = path;
    } else {
      pathArray = [path];
    }
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files/download`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, pathArray, {
      responseType: "arraybuffer",
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const deleteFile = (grpCode, topicCode, path) => {
  try {
    var pathArray;
    if (Array.isArray(path)) {
      pathArray = path;
    } else {
      pathArray = [path];
    }
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files`;
    return new HttpRequest(HttpMethod.DELETE, endPoint, null, null, {
      data: pathArray,
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getSearchResults = (grpCode, pattern) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/users/search`;
    const object = { pattern };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

//FDS APIs
export const getHomeFolder = () => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/homeFolder`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getFileList = (
  homeFolderValue,
  page,
  size,
  direction,
  orderBy
) => {
  const object = { page, size, direction, orderBy };
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${homeFolderValue}/items`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const personalVaultUploadFile = (selectedFolder, file) => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${selectedFolder}/files`;
    return new HttpRequest(HttpRequest.POST, endPoint, null, formData).invoke();
  } catch (err) {
    console.error("Error: " + err);
  }
};

export const downloadItem = (itemCode, parentCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${parentCode}/files/${itemCode}`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null, {
      responseType: "arraybuffer",
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const downloadMultipleItems = (parentCode, itemCodes) => {
  try {
    let itemsArray;
    if (Array.isArray(itemCodes)) {
      itemsArray = itemCodes;
    } else {
      itemsArray = [itemCodes];
    }
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${parentCode}/files/download`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, itemsArray, {
      responseType: "arraybuffer",
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const createFolderAPI = (folderName, currentFolderValue) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${currentFolderValue}/folders`;
    const object = { itemName: folderName };
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const updateItemAPI = (
  itemName,
  itemCode,
  destinationCode,
  itemDescription
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/items/${itemCode}`;
    const object = {
      itemName: itemName,
      destination: destinationCode,
      description: itemDescription,
    };
    return new HttpRequest(HttpMethod.PUT, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const MoveItemAPI = (itemName, itemCode, destinationCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/items/${itemCode}`;
    const object = { fldName: itemName, destination: destinationCode };
    return new HttpRequest(HttpMethod.PUT, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const deleteItemAPI = (folderCode, itemCodesVal) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${folderCode}/items`;
    return new HttpRequest(HttpMethod.DELETE, endPoint, null, null, {
      data: itemCodesVal,
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getItemsListAPI = (itemcode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/folders/${itemcode}/items`;
    return new HttpRequest(HttpMethod.GET, endPoint, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getItemsDetailsAPI = (itemCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/items/${itemCode}`;
    return new HttpRequest(HttpMethod.GET, endPoint, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getActiveContactsAPI = (
  userCode,
  direction,
  orderBy,
  page,
  size
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/users/${userCode}/contacts`;
    const object = { direction, page, orderBy, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get Contacts List API Error: " + err);
  }
};

export const getPendingContactsAPI = (
  userCode,
  direction,
  orderBy,
  page,
  size
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/users/${userCode}/invites`;
    const object = { direction, page, orderBy, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get Contacts List API Error: " + err);
  }
};

export const removeContactAPI = (contactCodes) => {
  try {
    let contactsArray;
    Array.isArray(contactCodes)
      ? (contactsArray = contactCodes)
      : (contactsArray = [contactCodes]);
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contacts`;
    return new HttpRequest(HttpMethod.DELETE, endPoint, null, null, {
      data: contactsArray,
    }).invoke();
  } catch (err) {
    console.error("Remove Contact API Error: " + err);
  }
};

export const getGroupsAPI = (userCode, direction, orderBy, page, size) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/users/${userCode}/contactGroups`;
    const object = { direction, page, orderBy, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (err) {
    console.error("Get Groups API Error: " + err);
  }
};

export const removeGroupAPI = (contactGroupCodes) => {
  try {
    let groupsArray;
    Array.isArray(contactGroupCodes)
      ? (groupsArray = contactGroupCodes)
      : (groupsArray = [contactGroupCodes]);

    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contactGroups`;
    return new HttpRequest(HttpMethod.DELETE, endPoint, null, null, {
      data: groupsArray,
    }).invoke();
  } catch (err) {
    console.error("Remove Group API Error: " + err);
  }
};

export const getInvitesAPI = (userCode, direction, orderBy, page, size) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/users/${userCode}/invites`;
    const object = { direction, page, orderBy, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get Invites List API Error: " + err);
  }
};

export const updateInviteStatusAPI = (userCode, updatedStatus) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contacts/${userCode}/update?status=${updatedStatus}`;
    return new HttpRequest(HttpMethod.PUT, endPoint).invoke();
  } catch (err) {
    console.error("Update Invite's API Error: " + err);
  }
};

export const addContactAPI = (contact) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contacts`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, contact).invoke();
  } catch (err) {
    console.error("Remove Contact API Error: " + err);
  }
};

export const addGroupAPI = (
  contactGroupName,
  contactGroupCodes,
  contactGroupId
) => {
  try {
    let groupsArray = [];
    Array.isArray(contactGroupCodes)
      ? (groupsArray = contactGroupCodes)
      : (groupsArray = [contactGroupCodes]);
    if (contactGroupId) {
      const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contactGroups/${contactGroupId}`;
      return new HttpRequest(HttpMethod.PUT, endPoint, null, {
        contactCodes: groupsArray,
        contactGroupName: contactGroupName,
      }).invoke();
    } else {
      const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contactGroups`;
      return new HttpRequest(HttpMethod.POST, endPoint, null, {
        contactCodes: groupsArray,
        contactGroupName: contactGroupName,
      }).invoke();
    }
  } catch (err) {
    console.error("Remove Group API Error: " + err);
  }
};

export const getContactsInGroupAPI = (contactGroupId) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/contactGroups/${contactGroupId}/contacts`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (err) {
    console.error("Get Contacts List in Contact Group API Error: " + err);
  }
};

export const userLogin = (username, password) => {
  try {
    const endPoint = `/sdv-authorize`;
    const object = { username, password };
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const userMFALoginAPI = (code, mfaType) => {
  try {
    const endPoint = `/auth/mfa_phone`;
    const object = { code, mfaType };
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const userSignUPAPI = (
  username,
  email,
  password,
  phone_number,
  given_name,
  family_name,
  middle_name,
  birthdate
) => {
  try {
    const endPoint = `/auth/signup`;
    const object = {
      username,
      email,
      password,
      phone_number,
      given_name,
      family_name,
      middle_name,
      birthdate,
    };
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const userVerifyAPI = (username, code) => {
  try {
    const endPoint = `/auth/signup/confirm`;
    const object = { username, code };
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error(error);
  }
};
export const getItemsSharedByMeAPI = (
  contactCode,
  direction,
  orderBy,
  page,
  size
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/sharedByMe/items`;
    const object = { contactCode, direction, orderBy, page, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get Items Shared By Me in Contact API Error: " + err);
  }
};

export const getItemsSharedWithMeAPI = (
  userCode,
  direction,
  itemType,
  orderBy,
  page,
  size
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/sharedWithMe/items`;
    const object = { userCode, direction, itemType, orderBy, page, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get Items Shared With Me in Contact API Error: " + err);
  }
};

export const getAllItemsSharedWithMeAPI = (
  direction,
  itemType,
  orderBy,
  page,
  size
) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/sharedWithMe/items`;
    const object = { direction, itemType, orderBy, page, size };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get All Items Shared With Me in Contact API Error: " + err);
  }
};

export const getGroupItemsSharedByMeAPI = (contactGroupCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/sharedByMe/items`;
    const object = { contactGroupCode };
    return new HttpRequest(HttpMethod.GET, endPoint, null, object).invoke();
  } catch (err) {
    console.error("Get Group Items Shared By Me in Contact API Error: " + err);
  }
};

export const editShareContactsAPI = (contacts, itemCodes) => {
  try {
    //To do when the array of itemcodes are supported.
    // let itemsArray = [];
    // Array.isArray(itemCodes) ? itemsArray = itemCodes : groupsArray = [contactGroupCodes];

    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/share`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, {
      categoryCode: `${con.CATEGORY_CODE}`,
      contacts: contacts,
      itemCode: itemCodes,
    }).invoke();
  } catch (err) {
    console.error("Remove Group API Error: " + err);
  }
};

export const getShareContactsAPI = (itemCode) => {
  try {
    const endPoint = `${con.uploadUrl}/products/fds/groups/fidsafe/sharedByMe/item/${itemCode}/users`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (err) {
    console.error("Get users I have shared the item with API Error: " + err);
  }
};

export const getVerificationAPI = (dob, ssn) => {
  try {
    return new HttpRequest(HttpMethod.POST, "/verify", null, {
      dob: dob,
      ssn: ssn,
      externalId: "",
    }).invoke();
  } catch (err) {
    console.error("Verification API Error: " + err);
  }
};

export const getCeremonyDisclosures = (
  grpCode,
  ceremonyCode
) => {
  try {
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/ceremonies/${ceremonyCode}/form/disclosures`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
}

export const intiateCeremonyProcess = (
  grpCode,
  topicCode,
  ceremonyType,
  ceremonies,
  formCode
) => {
  try {
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/ceremonyRequest`;
    let object;
    if (ceremonyType === 'esign') {
      object = { ceremonyTypeCode: ceremonyType, formCode: formCode, ceremonies: ceremonies };
    } else {
      object = { ceremonyTypeCode: ceremonyType, ceremonies: ceremonies };
    }
    return new HttpRequest(HttpMethod.POST, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const cancelCeremonyProcess = (
  grpCode,
  ceremonyCode
) => {
  try {
    let ceremonyCodesArray = [];
    if (Array.isArray(ceremonyCode)) {
      ceremonyCodesArray = ceremonyCode;
    } else {
      ceremonyCodesArray = [ceremonyCode];
    }
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/ceremonies`;
    const object = { ceremonyCodes: ceremonyCodesArray };
    return new HttpRequest(HttpMethod.DELETE, endPoint, null, null, {
      data: object,
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const startCeremonyProcess = (
  grpCode,
  topicCode,
  ceremonyCode,
  isForceInit
) => {
  try {
    const object = 
    {
      headers: {
        "forceInitiate": isForceInit,
        "completionUrl": window.location.origin+'/waiting'
      },
    }
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/ceremony/${ceremonyCode}`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, {}, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getCeremonyDetails = (grpCode, topicCode, itemCode) => {
  try {
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/items/${itemCode}/ceremonies`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getFormRoles = (grpCode, categoryCode, formType) => {
  const object =
  {
    headers: {
      "formcategory": formType,
    },
  }
  try {
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/categories/${categoryCode}/form/roles`;

    return new HttpRequest(HttpMethod.GET, endPoint, null, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getCeremonyTypes = (categoryCode) => {
  try {
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/categories/${categoryCode}/ceremonyTypes`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const updateCeremonyDetails = (
  grpCode,
  ceremonyCode,
  status
) => {
  try {
    const endPoint = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/ceremonyrequest`;
    let object = null;
    if (status)
      object = { stcCode: ceremonyCode, status: status };
    else
      object = { stcCode: ceremonyCode };
    // return new HttpRequest(HttpMethod.PUT, endPoint, null, object, headObj).invoke();
    return new HttpRequest(HttpMethod.PUT, endPoint, null, object).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const removeTopicFollowAPI = (grpCode, topicCode, userCodes) => {
  try {
    let usersArray;
    Array.isArray(userCodes)
      ? (usersArray = userCodes)
      : (usersArray = [userCodes]);
    const object = { userCodes: usersArray };
    const endPoint = `${con.notificationUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/followers`;
    return new HttpRequest(HttpMethod.DELETE, endPoint, null, null, {
      data: object,
    }).invoke();
  } catch (err) {
    console.error("Remove Topic follow user API Error: " + err);
  }
};

export const addTopicFollowAPI = (grpCode, topicCode, userCodes) => {
  try {
    let usersArray;
    Array.isArray(userCodes)
      ? (usersArray = userCodes)
      : (usersArray = [userCodes]);
    const endPoint = `${con.notificationUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/followers`;
    return new HttpRequest(HttpMethod.POST, endPoint, null, {
      userCodes: usersArray,
    }).invoke();
  } catch (err) {
    console.error("Add Topic follow user API Error: " + err);
  }
};

export const getTopicFollowAPI = (grpCode, topicCode) => {
  try {
    const endPoint = `${con.notificationUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/followers`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getSmartIntakeToken = (siGroupId, formId, groupCode, topicCode, itemCode) => {
  try {
    const siBody = {
      "forms": [
        {
          "id": formId,
          "contextData": [
            {
              "fieldName": "productCode",
              "fieldValue": con.PRODUCT_CODE
            },
            {
              "fieldName": "groupCode",
              "fieldValue": groupCode
            }
            ,
            {
              "fieldName": "topicCode",
              "fieldValue": topicCode
            },
            {
              "fieldName": "itemCode",
              "fieldValue": itemCode
            }
          ]
        }
      ]
    }
    return new HttpRequest(HttpMethod.POST, `/si_token/${siGroupId}/${formId}`,
      null, siBody).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getFileActivityAPI = (grpCode, topicCode, itemCode) => {
  try {
    const endPoint = `${con.notificationUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files/${itemCode}/activity`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const getInstUsers = (
  grpCode,
) => {
  try {
    const endPoint = `${con.adminUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/instUsers`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error(error);
  }
};

export const previewFile = (grpCode, topicCode, path) => {
  try {
    const endPoint = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${grpCode}/topics/${topicCode}/files/${path}/preview`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null, {
      responseType: "arraybuffer",
    }).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};

export const getF4BDisclosure = (disclosureName) => {
  try {
    const endPoint = `${con.PUBLIC_URL}/f4b/disclosure/${disclosureName}`;
    return new HttpRequest(HttpMethod.GET, endPoint, null, null).invoke();
  } catch (error) {
    console.error("Error:" + error);
  }
};