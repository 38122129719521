import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Styles from '../css/termsStyles.js';
import Warning from './Warning';
import classNames from 'classnames';
import { con } from '../../../../shared/config/config';  
import { AppBar } from '@material-ui/core';
import { selectLogo } from '../../../../shared/core/utils/functions'; 

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Terms extends React.Component {

    state = { open: false, alertOpen: false };
    modalRef = React.createRef();

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }
    }

    handleAgree = () => {
        this.props.handleClose();
    }

    handleDecline = () => {
        this.setState({ alertOpen: true });
    }

    closeAlert = () => {
        this.setState({ alertOpen: false })
    }


    renderAlert() {
        const { title } = this.props;
        return (
            <Warning
                open={this.state.alertOpen}
                declineCallback={this.props.declineCallback}
                closeCallback={this.closeAlert}
            >
                In order to continue with this app, you must review and agree to
                our {title.toLowerCase()}.
            </Warning>
        )
    }

    render() {
        const { open, handleClose, classes, children, title, showAction } = this.props;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                data-test="terms"
                TransitionComponent={Transition}
                ref={el => this.modalRef = el}
            >
                <AppBar
                    position='relative'
                    className={classes.appbar}>
                    <div className={classes.toolbarTitle}>
                        {selectLogo(con.PRODUCT_CODE)}
                    </div>
                </AppBar>
                <Paper elevation={0} className={classes.contentRoot}>
                    {this.renderAlert()}
                    {/* <DialogTitle className={classes.title} disableTypography>{title}</DialogTitle> */}
                    {/* <DialogContent className={classes.content}>
                            <DialogContentText className={classes.contentText}> */}
                    {children}
                </Paper>
                {/* </DialogContentText>
                        </DialogContent> */}
                <Paper elevation={0} className={classes.actionsRoot}>
                    <DialogActions className={classes.actions}>
                        {showAction && <div className={classes.warningWrapper}>
                            <Typography className={classes.warning}>
                                By clicking “I Agree” below, I agree that I have read and accept the Fidsafe for Business {title.toLowerCase()}.
                                </Typography>
                        </div>}
                        <div className={classes.buttonWrapper}>
                            {showAction && <Fab variant='extended'
                                color='secondary'
                                data-test='IDeclineBtn'
                                className={classes.button}
                                onClick={this.handleDecline}>
                                I Decline
                            </Fab>}
                            {showAction && <Fab variant='extended'
                                className={classNames(classes.button, classes.agreeButton)}
                                color='primary'
                                data-test='IAgreeBtn'
                                onClick={this.handleAgree}>
                                I Agree
                                </Fab>}
                            {!showAction && <Fab variant='extended'
                                className={classNames(classes.button, classes.closeButton)}
                                color='primary'
                                onClick={this.handleAgree}>
                                Close
                            </Fab>}
                        </div>
                    </DialogActions>
                </Paper>
            </Dialog >
        )
    }
}

export default withStyles(Styles)(Terms);