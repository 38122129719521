import {
    MY_FILES_GET_ITEMS_FILES_REQUEST,
    MY_FILES_GET_ITEMS_FILES_SUCCESS,
    MY_FILES_GET_ITEMS_FILES_FAILURE,
    MY_FILES_LAZY_LOAD_MORE_FILES_REQUEST,
    MY_FILES_LAZY_LOAD_MORE_FILES_SUCCESS,
    MY_FILES_LAZY_LOAD_MORE_FILES_FAILURE,
    GET_API_HOME_FOLDER,
    GET_API_HOME_FOLDER_SUCCESS,
    GET_API_HOME_FOLDER_FALIURE,
    STRIP_CURRENT_FOLDERPATH_SUCCESS,
    ADDTO_CURRENT_FOLDER,
    ADDTO_CURRENT_FOLDER_SUCCESS,
    CREATE_FOLDER_API_CREATE_REQUEST,
    CREATE_FOLDER_API_CREATE_SUCCESS,
    CREATE_FOLDER_API_CREATE_FAILED,
    FOLDER_API_UPDATE_ITEM_SUCCESS,
    FOLDER_API_UPDATE_ITEM_FAILED,
    GET_FILE_DOWNLOAD_API_REQUEST,
    GET_FILE_DOWNLOAD_API_SUCCESS,
    GET_FILE_DOWNLOAD_API_FAILURE,
    ITEM_API_DELETE,
    ITEM_API_DELETE_SUCCESS,
    ITEM_API_DELETE_FAILED,
    GET_ITEMS_CURRENT,
    GET_ITEMS_CURRENT_SUCCESS,
    GET_ITEMS_CURRENT_FAILURE,
    LOAD_CURRENT_ITEMS_SUCCESS,
    FILES_ERROR_CLEAR,
    GET_MULTIPLE_FILES_DOWNLOAD_REQUEST,
    GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS,
    GET_MULTIPLE_FILES_DOWNLOAD_FAILURE,
    MY_FILES_UPLOAD_FILES_REQUEST,
    MY_FILES_UPLOAD_FILES_SUCCESS,
    MY_FILES_UPLOAD_FILES_FAILURE
} from '../../constants/personalVault/myFilesConstants'
import { UNABLE_TO_FETCH_FILES } from '../../../shared/errors/ErrorActions';

const initialState = {
    itemsInfo: {
        fetching: true,
        lazyLoading: true,
        itemList: [],
        itemsPage: {
            paged: false,
            pageNumber: 0,
            pageSize: 0,
            totalPages: 0,
            totalElements: 0,
            first: false,
            last: false
        },
        sort: null,
        error: null
    },
    downloadInfo: {
        response: null,
        fetching: false,
    },
    updateItem: {
        response: null,
        updating: false,
        error: null
    },
    deleteItem: {
        response: null,
        deleting: false,
        error: false
    },
    breadcrumbInfo:
    {
        fetching: true,
        folders: [
            {
                itemCode: '',
                itemName: '',
                description: 'Home Folder',
                parentCode: null
            }
        ]
    },
    createdFolderInfo: {
        response: null,
        fetching: null,
        error: null
    },
    currentItemInfo: {
        itemList: [],
        fetching: false,
        error: null,
        currentItem: null
    },
    uploadFiles: {
        files: [],
        error: null,
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MY_FILES_GET_ITEMS_FILES_REQUEST:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    fetching: true
                }
            };

        case MY_FILES_GET_ITEMS_FILES_SUCCESS:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    fetching: false,
                    itemList: action.responseInfo.data.content,
                    itemsPage: action.responseInfo.data.page,
                    sort: action.responseInfo.data.sort
                }
            };

        case MY_FILES_GET_ITEMS_FILES_FAILURE:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    fetching: false,
                    itemList: []
                },
                error: {
                    ...action.error,
                    action: UNABLE_TO_FETCH_FILES
                }
            };

        case MY_FILES_LAZY_LOAD_MORE_FILES_REQUEST:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    lazyLoading: true
                }
            };
        
        case MY_FILES_LAZY_LOAD_MORE_FILES_SUCCESS:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    lazyLoading: false,
                    itemList: [...state.itemsInfo.itemList, ...action.responseInfo.data.content],
                    itemsPage: action.responseInfo.data.page,
                    sort: action.responseInfo.data.sort
                }
            };

        case MY_FILES_LAZY_LOAD_MORE_FILES_FAILURE:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    lazyLoading: false
                },
                error: {
                    ...action.error,
                    action: UNABLE_TO_FETCH_FILES
                }
            };

        case GET_API_HOME_FOLDER:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    fetching: true
                }
            }

        case GET_API_HOME_FOLDER_SUCCESS:
            return {
                ...state,
                // itemsInfo: {
                //     ...state.itemsInfo,
                //     fetching: false
                // },
                breadcrumbInfo: {
                    ...state.breadcrumbInfo,
                    fetching: false,
                    folders: [action.responseInfo]
                }
            };

        case GET_API_HOME_FOLDER_FALIURE:
            return {
                ...state, 
                itemsInfo: {
                    ...state.itemsInfo,
                    // fetching: false, 
                    itemList: state.itemsInfo 
                },
                breadcrumbInfo: {
                    ...state.breadcrumbInfo,
                    fetching: false,
                    folders: []
                },
                error: { ...action.error, action: UNABLE_TO_FETCH_FILES }
            };
        
        case MY_FILES_UPLOAD_FILES_REQUEST:
            return {
                ...state,
                uploadFiles: {
                    ...state.uploadFiles,
                    files: [0]
                }
            }
        
        case MY_FILES_UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                uploadFiles: {
                    ...state.uploadFiles,
                    files: action.files
                }
            }

        case MY_FILES_UPLOAD_FILES_FAILURE:
            return {
                ...state,
                uploadFiles: {
                    ...state.uploadFiles,
                    error: action.error
                }
            }
        
        case ADDTO_CURRENT_FOLDER:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    fetching: true
                }
            }

        case ADDTO_CURRENT_FOLDER_SUCCESS:
            return {
                ...state,
                itemsInfo: {
                    ...state.itemsInfo,
                    fetching: false
                },
                breadcrumbInfo:
                { folders: [...state.breadcrumbInfo.folders, action.responseInfo] }
            };

        case STRIP_CURRENT_FOLDERPATH_SUCCESS:
            return {
                ...state, breadcrumbInfo:
                    { folders: [...state.breadcrumbInfo.folders.slice(0, action.payload)] }
            };

        case CREATE_FOLDER_API_CREATE_REQUEST:
            return {
                ...state,
                createdFolderInfo: { fetching: true }
            }

        case CREATE_FOLDER_API_CREATE_SUCCESS:
            return {
                ...state,
                createdFolderInfo: {
                    fetching: false,
                    response: {
                        ...action.responseInfo
                    },
                    error: null
                },
                itemsInfo: {
                    ...state.itemsInfo,
                    itemList: addItem(action, state.itemsInfo, state.breadcrumbInfo.folders)
                }
            }

        case CREATE_FOLDER_API_CREATE_FAILED:
            return {
                ...state,
                createdFolderInfo: {
                    fetching: false,
                    response: null,
                    error: { ...action.error.response }
                }
            }

        case FOLDER_API_UPDATE_ITEM_SUCCESS:
            return {
                ...state, 
                updateItem: { 
                    updating: false, 
                    response: { 
                        ...action.responseInfo 
                    }, 
                    error: null 
                },
                itemsInfo: { 
                    ...state.itemsInfo, 
                    fetching: false, 
                    itemList: getUpdatedItemList(action, state.itemsInfo) 
                }
            }
        case FOLDER_API_UPDATE_ITEM_FAILED:
            return { ...state, updateItem: { updating: false, response: null, error: { ...action.error.response } } }
        case GET_FILE_DOWNLOAD_API_REQUEST:
            return { ...state, downloadInfo: { fetching: true } };
        case GET_FILE_DOWNLOAD_API_SUCCESS:
            return { ...state, downloadInfo: { fetching: false, response: { ...action.responseInfo } } };

        case GET_MULTIPLE_FILES_DOWNLOAD_REQUEST:
            return { ...state, downloadInfo: { fetching: true } };
        case GET_MULTIPLE_FILES_DOWNLOAD_SUCCESS:
            return { ...state, downloadInfo: { fetching: false, response: { ...action.responseInfo } } };
        case GET_MULTIPLE_FILES_DOWNLOAD_FAILURE:
            return { ...state, downloadInfo: { fetching: false, response: null, error: { ...action.error.response } } }

        case ITEM_API_DELETE:
            return { ...state, deleteItem: { deleting: true } };
        case ITEM_API_DELETE_SUCCESS:
            return { ...state, deleteItem: { deleting: false, response: { ...action.responseInfo } } };
        case ITEM_API_DELETE_FAILED:
            return { ...state, deleteItem: { deleting: false, response: null, error: { ...action.error.response } } }
        case GET_ITEMS_CURRENT:
            return { ...state, currentItemInfo: { fetching: true } };

        case CREATE_FOLDER_API_CREATE_REQUEST:
            return { ...state, createdFolderInfo: { fetching: true } }
        case CREATE_FOLDER_API_CREATE_SUCCESS:
            return { ...state, createdFolderInfo: { fetching: false, response: { ...action.responseInfo }, error: null }, itemsInfo: { ...state.itemsInfo, itemList: addItem(action, state.itemsInfo, state.breadcrumbInfo.folders) } }
        case CREATE_FOLDER_API_CREATE_FAILED:
            return { ...state, createdFolderInfo: { fetching: false, response: null, error: { ...action.error.response } } }


        case GET_ITEMS_CURRENT_SUCCESS:
            return {
                ...state, currentItemInfo: {
                    fetching: false,
                    itemList: action.responseInfo.data.content,
                    currentItem: (null != action.currentItem) ? action.currentItem.data : action.currentItem
                }
            };
        case GET_ITEMS_CURRENT_FAILURE:
            return { ...state, currentItemInfo: { fetching: false, itemList: [], currentItem: null }, error: { ...action.error, action: UNABLE_TO_FETCH_FILES } };
        case LOAD_CURRENT_ITEMS_SUCCESS:
            return {
                ...state, currentItemInfo: {
                    fetching: false, itemList: [...state.itemsInfo.itemList.slice()],
                    currentItem: null
                }
            };
        case FILES_ERROR_CLEAR:
            return { ...state, error: null }

        default:
            return state;
    }
}

const addItem = (action, itemsInfo, currentFolders) => {
    let addedFolderDetails = action.responseInfo;
    let exisitngArray = itemsInfo.itemList.slice()
    let exisitngArrayLength = exisitngArray.length
    if (addedFolderDetails && currentFolders[currentFolders.length - 1].itemCode === addedFolderDetails.parentCode) {
        exisitngArray.unshift(addedFolderDetails);
        if (exisitngArrayLength >= 20) {
            exisitngArray.pop();
        }
    }
    return exisitngArray;
}

const getUpdatedItemList = (action, itemsInfo) => {
    let updatedResponse = action.responseInfo.data;
    let newArray = itemsInfo.itemList.slice()
    // Check if the itemcode exists in the iteminfo state  and get the index.
    var updatedItemIndex = itemsInfo.itemList.findIndex((x) =>
        x.itemCode === updatedResponse.itemCode
    );

    //update the details.
    if (updatedItemIndex >= 0) {
        var updatedItem = itemsInfo.itemList[updatedItemIndex];
        //Check if the destination folder id different then the item moved from the current folder.
        if (updatedItem.parentCode !== updatedResponse.destination) {
            // remove the moved item from the list
            if (newArray.length > 1)
                newArray.splice(updatedItemIndex, 1)
            else
                newArray.splice(updatedItemIndex)
        }
        else //Consider it as a rename and update the item name
        {
            newArray[updatedItemIndex].itemName = updatedResponse.itemName;
        }
    }
    return newArray;
}