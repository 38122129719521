import React, { useState, useEffect, useRef } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import { Card, Paper, Tooltip } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formattedDate } from '../../core/utils/functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
            backgroundColor: '#F9F9F9',
            flexDirection: 'row',
            alignContent: 'flex-start'
        },
        toolBar: {
            backgroundColor: '#fff',
            padding: '0 3px',
            borderBottom: '1px solid #c5c5c5'
        },
        topicCard: {
            width: '258px !important',
            height: '100px !important',
            margin: '7px',
            transition: 'all ease 0.3s',
            display: 'flex',
            padding: '20px 16px 20px 0 !important',
            flexDirection: 'row',
            '&:hover': {
                transform: 'scale(1.02)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100% !important'
            }
        },
        topicCardSelected: {
            backgroundColor: 'rgba(212,243,182,0.42)'
        },
        leftSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
        rightSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
        contentWrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        topicIconWrapper: {
            margin: '0 16px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        typeIcon: {
            marginRight: '0',
            width: '40px'
        },
        checkbox: {
            marginLeft: '10px',
            marginTop: '4px'
        },
        topicIcon: {
            borderRadius: '4px',
            backgroundColor: '#333',
            width: '30px',
            height: '30px',
            padding: '5px',
            fontSize: '12px',
            margin: '0 1rem',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
        },
        topicTitle: {
            width: '196px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 700,
            fontSize: 14,
            margin: 0,
            textTransform: 'capitalize',
            color: '#0978A4',
            cursor: 'pointer',
            '&:hover': {
                color: '#0E67A9',
            }
        },
        momentContent: {
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '20px',
            color: 'rgba(68,70,87,0.75)',
            margin: 0
        },
        sizeContent: {
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '20px',
            color: 'rgba(68,70,87,0.75)',
            margin: 0
        },
        permissionBtn: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem',
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            fontFamily: 'Fidelity Sans',
            marginTop: '1rem'
        },
        permissionStatic: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem',
            display: 'flex',
            width: 'calc(100% - 16px)',
            justifyContent: 'flex-end',
            padding: '8px 8px',
            marginTop: '1rem'
        },
        progressbar: {
            height: '4px'
        },
        loadingContext: {
            color: '#8a8a8a',
            fontWeight: 700,
            fontSize: '11px',
            marginLeft: '10px'
        },
        loadingWrapper: {
            width: '100%',
            textAlign: 'center'
        },
        filterMenuBtn: {
            width: '20px',
            height: '20px',
        },
        groupname:
        {
            width: '100%',
            margin: '0px',
            padding: '7px',
            borderBottom: '1px solid lightgrey',
            fontFamily: 'Fidelity Sans',
            fontSize: '0.9rem',
            fontWeight: 500
        },
        grid:
        {
            padding: '7px'
        },
        noTopics:
        {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: '#fff !important',
            padding: '8px 13px',
            boxShadow: '0 3px 6px rgba(0, 0, 0,0.16), 0 3px 2px rgba(0, 0, 0,0.23) !important'
        }
    }),
);

interface TopicType {
    stCode: string,
    stName: string,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number,
}

interface groupType {
    groupCode: string,
    groupName: string,
    topics: TopicType[]
}

interface productType {
    productCode: string,
    groups: groupType[],
}

type CommonTopicGridListProps = {
    productTopics: productType[],
    tableMarginHeight: number,
    onFileOpen: any,
    topicsFetching: boolean,
}

const CommonUserTopicGridList = (props: CommonTopicGridListProps) => {
    const classes = useStyles();
    const [selectedTopic, setTopic] = useState(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [gridHeight, setgridHeight] = useState<number>(0);
    const gridContainer: any = useRef<HTMLDivElement>(null);
    const [hasReachedToBottom, setReachToBottom] = useState(false);
    const [noTopics, setNoTopics] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize);
        setgridHeight(gridHeight);
        props.productTopics.map((item: productType) => {
            item.groups.map((group: groupType) => {
                if (group.topics.length > 0)
                    setNoTopics(false)
            });
        });
        return () => window.removeEventListener("resize", handleResize);
    })


    const itemOnOpen = (event: React.MouseEvent<unknown>, topic: any, groupCode: string) => {
        event.stopPropagation();
        props.onFileOpen(topic, groupCode)
    }


    const handleClose = () => {
        setAnchorEl(null);
    };

    const colorArray = [
        '#191970',
        '#cd5c5c',
        '#414040',
        '#48b4e0',
        '#006400'
    ];

    const getTopicstyle = (color: any) => {
        return {
            borderRadius: '4px',
            width: '12px',
            height: '12px',
            padding: '5px',
            fontSize: '10px',
            margin: '0 1rem',
            color: color,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
            borderWidth: '2px',
            borderStyle: 'solid'
        }
    }


    return (
        <>
            {!props.topicsFetching &&
                <>
                    <div data-test='commonUserTopicGridListNoDataFetching' className={classes.root} ref={gridContainer}
                        style={{ height: `${dimensions.height - props.tableMarginHeight}px` }}
                    >
                        {noTopics && <div className={classes.noTopics} >No Topics to display.</div>}
                        {!noTopics && props.productTopics.map((product: productType) => {
                            var index = 0
                            return (<React.Fragment key={product.productCode} >
                                {
                                    product.groups.sort(
                                        (a, b) => a.groupName.localeCompare(b.groupName, undefined, { caseFirst: "upper" })
                                    ).map((group: groupType) => {
                                        if (group.topics.length > 0) {
                                            index = colorArray.length > index ? index : 0
                                            const color = colorArray[index++];
                                            const key = group.groupCode
                                            return (
                                                <React.Fragment key={key} >
                                                    <div className={classes.groupname}> {group.groupName.toLocaleUpperCase()}</div>
                                                    <Paper elevation={0} style={{ width: '100%' }}>
                                                        <GridList cellHeight={160} className={classes.grid}>
                                                            {group.topics.map((topic: TopicType, index: any) => {
                                                                const isItemSelected = false;
                                                                return (
                                                                    <Card className={`${classes.topicCard} ${isItemSelected ? classes.topicCardSelected : null}`}
                                                                        key={index} >
                                                                        <section className={classes.leftSection}>
                                                                            <div style={getTopicstyle(color)}>
                                                                                {topic.catgeoryIconLabel}
                                                                            </div>
                                                                        </section>
                                                                        <section className={classes.rightSection}>
                                                                            <div className={classes.contentWrapper}>
                                                                                <div>
                                                                                    <Tooltip disableFocusListener disableTouchListener title={topic.stName}>
                                                                                        <p className={classes.topicTitle} onClick={event => itemOnOpen(event, topic, key)}>
                                                                                            {topic.stName}
                                                                                        </p>
                                                                                    </Tooltip>
                                                                                    <p className={classes.momentContent}><span className={classes.momentContent}>{topic.categoryName}</span></p>
                                                                                    <p className={classes.momentContent}>Created: <span className={classes.momentContent}>{formattedDate(topic.createdAt)}</span></p>
                                                                                    <p className={classes.momentContent}>Modified: <span className={classes.momentContent}>{formattedDate(topic.modifiedAt)}</span></p>
                                                                                    <p className={classes.momentContent}>Contacts: <span className={classes.momentContent}>{topic.sharedTopicUsersCount}</span></p>
                                                                                    <p className={classes.momentContent}>Files: <span className={classes.momentContent}>{topic.sharedTopicItemsCount}</span></p>
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    </Card>
                                                                )
                                                            })
                                                            }
                                                        </GridList>
                                                    </Paper>
                                                </React.Fragment>
                                            )
                                        }
                                    })
                                }
                            </React.Fragment>
                            )
                        })
                        }
                    </div>
                </>
            }
            {
                props.topicsFetching &&
                <>
                    <LinearProgress data-test='commonUserTopicGridListDataFetching' className={classes.progressbar} variant="query" />
                </>
            }
        </>

    )
}

export default CommonUserTopicGridList;