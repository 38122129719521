import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as ReactLink } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import Link from '@material-ui/core/Link';
import moment from 'moment-timezone';
import { useState } from 'react';

type GroupContactType = {
    createdAt: string,
    modifiedAt: string,
    contactCode: string,
    contactFirstName: string,
    contactLastName: string,
    contactEmail: string,
    status: string,
    statusUpdated: string
}

type GroupDetailProfileCardProps = {
    group: GroupType;
    deleteGroupOnClick: any;
    editGroupOnClick: any;
    groupContacts: GroupContactType[];
}

type GroupType = {
    createdAt: string,
    modifiedAt: string,
    cgCode: string,
    cgName: string,
    displayName: string,
    status: string,
    contactCount: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: `${theme.spacing(2)}px`
    },
    backBtnRow: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        borderBottom: '1px solid #e8e8e8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    backBtnIcon: {
        color: '#0978A4'
    },
    linkContainer: {
        fontSize: theme.typography.pxToRem(15),
        display: 'flex',
        alignItems: 'center'
    },
    profileContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        display: 'flex',
        flexDirection: 'row',
    },
    leftIcon: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            marginTop: '.25em'
        }
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center',
        flex: '1 auto'
    },
    initAvatar: {
        backgroundColor: '#4A4A4A',
        color: 'white',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.spacing(5),
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nameContainer: {
        margin: '0 .8em',
        // maxWidth: '320px'
    },
    flexWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: '1 auto',
        marginLeft: '0.8em',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    groupName: {
        margin: '0 0 .25em 0',
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: '0.5px',
        display: 'flex',
        alignItems: 'center'
    },
    memberWrapper: {
        color: '#0978A4',
        margin: '.5em 0',
        fontSize: theme.typography.pxToRem(13)
    },
    memberLabel: {
        color: '#353636',
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(13)
    },
    groupCreatedTime: {
        margin: 0,
        fontSize: theme.typography.pxToRem(13),
        letterSpacing: '0.1px',
        color: '#353636',
        marginBottom: theme.spacing(1)
    },
    contactLabel: {
        marginLeft: '0.5em'
    },
    emailLink: {
        color: '#0978A4',
        textDecoration: 'underline'
    },
    editBtn: {
        color: '#8c8c8c',
        margin: '0 .2rem',
        cursor: 'pointer',
        transition: 'all ease 0.2s',
        '&:hover': {
            color: '#333',
        }
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
        }
    },
    removeGroupBtn: {
        color: '#0978A4',
        fontSize: theme.typography.pxToRem(15)
    }
  }),
);

const GroupDetailProfileCard = ( props: GroupDetailProfileCardProps ) => {
    const classes = useStyles();
    const content: any = {
        backBtnLabel: 'Back to All Groups',
        createdLabel: 'Added',
        deleteGroupLabel: 'Delete group'
    }
    const [ isShowMore, setShowMore ] = useState(false)

    return(
        <>
            <div className={ classes.root }>
                <div className={ classes.backBtnRow }>
                    <ReactLink to={'/personal/contacts'} style={{color: '#0978A4'}} className={ classes.linkContainer }>
                        <ArrowBackIosIcon className={ classes.backBtnIcon }/> { content.backBtnLabel }
                    </ReactLink>
                </div>
                <div className={ classes.profileContainer }>
                    <div className={ classes.leftIcon }>
                        <div className={ classes.initAvatar } >
                            { (props.group.displayName || '').charAt(0).toUpperCase() }
                        </div>
                    </div>
                    <div className={ classes.flexWrapper }>
                        <div className={ classes.leftSection }>
                            <div>
                                <p className={ classes.groupName }>
                                    { props.group.displayName } <EditIcon fontSize="small" className={ classes.editBtn } onClick={ props.editGroupOnClick }/>
                                </p>
                                { props.groupContacts.length > 0 && 
                                    <div className={ classes.memberWrapper }>
                                        <span className={ classes.memberLabel }>Members:</span>
                                        { !isShowMore && 
                                            <span>
                                                <span className={ classes.contactLabel }>
                                                    <ReactLink style={{color: '#0978A4'}} to={`/personal/contacts-detail/${props.groupContacts[0].contactCode}`}>
                                                        { props.groupContacts[0].contactFirstName } { props.groupContacts[0].contactLastName }
                                                    </ReactLink>
                                                </span> ... 
                                                <span onClick={ () => setShowMore(true) }> See more</span>
                                            </span>
                                        } 
                                        { isShowMore && 
                                            <>
                                                { props.groupContacts.map((contact: GroupContactType) => (
                                                    <span className={ classes.contactLabel } key={ contact.contactCode }>
                                                        <ReactLink style={{color: '#0978A4'}} to={`/personal/contacts-detail/${contact.contactCode}`}>
                                                            { contact.contactFirstName } { contact.contactLastName }; 
                                                        </ReactLink>
                                                    </span>
                                                ))}
                                                <span onClick={ () => setShowMore(false) }> Show less</span>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={ classes.detailsContainer }>   
                            <p className={ classes.groupCreatedTime }>
                                {/* <b>{ content.createdLabel }:</b> <Moment format="MMM D, YYYY">{ props.group.createdAt }</Moment> */}
                                <b>{ content.createdLabel }:</b> <span>{ moment.utc((props.group.createdAt)).local().format('MMM D, YYYY') }</span>
                            </p>
                            <Link className={ classes.removeGroupBtn } onClick={ props.deleteGroupOnClick }>{ content.deleteGroupLabel}</Link> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupDetailProfileCard;