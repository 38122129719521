import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import * as GroupDetailActionTypes from '../../constants/personalVault/groupDetailConstants';
import {
    getGroupItemsSharedByMeAPI,
} from '../../../shared/core/utils/apiFunctions';

export function* groupDetailWatcher() {
    yield takeLatest(GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST, getGroupItemsSharedByMeSaga);
}

function* getGroupItemsSharedByMeSaga(action) {
    try {
        const getUser = (state) => state.login;
        const { userAccessInfo } = yield all({
            userAccessInfo: select(getUser)
        });

        if(userAccessInfo !== undefined) {
            const files = yield call(
                getGroupItemsSharedByMeAPI,
                action.groupCode,
            )
            yield put({
                type: GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_SUCCESS,
                files: files.data.content
            });
        }
    } catch(err) {
        yield put({
            type: GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_FAILURE,
            err
        })
    }
}

export default groupDetailWatcher;