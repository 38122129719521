import React, { Component } from 'react';
import { connect } from 'react-redux';
import SideDrawer from '../../../shared/ui/sideDrawer/SideDrawer';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import { withRouter } from "react-router-dom";
import { RouteProps, RouteComponentProps } from 'react-router';

import './CvDrawer.scss';

type CvDrawerProps = {
  history: any;
  selectedGroup: any;
  drawerMenus: any;
  updateBreadcrumbItem: any;
  RemoveBreadcrumbItems: any;
  baseUrl: any;
  closeDrawer: any;
  updateDrawerMenu: any;
}

type CvDrawerPropsWithRouter = CvDrawerProps & RouteProps & RouteComponentProps<{}>

type CvDrawerStates = {}

class CvDrawer extends Component<CvDrawerPropsWithRouter, CvDrawerStates> {

  UNSAFE_componentWillReceiveProps() {
    if (window.location.pathname.includes('topics')) {
      this.props.updateDrawerMenu('topics')
    } else if (window.location.pathname.includes('topic-detail')) {
      this.props.updateDrawerMenu('topics')
    } else if (window.location.pathname.includes('contacts')) {
      this.props.updateDrawerMenu('contacts')
    } else if (window.location.pathname.includes('contact-detail')) {
      this.props.updateDrawerMenu('contacts')
    }
  }

  componentDidMount() {
    this.props.history.listen((location: any, action: any) => {
      if (window.location.pathname.includes('topics')) {
        this.props.updateDrawerMenu('topics')
      } else if (window.location.pathname.includes('topic-detail')) {
        this.props.updateDrawerMenu('topics')
      }
    })
  }

  menuSelect = (link: string) => {
    this.props.RemoveBreadcrumbItems(1);
    const baseUrl = `/${this.props.baseUrl}/group/${this.props.selectedGroup.grpCode}/${link}`
    this.props.updateBreadcrumbItem(this.props.selectedGroup.grpName, baseUrl);
    this.props.history.push(baseUrl);
    if (window.innerWidth < 600) {
      this.props.closeDrawer(false);
    }
    this.props.updateDrawerMenu(link);
  }

  render() {
    return (
      <div data-test='cvDrawer'>
        <SideDrawer menuItems={this.props.drawerMenus} menuSelect={this.menuSelect} />
      </div>

    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    selectedGroup: state.appUIStateReducer.selectedGroup,
    drawerMenus: state.appUIStateReducer.drawerMenuItems,
    baseUrl: state.appUIStateReducer.baseUrl,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
      type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
      itemName: itemName,
      itemLink: itemLink
    }),
    RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
      type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
      lastIndex: lastIndex
    }),
    updateDrawerMenu: (selectedMenu: string) => dispatch({
      type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
      selectedMenu: selectedMenu
    }),
  }
}

export const CvDrawerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CvDrawer);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CvDrawer));
