import { combineReducers } from 'redux';
import * as ShareItemsActionTypes from '../../constants/personalVault/shareItemsConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    itemsSharedWithMe: {
        fetching: false,
        files: [],
        error: null
    }
}

function itemsSharedWithMe (state = initState.itemsSharedWithMe, action) {
    switch (action.type) {
        case ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_REQUEST:
            return {
                ...state,
                fetching: true,
                files: []
            };
        case ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_SUCCESS:
            return {
                ...state,
                fetching: false,
                files: action.files
            };
        case ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_FAILURE:
            return {
                ...state,
                fetching: false,
                files: [],
                error: {
                    ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND
                }
            };
        default:
            return state;
    }
}

export default combineReducers({
    itemsSharedWithMe
});