import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../../../../shared/core/utils/icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FabButton from '../../../../shared/core/FabButton';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FileIcon from '../../../../shared/core/utils/FileTypeIcon';
import FolderIcon from '@material-ui/icons/Folder';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContactListGroupSearch from '../../contacts/shared/ContactListGroupSearch';
import ContactShareTable from './ContactShareTable';
import * as Types from '../../../../shared/core/ContactType';
import * as shareContactActionTypes from '../../../../redux/constants/personalVault/shareContactConstants';
import { connect } from 'react-redux';
import Error from '../../../../shared/errors/Error';

type shareContentProps = {
    classes: any;
    folderPath?: [];
    selectedItems: any[];
    contactsSelected: any[];
    shareResponse: any;
    getContactsSelected: any;
    addContactToSelect: any;
    updateContacts: any;
    removeContact: any
    modalOpen: boolean;
    handleModalClose: any;
    clearContacts: any;
    onActionComplete: any;
    shareError: any;
}

type shareContentState = {
    error: any,
    large: boolean
}

export const styles = (theme: any) => ({
    root:
    {
        fontFamily: theme.homefontfamilyregular
    },
    dialogPaper:
    {
        marginTop: '100px',
        [theme.breakpoints.down('lg')]: {
            height: '90%',
            width: '60%'
        },
        [theme.breakpoints.up('lg')]: {
            height: '90%',
            width: '754px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '90%',
            width: '90%'
        },
        backgroundColor: "#FAFAFA",
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '1.46rem !important',
        marginTop: theme.spacing(1),
        letterSpacing: '0.75px',
        lineHeight: '28px'
    },
    closeButton: {
        position: 'absolute' as 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    headerIcon: {
        width: '15%',
    },
    description:
    {
        color: '#444657',
        margin: '14px'
    },
    cancelBtn: {
        color: "#646D7D"
    },
    actionBtnPanel:
    {
        justifyContent: 'flex-end !important'
    },
    breadCrumb: {
        flex: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: "0.8rem",
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    paper:
    {
        height: "100%"
    },
    shareIcon: {
        marginRight: theme.spacing(2)
    },
    fileList: {
        overflowY: "scroll" as "scroll",
        maxHeight: '150px',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    iconColor: {
        color: '#627282',
        marginLeft: '5px'
    },
    itemName: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.9rem',
        letterSpacing: '0.44px',
        fontFamily: theme.homefontfamilyregular,
        fontWeight: 'bold' as 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        }
    },
    size: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.9rem',
        letterSpacing: '0.44px',
        fontFamily: theme.homefontfamilyregular
    },
    itemList: {
        backgroundColor: theme.palette.background.paper,
        "&$active": { backgroundColor: 'white', },
        "&$selected": { backgroundColor: 'white', },
        "&$selected:hover": { backgroundColor: 'white', },
        '&:hover': {
            backgroundColor: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    sharewith:
    {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        letterSpacing: '0.13px',
        color: '#474747',
        fontSize: '1.1rem',
        textTransform: 'uppercase !important' as 'uppercase',
        fontWeight: 'bold' as 'bold'
    },
    column:
    {
        flexDirection: 'column' as 'column'
    },
    avatar:
    {
        margin: 0,
        minWidth: 0
    },
    contactListContent: {
        flex: 'none',
        paddingBottom: 0,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    },
    contactTable: {
        minHeight: '150px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    }
});

class ShareContent extends Component<shareContentProps, shareContentState> {

    state: shareContentState = { error: null, large: true }

    UNSAFE_componentWillMount() {
        const mediaQuery = window.matchMedia('(max-width: 600px)');
        if (mediaQuery.matches) {
            this.setState({
                ...this.state,
                large: false,
            })
        } else {
            this.setState({
                ...this.state,
                large: true,
            })
        }
    }

    componentDidMount() {
        if (this.props.selectedItems.length === 1)
            this.props.getContactsSelected(this.props.selectedItems[0].itemCode);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.shareResponse != this.props.shareResponse && this.props.shareResponse) {
            if (this.props.shareResponse.itemResponsePayloads
                && this.props.shareResponse.itemResponsePayloads.length > 0) {
                this.props.handleModalClose();
                this.props.onActionComplete(true, "Sharing the item(s) with contacts is sucessful.");
                //Clear the response
                this.props.clearContacts();
            }
            else {
                //Check if it has sysmessages else take the user to error page.
                this.props.handleModalClose();
            }
        }
        if (prevProps.shareError != this.props.shareError && this.props.shareError) {
            this.setState({ error: this.props.shareError });
            // this.props.handleModalClose();
        }
    }

    handleClose = () => {
        this.props.clearContacts();
        this.props.handleModalClose();
    };

    onShare = () => {
        this.props.selectedItems && this.props.selectedItems.map((selectedItem: any) => {
            var contacts = this.props.contactsSelected.map((u: Types.ContactType) =>
                ({ code: u.code, permission: u.permission, type: u.type }));
            this.props.updateContacts(contacts, selectedItem.itemCode);
        })
    }

    handleContactChange = (selectedContact: Types.ContactType) => {
        if (selectedContact) {
            this.props.addContactToSelect(selectedContact);
        }
    };

    handleError = (error: any) => {
        this.setState({ error: error });
    }

    handleCloseErrorDisplay = () => {
        this.props.handleModalClose();
        this.props.onActionComplete(false);
    }

    renderItemList() {
        const { selectedItems, classes } = this.props;
        return (<List dense>
            {selectedItems && selectedItems.map((item: any) => {
                const title = (item.itemName.length >= 15 && !this.state.large) ? item.itemName.substring(0, 15) + "..." : item.itemName
                const labelId = `checkbox-list-secondary-label-${item.itemCode}`;
                let iconType: any;
                if (item.itemType === 'FILE') {
                    iconType = <FileIcon className={classes.iconColor} size={25} type={item.mimeType} title={item.itemName} />
                }
                else if (item.itemType === 'FOLDER') {
                    iconType = <FolderIcon type={item.mimeType} />
                }
                return (
                    <ListItem key={item.itemCode} classes={{ root: classes.itemList }}>
                        {<ListItemAvatar classes={{ root: classes.avatar }}>
                            {iconType}
                        </ListItemAvatar>}
                        <ListItemText classes={{ primary: classes.itemName }} id={labelId} primary={title} />
                        <ListItemSecondaryAction>
                            <div className={classes.size}
                            >
                                {item.size}&nbsp;Kb
                            </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>);
    }

    renderContactSelection() {
        const { classes } = this.props;
        return (
            <div className={classes.column}>
                <div className={classes.sharewith}>share with</div>
                <ContactListGroupSearch islarge={this.state.large}
                    handleChange={this.handleContactChange} handleError={this.handleError}></ContactListGroupSearch>
            </div>
        )
    }

    render() {
        const { classes, folderPath, modalOpen } = this.props;
        return (
            <div>
                {this.state.error != null ? (
                    <Error
                        error={this.state.error}
                        handleCloseErrorDisplay={this.handleCloseErrorDisplay}>
                    </Error>) : (null)
                }
                <Dialog
                    className={classes.root}
                    PaperProps={{ classes: { root: classes.dialogPaper } }}
                    open={modalOpen}
                    onClose={this.handleClose}
                    scroll='paper'
                    maxWidth={false}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    style={{ padding: '0px 0px 0px 0px' }}
                >
                    <MuiDialogTitle disableTypography className={classes.header}>
                        <div className={classes.shareIcon}>
                            <Icon name="Shared" stroke="#000000" />
                        </div>
                        <div>Share Documents</div>
                        <IconButton className={classes.closeButton} onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    <DialogContent className={classes.breadCrumb}>
                        {
                            (folderPath) ?
                                folderPath.map((current: any, index) => {
                                    if (index < folderPath.length - 1) {
                                        return <p key={index}>{current.itemName}&nbsp;/&nbsp;</p>
                                    }
                                    else {
                                        return <p key={index}>{current.itemName}</p>
                                    }
                                })
                                : null
                        }
                    </DialogContent>
                    <DialogContent className={classes.contactListContent} >
                        <Paper elevation={0}>
                            <div className={classes.fileList}>
                                {this.renderItemList()}
                            </div>
                            <Divider />
                            {this.renderContactSelection()}
                        </Paper>
                    </DialogContent>
                    <DialogContent className={classes.contactTable}>
                        <Paper elevation={0} className={classes.paper}>
                            <ContactShareTable islarge={this.state.large}></ContactShareTable>
                        </Paper>
                    </DialogContent>
                    <DialogActions className={classes.actionBtnPanel} >
                        <Button className={classes.cancelBtn} onClick={this.handleClose.bind(this)}>Cancel</Button>
                        <FabButton
                            id="Share"
                            disabled={false}
                            text="SAVE"
                            onClick={this.onShare.bind(this)} />
                    </DialogActions>
                </Dialog >
            </div>
        )
    }

}

const mapStateToProps = (state: any) => {
    return {
        contactsSelected: state.shareContactReducer.shareContacts,
        shareResponse: state.shareContactReducer.updateResponse,
        shareError: state.shareContactReducer.error
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getContactsSelected: (itemCode: any) => dispatch({
            type: shareContactActionTypes.GET_SHARE_CONTACTS,
            itemCode: itemCode
        }),
        addContactToSelect: (selectedItem: Types.ContactType) => dispatch({
            type: shareContactActionTypes.ADD_SHARE_CONTACTS,
            data: selectedItem
        }),
        removeContact: (selectedItem: Types.ContactType) => dispatch({
            type: shareContactActionTypes.REMOVE_SHARE_CONTACT,
            data: selectedItem
        }),
        clearContacts: () => dispatch({
            type: shareContactActionTypes.CLEAR_SHARE_CONTACTS,
        }),
        updateContacts: (contacts: any, itemCode: string, ) => dispatch({
            type: shareContactActionTypes.UPDATE_SHARE_CONTACTS_REQUEST,
            contacts: contacts,
            itemCode: itemCode,
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareContent));