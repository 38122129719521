import React, { Component } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import './ContactDetailProfileCard.scss';
import { convertPhoneNumber, formattedDate, formattedDateTime } from '../../../../shared/core/utils/functions';

type ContactType = {
    addedToGroup: string,
    connectedTopics: number,
    countryPhoneCode: number,
    email: string,
    externalId: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    sharedTopicNames: string[],
    status: string,
    userCode: string,
    lastLogin?: string
}

type ContactDetailProfileCardProps = {
    contact: ContactType;
}

class ContactDetailProfileCard extends Component<ContactDetailProfileCardProps> {
    state = {
        isShowMore: false,
        isModalOpen: false,
        modalName: ''
    }

    render() {
        return (
            <>
                {this.props.contact != null && <div className='root'>
                    <div className='profileContainer' data-test='contactDetailProfileCardComponent'>
                        <div className='leftIcon'>
                            <div className='contactProfileCardIcon' data-test='contactName'>
                                {this.props.contact.status === 'PENDING' ?
                                    (this.props.contact.lastName || '').charAt(0).toUpperCase() :
                                    (this.props.contact.firstName || '').charAt(0).toUpperCase() + (this.props.contact.lastName || '').charAt(0).toUpperCase()
                                }
                            </div>
                        </div>
                        <div className='flexWrapper'>
                            <div className='leftSection'>
                                <div>
                                    <p className='contactName'>
                                        {this.props.contact.lastName}, {this.props.contact.firstName}
                                    </p>
                                </div>
                                <p className='contact-meta-data'>
                                    <b>Email:</b> <span>{this.props.contact.email}</span>
                                </p>
                            </div>
                            <div className='detail-right-container'>
                                <p className='contact-meta-data'>
                                    { this.props.contact.phoneNumber ? 
                                        <>
                                            { this.props.contact.phoneNumber.includes('+') ? 
                                                <>
                                                    <b data-test='phone'>Phone: </b> <span>{convertPhoneNumber(this.props.contact.phoneNumber)}</span>
                                                </> : 
                                                <>
                                                    <b data-test='phone'>Phone: </b> <span>+{this.props.contact.countryPhoneCode} {convertPhoneNumber(this.props.contact.phoneNumber)}</span>
                                                </>
                                            }
                                        </> : null }
                                </p>
                                <p className='contact-meta-data'>
                                    <b>Added:</b> <span>{formattedDate(this.props.contact.addedToGroup)}</span>
                                </p>
                                { this.props.contact.lastLogin != null && <div className='login-stamp-wrapper'>
                                    <span className='last-login-label'>Last Login:</span>
                                    <span className='loginLabel'>
                                        {formattedDateTime(this.props.contact.lastLogin)}
                                    </span>
                                </div> }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
}


export default ContactDetailProfileCard;
