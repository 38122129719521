import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as TopicActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import { paginationUserTopics } from "../../../../../shared/core/utils/paginationDefaults";
import CommonUserTopicList from '../../../../../shared/ui/commonUserTopicList/commonUserTopicList';
import { Paper, AppBar, Hidden, IconButton, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { ReactComponent as GridIcon } from '../../../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../../../assets/list-view.svg';
import FilterBox from '../userTopicFilter/UserFilterBox';
import './TopicListUserView.scss';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import CommonUserTopicGridList from '../../../../../shared/ui/commonUserTopicGridList/commonUserTopicGridList';
import { ReactComponent as FilterFilledIcon } from '../../../../../assets/filter-filled.svg';
import { ReactComponent as FilterUnFilledIcon } from '../../../../../assets/filter-unfilled.svg';
import ReactGA from 'react-ga';
import * as GAConstants from '../../../../../shared/core/GAConstants';

type CategoryType = {
  grpCount: number,
  createdAt: string,
  modifiedAt: string,
  categoryCode: string,
  categoryName: string,
  categoryIconLabel: string,
  categroyIconColor: string
}

type TopicListViewProps = {
  selectedUser?: any,
  fetchTopics?: any,
  fetchUserProfile?: any,
  errorToNull?: any,
  topicData?: any,
  topicsFetching: boolean,
  selectTopic?: any,
  history: any,
  selectedTopic: any,
  updateTopics?: boolean,
  onUpdateComplete?: any,
  selectedGroup: any,
  groups: any,
  loggedIn: any,
  updateGroup: any,
  clearTopics: any,
  feedbackOpen: boolean,
  topicListHeaders: string[],
  updateTopicHeaders: any,
  features: any
}

interface TopicType {
  stCode: string,
  categoryName: string,
  createdAt: string,
  modifiedAt: string,
  categoryIconColor: string,
  catgeoryIconLabel: string,
  categoryCode: string,
  sharedTopicItemsCount: number,
  sharedTopicUsersCount: number,
}

interface groupType {
  groupCode: string,
  groupName: string,
  topics: TopicType[]
}

interface productType {
  productCode: string,
  groups: groupType[],
}

type TopicListViewStates = {}

export const content_type = {
  TEXT: 'text',
  DATE: 'date',
  ICON: 'icon',
  LINK: 'link'
}

export type TopicContent = {
  stCode: string,
  categoryName: string,
  createdAt: string,
  modifiedAt: string,
  categoryIconColor: string,
  categoryIconLabel: string,
  sharedTopicItemsCount: number,
  sharedTopicUsersCount: number,

}

class TopicListUserView extends Component<TopicListViewProps, TopicListViewStates> {

  state = {
    pagination: { ...paginationUserTopics },
    filter: {
      categoryCode: '',
      createdAt: '',
      modifiedAt: '',
    },
    openTopicFilter: false,
    listView: true,
    isModalOpen: false,
    modalName: '',
    noMoreData: false,
    openFilter: false,
    filterSelected: false,
    menuActions: {
      count: 1,
      menu: [
        {
          id: 'updateContact',
          label: 'Add/Edit Contacts',
          show: true
        }
      ]
    },
    allTopics: []
  };

  componentDidMount() {
    const { fetchTopics, loggedIn } = this.props;
    //Todo: have a loop to go through the list of products.
    //Get all the groups and iterate through the groups to get all the topic
    this.props.clearTopics();
    //Don't call api's if logout is in-progress or successful
    if(loggedIn.successful) {
      this.props.groups.forEach((element: any) => {
          fetchTopics(paginationUserTopics, null, loggedIn.user_profile.userCode, element.grpCode, element.grpName,
            'sdvtestvault');
      });
    }
    //Check if the device is small then turn on grid view
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.topicData != this.props.topicData) {
      var temp = JSON.parse(JSON.stringify(this.props.topicData));
      if (Array.isArray(this.props.features['hideOtherContactsForEndUsers']) && this.props.features['hideOtherContactsForEndUsers'].length > 0) {
        console.log("test")
        let hideContactsDict: any = {};
        this.props.features['hideOtherContactsForEndUsers'].forEach((catCode: string) => {
          hideContactsDict[catCode] = 1;
        })
        temp.forEach((item: productType) => {
          item.groups.forEach((group: groupType) => {
            if (group.topics.length > 0) {
              group.topics.forEach((topic: TopicType) => {
                if (hideContactsDict[topic.categoryCode] == 1) {
                  topic.sharedTopicUsersCount = 1
                }
              })
            }
          });
        });
      } 
      this.setState({ allTopics: temp });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (newProps.updateTopics != null && newProps.updateTopics) {
      this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
      this.props.onUpdateComplete();
    }
  }

  toggleViews = () => {
    this.setState({ listView: !this.state.listView })
    this.setState({ selectedFiles: [] })
  }

  toggleFilter = () => {
    this.setState({ openFilter: !this.state.openFilter })
  }

  onFileOpen = (topic: TopicType, groupCode: string) => {
    this.props.selectTopic(topic)
    var groupFound = this.props.groups.find((grp: any) => grp.grpCode === groupCode);
    if (null != groupFound) {
      this.props.updateGroup(groupFound);
      //Change the selected group here
      this.props.history.push(`/customer/group/${groupCode}/topic-detail/${topic.stCode}`);
      ReactGA.event({
        category: GAConstants.USER_EVENT_CATEGORY,
        action: GAConstants.TOPIC_SELECTION_ACTION,
        //label: topic.categoryName
      });
    }
    else {
      this.props.history.push(`/customer`);
    }

  }

  filterTopics = (id: string, group: groupType[], created: any, modified: any) => {
    var temp = JSON.parse(JSON.stringify(this.props.topicData));
    //Check if the product category already selected. else get the entire list
    temp.map((product: productType) => {
      if (group != null && group.length > 0) {
        product.groups = product.groups.filter((x: groupType) => {
          return group.find((ele: groupType) => ele.groupCode === x.groupCode)
        });
      }
      else {
        temp = JSON.parse(JSON.stringify(this.props.topicData));
      }
    });


    //Check if either created and modified defined apply the filter on group selection/entire list.
    temp.map((product: productType) => {
      if (created != null) {
        product.groups.map((group: groupType) => {
          group.topics = group.topics.filter((topic: TopicType) => {
            var newDate = new Date(topic.createdAt);
            return newDate >= created.value
          });
        });
      }
      if (modified != null) {
        product.groups.map((group: groupType) => {
          group.topics = group.topics.filter((topic: TopicType) => {
            var newDate = new Date(topic.modifiedAt);
            return newDate >= modified.value
          });
        });
      }
    });
    this.setState({ allTopics: temp })
  }

  resetFilter = () => {
    var temp = JSON.parse(JSON.stringify(this.props.topicData));
    this.setState({ allTopics: temp, filterSelected: false });
  }

  onHeaderFiltering = (filteredHeaders: string[]) => {
    this.props.updateTopicHeaders(filteredHeaders)
  }

  filterSelected = (selected: boolean) => {
    this.setState({ filterSelected: selected });
  }

  tableMarginHeight = 200

  render() {

    let extratableheight = 0;
    extratableheight = this.props.feedbackOpen ? extratableheight + 70 : extratableheight
    return (
      <Paper>
        <AppBar position="static" className="topic-app-bar-wrapper" data-test="topic-app-bar-wrapper">
          <div className="action-bar">
            {this.state.filterSelected &&
              <IconButton title="Filter Filled" className="iconBtn-wrapper" data-test="filter-filled-icon" ria-label="views" onClick={this.toggleFilter}>
                <FilterFilledIcon title="Filter Filled" className="iconBtn" />
              </IconButton>
            }
            {!this.state.filterSelected && <IconButton title="Filter" data-test="filter-unfilled-icon" className="iconBtn-wrapper" aria-label="views" onClick={this.toggleFilter}>
              <FilterUnFilledIcon title="Filter" className="iconBtn" />
            </IconButton>
            }
            <Hidden smDown>
              {this.state.listView &&
                <IconButton className="iconBtn-wrapper" data-test="listview" aria-label="views" onClick={this.toggleViews}>
                  <GridIcon className="iconBtn" />
                </IconButton>
              }
              {!this.state.listView &&
                <IconButton className="iconBtn-wrapper" data-test="gridview" aria-label="views" onClick={this.toggleViews}>
                  <ListViewIcon className="iconBtn" />
                </IconButton>
              }
            </Hidden>
          </div>
        </AppBar>
        <ExpansionPanel className="filter-collapse-wrapper" expanded={this.state.openFilter}>
          <ExpansionPanelSummary style={{ display: 'none' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography className="heading">
              <FilterListIcon/>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="filter-collapse-details">
            <FilterBox filterTopics={this.filterTopics} resetFilter={this.resetFilter} data-test="filterbox"
              filterselected={this.filterSelected} groups={
                this.props.topicData.reduce((resultArray: groupType[], product: productType) => {
                  return resultArray.concat(product.groups)
                }, [])
              } />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div>
          <Hidden smDown>
            {this.state.listView &&
              <CommonUserTopicList
                data-test="common-topic-list"
                productTopics={this.state.allTopics}
                // tableMarginHeight={this.state.openFilter ? 295 + extratableheight : 220 + extratableheight}
                tableMarginHeight={0}
                onFileOpen={this.onFileOpen}
                topicsFetching={this.props.topicsFetching}
                userTopicListHeaders={this.props.topicListHeaders}
                onUserHeadersFiltering={this.onHeaderFiltering}
              />
            }
            {!this.state.listView &&
              <CommonUserTopicGridList
                productTopics={this.state.allTopics}
                // tableMarginHeight={this.state.openFilter ? 295 + extratableheight : 220 + extratableheight}
                tableMarginHeight={0}
                onFileOpen={this.onFileOpen}
                topicsFetching={this.props.topicsFetching}
              />
            }
          </Hidden>
          <Hidden mdUp>
            <CommonUserTopicGridList
              productTopics={this.state.allTopics}
              // tableMarginHeight={this.state.openFilter ? 140 + extratableheight : 40 + extratableheight}
              tableMarginHeight={0}
              onFileOpen={this.onFileOpen}
              topicsFetching={this.props.topicsFetching}
            />
          </Hidden>
        </div>
      </Paper >
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    selectedGroup: state.appUIStateReducer.selectedGroup,
    topicData: state.topicReducer.userTopicList.products,
    topicsFetching: state.topicReducer.userTopicList.fetching,
    selectedTopic: state.appUIStateReducer.selectedTopic,
    groups: state.homeReducer.groupsInfo.response.data.content,
    loggedIn: state.login,
    feedbackOpen: state.appUIStateReducer.feedbackOpen,
    topicListHeaders: state.appUIStateReducer.topicListHeaders,
    features: state.homeReducer.features
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateGroup: (group: any) => dispatch({
      type: AppUIStateActionTypes.APP_STATE_UPDATE_GROUP,
      group: group
    }),
    clearTopics: () => dispatch(
      {
        type: TopicActionTypes.CLEAR_TOPICS
      }
    ),
    // fetchUserProfile: (grpCode, userCode) => dispatch({type: TopicS_LIST_API_GET_USER_PROFILE_REQUEST, grpCode, userCode}),
    fetchTopics: (pagination: any, filter: any, userCode?: any, grpCode?: any, grpName?: any,
      productCode?: any) => dispatch({
        type: TopicActionTypes.TOPICS_API_GET_ALL_TOPICS_REQUEST,
        pagination, filter,
        userCode: userCode,
        grpCode: grpCode,
        grpName: grpName,
        productCode: productCode
      }),
    selectTopic: (topic: TopicType) => dispatch({ type: TopicActionTypes.TOPIC_API_SELECT_TOPIC, selectedTopic: topic }),
    updateTopicHeaders: (filteredHeaders: string[]) => dispatch({
      type: AppUIStateActionTypes.UPDATE_TOPIC_LIST_HEADERS,
      filteredHeaders: filteredHeaders
    })
  };
};

export const TopicListUserComponent = connect(mapStateToProps, mapDispatchToProps)(TopicListUserView);

export default withRouter(TopicListUserComponent);