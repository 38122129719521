import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, IconButton, List, AppBar, Toolbar, ListItem, ListItemIcon, ListItemText, Divider, Hidden, InputBase } from '@material-ui/core';
import * as AppUIStateActionTypes from '../redux/constants/shared/appStatesConstants';
import * as TopicDetailActionTypes from '../redux/constants/customerVault/topicConstants';

import IdleTimer from 'react-idle-timer'

import FolderSharedIcon from '@material-ui/icons/FolderShared';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import { ReactComponent as HubIcon } from '../assets/hub.svg';
import { ReactComponent as MenuIcon } from '../assets/menu-white.svg';

import ProdSelectDrawer from '../components/shared/prodSelectDrawer/ProdSelectDrawer';
import CvDrawer from '../components/customerVault/drawer/CvDrawer';
import PvDrawer from '../components/personalVault/drawer/PvDrawer';
import CvToolbarActions from '../components/customerVault/toolbarActions/CvToolbarActions';
import PvToolBarActions from '../components/personalVault/toolbarActions/PvToolBarActions';
import CvHomePage from '../pages/customerVault/main';
import PvHomePage from '../pages/personalVault/main.js';
import CommonModal from '../shared/ui/commonModal/commonModal';
import CommonSwipeableDrawer from '../shared/ui/commonSwipeableDrawer/commonSwipeableDrawer';
import './index.scss';
import LogoutConfirmationContent from '../shared/ui/logoutConfirmation/LogoutConfirmation';
import SessionTimeoutReloadContent from '../shared/ui/sessionTimeoutReload/SessionTimeoutReload';
import { optionHandle } from '../shared/core/utils/helper';
import TimeOutCounter from '../components/shared/timeOutCounter/TimeOutCounter';
import Loading from '../shared/ui/loading/Loading';
import Tooltip from '@material-ui/core/Tooltip';
import { con } from '../shared/config/config';
import { selectLogo } from '../shared/core/utils/functions';

const DEFAULT_PAGE_TIMEOUT = 1000 * 60 * 10 // 10 Min
const ESIGN_EMBED_PAGE_TIMEOUT = 1000 * 60 * 60 // 60 Min

const CvHomePageElement = CvHomePage as React.ElementType;

type UniversalHomeProps = {
    loadtheme: any;
    initDrawer: any;
    history: any;
    baseUrl: any;
    productInfo: any;
    selectedGroup: any;
    breadcrumbItemList: any;
    RemoveBreadcrumbItems: any;
    updateBreadcrumbItem: any;
    emptyBreadcrumbItems: any;
    changeTheme: any;
    commonModal: any;
    updateCommonModal: any;
    vaultName: any;
    roles: any;
    updateDrawerMenu: any;
    feedbackFeatures: any;
    feedbackFetching: boolean;
    feedbackError: any;
    updateCommonSwipeableDrawer: any;
    commonSwipeableDrawerValue: any;
    user_profile: any;
    ceremonyToken: any;
    getTopicDetails: any;
    selectedTopic: any;
}

type UniversalHomeStates = {}

class UniversalHome extends Component<UniversalHomeProps, UniversalHomeStates> {

    idleTimer: any;
    constructor(props: Readonly<UniversalHomeProps>) {
        super(props);
        this.idleTimer = React.createRef();
    }
    state = {
        drawerOpen: true,
        vaultName: 'cv',
        openSearchbar: false,
        searchContext: '',
        isModalOpen: false,
        prodSelectPanelState: false,
        userRole: '',
        siteLoading: false,
        anchor: 'right',
        esignEmbedOpen: false,

    }
    intervalId = 0

    UNSAFE_componentWillMount() {
        if (window.location.pathname.includes('personal')) {
            this.props.history.push(`/personal/myfiles`);
            this.setState({ vaultName: 'pv' });
        } else {
            this.props.emptyBreadcrumbItems();
            this.setState({ vaultName: 'cv' });
        }

        if (this.props.roles.length > 0) {
            this.props.roles.includes('INST_USER') ? this.setState({ userRole: 'INST_USER' }) : this.setState({ userRole: 'END_USER' })
        }

        if (window.innerWidth < 820) {
            this.setState({ drawerOpen: false });
        }
    }

    componentDidMount() {
        this.props.initDrawer(this.state.vaultName);
        clearInterval(this.intervalId)

    }

    componentDidUpdate(prevProps: any) {
        // Handle Session Timeout for DocuSign Embed page
        // When the Docusign Embed is Open we set timeout to 60 min.
        this.handleSessionTimeOutDocuSign(prevProps)
    }

    handleSessionTimeOutDocuSign = (prevProps: any) => {
        if (prevProps.ceremonyToken.esignEmbadedUrl !== this.props.ceremonyToken.esignEmbadedUrl) {
            if (!this.props.ceremonyToken.requesting && this.props.ceremonyToken.esignEmbadedUrl !== null) {
                {
                    this.idleTimer.current.pause()
                    const that = this
                    this.intervalId = window.setInterval(function () {
                        that.props.getTopicDetails(that.props.selectedTopic[0].stCode) // Get new websess cookie every 10 min by calling API.
                        if (that.idleTimer.current !== null)
                            if (that.idleTimer.current.getElapsedTime() > ESIGN_EMBED_PAGE_TIMEOUT) {
                                that.idleTimer.current.resume()
                                clearInterval(that.intervalId)
                            }

                    }, DEFAULT_PAGE_TIMEOUT);
                }
            }
            else {
                if (this.idleTimer.current !== null)
                    this.idleTimer.current.resume()
                clearInterval(this.intervalId)
            }
        }
    }

    handleToggleDrawer = (openStatus: boolean) => {
        this.setState({ drawerOpen: openStatus });
        if (openStatus === false) {
            this.setState({ prodSelectPanelState: false });
        }
    }

    openSearchbar = (open: boolean) => {
        this.setState({ openSearchbar: open });
    }

    searchHandler = (val: string) => {
        this.setState({ searchContext: val });
    }

    cleanSearchContext = () => {
        this.setState({ searchContext: '' });
    }

    changeVault = (vault: string) => {
        this.setState({ vaultName: vault });
        this.props.initDrawer(vault);

        if (vault === 'cv') {
            this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/contacts`)
            this.props.emptyBreadcrumbItems();
        } else {
            this.props.history.push(`/personal/myfiles`);
        }

        if (window.innerWidth < 600) {
            this.setState({ drawerOpen: false });
        }
    }

    openProdSelectPanel = () => {
        if (this.state.vaultName === 'cv') {
            this.setState({ prodSelectPanelState: !this.state.prodSelectPanelState });
            this.setState({ drawerOpen: true });
        }
    }

    onProdSelected = () => {
        this.setState({ prodSelectPanelState: false });
        this.props.updateDrawerMenu('contacts');
        this.setState({ siteLoading: true });
        setTimeout(() => {
            this.setState({ siteLoading: false });
        }, 1500)
    }

    closeDrawer = () => {
        this.setState({ drawerOpen: false });
    }

    onRightSlide = () => {
        this.props.updateCommonSwipeableDrawer('right', !this.props.commonSwipeableDrawerValue.openState)
    }

    onClickedCallback = (itemID: any) => {
        console.log(`Profile menu item been clicked`)
    }

    onOutsideClickedCallback = (event: any) => {
        if (this.props.commonSwipeableDrawerValue) {
            this.props.updateCommonSwipeableDrawer('right', false)
        }
    }

    onDrawerClose = () => {
        this.props.updateCommonSwipeableDrawer('right', false)
    }

    handleActionDialogClose = () => {
        this.setState({ isModalOpen: false })
    }

    onModalClose = () => {
        this.props.updateCommonModal(false, null)
    }

    goOldExp = () => {
        this.props.history.push('/home')
    }

    onLogoutConfirm = () => {
        optionHandle('Log Out');
        this.props.updateCommonModal(false, null)
    }

    onLogoutClick = () => {
        let content = <LogoutConfirmationContent noAction={this.onModalClose} yesAction={this.onLogoutConfirm} />;
        this.props.updateCommonModal(true, content);
    }

    onIdle = (e: any) => {
        let content = <TimeOutCounter onClose={this.onTimerClose} />;
        this.props.updateCommonModal(true, content);
    }

    onUserActive = (e: any) => {
        // this.props.updateCommonModal(false, null);
    }

    truncate = (str: any) => {
        return str.length > 27 ? str.substring(0, 25) + "..." : str;
    }

    onRefreshPage = () => {
        this.props.updateCommonModal(false, null);
        window.location.reload();
    }

    onTimerClose = () => {
        let content = <SessionTimeoutReloadContent yesAction={this.onRefreshPage} />;
        this.props.updateCommonModal(true, content);
    }

    render() {
        return (
            <>
                <div className='main-wrapper' data-test='universalHome'>
                    <AppBar
                        position='relative'
                        className='app-bar'>
                        <Toolbar className='toolbar'>
                            <div className='toolbar-left'>
                                {this.state.userRole !== 'END_USER' &&
                                    <IconButton
                                        data-test='opendrawer btn'
                                        color='inherit'
                                        aria-label='open drawer'
                                        onClick={() => this.handleToggleDrawer(!this.state.drawerOpen)}
                                        edge='start'
                                        className='menu-button'>
                                        <MenuIcon title="Main Menu" />
                                        {/* <img src={ MenuIcon } /> */}
                                    </IconButton>
                                }
                                <Hidden xsDown>
                                    <div className='toolbar-title'>
                                        {selectLogo(con.PRODUCT_CODE)}
                                    </div>
                                </Hidden>
                            </div>
                            <div className='spacer'></div>
                            {this.state.openSearchbar &&
                                <div className='search-wrapper' onBlur={() => this.openSearchbar(false)}>
                                    <div className='search-icon'>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="Search…"
                                        className='search-input'
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={e => this.searchHandler(e.target.value)} />
                                    {this.state.searchContext.length > 0 && <div className='clear-icon' onClick={() => this.cleanSearchContext()}>
                                        <CancelIcon />
                                    </div>}
                                </div>
                            }
                            <div className='toolbar-right'>
                                {this.state.vaultName === 'cv' && <CvToolbarActions onLogoutClick={this.onLogoutClick} onRightSlide={this.onRightSlide} openSearchbar={this.openSearchbar} showSearchIcon={!this.state.openSearchbar} />}
                                {this.state.vaultName === 'pv' && <PvToolBarActions onLogoutClick={this.onLogoutClick} openSearchbar={this.openSearchbar} showSearchIcon={!this.state.openSearchbar} />}
                            </div>
                        </Toolbar>
                        <Hidden smUp>
                            {this.state.drawerOpen &&
                                <Toolbar className='toolbar'>
                                    <div className='toolbar-title'>
                                        {selectLogo(con.PRODUCT_CODE)}
                                    </div>
                                </Toolbar>
                            }
                        </Hidden>
                    </AppBar>
                    <main className='content-wrapper' onClick={this.onOutsideClickedCallback}>
                        {this.state.userRole !== 'END_USER' &&
                            <Drawer
                                variant='permanent'
                                className={clsx('drawer', { ['drawer-open']: this.state.drawerOpen, ['drawer-close']: !this.state.drawerOpen })}
                                classes={{ paper: clsx({ ['drawer-open']: this.state.drawerOpen, ['drawer-close']: !this.state.drawerOpen }) }}>
                                <div className='drawer-toolbar'></div>
                                <Divider />
                                {this.state.vaultName === 'cv' && this.state.userRole === 'INST_USER' &&
                                    <List>
                                        <ListItem button onClick={() => this.openProdSelectPanel()} className='menu-item' data-test='open-prod-select-panel'>
                                            {!this.state.prodSelectPanelState &&
                                                <>
                                                    <ListItemIcon className='list-icon'>
                                                        <HubIcon title="Product Groups" />
                                                    </ListItemIcon>
                                                    <ListItemText className='prod-list-title-wrapper'
                                                        primary={<Tooltip title={this.props.productInfo.data.prdName}><span className='prod-main-title'>{this.truncate(this.props.productInfo.data.prdName)}</span></Tooltip>}
                                                        secondary={<Tooltip title={this.props.selectedGroup.grpName}><span className='prod-sub-title'>{this.truncate(this.props.selectedGroup.grpName)}</span></Tooltip>} />
                                                </>
                                            }
                                            {this.state.prodSelectPanelState &&
                                                <>
                                                    <ListItemIcon className='list-icon'>
                                                        <ClearIcon />
                                                    </ListItemIcon>
                                                    <ListItemText secondary={<span className="prod-sub-title">SWITCH PRODUCT / GROUP</span>} />
                                                </>
                                            }
                                        </ListItem>
                                    </List>
                                }
                                <Divider />
                                {this.state.prodSelectPanelState && <div className='prod-select-drawer-wrapper'>
                                    <ProdSelectDrawer onProdSelected={this.onProdSelected} data-test='prod-select-drawer'/>
                                    {/* <ProdSelectDrawer/> */}
                                </div>}
                                {this.state.userRole !== 'INST_USER' &&
                                    <List>
                                        <ListItem button onClick={() => this.changeVault('cv')} data-test='customer-vault-icon' className={clsx('menu-item', { ['menu-active']: this.state.vaultName === 'cv' })}>
                                            <ListItemIcon className='list-icon'>
                                                <FolderSharedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Customer Vault'} />
                                        </ListItem>

                                        <ListItem button onClick={() => this.changeVault('pv')} data-test='personal-vault-icon' className={clsx('menu-item', { ['menu-active']: this.state.vaultName === 'pv' })}>
                                            <ListItemIcon className='list-icon'>
                                                <FolderIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Personal Vault'} />
                                        </ListItem>

                                    </List>
                                }
                                <Divider />
                                {this.state.vaultName === 'cv' && this.state.userRole === 'INST_USER' && <CvDrawer closeDrawer={this.closeDrawer} />}
                                {this.state.vaultName === 'pv' && this.state.userRole !== 'INST_USER' && <PvDrawer openDrawer={this.state.drawerOpen} closeDrawer={this.closeDrawer} />}
                                <span className='spacer'></span>
                            </Drawer>
                        }
                        {this.state.drawerOpen &&
                            <Hidden smUp>
                                <div className='mask-layer' onClick={() => this.handleToggleDrawer(false)}></div>
                            </Hidden>
                        }

                        <div className='content'>
                            {this.state.vaultName === 'cv' && <CvHomePageElement theme></CvHomePageElement>}
                            {this.state.vaultName === 'pv' && <PvHomePage />}
                        </div>
                    </main>
                </div>
                <CommonModal
                    modalOpen={this.props.commonModal.openState || false}
                    modalClose={this.onModalClose}
                    content={this.props.commonModal.content} />
                <CommonSwipeableDrawer className="common-swipeable-drawer"
                    toggleDrawer={this.onRightSlide}
                    onClickedCallback={this.onClickedCallback}
                    anchor={this.state.anchor}
                    content={this.props.user_profile}
                    modalClose={this.onDrawerClose}
                    swipeDrawer={this.props.commonSwipeableDrawerValue.openState}
                />
                <IdleTimer
                    ref={this.idleTimer}
                    onIdle={this.onIdle}
                    onActive={this.onUserActive}
                    debounce={250}
                    timeout={DEFAULT_PAGE_TIMEOUT} />
                {this.state.siteLoading && <Loading loadingText="Loading..." />}
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        baseUrl: state.appUIStateReducer.baseUrl,
        productInfo: state.homeReducer.productInfo.response,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        drawerMenus: state.appUIStateReducer.drawerMenuItems,
        breadcrumbItemList: state.appUIStateReducer.breadcrumbItemList,
        commonModal: state.appUIStateReducer.commonModal,
        vaultName: state.appUIStateReducer.vaultName,
        roles: state.login.user_profile.roles,
        feedbackFetching: state.featureReducer.fetching,
        feedbackFeatures: state.featureReducer.response,
        feedbackError: state.featureReducer.error,
        commonSwipeableDrawerValue: state.appUIStateReducer.commonSwipeableDrawerValue,
        user_profile: state.login.user_profile,
        ceremonyToken: state.eSignReducer.ceremonyToken,
        selectedTopic: state.appUIStateReducer.selectedTopic
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadtheme: () => dispatch({ type: 'FETCH_THEME_S3_REQUEST' }),
        initDrawer: (vaultName: string) => dispatch({
            type: AppUIStateActionTypes.INIT_DRAWER,
            vaultName: vaultName
        }),
        updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
        emptyBreadcrumbItems: () => dispatch({
            type: AppUIStateActionTypes.EMPTY_BREADCRUMB_ITEM
        }),
        changeTheme: (vaultName: string) => dispatch({
            type: 'UPDATE_THEME_LOCAL',
            vaultName: vaultName
        }),
        updateCommonModal: (openState: boolean, content: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_COMMON_MODAL,
            openState: openState,
            content: content
        }),
        updateDrawerMenu: (selectedMenu: string) => dispatch({
            type: AppUIStateActionTypes.UPDATE_DRAWER_MENU,
            selectedMenu: selectedMenu
        }),
        updateCommonSwipeableDrawer: (anchor: any, openState: boolean) => dispatch({
            type: AppUIStateActionTypes.UPDATE_COMMON_SWIPEABLE_DRAWER,
            openState: openState,
            anchor: anchor
        }),
        getTopicDetails: (topicCode: string) => dispatch({
            type: TopicDetailActionTypes.TOPICS_API_GET_TOPICS_DETAILS_REQUEST,
            topicCode: topicCode,
        }),
    }
}

export const UniversalHomeComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(UniversalHome);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UniversalHome));