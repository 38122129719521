import {
    FETCH_THEME_S3_REQUEST,
    FETCH_THEME_S3_SUCCESSFUL,
    FETCH_THEME_S3_FAILURE,
} from '../../constants/shared/themeConstants';

import { getThemeFile } from '../../../shared/core/utils/apiFunctions';
import { takeLatest, call, put } from "redux-saga/effects";

function* themesWatcher() {
    yield takeLatest(FETCH_THEME_S3_REQUEST, fetchThemeFile);
}

function* fetchThemeFile() {
    try {
        const result = yield call(getThemeFile, 'theme.js');

        var resultStr = result.data;

        if (typeof resultStr != 'string') {
            resultStr = JSON.stringify(resultStr)
        }

        if (checkValidJSON(resultStr)) {
            if (result && result.data) {
                yield put({ type: FETCH_THEME_S3_SUCCESSFUL, responseInfo: result });
            }
        }
        else {
            yield put({ type: FETCH_THEME_S3_FAILURE, error: null });
        }

    } catch (error) {
        yield put({ type: FETCH_THEME_S3_FAILURE, error: error });
    }
}

function checkValidJSON(text) {
    try {
        var json = text
        JSON.parse(json);
        return true

    } catch (e) {
        if (e instanceof SyntaxError) {
            return false
        } else {
            return true
        }
    }
}

export default themesWatcher;
export { fetchThemeFile };
