import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import fileIcon from '../../../../../assets/file-white.svg';
import infoOutlineIcon from '../../../../../assets/pvd-alert-info-outline.svg';
import { TOGGLE_FILE_DETAIL_MENU } from '../../../../../redux/constants/personalVault/myFilesConstants';
import * as FilePreviewActionTypes from '../../../../../redux/constants/shared/filePreviewConstants';
import * as TopicFileConstants from '../../../../../redux/constants/customerVault/topicFileConstants';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import './PreviewWindow.scss';
import { con } from '../../../../../shared/config/config'
import { Document, Page, pdfjs } from "react-pdf";
import printJS from 'print-js';
import { optionHandle } from '../../../../../shared/core/utils/helper';
import * as GAConstants from '../../../../../shared/core/GAConstants';
import ReactGA from 'react-ga';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ReactComponent as InfoIcon } from '../../../../../assets/info-white.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewWindowProps extends RouteComponentProps<{}> {
    history: any,
    selectedFileToPreview: any,
    toggleFileDetailMenu: any,
    menuOpen: boolean,
    topicCode: any,
    grpCode: any,
    selectedGroup: any;
    clearPreview: any;
    downloadFile: any;
    downloadFiles: any;
    RemoveBreadcrumbItems: any;
    breadcrumbItemList: any;
    selectFileToPreview: any;
    onClose: any;
    isEndUser: boolean;
    showCeremonyCancelledError: boolean;
    showAwaitingDocMessage: boolean;
    startPreview: (topicCode: string, itemCode: string, fileName: string) => void;
}

enum docType {
    pdf,
    image,
    none
}

type PreviewWindowStates = {
    downloadFileName: any,
    scale: any,
    doc: any,
    numPages: number,
    pageNumber: number,
    docType: docType,
    previewError: string,
    fileFetching: boolean,
    anchorEl: any,
}

const IMAGE_TYPES: string[] = ["image/jpeg", "image/png", "image/svg+xml", "image/tiff", "image/heic", "image/heif", "image/apng", "image/avif", "image/gif", "image/webp", "image/bmp", "image/vnd.microsoft.icon",]

class PreviewWindow extends Component<PreviewWindowProps, PreviewWindowStates> {
    imageRef: any;
    state = {
        downloadFileName: '',
        previewError: '',
        scale: 1,
        doc: '',
        numPages: 0,
        pageNumber: 1,
        docType: docType.none,
        fileFetching: true,
        anchorEl: null,
        blob: new Blob,
    }


    constructor(props: any) {
        super(props);
        this.imageRef = React.createRef();
    }

    getDocType(): docType {
        if (this.state.docType && this.state.docType !== docType.none)
            return this.state.docType
        if (this.props.selectedFileToPreview.mimeType === 'application/pdf')
            return docType.pdf
        if (IMAGE_TYPES.indexOf(this.props.selectedFileToPreview.mimeType) >= 0)
            return docType.image
        return docType.none
    }

    getItemCode(): string {
        let itemCode = this.props.selectedFileToPreview.itemCode
        if (this.getDocType() == docType.pdf && this.props.selectedFileToPreview.signedItem != null) {
            if (this.props.selectedFileToPreview.signedItem.code != null) {
                itemCode = this.props.selectedFileToPreview.signedItem.code
            }
        }

        return itemCode;
    }

    getDownloadURL(): string {
        return `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${this.props.selectedGroup.grpCode}/topics/${this.props.topicCode}/files/${this.getItemCode()}/preview`;
    }

    componentDidMount() {
        if(this.props.showCeremonyCancelledError) {
            let errorState: any = { fileFetching: false, docType: docType.image }
            errorState.previewError = 'We\'re sorry, the request has been cancelled. Please go back or refresh your web browser.';
            this.props.clearPreview();
            this.setState(errorState);
        }
        else if (this.props.selectedFileToPreview) {
            this.setState({ docType: this.getDocType(), downloadFileName: this.getDownloadURL() });
            if (this.getDocType() === docType.image)
                this.props.startPreview(this.props.topicCode, this.getItemCode(), this.props.selectedFileToPreview.itemName)
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.fileFetching && this.state.docType === docType.image) {
            const prevDownloadStatus = (prevProps && prevProps.downloadFiles && prevProps.downloadFiles.length > 0) ?
                prevProps.downloadFiles[0].downloading : true;
            const currDownloadStatus = (this.props.downloadFiles && this.props.downloadFiles.length > 0) ?
                this.props.downloadFiles[0].downloading : true;
            if (!currDownloadStatus && prevDownloadStatus !== currDownloadStatus) {
                let updatedState: any = { fileFetching: false }
                let errorStatus = 200
                if (this.props.downloadFiles[0].error) {
                    errorStatus = (this.props.downloadFiles[0].error.status) ? this.props.downloadFiles[0].error.status : 500
                    if (errorStatus === 400) {
                        updatedState.previewError = 'Antivirus Scan might be in progress. Please try again later.';
                        ReactGA.event({
                            category: this.props.isEndUser ? GAConstants.USER_EVENT_CATEGORY : GAConstants.ASSOCIATE_EVENT_CATEGORY,
                            action: GAConstants.FILE_PREVIEW_AV_SCAN,
                        });
                    } else if (errorStatus === 401) {
                        optionHandle('Log Out');
                    } else {
                        updatedState.previewError = 'Failed to Load the File.';
                        ReactGA.event({
                            category: this.props.isEndUser ? GAConstants.USER_EVENT_CATEGORY : GAConstants.ASSOCIATE_EVENT_CATEGORY,
                            action: GAConstants.FILE_PREVIEW_ERROR,
                        });
                    }
                } else if (this.props.downloadFiles[0].response) {
                    updatedState.blob = this.props.downloadFiles[0].response
                } else {
                    updatedState.previewError = 'Failed to Load the File.';
                    ReactGA.event({
                        category: this.props.isEndUser ? GAConstants.USER_EVENT_CATEGORY : GAConstants.ASSOCIATE_EVENT_CATEGORY,
                        action: GAConstants.FILE_PREVIEW_ERROR,
                    });
                }
                this.props.clearPreview();
                this.setState(updatedState);
            }
        }
    }

    toggleMenu = () => {
        this.props.toggleFileDetailMenu(!this.props.menuOpen, this.props.selectedFileToPreview.itemCode)
    }

    goMyFilesPage = () => {
        this.props.selectFileToPreview(null);
        this.props.clearPreview();
        this.props.toggleFileDetailMenu(false, '');
        this.props.onClose(true);
    }

    onDocumentLoadSuccess = (pdfwriter: any) => {
        this.setState({ numPages: pdfwriter._pdfInfo.numPages });
        ReactGA.event({
            category: this.props.isEndUser ? GAConstants.USER_EVENT_CATEGORY : GAConstants.ASSOCIATE_EVENT_CATEGORY,
            action: GAConstants.FILE_PREVIEW_SUCCESS,
        });
    }

    onDocumentLoadFailure = (error: any) => {
        if (error.message.includes(400)) {
            this.setState({ previewError: 'Antivirus Scan might be in progress. Please try again later.' });
            ReactGA.event({
                category: this.props.isEndUser ? GAConstants.USER_EVENT_CATEGORY : GAConstants.ASSOCIATE_EVENT_CATEGORY,
                action: GAConstants.FILE_PREVIEW_AV_SCAN,
            });
        }
        else if (error.message.includes(401))
            optionHandle('Log Out');
        else {
            ReactGA.event({
                category: this.props.isEndUser ? GAConstants.USER_EVENT_CATEGORY : GAConstants.ASSOCIATE_EVENT_CATEGORY,
                action: GAConstants.FILE_PREVIEW_ERROR,
            });
            this.setState({ previewError: 'Failed to Load the File.' });
        }
    }

    zoomIn = () => {
        this.setState({
            scale: this.state.scale * 1.1
        });
    }

    zoomOut = () => {
        this.setState({
            scale: this.state.scale / 1.1
        });
    }

    printDoc = (fileType: any) => {
        const downloadUrl = `${con.uploadUrl}/products/${con.PRODUCT_CODE}/groups/${this.props.selectedGroup.grpCode}/topics/${this.props.topicCode}/files/${this.props.selectedFileToPreview.itemCode}`;
        printJS(downloadUrl, fileType);
    }

    showFileDetails = () => {
        this.setState({ anchorEl: null });
        this.toggleMenu()
    }

    isFileCeremonyComplete = (fileItem: any) => {
        let fileStatus = false
        if (this.props.selectedFileToPreview.signedItem && this.props.selectedFileToPreview.signedItem.code !== null)
            fileStatus = true
        return fileStatus
    }

    fileDownload = (signedCopy: boolean) => {
        let fileCode = null
        if (this.isFileCeremonyComplete(this.props.selectedFileToPreview) && signedCopy)
            fileCode = this.props.selectedFileToPreview.signedItem.code
        else
            fileCode = this.props.selectedFileToPreview.itemCode

        this.props.downloadFile(
            fileCode,
            this.props.topicCode,
            this.props.selectedFileToPreview.itemName,
            Math.floor(Math.random() * 1000
            )
        );
    }

    handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = (option: any) => {
        this.setState({ anchorEl: null });
        // if (typeof option !== 'object') this.props.optionHandle(option);
    };


    render() {
        let isFileCeremonyDone = this.isFileCeremonyComplete(this.props.selectedFileToPreview)
        return (
            <div className='main-wrapper' data-test='preview-window'>
                <div className='top-action-bar'>
                    <div className="action-title">
                        <IconButton data-test='files-btn' onClick={this.goMyFilesPage} aria-label='go back'>
                            <ArrowBackIcon className="btn-icon" />
                        </IconButton>
                        {this.props.selectedFileToPreview && this.props.selectedFileToPreview.itemType === 'FILE' &&
                            <img className='typeIcon' src={fileIcon} alt="Previewed image" />}
                        <span className='action-title-text'>
                          {this.props.selectedFileToPreview ? this.props.selectedFileToPreview.itemName : this.state.downloadFileName}
                        </span>
                    </div>
                    {this.props.selectedFileToPreview &&
                        <div className='action-btns'>
                            <IconButton title="File Details" data-test='menu-btn' aria-label='more' onClick={this.handleClick}>
                                <MoreVertIcon className='btn-icon' />
                            </IconButton>
                            <Menu
                                data-test='file-preview-menu'
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem className="preview-MenuItem" onClick={() => this.showFileDetails()}>View File Details</MenuItem>
                                {
                                    isFileCeremonyDone &&
                                    <MenuItem className="preview-MenuItem" onClick={() => this.fileDownload(isFileCeremonyDone)}>Download</MenuItem>
                                }
                                <MenuItem className="preview-MenuItem" onClick={() => this.fileDownload(!isFileCeremonyDone)}>Download {isFileCeremonyDone ? "Original" : ""}</MenuItem>
                            </Menu>
                        </div>
                    }
                </div>
                {this.props.showAwaitingDocMessage &&
                    <div className='note-action-bar' data-test='awaiting-doc-msg'>
                        <img className='typeIcon' src={infoOutlineIcon} alt="Info" />
                        <span className='note-action-bar-message'><strong>Note:</strong> You're viewing the <u>original copy</u> of this document. We’ll update your preview once the signed copy is available. </span>
                    </div>
                }
                {docType.pdf == this.state.docType &&
                    <div className={`preview-window ${this.props.showAwaitingDocMessage ? 'note-action-bar-present' : ''}`}>
                        <div className='file-container'>
                            <Document
                                options={{ stopAtErrors: true, pdfBug: false }}
                                file={window.location.origin + this.state.downloadFileName}
                                loading={<CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} ></CircularProgress>}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onLoadError={this.onDocumentLoadFailure}
                                className='pdfdocument'
                                error={this.state.previewError != '' ? this.state.previewError : ''}
                                data-test='document-object'
                            >
                                {Array.from(new Array(this.state.numPages), (el, index) => (
                                    <Page
                                        scale={2}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                        </div>
                    </div>
                }
                {
                    docType.image == this.state.docType &&
                    <div className='preview-window'>
                        <div className='file-container'>
                            <div className='image-preview'>
                                {this.state.fileFetching &&
                                    <div className="preview-error-msg">
                                        <CircularProgress style={{ position: 'absolute', top: '40%', left: '50%' }} ></CircularProgress>
                                    </div>
                                }
                                {!this.state.fileFetching && this.state.previewError === '' &&
                                    <img src={URL.createObjectURL(this.state.blob)} className='preview-img-wrapper' />
                                }
                                {!this.state.fileFetching && this.state.previewError !== '' &&
                                    <div className="preview-error-msg">{this.state.previewError}</div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileToPreview: state.appUIStateReducer.selectedFileToPreview,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen,
        downloadFiles: state.filePreviewReducer.filePreview.files,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        grpCode: state.homeReducer.productInfo.response.data.prdCode,
        breadcrumbItemList: state.appUIStateReducer.breadcrumbItemList,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectFileToPreview: (item: any) => dispatch({ type: AppUIStateActionTypes.PREVIEW_FILE, selectedFile: item }),
        toggleFileDetailMenu: (open: boolean) => dispatch({ type: TOGGLE_FILE_DETAIL_MENU, menuOpen: open }),
        startPreview: (topicCode: string, itemCode: string, fileName: string) =>
            dispatch({
                type: FilePreviewActionTypes.TOPIC_FILES_API_VIEW_REQUEST,
                topicCode: topicCode, itemCode: itemCode, fileName: fileName
            }),
        clearPreview: () => dispatch({
            type: FilePreviewActionTypes.TOPIC_FILES_API_VIEW_CLEAR,
        }),
        downloadFile: (itemCode: any, topicCode: any, fileName: any, downloadId: any) => dispatch({
            type: TopicFileConstants.TOPIC_FILES_API_SINGLE_FILE_DOWNLOAD_REQUEST,
            itemCode: itemCode,
            topicCode: topicCode,
            fileName: fileName,
            downloadId: downloadId
        }),
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewWindow));

export const PreviewWindowComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewWindow);
