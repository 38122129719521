import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopicDetailProfileCard from './components/topicDetailProfileCard/TopicDetailProfileCard';
import { CommonFileItemList, CommonFileGridList, CommonModal } from '../../../shared/ui';
import { Paper, Hidden, AppBar, IconButton } from '@material-ui/core';
import { ReactComponent as GridIcon } from '../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../assets/list-view.svg';
import ItemActionsBar from '../../../components/customerVault/itemActionsBar/ItemActionsBar';
import Uploader from './components/uploader/Uploader';
import Upload from './components/upload/Upload';
import { RouteComponentProps, withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as TopicFileActionTypes from '../../../redux/constants/customerVault/topicFileConstants';
import * as TopicDetailActionTypes from '../../../redux/constants/customerVault/topicConstants';
import TopMessageBar from '../../../components/customerVault/feedback/topMessageBar';
import ReactGA from 'react-ga';
import './TopicDetail.page.scss';
import * as GAConstants from '../../../shared/core/GAConstants';
import FsFileDetailPage from '../../shared/fileDetail/Fs.fileDetail.page';
import ESignProgress from '../../../shared/ui/signingCermony/advisor/eSignProgress/ESignProgress';
import ESignEndUserProgress from '../../../shared/ui/signingCermony/enduser/eSignProgress/ESignProgress';
import ESignStart from '../../../shared/ui/signingCermony/enduser/eSignStart/ESignStart';
import ESignEnd from '../../../shared/ui/signingCermony/enduser/eSignEnd/ESignEnd';
import * as CeremonyActionTypes from '../../../redux/constants/shared/eSignConstants';
import SmartIntake from './components/smartIntake/SmartIntake';
import CommonDrawer from '../../../shared/ui/commonDrawer/commonDrawer';
import { con } from '../../../shared/config/config'
import ESignInitiation from '../../../shared/ui/signingCermony/advisor/eSignInitiation/ESignInitiation';
import { CeremonyStatus, CeremonyTokenStatus, ItemCeremonyStatus } from '../../../shared/core/CeremonyStatus';
import ESignConfirm from '../../../shared/ui/signingCermony/enduser/eSignConfirm/ESignConfirm';
import ESignValidate from '../../../shared/ui/signingCermony/enduser/esignValidate/ESignValidate';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../shared/ui/tablePaginationActions/TablePaginationActions';
import DocuSignEmbed from './components/docusignEmbed/DocuSignEmbed';
import { Authorization } from '../../../shared/core/AuthorizationComponent';
import Disclosure from '../../../components/customerVault/disclosure/Disclosure';

interface TopicType {
    stCode: any,
    stName: any,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number,
}

interface fileType {
    itemCode: string,
    itemName: string,
    itemType: string,
    size: number,
    mimeType: string,
    title: string,
    createdAt: string,
    modifiedAt: string,
    owner: OwnerType,
    status: string,
    permissionName: string
}

interface OwnerType {
    grpName: string,
    grpCode: string,
    userCode: string,
    userFirstName: string,
    userLastName: string
}

interface TopicDetailPageProps extends RouteComponentProps<{}> {
    selectedFileToPreview: any;
    selectedFileToViewDetails: any,
    RemoveBreadcrumbItems: any;
    getTopicFiles: any;
    getMoreTopicFiles: any;
    fetchMoreTopicFilesStart: any,
    topicFiles: any;
    addBreadcrumbItem: any;
    topicData: any;
    selectedGroup: any;
    clearAllFiles: any;
    loggedIn: any;
    usertopicData: any;
    selectTopic: any;
    feedbackOpen: any;
    updateFeedback: any;
    features: any;
    endUserDisclaimerContent: any;
    deleteFileDisclaimerContent: any;
    openSmartForm: any;
    getTopicDetails: any;
    topicDetails: any;
    newTopicFiles: any;
    selectFileToPreview: any;
    selectFileToViewDetails: any,
    updateSnackbar: any;
    breadcrumbItemList: any;
    topicContacts: any;
    startCeremony: any;
    getCeremonyDisclosure: any;
    getCeremonyDetails: any;
    fileListHeaders: string[],
    updateFileHeaders: any,
    ceremonyDetails: any,
    ceremonyToken: any,
    SmartIntake: any,
    ceremonyTypes: any,
    getCeremonyTypes: any,
    updateCeremony: any,
    ceremonyStatus: any,
    formTypeDisclosure:any
}

type TopicDetailPageStates = {
    selectedFiles: fileType[],
    listView: boolean,
    pagination: any,
    noMoreData: boolean,
    resetSelection: boolean,
    topic: TopicType,
    uploadmodalOpen: any,
    isEndUser: boolean,
    isDisclaimerOpen: boolean,
    isTopicfound: boolean,
    isModalOpen: boolean;
    modalName: string;
    modalStatus: string;
    selectedFile: any;
    showUploaded: boolean,
    showFileSize: boolean,
    showOwner: boolean
    smartintakeOpen: boolean,
    isMenuOpen: boolean,
    isCTAPreview: boolean,
    currentCallback: any,
    showAcceptedMessage: boolean,
    docusignembedOpen: boolean,
    showUpload: boolean,
}

class TopicDetailPage extends Component<TopicDetailPageProps, TopicDetailPageStates> {
    state = {
        pagination: {
            page: 0,
            size: 10,
            direction: 'DESC',
            orderBy: 'MODIFIED_AT'
        },
        listView: true,
        selectedFiles: [],
        noMoreData: false,
        resetSelection: false,
        topic: {
            stCode: window.location.pathname.split('/').pop(),
            stName: '',
            categoryName: '',
            createdAt: '',
            modifiedAt: '',
            categoryIconColor: '',
            catgeoryIconLabel: '',
            sharedTopicItemsCount: 0,
            sharedTopicUsersCount: 0,
            categoryCode: ''
        },
        uploadmodalOpen: false,
        isEndUser: true,
        isDisclaimerOpen: true,
        isTopicfound: false,
        isModalOpen: false,
        modalName: '',
        modalStatus: '',
        selectedFile: null,
        showUploaded: false,
        showFileSize: false,
        showOwner: false,
        smartintakeOpen: false,
        isMenuOpen: false,
        isCTAPreview: false,
        ceremonyCode: null,
        currentCallback: 0,
        showAcceptedMessage: true,
        docusignembedOpen: false,
        showUpload: false,
    }
    componentDidMount() {
        //Get the topic details friom the redux
        let topicCode = window.location.pathname.split('/').pop()
        this.props.selectFileToPreview(null);

        //Check if the user is end user
        let topic: any = null;
        //Don't call api's if logout is in-progress or successful
        if (this.props.loggedIn.successful) {
            if (this.props.loggedIn.user_profile.roles.indexOf('USER') > -1) {
                this.setState({ isEndUser: true });
                let grpCode = null;
                //Check if the topic id exists in any product then fill tte topic
                this.props.usertopicData.map(
                    (product: any) => {
                        product.groups.map(
                            (group: any) => {
                                group.topics.map((topic1: any) => {
                                    if (topic1.stCode === topicCode) {
                                        grpCode = group.groupCode;
                                        topic = topic1;
                                        this.setState({ isTopicfound: true })
                                        return;
                                    }
                                });
                            });
                    });
                if (topic != null)
                    this.props.addBreadcrumbItem(topic.stName, `/customer/group/${grpCode}/topic-detail/${topic.stCode}`);
                else {
                    //Check if the user has permissions for the topic by calling API
                    this.props.getTopicDetails(topicCode);
                }
            }
            else {
                this.setState({ isEndUser: false });
                topic = this.props.topicData.content.find((x: any) => x.stCode === topicCode);
                if (topic != null) {
                    this.setState({ isTopicfound: true })
                    this.props.addBreadcrumbItem(topic.stName, `/customer/group/${this.props.selectedGroup.grpCode}/topic-detail/${topic.stCode}`);
                } else {
                    //Check if the user has permissions for the topic by calling API
                    this.props.getTopicDetails(topicCode);
                }
            }
        }
        if (topic != null) {
            this.setState({ topic: topic })
            this.props.selectTopic(topic)
            if (topic.stCode) {
                this.props.getTopicFiles(topic.stCode, this.state.pagination)
            }
            this.setState({ noMoreData: false })

        }

        if (this.props.fileListHeaders.includes('createdAt')) {
            this.setState({ showUploaded: true })
        }

        if (this.props.fileListHeaders.includes('size')) {
            this.setState({ showFileSize: true })
        }

        if (this.props.fileListHeaders.includes('shared')) {
            this.setState({ showOwner: true })
        }
    }

    componentDidUpdate(prevProps: any) {
        let topic = null;
        const { topicDetails, SmartIntake, topicFiles } = this.props;
        if (prevProps.topicFiles.files !== topicFiles.files) {
            if (topicFiles.files.page.last && topicFiles.files.page.totalElements != 0)
                this.setState({ noMoreData: true })
        }
        if (prevProps.topicDetails.updating != topicDetails.updating && !topicDetails.updating) {
            if (topicDetails.topicData && topicDetails.error == null) {
                this.props.getTopicDetails(topicDetails.topicData.stCode);
                this.props.addBreadcrumbItem(topicDetails.topicData.stName, `/customer/group/${this.props.selectedGroup.grpCode}/topic-detail/${topicDetails.topicData.stCode}`);
            }
        }
        if (prevProps.topicDetails.fetching != topicDetails.fetching && !topicDetails.fetching) {
            if (topicDetails.error == null && topicDetails.topicData !== null) {
                topic = topicDetails.topicData;
            }
            if (topic != null) {
                this.setState({ topic: topicDetails.topicData })
                this.props.selectTopic(topicDetails.topicData)
                if (!this.state.isTopicfound) {
                    if (this.state.selectedFile == null) {
                        this.props.addBreadcrumbItem(topic.stName, `/customer/group/${this.props.selectedGroup.grpCode}/topic-detail/${topic.stCode}`);
                        this.props.getTopicFiles(topic.stCode, this.state.pagination);
                    }
                }
                if (topic.categoryCode && !this.state.isEndUser) {
                    this.props.getCeremonyTypes(topic.categoryCode);
                    this.setState({showUpload: true});
                }
                if (Array.isArray(this.props.features['uploadDisabledCategories']) && topic.categoryCode){
                    let categoryUploadDisabled = this.props.features['uploadDisabledCategories'].findIndex(code => code == this.props.topicDetails?.topicData?.categoryCode) > -1;
                    this.setState({showUpload: !(this.state.isEndUser && categoryUploadDisabled)})
                }
            }
            else {
                let errorCode = topicDetails.error.response.data.errCode;
                if (errorCode === 671 || errorCode === 705 || errorCode === 706) {
                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: "Invalid link. You've been redirected home."
                    })
                }
                if (this.state.isEndUser)
                    this.props.history.push('/customer/topics');
                else
                    this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/topics`)
            }
        }
        if (prevProps.ceremonyToken.requesting !== this.props.ceremonyToken.requesting && !this.props.ceremonyToken.requesting) {

            if (this.props.ceremonyToken.error == null) {
                let ceremonyDet = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode)
                let prevCeremonyValue = { status: 'NONE' };
                let temp = this.props.ceremonyToken.content.find((ceremony: any) => ceremony[ceremonyDet.ceremonyTypeCode] != null);
                let ceremonyValue = temp != null ? temp[ceremonyDet.ceremonyTypeCode] : { status: 'NONE' };
                let ESIGN = 'esign';
                if (prevProps.ceremonyToken.content)
                    prevCeremonyValue = prevProps.ceremonyToken.content.find((ceremony: any) => ceremony[ceremonyDet.ceremonyTypeCode] != null);

                if (null != ceremonyValue) {
                    if (ceremonyDet.ceremonyTypeCode == ESIGN && (ceremonyValue.status == CeremonyTokenStatus.IN_PROGRESS || ceremonyValue.status == CeremonyTokenStatus.REQUESTED)) {
                        this.onDocusignEmbedOpen(this.state.selectedFile);
                    }
                    else if (ceremonyDet.ceremonyTypeCode != ESIGN && ceremonyValue.status == CeremonyTokenStatus.IN_PROGRESS) {
                        //request for status
                        this.setState({ isModalOpen: true, modalName: CeremonyStatus.CEREMONY_PROGRESS_ENDUSER })
                        let previousCeremony = { status: 'NONE' };
                        let id = setInterval(async () => {
                            let url = `${con.ceremonyUrl}/products/${con.PRODUCT_CODE}/groups/${this.props.selectedGroup.grpCode}/topics/${this.state.topic.stCode}/ceremony/${ceremonyDet.stcCode}`;
                            const response = await fetch(url).then(response => response.json())
                            if (response.ceremony_claims != null) {
                                for (var i = 0; i < response.ceremony_claims.length; i++) {
                                    let ceremony = response.ceremony_claims[i];
                                    let ceremonyValue = ceremony[ceremonyDet.ceremonyTypeCode];
                                    if (ceremonyValue != null) {
                                        //Check if the response successful and the status of the token is COMPLETE then take the user to CTA
                                        if (ceremonyValue.status == CeremonyTokenStatus.COMPLETE) {
                                            clearInterval(this.state.currentCallback);
                                            this.resetSelection(true);
                                            this.setState({ isCTAPreview: true, isModalOpen: true, modalName: CeremonyStatus.CEREMONY_ACCEPTED_ENDUSER, showAcceptedMessage: false });
                                            setTimeout(() => {
                                                this.setState({ isModalOpen: false });
                                                this.onFileOpen(this.state.selectedFile);
                                            }, 5000);
                                        }
                                        else if (previousCeremony.status != CeremonyTokenStatus.REJECTED && ceremonyValue.status == CeremonyTokenStatus.REJECTED) {
                                            clearInterval(this.state.currentCallback);
                                            this.resetSelection(true);
                                            this.props.updateCeremony(ceremonyDet.stcCode);
                                            this.setState({ isModalOpen: true, modalName: CeremonyStatus.CEREMONY_REJECTED_ENDUSER });
                                        }
                                        previousCeremony = ceremonyValue;
                                        break;
                                    }
                                }
                            }
                        }, 5000)
                        this.setState({ currentCallback: id });
                    }
                    else if (prevCeremonyValue.status !== CeremonyTokenStatus.REJECTED && ceremonyValue.status == CeremonyTokenStatus.REJECTED) //REJECTED or RESUBMIT CASE
                    {
                        this.onCermonyAccept(this.state.selectedFile, true);
                    }
                    else if (ceremonyValue.status == CeremonyTokenStatus.COMPLETE) {
                        if (ceremonyDet.ceremonyTypeCode != 'cta') {
                            if (this.state.showAcceptedMessage) {
                                this.setState({ isCTAPreview: true, isModalOpen: true, modalName: CeremonyStatus.CEREMONY_ACCEPTED_ENDUSER });
                            }
                            setTimeout(() => {
                                this.setState({ isModalOpen: false, showAcceptedMessage: true });
                                this.onFileOpen(this.state.selectedFile);
                            }, 5000);
                        }
                        else {
                            this.setState({ isCTAPreview: true });
                            this.onFileOpen(this.state.selectedFile);
                        }
                    }
                }
            }
            else if (this.props.ceremonyToken.error.response) {
                let ceremonyDet = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode)
                if (ceremonyDet.ceremonyTypeCode != 'esign' && this.props.ceremonyToken.error.response?.data?.errCode === 1308) { ////when there is ceremony cancelled error, show the file preview screen with error message
                    this.setState({ isCTAPreview: true });
                    this.props.selectFileToPreview(this.state.selectedFile);
                    this.props.selectFileToViewDetails(this.state.selectedFile);
                }
                else {
                    this.showSnackBarMessage("We're sorry, an error occurred while accessing this request. Please contact your Fidelity representative.")
                }
            }
        }
        if (prevProps.ceremonyDetails.creating != this.props.ceremonyDetails.creating && !this.props.ceremonyDetails.creating) {
            let isError = false;
            isError = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.sysMsg != null) != undefined
            if (isError || this.props.ceremonyDetails.error != null)
                this.onCeremonyModalClose(false, CeremonyStatus.CEREMONY_INITIATE_INSTUSER)
            else
                this.onCeremonyModalClose(true, CeremonyStatus.CEREMONY_INITIATE_INSTUSER)
        }
        if (prevProps.ceremonyStatus.requesting != this.props.ceremonyStatus.requesting && !this.props.ceremonyStatus.requesting && this.state.modalName != CeremonyStatus.CEREMONY_COMPLETE_ENDUSER) {
            this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination);
        }
        if (prevProps.ceremonyDetails.requesting && !this.props.ceremonyDetails.requesting && this.props.ceremonyDetails.content.length && this.state.isEndUser) {
            if (!this.state.isModalOpen) {
                let ceremonyDet = this.props.ceremonyDetails.content.find((ceremony: any) => (ceremony.status === ItemCeremonyStatus.CANCELLED) || ceremony.status === ItemCeremonyStatus.REVOKED || ceremony.status === ItemCeremonyStatus.DECLINED)
                if (ceremonyDet) {
                    this.showSnackBarMessage("We're sorry, an error occurred while accessing this request. Please refresh the page and try again.")
                }
            }
            else {
                let stcCode, ceremonyType;
                stcCode = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode).stcCode;
                ceremonyType = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode).ceremonyTypeCode;
                if (stcCode && ceremonyType == 'esign') {
                    this.props.getCeremonyDisclosure(stcCode);
                }
            }
        }

        if (prevProps.formTypeDisclosure.fetching && !this.props.formTypeDisclosure.fetching && this.props.formTypeDisclosure.error) {
            this.showSnackBarMessage("We're sorry, an error occurred while accessing this request. Please refresh and try again later.")
        }
    }

    componentWillUnmount() {
        if (this.state.currentCallback != null)
            return () => clearInterval(this.state.currentCallback);
    }

    showSnackBarMessage = (message: string) => {
        this.props.updateSnackbar({
            modalOpen: true,
            content: message
        })
    }

    backTopicOnClick = () => {
        this.props.RemoveBreadcrumbItems(1);
    }

    toggleViews = () => {
        this.setState({ listView: !this.state.listView });
        this.resetSelection(true);
    }

    fetchMoreTopicFiles = () => {
        if (this.props.topicFiles.files.page.pageNumber < this.props.topicFiles.files.page.totalPages - 1) {
            this.props.fetchMoreTopicFilesStart();
            let paginationNew = {
                ...this.state.pagination,
                page: this.props.topicFiles.files.page.pageNumber + 1
            }
            setTimeout(() => {
                this.props.getMoreTopicFiles(this.state.topic.stCode, paginationNew)
            }, 2000)
        } else {
            this.setState({ noMoreData: true })
        }
    }

    onFileSelection = (selectedIds: string[]) => {
        var selectedFiles = []
        selectedFiles = this.props.topicFiles.files.content.filter((I: any) =>
            selectedIds.findIndex((x: string) => x === I.itemCode) > -1
        );
        this.setState({ selectedFiles: selectedFiles });
    }

    onFileOpen = (item: any) => {
        if (!this.props.features['isPreviewDisable']) {
            if (item.mimeType === 'application/pdf' || item.mimeType === 'image/png' || item.mimeType === 'image/jpeg' || item.mimeType === 'image/jpg') {
                if (item.size <= 5242880) {
                    this.props.selectFileToPreview(item);
                    this.props.selectFileToViewDetails(item);
                } else {
                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: "File is too large to be previewed. Please download to view it."
                    })
                }
            } else {
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: "File preview cannot be generated for this file type."
                })
            }
            // For GA
            var category = '';
            if (this.state.isEndUser)
                category = GAConstants.USER_EVENT_CATEGORY;
            else
                category = GAConstants.ASSOCIATE_EVENT_CATEGORY;
            ReactGA.event({
                category: category,
                action: GAConstants.FILE_VIEWED,
            });
            /*  }
             else {
                 this.props.updateSnackbar({
                     modalOpen: true,
                     content: "Antivirus Scan in progress. Please try again later."
                 })
             } */
        }
        else {
            //Todo
        }
    }

    onSmartFormOpen = (item: any) => {
        this.setState({ smartintakeOpen: true })
        this.props.openSmartForm(this.state.topic.stCode, item.itemCode);
        this.setState({ selectedFile: item })
    }

    onDocusignEmbedOpen = (item: any) => {
        this.setState({ docusignembedOpen: true });
    }

    closeDocusignEmbed = (finish: boolean, status: string) => {
        if (this.state.currentCallback != null) {
            clearInterval(this.state.currentCallback);
        }
        this.setState({ docusignembedOpen: false });
        if (finish || status) {
            this.setState({ isModalOpen: true, modalName: CeremonyStatus.CEREMONY_COMPLETE_ENDUSER, modalStatus: status || '' });
        }
    }

    onFolderOpen = () => {
        console.log('onFolderOpen')
    }

    sortOnClick = (id: string) => {
        switch (id) {
            case 'fileName':
                this.setState({
                    pagination: {
                        page: 0,
                        size: 20,
                        direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
                        orderBy: 'NAME'
                    }
                }, () => {
                    this.resetSelection(false);
                    this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination);
                })
                break;
            case 'modifiedAt':
                this.setState({
                    pagination: {
                        page: 0,
                        size: 20,
                        direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
                        orderBy: 'MODIFIED_AT'
                    }
                }, () => {
                    this.resetSelection(false);
                    this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination);
                })
                break;
            case 'createdAt':
                this.setState({
                    pagination: {
                        page: 0,
                        size: 20,
                        direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
                        orderBy: 'CREATED_AT'
                    }
                }, () => {
                    this.resetSelection(false);
                    this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination);
                })
                break;
        }
    }

    showCeremonyMessage = () => {
        this.setState({ modalName: CeremonyStatus.CEREMONY_COMPLETE_ENDUSER, isCTAPreview: false, isModalOpen: true });
    }

    resetSelection = (refreshRequired: boolean) => {
        this.setState({ resetSelection: true, isCTAPreview: false, modalName: '', isModalOpen: false, noMoreData: false, docusignembedOpen: false }, () => {
            setTimeout(() => {
                this.setState({ resetSelection: false });
            }, 1500);
        });
        if (refreshRequired != null && refreshRequired)
            if (this.state.topic.stCode) {
                this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination)
            }
    }

    onTopicSave = (success: any) => {
        if (success)
            this.props.RemoveBreadcrumbItems(this.props.breadcrumbItemList.length - 1)
    }

    onFileUploadAction = () => {
        this.setState({ uploadmodalOpen: true })
    }

    handleClick = () => {
        this.setState({ isMenuOpen: true })
    };

    handleDrawerClose = () => {
        this.setState({ isMenuOpen: false })
    }

    showfileDetails = (item: any) => {
        this.props.selectFileToViewDetails(item);
        this.handleClick()
    }
    isFilePreviewable = () => {
        return (!this.props.features['isPreviewDisable'] && this.state.selectedFiles[0] &&
            ((
                this.state.selectedFiles[0]['mimeType'] === 'application/pdf' ||
                this.state.selectedFiles[0]['mimeType'] === 'image/png' ||
                this.state.selectedFiles[0]['mimeType'] === 'image/jpeg' ||
                this.state.selectedFiles[0]['mimeType'] === 'image/jpg') || this.state.selectedFiles[0]['itemType'] === 'FORM') &&
            this.state.selectedFiles[0]['size'] <= 5242880)
    }

    isFileValidForEsign = () => {
        return (!this.props.features['isPreviewDisable'] && this.state.selectedFiles[0] &&
            this.state.selectedFiles[0]['mimeType'] === 'application/pdf' &&
            this.state.selectedFiles[0]['size'] <= 5242880)
    }


    onActionComplete = () => {
        this.setState({ uploadmodalOpen: false })
        if (this.state.topic.stCode) {
            this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination)
        }
        this.props.clearAllFiles();
    }
    onCermonyAccept = (file: any, isForceInit: any) => {

        let ceremonyDet = this.props.ceremonyDetails.content.find((ceremony: any) => (ceremony.status === ItemCeremonyStatus.CANCELLED) || ceremony.status === ItemCeremonyStatus.REVOKED || ceremony.status === ItemCeremonyStatus.DECLINED)
        if (ceremonyDet) {
            this.showSnackBarMessage("We're sorry, an error occurred while accessing this request. Please refresh the page and try again.")
        }
        else {
            let stcCode = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode).stcCode;
            this.props.startCeremony(this.state.topic.stCode, stcCode, isForceInit);

            this.setState({ isModalOpen: false, isCTAPreview: true });
        }
    }

    ondisclaimerClose = () => {
        this.setState({ isDisclaimerOpen: false });
   } 

    onCeremonyAction = (file: any, type?: CeremonyStatus) => {
        let modalName = '';
        if (type != null) {
            modalName = type;
            this.setState({ isModalOpen: true, modalName: type })
        }
        else {
            if (!this.state.isEndUser) {
                this.setState({ isModalOpen: true, modalName: CeremonyStatus.CEREMONY_REQUESTED_INSTUSER })
                modalName = CeremonyStatus.CEREMONY_REQUESTED_INSTUSER
            }
            else {
                //fetch the ceremony status for the 
                this.setState({ isModalOpen: true, modalName: CeremonyStatus.CEREMONY_REQUESTED_ENDUSER })
                modalName = CeremonyStatus.CEREMONY_REQUESTED_ENDUSER
            }
        }
        switch (modalName) {
            case CeremonyStatus.CEREMONY_INITIATE_INSTUSER:
                this.setState({ selectedFile: file });
                break;
            case CeremonyStatus.CEREMONY_REQUESTED_INSTUSER:
            case CeremonyStatus.CEREMONY_REQUESTED_ENDUSER:
                this.setState({ selectedFile: file }, () => {
                    if (file != null) {
                        this.props.getCeremonyDetails(file.ceremonyCode, this.state.topic.stCode, file.itemCode);
                    }
                });
                break;
            default:
                break;
        }
    }

    onCeremonyModalClose = (success: boolean, type?: CeremonyStatus, apiFailure: boolean = false) => {
        this.setState({ isModalOpen: false })

        if (apiFailure) {
            this.props.updateSnackbar({
                modalOpen: true,
                content: "Something wrong with API. Please try again"
            })
        }
        if (success) {
            switch (type) {
                case CeremonyStatus.CEREMONY_INITIATE_INSTUSER:
                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: "Electronic consent request created successfully."
                    })
                    break;
                case CeremonyStatus.CEREMONY_REQUESTED_INSTUSER:
                case CeremonyStatus.CEREMONY_REQUESTED_ENDUSER:
                    break;
                case CeremonyStatus.CEREMONY_CANCEL_INSTUSER:
                    this.props.updateSnackbar({
                        modalOpen: true,
                        content: "Electronic consent request cancelled successfully."
                    })
                    break;
                default:
                    break;
            }
            this.resetSelection(true);
        }
        else {
            switch (type) {
                case CeremonyStatus.CEREMONY_INITIATE_INSTUSER:
                    let autoHideSnackBarDisable = false
                    let content = "Electronic consent request cancellation failed.";
                    let ceremonyError = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.sysMsg != null)
                    if (ceremonyError !== null && ceremonyError !== undefined) {
                        if (ceremonyError.sysMsg.errCode === 614) { //Antivirus scan is in progress
                            content = ceremonyError.sysMsg.errMsg;
                            autoHideSnackBarDisable = true
                        }
                    }
                    this.props.updateSnackbar({
                        modalOpen: true,
                        autoHideDisable: autoHideSnackBarDisable,
                        content: content
                    })
                    break;
                case CeremonyStatus.CEREMONY_REQUESTED_INSTUSER:
                case CeremonyStatus.CEREMONY_REQUESTED_ENDUSER:
                    break;
                case CeremonyStatus.CEREMONY_CANCEL_INSTUSER:
                    let message = "Electronic consent request cancellation failed."
                    let autoHideDisable = false
                    let isError = false
                    let ceremonyWithError = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.sysMsg != null)
                    if (ceremonyWithError !== null && ceremonyWithError !== undefined) {
                        if (ceremonyWithError.sysMsg.errCode == 1304) { //This error code indicates, ceremony is not in requested state
                            message = "Unable to cancel request. Response already submitted.";
                            autoHideDisable = true;
                            isError = true;
                        }
                    }
                    this.props.updateSnackbar({
                        modalOpen: true,
                        isError: isError,
                        autoHideDisable: autoHideDisable,
                        content: message
                    })
                    break;
                default:
                    break;
            }
        }
    }

    handleClose = (refreshRequired: boolean = false) => {
        //this.setState({ isModalOpen: false });
        this.resetSelection(refreshRequired)
        if (null != this.state.currentCallback)
            clearInterval(this.state.currentCallback);
    }

    closeSigningCeremony = (abortSigning: boolean = false) => {
        //this.setState({ isModalOpen: false });
        if (abortSigning) {
            if (null != this.state.currentCallback) {
                clearInterval(this.state.currentCallback);
            }
            this.setState({ isModalOpen: false });
        }
    }

    modalContentCreate = (name: string) => {
        if (this.props.ceremonyDetails.error) {
            this.showSnackBarMessage("We're sorry, an error occurred while accessing this request. Please contact your Fidelity representative.")
        }
        else {
            let ceremonyToken = { status: 'NONE' };
            let ceremonyDet = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode)
            this.props.ceremonyToken != null &&
                this.props.ceremonyToken.content != null &&
                this.props.ceremonyToken.content.map((ceremony: any) => {
                    ceremonyToken = ceremony[ceremonyDet.ceremonyTypeCode];
                });

            switch (name) {
                case CeremonyStatus.CEREMONY_INITIATE_INSTUSER:
                    return <CommonModal
                        data-test="ceremonyInitiateModalInstUser"
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.onCeremonyModalClose(false)}
                        content={<ESignInitiation
                            onCeremonyModalClose={this.onCeremonyModalClose}
                            isEndUser={this.state.isEndUser}
                            topic={this.state.topic}
                            selectedItem={(this.state.selectedFile != null) ? this.state.selectedFile : null}
                        >
                        </ESignInitiation>}
                    />
                case CeremonyStatus.CEREMONY_REQUESTED_INSTUSER:
                    return <CommonModal
                        data-test="ceremonyRequestModalInstUser"
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.onCeremonyModalClose(false)}
                        content={<ESignProgress
                            onCeremonyModalClose={this.onCeremonyModalClose}
                            isEndUser={this.state.isEndUser}
                            topicCode={this.state.topic.stCode}
                            selectedItem={(this.state.selectedFile != null) ? this.state.selectedFile : null}
                        >
                        </ESignProgress>}
                    />
                case CeremonyStatus.CEREMONY_REQUESTED_ENDUSER:
                    return <CommonModal
                        data-test="ceremonyRequestModal"
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.onCeremonyModalClose(false)}
                        content={<ESignStart
                            isMultiSigner={this.props.ceremonyDetails.content.length > 1 ? true : false}
                            onActionComplete={this.onCermonyAccept}
                            onCancelRequest={() => this.onCeremonyModalClose(false)}
                            ceremonyDetails={this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode)}
                            selectedItem={(this.state.selectedFile != null) ? this.state.selectedFile : null}
                            forceInitiate={(ceremonyToken != null && ceremonyToken.status != null && ceremonyToken.status == 'COMPLETE') ? true : false}
                        >
                        </ESignStart>}
                    />
                case CeremonyStatus.CEREMONY_PROGRESS_ENDUSER:
                    return <CommonModal
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.closeSigningCeremony(true)}
                        content={<ESignEndUserProgress
                            openConfirmationModal={this.openConfirmationModal}
                            ceremonyDetails={this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode)}
                            selectedItem={(this.state.selectedFile != null) ? this.state.selectedFile : null}
                        >
                        </ESignEndUserProgress>}
                    />
                case CeremonyStatus.CEREMONY_PROGRESS_ENDUSER_ABORT:
                    return <CommonModal
                        data-test="ceremonyAbortEndUserModal"
                        modalOpen={this.state.isModalOpen}
                        modalClose={this.onConfirmation}
                        content={
                            <ESignConfirm
                                onClose={this.onConfirmation}>
                            </ESignConfirm>}
                    />
                case CeremonyStatus.CEREMONY_ACCEPTED_ENDUSER:
                    return <CommonModal
                        data-test="ceremonyAcceptedModal"
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.onEsignComplete(true)}
                        content={
                            <ESignValidate
                                isSuccess={true}
                                onClose={() => this.onEsignComplete(true)}>
                            </ESignValidate>}
                    />
                case CeremonyStatus.CEREMONY_REJECTED_ENDUSER:
                    return <CommonModal
                        data-test="ceremonyRejectedModal"
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.onEsignComplete(false)}
                        content={
                            <ESignValidate
                                isSuccess={false}
                                onClose={() => this.onEsignComplete(false)}>
                            </ESignValidate>}
                    />
                case CeremonyStatus.CEREMONY_COMPLETE_ENDUSER:
                    return <CommonModal
                        data-test="signingCompletionModal"
                        modalOpen={this.state.isModalOpen}
                        modalClose={() => this.resetSelection(true)}
                        content={<ESignEnd
                            isMultiSigner={this.props.ceremonyDetails.content.length > 1}
                            ceremonyStatus={this.state.modalStatus}
                            onActionComplete={this.resetSelection}></ESignEnd>}
                    />
                default:
                    return null
            }
        }
    }

    onConfirmation = (abortSigning: boolean) => {
        this.setState({ modalName: CeremonyStatus.CEREMONY_PROGRESS_ENDUSER })
        if (abortSigning) {
            this.closeSigningCeremony(true);
        }
        else {
            this.closeSigningCeremony(false);
        }
    }

    openConfirmationModal = () => {
        this.setState({ isModalOpen: true, modalName: CeremonyStatus.CEREMONY_PROGRESS_ENDUSER_ABORT })
    }

    onHeaderFiltering = (filteredHeaders: string[]) => {
        this.props.updateFileHeaders(filteredHeaders)
    }

    closeSmartIntakeDialog = () => {
        this.setState({ smartintakeOpen: false });
    }



    onEsignComplete = (success: boolean) => {
        if (success) {
            setTimeout(() => {
                this.setState({ isModalOpen: false, modalName: '' });
            }, 5000);
        }
        else {
            this.setState({ isModalOpen: false, modalName: '' });
        }
    }

    renderProgressBar = () => {
        return <div className='loaderholder' >
            <Paper elevation={3} className='loader'>
                <CircularProgress />
                <div className='loadingtext'>Loading...</div>
            </Paper>
        </div>
    }

    handlePageChange = (event: any, pageNumber: any) => {
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    page: pageNumber
                }
            }, () => {
                this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination)
                this.resetSelection(false);
            })
    }

    handleRowsPerPageChange = (event: any) => {
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    size: event.target.value,
                    page: 0
                }
            }, () => {
                this.props.getTopicFiles(this.state.topic.stCode, this.state.pagination)
                this.resetSelection(false);
            })
    }

    getDisplayedRows: ({ from, to, count }: { from: number, to: number, count: any }) => string = ({ from, to, count }) => {
        return (count && !this.props.topicFiles.fetching) ? `${from}-${to} of ${count} total files` : '';
    }

    getDisplayedRowsParams = () => {
        const count = this.props.topicFiles.files.page.totalElements;
        const page = this.props.topicFiles.files.page.pageNumber;
        const rowsPerPage = this.state.pagination.size;
        const from = count === 0 ? 0 : page * rowsPerPage + 1;
        const to = count !== -1 ? Math.min(count, (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage;

        return { from, to, count };
    }

    renderTablePagination = () => {
        return (
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                labelRowsPerPage="Results per page:"
                count={this.props.topicFiles.files.page.totalElements}
                rowsPerPage={this.state.pagination.size}
                page={this.props.topicFiles.files.page.pageNumber}
                component="div"
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                data-test="tablepagination"
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handleRowsPerPageChange}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={this.getDisplayedRows}
            />
        );
    }

    renderFileGridList = () => {
        return (
            <div>
                <CommonFileGridList
                    files={this.props.topicFiles.files.content}
                    filesFetching={this.props.topicFiles.fetching}
                    allowChangePermission={true}
                    onSelection={this.onFileSelection}
                    showPermission={false}
                    showFileSize={true}
                    showOnwer={false}
                    onFileOpen={this.onFileOpen}
                    onFolderOpen={this.onFolderOpen}
                    onSmartFormOpen={this.onSmartFormOpen}
                    showMeAsOnwer={true}
                    noMoreData={this.state.noMoreData}
                    showUploaded={false}
                    esignStatusOpen={this.onCeremonyAction}
                    resetSelection={this.state.resetSelection} />
                <>{this.renderTablePagination()}</>
            </div>
        );
    }

    render() {
        let extratableheight = 0;
        extratableheight = this.props.feedbackOpen ? extratableheight + 70 : extratableheight;
        if (this.state.isEndUser) {
            extratableheight = this.state.isDisclaimerOpen ? extratableheight + 84 : extratableheight;
        }
        return (
            <div data-test='topicDetailWrapper'>
                {this.state.isEndUser && !this.props.features['noDisclaimerContent'] &&
                    <TopMessageBar variant='warning' onClose={() => { this.ondisclaimerClose(); }}>
                        <span className='disclaimer-msg'>
                            <p dangerouslySetInnerHTML={{ __html: this.props.features['noDisclaimerContent'] ? undefined : this.props.endUserDisclaimerContent }}></p>
                        </span>
                    </TopMessageBar>
                }
                <TopicDetailProfileCard
                    topic={this.state.topic}
                    backTopicOnClick={this.backTopicOnClick}
                    isEndUser={this.state.isEndUser}
                    onActionComplete={this.onTopicSave}
                />
                {
                    this.state.showUpload &&
                    <Authorization authorization="UploadFiles">
                        <>
                            <Upload data-test='uploadWrapper' isEndUser={this.state.isEndUser} modalOpen={this.state.uploadmodalOpen} topic={this.state.topic} onActionComplete={this.onActionComplete}/>
                            <Uploader data-test='uploaderWrapper' isEndUser={this.state.isEndUser} uploaderkey='mainpage' topic={this.state.topic} onFileUploadAction={this.onFileUploadAction} />
                        </>
                    </Authorization>
                }
                <AppBar position='static' className='topic-detail-appbar'>
                    <div className='total-count-label-wrapper'>
                        {this.props.topicFiles.files != null &&
                            <p className='MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit total-count-label'>
                                {this.getDisplayedRows(this.getDisplayedRowsParams())}
                            </p>
                        }
                    </div>
                    <div className='topic-detail-actions'>
                        <ItemActionsBar
                            showfileDetails={() => this.showfileDetails(this.state.selectedFiles[0])}
                            isEndUser={this.state.isEndUser}
                            selectedFiles={this.state.selectedFiles}
                            topic={this.state.topic}
                            onActionComplete={this.resetSelection}
                            isFilePreviewable={this.isFilePreviewable()}
                            isFileValidForEsign={this.isFileValidForEsign()}
                            onCermonyAction={this.onCeremonyAction}
                            filePreview={() => this.state.selectedFiles[0]['itemType'] === 'FORM' ? this.onSmartFormOpen(this.state.selectedFiles[0]) : this.onFileOpen(this.state.selectedFiles[0])}
                        />
                        <Hidden smDown>
                            {this.state.listView &&
                                <IconButton aria-label='views' className='iconBtn-wrapper' onClick={this.toggleViews}>
                                    <GridIcon className='iconBtn' />
                                </IconButton>
                            }
                            {!this.state.listView &&
                                <IconButton aria-label='views' className='iconBtn-wrapper' onClick={this.toggleViews}>
                                    <ListViewIcon className='iconBtn' />
                                </IconButton>
                            }
                        </Hidden>
                    </div>
                </AppBar>
                {this.props.topicFiles.files != null && this.props.topicFiles.files.content.length === 0 && this.props.topicFiles.files.page.totalElements === 0 && !this.props.topicFiles.fetching &&
                    <div className='no-file-block' data-test="noFiles">
                        No files to display
                    </div>
                }
                {this.props.topicFiles.files != null &&
                    <Paper>
                        <Hidden smDown>
                            {this.state.listView &&
                                <>
                                    <CommonFileItemList files={this.props.topicFiles.files.content}
                                        curSorting={this.props.topicFiles.files}
                                        filesFetching={this.props.topicFiles.fetching}
                                        onSelection={this.onFileSelection}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        onSmartFormOpen={this.onSmartFormOpen}
                                        onSorting={this.sortOnClick}
                                        showOwner={true}
                                        showMeAsOnwer={false}
                                        allowChangePermission={false}
                                        showPermission={false}
                                        showUploaded={true}
                                        showFileSize={true}
                                        noMoreData={this.state.noMoreData}
                                        tableMarginHeight={extratableheight + 0}
                                        // tableMarginHeight={0}
                                        esignStatusOpen={this.onCeremonyAction}
                                        resetSelection={this.state.resetSelection}
                                        fileListHeaders={this.props.fileListHeaders}
                                        onFiltering={this.onHeaderFiltering}
                                    />
                                    <>{this.renderTablePagination()}</>
                                </>
                            }
                            {!this.state.listView &&
                                <>{this.renderFileGridList()}</>
                            }
                        </Hidden>
                        <Hidden mdUp>
                            <>{this.renderFileGridList()}</>
                        </Hidden>
                    </Paper>
                }
                {
                    this.props.selectedFileToPreview !== null &&
                    <FsFileDetailPage onClose={this.resetSelection} isCTAPreview={this.state.isCTAPreview} showMessage={this.showCeremonyMessage}></FsFileDetailPage>
                }
                {(this.props.ceremonyDetails.requesting || this.props.ceremonyToken.requesting || this.props.ceremonyTypes.fetching) ?
                    this.renderProgressBar() :
                    this.modalContentCreate(this.state.modalName)}
                {
                    (this.state.smartintakeOpen && this.state.selectedFile != null) ?
                        this.props.SmartIntake.fetching ?
                            this.renderProgressBar() :
                            <SmartIntake onClose={this.closeSmartIntakeDialog} item={this.state.selectedFile != null ? this.state.selectedFile : null}></SmartIntake> : null
                }
                {
                    (this.state.docusignembedOpen && this.state.selectedFile != null) ?
                        <DocuSignEmbed onClose={this.closeDocusignEmbed} item={this.state.selectedFile != null ? this.state.selectedFile : null}></DocuSignEmbed> : null
                }
                <CommonDrawer
                    handleClick={this.handleClick}
                    handleClose={this.handleDrawerClose}
                    isOpen={this.state.isMenuOpen}
                    fileDetailContent={this.props.selectedFileToViewDetails}
                    topicCode={this.state.topic.stCode}
                ></CommonDrawer>
                <Disclosure data-test='disclosureWrapper' disclosure={this.props.deleteFileDisclaimerContent.disclosure} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileToPreview: state.appUIStateReducer.selectedFileToPreview,
        selectedFileToViewDetails: state.appUIStateReducer.selectedFileToViewDetails,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        topicData: state.topicReducer.topicList.topicData,
        usertopicData: state.topicReducer.userTopicList.products,
        loggedIn: state.login,
        topicFiles: state.topicFilesReducer.topicFiles,
        feedbackOpen: state.appUIStateReducer.feedbackOpen,
        features: state.homeReducer.features,
        endUserDisclaimerContent: state.legalsReducer.endUserDisclaimer.content,
        topicDetails: state.topicReducer.topicDetails,
        newTopicFiles: state.topicFilesReducer.filesUpload.files,
        breadcrumbItemList: state.appUIStateReducer.breadcrumbItemList,
        topicContacts: state.topicReducer.topicContacts.contactsData.content,
        fileListHeaders: state.appUIStateReducer.fileListHeaders,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen,
        ceremonyDetails: state.eSignReducer.ceremonyDetails,
        ceremonyToken: state.eSignReducer.ceremonyToken,
        SmartIntake: state.topicFilesReducer.smartIntake,
        ceremonyTypes: state.eSignReducer.ceremonyTypes,
        ceremonyStatus: state.eSignReducer.ceremonyChange,
        deleteFileDisclaimerContent: state.legalsReducer.deleteFileDisclaimer.content,
        formTypeDisclosure: state.legalsReducer.formTypeDisclosure
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCeremonyDetails: (ceremonyCode: any, topicCode: any, itemCode: any) => dispatch({
            type: CeremonyActionTypes.CEREMONY_API_GET_DETAILS,
            topicCode: topicCode,
            itemCode: itemCode,
            ceremonyCode: ceremonyCode
        }),
        startCeremony: (topicCode: any, ceremonyCode: any, isForceInit: boolean) => dispatch({
            type: CeremonyActionTypes.CEREMONY_API_INITIATION,
            topicCode: topicCode, ceremonyCode: ceremonyCode,
            isForceInit: isForceInit
        }),
        getCeremonyDisclosure: (ceremonyCode: any) => dispatch({
            type: CeremonyActionTypes.GET_CEREMONY_DISCLOSURES,
            ceremonyCode: ceremonyCode
        }),
        selectFileToViewDetails: (item: any) => dispatch({ type: AppUIStateActionTypes.VIEW_FILE_DETAILS, selectedFile: item }),
        selectFileToPreview: (item: any) => dispatch({ type: AppUIStateActionTypes.PREVIEW_FILE, selectedFile: item }),
        RemoveBreadcrumbItems: (lastIndex: any) => dispatch({
            type: AppUIStateActionTypes.REMOVE_BREABCRUMB_ITEMS,
            lastIndex: lastIndex
        }),
        openSmartForm: (topicCode: string, itemCode: string) => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_API_SMART_FORM_REQUEST,
            topicCode: topicCode,
            itemCode: itemCode
        }),
        getTopicFiles: (topicCode: string, pagination: any) => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_API_GET_REQUEST,
            topicCode: topicCode,
            pagination: pagination
        }),
        getMoreTopicFiles: (topicCode: string, pagination: any) => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_API_LOAD_MORE_REQUEST,
            topicCode: topicCode,
            pagination: pagination
        }),
        fetchMoreTopicFilesStart: () => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_API_LOAD_MORE_START
        }),
        addBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.ADD_BREADCRUMB_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        selectTopic: (topic: TopicType) => dispatch({ type: TopicDetailActionTypes.TOPIC_API_SELECT_TOPIC, selectedTopic: topic }),
        clearAllFiles: () => dispatch({
            type: TopicFileActionTypes.TOPIC_FILES_UPLOAD_CLEAR
        }),
        updateFeedback: (feedbackOpen: boolean) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_FEEDBACK_UPDATE,
            feedbackOpen: feedbackOpen
        }),
        getTopicDetails: (topicCode: string) => dispatch({
            type: TopicDetailActionTypes.TOPICS_API_GET_TOPICS_DETAILS_REQUEST,
            topicCode: topicCode,
        }),
        getCeremonyTypes: (categoryCode: any) => dispatch({
            type: CeremonyActionTypes.CEREMONY_API_GET_TYPES,
            categoryCode: categoryCode
        }),
        /* downloadFiles: (itemCode: any, topicCode: any, fileName: any, downloadId: any) => dispatch({
            type: TopicFileActionTypes.TOPIC_FORMS_OPEN,
            itemCode: itemCode,
            topicCode: topicCode,
            fileName: fileName,
            downloadId: downloadId
        }),  */
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        updateFileHeaders: (filteredHeaders: string[]) => dispatch({
            type: AppUIStateActionTypes.UPDATE_FILE_LIST_HEADERS,
            filteredHeaders: filteredHeaders
        }),
        updateCeremony: (ceremonyCode: string, ceremonyStatus: string) =>
            dispatch({
                type: CeremonyActionTypes.CEREMONY_API_STATUS_CHANGE,
                ceremonyCode: ceremonyCode,
                ceremonyStatus: ceremonyStatus
            })
    };
};

export const TopicDetailComponent = connect(mapStateToProps, mapDispatchToProps)(TopicDetailPage);

export default withRouter(TopicDetailComponent);
