import React from 'react';

const AllFilesIcon = (props) => {
    return <svg width="23px" height="19px" viewBox="0 0 23 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <style>{`.st1{stroke:${props.stroke}}`}
            {`.st2{fill:${props.stroke}}`}
        </style>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Files-Folders-/-Folder-Black" transform="translate(-1.000000, -3.000000)">
                <g id="Group" transform="translate(2.000000, 3.000000)">
                    <g id="folder">
                        <path d="M20.75,5.48076923 C20.75,4.60985866 20.0439875,3.90384615 19.1730769,3.90384615 L8.92307692,3.90384615 L7.03076923,1.38076923 C6.7329604,0.983690787 6.26557882,0.75 5.76923077,0.75 L1.82692308,0.75 C0.95601251,0.75 0.25,1.45601251 0.25,2.32692308 L0.25,16.5192308 C0.25,17.3901413 0.95601251,18.0961538 1.82692308,18.0961538 L19.1730769,18.0961538 C20.0439875,18.0961538 20.75,17.3901413 20.75,16.5192308 L20.75,5.48076923 Z"
                            className="st1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <polygon className="st2"
                            points="8.47265106 4.38431924 0.637952515 4.38431924 0.637952515 0.75 6.23064161 0.75"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}
export default AllFilesIcon