import React, { Component } from 'react';
import { IconButton, Modal, withStyles } from '@material-ui/core';
import { ReactComponent as ViewIcon } from '../../../assets/preview.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/download.svg';
import { ReactComponent as ShareIcon } from '../../../assets/share.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as MoveFolderIcon } from '../../../assets/move-folder.svg';
import { ReactComponent as RenameIcon } from '../../../assets/rename.svg';
import { ReactComponent as EsignIcon } from '../../../assets/esign.svg';
import { ReactComponent as InfoFileIcon } from '../../../assets/info.svg';
import { grey } from "@material-ui/core/colors";
import { CeremonyStatus } from '../../core/CeremonyStatus';

export const styles = (theme: any) => ({
    fileActionBar: {
        borderRight: '1px solid #c5c5c5',
        paddingRight: '.5em',
        marginRight: '.5em',
        [theme.breakpoints.down('md')]: {
            borderRight: 'none'
        }
    },
    iconBtn: {
        width: '20px',
        height: '20px',
    }
})

type CommonItemsActionsBarProps = {
    showView?: boolean;
    showShare?: boolean;
    showDownload?: boolean;
    showMove?: boolean;
    showRename?: boolean;
    showDelete?: boolean;
    showDetails?: boolean;
    onActionClick: any;
    classes: any;
    filePreview?: any;
    showEsign?: any;
    showEsignProgress?: any;
    showfileDetails?: any;
}


class CommonItemsActionsBar extends Component<CommonItemsActionsBarProps>
{
    render() {
        const { classes } = this.props;
        return (
            <div data-test='CommonItemsActionsBar'>
                <div className={classes.fileActionBar}>
                    {this.props.showEsign
                        && <IconButton aria-label="Esign" data-test="previewBtn" onClick={(event) => this.props.onActionClick("esignInitiation")}>
                            <EsignIcon className={classes.iconBtn} aria-label="Intiate the Ceremony" />
                        </IconButton>}
                    {this.props.showEsignProgress
                        && <IconButton aria-label="Esign" data-test="previewBtn" onClick={(event) => this.props.onActionClick("esignProgress")}>
                            <EsignIcon className={classes.iconBtn} aria-label="View the Ceremony details" />
                        </IconButton>}
                    {this.props.showDetails
                        && <IconButton aria-label="View" data-test="filedetailsBtn" onClick={(event) => this.props.showfileDetails()}>
                            <InfoFileIcon className={classes.iconBtn} aria-label="File Details" />
                        </IconButton>}
                    {this.props.showView
                        && <IconButton aria-label="View" data-test="previewBtn" onClick={(event) => this.props.filePreview()}>
                            <ViewIcon className={classes.iconBtn} aria-label="View" />
                        </IconButton>}
                    {this.props.showShare && <IconButton data-test="shareBtn" aria-label="Share" onClick={(event) => this.props.onActionClick("share")}>
                        <ShareIcon className={classes.iconBtn} aria-label="Share" />
                    </IconButton>}
                    {
                        this.props.showDelete &&
                        <IconButton aria-label="delete" data-test="deleteBtn" onClick={(event) => this.props.onActionClick("delete")} >
                            <DeleteIcon style={{ color: grey[900] }} />
                        </IconButton>
                    }
                    {this.props.showDownload
                        && <IconButton aria-label="Download" data-test="downloadBtn" onClick={(event) => this.props.onActionClick("download")}>
                            <DownloadIcon className={classes.iconBtn} aria-label="Download" />
                        </IconButton>}
                    {this.props.showRename
                        &&
                        <IconButton aria-label="Rename" data-test="renameBtn" onClick={(event) => this.props.onActionClick("rename")}>
                            <RenameIcon style={{ color: grey[900] }} />
                        </IconButton>}
                    {this.props.showMove
                        &&
                        <IconButton aria-label="move" data-test="moveBtn" onClick={(event) => this.props.onActionClick("move")}>
                            <MoveFolderIcon />
                        </IconButton>}
                </div>
            </div>
        )
    }
}

export default (withStyles(styles)(CommonItemsActionsBar));