export const styles = (theme: any) => ({
    createFolderModalView: {
        position: 'absolute' as 'absolute',
        width: '429px',
        top: '40%',
        left: '40%',
        marginLeft: '-108px',
        [theme.breakpoints.down('xs')]: {
            width: '290px'
        },
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px',
        borderRadius: '3px'
    },
    deleteTextTitle: {
        fontFamily: 'Fidelity Sans',
        fontSize: '24px',
        textAlign: 'left' as 'left',
        margin: 0,
    },
    closeBtn: {
        position: 'absolute' as 'absolute',
        top: '0',
        right: '0',
        color: '#444657'
    },
    deleteTextContent: {
        fontSize: '16px',
        padding: '25px 0 13px 0',
    },
    buttons: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        width: '100%'
    },
    buttonCancel: {
        border: '1px solid',
        float: 'left' as 'left',
        borderColor: theme.fabbuttonmobilebgcolor,
        textTransform: 'none' as 'none',
        padding: '8px 16px',
        margin: '0px 5px',
    },
    buttonSave:{
        backgroundColor: theme.fabbuttonmobilebgcolor,
        "&:hover": {
            backgroundColor: theme.fabbuttonmobilehovercolor
        },
        textTransform: 'none' as 'none',
        margin: '0px 5px',
    },
});