import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DialogContent, DialogActions, Button, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as InfoFileIcon } from '../../../../../assets/info-esign.svg';
import ceremonyTypeConstants from '../ceremonyTypeConstants.json';
import * as CeremonyActionTypes from '../../../../../redux/constants/shared/eSignConstants';
import './ESignStart.scss'

type ESignStartprops = {
    selectedItem: any,
    onActionComplete: any,
    children?: any,
    classes?: any
    onCancelRequest: any;
    ceremonyDetails: any;
    forceInitiate: any;
    isMultiSigner: any;
    formTypeDisclosure?: any;
    getFormSpecificDisclosure: any;
    ceremonyDisclosure: any;
}

interface Ceremony {
    type: string;
    singleSignerTitle: string;
    multiSignerTitle: string;
    content: string;
    footer: string;
}

export const styles = (theme: any) => ({
    main: {
        fontFamily: theme.homefontfamilyregular,
        lineHeight: '24px'
    },
    content: {
        display: 'flex',
        padding: '16px 16px',
        flexDirection: 'column' as 'column'
    },
    subtitle: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 1)',
        width: '100%',
        letterSpacing: '0.15px',
        paddingBottom: '16px',
        lineHeight: '24px'
    },
    title: {
        color: '#000000',
        padding: '0px 16px',
        fontSize: '1.65rem',
        fontWeight: 'bold' as 'bold',
        letterSpacing: '0',
        lineHeight: '32.5px'
    },
    commonModalActions: {
        justifyContent: 'center',
        padding: '0px'
    },
    primarybtn: {
        textTransform: 'none' as 'none',
        width: '170px'
    },
    documentLinkCancel: {
        fontSize: '1rem',
        paddingLeft: '8px',
        position: 'absolute' as 'absolute',
        left: '2rem'
    }
});

type ESignStartStates = {
    selectedCeremony: Ceremony
}

class ESignStart extends Component<ESignStartprops> {

    state = {
        selectedCeremony: {
            type: null,
            header: null,
            singleSignerTitle: null,
            multiSignerTitle: null,
            subTitle: null,
            content: null,
            footer: null,
            bodyText1: null, //the below items are esign specific
            bodyText2: null,
            bodyText3: null,
            disclosureHeader: null,
            footerText: null,
            eReview: null,
            buttonTitle: null,
            disclosureText: null,
        },
        disclosureLinks: [],
        disclosure: '',
        disabled: true
    }

    componentDidMount() {
        let selectedCeremony = ceremonyTypeConstants.ceremony.find((ceremony) => ceremony.type === this.props.ceremonyDetails?.ceremonyTypeCode)
        if (selectedCeremony?.type != 'esign')
            this.setState({ disabled: false });
        this.setState({ selectedCeremony: selectedCeremony })
    }

    componentDidUpdate(prevProps: any) {
        if (!prevProps.ceremonyDisclosure.content.length && this.props.ceremonyDisclosure.content.length) {
            this.setState({ disclosureLinks: this.props.ceremonyDisclosure.content }, () => {
                if (this.state.disclosureLinks) {
                    let arr = this.state.disclosureLinks.map((disc: any) => disc.disclosureType?.disclosureLink);
                    this.props.getFormSpecificDisclosure(arr);
                }
            });
        }
        if (prevProps.formTypeDisclosure.fetching && !this.props.formTypeDisclosure.fetching) {

            if (this.props.formTypeDisclosure.error) {
                this.setState({ disabled: true });
                this.onCancelClick()
            }
            else
                this.setState({ disabled: false });
            let data = '';
            for (let i = 0; i < this.props.formTypeDisclosure.content.length; i++) {
                data = data + this.props.formTypeDisclosure.content[i]["data"] + '<br/>'
            }
            this.setState({ disclosure: data });
        }
        if (this.state.disabled && ((prevProps.ceremonyDisclosure.requesting && !this.props.ceremonyDisclosure.requesting && this.props.ceremonyDisclosure.content.length == 0))) {
            this.setState({ disabled: false });
        }
    }

    onCancelClick = () => {
        this.props.onCancelRequest()
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const eSignCeremony = this.props.ceremonyDetails?.ceremonyTypeCode === 'esign';
        return (
            <div className={eSignCeremony ? "eSignStartMain" : classes.main} data-test="ESignStart">
                {eSignCeremony &&
                    <DialogContent className="content">
                        <div className="disclosure" data-test="disclosureText">

                            {(!this.props.formTypeDisclosure.fetching && this.props.formTypeDisclosure.content.length != 0 && this.state.disclosure.length != 0) &&
                                <div dangerouslySetInnerHTML={{ __html: this.state.disclosure }}>
                                </div>
                            }
                            <DialogActions className="commonModalActions"  >
                                <Link id="cancelInvite" data-test="cancelBtn" className="documentLinkCancel" href="#" onClick={this.onCancelClick.bind(this)}>
                                    Cancel
                                </Link>
                                <Button
                                    id="sendrequest"
                                    className="primarybtn"
                                    data-test="proceedBtn"
                                    variant="contained"
                                    onClick={() => this.props.onActionComplete(this.props.selectedItem, this.props.forceInitiate)}
                                    color="primary"
                                    disabled={(this.state.disabled)}
                                    disableElevation>
                                    {this.state.selectedCeremony.buttonTitle}
                                </Button>
                            </DialogActions>
                        </div>
                    </DialogContent>
                }
                {!eSignCeremony && <>
                    <div className={classes.title} data-test="startHeader">
                        {this.state.selectedCeremony.header}
                    </div>
                    <DialogContent className={classes.content}>
                        <div className={classes.subtitle} data-test="startTitle">
                            {this.props.isMultiSigner ? this.state.selectedCeremony.multiSignerTitle : this.state.selectedCeremony.singleSignerTitle}
                        </div>
                        {this.state.selectedCeremony.subTitle != null && <div className={classes.subtitle} data-test="startSubTitle">
                            {this.state.selectedCeremony.subTitle}
                        </div>
                        }
                        {this.state.selectedCeremony.content != null && <div className={classes.subtitle} data-test="startContent">
                            <b>Note: </b> {this.state.selectedCeremony.content}
                        </div>
                        }
                        <div className={classes.subtitle} data-test="startFooter">
                            {this.state.selectedCeremony.footer}
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.commonModalActions}  >
                        <Link id="cancelInvite" data-test="cancelBtn" className={classes.documentLinkCancel} href="#" onClick={this.onCancelClick.bind(this)}>
                            Cancel
                        </Link>
                        <Button
                            id="sendrequest"
                            className={classes.primarybtn}
                            data-test="proceedBtn"
                            variant="contained"
                            onClick={() => this.props.onActionComplete(this.props.selectedItem, this.props.forceInitiate)}
                            color="primary"
                            disabled={(this.state.disabled)}
                            disableElevation>
                            {this.state.selectedCeremony.buttonTitle}
                        </Button>
                    </DialogActions></>}
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getFormSpecificDisclosure: (formType: string[]) => dispatch({
            type: CeremonyActionTypes.GET_FORM_DISCLOSURE,
            formType: formType
        })
    };
}
const mapStateToProps = (state: any) => {
    return {
        formTypeDisclosure: state.legalsReducer.formTypeDisclosure,
        ceremonyDisclosure: state.eSignReducer.esignCeremonyDisclosure
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ESignStart));