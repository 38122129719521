import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


type SharePermissionProps = {
  classes: any,
  handlePermissionChange: any,
  selectedContactId: string
  defaultPermission: any
}

export const styles = (theme: any) => ({
  popper:
  {
    fontFamily: theme.homefontfamilyregular,
    fontSize: '0.875rem',
  },
  permissionsselect: {
    paddingRight: 0,
    fontFamily: theme.homefontfamilyregular,
    fontSize: '0.875rem !important',
  }
});

const permissionOptions = [
  { id: "VIEW", value: "View" },
  { id: "VIEW_AND_DOWNLOAD", value: "View & Download" }
]


class SharePermission extends Component<SharePermissionProps> {

  constructor(props: any) {
    super(props);
  }

  handlePermissionChange = (event: any, value: any) => {
    if (value) {
      this.props.handlePermissionChange(this.props.selectedContactId, value.id);
    }
  }

  render() {
    const { classes, selectedContactId, defaultPermission } = this.props;
    return (
      <div>
        <Autocomplete
          classes={{
            inputRoot: classes.permissionsselect,
            paper: classes.popper
          }}
          style={{textAlign: 'right'}}
          onChange={(option: any, value: any) => this.handlePermissionChange(option, value)}
          className={classes.AutoComplete}
          key={`combo-${selectedContactId}`}
          id={`combo-${selectedContactId}`}
          disableClearable
          defaultValue={(defaultPermission === permissionOptions[0].id) ? permissionOptions[0] : permissionOptions[1]}
          options={permissionOptions}
          getOptionLabel={option => option.value}
          renderInput={params => <TextField style={{ width: "100%" }}
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true, readOnly: true }} />}
        />
      </div>)
  }
}

export default withStyles(styles)(SharePermission);
