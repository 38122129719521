import React, { Component } from 'react';
import { CommonFileActionsBar, CommonFileItemList, CommonFileGridList } from '../../../../shared/ui';
import { IconButton, AppBar, Hidden } from '@material-ui/core';
import { ReactComponent as GridIcon } from '../../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../../assets/list-view.svg';
import { connect } from 'react-redux';
import * as ShareItemsActionTypes from '../../../../redux/constants/personalVault/shareItemsConstants';
import * as AppUIStateActionTypes from '../../../../redux/constants/shared/appStatesConstants';
import { fsFileSharing } from '../../../../shared/core/utils/paginationDefaults';

import './ShareListView.scss';

type ShareListViewProps = {
    getAllItemsSharedWithMe: any,
    sharedWithMeFiles: any,
    selectFileToPreview: any,
    history: any,
    filesFetching: boolean,
    updateFileHeaders: any,
    fileListHeaders: any
}

type ShareListViewState = {
    value: number,
    listView: boolean,
    pagination: PaginationType,
    sharedByMeFiles: fileItemType[],
    selectedFiles: string[],
    initPage: number
}

type fileItemType = {
    itemCode: string,
    itemName: string,
    itemType: string,
    size: number,
    mineType: string,
    title: string,
    createdAt: string,
    modifiedAt: string,
    owner: any,
    status: string,
    permissionName: string
}

interface PaginationType {
    direction: string,
    orderBy: string,
    page: number;
    size: number;
}

class ShareListView extends Component<ShareListViewProps, ShareListViewState>{

    state = {
        value: 0,
        listView: true,
        pagination: {
            direction: fsFileSharing.direction,
            orderBy: fsFileSharing.orderBy,
            page: fsFileSharing.page,
            size: fsFileSharing.size
        },
        sharedByMeFiles: [],
        selectedFiles: [],
        initPage: 0
    }

    componentDidMount() {
        this.props.getAllItemsSharedWithMe('FILE', this.state.pagination);
    }

    toggleViews = () => {
        this.setState({ listView: !this.state.listView })
    }

    sortOnClick = (id: string) => {
        let tmp_orderBy: string = ''
        switch (id) {
            case 'fileName':
                tmp_orderBy = 'NAME';
                break;
            case 'modifiedAt':
                tmp_orderBy = 'MODIFIED_AT';
                break;
            default:
                tmp_orderBy = 'NAME'
        }

        if (tmp_orderBy === this.state.pagination.orderBy) {
            this.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    direction: prevState.pagination.direction === 'ASC' ? 'DESC' : 'ASC'
                }
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    orderBy: tmp_orderBy
                }
            }))
        }

        this.props.getAllItemsSharedWithMe('FILE', this.state.pagination);
    }

    onFileSelection = (selectedIds: string[]) => {
        var selectedFiles = []
        selectedFiles = this.props.sharedWithMeFiles.files.filter((I: any) =>
            selectedIds.findIndex((x: string) => x === I.itemCode) > -1
        );
        this.setState({ selectedFiles: selectedFiles });
    }

    onFileOpen = (file: any) => {
        this.props.selectFileToPreview(file);
        this.props.history.push(`/personal/file-detail/${file.itemCode}`);
    }

    onFolderOpen = () => {
        console.log('Folder Open')
    }

    fetchMoreData = () => {
        this.setState(prevState => {
            return { initPage: prevState.initPage + 1 }
        })


        setTimeout(() => {
            console.log('Fetch More Data!')
            // this.props.getMoreItemsSharedWithMe( 'FILE', paginationNew );
        }, 2000)
    }

    onHeaderFiltering = (filteredHeaders: string[]) => {
        this.props.updateFileHeaders(filteredHeaders)
    }

    render() {
        return (
            <div>
                { this.props.sharedWithMeFiles.files.length > 0 &&
                    <div>
                        <AppBar position="static" className="app-bar-wrapper">
                            <div className="action-bar">
                                <CommonFileActionsBar
                                    selectedFiles={this.state.selectedFiles}
                                    isShareEnabled={false}
                                />
                                <Hidden xsDown>
                                    {this.state.listView &&
                                        <IconButton aria-label="views" className="iconBtn-wrapper" onClick={this.toggleViews}>
                                            <GridIcon className="iconBtn" />
                                        </IconButton>
                                    }
                                    {!this.state.listView &&
                                        <IconButton aria-label="views" className="iconBtn-wrapper" onClick={this.toggleViews}>
                                            <ListViewIcon className="iconBtn" />
                                        </IconButton>
                                    }
                                </Hidden>
                            </div>
                        </AppBar>
                        <div>
                            <Hidden xsDown>
                                {this.state.listView &&
                                    <CommonFileItemList
                                        files={this.props.sharedWithMeFiles.files}
                                        curSorting={this.props.sharedWithMeFiles.files}
                                        filesFetching={this.props.filesFetching}
                                        lazyLoading={this.props.sharedWithMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreData}
                                        onSorting={this.sortOnClick}
                                        allowChangePermission={false}
                                        showOwner={true}
                                        showMeAsOnwer={false}
                                        onSelection={this.onFileSelection}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        showPermission={true}
                                        showUploaded={false}
                                        showFileSize={false}
                                        tableMarginHeight={255} 
                                        fileListHeaders={this.props.fileListHeaders}
                                        onFiltering={this.onHeaderFiltering}
                                    />
                                }
                                {!this.state.listView &&
                                    <CommonFileGridList
                                        files={this.props.sharedWithMeFiles.files}
                                        filesFetching={this.props.filesFetching}
                                        lazyLoading={this.props.sharedWithMeFiles.lazyLoading}
                                        fetchMoreData={this.fetchMoreData}
                                        allowChangePermission={false}
                                        onSelection={this.onFileSelection}
                                        tableMarginMinHeight={255}
                                        tableMarginMaxHeight={255}
                                        showPermission={true}
                                        showFileSize={false}
                                        showOnwer={true}
                                        onFileOpen={this.onFileOpen}
                                        onFolderOpen={this.onFolderOpen}
                                        showMeAsOnwer={true}
                                        showUploaded={false} />
                                }
                            </Hidden>
                            <Hidden smUp>
                                <CommonFileGridList
                                    files={this.props.sharedWithMeFiles.files}
                                    filesFetching={this.props.filesFetching}
                                    lazyLoading={this.props.sharedWithMeFiles.lazyLoading}
                                    fetchMoreData={this.fetchMoreData}
                                    allowChangePermission={false}
                                    onSelection={this.onFileSelection}
                                    tableMarginMinHeight={255}
                                    tableMarginMaxHeight={255}
                                    showPermission={true}
                                    showFileSize={false}
                                    showOnwer={true}
                                    onFileOpen={this.onFileOpen}
                                    onFolderOpen={this.onFolderOpen}
                                    showMeAsOnwer={true}
                                    showUploaded={false} />
                            </Hidden>
                        </div>
                    </div>
                }
                {
                    this.props.sharedWithMeFiles.files.length === 0 &&
                    <div>This page shows files that your contacts have shared with you. There are no such files yet.</div>
                }
            </div>

        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        sharedWithMeFiles: state.allShareItemsReducer.itemsSharedWithMe,
        filesFetching: state.allShareItemsReducer.itemsSharedWithMe.fetching,
        fileListHeaders: state.appUIStateReducer.fileListHeaders
    };
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllItemsSharedWithMe: (itemType: any, pagination: any) => dispatch({
            type: ShareItemsActionTypes.SHARED_GET_ALL_SHARED_ITEMS_WITH_ME_REQUEST,
            itemType: itemType,
            pagination: pagination
        }),
        selectFileToPreview: (item: any) => dispatch({ type: ShareItemsActionTypes.PREVIEW_FILE, selectedFile: item }),
        updateFileHeaders: (filteredHeaders: string[]) => dispatch({
            type: AppUIStateActionTypes.UPDATE_FILE_LIST_HEADERS,
            filteredHeaders: filteredHeaders
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareListView);
