import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ContactActionTypes from '../../../redux/constants/personalVault/contactsConstants';
import ContactDetailProfileCard from './components/contactDetailProfileCard';
import { CommonModal } from '../../../shared/ui';
import RemoveContactContent from '../contacts/components/popUpContent/RemoveContacts';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContactDetailFileSharingTable from './components/contactDetailFileSharingTable';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';

type ContactType = {
    createdAt: string,
    modifiedAt: string,
    contactCode: string,
    contactFirstName: string,
    contactLastName: string,
    contactNickname: string,
    contactEmail: string,
    status: string,
    statusUpdated: string,
}

type FsContactsDetailPageProps = RouteComponentProps<any> & {
    components: string[]
}

type FsContactDetailStateProps = {
    contactData: ContactType[],
    snackbarData: any,
    fileListHeaders: any
}

type FsContactDetailDispatchProps = {
    deleteContact: (userCode: string) => any,
    updateSnackbar: (snackbarVal: any) => any,
    updateFileHeaders: (fileHeaders: any) => any
}

type FsContactsDetailPageState = {
    contactCode: string,
    modalOpenState: boolean
}

type FsContactsDetailProps = FsContactsDetailPageProps & FsContactDetailStateProps & FsContactDetailDispatchProps

class FsContactsDetailPage extends Component<FsContactsDetailProps, FsContactsDetailPageState> {

    contactCode = window.location.pathname.split('/').pop();
    selectedContact: ContactType;
    modalContent: any;

    constructor(props: FsContactsDetailProps) {
        super(props);
        this.selectedContact = {
            createdAt: '',
            modifiedAt: '',
            contactCode: '',
            contactFirstName: '',
            contactLastName: '',
            contactNickname: '',
            contactEmail: '',
            status: '',
            statusUpdated: ''
        }
    }

    state = {
        contactCode: '',
        modalOpenState: false,
    }

    UNSAFE_componentWillMount() {
        this.contactCode = window.location.pathname.split('/').pop();
        this.selectedContact = this.props.contactData.filter((contact: any) => {
            return contact.contactCode === this.contactCode;
        })[0]
        this.setState({
            modalOpenState: false
        })
    }

    componentDidMount() {
        this.setState({
            contactCode: window.location.pathname.split('/').pop() || ''
        })
        this.selectedContact = this.props.contactData.filter((contact: any) => {
            return contact.contactCode === this.contactCode;
        })[0]
        this.setState({
            modalOpenState: false
        })
    }

    cancelConfirm = () => {
        this.setState({ modalOpenState: false });
    }

    removeContactConfirm = (contactCode: string) => {
        this.setState({ modalOpenState: false }, () => {
            this.props.deleteContact(contactCode);
        });
        this.props.updateSnackbar({
            modalOpen: true,
            content: `Contact ${this.selectedContact.contactFirstName} ${this.selectedContact.contactLastName} has been removed.`
        })
        this.props.history.push('/personal/contacts');
    }

    removeContactOnClick = () => {
        this.setState({ modalOpenState: true })
        this.modalContent =
            <RemoveContactContent
                cancelConfirm={this.cancelConfirm}
                removeContactConfirm={() => this.removeContactConfirm(this.selectedContact.contactCode)} />
    }

    modalClose = () => {
        this.setState({ modalOpenState: false });
    }

    onHeaderFiltering = (filteredHeaders: string[]) => {
        this.props.updateFileHeaders(filteredHeaders)
    }

    render() {
        return (
            <>
                <ContactDetailProfileCard contact={this.selectedContact} removeContactOnClick={this.removeContactOnClick} />
                <ContactDetailFileSharingTable contact={this.selectedContact} history={this.props.history} fileListHeaders={this.props.fileListHeaders} onFiltering={this.onHeaderFiltering}/>
                <CommonModal modalOpen={this.state.modalOpenState}
                    modalClose={this.modalClose}
                    content={this.modalContent}
                />
            </>
        )
    }
}

const mapStateToProps = (state: any): FsContactDetailStateProps => {
    return {
        contactData: state.contactsReducer.contacts.contactsData.content,
        snackbarData: state.appUIStateReducer.snackbar,
        fileListHeaders: state.appUIStateReducer.fileListHeaders
    };
}

const mapDispatchToProps = (dispatch: any): FsContactDetailDispatchProps => {
    return {
        deleteContact: (userCode: string) => dispatch({
            type: ContactActionTypes.CONTACT_API_DELETE_CONTACTS_REQUEST,
            userCode: userCode
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        updateFileHeaders: (filteredHeaders: string[]) => dispatch({
            type: AppUIStateActionTypes.UPDATE_FILE_LIST_HEADERS,
            filteredHeaders: filteredHeaders
        })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FsContactsDetailPage));
