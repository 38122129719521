import * as AppStateActionTypes from "../../../redux/constants/shared/appStatesConstants";
import { con } from "../../../shared/config/config";

const pvDrawerMenuItemsInitialState = [
  {
    id: "myfiles",
    label: "My Files",
    link: "myfiles",
    active: true,
  },
  {
    id: "recents",
    label: "Recents",
    link: "recents",
    active: false,
  },
  {
    id: "shared",
    label: "Shared",
    link: "shared",
    active: false,
  },
  {
    id: "contacts",
    label: "Contacts",
    link: "contacts",
    active: false,
  },
];

const pvSubDrawerMenuItemsInitialState = [
  {
    id: "uploadfiles",
    label: "Upload New Files",
    link: "uploadfiles",
    active: false,
  },
  {
    id: "addfolder",
    label: "Add a Folder",
    link: "addfolder",
    active: false,
  },
  {
    id: "invitecontact",
    label: "Invite a Contact",
    link: "invitecontact",
    active: false,
  },
  {
    id: "creategroup",
    label: "Create a Group",
    link: "creategroup",
    active: false,
  },
];

const cvDrawerMenuItemsInitialState = [
  {
    id: "contacts",
    label: "Contacts",
    link: "contacts",
    active: false,
  },
  {
    id: "topics",
    label: "Topics",
    link: "topics",
    active: false,
  },
];

const DEFAULT_CONFIG = {
  trackingId: con.GA_ID_DEV,
  debug: true,
  gaOptions: {
    cookieDomain: "none",
    userId: "",
  },
};

const initState = {
  contactTabIndex: 0,
  selectedGroupContacts: [
    {
      createdAt: "",
      modifiedAt: "",
      contactCode: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      status: "",
      statusUpdated: "",
    },
  ],
  snackbar: {
    modalOpen: false,
    content: "",
    isError: false,
  },
  selectedFileToPreview: null,
  fileDetailMenuOpen: false,
  selectedGroup: {
    grpCode: "",
    grpDisplayCode: "",
    grpName: "",
    createdAt: "",
    modifiedAt: "",
    userCount: "",
  },
  selectedTopic: [
    {
      stCode: "",
      categoryName: "",
      createdAt: "",
      modifiedAt: "",
      categoryIconColor: "",
      catgeoryIconLabel: "",
      sharedTopicItemsCount: 0,
      sharedTopicUsersCount: 0,
    },
  ],
  vaultName: "cv",
  baseUrl: "home",
  breadcrumbItemList: [],
  drawerMenuItems: [],
  error: null,
  commonModal: {
    openState: false,
    content: null,
  },
  commonSwipeableDrawerValue: {
    openState: false,
    anchor: null,
  },
  feedbackOpen: true,
  cognitoLogin: {
    state: 0,
    userName: "",
    password: "",
    errorMessage: null,
  },
  GAConfig: [DEFAULT_CONFIG],
  reactGaInitialised: false,
  topicListHeaders: ['stName', 'categoryName', 'modifiedAt', 'createdAt', 'contactsCount', 'topicsCount'],
  fileListHeaders: ['fileName', 'modifiedAt', 'shared', 'size', 'permission'],
  selectedFileToViewDetails: null
};

function appUI(state = initState, action) {
  switch (action.type) {
    case AppStateActionTypes.APP_STATE_UPDATE_FEEDBACK_UPDATE:
      return {
        ...state,
        feedbackOpen: action.feedbackOpen,
      };
    case AppStateActionTypes.APP_STATE_GENERIC_ERROR:
      return {
        ...state,
        error: action.errorResponse,
      };
    case AppStateActionTypes.APP_STATE_UPDATE_CONTACTS_PAGE_TABS:
      return {
        ...state,
        contactTabIndex: action.tabIndex,
      };

    case AppStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_REQUEST:
      return {
        ...state,
        selectedGroupContacts: initState.selectedGroupContacts,
      };

    case AppStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        selectedGroupContacts: action.selectedGroupContacts,
      };

    case AppStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_FAILURE:
      return {
        ...state,
        selectedGroupContacts: initState.selectedGroupContacts,
      };

    case AppStateActionTypes.APP_STATE_UPDATE_SNACKBAR:
      //Check if action.snackbar has isError field else set to false
      if (action.snackbar.isError === null) action.snackbar.isError = false;
      return {
        ...state,
        snackbar: action.snackbar,
      };

    case AppStateActionTypes.PREVIEW_FILE:
      return {
        ...state,
        selectedFileToPreview: action.selectedFile,
      };
    case AppStateActionTypes.VIEW_FILE_DETAILS:
        return {
          ...state,
          selectedFileToViewDetails: action.selectedFile,
        };
  
   case AppStateActionTypes.TOGGLE_FILE_DETAIL_MENU:
      return {
        ...state,
        fileDetailMenuOpen: action.menuOpen,
      };
    case AppStateActionTypes.APP_STATE_UPDATE_GROUP:
      return {
        ...state,
        selectedGroup: action.group,
      };

    case AppStateActionTypes.TOPIC_API_SELECT_TOPIC:
      return {
        ...state,
        selectedTopic: [action.selectedTopic],
      };
    case AppStateActionTypes.ADD_BREADCRUMB_ITEM:
      return {
        ...state,
        breadcrumbItemList: [
          ...state.breadcrumbItemList,
          {
            itemName: action.itemName,
            itemLink: action.itemLink,
          },
        ],
      };
    case AppStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM:
      return {
        ...state,
        breadcrumbItemList: [
          {
            itemName: action.itemName,
            itemLink: action.itemLink,
          },
        ],
      };
    case AppStateActionTypes.REMOVE_BREABCRUMB_ITEMS:
      return {
        ...state,
        breadcrumbItemList: [
          ...state.breadcrumbItemList.slice(0, action.lastIndex),
        ],
      };
    case AppStateActionTypes.EMPTY_BREADCRUMB_ITEM:
      return {
        ...state,
        breadcrumbItemList: [],
      };

    case AppStateActionTypes.INIT_DRAWER:
      if (action.vaultName === "cv") {
        return {
          ...state,
          vaultName: "cv",
          baseUrl: "customer",
          drawerMenuItems: cvDrawerMenuItemsInitialState,
        };
      } else if (action.vaultName === "pv-sub") {
        pvSubDrawerMenuItemsInitialState.forEach(
          (item) => (item.active = false)
        );
        return {
          ...state,
          vaultName: "pv",
          baseUrl: "personal",
          drawerMenuItems: pvSubDrawerMenuItemsInitialState,
        };
      } else {
        return {
          ...state,
          vaultName: "pv",
          baseUrl: "personal",
          drawerMenuItems: pvDrawerMenuItemsInitialState,
        };
      }

    case AppStateActionTypes.UPDATE_DRAWER_MENU:
      state.drawerMenuItems.forEach((item) => {
        item.id === action.selectedMenu
          ? (item.active = true)
          : (item.active = false);
      });
      return {
        ...state,
        drawerMenuItems: state.drawerMenuItems
      };
    case AppStateActionTypes.UPDATE_COMMON_MODAL:
      return {
        ...state,
        commonModal: {
          ...state.commonModal,
          openState: action.openState,
          content: action.content,
        },
      };
    case AppStateActionTypes.UPDATE_COMMON_SWIPEABLE_DRAWER:
      return {
        ...state,
        commonSwipeableDrawerValue: {
          ...state.commonSwipeableDrawerValue,
          openState: action.openState,
          anchor: action.anchor,
        },
      };
    case AppStateActionTypes.UPDATE_COGNITO_LOGIN_STATE:
      return {
        ...state,
        cognitoLogin: {
          ...state.cognitoLogin,
          state: action.state != null ? action.state : state.cognitoLogin.state,
          userName:
            action.userName != null
              ? action.userName
              : state.cognitoLogin.userName,
          password:
            action.password != null
              ? action.password
              : state.cognitoLogin.password,
        },
      };
    case AppStateActionTypes.UPDATE_COGNITO_ERROR:
      return {
        ...state,
        cognitoLogin: {
          ...state.cognitoLogin,
          errorMessage: action.errorMessage,
        },
      };
    case AppStateActionTypes.UPDATE_GACONFIG:
      return {
        ...state,
        GAConfig: action.GAConfig,
        reactGaInitialised: true,
      };

    case AppStateActionTypes.UPDATE_TOPIC_LIST_HEADERS:
      return {
        ...state,
        topicListHeaders: action.filteredHeaders
      };
    
    case AppStateActionTypes.UPDATE_FILE_LIST_HEADERS:
      return {
        ...state,
        fileListHeaders: action.filteredHeaders
      };
         
    default:
      return state;
  }
}

export default appUI;
