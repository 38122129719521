import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fsPaginationContacts } from '../../../../shared/core/utils/paginationDefaults';
import {
  getActiveContactsAPI
  , getContactsInGroupAPI
} from '../../../../shared/core/utils/apiFunctions';



type ContactListSearchProps = {
  classes: any,
  loggedIn: any,
  handleChange: any,
  handleError: any,
  contactGroupId?: string
}

export const styles = (theme: any) => ({
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  text: {
  },
  textfield: {
    width: '90%',
    height: 76,
    margin: theme.spacing(2)
  },
  autocomplete: {
    paddingRight: '10px !important'
  },
  clear: {
    paddingRight: '40px !important'
  },
  paper:
  {
    border: '1px solid #dfe1e5',
    borderRadius: '8px',
    boxShadow: "0px 2px 38px rgba(0, 0, 0, 0.2)",
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
});

class ContactListSearch extends Component<ContactListSearchProps> {

  state = { seletecedValues: [], loading: false, contacts: new Map() }

  componentDidMount() {
    this.setState({ seletecedValues: [], loading: true })
    if (this.props.loggedIn.user_profile) {
      this.setState({ loading: true })

      getActiveContactsAPI(this.props.loggedIn.user_profile.userCode, fsPaginationContacts.direction,
        fsPaginationContacts.orderBy, fsPaginationContacts.page, fsPaginationContacts.size)?.then((result: any) => {
          //let output: Map<string, any> = new Map();
          const output = result.data.content.reduce((accumulator: Map<string, any>, contact: any) => {
            accumulator.set(contact.contactCode, contact)
            return accumulator
          },new Map())
          this.setState({ contacts: output, loading: false })
          if (this.props.contactGroupId) {
            //If the Contact Group exists, read the existing contacts from the group
            getContactsInGroupAPI(this.props.contactGroupId)?.then((result: any) => {
              if (result.data.content && result.data.content.length > 0) {
                //Remove the selectec values from the contact list.
                result.data.content.forEach((newvalue: any) => {
                  this.state.contacts.delete(newvalue.contactCode)
                });
                this.setState({ seletecedValues: result.data.content })
                this.props.handleChange(this.state.seletecedValues.map((a: any) => a.contactCode))
              }
            }).catch((err: any) => {
              //If it is 401 take the user back to login page.
              this.props.handleError(err)
            });
          }
        }).catch((err: any) => {
          //If it is 401 take the user back to login page.
          this.props.handleError(err)
          this.setState({ loading: false })
        });
    }
  }


  handleInputChange = (newvalue: any) => {
    if (newvalue) {
      this.state.contacts.delete(newvalue.contactCode);
      this.setState(prevState => {
        return {
          ...prevState,
          seletecedValues:
            [...this.state.seletecedValues, newvalue],
          contacts: this.state.contacts
        }
      }, () => {
        this.props.handleChange(this.state.seletecedValues.map((a: any) => a.contactCode))
      });
    }

  }

  handleClick = (data: any) => {
  };

  handleDelete = (chipToDelete: any) => () => {
    this.setState({
      seletecedValues: this.state.seletecedValues.filter((chip: any) => chip.contactCode !== chipToDelete.contactCode),
      contacts: this.state.contacts
    },
      () => {
        this.props.handleChange(this.state.seletecedValues.map((a: any) => a.contactCode))
      });
    this.state.contacts.set(chipToDelete.contactCode, chipToDelete)
  };
  render() {

    const { classes } = this.props;
    return (
      <div>
        <Autocomplete
          autoComplete={true}
          autoHighlight={true}
          id="contacts"
          noOptionsText="No Contacts"
          classes={{
            inputRoot: classes.autocomplete,
            endAdornment: classes.clear,
            paper: classes.paper
          }}
          clearOnEscape={true}
          forcePopupIcon={false}
          disableCloseOnSelect={false}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <div className={classes.text}>
                {option.contactLastName ? `${option.contactFirstName} ${option.contactLastName}` : `${option.contactFirstName}`}
                <br />
                {option.contactEmail}
              </div>
            </React.Fragment>
          )}
          loading={this.state.loading}
          onChange={(option: any, value: any) => this.handleInputChange(value)}
          getOptionSelected={(option, value) => option.contactCode === value.contactCode}
          options={Array.from(this.state.contacts.values())}
          getOptionLabel={option => option.contactFirstName}
          renderInput={params => (
            <TextField
              {...params}
              label="Add Contacts"
              variant="outlined"
              className={classes.textfield}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    <AddIcon color="primary" />
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <div className={classes.chiproot} style={{ flexWrap: "wrap" }}>
          {this.state.seletecedValues.map((data: any) => {
            return (
              <Chip
                key={data.contactCode}
                label={data.contactFirstName}
                onDelete={this.handleDelete(data)}
                variant="outlined"
              />
            );
          })}
        </div>
      </div>
    )
  }
}



const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.login,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContactListSearch));
