import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import fileIcon from '../../../../../assets/file-grey.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/download.svg';
import { TOGGLE_FILE_DETAIL_MENU } from '../../../../../redux/constants/personalVault/myFilesConstants';
import './PreviewWindow.scss';

interface OwnerType {
    isOwner: boolean,
    isGroupOwner: boolean,
    grpCode: string,
    userCode: string
}

interface FileType {
    itemCode: string,
    itemName: string,
    itemType: string,
    parentCode: string,
    size: number,
    mimeType: string,
    title: string,
    createdAt: string,
    modifiedAt: string,
    owner: OwnerType,
    status: string,
    premissionName: string
}

type PreviewWindowProps = {
    history: any,
    selectedFileForPreview: FileType,
    toggleFileDetailMenu: any,
    menuOpen: boolean,
}

type PreviewWindowStates = {}

class PreviewWindow extends Component<PreviewWindowProps, PreviewWindowStates> {
    toggleMenu = () => {
        this.props.toggleFileDetailMenu(!this.props.menuOpen)
    }

    goMyFilesPage = () => {
        this.props.history.push('/personal/myfiles')
    }

    render() {
        return(
            <div className='main-wrapper'>
                <div className='top-action-bar'>
                    <div className="action-title">
                        <IconButton onClick={ this.goMyFilesPage } aria-label='go back'>
                            <ArrowBackIcon />
                        </IconButton>
                        { this.props.selectedFileForPreview.itemType === 'FILE' && 
                            <img className='typeIcon' src={fileIcon} alt="File" />}
                        { this.props.selectedFileForPreview.itemName }
                    </div>
                    <div>
                        <IconButton aria-label="download">
                            <DownloadIcon className='iconBtn' aria-label="Download" />
                        </IconButton>
                        <IconButton aria-label='print'>
                            <PrintIcon />
                        </IconButton>
                        { !this.props.menuOpen &&
                            <IconButton aria-label='more' onClick={this.toggleMenu}>
                                <MoreVertIcon />
                            </IconButton>
                        }
                    </div>
                </div>
                <div className='preview-window'>
                    File Preview
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedFileForPreview: state.appUIStateReducer.selectedFileToPreview,
        menuOpen: state.appUIStateReducer.fileDetailMenuOpen
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleFileDetailMenu: (open: boolean) => dispatch({ type: TOGGLE_FILE_DETAIL_MENU, menuOpen: open })
    }
}


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewWindow));