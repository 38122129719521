import { takeLatest, call, put, all, select } from "redux-saga/effects";
import * as UserActionTypes from "../../constants/customerVault/userConstants";
import * as appActionTypes from "../../constants/shared/appStatesConstants";
import {
  getContacts,
  getSearchResults,
  getUserProfileByUserCode,
} from "../../../shared/core/utils/apiFunctions";

export function* userWatcher() {
  yield takeLatest(UserActionTypes.USER_API_GET_USERS_REQUEST, allUsersSaga);
  yield takeLatest(
    UserActionTypes.USER_API_LOAD_MORE_USERS_REQUEST,
    loadMoreUsersSaga
  );
  yield takeLatest(
    UserActionTypes.USER_API_CONTACT_SEARCH_REQUEST,
    userSearchSaga
  );
  yield takeLatest(UserActionTypes.USER_API_GET_USER_REQUEST, userProfileSaga);
}

function* allUsersSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const contacts = yield call(
        getContacts,
        grpCode,
        action.pattern,
        action.pagination.page,
        action.pagination.size,
        action.pagination.direction,
        action.pagination.orderBy
      );
      yield put({
        type: UserActionTypes.USER_API_GET_USERS_SUCCESS,
        contacts: contacts,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({ type: UserActionTypes.USER_API_GET_USERS_FAILURE, error });
    } else {
      yield put({ type: UserActionTypes.USER_API_GET_USERS_FAILURE, error });
    }
  }
}

function* loadMoreUsersSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const contacts = yield call(
        getContacts,
        grpCode,
        action.pattern,
        action.pagination.page,
        action.pagination.size,
        action.pagination.direction,
        action.pagination.orderBy
      );
      yield put({
        type: UserActionTypes.USER_API_LOAD_MORE_USERS_SUCCESS,
        contacts: contacts,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: UserActionTypes.USER_API_LOAD_MORE_USERS_FAILURE,
          error,
        });
    } else {
      yield put({
        type: UserActionTypes.USER_API_LOAD_MORE_USERS_FAILURE,
        error,
      });
    }
  }
}

function* userSearchSaga(action) {
  try {
    //Todo: Get the Group code from the state
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const searchResults = yield call(
        getSearchResults,
        grpCode,
        action.searchString
      );
      if (searchResults.data.length > action.limit) {
        let d = searchResults.data.splice(0, action.limit);
        d.push("View More");
        searchResults["data"] = d;
      }
      yield put({
        type: UserActionTypes.USER_API_CONTACT_SEARCH_SUCCESS,
        responseInfo: searchResults,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: UserActionTypes.USER_API_CONTACT_SEARCH_FAILURE,
          error,
        });
    } else {
      yield put({
        type: UserActionTypes.USER_API_CONTACT_SEARCH_FAILURE,
        error,
      });
    }
  }
}

function* userProfileSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined && action.userCode !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const contact = yield call(
        getUserProfileByUserCode,
        grpCode,
        action.userCode
      );
      yield put({
        type: UserActionTypes.USER_API_GET_USER_SUCCESS,
        contact: contact,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({ type: UserActionTypes.USER_API_GET_USER_FAILURE, error });
    } else {
      yield put({ type: UserActionTypes.USER_API_GET_USER_FAILURE, error });
    }
  }
}

export default userWatcher;
