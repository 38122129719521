import React, { Component } from 'react';
import { Divider, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fab, Link, TextField } from '@material-ui/core';
import TextInput from '../../../../../shared/core/TextInput';


type ResetPasswordProps = {
    onResetSubmit: any;
    classes: any;
    onCancel: any;
    loading: any;
    errorMessage: any;
}
export const styles = (theme: any) => ({
    mainContainer:
    {
        maxWidth: '420px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none'
        }
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
    },
    title: {
        fontSize: '1.9rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(2),
        fontWeight: 'lighter' as 'lighter'
    },
    description: {
        fontSize: '0.875rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(1),
        fontWeight: 300
    },
    inputContainer:
    {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'left',
        alignItems: 'center',
        padding: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            alignItems: 'left',
            flexDirection: 'column' as 'column',
        },
        marginBottom: theme.spacing(2)
    },
    nameLabel:
    {
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'center',
        fontFamily: theme.homefontfamilybold,
        fontSize: '0.9rem',
        width: '20%',
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            justifyContent: 'flex-start',
            width: '100%',
        }
    },
    inputtextField:
    {
        width: '100%',
        marginLeft: theme.spacing(2),
        [`${theme.breakpoints.down('xs')} and (orientation : portrait)`]: {
            marginRight: theme.spacing(4)
        }
    },
    codeText:
    {
        paddingBottom: theme.spacing(3)
    },
    cancelLink:
    {
        alignSelf: 'center',
        cursor: "pointer"
    },
    fabBtn: {
        width: 120,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
    row: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-evenly',
        paddingTop: theme.spacing(5)
    },
    errorMessage: {
        color: 'red',
        fontSize: '13px',
        textAlign: 'center' as 'center'
    },
});

class ResetPassword extends Component<ResetPasswordProps> {

    state = {
        username: '',
        usernameError: false
    }

    handleUserNameChange = (id: any, value: string) => {
        this.setState({ username: value })
    }

    handleSubmit = () => {
        if (this.state.username === null || this.state.username === '')
            this.setState({ usernameError: true })
        else {
            if (this.state.usernameError)
                this.props.onResetSubmit(this.state.username);
        }
    }

    validate = (id: string, isvalid: boolean) => {
        this.setState({ usernameError: isvalid })
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.mainContainer} data-test="resetPassword">
                <div className={classes.titleContainer}>
                    <div className={classes.title}>Verify your identity</div>
                    <div className={classes.description}>Please enter your email. We need to send you a code to verify that you're the account owner.</div>
                </div>
                <div className={classes.inputContainer}>
                    <div className={classes.nameLabel}>Email</div>
                    <div className={classes.inputtextField}>
                        <TextInput
                            forceValidate={this.state.usernameError}
                            data-test="resetPasswordInputBox"
                            validate={this.validate}
                            size="medium"
                            isFocus={true}
                            id="user"
                            validateRegex="^[a-zA-Z0-9._%+-\\-`\']+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$"
                            handleInPutChange={this.handleUserNameChange}
                            assistiveText={this.state.usernameError ? "required" : "Please enter a valid Email."}
                            label="Email"
                            length={100}
                            onSubmit={this.handleSubmit}>
                        </TextInput>
                    </div>
                </div>
                <Typography className={classes.errorMessage}>{this.props.errorMessage}</Typography>
                <div className={classes.row}>
                    <Link data-test="cancelLink" className={classes.cancelLink} onClick={() => { this.props.onCancel() }}>Cancel   </Link>
                    <Fab data-test="submitFab" disabled={this.props.loading} variant='extended' color='primary' className={classes.fabBtn} onClick={this.handleSubmit} >
                        Submit
                            </Fab>
                </div>
            </Paper>)
    }
}

export default (withStyles(styles)(ResetPassword));