import * as shareContactActionTypes from '../../constants/personalVault/shareContactConstants';
import * as Types from '../../../shared/core/ContactType';

const initState = {
    shareContacts: [],
    fetching: false,
    updating: false,
    error: null,
    updateResponse: null
}

export default (state = initState, action) => {
    switch (action.type) {
        // Get share Contacts
        case shareContactActionTypes.GET_SHARE_CONTACTS_SUCCESS:
            //Get Contact groups
            var result = [];
            var contactGroups = action.response.data.contactGroups;
            var contacts = action.response.data.contacts;
            contactGroups.map(x => {
                var newitem =
                {
                    code: x.cgCode,
                    name: x.cgName,
                    type: "GROUP",
                    permission: x.permissionName //update the permissions
                }
                result.push(newitem)
            });
            contacts.map(contactObj => {
                var name = "";
                if (contactObj.lastName)
                    name = `${contactObj.firstName} ${contactObj.lastName}`;
                else
                    name = `${contactObj.firstName}`;
                var newitem =
                {
                    code: contactObj.contactCode,
                    name: name,
                    type: "USER",
                    permission: contactObj.permissionName//update the permissions
                }
                result.push(newitem)
            });
            return {
                ...state,
                fetching: false,
                error: null,
                shareContacts: result
            };
        case shareContactActionTypes.GET_SHARE_CONTACTS_FAILURE:
            return {
                ...state,
                fetching: false,
                shareContacts: [],
                error: { ...action.error }
            };
        //update share contacts API
        case shareContactActionTypes.UPDATE_SHARE_CONTACTS_REQUEST:
            return {
                ...state,
                updating: true,
                updateResponse: null
            };
        case shareContactActionTypes.UPDATE_SHARE_CONTACTS_SUCCESS:
            return {
                ...state,
                updating: false,
                shareContacts: [],
                updateResponse: action.response.data,
                error: null
            };
        case shareContactActionTypes.UPDATE_SHARE_CONTACTS_FAILURE:
            return {
                ...state,
                updating: false,
                shareContacts: [],
                updateResponse: null,
                error: { ...action.error }
            };

        // ADD to share Contacts UI
        case shareContactActionTypes.ADD_SHARE_CONTACTS:
            var index = state.shareContacts.findIndex(I => I.code === action.data.code);
            if (index < 0)
                return {
                    ...state,
                    shareContacts:
                        [action.data, ...state.shareContacts]
                };
            else
                return state;
        case shareContactActionTypes.REMOVE_SHARE_CONTACT:
            var index = state.shareContacts.findIndex(I => I.code === action.data.code);
            if (index > -1) {
                return {
                    ...state,
                    shareContacts: [
                        ...state.shareContacts.slice(0, index),
                        ...state.shareContacts.slice(index + 1)
                    ]
                }
            }
            else
                return state;
        case shareContactActionTypes.EDIT_SHARE_CONTACT_PERMISSION:
            var index = state.shareContacts.findIndex(I => I.code === action.selectedItemId);
            if (index > -1) {
                return {
                    ...state,
                    ...state.shareContacts[index].permission = action.value
                }
            }
            else {
                return state
            }
        case shareContactActionTypes.CLEAR_SHARE_CONTACTS:
            return {
                ...state,
                shareContacts: [],
                updateResponse: null,
                error: null,
                fetching: true
            };
        default:
            return state;
    }
}
