export const con = {
	adminUrl: '/admin/api/v1',
	uploadUrl: '/api/v1',
	notificationUrl: '/notification/api/v1',
	ceremonyUrl: '/ceremony/api/v1',

	INSTITUTION_CODE: window.process.env.REACT_APP_INSTITUTION_CODE,
	PRODUCT_CODE: window.process.env.REACT_APP_PRODUCT_CODE,
	PRODUCT_TITLE: window.process.env.REACT_APP_PRODUCT_TITLE,
	CATEGORY_CODE : window.process.env.REACT_APP_CATEGORY_CODE,
	GA_ID_DEV: window.process.env.REACT_APP_GA_ID_DEV,
	GA_ID_QA: window.process.env.REACT_APP_GA_ID_QA,
	GA_ID_PROD: window.process.env.REACT_APP_GA_ID_PROD,
	PUBLIC_URL: window.process.env.REACT_APP_CDN_HOST,
	PRIVACY_POLICY: window.process.env.REACT_APP_PRIVACY_POLICY,
	ENVIRONMENT: window.process.env.REACT_APP_ENVIRONMENT,
	APP_FEATURES: window.process.env.REACT_APP_FEATURES,
	DIGITAL_HOST: window.process.env.REACT_APP_DIGITAL_HOST,
	REDIRECTION: window.process.env.REACT_APP_REDIRECTION
};