import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { GET_ITEMS_CURRENT, LOAD_CURRENT_ITEMS, CREATE_FOLDER_API_CREATE_REQUEST, FOLDER_API_UPDATE_ITEM }
    from '../../../../../redux/constants/personalVault/itemsConstants';
import { connect } from 'react-redux';
import { styles } from './moveItemStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularIndeterminate from '../../../../../shared/core/utils/CircularIndeterminate'
import FileIcon from '../../../../../shared/core/utils/FileTypeIcon';
import FolderIcon from '@material-ui/icons/Folder';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ProgressBar from '../../../../../shared/core/utils/CircularIndeterminate';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { grey } from "@material-ui/core/colors";
import Icon from '../../../../../shared/core/utils/icon';



type MoveItemProps =
    {
        open: boolean,
        handleModalClose: any,
        currentFolder: any,
        onActionComplete: any,
        selectedItems: any,
        fetchFiles: any,
        itemsFetching: any,
        currentItem: any,
        folderAPIfetching: any,
        createdFolderResponse: any,
        createdFolderResponseError: any,
        updateItemProgress: any,
        updateItemError: any,
        updateItemResponse: any,
        updateItemAPI: any,
        createFolderAPI: any,
        classes: any,
        itemList: any,
        children?: any
    }

class MoveItem extends Component<MoveItemProps> {

    state = {
        open: false,
        scroll: 'paper',
        currentFolder: {
            parentCode: null,
            itemCode: null,
            itemName: null
        },
        destinationCode: null,
        selectedItems: [{
            itemCode: null
        }],
        isbackButtonPressed: false,
        parentFolderCode: '',
        showCreateFolderTB: false,
        disableAddFolder: false,
        NewFolderName: '',
        errorMessage: null,
        updateItemErrMsg: null
    }

    //Life cycle hooks
    componentDidMount() {
        const { open, currentFolder, selectedItems } = this.props;
        this.setState({ open: open });
        let isParentCode = (null != currentFolder.ParentCode) ? true : false;
        this.props.fetchFiles(currentFolder.itemCode, isParentCode)
        //Read the current folder name
        if (currentFolder != null) {
            this.setState({
                currentFolder: currentFolder,
                destinationCode: currentFolder.itemCode,
                parentFolderCode: currentFolder.itemCode
            });
        }
        if (selectedItems != null) {
            this.setState({ selectedItems: selectedItems });
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { itemsFetching, currentItem, folderAPIfetching, createdFolderResponse, createdFolderResponseError,
            updateItemProgress, updateItemError, updateItemResponse } = this.props;
        // if (prevProps.itemsFetching !== itemsFetching && !itemsFetching) {
        if (prevProps.itemsFetching !== itemsFetching) {
            if (this.state.isbackButtonPressed && !itemsFetching) {
                if (null === currentItem.parentCode)
                    currentItem.itemName = "Home"
                //set the back button to default state
                this.setState({ isbackButtonPressed: false, currentFolder: currentItem })
            }
        }

        if (prevProps.folderAPIfetching !== folderAPIfetching) {
            if (!folderAPIfetching) {
                //If the create folder response is successful.
                if (createdFolderResponse != null) {
                    this.hideCreateFolderTB();
                    this.setState({ currentFolder: createdFolderResponse, destinationCode: createdFolderResponse.itemCode })
                    const { fetchFiles } = this.props;
                    if (null === createdFolderResponse.parentCode)
                        fetchFiles(createdFolderResponse.itemCode, true);
                    else
                        fetchFiles(createdFolderResponse.itemCode, false);
                } else {
                    if (createdFolderResponseError) {
                        const errMsg = createdFolderResponseError.data.errMsg
                        this.setState({ errorMessage: errMsg });
                    }
                }

            }
        }

        if (prevProps.updateItemProgress !== updateItemProgress || (prevProps.updateItemProgress && updateItemProgress)) {
            if (!updateItemProgress) {
                if (updateItemError) {
                    if (updateItemError.data) {
                        if (this.state.selectedItems.length > 1) {
                            this.props.handleModalClose();
                            this.props.onActionComplete(false, "Some items were not moved because items with same names exist in the destination folder.");
                        }
                        else {
                            const errMsg = updateItemError.data.errMsg;
                            this.setState({ updateItemErrMsg: errMsg })
                        }
                    }
                }
                else if (null != updateItemResponse) {
                    //Item moved successfully. close the dialog and show the confirmation dialog
                    //set the snack bar message for rename/move
                    this.props.handleModalClose();
                    this.props.onActionComplete(true, "Your item(s) has been successfully moved.");
                }
            }
        }

    }

    hideCreateFolderTB = () => {
        this.setState({ showCreateFolderTB: false, disableAddFolder: false, NewFolderName: '', errorMessage: null })
    }


    handleClose = () => {
        this.setState({ open: false })
        this.props.handleModalClose();
    };

    handleMove = () => {
        //Move the selected folder to destinaton folder
        this.state.selectedItems.forEach(
            (selectedFolder: any) => {
                this.props.updateItemAPI(selectedFolder.itemName, selectedFolder.itemCode, this.state.destinationCode);
            }
        )
    }

    onItemSelect = (itemCode: any) => {
        //Get the destination code
        this.setState({ destinationCode: itemCode })
    }

    onItemOpen = (item: any) => {
        this.setState({ currentFolder: item, destinationCode: item.itemCode })
        const { fetchFiles } = this.props;
        fetchFiles(item.itemCode, false);
    }

    handleBackButton = () => {
        const { fetchFiles } = this.props;
        if (!this.state.showCreateFolderTB)
            // If the parentCode exists update the item list
            if (this.state.currentFolder != null)
                this.setState({ isbackButtonPressed: true, destinationCode: this.state.currentFolder!.parentCode })
        fetchFiles(this.state.currentFolder!.parentCode, true);
        this.hideCreateFolderTB();
    }

    handleClickCreateFolder = (event: any) => {
        const { createFolderAPI } = this.props
        if (null != this.state.currentFolder && this.state.currentFolder!.itemCode != null)
            createFolderAPI(this.state.NewFolderName, this.state.currentFolder!.itemCode)
    }

    handleFolderNameChange = (event: any) => {
        this.setState({ NewFolderName: event.target.value, errorMessage: null })
    }

    showCreateFolderTB = () => {
        this.setState({ disableAddFolder: true, showCreateFolderTB: true, destinationCode: null })
    }

    //Rendering items
    renderProgressBar() {
        return <CircularIndeterminate></CircularIndeterminate>
    }

    render() {
        //Todo : Change the content based on mobile/desktop
        return (<div>
            {this.renderContent()}
        </div>
        );
    }

    renderList() {
        const { classes, itemList } = this.props;
        return <div>
            <List className={classes.itemlist}>
                {
                    itemList
                        .map((item: any, index: any) => {
                            const temp = this.state.selectedItems.find(x => x.itemCode === item.itemCode)
                            const folderTobeMovedCode = (temp != null) ? temp.itemCode : null
                            const isSelected = item.itemCode === this.state.destinationCode ? true : false;
                            const isFile = item.itemType === 'FILE' ? true : false;
                            const title = item.itemName.length > 50 ? item.itemName.substring(0, 51) + "..." : item.itemName
                            var iconType: any
                            if (item.itemType === 'FILE') {
                                iconType = <FileIcon className={classes.iconColor} size={25} type={item.mimeType} title={item.itemName} />
                            }
                            else if (item.itemType === 'FOLDER') {
                                iconType = <FolderIcon type={item.mimeType} />
                            }
                            return (
                                <React.Fragment key={`itemfrarment-${index}`}>
                                    <ListItem button key={`item-${index}`} alignItems="flex-start"
                                        className={classes.listitem}
                                        onClick={() => this.onItemSelect(item.itemCode)}
                                        onDoubleClick={() => this.onItemOpen(item)}
                                        selected={(item.itemCode != folderTobeMovedCode && isSelected && !isFile)}
                                        disabled={(item.itemCode === folderTobeMovedCode) || isFile}
                                    >
                                        <ListItemIcon style={{ marginTop: 0 }}>
                                            {iconType}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary=
                                            {
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={classes.iteminline}
                                                        color="textPrimary"
                                                        onClick={() => this.onItemOpen(item)}
                                                    >
                                                        {title}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                        {(item.itemType === 'FOLDER') ?
                                            < IconButton size="small"
                                                className={classes.folderIcon} onClick={() => this.onItemOpen(item)}>
                                                <ChevronRightIcon />
                                            </IconButton> : null
                                        }
                                    </ListItem>

                                    {index != itemList.length - 1 ? <Divider /> : null}
                                </React.Fragment>
                            )
                        })
                }
            </List>
        </div >
    }

    renderErrorMessage(classes: any, updateItemErrMsg: any) {
        return <div className={classes.errorMessage}>{updateItemErrMsg}</div>
    }

    renderContent() {
        const { classes, itemsFetching } = this.props;
        return (
            <div>
                <Dialog
                    className={classes.root}
                    open={this.state.open}
                    onClose={this.handleClose}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    style={{ padding: '0px 0px 0px 0px' }}
                >
                    <MuiDialogTitle disableTypography className={classes.itemstitle}>
                        {(this.state.currentFolder.parentCode != null || (this.state.currentFolder.parentCode === null && this.state.showCreateFolderTB)) ?
                            <IconButton disabled={itemsFetching} style={{ backgroundColor: 'transparent', paddingLeft: 0 }} color="primary" aria-label="back" onClick={this.handleBackButton}>
                                <ArrowBackIcon />
                            </IconButton> : null
                        }
                        {
                            (this.state.showCreateFolderTB) ?
                                <TextField
                                    error={this.state.errorMessage != null}
                                    autoFocus
                                    className={clsx(classes.margin, classes.textField)}
                                    label="Create a New Folder"
                                    id="createFolder"
                                    value={this.state.NewFolderName}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    helperText={this.state.errorMessage}
                                    onChange={this.handleFolderNameChange.bind(this)}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            position="end"
                                            className={classes.endAdornment}>
                                            <IconButton
                                                size="small"
                                                type="submit" aria-label="savefolder"
                                                onClick={this.handleClickCreateFolder.bind(this)}>
                                                <CheckIcon htmlColor={grey[50]} />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                                : <Typography variant="h6" className={classes.title}> {this.state.currentFolder.itemName}</Typography>
                        }
                        {(!this.state.showCreateFolderTB) ? <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton> : null}
                    </MuiDialogTitle>
                    <DialogContent className={classes.liststyle} style={{ padding: '0px 0px 0px 0px' }} dividers={this.state.scroll === 'paper'}>
                        {
                            (this.state.showCreateFolderTB) ?
                                <div className={classes.createFolderText}>
                                    Create a New Folder in {this.state.currentFolder.itemName}
                                </div>
                                :
                                itemsFetching ? this.renderProgressBar() : this.renderList()
                        }
                    </DialogContent>
                    <DialogContent style={{ padding: '3px 24px' }}>
                        {this.state.updateItemErrMsg ? this.renderErrorMessage(classes, this.state.updateItemErrMsg) : null}
                    </DialogContent>
                    <DialogActions className={classes.bottomActions}>
                        <IconButton disabled={this.state.disableAddFolder} aria-label="Add Folder" onClick={this.showCreateFolderTB.bind(this)}>
                            <Icon name="Add a Folder" stroke={(!this.state.disableAddFolder) ? '#000000' : '#808080'} />
                        </IconButton>
                        <Button disabled={itemsFetching || (this.state.destinationCode === this.state.parentFolderCode) || (this.state.destinationCode === null)} className={classes.buttonClass} onClick={() => this.handleMove()}>
                            MOVE HERE
                         </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        itemsFetching: state.itemsReducer.currentItemInfo.fetching,
        createdFolderResponse: state.itemsReducer.createdFolderInfo.response,
        createdFolderResponseError: state.itemsReducer.createdFolderInfo.error,
        folderAPIfetching: state.itemsReducer.createdFolderInfo.fetching,
        itemList: state.itemsReducer.currentItemInfo.itemList,
        currentItem: state.itemsReducer.currentItemInfo.currentItem,
        updateItemProgress: state.itemsReducer.updateItem.updating,
        updateItemResponse: state.itemsReducer.updateItem.response,
        updateItemError: state.itemsReducer.updateItem.error,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createFolderAPI: (folderName: any, currentFolderCode: any) => dispatch({ type: CREATE_FOLDER_API_CREATE_REQUEST, folderName, currentFolderCode }),
        fetchFiles: (folderCode: any, isParent: any) => dispatch({ type: GET_ITEMS_CURRENT, folderCode, isParent }),
        onMoveLoad: () => dispatch({ type: LOAD_CURRENT_ITEMS }),
        updateItemAPI: (itemName: any, itemCode: any, destinationCode: any, description: any) => dispatch({ type: FOLDER_API_UPDATE_ITEM, itemName, itemCode, destinationCode, description }),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MoveItem));
