import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

type waitingPageProps = {
    classes: any;
}

export const styles = (theme: any) => ({
    pagewrapper: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#505050',
        zIndex: 1300,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    sysMsg: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
    }
});

class WaitingPage extends Component<waitingPageProps> {

    componentDidMount() {
        if (window.location.search.includes('signing_complete')) {
            window.parent.postMessage("SUCCESS", window.parent.origin);
        }
        if (window.location.search.includes('decline')) {
            window.parent.postMessage("DECLINE", window.parent.origin);
        }
        if (window.location.search.includes('cancel')) {
            window.parent.postMessage("CANCEL", window.parent.origin);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div data-test='WaitingPage-component' className={classes.pagewrapper}>
                <div className={classes.sysMsg}>
                    Please wait. You will be redirected shortly.
                </div>
            </div>
        )
    }
}

export default (withStyles(styles)(WaitingPage));