import { combineReducers } from 'redux';
import * as UserActionTypes from '../../constants/customerVault/userConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    groupContacts: {
        fetching: true,
        lazyloading: false,
        contactsData: {
            content: [],
            page: {},
            sort: []
        },
        error: null
    },
    searchContacts: {
        fetching: false,
        error: null,
        contactsData: [],
        hasFound: false
    },
    selectedContact: {
        fetching: false,
        error: null,
        contact: null
    }
}

function groupContacts(state = initState.groupContacts, action) {
    switch (action.type) {

        // Get Contacts
        case UserActionTypes.USER_API_GET_USERS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case UserActionTypes.USER_API_GET_USERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                contactsData: { ...action.contacts.data }
            };
        case UserActionTypes.USER_API_GET_USERS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        // Handling Lazyloading
        case UserActionTypes.USER_API_LOAD_MORE_USERS_START:
            return {
                ...state,
                lazyloading: true
            };
        case UserActionTypes.USER_API_LOAD_MORE_USERS_REQUEST:
            return {
                ...state,
                lazyloading: true
            };
        case UserActionTypes.USER_API_LOAD_MORE_USERS_SUCCESS:
            return {
                ...state,
                lazyloading: false,
                contactsData: {
                    ...state.contactsData,
                    content: [
                        ...state.contactsData.content,
                        ...action.contacts.data.content
                    ],
                    page: action.contacts.data.page,
                    sort: action.contacts.data.sort,
                }
            };
        case UserActionTypes.USER_API_LOAD_MORE_USERS_FAILURE:
            return {
                ...state,
                lazyloading: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;
    }
}

function searchContacts(state = initState.searchContacts, action) {
    switch (action.type) {
        // Get Contacts
        case UserActionTypes.USER_API_CONTACT_SEARCH_REQUEST:
            return {
                ...state,
                fetching: true,
                hasFound: false
            };
        case UserActionTypes.USER_API_CONTACT_SEARCH_SUCCESS:
            if (action.responseInfo.data.content.length > 0) {
                return {
                    ...state,
                    fetching: false,
                    hasFound: true,
                    contactsData: [
                        ...action.responseInfo.data.content
                    ]
                };
            } else {
                return {
                    ...state,
                    fetching: false,
                    hasFound: false,
                    contactsData: [
                        ...action.responseInfo.data.content
                    ]
                };
            }

        case UserActionTypes.USER_API_CONTACT_SEARCH_FAILURE:
            return {
                ...state,
                fetching: false,
                hasFound: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        case UserActionTypes.USER_API_CLEAR_SEARCH_REQUEST:
            return {
                ...state,
                fetching: false,
                contactsData: [],
                error: null,
                hasFound: false
            }
        default:
            return state;
    }
}

function selectedContact(state = initState.selectedContact, action) {
    switch (action.type) {
        // Get Contacts
        case UserActionTypes.USER_API_GET_USER_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        //Get the details of selected contact
        case UserActionTypes.USER_API_GET_USER_SUCCESS:
            return {
                ...state,
                fetching: false,
                contact: { ...action.contact.data}
            };
        case UserActionTypes.USER_API_GET_USER_FAILURE:
            return {
                ...state,
                fetching: false,
                contact: null,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;
    }
}


export default combineReducers({
    groupContacts,
    searchContacts,
    selectedContact,
})