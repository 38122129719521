import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import Styles from '../css/warningStyles';


class Warnning extends React.Component {

    state = { open: this.props.open };

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }
    }

    handleAlertClose = (response) => {
        this.setState({ open: false })
        if (response === 'decline') this.props.declineCallback();
        this.props.closeCallback();
    }

    render() {
        const { classes, children } = this.props;
        return <Dialog
            open={this.state.open}
            data-test="warning"
            onClose={this.handleAlertClose}
            classes={
                {
                    paper: classes.alertRoot
                }
            }>
            <div className={classes.alertMain}>
                <DialogTitle className={classes.title} disableTypography >
                    You must agree to continue
                </DialogTitle>

                <DialogContent className={classes.content} style={{ marginTop: 16 }}>
                    <DialogContentText className={classes.contentText}>
                        {children}
                    </DialogContentText>
                </DialogContent>

                <DialogActions className={classes.actionRoot}>
                    <Fab variant='extended'
                        className={classes.button}
                        color='secondary'
                        onClick={() => this.handleAlertClose('decline')}>
                        Decline and Exit
                    </Fab>
                    <Fab variant='extended'
                        className={classes.button}
                        color='primary'
                        onClick={() => this.handleAlertClose('cancel')}
                    >
                        Close and Review
                    </Fab>
                </DialogActions>
            </div>
        </Dialog>
    }
}

export default withStyles(Styles)(Warnning);