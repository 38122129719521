import React from 'react';

// import { ReactComponent as AllFilesIcon } from '../../../assets/all-files.svg';
import { ReactComponent as NotificationIcon } from '../../../assets/notification.svg';
// import { ReactComponent as SharedIcon } from '../../../assets/share.svg';
// import { ReactComponent as ContactsIcon } from '../../../assets/contact.svg';
// import { ReactComponent as AddContactGroup } from '../../../assets/add-contact-group.svg';
// import { ReactComponent as InviteContact } from '../../../assets/add-contact.svg';
// import { ReactComponent as AddFolder } from '../../../assets/add-folder.svg';
// import { ReactComponent as UploadFile } from '../../../assets/file-upload.svg';
// import { ReactComponent as TopicsIcon } from '../../../assets/topics.svg';
import TopicsIcon from '../../../assets/Topics';

// Retired
import AllFilesIcon from '../../../assets/Remove/personal/Allfiles';
import SharedIcon from '../../../assets/Remove/personal/Shared';
import ContactsIcon from '../../../assets/Remove/personal/Contacts';
import CreatePassword from '../../../assets/Remove/personal/password';
import UploadFile from '../../../assets/Remove/personal/upload_file';
import AddFolder from '../../../assets/Remove/personal/add_folder';
import Note from '../../../assets/Remove/personal/Note';
import InviteContact from '../../../assets/Remove/personal/add_contact';
import AddContactGroup from '../../../assets/Remove/personal/add_contact_group';
import RecentIcon from '../../../assets/Remove/personal/Recent';
import { ReactComponent as MyPlansIcon } from '../../../assets/Remove/personal/Plans.svg';

const Icon = (props) => {
    switch (props.name) {
        case "My Files":
            return AllFilesIcon(props)
        case "My Plans":
            return <MyPlansIcon {...props} />;
        case "Notifications":
            return NotificationIcon(props);
        case "Recents":
            return RecentIcon(props);
        case "Shared":
            return SharedIcon(props)
        case "Contacts":
            return ContactsIcon(props);
        case "Topics":
            // return <TopicsIcon {...props} />;
            return TopicsIcon(props);
        case "Add a Folder":
            return AddFolder(props);
        case "Upload New Files":
            return UploadFile(props);
        case "Create a Note":
            return Note(props);
        case "Save a Password":
            return CreatePassword(props);
        case "Invite a Contact":
            return InviteContact(props)
        case "Create a Group":
            return AddContactGroup(props);
        default:
            return <div />;
    }
}




export default Icon;