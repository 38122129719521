import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, AppBar, CircularProgress, Paper, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Redirect } from 'react-router-dom';
import SignUp from './components/Signup/Signup';
import * as CognitoActionTypes from '../../../redux/constants/shared/cognitoLoginConstants';
import * as AppStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import UserType from '../../../shared/core/UserType';
import SignupConfirm from './components/Signup/SignupConfirm';
import Welcome from './components/Welcome'
import ResetPassword from './components/Reset/ResetPassword';
import ResetPasswordConfirm from './components/Reset/ResetPasswordConfirm';
import ResetPasswordSuccess from './components/Reset/ResetPasswordSuccess';
import Footer from '../../../shared/ui/loginfooter/footer';
import ReactGA from 'react-ga';
import { selectCognitoPageLogo } from '../../../shared/core/utils/functions';
import { con } from '../../../shared/config/config'; 

type LoginContentState = {
    passwordCodeEmail: string;
    signUpCode: string;
    loginCode: string;

}

type LoginContentProps = {
    classes: any;
    loggedIn: any;
    signin: any;
    signup: any;
    reset: any;
    signInAction: any;
    signInMFAACtion: any;
    SignupAction: any;
    SignupConfirmAction: any;
    SignupResendCodeAction: any;
    SignInResendCodeAction: any;
    ResetPasswordAction: any;
    ResetPasswordConfirmAction: any;
    ResetPasswordResendction: any;
    location: any;
    userDetails: any;
    CognitoLoginStateUpdate: any;
    CognitoLoginError: any;
    cognitoLogin: any;
    features: any;
    GAConfig: any;
    reactGaInitialised: any;
    intializeGAConfig: any;
}

enum loginState {
    signinState,
    mfaRequired,
    signup,
    signupConfirm,
    welcome,
    resetPassword,
    resetPasswordConfirm,
    resetPasswordSuccess,
    redirect,
    signupPrefilled,
    loginPrefilled
}

export const styles = (theme: any) => ({
    loginContent: {
        backgroundColor: theme.appbarbgcolor,
        width: "100%",
        display: 'flex',
        flex: '1',
        flexDirection: 'column' as 'column',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        overflowY: 'scroll' as 'scroll'
    },
    mainContainer:
    {
        display: 'flex',
        flexDirection: 'column' as 'column',
        height: '100vh',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
        overflowY: 'hidden' as 'hidden'
    },
    layout:
    {
        display: 'flex',
        flexDirection: 'column' as 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    header:
    {
        height: 64,
        alignItems: "center",
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    logoIcon: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imgIcon:
    {
        width: '200px'
    },
    loaderholder:
    {
        height: "100%",
        width: "100%",
        position: 'absolute' as 'absolute',
        backgroundColor: 'rgba(0,0,0,0.1)',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader:
    {
        position: 'absolute' as 'absolute',
        height: '120px',
        minWidth: '120px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',

    },
    loadingtext:
    {
        margin: theme.spacing(2),
        fontSize: '0.9rem'
    },
    footer:
    {
        height: 40,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'fixed' as 'fixed',
        width: '100%',
        bottom: 0
    },
    footerlink:
    {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
});

export class LoginContent extends Component<LoginContentProps, LoginContentState> {
    state = {
        passwordCodeEmail: '',
        unconfirmedStatus: 'UNCONFIRMED',
        signUpCode: '',
        loginCode: ''
    }

    initReactGA = (userId: any) => {
        const { configs } = this.props.GAConfig;
        this.props.intializeGAConfig(configs)
        // Send initial test view
        ReactGA.pageview('LoginPage');
    };

    componentDidUpdate(prevprops: any) {
        const { signin, signup, reset, loggedIn, userDetails } = this.props;
        //Only if the user is not Logged in
        if (loggedIn && loggedIn.user && loggedIn.exp > Date.now() / 1000) {
            // If the user already Logged in redirect them to login page
            //this.props.CognitoLoginStateUpdate(loginState.redirect);
            //this.props.CognitoLoginError(null);
            this.initReactGA(loggedIn.user_profile);
        }
        else {
            if (prevprops.signin.signinProgress != signin.signinProgress && !signin.signinProgress) {
                // Check if there are any errors
                //&& signin.error.data.message.length>0 && signin.error.data.message[0] == 'UserNotConfirmedException'
                if (signin.error) {
                    if (signin.error.status === 401 && signin.error.data.message != null && signin.error.data.message == 'UserNotConfirmedException')
                        this.props.CognitoLoginError('Your account has not been confirmed yet.');
                    else if (signin.error.status === 500)
                        this.props.CognitoLoginError('Something went wrong. Please try again later.');
                    else
                        this.props.CognitoLoginError('Invalid Username or Password');
                }
                else {
                    if (signin.mfaRequired) {
                        this.props.CognitoLoginStateUpdate(loginState.mfaRequired);
                        this.props.CognitoLoginError(null);
                    }
                    else {
                        this.props.CognitoLoginStateUpdate(loginState.redirect);
                        this.props.CognitoLoginError(null);
                    }
                }
            }
            if (prevprops.signin.mfaValidationProgress != signin.mfaValidationProgress && !signin.mfaValidationProgress) {
                // Check if there are any errors
                if (signin.error)
                    this.props.CognitoLoginError('Please enter a valid code.');
                else {
                    this.props.CognitoLoginError(null);
                    this.props.CognitoLoginStateUpdate(loginState.redirect);
                }
            }

            if (prevprops.signup.signupProgress != signup.signupProgress && !signup.signupProgress) {
                // Check if there are any errors
                if (signup.error) {
                    if (signup.error.response && signup.error.response.data && signup.error.response.data.message) {
                        if (signup.error.response.data.code != null && signup.error.response.data.code == "UserLambdaValidationException") {
                            this.props.CognitoLoginStateUpdate(null, '', '');
                            this.props.CognitoLoginError('Please try again.');
                        }
                        else {
                            this.props.CognitoLoginStateUpdate(null, '', '');
                            this.props.CognitoLoginError(signup.error.response.data.message);
                        }
                    }
                    else {
                        this.props.CognitoLoginStateUpdate(null, '', '');
                        this.props.CognitoLoginError('Please try again.');
                    }
                }
                else {
                    this.props.CognitoLoginStateUpdate(loginState.signupConfirm);
                    this.props.CognitoLoginError(null);
                }
            }

            if (prevprops.signup.signupVerifyProgress != signup.signupVerifyProgress && !signup.signupVerifyProgress) {
                // Check if there are any errors
                if (signup.error) {
                    if (signup.error.response && signup.error.response.data && signup.error.response.data.message) {
                        this.props.CognitoLoginError(signup.error.response.data.message);
                    }
                    else
                        this.props.CognitoLoginError('Code is incorrect.please try again.');
                }
                else {
                    this.props.CognitoLoginStateUpdate(loginState.welcome);
                    this.props.CognitoLoginError(null);
                }
            }
            if (prevprops.signup.signupResendCodeProgress != signup.signupResendCodeProgress && !signup.signupResendCodeProgress) {
                // Check if there are any errors
                if (signup.error) {
                    if (signup.error.response && signup.error.response.data && signup.error.response.data.message) {
                        this.props.CognitoLoginError(signup.error.response.data.message);
                    }
                    else
                        this.props.CognitoLoginError('Resend failed.please try again.');
                }
            }

            if (prevprops.reset.resetPasswordProgress != reset.resetPasswordProgress && !reset.resetPasswordProgress) {
                // Check if there are any errors
                if (reset.error)
                    if (reset.error.response && reset.error.response.data && reset.error.response.data.message)
                        this.props.CognitoLoginError(reset.error.response.data.message);
                    else
                        this.props.CognitoLoginError('Please enter a valid Email.')
                else {
                    this.props.CognitoLoginStateUpdate(loginState.resetPasswordConfirm);
                    this.props.CognitoLoginError(null);
                    this.setState({ passwordCodeEmail: reset.mfaDestination });
                }
            }
            if (prevprops.reset.resetPasswordVerifyProgress != reset.resetPasswordVerifyProgress && !reset.resetPasswordVerifyProgress) {
                // Check if there are any errors
                if (reset.error) {
                    if (reset.error.response && reset.error.response.data && reset.error.response.data.message)
                        this.props.CognitoLoginError(reset.error.response.data.message)
                    else
                        this.props.CognitoLoginError('Please enter a valid code.')
                }
                else {
                    this.props.CognitoLoginStateUpdate(loginState.resetPasswordSuccess);
                    this.props.CognitoLoginError(null);
                }
            }
            if (prevprops.reset.resetPasswordResendProgress != reset.resetPasswordResendProgress && !reset.resetPasswordResendProgress) {
                // Check if there are any errors
                if (reset.error) {
                    if (reset.error.response && reset.error.response.data && reset.error.response.data.message)
                        this.props.CognitoLoginError(reset.error.response.data.message)
                    else
                        this.props.CognitoLoginError('Resending Code failed.Please try again.')
                }
                else {
                    this.props.CognitoLoginError(null);
                }
            }

            if (prevprops.userDetails.getUserDetailsProgress != userDetails.getUserDetailsProgress && !userDetails.getUserDetailsProgress) {
                if (userDetails.error) {
                    this.props.CognitoLoginStateUpdate(loginState.signupPrefilled);
                    this.props.CognitoLoginError('Signup Code Invalid/Expired.')
                }
                else if (userDetails.getUserDetailsResponse.status === this.state.unconfirmedStatus) {
                    this.props.CognitoLoginStateUpdate(loginState.signupConfirm, userDetails.getUserDetailsResponse.email);
                    this.props.CognitoLoginError(null);
                    this.onSignupResendCode();
                }
                else if (this.state.signUpCode) {
                    this.props.CognitoLoginStateUpdate(loginState.signupPrefilled);
                    this.props.CognitoLoginError(null);
                }
                else if (this.state.loginCode) {
                    this.props.CognitoLoginStateUpdate(loginState.loginPrefilled);
                    this.props.CognitoLoginError(null);
                }
            }
        }
    }

    componentDidMount() {
        const { loggedIn } = this.props;
        if (loggedIn && loggedIn.user && loggedIn.exp > Date.now() / 1000) {
            this.props.CognitoLoginStateUpdate(loginState.redirect);
            this.props.CognitoLoginError(null);
        } else {
            if (this.props.cognitoLogin.state == loginState.signinState)
                this.props.CognitoLoginStateUpdate(loginState.redirect);
        }
    }
    getTextVerficationDescription() {
        if (this.props.signin.mfaMedium === 'CUSTOM_EMAIL') {
            return "We have sent an email to " + this.props.signin.mfaDestination + ". Please enter the code to sign in.";
        }
        return "We have sent a text to your mobile phone " + this.props.signin.mfaDestination + ". Please enter the code to sign in.";
    }

    getTextVerficationTitle() {
        if (this.props.signin.mfaMedium === 'CUSTOM_EMAIL') {
            return "Email verification requested";
        }
        return "Text verification requested";
    }


    LoadContent(state: loginState) {
        let content = null;
        const { classes, userDetails } = this.props;
        switch (state) {
            case loginState.mfaRequired:
                content =
                    <SignupConfirm data-test='signupConfirm'
                        title={this.getTextVerficationTitle()}
                        description={this.getTextVerficationDescription()}
                        onResend={this.onSignInResendCode}
                        errorMessage={this.props.cognitoLogin.errorMessage}
                        onSignupConfirm={this.onSignInMFA}
                        onCancel={this.onCancelSelect}
                        resendLinkName="Resend Code"
                        loading={this.props.signin.mfaValidationProgress}
                    />
                break;
            case loginState.signup:
                content =
                    <SignUp data-test='signup'
                        errorMessage={this.props.cognitoLogin.errorMessage}
                        onSignup={this.onSignup}
                        onCancel={this.onCancelSelect}
                        loading={this.props.signup.signupProgress}
                    />
                break;
            case loginState.signupPrefilled:

                if (this.props.cognitoLogin.errorMessage && this.props.cognitoLogin.errorMessage === 'Signup Code Invalid/Expired.') {
                    return <Redirect to='/error' />
                }
                content =
                    <SignUp data-test='signup'
                        errorMessage={this.props.cognitoLogin.errorMessage}
                        onSignup={this.onSignup}
                        onCancel={this.onCancelSelect}
                        loading={this.props.signup.signupProgress}
                        defaultUser={new UserType(userDetails.getUserDetailsResponse)}
                    />
                break;
            case loginState.signupConfirm:
                content =
                    <SignupConfirm data-test='signupConfirm'
                        title="Email Verification Requested"
                        description="You'll receive an email shortly with a verification code required to complete the signup."
                        onResend={this.onSignupResendCode}
                        errorMessage={this.props.cognitoLogin.errorMessage}
                        onSignupConfirm={this.onSignupConfirm}
                        onCancel={this.onCancelSelect}
                        resendLinkName="I did not receive an email"
                        loading={this.props.signup.signupVerifyProgress}
                        resendLoading={this.props.signup.signupResendCodeProgress}
                        defaultUser={new UserType(userDetails.getUserDetailsResponse)}
                    />
                break;
            case loginState.resetPassword:
                content =
                    <div className={classes.layout}>
                        <ResetPassword data-test='reset-password'
                            onResetSubmit={this.onResetPassword}
                            errorMessage={this.props.cognitoLogin.errorMessage}
                            onCancel={this.onCancelSelect}
                            loading={this.props.reset.resetPasswordProgress}
                        />
                    </div>
                break;
            case loginState.resetPasswordConfirm:
                content = <div className={classes.layout}>
                    <ResetPasswordConfirm data-test='reset-password-confirm'
                        onResetPasswordAnother={this.onResetPasswordAnother}
                        username={this.props.cognitoLogin.userName}
                        email={this.state.passwordCodeEmail}
                        onResetConfirm={this.onResetConfirmPassword}
                        errorMessage={this.props.cognitoLogin.errorMessage}
                        onCancel={this.onCancelSelect}
                        loading={this.props.reset.resetPasswordVerifyProgress}
                    /></div>
                break;
            case loginState.resetPasswordSuccess:
                content = <div className={classes.layout}>
                    <ResetPasswordSuccess data-test='reset-password-success'
                        onLogin={this.onVaultSelect}
                        username={this.props.cognitoLogin.userName}
                    /></div>
                break;
            case loginState.welcome:
                content =
                    <Welcome data-test='welcome'
                        onEnterVault={this.onVaultSelect}
                        title="Welcome"
                        description="Your account is successfully verified and ready to use."
                    />
                break;
            case loginState.redirect:
                window.location.href = "/login";
                break;
            default:
                break;

        }
        return content;
    }


    onSignup = (user: UserType) => {
        this.props.CognitoLoginError(null);
        if (user != null && user.username != null && user.password != null)
            this.props.CognitoLoginStateUpdate(null, user.username, user.password);
        this.props.SignupAction(user);
    }

    onSignupConfirm = (code: string, usercode: string, signUpCode: string) => {
        this.props.SignupConfirmAction(code, this.props.cognitoLogin.userName, usercode, signUpCode);
    }

    onSignupResendCode = () => {
        this.props.SignupResendCodeAction(this.props.cognitoLogin.userName);
    }

    onSignInResendCode = () => {
        let type = null;
        this.props.features.loginLayout.map(function (element: any) {
            let key = Object.keys(element)[0]
            if (key === 'fidsafe-login')
                type = element[key].type;
        });
        this.props.features.thirdPartyLoginLayout.map(function (item: any) {
            let key = Object.keys(item)[0]
            if (key === 'fidsafe-login')
                type = item[key].type;
        });
        if (type != null)
            this.props.signInAction(this.props.cognitoLogin.userName, this.props.cognitoLogin.password, type);
    }

    onSignInMFA = (code: string) => {
        const { signin } = this.props;
        let type = null;
        this.props.features.loginLayout.map(function (element: any) {
            let key = Object.keys(element)[0]
            if (key === 'fidsafe-login')
                type = element[key].type;
        });
        this.props.features.thirdPartyLoginLayout.map(function (item: any) {
            let key = Object.keys(item)[0]
            if (key === 'fidsafe-login')
                type = item[key].type;
        });
        if (type != null)
            this.props.signInMFAACtion(code, signin.mfaMedium + "_MFA", type);
    }

    onResetPassword = (username: string) => {
        if (username != null)
            this.props.CognitoLoginStateUpdate(null, username);
        this.props.ResetPasswordAction(username);
    }

    onResetPasswordAnother = () => {
        this.props.ResetPasswordResendction(this.props.cognitoLogin.userName);
    }

    onResetConfirmPassword = (code: string, password: string) => {
        this.props.ResetPasswordConfirmAction(this.props.cognitoLogin.userName, code, password);
    }



    onVaultSelect = () => {
        this.props.CognitoLoginStateUpdate(loginState.redirect);
        this.props.CognitoLoginError(null);
    }



    onCancelSelect = () => {
        this.props.CognitoLoginStateUpdate(loginState.redirect);
        this.props.CognitoLoginError(null);
    }

    determineLoading = () => {
        const { signin, signup, reset, userDetails } = this.props;
        return (signin.signinProgress ||
            signin.mfaValidationProgress ||
            signin.signinResendCodeProgress ||
            signup.signupProgress ||
            signup.signupVerifyProgress ||
            signup.signupResendCodeProgress ||
            reset.resetPasswordProgress ||
            reset.resetPasswordResendProgress ||
            reset.resetPasswordVerifyProgress ||
            userDetails.getUserDetailsProgress);
    }

    getLoadingText = () => {
        const { signin, signup, reset } = this.props;
        if (signin.signinResendCodeProgress ||
            signup.signupResendCodeProgress ||
            reset.resetPasswordResendProgress)
            return "Resending the code..."
        else
            return "Loading"
    }

    render() {
        const { classes } = this.props;
        return (
            <div data-test='loginContentComponent' className={classes.mainContainer}>
                <div className={classes.header}>
                    <AppBar position="relative" style={{ height: 'inherit' }}>
                        <div className={classes.logoIcon}>
                            {selectCognitoPageLogo(con.PRODUCT_CODE)}
                        </div>
                    </AppBar>
                </div>
                <div className={classes.loginContent}>
                    <Divider variant="middle" />
                    {this.determineLoading() ? this.renderProgressBar() : null}
                    {this.LoadContent(this.props.cognitoLogin.state)}
                    <Footer />
                </div>
            </div>
        )
    }
    renderProgressBar() {
        const { classes } = this.props;
        return <div className={classes.loaderholder} >
            <Paper elevation={3} className={classes.loader}>
                <div className={classes.loadingtext}>{this.getLoadingText()}</div>
                <CircularProgress />
            </Paper>
        </div>
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.login,
        signin: state.cognitoLoginReducer.signin,
        signup: state.cognitoLoginReducer.signup,
        reset: state.cognitoLoginReducer.reset,
        userDetails: state.userDetailsReducer.userDetails,
        cognitoLogin: state.appUIStateReducer.cognitoLogin,
        features: state.homeReducer.features,
        GAConfig: state.appUIStateReducer.GAConfig,
        reactGaInitialised: state.appUIStateReducer.reactGaInitialised
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        intializeGAConfig: () => dispatch({ type: AppStateActionTypes.UPDATE_GACONFIG }),
        //dispatch a Login_request(/current user)
        signInAction: (username: any, password: any, link: any) => dispatch({
            type: CognitoActionTypes.COGNITO_API_LOGIN_REQUEST,
            username: username,
            password: password,
            endpoint: `/auth-login/${link}`
        }),
        signInMFAACtion: (phoneCode: any, mfaType: any, link: any) => dispatch({
            type: CognitoActionTypes.COGNITO_API_LOGIN_MFA_REQUEST,
            phoneCode: phoneCode,
            mfaType: mfaType,
            endpoint: `/auth-login/${link}`
        }),
        SignupAction: (user: any) => dispatch({
            type: CognitoActionTypes.COGNITO_API_SIGNUP_REQUEST,
            user: user
        }),
        SignupConfirmAction: (code: string, username: string, userCode: string, signUpCode: string) =>
            dispatch({
                type: CognitoActionTypes.COGNITO_API_SIGNUP_VERIFY_REQUEST,
                code: code,
                username: username,
                signUpCode: signUpCode,
                userCode: userCode
            }),
        SignupResendCodeAction: (username: string) =>
            dispatch({
                type: CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE,
                username: username
            }),
        SignInResendCodeAction: (username: string) =>
            dispatch({
                type: CognitoActionTypes.COGNITO_API_SIGNIN_RESEND_CODE,
                username: username
            }),
        ResetPasswordAction: (username: any) => dispatch({
            type: CognitoActionTypes.COGNITO_API_RESET_PASSWORD,
            username: username
        }),
        ResetPasswordResendction: (username: any) => dispatch({
            type: CognitoActionTypes.COGNITO_API_RESET_RESEND_PASSWORD,
            username: username
        }),
        ResetPasswordConfirmAction: (username: string, code: string, password: string) =>
            dispatch({
                type: CognitoActionTypes.COGNITO_API_RESET_CONFIRM_PASSWORD,
                username: username,
                code: code,
                password: password
            }),
        CognitoLoginStateUpdate: (state?: any, userName?: any, password?: null) => dispatch({
            type: AppStateActionTypes.UPDATE_COGNITO_LOGIN_STATE,
            state: state,
            userName: userName,
            password: password
        }),
        CognitoLoginError: (errorMessage: any) => dispatch({
            type: AppStateActionTypes.UPDATE_COGNITO_ERROR,
            errorMessage: errorMessage
        })
    }
}

export const LoginContentComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoginContent));

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoginContent)));