import React from 'react';
import { content_type, GroupContent, TableData, TableDataCell } from "../../shared/contactListTypes";
import { ItemList } from '../../../../../shared/ui';
import { connect } from 'react-redux';

interface PaginationType {
  page: any;
  size: any;
  direction: string;
  orderBy: string;
}

const NO_GROUP_MSG = 'You are yet to add any groups. To start, click on Add New Group'

const groupsDataProc = ( data: GroupContent[] ) => {
    let filteredGroups: TableData[] = [];
  
    data.forEach((el) => {
      const cell1:TableDataCell = {
        content: {
          s1: {
            type: content_type.LINK,
            content: {
              data: el.cgName,
              link: `/personal/contacts-groups-detail/${el.cgCode}`
            },
            bold: true
          },
          s2: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s3: {
            type: content_type.DATE,
            content: el.createdAt,
            bold: false
          },
          s4: {
            type: content_type.TEXT,
            content: '',
            bold: false
          }
        },
        resizeShown: true
      }

      const cell2:TableDataCell = {
        content: {
          s1: {
            type: content_type.DATE,
            content: el.createdAt,
            bold: false
          },
          s2: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s3: {
            type: content_type.TEXT,
            content: '',
            bold: false
          },
          s4: {
            type: content_type.TEXT,
            content: '',
            bold: false
          }
        },
        resizeShown: false
      }
  
      filteredGroups.push({
        id: el.cgCode,
        cells: [
          cell1,
          cell2
        ]
      })
    })
    return filteredGroups;
}

const itemActionMenuProc = ( data: any ) => {
  let action_menu : any[] = [];
  data.forEach( (el:any) => {
    action_menu.push({
      id: el.cgCode,
      menu: [
        {
          id: 'removeGroup',
          label: 'Remove Group'
        },
        {
          id: 'viewDetail',
          label: 'View Details'
        }
      ]
    })
  })
  return action_menu;
}
  
const GroupList = (props: any) => {
    const columns = [
      {
          id: 'NAME',
          label: 'Name',
          sortable: true,
          mustShow: true,
      },{
          id: 'CREATED_AT',
          label: 'Added',
          sortable: true,
          mustShow: false,
      }
    ];

    const selectable = false;

    const batchAction = {
        enable: false,
        menu: []
    }

    const itemAction = {
        enable: true,
        menu: itemActionMenuProc( props.data )
    }

    const pagination = {
        pageSize: 5,
        pageNumber: 0
    }

    const rowOnClick = () => {
      console.log('rowOnClick!')
    }

    if( props.data.length > 0 ) {
      return (
        <ItemList data={ groupsDataProc(props.data) } 
                  columns={ columns } 
                  selectable={ selectable } 
                  rowOnClick={rowOnClick}
                  batchAction={ batchAction } 
                  itemAction={ itemAction }
                  pagination= { pagination }
                  itemOnClick={ props.itemOnClick }
                  sortingOnClick={ props.sortingOnClick }
                  tableMarginHeight = { props.tableMarginHeight } />
        );
    } else {
      return ( <p style={{'padding': '1em', 'margin': 0}}>{ NO_GROUP_MSG }</p> )
    }
}

const mapStateToProps = (state: any) => {
  return {
      contactPageData: state.contactsReducer,
      contactsData: state.contactsReducer.contacts,
      groupsData: state.contactsReducer.groups,
      contactTabIndex: state.appUIStateReducer.contactTabIndex,
  }; 
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      // getGroupContacts: (pagination: PaginationType) => dispatch({
      //     type: ContactActionTypes.CONTACT_API_GET_CONTACTS_REQUEST,
      //     pagination: pagination
      // }),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(GroupList);