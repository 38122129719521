import React, { Component } from 'react';
import { Paper, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

type LoadingProps = {
    classes: any,
    loadingText: any;
}

export const styles = (theme: any) => ({
    loaderholder:
    {
        top: "50%",
        left: "50%",
        position: 'fixed' as 'fixed',
        backgroundColor: 'rgba(0,0,0,0.1)',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader:
    {
        position: 'absolute' as 'absolute',
        height: '120px',
        minWidth: '120px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',

    },
    loadingtext:
    {
        margin: theme.spacing(2),
        fontSize: '0.9rem'
    }

});

class Loading extends Component<LoadingProps> {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.loaderholder} data-test='loadingComponent'>
                <Paper elevation={3} className={classes.loader}>
                    <div className={classes.loadingtext}>{this.props.loadingText}</div>
                    <CircularProgress />
                </Paper>
            </div>)
    }
}

export { Loading };
export default (withStyles(styles)(Loading));