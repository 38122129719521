import React, { Component } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { base_path } from './shared/config/basePath'
import Login from './pages/shared/loginuser';
import InviteVerification from './pages/shared/inviteVerification/InviteVerification';
import LoginContent from './pages/shared/cognito/LoginContent';
import ProtectedRoute from './shared/core/ProtectedRoute';
import { LOGIN_REQUESTING } from './redux/constants/shared/loginUserConstants';
import { FETCH_THEME_S3_REQUEST } from './redux/constants/shared/themeConstants';
import { FETCH_FEATURE_REQUEST } from './redux/constants/shared/featureConstant';
import { INIT_DRAWER } from './redux/constants/shared/appStatesConstants';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Error from './shared/errors/Error';
import UniversalHome from './pages';
import { con } from './shared/config/config';
import WaitingPage from './pages/shared/fileDetail/components/waitingPage/WaitingPage';

class App extends Component {
  componentDidMount() {
    const { loadtheme, loadfeature, callAuthCheck, updateDrawerMenu } = this.props;
    this.setAppTitle();
    loadtheme();
    loadfeature();
    callAuthCheck();
    updateDrawerMenu('cv');
  }
  
  setAppTitle() {
    if(con.PRODUCT_TITLE){
      let envTitle;
      if(con.ENVIRONMENT === 'QA' || con.ENVIRONMENT === 'QABLUE'){
        envTitle = 'QA';
      }
      if(con.ENVIRONMENT === 'DEV' || con.ENVIRONMENT === 'LOCAL'){
        envTitle = 'DEV';
      }
      document.title = (!!envTitle) ? `${con.PRODUCT_TITLE} - Vault (${envTitle})` : `${con.PRODUCT_TITLE} - Vault`;
    }
  }

  render() {
    const { themeFetching, themeError } = this.props;
    if (themeError)
      return (
        this.renderError()
      )

    return (
      themeFetching ? this.renderNothing() : this.renderContent()
    );
  }

  renderError() {
    const { themeError } = this.props;
    return (
      <Error
        error={themeError}
      />
    )
  }
  renderNothing() {
    return (
      <div></div>
    )
  }

  renderContent() {
    let thirdPartyPaths = [];
    const thirdPartyLoginLayoutFeatures = this.props.features.thirdPartyLoginLayout;
    if (thirdPartyLoginLayoutFeatures && thirdPartyLoginLayoutFeatures.length) {
      thirdPartyLoginLayoutFeatures.forEach(element => {
        const loginUrls = Object.values(element)[0]['login-urls'];
        loginUrls && thirdPartyPaths.push(...loginUrls);
      });
    }
    const themeResponse = this.props.themeResponse;
    themeResponse.palette = {
      primary: {
        main: this.props.themeResponse.gridheaderbgcolor,
      },
      secondary: {
        main: this.props.themeResponse.appbarbgcolor
      }
    }
    const theme = createMuiTheme(
      themeResponse
    )

    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/customer" />} ></Route>
          <Route exact path="/verification" render={() => <InviteVerification />} />
          <Route exact path="/invite" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Login} />
          <Route exact path="/userauth/userlogin" component={LoginContent} />
          <Route exact path="/advisor/login" render={() => <Login discriminator="advisor" />} />
          <Route exact path="/proxy/login" render={() => <Login discriminator="proxy" />} />
          <Route exact path="/error" render={() => <Login unauthorized={true} />} />
          <Route exact path={`/waiting(/.*)?`} render={() => <WaitingPage/>} />
          {thirdPartyPaths.map((thirdPartyPath) => (
            <Route exact path={`${thirdPartyPath}`} render={() => <Login discriminator="thirdparty" />} />
          ))}
          {/* <Route exact path={`${thirdPartyPath}`} render={() => <Login discriminator="thirdparty" />} /> */}
          <ProtectedRoute path={`/customer(/.*)?`} render={() => <UniversalHome> </UniversalHome>} />
          <ProtectedRoute exact path={`${base_path}`} render={() => <UniversalHome> </UniversalHome>} />
        </Switch>
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.appReducer.fetching,
    error: state.appReducer.error,
    themeFetching: state.themeReducer.themeFile.fetching,
    themeResponse: state.themeReducer.themeFile.content,
    themeError: state.themeReducer.themeFile.error,
    loggedIn: state.login,
    features: state.homeReducer.features,
    productInfo: state.homeReducer.productInfo.response,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    callAuthCheck: () => dispatch({ type: LOGIN_REQUESTING }),
    loadtheme: () => dispatch({ type: FETCH_THEME_S3_REQUEST }),
    loadfeature: () => dispatch({ type: FETCH_FEATURE_REQUEST }),
    updateDrawerMenu: (product) => dispatch({ type: INIT_DRAWER, product: product })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
