import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import fileIcon from '../../../assets/file-grey.svg';
import folderIcon from '../../../assets/folder-black.svg';
import formIcon from '../../../assets/smart-form-icon.svg';
import { Button } from '@material-ui/core';
import { ReactComponent as HorizontalMenu } from '../../../assets/horizontal-menu.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loading from '../loading/Loading';
import { ReactComponent as AlertAttention } from '../../../assets/alert-attention.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { red } from "@material-ui/core/colors";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { ItemCeremonyStatus } from '../../../shared/core/CeremonyStatus';
import { formattedDateTime } from '../../core/utils/functions';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        container: {
            //  maxHeight: 400
        },
        customTable: {
            "& .MuiTableCell-alignRight": {
                width: 100,
                paddingRight: '16px !important'
            },
            "& .MuiTableCell-root": {
                padding: "0px 0px 0px 6px"
            },
        },

        table: {
            minWidth: 768,
        },
        tableBody: {
            overflow: 'auto',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHeadRow: {
            backgroundColor: '#FAFAFA',
        },
        checkboxCell: {
            borderBottom: '1px solid #e8e8e8',
        },
        headerWrapper: {
            display: 'flex',
            justifyContent: 'flex-start'
        },
        headerTitle: {
            cursor: 'pointer',
            paddingLeft: '18px'
        },
        headerLabel: {
            cursor: 'pointer'
        },
        sortLabel: {
            width: '25px'
        },
        headWithIcon: {
            color: 'rgba(81, 81, 81, 0.87)',
            paddingLeft: '40px',
            fontSize: '.785em',
            fontWeight: 'bold',
        },
        headWithoutIcon: {
            color: 'rgba(81, 81, 81, 0.87)',
            paddingLeft: '16px',
            fontSize: '.785em',
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
        },
        hiddenCol: {
            display: 'none'
        },
        shownCol: {
            display: 'flex'
        },
        itemListRow: {
            height: '53px',
            borderBottom: '1px solid #e8e8e8',
            borderCollapse: 'collapse',
            '&.Mui-selected': {
                backgroundColor: '#FAFAFA'
            }
        },
        filterBtnCell: {
            padding: '0 .5rem',
            width: '40px'
        },
        filterMenuBtn: {
            width: '20px',
            height: '20px',
        },
        filterMenuItem: {
            paddingLeft: '10px',
            '&:hover': {
                color: '#000',
                backgroundColor: 'rgba(220,220,220,1)',
                cursor: 'pointer'
            }
        },
        typeIcon: {
            // marginRight: '1rem'
        },
        avatarCell: {
            // borderBottom: 'none',
            // minHeight: '73px'
        },
        avatarCellContentWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        itemListCell: {
            // borderBottom: 'none',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 100,
            fontSize: '.85rem'
        },
        momentListCell: {
            borderBottom: 'none',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddngRight: '0 !important',
        },
        momentCell: {
            fontWeight: 200,
            fontSize: '.875rem',
            color: 'rbga(0, 0, 0, 0.54)',
            margin: 0,
            minWidth: '200px'
        },
        actionCell: {
            borderBottom: 'none',
            color: 'rbga(0, 0, 0, 0.54)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddingRight: 0,
        },
        fileSizeCell: {
            borderBottom: 'none',
            color: 'rbga(0, 0, 0, 0.54)',
            fontWeight: 100,
            fontSize: '.875rem',
            paddingRight: 0,
            width: 90
        },
        ownerCell: {
            width: 220,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        fileTitle: {
            width: '18.75rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 700,
            fontSize: '.875rem',
            margin: 0,
            color: 'rgba(0, 0, 0, 0.72)',
            cursor: 'pointer',
            '&:hover': {
                color: '#0E67A9',
            }
        },
        permissionBtn: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem'
        },
        permissionStatic: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem'
        },
        progressbar: {
            height: '4px'
        },
        loadingWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '36px'
        },
        loadingContext: {
            color: '#8a8a8a',
            fontWeight: 700,
            fontSize: '11px',
            marginLeft: '10px'
        },
        iconBtn: {
            width: '20px',
            height: '20px',
        },
        iconholder:
        {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.8rem'
        }
    }),
);

interface Data {
    fileName: string;
    modifiedAt: string;
    shared: string;
    permission: string;
    createdAt: string;
    size: string;
    owner: string;
}

type Order = 'asc' | 'desc';

interface fileType {
    itemCode: string,
    itemName: string,
    itemType: string,
    size: number,
    mimeType: string,
    title: string,
    createdAt: string,
    modifiedAt: string,
    owner: OwnerType,
    status: string,
    permissionName: string
}

interface OwnerType {
    grpName: string,
    grpCode: string,
    userCode: string,
    userFirstName: string,
    userLastName: string
}

type CommonFileItemListProps = {
    files: fileType[],
    onSorting: any,
    allowChangePermission: boolean,
    onSelection?: any,
    showOwner: boolean,
    tableMarginHeight: number,
    showPermission: boolean,
    showUploaded: boolean,
    showMeAsOnwer: boolean,
    showFileSize: boolean,
    onFileOpen: any,
    onFolderOpen: any,
    filesFetching: boolean,
    fetchMoreData?: any,
    lazyLoading?: boolean,
    noMoreData?: boolean,
    allFetched?: boolean,
    resetSelection?: boolean,
    curSorting?: any,
    onSmartFormOpen?: any,
    esignStatusOpen?: any,
    fileListHeaders: string[],
    onFiltering: any
}

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    rowCount: number;
    files: fileType[];
    allowChangePermission: boolean;
    onSelectFilters: any;
    showOwner: boolean;
    sortOnClick: any;
    showPermission: boolean;
    showUploaded: boolean;
    showMeAsOnwer: boolean;
    showFileSize: boolean;
    curSorting?: any;
    fileListHeaders: string[];
    onFiltering: any;
}

interface HeadCell {
    id: keyof Data;
    label: string;
    filterable: boolean;
    withIcon: boolean;
    paddingRight: number;
    sortable: boolean;
}

let headCells: HeadCell[] = [
    { id: 'fileName', label: 'File Name', withIcon: true, paddingRight: 16, filterable: false, sortable: true },
    { id: 'createdAt', label: 'Created', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'modifiedAt', label: 'Modified', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'shared', label: 'Shared', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'permission', label: 'Permission', withIcon: false, paddingRight: 34, filterable: true, sortable: false },
    { id: 'size', label: 'File Size', withIcon: false, paddingRight: 16, filterable: true, sortable: false },
    { id: 'owner', label: 'Owner', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
];

let headCellsNoShared: HeadCell[] = [
    { id: 'fileName', label: 'File Name', withIcon: true, paddingRight: 16, filterable: false, sortable: true },
    { id: 'modifiedAt', label: 'Modified', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'createdAt', label: 'Created', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'permission', label: 'Permission', withIcon: false, paddingRight: 34, filterable: true, sortable: false },
];

let headCellsNoPermission: HeadCell[] = [
    { id: 'fileName', label: 'File Name', withIcon: true, paddingRight: 16, filterable: false, sortable: true },
    { id: 'modifiedAt', label: 'Modified', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'shared', label: 'Shared', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
];

let headCellsFileSize: HeadCell[] = [
    { id: 'fileName', label: 'File Name', withIcon: true, paddingRight: 16, filterable: false, sortable: true },
    { id: 'modifiedAt', label: 'Last Updated', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'size', label: 'File Size', withIcon: false, paddingRight: 16, filterable: true, sortable: false },
];

let headCellsTopicsFiles: HeadCell[] = [
    { id: 'fileName', label: 'File Name', withIcon: true, paddingRight: 16, filterable: false, sortable: true },
    { id: 'modifiedAt', label: 'Modified', withIcon: false, paddingRight: 16, filterable: false, sortable: true },
    { id: 'createdAt', label: 'Uploaded', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
    { id: 'size', label: 'File Size', withIcon: false, paddingRight: 16, filterable: true, sortable: false },
    { id: 'shared', label: 'Owner', withIcon: false, paddingRight: 16, filterable: true, sortable: true },
];

let headCellsArr = ['fileName', 'modifiedAt', 'createdAt', 'shared', 'size', 'permission'];

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { classes, onSelectAllClick, numSelected, rowCount, onSelectFilters, sortOnClick, showPermission, showUploaded, showFileSize, fileListHeaders } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterHeads, setFilterHeads] = useState<string[]>(fileListHeaders);
    const [curSortBy, setCurSortBy] = useState<string>('MODIFIED_AT');
    const [fileSortBy, setFileSortBy] = useState<boolean>(true);
    const [modifiedSortBy, setModifiedSortBy] = useState<boolean>(true);
    const [createdSortBy, setCreatedSortBy] = useState<boolean>(true);

    useEffect(() => {
    }, [filterHeads])

    const filterMenusProc = () => {
        let filterable_menus: string[] = [];
        // headCells.filter((col: HeadCell) => {
        //     return col.filterable
        // }).forEach(el => filterable_menus.push(el.id));
        filterable_menus = props.fileListHeaders;
        return filterable_menus;
    }

    const [selected, setSelected] = useState<string[]>(filterMenusProc());

    const isSelected = (name: string) => {
        if (selected.indexOf(name) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    const filterMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const filterMenuOnClose = () => {
        setAnchorEl(null);
    };

    const filterItemOnClick = (event: React.MouseEvent<unknown>, name: string) => {
        let itemIndex = filterHeads.indexOf(name);
        let copy_filterHeads: string[] = [];
        filterHeads.forEach(el => {
            copy_filterHeads.push(el)
        })

        if (itemIndex > -1) {
            copy_filterHeads.splice(itemIndex, 1)
        } else {
            copy_filterHeads.push(name)
        }

        setFilterHeads(copy_filterHeads);

        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        onSelectFilters(copy_filterHeads);

        props.onFiltering(copy_filterHeads);
    };

    const sortingOnClick = (name: string) => {
        switch (name) {
            case 'fileName':
                setCurSortBy('NAME');
                setFileSortBy(!fileSortBy);
                break;
            case 'modifiedAt':
                setModifiedSortBy(!modifiedSortBy);
                setCurSortBy('MODIFIED_AT');
                break;
            case 'createdAt':
                setCreatedSortBy(!createdSortBy);
                setCurSortBy('CREATED_AT');
                break;
        }
        props.sortOnClick(name);
    }

    return (
        <TableHead>
            <TableRow className={classes.tableHeadRow}>
                <TableCell padding="checkbox">
                    <Checkbox color='primary'
                        data-test= "select-all-files"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all files' }}
                    />
                </TableCell>
                <TableCell className={`${classes.headWithIcon}`} id="fileName">
                    <div className={classes.headerWrapper}>
                        <div className={classes.headerTitle} onClick={() => sortingOnClick('fileName')}>File Name</div>
                        <TableSortLabel className={classes.sortLabel} active={props.curSorting.sort[0].property === 'itm_name'}
                            onClick={() => sortingOnClick('fileName')}
                            direction={props.curSorting.sort[0].direction === 'DESC' ? 'desc' : 'asc'}
                            title={props.curSorting.sort[0].direction === 'DESC' ? 'Descending' : 'Ascending'}
                            data-test="sort-on-fileName"
                        >
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('modifiedAt') > -1 ? null : classes.hiddenCol}`} id="modifiedAt">
                    <div className={classes.headerWrapper}>
                        <div className={classes.headerLabel} onClick={() => sortingOnClick('modifiedAt')}>Modified</div>
                        <TableSortLabel className={classes.sortLabel} active={props.curSorting.sort[0].property === 'modified'}
                            onClick={() => sortingOnClick('modifiedAt')}
                            direction={props.curSorting.sort[0].direction === 'DESC' ? 'desc' : 'asc'}
                            title={props.curSorting.sort[0].direction === 'DESC' ? 'Descending' : 'Ascending'}
                            data-test="sort-on-modifiedAt"
                        >
                        </TableSortLabel>
                    </div>
                </TableCell>
                {showUploaded &&
                    <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('createdAt') > -1 ? null : classes.hiddenCol}`} id="createdAt">
                        <div className={classes.headerWrapper}>
                            <div className={classes.headerLabel} onClick={() => sortingOnClick('createdAt')}>Uploaded</div>
                            <TableSortLabel className={classes.sortLabel} active={props.curSorting.sort[0].property === 'created'}
                                onClick={() => sortingOnClick('createdAt')}
                                direction={props.curSorting.sort[0].direction === 'DESC' ? 'desc' : 'asc'}
                                title={props.curSorting.sort[0].direction === 'DESC' ? 'Descending' : 'Ascending'}
                                data-test="sort-on-createdAt"
                            >
                            </TableSortLabel>
                        </div>
                    </TableCell>
                }
                {showFileSize &&
                    <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('size') > -1 ? null : classes.hiddenCol}`} id="fileSize" align="right">
                        File Size
                        {/* <TableSortLabel
                            onClick={() => sortingOnClick('size')}
                            direction={createdSortBy ? 'desc' : 'asc'}>
                        </TableSortLabel> */}
                    </TableCell>
                }
                {props.showOwner &&
                    <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('shared') > -1 ? null : classes.hiddenCol}`} id="shared">
                        {showFileSize ? 'Owner' : 'Shared'}
                    </TableCell>
                }
                {props.showPermission &&
                    <TableCell className={`${classes.headWithoutIcon} ${filterHeads.indexOf('permission') > -1 ? null : classes.hiddenCol}`} id="permission">
                        Permission
                    </TableCell>
                }
                <TableCell className={classes.filterBtnCell}>
                    <IconButton aria-label="Filter" onClick={filterMenuOnClick} data-test="filter-menu-btn">
                        <HorizontalMenu className={classes.filterMenuBtn} />
                    </IconButton>
                    <Menu id="filter-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        data-test="filter-menu"
                        onClose={filterMenuOnClose}>
                        {props.showOwner && props.showPermission && headCells.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} className={classes.filterMenuItem} onClick={event => filterItemOnClick(event, col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                        {props.showOwner && props.showFileSize && headCellsTopicsFiles.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} className={classes.filterMenuItem} onClick={event => filterItemOnClick(event, col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                        {!props.showOwner && props.showPermission && headCellsNoShared.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} className={classes.filterMenuItem} onClick={event => filterItemOnClick(event, col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                        {props.showOwner && !props.showFileSize && headCellsNoPermission.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} className={classes.filterMenuItem} onClick={event => filterItemOnClick(event, col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                        {props.showFileSize && !props.showOwner && headCellsFileSize.filter((col: HeadCell) => {
                            return col.filterable
                        }).map((col: HeadCell) => {
                            const isItemSelected = isSelected(col.id);
                            return (
                                <MenuItem key={col.id} className={classes.filterMenuItem} onClick={event => filterItemOnClick(event, col.id)}>
                                    <Checkbox color="primary"
                                        checked={isItemSelected}
                                        value="primary"
                                        inputProps={{ 'aria-label': `${col.label} checkbox` }}
                                    />
                                    {col.label}
                                </MenuItem>
                            )
                        })
                        }
                    </Menu>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const CommonFileItemList = (props: CommonFileItemListProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string[]>([]);
    const [permission, setPermission] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filters, setFilters] = useState<string[]>(props.fileListHeaders);
    const tableContainer: any = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.resetSelection) {
            setSelected([])
            props.onSelection([]);
        }
    }, [filters, props.resetSelection])

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = props.files.map(n => n.itemCode);
            setSelected(newSelecteds);
            props.onSelection(newSelecteds);
        }
        else {
            setSelected([]);
            props.onSelection([])
        }
    };

    const itemOnClick = (event: React.MouseEvent<unknown>, name: string, type: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        props.onSelection(newSelected);
    };

    const itemOnOpen = (event: React.MouseEvent<unknown>, itemCode: string, type: string, file: any) => {
        if (type === 'FILE') {
            props.onFileOpen(file)
        } else if (type === 'FORM') {
            event.stopPropagation();
            props.onSmartFormOpen(file);
        }
        else {
            event.stopPropagation();
            props.onFolderOpen(file)
        }
        setSelected([]);
    }

    const changePermission = (permission: string) => {
        setPermission(permission);
        handleClose();
    };   
    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectFilters = (updatedFilters: any) => {
        setFilters(updatedFilters);
    }

    const sizeConverter = (size: number) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '-';
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return Math.round(size / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    if (!props.files) {
        return null;
    }

    const getOwner = (item: any) => {
        if (item.owner.isGroupOwner) {
            if (item.owner.userCode === "sysops") {
                const name = (item.owner.userFirstName === undefined ? '' : item.owner.userFirstName) + ' '
                    + (item.owner.userMiddleName === undefined ? '' : item.owner.userMiddleName) + ' '
                    + (item.owner.userLastName === undefined ? '' : item.owner.userLastName)
                return name;
            }
            return item.owner.grpName;
        }
        else {
            const name = item.owner.userLastName + ', '
                + item.owner.userFirstName + ' '
                + (item.owner.userMiddleName === undefined ? '' : item.owner.userMiddleName);
            return name;
        }
    }

    return (
        <>
            {!props.filesFetching && props.files.length > 0 &&
                <Paper elevation={0} data-test='dataIsNotFetchingWrapper'>
                    <TableContainer data-test='tableContainer'
                        ref={tableContainer}
                        //style={{ maxHeight: `${dimensions.height - props.tableMarginHeight}px`,height: `${props.tableMarginHeight < 0 ? dimensions.height - props.tableMarginHeight : (props.files.length + 2) * 45 }px` }}
                        // style={{ maxHeight: `${dimensions.height - props.tableMarginHeight}px important!` }}
                        className={classes.container}>
                        <Table className={classes.customTable} size="medium" stickyHeader> 
                            <EnhancedTableHead
                                data-test='tablehead'
                                files={props.files}
                                curSorting={props.curSorting}
                                classes={classes}
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={props.files.length}
                                allowChangePermission={props.allowChangePermission}
                                onSelectFilters={onSelectFilters}
                                showOwner={props.showOwner}
                                sortOnClick={props.onSorting}
                                showPermission={props.showPermission}
                                showUploaded={props.showUploaded}
                                showMeAsOnwer={props.showMeAsOnwer}
                                showFileSize={props.showFileSize}
                                fileListHeaders={props.fileListHeaders}
                                onFiltering={props.onFiltering}
                            />
                            <TableBody className={classes.tableBody}>
                                {props.files.map((file: any, rIndex: number) => {
                                    const isItemSelected = isSelected(file.itemCode);
                                    const labelId = `enhanced-table-checkbox-${rIndex}`;
                                    return (
                                        <TableRow className={classes.itemListRow}
                                            key={rIndex}
                                            hover
                                            onClick={event => itemOnClick(event, file.itemCode, file.itemType)}
                                            // onDoubleClick={event => itemOnOpen(event, file.itemCode, file.itemType)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox"
                                                className={`${classes.itemListCell} ${classes.checkboxCell}`}
                                                onClick={event => itemOnClick(event, file.itemCode, file.itemType)}>
                                                <Checkbox color='primary' checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>
                                            <TableCell id={labelId} scope="row" padding="none" className={classes.avatarCell}
                                                onClick={event => itemOnClick(event, file.itemCode, file.itemType)}>
                                                <div className={classes.avatarCellContentWrapper}>
                                                    {file.ceremonyStatus === ItemCeremonyStatus.REQUESTED &&
                                                        <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Requested">
                                                            <IconButton aria-label="Signing Ceremony Requested" data-test="requested" onClick={(event) => { props.esignStatusOpen(file); event.stopPropagation(); }}>
                                                                <AlertAttention aria-label="Signing Ceremony Requested" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.IN_PROGRESS &&
                                                        <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Requested">
                                                            <IconButton aria-label="Signing Ceremony Requested" data-test="progress" onClick={(event) => { props.esignStatusOpen(file); event.stopPropagation(); }}>
                                                                <AlertAttention aria-label="Signing Ceremony Requested" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.WAITING_SIGNED_DOC &&
                                                        <Tooltip disableFocusListener disableTouchListener title="Waiting Signed Document">
                                                            <IconButton aria-label="Waiting Signed Document" data-test="waiting" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                                <AlertAttention aria-label="Waiting Signed Document" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.CANCELLED &&

                                                        <div>
                                                            <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Cancelled">
                                                                <IconButton aria-label="Signing Ceremony Cancelled" data-test="cancelled" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                                    <ErrorOutlineOutlinedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        // <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Cancelled">
                                                        //     <div className={classes.iconholder}>
                                                        //         <ErrorOutlineOutlinedIcon />
                                                        //     </div>
                                                        // </Tooltip>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.FAILED &&
                                                        <div>
                                                            <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Failed">
                                                                <IconButton aria-label="Signing Ceremony Failed" data-test="failed" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                                    <CancelIcon color="error" aria-label="Signing Ceremony Failed" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {/* <div className={classes.iconholder}>
                                                                <CancelIcon color="error" aria-label="Signing Ceremony Failed" />
                                                            </div> */}
                                                        </div>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.AGREED &&
                                                        <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Agreed">
                                                            <IconButton aria-label="Signing Ceremony Agreed" data-test="agreed" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                                <CheckCircleIcon color="primary" aria-label="Signing Ceremony Agreed" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.DECLINED &&
                                                        <div>
                                                            <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Declined">
                                                                <IconButton aria-label="Signing Ceremony Declined" data-test="declined" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                                    <CancelOutlinedIcon aria-label="Signing Ceremony declined" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    {file.ceremonyStatus === ItemCeremonyStatus.REVOKED &&
                                                        <div>
                                                            <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Cancelled">
                                                                <IconButton aria-label="Signing Ceremony Declined" data-test="declined" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                                    <CancelOutlinedIcon aria-label="Signing Ceremony declined" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    {file.itemType === 'FILE' && typeof file.ceremonyStatus === 'undefined' &&
                                                        <div className={classes.iconholder}>
                                                            <img className={classes.typeIcon} src={fileIcon} />
                                                        </div>
                                                    }
                                                    {file.itemType === 'FOLDER' && typeof file.ceremonyStatus === 'undefined' &&
                                                        <div className={classes.iconholder}>
                                                            <img className={classes.typeIcon} src={folderIcon} />
                                                        </div>
                                                    }
                                                    {file.itemType === 'FORM' && typeof file.ceremonyStatus === 'undefined' &&
                                                        <div className={classes.iconholder}>
                                                            <img className={classes.typeIcon} src={formIcon} />
                                                        </div>
                                                    }
                                                    <Tooltip disableFocusListener disableTouchListener title={file.itemName}>
                                                        <p className={classes.fileTitle}
                                                            onClick={event => itemOnOpen(event, file.itemCode, file.itemType, file)}>
                                                            {file.itemName}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                            {
                                                <TableCell className={`classes.momentListCell ${filters.indexOf('modifiedAt') > -1 ? null : classes.hiddenCol} `} align="left">
                                                    <p className={classes.momentCell}>{formattedDateTime(file.modifiedAt)}</p>
                                                </TableCell>
                                            }
                                            {
                                                props.showUploaded && <TableCell className={`classes.momentListCell ${filters.indexOf('createdAt') > -1 ? null : classes.hiddenCol} `} align="left">
                                                    <p className={classes.momentCell}>{formattedDateTime(file.createdAt)}</p>
                                                </TableCell>
                                            }
                                            {
                                                props.showOwner && !props.showFileSize &&
                                                <TableCell className={`classes.actionCell ${filters.indexOf('shared') > -1 ? null : classes.hiddenCol} `} align="left">
                                                    <span>Owner: You</span>
                                                </TableCell>
                                            }
                                            {
                                                props.showFileSize &&
                                                <TableCell className={`classes.fileSizeCell ${filters.indexOf('size') > -1 ? null : classes.hiddenCol}`} align="right">
                                                    {file.size === 0 ? '-' : sizeConverter(file.size)}
                                                </TableCell>
                                            }
                                            {
                                                props.showOwner && props.showFileSize &&
                                                <TableCell className={`classes.actionCell ${filters.indexOf('shared') > -1 ? null : classes.hiddenCol} `} align="left">
                                                    <Tooltip disableFocusListener disableTouchListener title={getOwner(file)}>
                                                        <p className={classes.ownerCell}>{getOwner(file)}</p>
                                                    </Tooltip>
                                                </TableCell>
                                            }
                                            {
                                                props.showPermission &&
                                                <TableCell className={`classes.actionCell ${filters.indexOf('permission') > -1 ? null : classes.hiddenCol} `} align="left">
                                                    {!props.allowChangePermission &&
                                                        <span className={classes.permissionStatic}>{file.permissionName === 'VIEW' ? 'View' : 'View & Download'}</span>
                                                    }
                                                    {props.allowChangePermission &&
                                                        <>
                                                            <Button onClick={openMenu} className={classes.permissionBtn} >
                                                                {file.permissionName === 'VIEW' ? 'View' : 'View & Download'} <ArrowDropDownIcon />
                                                            </Button>
                                                            <Menu
                                                                id="permission-menu"
                                                                anchorEl={anchorEl}
                                                                keepMounted
                                                                open={Boolean(anchorEl)}
                                                                onClose={handleClose}>
                                                                <MenuItem onClick={() => changePermission('VIEW')}>View</MenuItem>
                                                                <MenuItem onClick={() => changePermission('DOWNLOAD')}>View & Download</MenuItem>
                                                            </Menu>
                                                        </>
                                                    }
                                                </TableCell>
                                            }
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            }
            {
                props.filesFetching &&
                <>
                    <Paper className={classes.progressbar} data-test='dataIsFetchingWrapper'>
                        <LinearProgress className={classes.progressbar} variant="query" />
                    </Paper>
                    {/* <Loading loadingText="Loading..." /> */}
                </>
            }
        </>
    )
}

export const EnhancedTableHeadTesting = {EnhancedTableHead};

export default CommonFileItemList;