import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as TopicActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ContactsSearchBar from '../../../../../components/customerVault/contactsSearchBar/ContactsSearchBar';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import { Typography, Link, TextField } from '@material-ui/core';
import './AddContact.scss';
import CircularIndeterminate from '../../../../../shared/core/utils/CircularIndeterminate';

type AddContactProps = {
    classes: any;
    onActionComplete: any;
    handleError: any,
    addContactProgress: any,
    snackbarData: any,
    selectedTopic: any,
    addContactError: any,
    addContactGroup: any;
    updateSnackbar: any,
    getTopicContacts: any,
    getContactsProgress: any,
    topicContacts: any,
    loadTopicContacts: any
}

export const styles = (theme: any) => ({
    root: {
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
        fontFamily: 'Fidelity Sans',
        fontSize: '1rem',
        width: 500,
        minHeight: 200,
        //Check if it is not mobile
        [theme.breakpoints.down('sm')]: {
            width: '95%'
        }
    },
    textfield: {
        width: '90%',
        height: 56,
        margin: theme.spacing(2)
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(2),
    },
    cancelBtn: {
        color: "#646D7D",
        textTransform: 'none' as 'none'
    },
    errorMsg: {
        fontSize: '0.8rem',
        width: '100%',
        color: 'red',
        marginLeft: theme.spacing(2),
        whiteSpace: 'pre-wrap' as 'pre-wrap'
    },
    initAvatar: {
        backgroundColor: '#4A4A4A',
        color: 'white',
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.spacing(5),
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    },
    title:
    {
        display: 'flex',
        alignItems: 'center',
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    textinput:
    {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
});

class AddContact extends Component<AddContactProps> {

    state = {
        contactCodes: [],
        sharedTopicName: '',
        errorMsg: '',
        loading: true,
        isTopicValid: true
    }

    componentDidMount() {
        if (this.props.selectedTopic) {
            this.setState({ sharedTopicName: this.props.selectedTopic[0].stName })
        }
        if (this.props.loadTopicContacts)
            this.props.getTopicContacts(this.props.selectedTopic[0].stCode)
        else
            this.setState({ loading: false })
    }

    componentDidUpdate(prevProps: any) {
        const { addContactProgress, onActionComplete, addContactError, getContactsProgress } = this.props;
        var selectedTopic = this.props.selectedTopic[0];
        if (prevProps.getContactsProgress != getContactsProgress && !getContactsProgress) {
            this.setState({ loading: false })
        }
        if (prevProps.addContactProgress !== addContactProgress && !addContactProgress) {
            if (addContactError === null) {
                this.setState({ errorMsg: '' })
                onActionComplete(true);
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Topic ${this.state.sharedTopicName} has been modified.`
                })
            }
            else {
                if (addContactError && addContactError.response && addContactError.response.data.errCode) {
                    this.setState({ errorMsg: addContactError.response.data.errMsg })
                    if (addContactError.response.data.errCode === 401) {
                        this.props.handleError(addContactError);
                    }
                }
                else {
                    this.props.handleError(addContactError);
                }
                this.props.updateSnackbar({
                    modalOpen: true,
                    content: `Unable to edit Topic ${selectedTopic.categoryName}. Please try again later.`
                })
            }
        }
    }

    handleContactChange = (value: any) => {
        this.setState({ contactCodes: value, errorMsg: '' })
    }

    onEditContacts = () => {
        var selectedTopic = this.props.selectedTopic[0];
        if (this.state.contactCodes.length > 0)
            this.props.addContactGroup(
                this.state.sharedTopicName,
                selectedTopic.stCode,
                this.state.contactCodes,
            )
    }


    changeTopicName = (event: { target: { value: any; }; }) => {
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9 ()\/@&'.,_[\]$-]*$/sg;
        if (event.target.value.match(regex)) {
            this.setState({ isTopicValid: true })
        }
        else
            this.setState({ isTopicValid: false });
        if (event.target.value.length > 100) {
            this.setState({ isTopicValid: false })
        }
        this.setState({ sharedTopicName: event.target.value, errorMessage: null })
    }

    onCancelClick = () => {
        this.props.onActionComplete(false)
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} data-test='addContactWrapper'>
                <div className={classes.title}>
                    {/* <div className={classes.initAvatar} >
                        {topic.catgeoryIconLabel}
                    </div> */}
                    <Typography variant="h6">
                        {/* Add/Edit contact to {topic.categoryName} */}
                        Edit Topic
                    </Typography>
                </div>
                <TextField
                    //inputProps={{ pattern: "^[-@.\/()\/@&'.,_\-*$.A-Za-z0-9]*$" }}
                    inputProps={{ maxLength: 101 }}
                    error={!this.state.isTopicValid} className='add-topic-textfield' label="Edit a Topic Name" variant="outlined"
                    value={this.state.sharedTopicName} onChange={this.changeTopicName.bind(this)} />
                { !this.state.isTopicValid && <div className='helper-text-wrapper'>
                    <p className="topic-helper-text">Topic name should contain letters, numbers, and/or symbols ()/@&'.,_-$</p>
                    <p className="topic-helper-text">Topic name must be less than 100 characters.</p>
                </div>}
                 {this.state.loading ?
                    <CircularIndeterminate data-test='loadingIcon'></CircularIndeterminate> :
                     <><ContactsSearchBar handleChange={this.handleContactChange} data-test='contactlistsearchbar'
                            topicContacts={this.props.topicContacts != null ? this.props.topicContacts : []} /></>
                } 
                {this.state.errorMsg ? <div className={classes.errorMsg}>{this.state.errorMsg} </div> : null}
                <div className='add-contact-modal-actions'>
                    <Link className='contact-link-cancel' href="#" onClick={this.onCancelClick.bind(this)} id="cancel">
                        Cancel
                    </Link>
                    <Button className="common-modal-button"
                        id="sendInvite"
                        variant="contained"
                        onClick={this.onEditContacts.bind(this)}
                        disabled={this.state.contactCodes.length <= 0 ||
                            // this.state.loading || 
                            !this.state.isTopicValid
                            || this.state.sharedTopicName.trim() === ''}
                        color="primary"
                        disableElevation>
                        Save
                    </Button>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        addContactProgress: state.topicReducer.topicDetails.updating,
        addContactError: state.topicReducer.topicDetails.error,
        getContactsProgress: state.topicReducer.topicContacts.fetching,
        snackbarData: state.appUIStateReducer.snackbar,
        error: state.contactsReducer.groups.error,
        selectedTopic: state.appUIStateReducer.selectedTopic,
        topicContacts: state.topicReducer.topicContacts.contactsData.content
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addContactGroup: (topicName: any, topicCode: any, contactCodes: []) => dispatch({
            type: TopicActionTypes.USER_API_EDIT_TOPIC_USERS_REQUEST,
            topicName: topicName,
            topicCode: topicCode,
            contactCodes: contactCodes,
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        }),
        getTopicContacts: (topicCode: any) => dispatch({
            type: TopicActionTypes.USER_API_GET_TOPIC_USERS_REQUEST,
            topicCode: topicCode
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AddContact)); 