import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CommonModal } from '../../../../../shared/ui';
import * as TopicActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import { paginationTopics } from "../../../../../shared/core/utils/paginationDefaults";
import CommonTopicList from '../../../../../shared/ui/commonTopicList/commonTopicList';
import CommonTopicGridList from '../../../../../shared/ui/commonTopicGridList/commonTopicGridList';
import { Paper, AppBar, Hidden, IconButton, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { ReactComponent as GridIcon } from '../../../../../assets/grid.svg';
import { ReactComponent as ListViewIcon } from '../../../../../assets/list-view.svg';
import FilterBox from '../topicFilter/FilterBox';
import AddContact from '../../../contacts/components/addContact/AddContact';
import './TopicListView.scss';
import * as AppUIStateActionTypes from '../../../../../redux/constants/shared/appStatesConstants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as FilterFilledIcon } from '../../../../../assets/filter-filled.svg';
import { ReactComponent as FilterUnFilledIcon } from '../../../../../assets/filter-unfilled.svg';
import ReactGA from 'react-ga';
import * as GAConstants from '../../../../../shared/core/GAConstants';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../../../shared/ui/tablePaginationActions/TablePaginationActions';
import LinearProgress from '@material-ui/core/LinearProgress';

interface TopicType {
  stCode: string,
  stName: string,
  categoryName: string,
  createdAt: string,
  modifiedAt: string,
  categoryIconColor: string,
  catgeoryIconLabel: string,
  sharedTopicItemsCount: number,
  sharedTopicUsersCount: number
  grpCode: string
}

type CategoryType = {
  grpCount: number,
  createdAt: string,
  modifiedAt: string,
  categoryCode: string,
  categoryName: string,
  categoryIconLabel: string,
  categroyIconColor: string
}

type TopicListViewProps = {
  selectedUser?: any,
  fetchTopics?: any,
  fetchUserProfile?: any,
  fetchCategories?: any,
  errorToNull?: any,
  topicData?: any,
  topicsFetching: boolean,
  selectTopic?: any,
  history: any,
  selectedTopic: any,
  fetchMoreTopics: any,
  fetchMoreTopicsStart: any,
  topicsLazyLoading: boolean,
  updateTopics?: boolean,
  onUpdateComplete?: any,
  categories: CategoryType[],
  selectedGroup: any,
  groups: any,
  loggedIn: any,
  updateGroup: any,
  tableMarginHeightExtra?: number,
  fetchAllTopics: any
  feedbackOpen: boolean,
  topicListHeaders: string[],
  updateTopicHeaders: any
}

type TopicListViewStates = {}

export const content_type = {
  TEXT: 'text',
  DATE: 'date',
  ICON: 'icon',
  LINK: 'link'
}

export type TopicContent = {
  stCode: string,
  stName: string,
  categoryName: string,
  createdAt: string,
  modifiedAt: string,
  categoryIconColor: string,
  categoryIconLabel: string,
  sharedTopicItemsCount: number,
  sharedTopicUsersCount: number
}

class TopicListView extends Component<TopicListViewProps, TopicListViewStates> {

  state = {
    pagination: { ...paginationTopics },
    filter: {
      categoryCode: '',
      createdAt: '',
      modifiedAt: '',
    },
    hasFilterCondition: false,
    openTopicFilter: false,
    listView: true,
    isModalOpen: false,
    modalName: '',
    noMoreData: false,
    openFilter: true,
    menuActions: {
      count: 1,
      menu: [
        {
          id: 'updateContact',
          label: 'Add/Edit Contacts',
          show: true
        }
      ]
    }
  };

  componentDidMount() {
    const { fetchTopics } = this.props;
    if (this.props.loggedIn.successful) {
      this.props.fetchCategories();
      fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
    }
    this.setState({ noMoreData: false })
    if (window.innerWidth < 600) {
      this.setState({ openFilter: false })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (newProps.updateTopics != null && newProps.updateTopics) {
      this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
      this.props.onUpdateComplete();
    }
  }

  toggleViews = () => {
    this.setState({ listView: !this.state.listView })
    this.setState({ selectedFiles: [] })
  }

  toggleFilter = () => {
    this.setState({ openFilter: !this.state.openFilter })
  }


  sortingOnClick = (id: string) => {
    this.setState({ noMoreData: false })
    switch (id) {
      case 'stName':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "NAME"
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser);
        })
        break;
      case 'categoryName':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "CATEGORY_NAME"
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser);
        })
        break;
      case 'modifiedAt':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "MODIFIED_AT"
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser);
        })
        break;
      case 'createdAt':
        this.setState({
          pagination: {
            page: 0,
            size: 20,
            direction: this.state.pagination.direction === 'ASC' ? 'DESC' : 'ASC',
            orderBy: "CREATED_AT"
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser);
        })
        break;
    }
  }

  onFileOpen = (topic: TopicType) => {
    this.props.selectTopic(topic)
    this.props.history.push(`/customer/group/${this.props.selectedGroup.grpCode}/topic-detail/${topic.stCode}`);
    ReactGA.event({
      category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
      action: GAConstants.TOPIC_SELECTION_ACTION,
      //label: topic.categoryName
    });
  }

  fetchMoreData = () => {
    if (this.props.topicData.page.pageNumber < this.props.topicData.page.totalPages - 1) {
      this.props.fetchMoreTopicsStart();
      let paginationNew = {
        ...this.state.pagination,
        page: this.props.topicData.page.pageNumber + 1
      }
      // this.props.fetchMoreTopics(paginationNew, this.state.filter);
      setTimeout(() => {
        this.props.fetchMoreTopics(paginationNew, this.state.filter, this.props.selectedUser);
      }, 1500)
    } else {
      this.setState({ noMoreData: true })
    }
  }

  updateContacts = (topic: string) => {
    //set the common modal to open
    this.props.selectTopic(topic)
    this.setState({ isModalOpen: true, modalName: 'addcontacts' })
  }

  menuActionOnClick = (cat: string, val: any) => {
    switch (cat) {
      case 'updateContact':
        this.updateContacts(val);
    }
  }

  handleActionDialogClose = () => {
    this.setState({ isModalOpen: false, modalName: '' })
  }

  onActionComplete = () => {
    this.setState({ isModalOpen: false, modalName: '' })
    this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
  }


  getModalContent = (name: string) => {
    switch (name) {
      case 'addcontacts':
        return <AddContact
          onActionComplete={this.onActionComplete}
          handleError={this.handleActionDialogClose}
          loadTopicContacts={true} 
        />
      default:
        return null
    }
  }

  filterTopics = (id: string, val: any) => {
    switch (id) {
      case 'CATEGORY':
        this.setState({
          filter: {
            ...this.state.filter,
            categoryCode: val ? val.categoryCode : null
          },
          pagination: {
            ...this.state.pagination,
            page: 0
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
        });
        break;
      case 'CREATED':
        this.setState({
          filter: {
            ...this.state.filter,
            createdAt: val ? val.value : null
          },
          pagination: {
            ...this.state.pagination,
            page: 0
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
        });
        break;
      case 'MODIFIED':
        this.setState({
          filter: {
            ...this.state.filter,
            modifiedAt: val ? val.value : null
          },
          pagination: {
            ...this.state.pagination,
            page: 0
          }
        }, () => {
          this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
        });
        break;
    }
    this.setState({ noMoreData: false });
    val ? this.setState({ hasFilterCondition: true }) : this.setState({ hasFilterCondition: false });
  }

  handlePageChange = (event: any, pageNumber: any) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: pageNumber
        }
      }, () => {
        this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
      })
  }

  handleRowsPerPageChange = (event: any) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          size: event.target.value,
          page: 0
        }
      }, () => {
        this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
      })
  }

  resetFilter = () => {
    this.setState({
      filter: {
        categoryCode: null,
        createdAt: null,
        modifiedAt: null
      }
    }, () => {
      this.props.fetchTopics(this.state.pagination, this.state.filter, this.props.selectedUser)
    })
    this.setState({ noMoreData: false })
    this.setState({ hasFilterCondition: false });
  }

  onHeaderFiltering = (filteredHeaders: string[]) => {
    this.props.updateTopicHeaders(filteredHeaders)
  }

  getDisplayedRows: ({ from, to, count }: { from: number, to: number, count: any }) => string = ({ from, to, count }) => {
    return (count && !this.props.topicsFetching) ? `${from}-${to} of ${count} total topics` : '';
  }

  getDisplayedRowsParams = () => {
    const count = this.props.topicData.page.totalElements;
    const page = this.props.topicData.page.pageNumber;
    const rowsPerPage = this.state.pagination.size;
    const from = count === 0 ? 0 : page * rowsPerPage + 1;
    const to = count !== -1 ? Math.min(count, (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage;

    return { from, to, count };
  }

  renderTablePagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        labelRowsPerPage="Results per page:"
        count={this.props.topicData.page.totalElements}
        rowsPerPage={this.state.pagination.size}
        page={this.props.topicData.page.pageNumber}
        component="div"
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        data-test="tablepagination"
        onChangePage={this.handlePageChange}
        onChangeRowsPerPage={this.handleRowsPerPageChange}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={this.getDisplayedRows}
      />
    );
  }

  renderTopicsGridList = () => {
    return (
      <>
        <CommonTopicGridList
          topics={this.props.topicData.content}
          tableMarginHeight={0}
          menuActions={this.state.menuActions}
          onFileOpen={this.onFileOpen}
          topicsFetching={this.props.topicsFetching}
          lazyLoading={this.props.topicsLazyLoading}
          noMoreData={this.state.noMoreData}
          menuActionOnClick={this.menuActionOnClick} />
        <>{this.renderTablePagination()}</>
      </>
    );
  }

  render() {
    return (
      <>
        <div>
          <AppBar position="static" className="topic-app-bar-wrapper" data-test="topiclist-view-advisor">
            <div className='total-count-label-wrapper'>
              <p className='MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit total-count-label'>
                {this.getDisplayedRows(this.getDisplayedRowsParams())}
              </p>
            </div>
            <div className="action-bar">
              <IconButton className="iconBtn-wrapper" aria-label="Filter" onClick={this.toggleFilter} data-test="filtter-icon" title="Filter">
                {!this.state.hasFilterCondition && <FilterUnFilledIcon className="iconBtn" data-test="filter-unfilled-icon" />}
                {this.state.hasFilterCondition && <FilterFilledIcon title="Filter Filled" className="iconBtn" data-test="filter-filled-icon" />}
              </IconButton>
              <Hidden smDown>
                {this.state.listView &&
                  <IconButton data-test="listview" className="iconBtn-wrapper" aria-label="Grid" title="Grid" onClick={this.toggleViews}>
                    <GridIcon className="iconBtn" />
                  </IconButton>
                }
                {!this.state.listView &&
                  <IconButton data-test="gridview" className="iconBtn-wrapper" aria-label="List" title="List" onClick={this.toggleViews}>
                    <ListViewIcon className="iconBtn" />
                  </IconButton>
                }
              </Hidden>
            </div>
          </AppBar>
          <div className="expansion-panel">
            <ExpansionPanel className="filter-collapse-wrapper" expanded={this.state.openFilter}>
              <ExpansionPanelSummary style={{ display: 'none' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography className="heading">
                  {!this.state.hasFilterCondition && <FilterUnFilledIcon className="iconBtn" />}
                  {this.state.hasFilterCondition && <FilterFilledIcon className="iconBtn" />}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="filter-collapse-details">
                <FilterBox data-test="filterbox" filterTopics={this.filterTopics} resetFilter={this.resetFilter} categories={this.props.categories} />
              </ExpansionPanelDetails>
            </ExpansionPanel>

          </div>

          {this.props.topicData.content.length > 0 &&
            <Paper>
              <Hidden smDown>
                {this.state.listView &&
                  <><CommonTopicList
                    data-test="commontopiclist"
                    topics={this.props.topicData}
                    menuActions={this.state.menuActions}
                    menuActionOnClick={this.menuActionOnClick}
                    onSorting={this.sortingOnClick}
                    tableMarginHeight={0}
                    onFileOpen={this.onFileOpen}
                    topicsFetching={this.props.topicsFetching}
                    fetchMoreData={this.fetchMoreData}
                    lazyLoading={this.props.topicsLazyLoading}
                    noMoreData={this.state.noMoreData}
                    topicListHeaders={this.props.topicListHeaders}
                    onFiltering={this.onHeaderFiltering} />
                    <>{this.renderTablePagination()}</>
                  </>
                }
                {!this.state.listView &&
                  <>{this.renderTopicsGridList()}</>
                }
              </Hidden>
              <Hidden mdUp>
                <>{this.renderTopicsGridList()}</>
              </Hidden>
            </Paper>
          }
          {(this.props.topicData.content.length === 0 && !this.props.topicsFetching) &&
            <AppBar position='static' className="topic-app-bar-wrapper-no-topics no-margin-top">
              <p style={{ 'padding': '1em', 'margin': 0, 'color': '#000' }}>No Topics to display. </p>
            </AppBar>
          }
          {this.props.topicsFetching &&
            <Paper data-test='dataIsFetchingWrapper' className="progressbar">
              <LinearProgress className="progressbar" variant="query" />
            </Paper>
          }
          <CommonModal modalOpen={this.state.isModalOpen}
            data-test="commonmodal"
            modalClose={this.handleActionDialogClose}
            content={this.getModalContent(this.state.modalName)} />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    selectedGroup: state.appUIStateReducer.selectedGroup,
    feedbackOpen: state.appUIStateReducer.feedbackOpen,
    topicData: state.topicReducer.topicList.topicData,
    topicsFetching: state.topicReducer.topicList.fetching,
    topicsLazyLoading: state.topicReducer.topicList.lazyLoading,
    selectedTopic: state.appUIStateReducer.selectedTopic,
    categories: state.topicReducer.topicCategories.categoryData.content,
    groups: state.homeReducer.groupsInfo.response.data.content,
    loggedIn: state.login,
    topicListHeaders: state.appUIStateReducer.topicListHeaders
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // fetchUserProfile: (grpCode, userCode) => dispatch({type: TopicS_LIST_API_GET_USER_PROFILE_REQUEST, grpCode, userCode}),
    fetchTopics: (pagination: any, filter: any, userCode?: any) => dispatch({
      type: TopicActionTypes.TOPICS_API_GET_TOPICS_REQUEST,
      pagination, filter,
      userCode: userCode
    }),
    fetchAllTopics: (pagination: any, filter: any, userCode?: any, grpCode?: any, grpName?: any,
      productCode?: any) => dispatch({
        type: TopicActionTypes.TOPICS_API_GET_ALL_TOPICS_REQUEST,
        pagination, filter,
        userCode: userCode,
        grpCode: grpCode,
        grpName: grpName,
        productCode: productCode
      }),
    fetchMoreTopicsStart: () => dispatch({
      type: TopicActionTypes.TOPICS_API_LOAD_MORE_TOPICS_START,
    }),
    fetchMoreTopics: (pagination: any, filter: any, userCode?: any) => dispatch({
      type: TopicActionTypes.TOPICS_API_LOAD_MORE_TOPICS_REQUEST,
      pagination, filter,
      userCode: userCode
    }),
    selectTopic: (topic: TopicType) => dispatch({ type: TopicActionTypes.TOPIC_API_SELECT_TOPIC, selectedTopic: topic }),
    // fetchUserProfile: (grpCode, topic) => dispatch({type: TOPICS_LIST_API_GET_USER_PROFILE_REQUEST, grpCode, topic }),
    fetchCategories: () => dispatch({ type: TopicActionTypes.TOPICS_API_GET_CATEGORIES_REQUEST }),
    updateGroup: (group: any) => dispatch({
      type: AppUIStateActionTypes.APP_STATE_UPDATE_GROUP,
      group: group
    }),
    // errorToNull: () => dispatch({type: TOPICS_ERROR_CLEAR}),
    updateTopicHeaders: (filteredHeaders: string[]) => dispatch({
      type: AppUIStateActionTypes.UPDATE_TOPIC_LIST_HEADERS,
      filteredHeaders: filteredHeaders
    })
  };
};

export const TopicListComponent = connect(mapStateToProps, mapDispatchToProps)(TopicListView);

export default withRouter(TopicListComponent);