const styles = ({
    legalDocStyles: {
            // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            color: '#444657',
            letterSpacing: '0.5px',
        
        '& h2': {
            color: 'rgba(0,0,0,0.87)',
            fontSize: 28,
            margin: 0,
        },
        
        '& h3': {
            color: 'rgba(0,0,0,0.87)',
            fontSize: 24,
            margin: '48px 0 32px 0',
        },
        
        '& h4':{
            color: 'rgba(0,0,0,0.87)',
            fontSize: 20,
            margin: '32px 0',
        },

        '& h2, h3, h4': {
            // fontFamily: 'Lato', 
            fontWeight: 400,
            color: 'rgba(0,0,0,0.87)',
            letterSpacing: '0.2px',
        },
        
        '& p, ul': {
            lineHeight: 1.8,
            fontSize: 16,
            margin: '24px 0',
            display: 'inline-block',
            wordBreak: 'break-word'
        },
        
        '& li': {
            margin: '10px 0',
        },

        '& .paragraphList > li': {
            margin: '24px 0',
        },

        '& .dateModified': {
            margin: '16px 0 0 0 !important',
        },
        
        '& .description':  {
            margin: '48px 0',
        },


        '& .firstParagraph': {
            marginTop: '48',
        },
    },
})

export default styles;