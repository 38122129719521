import React, { useState, useEffect, useRef } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, IconButton, Checkbox, TableSortLabel, Menu, MenuItem, CircularProgress, LinearProgress } from '@material-ui/core';
import pendingIcon from '../../../assets/pending.svg';
import { ReactComponent as AddTopicIcon } from '../../../assets/topics.svg';
import { ReactComponent as ViewIcon } from '../../../assets/preview.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { Link, withRouter } from 'react-router-dom';
import clsx from 'clsx';

import './itemList.scss';
import { formattedDate } from '../../core/utils/functions';

type Column = {
    id: string,
    label: string,
    sortable: boolean,
    mustShow: boolean,
    align?: string
}

type MenuItemType = {
    id: string,
    label: string
}

const ItemList = (props: any) => {
    const { data, columns, selectable, batchAction, itemAction, pagination, itemOnClick, sortingOnClick, rowOnClick } = props
    const [selectedItem, menuBtnOnClick] = useState<{ id: string, item: any[] }>({ id: '', item: [] })
    const [sortDirection, changeSortDirection] = useState(true);
    const [sortCol, setSortCol] = useState(0);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const tableContainer: any = useRef<HTMLDivElement>(null);
    const [hasReachedToBottom, setReachToBottom] = useState(false);
    const [timeUp, setTimer] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener("resize", handleResize);
    }, [])


    const selectAll = () => {
    }

    const onSelect = () => {
        console.log('On Change!');
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowId: string, menu: any[]) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        menuBtnOnClick({ id: rowId, item: menu });
    };

    const menuItemOnClick = (event: React.MouseEvent<HTMLButtonElement>, eventId: string, itemId: string) => {
        event.stopPropagation();
        props.itemOnClick({ eventId: eventId, itemId: itemId })
    }

    const closeMenu = () => {
        setTimeout(() => {
            setAnchorEl(null);
            menuBtnOnClick({ id: '', item: [] });
        }, 100)
    }


    const procCellData = (type: string, data: any) => {
        switch (type) {
            case 'text':
                return data;
            case 'link':
                return (
                    <>
                        <Link className='cell-link' to={data.link}>{data.data}</Link>
                    </>
                )
            case 'date':
                const localedDate = new Date(data).toLocaleString('en-US')
                return <span> {formattedDate(localedDate)}</span>;
            case 'icon':
                switch (data) {
                    case 'PENDING':
                        return <img className='pending-icon' src={pendingIcon} data-test='pendingIcon' />
                    default:
                        return null;
                }
            default:
                return data;
        }
    }

    const tableOnScroll = (event: any) => {
        if (tableContainer.current.offsetHeight + Math.ceil(tableContainer.current.scrollTop) >= tableContainer.current.scrollHeight) {
            if (timeUp === false) {
                setReachToBottom(true);
                props.fetchMoreData();
                setTimer(true);
                setTimeout(() => setTimer(false), 3000)
            }
        }
    }

    return (
        <>
            {!props.dataFetching &&
                <Paper elevation={0} data-test='dataIsNotFetchingWrapper'>
                    <TableContainer
                        data-test='tableContainer'
                        ref={tableContainer}
                        className='tableContainer'
                        onScroll={(e) => props.fetchMoreData && tableOnScroll(e)}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {selectable === true &&
                                        <TableCell>
                                            <Checkbox data-test='checkboxAll' onChange={selectAll} />
                                        </TableCell>
                                    }
                                    {columns.map((col: Column, cIndex: number) => (
                                        <TableCell key={col.id} className={clsx('itemlist-table-header-cell', { ['header-right-align']: col.align === 'right' })}>
                                            {col.sortable ?
                                                <TableSortLabel data-test='headerSortLabel'
                                                    active={cIndex === sortCol}
                                                    onClick={() => { sortingOnClick(col.id); changeSortDirection(!sortDirection); setSortCol(cIndex) }}
                                                    direction={sortDirection ? 'asc' : 'desc'}
                                                    title={sortDirection ? 'Ascending' : 'Descending'}
                                                >
                                                    <span className='itemlist-table-header'>{col.label}</span>
                                                </TableSortLabel> :
                                                <span className='itemlist-table-header'>{col.label}</span>
                                            }
                                        </TableCell>
                                    ))}
                                    {batchAction.enable ?
                                        <TableCell>
                                            <IconButton color='primary' aria-label='batch actions' component='span'>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </TableCell> :
                                        <TableCell></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row: any, rIndex: number) =>
                                    <TableRow data-test='tableRow' hover key={rIndex} onClick={() => rowOnClick(row)}>
                                        {/* Selectable? */}
                                        {selectable &&
                                            <TableCell>
                                                <Checkbox data-test='checkBox' onChange={onSelect} />
                                            </TableCell>
                                        }
                                        {row.cells.map((cell: any, cIndex: number) => (
                                            <TableCell key={cIndex} className={(cell.resizeShown ? 'tableCellContainer' : 'tableCellContainerHidden')}>
                                                <div className={clsx('topCell', { ['right-align']: cell.align === 'right' })}>
                                                    <div className={(cell.content.s1.bold ? 'cellBoldSection' : 'cellSection')}>
                                                        {procCellData(cell.content.s1.type, cell.content.s1.content)}
                                                    </div>
                                                    <div className={(cell.content.s2.bold ? 'cellBoldSection' : 'cellSection')}>
                                                        {procCellData(cell.content.s2.type, cell.content.s2.content)}
                                                    </div>
                                                </div>
                                                <div className='bottomCell'>
                                                    <div className={(cell.content.s3.bold ? 'cellBoldSection' : 'cellSection')}>
                                                        {procCellData(cell.content.s3.type, cell.content.s3.content)}
                                                    </div>
                                                    <div className={(cell.content.s4.bold ? 'cellBoldSection' : 'cellSection')}>
                                                        {procCellData(cell.content.s4.type, cell.content.s4.content)}
                                                    </div>
                                                </div>
                                            </TableCell>
                                        ))}
                                        {itemAction.enable ?
                                            <TableCell className='item-ation-btn-cell'>
                                                {itemAction.menu[rIndex].menu.length > 2 &&
                                                    <IconButton
                                                        data-test='actionMenuBtn'
                                                        color='primary'
                                                        aria-label='item actions'
                                                        component='span'
                                                        aria-controls='action-menu'
                                                        aria-haspopup='false'
                                                        onClick={(e: any) => handleClick(e, row.id, itemAction.menu[rIndex].menu)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                }
                                                {itemAction.menu[rIndex].menu.length <= 2 && itemAction.menu[rIndex].menu.map((item: any, mIndex: number) => (
                                                    <IconButton
                                                        data-test='actionItemLessThanTowMenuBtn'
                                                        key={mIndex}
                                                        color='primary'
                                                        aria-label='item actions'
                                                        component='span'
                                                        aria-controls='action-menu'
                                                        aria-haspopup='false'
                                                        onClick={(e: any) => menuItemOnClick(e, itemAction.menu[rIndex].menu[mIndex].id, row.id)}>
                                                        {item.id === 'addTopic' && <AddTopicIcon />}
                                                        {item.id === 'removeContact' && <DeleteIcon />}
                                                        {item.id === 'removeGroup' && <DeleteIcon />}
                                                        {item.id === 'cancelRequest' && <DeleteIcon />}
                                                        {item.id === 'viewDetail' && <ViewIcon />}
                                                    </IconButton>
                                                ))
                                                }
                                            </TableCell> :
                                            <TableCell data-test='emptyActionTableCell'></TableCell>
                                        }
                                    </TableRow>
                                )}
                                {props.lazyLoading &&
                                    <TableRow >
                                        <TableCell colSpan={props.columns.length + 1}>
                                            <div className='loadingWrapper'>
                                                <CircularProgress size={12} /> <span className='loadingContext'>LOADING MORE</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                                {props.noMoreData &&
                                    <TableRow >
                                        <TableCell colSpan={props.columns.length + 1}>
                                            <div className='loadingWrapper'>
                                                <span className='loadingContext'>END OF LIST</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                            <Menu data-test='action-menu'
                                className='item-action-menu'
                                id='action-menu'
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={closeMenu}>
                                {selectedItem.item.map((item: MenuItemType, mIndex: number) => (
                                    <MenuItem data-test='itemActionMenu'
                                        className='item-menu-list-item' key={mIndex} onClick={() => {
                                            itemOnClick({ eventId: item.id, itemId: selectedItem.id });
                                            closeMenu()
                                        }}>
                                        {item.label}
                                    </MenuItem>)
                                )}
                            </Menu>
                        </Table>
                    </TableContainer>
                </Paper>
            }
            {props.dataFetching &&
                <LinearProgress data-test='dataIsFetchingWrapper' />
            }
        </>
    )
}
export { ItemList };
export default withRouter(ItemList);