import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as TopicActionTypes from '../../../../../redux/constants/customerVault/topicConstants';
import { connect } from 'react-redux';
import { DialogContent, Paper, DialogActions, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Fade, Modal, IconButton, Button } from '@material-ui/core';
import Uploader from '../uploader/Uploader';
import { Typography } from '@material-ui/core';
import FabButton from '../../../../../shared/core/FabButton';
import DescriptionIcon from '@material-ui/icons/Description';
import fileIcon from '../../../../../assets/file-grey.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import store from '../../../../../index-store';
import CircularProgressWithLabel from '../../../../../shared/ui/circularProgressWithLabel/CircularProgressWithLabel'
import ReactGA from 'react-ga';
import * as GAConstants from '../../../../../shared/core/GAConstants';

type uploadprops = {
    files: any,
    modalOpen: any,
    classes: any,
    topic: any,
    onActionComplete: any,
    children?: never[],
    isEndUser: any
}

export const styles = (theme: any) => ({
    root:
    {
        padding: "8px 8px",
        marginBottom: '1rem'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    container: {
        width: '100%',
        maxHeight: 440,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 150,
            overflow: 'auto'
        },
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    title:
    {
        fontFamily: 'Fidelity Sans',
        display: 'flex',
        alignItems: 'center',
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    paper: {
        //backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: `${theme.spacing(2)}px`,
        margin: theme.spacing(1),
        position: 'relative' as 'relative',
        borderRadius: '3px',
        backgroundColor: '#FAFAFA',
        minWidth: '600px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 11,
            minWidth: '280px'
        },
    },
    closeBtn: {
        position: 'absolute' as 'absolute',
        top: '0',
        right: '0',
        color: '#444657'
    },
    tableCellItem: {
        borderBottom: 'none',
        padding: '1rem',
        color: '#848484'
    },
    fileInfo: {
        display: 'flex'
    },
    fileIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem'
    },
    itemTitle: {
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-all' as 'break-all'
    },
    tableCellError: {
        color: 'red',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '10px',
        }
    },
    fileProgress: {
        width: 20,
        height: 20,
    },
    successIcon: {
        color: 'green'
    },
    errorIcon: {
        color: 'red'
    },
    errorMsg: {
        position: 'relative' as 'relative',
        fontWeight: 'bold' as 'bold',
        fontSize: 12,
        width: '100%',
        textAlign: 'center' as 'center',
        color: 'red'
    },
});


class Upload extends Component<uploadprops> {

    state = {
        loading: true
    }

    onFileUploadAction = () => {

    }

    formatBytes = (b: any) => {
        const units = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB']
        let l = 0
        let n = b

        while (n >= 1024) {
            n /= 1024
            l += 1
        }
        return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
    }

    renderProgress(file: any) {
        const { classes } = this.props;
        if (file.uploading)
            return <CircularProgressWithLabel value={file.progress}></CircularProgressWithLabel>
        else if (file.error != null)
            return <WarningIcon className={classes.errorIcon} />
        else {
            if (this.props.isEndUser)
                ReactGA.event({
                    category: GAConstants.USER_EVENT_CATEGORY,
                    action: GAConstants.FILE_UPLOADED,
                    label: file.type
                });
            else
                ReactGA.event({
                    category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                    action: GAConstants.FILE_UPLOADED,
                    label: file.type
                });
            return <DoneIcon color="primary" />
        }
    }

    renderErrorMsg(file: any) {
        const { classes } = this.props;
        if (file.error != null)
            return <div className={classes.errMsg}>{file.error.errMsg != null ? file.error.errMsg : 'Something went wrong. Please try again.'}</div>
        else
            return null
    }

    renderTableView() {
        const { classes } = this.props;
        return (
            <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableItemHeader}>File</TableCell>
                            {/*    <TableCell className={classes.tableItemHeader} align="center">Type</TableCell> */}
                            <TableCell className={classes.tableItemHeader} align="right">Size</TableCell>
                            <TableCell className={classes.tableItemHeader} align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.files.map((file: any, rindex: any) => (
                            <React.Fragment key={`file${rindex}`}>
                                <TableRow
                                    key={rindex}
                                    classes={{ hover: classes.hover, selected: classes.selected }}>
                                    <TableCell className={classes.tableCellItem}>
                                        <div className={classes.fileInfo}>
                                            <div className={classes.fileIcon}>
                                                <img src={fileIcon} />
                                            </div>
                                            <div className={classes.itemTitle}>{file.itemInfo != null ? file.itemInfo.title : ''}</div></div>
                                    </TableCell>
                                    <TableCell className={classes.tableCellItem} style={{ whiteSpace: 'nowrap' }} align="right">{file.itemInfo != null ?
                                        this.formatBytes(file.itemInfo.size) : ''}</TableCell>
                                    <TableCell className={classes.tableCellItem} align="right">{this.renderProgress(file)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellError} colSpan={4}>{this.renderErrorMsg(file)}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    render() {
        const { classes } = this.props;
        //Check if all the uploading were finished
        let temp = this.props.files.filter((file: any) => file.uploading)
        let doneDisable = temp != null && temp.length > 0 ? true : false
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={this.props.modalOpen}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                data-test='upload-component'
                >
                <Fade in={this.props.modalOpen}>
                    <div className={classes.paper}>
                        <Typography variant="h6" className={classes.title}>
                            Add New File
                            </Typography>
                        <DialogContent className={classes.root}>
                            <Paper elevation={0} >
                                <Uploader isEndUser={this.props.isEndUser} uploaderkey="key1" topic={this.props.topic} onFileUploadAction={this.onFileUploadAction} />
                                {
                                    this.props.files !== undefined ? (this.props.files.length > 0
                                        ? this.renderTableView()
                                        : null) : null
                                }
                            </Paper>
                        </DialogContent>
                        <DialogActions className="common-modal-actions">
                            <Button className="common-modal-button"
                                id="uploadDone"
                                variant="contained"
                                onClick={() => this.props.onActionComplete()}
                                color="primary"
                                disabled={doneDisable}
                                data-test='done-btn'
                                disableElevation>
                                Done
                            </Button>
                        </DialogActions>
                    </div>

                </Fade>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        files: state.topicFilesReducer.filesUpload.files
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Upload));