import React, { Component } from 'react';
import TermsOfService from '../loginuser/components/TermsOfService';
import { INVITE_VERIFICATION_REQUEST, INVITE_VERIFICATION_ERROR_CLEAR } from './inviteVerificationConstants'
import { FETCH_TRM_OF_SVC_REQUEST } from '../../../redux/constants/shared/legalsConstants';
import { LOGIN_REQUESTING } from '../../../redux/constants/shared/loginUserConstants';
import { Redirect, withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import styles from './inviteVerificationStyles';
import Typography from '@material-ui/core/Typography';
import { Fab } from '@material-ui/core';
import classNames from 'classnames';
import TextInput from '../../../shared/core/TextInput';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { con } from '../../../shared/config/config'

class InviteVerification extends Component {
    state = {
        openTerms: false,
        ssn: '',
        dob: '',
        hasAgreed: false,
        snackBarOpen: false
    };

    componentDidUpdate(prevProps) {
        const { error } = this.props
        if (error && prevProps.error !== this.props.error) {
            this.setState({ snackBarOpen: true });
        }
    }

    handleOpenModal = (type) => {
        if (type === 'openTerms') {
            if (!this.props.trmsOfSvcfetching && (!this.props.trmsOfSvcContent || this.props.trmsOfSvcContent.length === 0))
                this.props.fetchTrmsOfSvcContent();
            this.setState({ [type]: true });
        }
        else if (type === 'openPolicy') {
            //open fidelity privacy policy
            window.open(con.PRIVACY_POLICY,'_blank');
        }
        
    }

    handleCloseModal = (type) => {
        this.setState({ [type]: false });
    }

    generateInlineLink(flag, label) {
        const { classes } = this.props;
        return <a className={classes.link} onClick={() => this.handleOpenModal(flag)}>{label}</a>
    }

    generateInlineLinkForCheckBox(flag, label) {
        const { classes } = this.props;
        return <a className={classes.link} style={{ fontSize:16 }} onClick={() => this.handleOpenModal(flag)}>{label}</a>
    }

    handleSSNInputChange = (id, value) => {
        this.setState({ ssn: value })
    }

    handleDOBInputChange = (date, value) => {
        this.setState({ dob: value })
    }

    agreeTerms = () => {
        this.setState({ hasAgreed: !this.state.hasAgreed })
    }

    verify = () => {
        let dob = new Date(this.state.dob);
        this.props.verifyUser(dob.getFullYear() + '-' + ('0' + (dob.getMonth()+1)).slice(-2) + '-' + ('0' + dob.getDate()).slice(-2), this.state.ssn);
    }


    handleSnackbarClose = () => {
        this.props.clearVerificationError();
        this.setState({ snackBarOpen: !this.state.snackBarOpen });
    }

    renderSnackBar() {

        const { classes, error } = this.props;

        return <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={this.state.snackBarOpen}
            >
                <SnackbarContent
                    className={classNames(classes.error)}
                    message={
                        <span className={classes.message}>
                            <ErrorIcon className={classNames(classes.icon, classes.iconVar)} />
                            {error}
                        </span>
                    }
                    action={
                        <IconButton
                            color='inherit'
                            onClick={this.handleSnackbarClose}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    }
                />
            </Snackbar>
        </>
    }

    renderLinkAndTos() {
        const { classes } = this.props;
        return <>
            <div className={classes.statement} style={{ marginTop: '1rem' }}>
                Fidsafe<sup>&reg;</sup> for Business (the "Service") offers a secure, digital document sharing facility (a "vault"). The Service is operated by Fidelity Wealth Technologies LLC, a Fidelity affiliate, is made available to you as a Fidelity customer at no additional charge, and is subject to certain limitations and
                restrictions. Note that your Fidelity log-in credentials provide access to client vault via Fidelity.com.
                However, the {this.generateInlineLink('openPolicy', 'Privacy Policy')} and {this.generateInlineLink('openTerms', 'Terms of Service ')}
                that govern your use of client vault differ from those applicable to your experience on Fidelity.com. Review carefully prior to use.
            </div>
        </>
    }

    renderContent() {

        let title = 'Please verify your account';
        let description = 'To verify and activate your account, please enter your first and last name, as well as the verification code sent to your email on file.';
        let buttonLabel = 'VERIFY';
        return <>
            {this.renderLoginContent(title, description, buttonLabel)}
            {this.renderLinkAndTos()}
            {this.renderSnackBar()}
        </>
    }

    renderLoginContent(title, description, buttonLabel) {
        const { classes } = this.props;
        return <div className={classes.loginContent}>
            <div className={classes.subtitles}>
                <Typography variant='h5' className={classes.subtitle}>{title}</Typography>
                <Typography variant='subtitle1' className={classes.description}>{description}</Typography>
            </div>
            <div className={classes.namingWrapper}>
                <div className={classes.nameInput}>
                    <TextInput
                        isFocus={true}
                        fieldType="password"
                        id="Last 4 Digits of SSN"
                        length="4"
                        validateRegex="^[0-9]{4}$"
                        handleInPutChange={this.handleSSNInputChange}
                        assistiveText="Please use numbers only.">
                    </TextInput>
                </div>
                <div className={classes.nameInput}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            variant="inline"
                            autoOk
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={this.state.dob ? this.state.dob : null}
                            label="Date of Birth"
                            onChange={this.handleDOBInputChange}
                            disableFuture
                            views={["year", "month", "date"]}
                            openTo="year"
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <Checkbox color='primary'
                checked={this.state.hasAgreed}
                onChange={this.agreeTerms} />
                I agree to the {this.generateInlineLinkForCheckBox('openTerms', 'Terms of Service')}
            <div className={classes.actionWrapper}>
                <Fab data-test='verifyUserBtn' variant='extended' disabled={!this.state.ssn || !/^[0-9]{4}$/.test(this.state.ssn) || !this.state.dob || isNaN(new Date(this.state.dob).getDate()) || !this.state.hasAgreed} type='submit'
                    color='primary' name='login-btn' className={classes.button} onClick={this.verify}>
                    {buttonLabel}
                </Fab>
            </div>
        </div>
    }

    render() {
        const { classes, inviteVerificationResp, callAuthCheck } = this.props;
        if (inviteVerificationResp && inviteVerificationResp.data.verified) {
            callAuthCheck();
            return <Redirect to="/customer" />;
        }

        return (
            <div className={classes.root}>

                <TermsOfService
                    title='Terms of Service'
                    data-test="termsOfService"
                    openModal={this.state.openTerms}
                    showAction={false}
                    handleCloseCallback={() => { this.handleCloseModal('openTerms') }} />

                <div className={classes.sideBar} />
                <main className={classes.main}>
                    {this.renderContent()}
                </main>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.login,
        error: state.inviteVerificationReducer.error,
        inviteVerificationResp: state.inviteVerificationReducer.inviteVerificationInfo.response,
        inviteVerificationChecking: state.inviteVerificationReducer.inviteVerificationInfo.fetching,
        legalsError: state.legalsReducer.error,
        trmsOfSvcContent: state.legalsReducer.trmsOfService.content,
        trmsOfSvcfetching: state.legalsReducer.trmsOfService.fetching,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        callAuthCheck: () => dispatch({ type: LOGIN_REQUESTING }),
        verifyUser: (dob, ssn) => dispatch({ type: INVITE_VERIFICATION_REQUEST, dob, ssn }),
        fetchTrmsOfSvcContent: () => dispatch({ type: FETCH_TRM_OF_SVC_REQUEST }),
        clearVerificationError: () => dispatch({ type: INVITE_VERIFICATION_ERROR_CLEAR })
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InviteVerification)));