import React, { Component } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

type passwordProps = {
    guptshabd: any;
    handlePasswordChange: any;
    validpassword: any;
    isValidPassword: any;
    id: any;
    helperText: string;
    size?: string;
    onSubmit?: any
}

class Password extends Component<passwordProps> {

    state =
        {
            showPassword: false
        }

    handleShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    render() {
        return (
            <TextField data-test='password'
                id={this.props.id}
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.props.guptshabd}
                onChange={event => this.props.handlePasswordChange(event, this.props.id)}
                variant="outlined"
                label="Password"
                size={(this.props.size != null && this.props.size === 'medium') ? 'medium' : 'small'}
                color="primary"
                autoComplete="off"
                fullWidth={true}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        if (this.props.onSubmit != null)
                            this.props.onSubmit()
                    }
                }}
                error={!this.props.validpassword}
                helperText={!this.props.validpassword ? this.props.helperText : ''}
                onBlur={() => this.props.isValidPassword()}
                InputProps={{
                    endAdornment: <InputAdornment position="end" style={{ marginRight: '12px' }}>
                        <IconButton data-test='showPWBtn'
                            aria-label="toggle password visibility"
                            onClick={() => this.handleShowPassword()}
                            edge="end"
                        >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }}
            />
        )
    }
}


export default Password;