import React from 'react';
import PropTypes from 'prop-types';
import ErrorSnackBar from './ErrorSnackBar';
import ErrorModal from './ErrorModal';
import ErrorPage from './ErrorPage';
import ErrorDetails from './ErrorDetails';
import { UNABLE_TO_DELETE_FILES, UNABLE_TO_DOWNLOAD_FILES, FETCH_TRM_OF_SVC_ERROR, FETCH_PRI_POL_ERROR, FETCH_THEME_S3_FAILUIRE } from './ErrorActions'
import { optionHandle } from '../core/utils/helper';

class Error extends React.Component {

    handleCloseErrorDisplay = () => {
        this.props.handleCloseErrorDisplay();
    }

    handleLogout = () => {
        optionHandle('Log Out')

    }

    renderErrorPage = (error, loggedIn) => {

        return (
            <ErrorPage
                error={error}
                loggedIn={loggedIn}
            />
        );
    }

    renderUnkownError = (error) => {

        return (
            //TODO
            <div>Unknown Error</div>
        );
    }

    renderErrorSnackbar = (error) => {

        return (
            <ErrorSnackBar
                error={error}
                handleCloseErrorDisplay={this.props.handleCloseErrorDisplay}
            />
        );
    }

    renderErrorDetails = (error) => {
        return (
            <ErrorDetails
                error={error}
                handleCloseErrorDisplay={this.props.handleCloseErrorDisplay}
                handleLogout={this.handleLogout}
            />
        );
    }

    renderErrorModal = (error) => {

        return (
            <ErrorModal
                error={error}
                handleCloseErrorDisplay={this.props.handleCloseErrorDisplay}
                retryAction={this.props.retryAction}
            />
        );
    }

    render() {

        const { error } = this.props;

        const statusArray = [401, 403, 404, 501, 500];
        if (error && error.response) {

            if (statusArray.includes(error.response.status)) {
                if (error.response.status === 401 && (error.response.data.errMsg === 'Missing Authorization Token.' || error.response.data.errMsg === 'Session Expired. Please login again.')) {
                    error.response.data.errMsg = 'Session Expired. Please login again.'
                    return this.renderErrorDetails(error);
                } else {
                    error.response.statusText = ' We encountered an error while performing this action.'
                    return this.renderErrorDetails(error);
                }
                // return this.renderErrorPage(error, loggedIn);
            } else if (error.action) {
                switch (error.action) {
                    case UNABLE_TO_DOWNLOAD_FILES:
                        switch (error.response.data.errCode) {
                            case 614:
                                return this.renderErrorSnackbar(error);
                            case 613:
                                return this.renderErrorDetails(error);
                            default:
                                return this.renderErrorModal(error);
                        }
                    case UNABLE_TO_DELETE_FILES:
                        return this.renderErrorModal(error);
                    case FETCH_TRM_OF_SVC_ERROR:
                        return this.renderErrorSnackbar(error);
                    case FETCH_PRI_POL_ERROR:
                        return this.renderErrorSnackbar(error);
                    case FETCH_THEME_S3_FAILUIRE:
                        return this.renderErrorPage(error);
                    default:
                        return this.renderErrorSnackbar(error);
                }
            }
            else {
                return this.renderErrorSnackbar(error);
            }

        } else {
            return this.renderUnkownError();
        }
    }
}

Error.propTypes = {
    error: PropTypes.object.isRequired,
};

export default Error;