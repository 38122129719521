const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.homerootbgcolor,
        minHeight: '100vh',
        color: '#444657',
        fontFamily:theme.homefontfamilyregular,
    },
    sideBar: {
        width: 64,
        height: '100vh',
        backgroundColor: theme.actionbarbgcolor,
        position: 'absolute',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: theme.spacing(2),
            top: 0
        },
    },
    main: {
        padding: '2rem 0 2rem 7rem',
        [theme.breakpoints.down('sm')]: {
            padding: '2rem 0 0 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#fff'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 0 1rem 0',
        },
    },
    // fidelityLogo: {
    //     width: 138,
    //     height: 31,
    //     margin: '17px 0 25px -10px',
    //     [theme.breakpoints.down('sm')]: {
    //         marginTop: '2rem',
    //         marginBottom: '2rem',
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         margin: '2rem 1rem',
    //     },
    // },
    loginContent: {
        width: 416,
        backgroundColor: '#fff',
        borderRadius: 6,
        fontFamily:theme.homefontfamilyregular,
        boxShadow: '0 0 8px 0 #F2F2F2',
        [theme.breakpoints.down('sm')]: {
            width: 470,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
        },
        marginBottom: '15px'
    },


    subtitles: {
        padding: '2rem',
    },
    subtitle: {
        padding: '0 0 16px 0',
        fontWeight: 'lighter',
         fontFamily: theme.homefontfamilyregular,
        letterSpacing: 0.2,
        lineHeight: 1.2,
        fontSize: theme.spacing(3) + 1,
    },
    description: {
        fontSize: 16,
        fontFamily: theme.homefontfamilyregular,
        letterSpacing: 0.5,
        maxWidth: 352,
        // maxHeight: 56,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
            maxHeight: 'none',
        },
    },
    row: {
        display: 'flex',
        padding: '0 32px 32px 32px',
        // justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    loginBtn: {
        borderColor: '#568200',
        color: '#568200',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    loginBtnIcon: {
        width: '28px',
        margin: '.5rem 1.5rem .5rem 0'
    },
    button: {
        width: 186,
        color: '#FFFFFF',
        fontWeight: 'normal',
        letterSpacing: 1.25,
        borderRadius:5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
          },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
          },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
          },
        textTransform: 'none',
        fontSize: 14,
        backgroundColor:theme.loginbuttonbgcolor,
        
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    statement: {
        marginTop: '1.5rem',
        width: 416,
        fontSize: 12,
        fontFamily: theme.homefontfamilyregular,
        lineHeight: 1.4,
        color: theme.loginstatementcolor,
        [theme.breakpoints.down('sm')]: {
            width: 470,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(100vw - 2rem)',
            height: 'auto',
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
    },
    cognitoLogincontainer:
    {
        paddingLeft: '2rem',
        paddingRight: '2rem',
        paddingBottom: '1rem',
    },
    externalCustomertext:{
        paddingLeft: '0.5rem'
    },
    loadingtext:
    {
        margin: theme.spacing(2),
        fontSize: '0.9rem'
    },
    loaderholder:
    {
        top: 0,
        height: "100%",
        width: "100%",
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.1)',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader:
    {
        zIndex: 999,
        display: 'flex',
        flexDirection:'column',
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center'
    },
    NotaFidelityCustomer:
    {
        paddingLeft: '2rem',
        paddingBottom: '1rem',
        color:'#0E67A9',
        display: 'flex',
    },
    link: {
        color: '#005F7C',
        cursor: 'pointer',
        fontSize: '12px'
    },
    centerText: {
        display: 'flex',
        alignItems: 'center',
    },
    advisorBox: {
        width: 416,
        height: 56,
        marginTop: '1rem',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 470,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    advisorBoxContent: {
        padding: '0 2rem',
        fontWeight: 'lighter',
        width: '100%',
        fontFamily: theme.homefontfamilyregular,
        letterSpacing: 0.2,
        lineHeight: 1.2,
        fontSize: theme.spacing(3) + 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
    subtitle2: {
        color: 'rgba(0,0,0,0.87)',
        fontFamily: 'Fidelity Sans',
        fontSize: 16,
        fontWeight: '500',
        letterSpacing: 0.15,
    },
    loginLink: {
        color:theme.loginlinkcolor,
        fontWeight: 'normal',
        fontFamily: theme.homefontfamilyregular,
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 1.7,
    },
    errMsg: {
        color: 'red',
        fontSize:  '12px',
        marginTop: '1rem',
    },
    errorMessage: {
        color: 'red',
        fontSize:  '13px'
    }
});

export default styles; 