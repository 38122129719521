import IndexReducer from "./redux/reducers/index-reducer";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import IndexSagas from './redux/sagas/index-sagas';
import { history } from './shared/core/history';
import { routerMiddleware } from 'react-router-redux'; 

// dev tools middleware 
const composeSetup =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

// create the saga middleware 
const sagaMiddleware = createSagaMiddleware();

const routersMiddleware = routerMiddleware(history);

var store = createStore(
    IndexReducer,
    composeSetup(applyMiddleware(sagaMiddleware, routersMiddleware)) // allows redux devtools to watch sagas 
);
// run the saga 
sagaMiddleware.run(IndexSagas);

export default store;