import React, { Component } from 'react';
import componentLookUp from '../../../shared/core/components.map';

type FsContactsPagePropsType = {
    components: string[]
}

type FsContactsPageState = {
    isLoaded: boolean,
    error: null,
    data: any
}

class FsContactsPage extends Component<FsContactsPagePropsType, FsContactsPageState> {
    render() {
        return(
            <>
                { this.props.components.map( (comp, index) => 
                    <div key={index}>{ componentLookUp(comp) }</div>
                )}
            </>
        )
    }
}

export default FsContactsPage;