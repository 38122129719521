import {
    FETCH_FEATURE_REQUEST,
    FETCH_FEATURE_SUCCESS,
    FETCH_FEATURE_FAILURE
} from '../../constants/shared/featureConstant';

import { takeLatest, call, put } from "redux-saga/effects";
import { getFeatureConfig } from '../../../shared/core/utils/apiFunctions';

function* featureWatcher(){
    yield takeLatest(FETCH_FEATURE_REQUEST, getFeatureFromS3);
}

function* getFeatureFromS3(){
    try {
        const result = yield call(getFeatureConfig);

        if (result && result.data) {
            yield put({ type: FETCH_FEATURE_SUCCESS, responseInfo: result });
        }
    } catch (error) {
        yield put({ type: FETCH_FEATURE_FAILURE, error: error });
    }
}

export default featureWatcher;
export { getFeatureFromS3 };
