// Get All the users assoiated to the Group
export const USER_API_GET_USERS_REQUEST = 'USER_API_GET_USERS_REQUEST';
export const USER_API_GET_USERS_SUCCESS = 'USER_API_GET_USERS_SUCCESS';
export const USER_API_GET_USERS_FAILURE = 'USER_API_GET_USERS_FAILURE';

// Lazyload more users assoiated to the Group
export const USER_API_LOAD_MORE_USERS_START = 'USER_API_LOAD_MORE_USERS_START';
export const USER_API_LOAD_MORE_USERS_REQUEST = 'USER_API_LOAD_MORE_USERS_REQUEST';
export const USER_API_LOAD_MORE_USERS_SUCCESS = 'USER_API_LOAD_MORE_USERS_SUCCESS';
export const USER_API_LOAD_MORE_USERS_FAILURE = 'USER_API_LOAD_MORE_USERS_FAILURE';

//Get all the user with search results.
export const USER_API_CONTACT_SEARCH_REQUEST = "USER_API_CONTACT_SEARCH_REQUEST"
export const USER_API_CONTACT_SEARCH_SUCCESS = "USER_API_CONTACT_SEARCH_SUCCESS"
export const USER_API_CONTACT_SEARCH_FAILURE = "USER_API_CONTACT_SEARCH_FAILURE"

//Get the details of specific user.
export const USER_API_GET_USER_REQUEST="USER_API_GET_USER_REQUEST"
export const USER_API_GET_USER_SUCCESS="USER_API_GET_USER_SUCCESS"
export const USER_API_GET_USER_FAILURE="USER_API_GET_USER_FAILURE"

//Clean Search Contact
export const USER_API_CLEAR_SEARCH_REQUEST = "USER_API_CLEAR_SEARCH_REQUEST"