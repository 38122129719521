import {
    FETCH_FEATURE_REQUEST,
    FETCH_FEATURE_SUCCESS,
    FETCH_FEATURE_FAILURE
} from '../../constants/shared/featureConstant';

const initialState = {
    fetching: false,
    response: null,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FEATURE_REQUEST:
            return { ...state, fetching: true, response: null, error: null }
        case FETCH_FEATURE_SUCCESS:
            return { ...state, fetching: false, response: action.responseInfo.data }
        case FETCH_FEATURE_FAILURE:
            return { ...state, fetching: false, error: action.error }
        default:
            return state;
    }
}