import {
    GET_PAGE_COMPONENTS,
} from '../../constants/shared/pageComponentsConstants';



const initialState = {
    fetching: false,
    pages: [
        {
            name: 'pv-myfiles',
            components: [
                'myFilesListView'
            ]
        }, {
            name: 'pv-notifications',
            components: [
                'notificationListView'
            ]
        }, {
            name: 'pv-recents',
            components: [
                'recentsListView'
            ]
        }, {
            name: 'pv-shared',
            components: [
                'sharedListView'
            ]
        }, {
            name: 'pv-contacts',
            components: [
                'invitationPanel',
                'contactListView'
            ]
        }, {
            name: 'pv-contacts-detail',
            components: [
            ]
        }, {
            name: 'pv-contacts-groups-detail',
            components: [
            ]
        }, {
            name: 'cv-contacts',
            components: []
        }, {
            name: 'cv-contact-detail',
            components: []
        }, {
            name: 'cv-topics',
            components: []
        }, {
            name: 'cv-topic-detail',
            components: []
        }, {
            name: 'file-detail',
            components: []
        }, {
            name: 'waiting-page',
            components: []
        }
    ],
    error: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PAGE_COMPONENTS:
            return { 
                ...state, 
            }
        default:
            return state;
    }
}