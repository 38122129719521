

import React, { Component, CSSProperties } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Types from '../../../../shared/core/ContactType';
import CancelIcon from '@material-ui/icons/Cancel';
import * as shareContactActionTypes from '../../../../redux/constants/personalVault/shareContactConstants';
import { connect } from 'react-redux';
import SharePermission from './SharePermission';

type ContactType = {
  contactCode: string,
  contactName: string,
  permissions: string,
}

type ContactShareTableProps = {
  classes: any;
  islarge: boolean;
  removeContact: any;
  editPermissions: any;
  contactsSelected: any
}

type ContactShareTableState = {
  contacts: Types.ContactType[];
}

const columns = [
  { id: "contactName", label: 'Recipients', width: '60%', mobilewidth: "40%", alignment: 'left' },
  { id: "permissions", label: 'Permissions', width: '32%', mobilewidth: "55%", alignment: 'left' },
  { id: 2, label: '', width: '8%', mobilewidth: "5%" }
];



export const styles = (theme: any) => ({
  tablecell: {
    padding: theme.spacing(1.5),
  },
  cancelBtn: {
    padding: 0
  },
  tableContainer:
  {
    height: 'inherit'
  }
});


class ContactShareTable extends Component<ContactShareTableProps, ContactShareTableState> {

  componentDidMount() {
    this.intialState();
  }

  intialState() {
    this.setState({
      contacts: []
    });
  }

  removeContact = (selectedContact: Types.ContactType) => {
    if (selectedContact) {
      this.props.removeContact(selectedContact);
    }
  }

  handlePermissionChange = (selectedContactId: string, value: any) => {
    const temp = this.props.contactsSelected.find((I: Types.ContactType) => I.code === selectedContactId)
    if (temp)
      this.props.editPermissions(selectedContactId, value)
  }


  render() {
    const { classes, contactsSelected, islarge } = this.props;
    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  classes={{ root: classes.tablecell }}
                  key={column.id}
                  style={Object.assign(
                    {},
                    (islarge) ? { width: column.width } : { width: column.mobilewidth },
                    {textAlign: column.alignment}
                  ) as CSSProperties}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contactsSelected.map((row: Types.ContactType) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={`row-${row.code}`}>
                  <TableCell classes={{ root: classes.tablecell }} key={`column1-${row.code}`}>
                    {row.name}
                  </TableCell>
                  <TableCell classes={{ root: classes.tablecell }} key={`column2-${row.code}`}>
                    <SharePermission selectedContactId={row.code} handlePermissionChange={this.handlePermissionChange}
                      defaultPermission={row.permission}></SharePermission>
                  </TableCell>
                  <TableCell classes={{ root: classes.tablecell }} key={`column3-${row.code}`}>
                    <IconButton key={`btn-${row.code}`} className={classes.cancelBtn} onClick={this.removeContact.bind(this, row)} >
                      <CancelIcon fontSize="small"></CancelIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    contactsSelected: state.shareContactReducer.shareContacts,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeContact: (selectedItem: Types.ContactType) => dispatch({
      type: shareContactActionTypes.REMOVE_SHARE_CONTACT,
      data: selectedItem
    }),
    editPermissions: (selectedItemId: string, value: string) => dispatch({
      type: shareContactActionTypes.EDIT_SHARE_CONTACT_PERMISSION,
      selectedItemId: selectedItemId,
      value: value
    }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactShareTable));
