import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';

const variants: any = {
    default: { icon: <InfoIcon style={{ fontSize: '1.5rem' }} /> },
    information: { color: '#6792AF', icon: <InfoIcon style={{ fontSize: '1.5rem' }} /> },
    error: { color: 'red', icon: <WarningIcon style={{ fontSize: '1.5rem' }} /> },
    warning: { color: '#FFCD00', icon: <ErrorIcon style={{ fontSize: '1.5rem' }} /> }
}

function validateVariantType(variant: any) {
    return ['information', 'warning', 'error'].includes(variant);
}

type TopMessageBarProps = {
    variant: any,
    onClose: any,
    children: any
}

const useStyles = makeStyles((theme: any) => ({
    root: (props: TopMessageBarProps) => ({
        margin: '0.5rem 0',
        padding: 0,
        backgroundColor: '#fff',
        border: `2px solid ${validateVariantType(props.variant) ? variants[props.variant].color : theme.topmessagebordercolor}`,
        color: 'black',
        display: 'flex',
        fontSize: 14,
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '0.5rem',
            // marginRight: '0.5rem',
            // fontSize: 12,
        },
        "& a": {
            fontSize: 14,
        }
    }),
    icon: (props: TopMessageBarProps) => ({
        backgroundColor: validateVariantType(props.variant) ? variants[props.variant].color : theme.topmessageiconbgcolor,
        color: 'black',
        marginRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0.25rem 0.5rem',
        fontSize: '1.5rem',
    }),
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    floatRight: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    iconButton: (props: TopMessageBarProps) => ({
        color: validateVariantType(props.variant) ? 'grey' : theme.topmessageiconbuttoncolor,
        padding: '0.5rem',
        marginRight: '0.5rem',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        }
    }),
}));

const TopMessageBar = function (props: TopMessageBarProps) {

    const classes = useStyles(props);
    const [state, setState] = React.useState({
        open: true,
    });

    const handleClose = () => {
        setState({ open: false });
        props.onClose();
    }

    const icon = validateVariantType(props.variant) ? variants[props.variant].icon : variants['default'].icon;

    return <Collapse in={state.open} data-test='topMsgBarComp'>
        <div className={classes.root}>
            <div className={classes.icon}>
                {icon}
            </div>
            <div className={classes.message}>
                {props.children}
            </div>
            <div className={classes.floatRight}>
                <IconButton data-test='closeBtn' key="close" aria-label="close" color="inherit" className={classes.iconButton} onClick={handleClose}>
                    <CloseIcon style={{ fontSize: '1.5rem' }} />
                </IconButton>
            </div>
        </div>
    </Collapse>
}

export default TopMessageBar;
