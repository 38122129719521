import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as TopicFileConstants from '../../../redux/constants/customerVault/topicFileConstants'
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import * as GAConstants from '../../../shared/core/GAConstants';
import ReactGA from 'react-ga';

type DownloadItemsProps = {
    isDownloadIntiated: any;
    resetDownload: any;
    topicCode: any;
    downloadFile: any;
    downloadMultipleFiles: any;
    onActionComplete: any;
    classes: any;
    selectedItems: any[];
    children?: any;
    updateSnackbar: any;
    downloadFiles: any[];
    deleteDownloadFile: any;
    isEndUser: any;
}

export const styles = (theme: any) => ({
    successDownloadPaper: {
        position: 'absolute' as 'absolute',
        minWidth: '320px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
    },
    successDownloadMessage: {
        fontFamily: 'Fidelity Sans',
        fontSize: '18px',
        color: '#000000',
        justifyContent: 'center',
        textAlign: 'center' as 'center',
        marginBottom: '40px'
    },
    iconHeading: {
        justifyContent: 'space-between',
        fontSize: '17px',
        width: '100%',
    },
    iconSection: {
        textAlign: 'center' as 'center',
        color: 'green'
    },
    sucessIcon: {
        fontSize: '40px'
    },
});

class DownloadItems extends Component<DownloadItemsProps>{

    state = {
        snackBarOpen: false,
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: any) {

        const { isDownloadIntiated, resetDownload, deleteDownloadFile } = this.props;
        if (prevProps.isDownloadIntiated != isDownloadIntiated && isDownloadIntiated) {
            this.init();
            resetDownload();
        }
        const { downloadFiles } = this.props;

        let anyDownloadHapenning = false
        let anyDownloadError = false
        let errorMsg = "Downloading files failed.Please try again."

        if (prevProps.downloadFiles !== downloadFiles) {
            //Check if any of the download file is in progress.
            if (downloadFiles && downloadFiles.length > 0) {
                downloadFiles.forEach((element: any) => {
                    if (element.downloading)
                        anyDownloadHapenning = true;
                    else if (element.error != null) {
                        anyDownloadError = true;
                        errorMsg = element.error.errMsg != null ? element.error.errMsg : 'Something went wrong. Please try again.';
                        //delete the item from the array
                        deleteDownloadFile(element.downloadId);
                    }
                    else {
                        var category = '';
                        if (this.props.isEndUser)
                            category = GAConstants.USER_EVENT_CATEGORY;
                        else
                            category = GAConstants.ASSOCIATE_EVENT_CATEGORY;
                        ReactGA.event({
                            category: category,
                            action: GAConstants.FILE_DOWNLOADED,
                        });
                        //delete the item from the array
                        deleteDownloadFile(element.downloadId);
                    }
                });
                if (anyDownloadHapenning) {
                    this.props.updateSnackbar({
                        modalOpen: true,
                        autoHideDisable: true,
                        content: "Downloading file(s) is in progress..."
                    })
                }
                else {
                    if (anyDownloadError) {
                        this.props.onActionComplete(false, errorMsg);
                    }
                    else
                        this.props.onActionComplete(true, "Download successful.");
                }
            }
        }
    }

    init() {
        const { selectedItems, downloadFile, downloadMultipleFiles, topicCode } = this.props;
        if (selectedItems && selectedItems.length === 1) {
            if (selectedItems[0].signedItem) {
                if (selectedItems[0].signedItem.code && selectedItems[0].ceremonyStatus === 'AGREED')
                    downloadFile(selectedItems[0].signedItem.code, topicCode, selectedItems[0].itemName, Math.floor(Math.random() * 1000));
                else
                    downloadFile(selectedItems[0].itemCode, topicCode, selectedItems[0].itemName, Math.floor(Math.random() * 1000));
            }
            else
                downloadFile(selectedItems[0].itemCode, topicCode, selectedItems[0].itemName, Math.floor(Math.random() * 1000));
        } else if (selectedItems.length > 1) {
            let tmp_itemCodes: string[] = [];
            selectedItems.forEach((item: any) => {
                if (item.signedItem) {
                    if (item.signedItem.code && item.ceremonyStatus === 'AGREED')
                        tmp_itemCodes.push(item.signedItem.code);
                    else
                        tmp_itemCodes.push(item.itemCode);
                }
                else
                    tmp_itemCodes.push(item.itemCode);
            })
            downloadMultipleFiles(tmp_itemCodes, topicCode, "Download", Math.floor(Math.random() * 1000));
        }
    }

    handleSnackbarClose = () => {
        this.setState({ snackBarOpen: false })
    }


    render() {
        return (
            <> </>
            /*  <Snackbar
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 open={this.state.snackBarOpen}
                 onClose={this.handleSnackbarClose}
                 message={"Downloading Files is in progress..."}
                 action={
                     <React.Fragment>
                         <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                             <CloseIcon fontSize="small" />
                         </IconButton>
                     </React.Fragment>
                 }
             /> */
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        downloadFiles: state.topicFilesReducer.downloadInfo.files,
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        downloadFile: (itemCode: any, topicCode: any, fileName: any, downloadId: any) => dispatch({
            type: TopicFileConstants.TOPIC_FILES_API_SINGLE_FILE_DOWNLOAD_REQUEST,
            itemCode: itemCode,
            topicCode: topicCode,
            fileName: fileName,
            downloadId: downloadId
        }),
        downloadMultipleFiles: (itemCodes: any, topicCode: any, fileName: any, downloadId: any) => dispatch({
            type: TopicFileConstants.TOPIC_FILES_API_MULTIPLE_FILE_DOWNLOAD_REQUEST,
            itemCodes: itemCodes,
            topicCode: topicCode,
            fileName: fileName,
            downloadId: downloadId
        }),
        deleteDownloadFile: (downloadId: any) => dispatch({
            type: TopicFileConstants.TOPIC_FILES_API_FILE_DOWNLOAD_DELETE,
            downloadId: downloadId
        }),
        updateSnackbar: (snackbarVal: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_SNACKBAR,
            snackbar: snackbarVal
        })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DownloadItems));

