import React from 'react';

const AddFolder = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st8{stroke:${props.stroke}}`}
        </style>
        <g id="files-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="Files-Folders-/-Add-Folder-Black" className="st8">
            <g id="Group">
                <g id="folder-add" strokeWidth="1.5">
                    <line x1="8.25" y1="18.75" x2="2.447" y2="18.75" id="Shape"></line>
                    <path d="M20.25,8.25 L20.25,5.25 C20.25,4.42157288 19.5784271,3.75 18.75,3.75 L8.25,3.75 L8.25,2.25 C8.25,1.42157288 7.57842712,0.75 6.75,0.75 L2.25,0.75 C1.42157288,0.75 0.75,1.42157288 0.75,2.25 L0.75,17.05 C0.761667745,17.8943312 1.39130907,18.6021206 2.22853556,18.7120442 C3.06576205,18.8219678 3.85677202,18.3007029 4.086,17.488 L6.437,9.334 C6.62240297,8.69170281 7.2104794,8.24962595 7.879,8.25 L21.75,8.25 C22.2160199,8.2498397 22.6556436,8.46628712 22.9396863,8.83573851 C23.223729,9.20518991 23.3199225,9.68567436 23.2,10.136" id="Shape"></path>
                    <circle id="Oval" cx="17.25" cy="17.25" r="6"></circle>
                    <line x1="17.25" y1="14.25" x2="17.25" y2="20.25" id="Shape"></line>
                    <line x1="14.25" y1="17.25" x2="20.25" y2="17.25" id="Shape"></line>
                </g>
            </g>
        </g>
    </g>
    </svg>
}
export default AddFolder