export const styles = theme => ({
    appBar: {
      backgroundColor: 'white',
      color: 'black',
      height: 64,
      fontFamily:theme.homefontfamilyregular,
  
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      justifyContent: 'flex-start',
      backgroundColor: 'inherit',
      height: 40,
      fontFamily:theme.homefontfamilyregular,
  
    },
    logoHeader: {
      letterSpacing: 5,
      fontSize: 11,
      fontFamily:theme.homefontfamilyregular,
  
    },
  
    logoIcon: {
      width: 40,
      height: 40,
      marginRight: 10
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 20,
      fontFamily:theme.homefontfamilyregular,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    content: {
      flexGrow: 1,
      overFlow:'hidden',
    },

    errPaper:{
      height:'30vh',
      margin:10,
      backgroundColor:'#ffffff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
  