export const styles = (theme) => ({
    breadcrumbWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    listyle: {
        listStyle: 'none',
        color: '#000',
        border: '1px',
        paddingLeft: '3px',
        borderRadius: '5px',
        paddingRight: '3px',
        maxWidth: '250px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    listhover :
    {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#d3d4d5 !important'
        }
    },
    astyle:
    {
        color: '#444557',
        height: '30px',
        width: '273.24px',
        fontFamily: theme.homefontfamilyregular,
        fontSize: '.875rem',
        letterSpacing: '0.17px',
        lineHeight: '30px',
    },
    selectedastyle:
    {
        color: '#1C1E28',
    },
    olstyle:
    {
        color: '#444557',
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: theme.homefontfamilyregular,
        fontSize: '.875rem',
        alignItems: 'center',
        fontWeight: 400,
        margin: 0,
        padding: 0,
        letterSpacing: '0.17px',
    },
    seperator:
    {
        paddingTop: "4px"
    }
});