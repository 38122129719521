import React from 'react';

const CreatePassword = (props) => {
    return <svg width="24px" height="24px" >
        <style>{`.st11{stroke:${props.stroke}}`}
            {`.st10{stroke:${props.stroke}}`}
        </style>
        <g id="files-4" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Lock/Unlock-/-Lock" transform="translate(-3.000000, 0.000000)" class="st11">
            <g id="Group">
                <g id="lock-1" stroke-width="1.5">
                    <rect id="Rectangle-path" x="3.75" y="9.75" width="16.5" height="13.5" rx="1.5"></rect>
                    <path d="M6.75,9.75 L6.75,6 C6.75,3.10050506 9.10050506,0.75 12,0.75 C14.8994949,0.75 17.25,3.10050506 17.25,6 L17.25,9.75" id="Shape"></path>
                    <line x1="12" y1="15" x2="12" y2="18" id="Shape"></line>
                </g>
            </g>
        </g>
    </g>
    </svg >
}
export default CreatePassword