import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@material-ui/core';
import * as TopicFileConstants from '../../../redux/constants/customerVault/topicFileConstants'

type DeleteItemsProps = {
    onActionComplete: any,
    handleModalClose: any,
    selectedItems: any,
    topicCode: any;
    deleteItemError: any,
    deleteItemProgress: any,
    deleteItemAction: any,
    children?: any;
}

class DeleteItems extends Component<DeleteItemsProps> {

    content: any = {
        iconLink: '',
        title: 'Confirm Delete',
        body: 'Are you sure you want to delete the selected Item(s) ?',
        buttonOneLabel: 'Cancel',
        buttonTwoLabel: 'Delete'
    }

    componentDidUpdate(prevProps: any) {
        const { deleteItemProgress, deleteItemError } = this.props;
        if (deleteItemProgress !== prevProps.deleteItemProgress && !deleteItemProgress) {
            if (deleteItemError === null)
                this.props.onActionComplete(true, 'Your selected item(s) has been deleted.');
            else
                this.props.onActionComplete(false, 'Your selected item(s) not deleted. Please try again.');
            this.props.handleModalClose();
        }
    }

    handleDeleteFiles = () => {
        const itemCodes = this.props.selectedItems.map((i: any) => i.itemCode);
        this.props.deleteItemAction(this.props.topicCode, itemCodes)
    }

    render() {
        return (
            <>
                <DialogTitle id="common-modal-title" className="common-modal-title" data-test='deleteItemModalTitle'>
                    <span className="dialog-title">{this.content.title}</span>
                </DialogTitle>
                <DialogContent className="common-modal-description-root">
                    <DialogContentText className="common-modal-description" id="common-modal-description">
                        <span className="description-content">{this.content.body}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="common-modal-actions">
                    <Link className='contact-link-cancel' href="#" onClick={this.props.handleModalClose} id="cancel">
                        {this.content.buttonOneLabel}
                    </Link>
                    <Button className="common-modal-button" data-test="btnTwo" variant="contained" onClick={this.handleDeleteFiles}
                        disabled={this.props.deleteItemProgress} color="primary" disableElevation>
                        {this.content.buttonTwoLabel}
                    </Button>
                </DialogActions>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        deleteItemProgress: state.topicFilesReducer.deleteInfo.fetching,
        deleteItemError: state.topicFilesReducer.deleteInfo.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteItemAction: (topicCode: any, itemCodes: any) =>
            dispatch({
                type: TopicFileConstants.TOPIC_FILES_API_DELETE_FILE_REQUEST,
                topicCode: topicCode, itemCodes: itemCodes
            }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeleteItems);

