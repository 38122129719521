import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Hidden, IconButton } from '@material-ui/core';
import DoneIcon  from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';

interface InvitedContact {
    contactCode: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    modifiedAt: string;
}

type CommonCollapseItemPropsType = {
    data: InvitedContact,
    btnOneOnClick: any,
    btnTwoOnClick: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      borderBottom: '1px solid #e8e8e8'
    },
    initAvatar: {
        backgroundColor: '#4A4A4A',
        color: 'white',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.spacing(5),
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nameContainer: {
        margin: '0 .8em',
        width: '40%'
    },
    contactName: {
        margin: 0,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: '0.5px'
    },
    contactEmail: {
        margin: 0,
        fontSize: theme.typography.pxToRem(13),
        letterSpacing: '0.1px',
        color: '#353636'
    },
    dateContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(13)
    },
    btnGroups: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    actionBtn: {
        marginLeft: '2em',
        textTransform: 'capitalize',
        padding: '8px 35px'
    },
    fabBtn: {
        border: '1px solid',
        marginLeft: '.5em'
    }
  }),
);

const CommonCollapseItem = (props: CommonCollapseItemPropsType ) => {
    const classes = useStyles();
    const content: any = {
        buttonOneLabel: 'Ignore',
        buttonTwoLabel: 'Accept'
    }

    return (
        <div className={ classes.root } data-test='commonCollapseItem'>
            <div className={ classes.initAvatar } >
                { props.data.contactFirstName.charAt(0).toUpperCase() }
                { props.data.contactLastName.charAt(0).toUpperCase() }
            </div>
            <div className={ classes.nameContainer }>
                <p className={ classes.contactName }>{ props.data.contactFirstName } { props.data.contactLastName }</p>
                <p className={ classes.contactEmail }>{ props.data.contactEmail }</p>
            </div>
            <div className={ classes.dateContainer }>
                <Hidden mdDown>
                    {/* <Moment format="MMM DD, YYYY HH:mm A">{ props.data.modifiedAt }</Moment> */}
                    <span>{ moment.utc((props.data.modifiedAt)).local().format('MMM DD, YYYY HH:mm A') }</span>
                </Hidden>
            </div>
            <div className={ classes.btnGroups }>   
                <Hidden lgUp>
                    <IconButton 
                        aria-label="ignore" 
                        data-test='ignoreButton'
                        className={ classes.fabBtn } 
                        onClick={ () => props.btnOneOnClick( props.data.contactCode ) }>
                        <CloseIcon/>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <Button className={classes.actionBtn} data-test='mdDown' size="large" onClick={ () => props.btnOneOnClick( props.data.contactCode ) }>
                        { content.buttonOneLabel }
                    </Button>
                </Hidden>
                <Hidden lgUp>
                    <IconButton 
                        aria-label="accept"
                        color="primary"
                        data-test='primaryBtn'
                        className={ classes.fabBtn }
                        onClick={ () => props.btnTwoOnClick( props.data.contactCode ) } >
                        <DoneIcon/>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <Button className={classes.actionBtn}  data-test='containedBtn' size="large" variant="contained" onClick={ () => props.btnTwoOnClick( props.data.contactCode ) } color="primary">
                        { content.buttonTwoLabel }
                    </Button>
                </Hidden>
            </div>
        </div>
    )
}

export default CommonCollapseItem;