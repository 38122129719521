enum viewOptions {
    VIEW_AND_DOWNLOAD="DOWNLOAD",
    VIEW_ONLY="VIEW"
}
export class ContactType {
    constructor() {
        this.code = "";
        this.name = "";
        this.type = "";
        this.permission = viewOptions.VIEW_AND_DOWNLOAD
    }
    code: string;
    name?: string;
    type: string;
    permission?: viewOptions;
}


