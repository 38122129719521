import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import clsx from 'clsx';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactGA from 'react-ga';
import './ProdSelectDrawer.scss';
import * as GAConstants from '../../../shared/core/GAConstants';

type ProdSelectDrawerState = {
    menuOpen: boolean
}

type ProdSelectDrawerProp = {
    history: any,
    prodInfo: any,
    groups: any,
    selectedGroup: any,
    baseUrl: any,
    updateGroup: any,
    vaultName: string,
    onProdSelected: any,
    // emptyBreadcrumbItems: any,
    updateBreadcrumbItem: any,
    initDrawer: any,
}

class ProdSelectDrawer extends Component<ProdSelectDrawerProp & RouteComponentProps<{}>, ProdSelectDrawerState> {
    state = {
        menuOpen: true,
        showSiteLoading: false,
    }

    toggleMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    productOnClick = (groupInfo: any) => {
        this.props.updateGroup(groupInfo);
        if (this.props.vaultName === 'cv') {
            const baseUrl = `/${this.props.baseUrl}/group/${groupInfo.grpCode}`
            this.props.history.push(baseUrl);
            this.props.onProdSelected();
            // this.props.emptyBreadcrumbItems();
            this.props.updateBreadcrumbItem(groupInfo.grpName, baseUrl + '/contacts');
            this.props.initDrawer('cv')
            ReactGA.event({
                category: GAConstants.ASSOCIATE_EVENT_CATEGORY,
                action: GAConstants.GROUP_SELECTION_ACTION,
                //label: groupInfo.grpName
            });
        }
    }

    render() {
        return (
            <div data-test='prodSelectDrawer'>
                <List component='nav'
                    aria-labelledby='prod-menu-list-subheader'
                    className='prod-menu-list'>
                    <ListItem button data-test='prodMenu' onClick={this.toggleMenu} className='prod-menu-header'>
                        <ListItemText primary={<span className='prod-menu-title'>{this.props.prodInfo.prdName}</span>} />
                        {this.state.menuOpen ? <ExpandLess /> : <ExpandMore data-test='expandMoreIcon' />}
                    </ListItem>
                    <Collapse in={this.state.menuOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            {this.props.groups.map((group: any) => (
                                <ListItem key={group.grpCode} button data-test={`prodGroup${group.grpCode}`} onClick={() => this.productOnClick(group)}
                                    className={clsx('prod-list-item', { ['menu-selected']: this.props.selectedGroup.grpCode === group.grpCode })}>
                                    <ListItemText primary={group.grpName} />
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        prodInfo: state.homeReducer.productInfo.response.data,
        groups: state.homeReducer.groupsInfo.response.data.content,
        selectedGroup: state.appUIStateReducer.selectedGroup,
        baseUrl: state.appUIStateReducer.baseUrl,
        vaultName: state.appUIStateReducer.vaultName,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateGroup: (group: any) => dispatch({
            type: AppUIStateActionTypes.APP_STATE_UPDATE_GROUP,
            group: group
        }),
        updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
            itemName: itemName,
            itemLink: itemLink
        }),
        // emptyBreadcrumbItems: () => dispatch({
        //     type: AppUIStateActionTypes.EMPTY_BREADCRUMB_ITEM
        // }),
        initDrawer: (vaultName: string) => dispatch({
            type: AppUIStateActionTypes.INIT_DRAWER,
            vaultName: vaultName
        }),
    }
}

export const ProdSelectDrawerComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProdSelectDrawer);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProdSelectDrawer));
