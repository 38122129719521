// Get Contacts
export const CONTACT_API_GET_CONTACTS_REQUEST = 'CONTACT_API_GET_CONTACTS_REQUEST';
export const CONTACT_API_GET_CONTACTS_SUCCESS = 'CONTACT_API_GET_CONTACTS_SUCCESS';
export const CONTACT_API_GET_CONTACTS_FAILURE = 'CONTACT_API_GET_CONTACTS_FAILURE';

// Remove Contact
export const CONTACT_API_DELETE_CONTACTS_REQUEST = 'CONTACT_API_DELETE_CONTACTS_REQUEST';
export const CONTACT_API_DELETE_CONTACTS_SUCCESS = 'CONTACT_API_DELETE_CONTACTS_SUCCESS';
export const CONTACT_API_DELETE_CONTACTS_FAILURE = 'CONTACT_API_DELETE_CONTACTS_FAILURE';

export const CONTACT_API_ERROR_CLEAR = 'CONTACT_API_ERROR_CLEAR';

// Get Groups
export const GROUP_API_GET_GROUPS_REQUEST = 'GROUP_API_GET_GROUPS_REQUEST';
export const GROUP_API_GET_GROUPS_SUCCESS = 'GROUP_API_GET_GROUPS_SUCCESS';
export const GROUP_API_GET_GROUPS_FAILURE = 'GROUP_API_GET_GROUPS_FAILURE';

// Remove Group
export const GROUP_API_DELETE_GROUPS_REQUEST = 'GROUP_API_DELETE_GROUPS_REQUEST';
export const GROUP_API_DELETE_GROUPS_SUCCESS = 'GROUP_API_DELETE_GROUPS_SUCCESS';
export const GROUP_API_DELETE_GROUPS_FAILURE = 'GROUP_API_DELETE_GROUPS_FAILURE';

export const GROUP_API_ADD_GROUPS_REQUEST = 'GROUP_API_ADD_GROUPS_REQUEST';
export const GROUP_API_ADD_GROUPS_SUCCESS = 'GROUP_API_ADD_GROUPS_SUCCESS';
export const GROUP_API_ADD_GROUPS_FAILURE = 'GROUP_API_ADD_GROUPS_FAILURE';


export const CONTACT_API_ADD_CONTACT_REQUEST = 'CONTACT_API_ADD_CONTACT_REQUEST';
export const CONTACT_API_ADD_CONTACT_SUCCESS = 'CONTACT_API_ADD_CONTACT_SUCCESS';
export const CONTACT_API_ADD_CONTACT_FAILURE = 'CONTACT_API_ADD_CONTACT_FAILURE';

export const GROUP_API_EDIT_GROUPS_REQUEST = 'GROUP_API_EDIT_GROUPS_REQUEST';
export const GROUP_API_EDIT_GROUPS_SUCCESS = 'GROUP_API_EDIT_GROUPS_SUCCESS';
export const GROUP_API_EDIT_GROUPS_FAILURE = 'GROUP_API_EDIT_GROUPS_FAILURE';

export const GROUP_API_ERROR_CLEAR = 'GROUP_API_ERROR_CLEAR';