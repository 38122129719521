export const UNABLE_TO_FETCH_USER_DETAILS = "UNABLE_TO_FETCH_USER_DETAILS";
export const UNABLE_TO_FETCH_PRODUCT_DETAILS = "UNABLE_TO_FETCH_PRODUCT_DETAILS";
export const UNABLE_TO_FETCH_TOPICS = "UNABLE_TO_FETCH_TOPICS";
export const UNABLE_TO_FETCH_FILES = "UNABLE_TO_FETCH_FILES";
export const UNABLE_TO_DELETE_FILES = "UNABLE_TO_DELETE_FILES"
export const UNABLE_TO_DOWNLOAD_FILES = "UNABLE_TO_DOWNLOAD_FILES"
export const UNABLE_TO_FETCH_CATEGORIES = "UNABLE_TO_FETCH_CATEGORIES"
export const FETCH_TRM_OF_SVC_ERROR = "FETCH_TRM_OF_SVC_ERROR"
export const FETCH_PRI_POL_ERROR = "FETCH_PRI_POL_ERROR"
export const FETCH_ENDUSER_DISCLAIMER_ERROR = "FETCH_ENDUSER_DISCLAIMER_ERROR"
export const UNABLE_TO_UPDATE_CONSENT_FLAG = "UNABLE_TO_UPDATE_CONSENT_FLAG"
export const FETCH_THEME_S3_FAILUIRE = "FETCH_THEME_S3_FAILUIRE"
export const FETCH_DELETE_FILE_DISCLAIMER_ERROR = "FETCH_DELETE_FILE_DISCLAIMER_ERROR"
