export const TOPIC_FILES_API_GET_REQUEST = "TOPIC_FILES_API_GET_REQUEST"
export const TOPIC_FILES_API_GET_SUCCESS = "TOPIC_FILES_API_GET_SUCCESS"
export const TOPIC_FILES_API_GET_FAILURE = "TOPIC_FILES_API_GET_FAILURE"

export const TOPIC_FILES_API_LOAD_MORE_START = "TOPIC_FILES_API_LOAD_MORE_START"
export const TOPIC_FILES_API_LOAD_MORE_REQUEST = "TOPIC_FILES_API_LOAD_MORE_REQUEST"
export const TOPIC_FILES_API_LOAD_MORE_SUCCESS = "TOPIC_FILES_API_LOAD_MORE_SUCCESS"
export const TOPIC_FILES_API_LOAD_MORE_FAILURE = "TOPIC_FILES_API_LOAD_MORE_FAILURE"

export const TOPIC_FILES_API_SINGLE_FILE_DOWNLOAD_REQUEST = "TOPIC_FILES_API_SINGLE_FILE_DOWNLOAD_REQUEST"
export const TOPIC_FILES_API_MULTIPLE_FILE_DOWNLOAD_REQUEST = "TOPIC_FILES_API_MULTIPLE_FILE_DOWNLOAD_REQUEST"
export const TOPIC_FILES_API_FILE_DOWNLOAD_SUCCESS = "TOPIC_FILES_API_FILE_DOWNLOAD_SUCCESS"
export const TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE = "TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE"
export const TOPIC_FILES_API_FILE_DOWNLOAD_DELETE="TOPIC_FILES_API_FILE_DOWNLOAD_DELETE"


export const TOPIC_FILES_API_DELETE_FILE_REQUEST = "TOPIC_FILES_API_DELETE_FILE_REQUEST"
export const TOPIC_FILES_API_DELETE_FILE_SUCCESS = "TOPIC_FILES_API_DELETE_FILE_SUCCESS"
export const TOPIC_FILES_API_DELETE_FILE_FAILURE = "TOPIC_FILES_API_DELETE_FILE_FAILURE"

export const TOPIC_FILES_API_UPLOAD_FILE_REQUEST = "TOPIC_FILES_API_UPLOAD_FILE_REQUEST"
export const TOPIC_FILES_API_UPLOAD_FILE_SUCCESS = "TOPIC_FILES_API_UPLOAD_FILE_SUCCESS"
export const TOPIC_FILES_API_UPLOAD_FILE_PROGRESS = "TOPIC_FILES_API_UPLOAD_FILE_PROGRESS"
export const TOPIC_FILES_API_UPLOAD_FILE_FAILURE = "TOPIC_FILES_API_UPLOAD_FILE_FAILURE"
export const TOPIC_FILES_UPLOAD_CLEAR = "TOPIC_FILES_UPLOAD_CLEAR"
export const TOPIC_FILES_UPLOAD_ADD_FILE="TOPIC_FILES_UPLOAD_ADD_FILE"

export const TOPIC_FILES_API_SMART_FORM_REQUEST = "TOPIC_FILES_API_SMART_FORM_REQUEST"
export const TOPIC_FILES_API_SMART_FORM_SUCCESS = "TOPIC_FILES_API_SMART_FORM_SUCCESS"
export const TOPIC_FILES_API_SMART_FORM_FAILURE = "TOPIC_FILES_API_SMART_FORM_FAILURE"

export const TOPIC_FILES_API_ACTIVITY_REQUEST = "TOPIC_FILES_API_ACTIVITY_REQUEST"
export const TOPIC_FILES_API_ACTIVITY_SUCCESS = "TOPIC_FILES_API_ACTIVITY_SUCCESS"
export const TOPIC_FILES_API_ACTIVITY_FAILURE = "TOPIC_FILES_API_ACTIVITY_FAILURE"