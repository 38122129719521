import { combineReducers } from 'redux';
import * as InvitationActionTypes from '../../constants/personalVault/invitationConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    invites: {
        fetching: false,
        invitesData: {
            content: [],
            page: {},
            sort: []
        },
        error: null
    }
}

function invites(state = initState.invites, action) {
    switch(action.type) {
        // Get Invites
        case InvitationActionTypes.INVITATION_API_GET_INVITES_REQUEST:
            return { 
                ...state, 
                fetching: true 
            };
        case InvitationActionTypes.INVITATION_API_GET_INVITES_SUCCESS:
            return { 
                ...state, 
                fetching: false, 
                invitesData: {...action.invites.data}
            };
        case InvitationActionTypes.INVITATION_API_GET_INVITES_FAILURE:
            return { 
                ...state, 
                fetching: false, 
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        case InvitationActionTypes.INVITATION_API_UPDATE_INVITE_REQUEST:
            return { 
                ...state, 
                fetching: true 
            };
        case InvitationActionTypes.INVITATION_API_UPDATE_INVITE_SUCCESS:
            const copy_invite = { ...state }
            const updatedContact = action.updatedInvite;
            const filted_invites = copy_invite.invitesData.content.filter(contact => {
                return contact.contactCode !== updatedContact;
            })
            return { 
                ...state, 
                fetching: false, 
                invitesData: {
                    ...state.invitesData,
                    content: filted_invites
                }
            };
        case InvitationActionTypes.INVITATION_API_UPDATE_INVITE_FAILURE:
            return { 
                ...state, 
                fetching: false, 
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        default:
            return state;    
    }
}

export default combineReducers({
    invites
})