import {
  takeLatest,
  takeEvery,
  take,
  call,
  put,
  all,
  select,
} from "redux-saga/effects";
import * as TopicFilesActionTypes from "../../constants/customerVault/topicFileConstants";
import * as appActionTypes from "../../constants/shared/appStatesConstants";
import {
  getItems,
  deleteFile,
  downloadFile,
  downloadMultipleFiles,
  createUploadFileChannel,
  getSmartIntakeToken,
  getFileActivityAPI
} from "../../../shared/core/utils/apiFunctions";
import { saveAs } from "file-saver";
import { getFormId,getSIGroupId } from "./formOpener.js";

export function* topicFilesWatcher() {
  yield takeLatest(
    TopicFilesActionTypes.TOPIC_FILES_API_DELETE_FILE_REQUEST,
    deletefileSaga
  );
  yield takeEvery(
    TopicFilesActionTypes.TOPIC_FILES_API_SINGLE_FILE_DOWNLOAD_REQUEST,
    downloadFileSaga
  );
  yield takeEvery(
    TopicFilesActionTypes.TOPIC_FILES_API_MULTIPLE_FILE_DOWNLOAD_REQUEST,
    downloadMultipleFilesSaga
  );
  yield takeLatest(
    TopicFilesActionTypes.TOPIC_FILES_API_GET_REQUEST,
    filesSaga
  );
  yield takeLatest(
    TopicFilesActionTypes.TOPIC_FILES_API_LOAD_MORE_REQUEST,
    filesLazyLoadSaga
  );
  yield takeEvery(
    TopicFilesActionTypes.TOPIC_FILES_API_UPLOAD_FILE_REQUEST,
    uploadFilesSaga
  );
  yield takeEvery(
    TopicFilesActionTypes.TOPIC_FILES_API_SMART_FORM_REQUEST,
    openSmartFormSaga
  );
  yield takeEvery(
    TopicFilesActionTypes.TOPIC_FILES_API_ACTIVITY_REQUEST,
    getFileActivitySaga
  );
}

function* filesSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const items = yield call(
        getItems,
        grpCode,
        action.topicCode,
        action.pagination.page,
        action.pagination.size,
        action.pagination.direction,
        action.pagination.orderBy
      );
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_GET_SUCCESS,
        response: items,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_GET_FAILURE,
          error,
        });
    } else {
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_GET_FAILURE,
        error,
      });
    }
  }
}

function* uploadFilesSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const channel = yield call(
        createUploadFileChannel,
        grpCode,
        action.topicCode,
        action.file,
        action.user_sid,
        action.isSubmitRequired
      );
      while (true) {
        const { progress = 0, error, success, items } = yield take(channel);
        if (error) {
          let errorJson = JSON.parse(error);
          if (errorJson.status === 403 || errorJson.status === 401)
            yield put({
              type: appActionTypes.APP_STATE_GENERIC_ERROR,
              errorResponse: { response: { data: errorJson } },
            });
          else
            yield put({
              type: TopicFilesActionTypes.TOPIC_FILES_API_UPLOAD_FILE_FAILURE,
              fileId: action.fileId,
              error: errorJson,
            });
          return;
        }
        if (success == false) {
          let errJson = { data: { errMsg: "Unable to upload. File is currently in use on your device." } };
          yield put({
            type: TopicFilesActionTypes.TOPIC_FILES_API_UPLOAD_FILE_FAILURE,
            fileId: action.fileId,
            error: { response: errJson },
          });
          return;
        }
        if (success) {
          yield put({
            type: TopicFilesActionTypes.TOPIC_FILES_API_UPLOAD_FILE_SUCCESS,
            fileId: action.fileId,
            response: JSON.parse(items),
          });
          return;
        }
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_UPLOAD_FILE_PROGRESS,
          fileId: action.fileId,
          progress: progress,
        });
      }
    }
  } catch (error) {
    yield put({
      type: TopicFilesActionTypes.TOPIC_FILES_API_UPLOAD_FILE_FAILURE,
      fileId: action.fileId,
      error: error,
    });
  }
}

function* filesLazyLoadSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const items = yield call(
        getItems,
        grpCode,
        action.topicCode,
        action.pagination.page,
        action.pagination.size,
        action.pagination.direction,
        action.pagination.orderBy
      );
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_LOAD_MORE_SUCCESS,
        response: items,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_LOAD_MORE_FAILURE,
          error,
        });
    } else {
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_LOAD_MORE_FAILURE,
        error,
      });
    }
  }
}

function* deletefileSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const fileResponse = yield call(
        deleteFile,
        grpCode,
        action.topicCode,
        action.itemCodes
      );
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_DELETE_FILE_SUCCESS,
        responseInfo: fileResponse.status,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_DELETE_FILE_FAILURE,
          error,
        });
    } else {
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_DELETE_FILE_FAILURE,
        error,
      });
    }
  }
}

function* openSmartFormSaga(action) {
  try {
    var isError = false;
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const fileResponse = yield call(
        downloadFile,
        grpCode,
        action.topicCode,
        action.itemCode
      );
      if (fileResponse.status === 200 && fileResponse.headers["content-type"].includes("application/json")) {
        // if (fileResponse.status === 200) {
        //Get the form id from the json
        let formType =getFormId(fileResponse);
        let siGroupId = getSIGroupId(fileResponse);
        if (formType != null) {
          const smartIntakeResp = yield call(getSmartIntakeToken,
            siGroupId,
            formType,
            grpCode,
            action.topicCode,
            action.itemCode);
          if (smartIntakeResp.status === 200 && smartIntakeResp.data !== null) {
            yield put({
              type: TopicFilesActionTypes.TOPIC_FILES_API_SMART_FORM_SUCCESS,
              userLink: smartIntakeResp.data.userLink,
            });
          }
          else {
            isError = true;
          }
        }
        else {
          isError = true;
        }
      }
      else {
        isError = true;
      }
      if (isError) {
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_SMART_FORM_FAILURE,
          error: "Failed to perform the smart intake authentication",
        });
      }
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401) {
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      }
      else {
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_SMART_FORM_FAILURE,
          error,
        });
      }
    } else {
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_SMART_FORM_FAILURE,
        error,
      });
    }
  }
}

function* downloadFileSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const fileResponse = yield call(
        downloadFile,
        grpCode,
        action.topicCode,
        action.itemCode
      );
      if (fileResponse.status === 200) {
        saveAs(
          new Blob([fileResponse.data], {
            type: fileResponse.headers["content-type"],
          }),
          action.fileName
        );
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_SUCCESS,
          response: new Blob([fileResponse.data]),
          downloadId: action.downloadId,
        });
      }
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      const errorResponse = String.fromCharCode.apply(
        null,
        new Uint8Array(error.response.data)
      );
      const jsonResponse = JSON.parse(errorResponse);
      if (
        jsonResponse != null &&
        (jsonResponse.status === 403 || jsonResponse.status === 401)
      ) {
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          downloadId: action.downloadId,
          errorResponse: jsonResponse,
        });
      }
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE,
        downloadId: action.downloadId,
        error: {
          response: {
            data: JSON.parse(errorResponse),
            status: error.response.status,
          },
        },
      });
    } else {
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE,
        downloadId: action.downloadId,
        error: {},
      });
    }
  }
}

function* downloadMultipleFilesSaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const fileResponse = yield call(
        downloadMultipleFiles,
        grpCode,
        action.topicCode,
        action.itemCodes
      );
      if (fileResponse.status === 200) {
        saveAs(
          new Blob([fileResponse.data], {
            type: fileResponse.headers["content-type"],
          }),
          action.fileName
        );
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_SUCCESS,
          response: fileResponse,
          downloadId: action.downloadId,
        });
      }
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      const errorResponse = String.fromCharCode.apply(
        null,
        new Uint8Array(error.response.data)
      );
      const jsonResponse = JSON.parse(errorResponse);

      if (
        jsonResponse != null &&
        (jsonResponse.status === 403 || jsonResponse.status === 401)
      ) {
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          downloadId: action.downloadId,
          errorResponse: jsonResponse,
        });
      }
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE,
        downloadId: action.downloadId,
        error: {
          response: {
            data: JSON.parse(errorResponse),
            status: error.response.status,
          },
        },
      });
    } else {
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_FILE_DOWNLOAD_FAILURE,
        downloadId: action.downloadId,
        error: {},
      });
    }
  }
}

function* getFileActivitySaga(action) {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const items = yield call(
        getFileActivityAPI,
        grpCode,
        action.topicCode,
        action.itemCode
      );
      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_ACTIVITY_SUCCESS,
        response: items,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({
          type: TopicFilesActionTypes.TOPIC_FILES_API_ACTIVITY_FAILURE,
          error: errorResponse,
        });
    } else {
      let errorResponse = error.response.data;

      yield put({
        type: TopicFilesActionTypes.TOPIC_FILES_API_ACTIVITY_FAILURE,
        error: errorResponse,
      });
    }
  }
}

export default topicFilesWatcher;

