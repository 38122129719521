import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';
import moment from 'moment-timezone';

import './FilterBox.scss';

type CategoryType = {
    grpCount: number,
    createdAt: string,
    modifiedAt: string,
    categoryCode: string,
    categoryName: string,
    categoryIconLabel: string,
    categroyIconColor: string
}

type FilterBoxProps = {
    filterTopics: any,
    resetFilter: any,
    categories: CategoryType[]
}

type FilterBoxStates = {

}

class FilterBox extends Component<FilterBoxProps, FilterBoxStates> {
    state = {
        cat: null,
        created: null,
        modified: null,
        timeStampOptions: [
            { title: 'Within 24 Hours', value: 'lastDay' },
            { title: 'Within Week', value: 'lastWeek' },
            { title: 'Within Month', value: 'lastMonth' },
            { title: 'Within Year', value: 'lastYear' },
        ]
    }

    componentDidMount() {
        const yesterday = moment().subtract(24, 'hours').toISOString();
        const lastWeek = moment().startOf('day').subtract(1, 'weeks').toISOString();
        const lastMonth = moment().startOf('day').subtract(1, 'months').toISOString();
        const lastYear = moment().startOf('day').subtract(1, 'years').toISOString();
        this.setState({
            timeStampOptions: [
                { title: 'Within 24 Hours', value: yesterday },
                { title: 'Within Week', value: lastWeek },
                { title: 'Within Month', value: lastMonth },
                { title: 'Within Year', value: lastYear }
            ]
        })
    }

    filterOnChange = (e: any, val: any, id: string) => {
        switch (id) {
            case 'CATEGORY':
                this.setState({ cat: val });
                break;
            case 'CREATED':
                this.setState({ created: val });
                break;
            case 'MODIFIED':
                this.setState({ modified: val });
                break;
        }
        this.props.filterTopics(id, val);
    }

    resetFilter = () => {
        this.setState({
            cat: null,
            created: null,
            modified: null,
        }, () => {
            this.props.resetFilter()
        })
    }

    render() {
        return (
            <div className="filter-wrapper" data-test="filterWrapper">
                <Autocomplete
                    id="category-select"
                    className="filter"
                    limitTags={1}
                    size="small"
                    options={this.props.categories}
                    getOptionLabel={(option) => option.categoryName}
                    onChange={(e: any, val: any) => this.filterOnChange(e, val, 'CATEGORY')}
                    value={this.state.cat}
                    renderInput={(params) => <TextField className="filter-input" {...params} label="Categories" variant="outlined" />}
                />
                <Autocomplete
                    id="created-select"
                    className="filter"
                    size="small"
                    options={this.state.timeStampOptions}
                    onChange={(e: any, val: any) => this.filterOnChange(e, val, 'CREATED')}
                    getOptionLabel={(option) => option.title}
                    value={this.state.created}
                    renderInput={(params) => <TextField className="filter-input" {...params} label="Created" variant="outlined" />}
                />
                <Autocomplete
                    id="modified-select"
                    className="filter"
                    size="small"
                    options={this.state.timeStampOptions}
                    onChange={(e: any, val: any) => this.filterOnChange(e, val, 'MODIFIED')}
                    getOptionLabel={(option) => option.title}
                    value={this.state.modified}
                    renderInput={(params) => <TextField className="filter-input" {...params} label="Modified" variant="outlined" />}
                />
                <Button color="primary" className="reset-btn" data-test="resetbtn" onClick={this.resetFilter}>
                    RESET
                </Button>
            </div>
        )
    }
}

export default FilterBox;
