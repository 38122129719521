import React, { Component } from 'react';
import pageLookUp from '../../core/pages.map';
import './Layout.scss';
import { connect } from 'react-redux';

type LayoutState = {
    isLoaded: boolean,
    error: null,
    pageName: string,
    components: string[]
}

type LayoutProps = {
    pageName: string;
    location: {
        pathname: string
    },
    pageComponents: any
}

type pageType = {
    name: string,
    components: string[]
}

class Layout extends Component<LayoutProps, LayoutState> {
    state = {
        isLoaded: false,
        error: null,
        pageName: '',
        components: []
    }

    getData = (comps: any) => {
        let pageData = comps.pages.filter((page: pageType) => page.name === this.props.pageName);
        this.setState({
            isLoaded: true,
            pageName: this.props.pageName,
            components: pageData[0].components
        })
    }

    componentDidUpdate(prevprops: any) {
        const { pageName, location, pageComponents } = prevprops; 
        if (location.pathname !== this.props.location.pathname) {
            this.getData.bind(this)(pageComponents);
        }
    }

    componentDidMount() {
        this.getData.bind(this)(this.props.pageComponents);
    }

    render() {
        return(
            <div className="layout-wrapper" data-test='layoutComponent'>
                { pageLookUp(this.state.pageName, this.state.components) }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        pageComponents: state.pageComponentsReducer
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);