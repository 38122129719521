import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    close: {
      padding: theme.spacing(0.5),
    },
  }));

export default function ConfirmDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const handleClose = () => {
        setOpen(false);
    };
    const classes = useStyles();
    return <Snackbar
        style={{ paddingTop: '64px', flexWrap: 'nowrap' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={props.message ? props.message : undefined}
        action={
            <React.Fragment>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </React.Fragment>
        }>
    </ Snackbar >
}