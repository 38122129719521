import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { reduxForm } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import styles from '../css/loginStyles';
import { Fab, Link, Button, Paper, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import TermsOfService from './TermsOfService';
import { connect } from 'react-redux';
import FidelityIcon from '../../../../assets/fbs.svg';
import ShowMoreIcon from '../../../../assets/show-more.svg';
import ShowLessIcon from '../../../../assets/show-less.svg';
import { FETCH_TRM_OF_SVC_REQUEST } from '../../../../redux/constants/shared/legalsConstants';
import SignIn from '../../cognito/components/SignIn';
import { con } from '../../../../shared/config/config'

class Login extends React.Component {
    state = {
        openTerms: false,
        openFidsafeForm: true
    };
    
    handleOpenModal = (type) => {
        if (type === 'openTerms') {
            if (!this.props.trmsOfSvcfetching && (!this.props.trmsOfSvcContent || this.props.trmsOfSvcContent.length === 0))
                this.props.fetchTrmsOfSvcContent();
            this.setState({ [type]: true });
        }
        else if (type === 'openPolicy') {
            //open fidelity privacy policy
            window.open(con.PRIVACY_POLICY, '_blank'); 
        }
        
    }

    handleCloseModal = (type) => {
        this.setState({ [type]: false });
    }

    generateInlineLink(flag, label) {
        const { classes } = this.props;
        return <Link data-test='inlineLink' className={classes.link} onClick={() => this.handleOpenModal(flag)}>{label}</Link>
    }

    renderFidelityLoginContent() {
        const { classes } = this.props;
        return (
            <div className={classes.statement} style={{ marginTop: '1rem' }}>
                Fidsafe<sup>&reg;</sup> for Business (the "Service") offers a secure, digital document sharing facility (a "vault"). 
                The Service is operated by Fidelity Technology Group, LLC, a Fidelity affiliate, is made available to you as a Fidelity customer at no additional charge, and is subject to certain limitations and
                restrictions. Note that your Fidelity log-in credentials provide access to client vault via Fidelity.com.
                However, the {this.generateInlineLink('openTerms', 'Terms of Service ')} that govern your use of client vault differ from those applicable to your experience on Fidelity.com. Please carefully review
                the FidSafe for Business {this.generateInlineLink('openTerms', 'Terms of Service ')} and the Fidelity {this.generateInlineLink('openPolicy', 'Privacy Policy')} prior to use.
            </div>
        )
    }

    renderNonFidelityLoginContent() {
        const { classes } = this.props;
        return (
            <div className={classes.statement} style={{ marginTop: '1rem' }}>
                Fidsafe<sup>&reg;</sup> for Business (the "Service") offers a secure, digital document sharing facility (a "vault").
                The Service is operated by Fidelity Technology Group, LLC, a Fidelity affiliate, is made available to you as a Fidelity customer at no additional charge, and is subject to certain limitations and
                restrictions. 
                However, the {this.generateInlineLink('openTerms', 'Terms of Service ')} that govern your use of client vault differ from those applicable to your experience on Fidelity.com. Please carefully review
                the FidSafe for Business {this.generateInlineLink('openTerms', 'Terms of Service ')} and the Fidelity {this.generateInlineLink('openPolicy', 'Privacy Policy')} prior to use.            
            </div>
        )
    }

    renderLinkAndTos(thirdParty) {
        const { classes, features } = this.props;
        if (thirdParty)
            return this.renderNonFidelityLoginContent()
        else
            return <>
                {features['nonFidelityLogin'] === false &&
                    this.renderFidelityLoginContent()
                }
                {Boolean(features['nonFidelityLogin']) &&
                    this.renderNonFidelityLoginContent()
                }
            </>
    }

    renderLoginContent(title, description, children) {
        let that = this;
        const { classes } = this.props;
        return <div className={classes.loginContent}>
            <div className={classes.subtitles}>
                <Typography variant='h5' className={classes.subtitle}>{title}</Typography>
                <Typography variant='subtitle1' className={classes.description}
                    dangerouslySetInnerHTML={{ __html: description }}></Typography>
                {
                    children && children
                }
            </div>

            {this.props.features.thirdPartyLoginLayout.filter(x => Object.keys(x).includes('fidsafe-login')).map(function (element) {
                const key = Object.keys(element)[0]
                const link = element[key].type;
                return (
                    <React.Fragment key={'fidsafe-login'}>
                        <div style={{ display: 'none' }}>
                            <SignIn endpoint={`auth-login/${link}`}>
                            </SignIn>
                        </div>
                    </React.Fragment>
                )
            })}
            {this.props.features.loginLayout.map(function (element) {
                const key = Object.keys(element)[0]
                const link = element[key].type;
                var fidelityLoginExists = that.props.features.loginLayout.find(x => Object.keys(x).includes('fidelity-login'));

                if (key === 'fidsafe-login') {
                    return (
                        <React.Fragment key={'fidsafe-login'}>
                            {link !== "idaas" && fidelityLoginExists != null && <div className={classes.NotaFidelityCustomer} onClick={() => that.setState({ openFidsafeForm: !that.state.openFidsafeForm })}>
                                {that.state.openFidsafeForm ? <img src={ShowLessIcon} alt="Collapse" /> : <img src={ShowMoreIcon} alt="Expand" />}
                                <div className={classes.externalCustomertext}>Not a Fidelity Customer</div>
                            </div>}
                            {that.state.openFidsafeForm && <div className={classes.cognitoLogincontainer} >
                                <SignIn endpoint={`auth-login/${link}`}>
                                </SignIn>
                            </div>}
                        </React.Fragment>
                    )
                }
                if (key === 'fidelity-login') {
                    return (
                        <form key={'fidelity-login'} action={`auth-login/${link}`} >
                            <div className={classes.row} >
                                <Button className={classes.loginBtn} variant="outlined" type='submit' fullWidth={true}>
                                    <img className={classes.loginBtnIcon} src={FidelityIcon} alt="Login with Fidelity" />
                                    LOG IN WITH FIDELITY
                                </Button>
                            </div>
                        </form>
                    )
                } else {
                    return (null);
                }
            })}
        </div>
    }

    renderThirdPartyLoginContent(title, description, children) {
        const { classes } = this.props;
        return <div className={classes.loginContent}>
            <div data-test="thirdPartyLoginCont" className={classes.subtitles}>
                <Typography variant='h5' className={classes.subtitle}>{title}</Typography>
                <Typography variant='subtitle1' className={classes.description}
                    dangerouslySetInnerHTML={{ __html: description }}></Typography>
                {
                    children
                }
            </div>
            {this.props.features.thirdPartyLoginLayout.map(function (element) {
                const key = Object.keys(element)[0]
                const link = element[key].type;
                if (key === 'fidsafe-login') {
                    return (
                        <React.Fragment key={'fidsafe-login'}>
                            <div className={classes.cognitoLogincontainer} >
                                <SignIn endpoint={`auth-login/${link}`}>
                                </SignIn>
                            </div>
                        </React.Fragment>
                    )
                } else {
                    return (null);
                }
            })}
        </div>
    }

    renderAdvisorLoginContent(advisorLink) {
        const { classes } = this.props;
        return <div className={classes.advisorBox} key={advisorLink}>
                <div className={classes.advisorBoxContent}>
                <Typography className={classes.subtitle2}> Are you a Fidelity representative?</Typography>
                <Link className={classNames(classes.link, classes.centerText)} href={advisorLink} >
                    <Typography className={classes.loginLink} style={{ fontSize: 16 }}>Login</Typography>
                </Link>
            </div>
        </div>
    }

    renderLoginContentUserNamePassword() {

        const { classes, proxyUserLink } = this.props;
        return <div className={classes.advisorBox}>
            <div className={classes.advisorBoxContent}>
                <Typography className={classes.subtitle2}> Are you a Fidsafe user?</Typography>
                <Link className={classNames(classes.link, classes.centerText)} href={proxyUserLink} >
                    <Typography className={classes.loginLink} style={{ fontSize: 16 }}>Login/Signup</Typography>
                </Link>
            </div>
        </div>;
    }

    renderContent() {
        const that = this;
        let thirdParty = false
        const { classes, unauthorized, loggedIn, error } = this.props;

        if (unauthorized) {
            const discriminator = loggedIn.discriminator;
            const contactOption = discriminator === 'advisor' ? 'administrator' : 'Fidelity advisor'; // if user is logged as a advisor, he/she should contact admin
            const title = "We're sorry. It looks like there's a problem.";
            const description = `Your account is not authorized to access this resource. Please contact your ${contactOption} for further assistance.`;
            const buttonLabel = 'back';
            let errMsg;
            if (error && error.response && error.response.data)
                errMsg = <li className={classes.errMsg}>{error.response.data.errMsg}</li>
            // errMsg = null; // duplicated error msg with description above.
            return <div data-test='loginContentContainer' className={classes.loginContent}>
                <div className={classes.subtitles}>
                    <Typography variant='h5' className={classes.subtitle}>{title}</Typography>
                    <Typography variant='subtitle1' className={classes.description}>{description}</Typography>
                    {
                        errMsg
                    }
                </div>
                <form data-test='logout-form' action='/logout'>
                    <div className={classes.row} >
                        <Fab variant='extended' type='submit' color='primary' className={classes.button} >
                            {buttonLabel}
                        </Fab>
                    </div>
                </form>
            </div>
        }
        else {
            let title = 'Welcome to your vault';
            let description = 'Vault is supported by FidSafe<sup>&reg;</sup> for Business to securely and digitally share documents';
            const location = window.location.pathname;

            const thirdPartyLogin = this.props.features.thirdPartyLoginLayout.filter(function (element) {
                const loginUrls = Object.values(element)[0]['login-urls'];
                return loginUrls.includes(location);
            })
            thirdParty = (thirdPartyLogin.length > 0);
            return <>
                { !thirdParty ? this.renderLoginContent(title, description) : this.renderThirdPartyLoginContent(title, description)}
                {this.props.features.loginLayout.map(function (element) {
                    const key = Object.keys(element)[0];
                    const link = element[key].type;
                    if (key === 'rep-login' && !thirdParty) {
                       return (that.renderAdvisorLoginContent(`auth-login/${link}`)) 
                    } else {
                        return (null)
                    }
                })}
                {this.renderLinkAndTos(thirdParty)}
            </>
        }
    }

    renderProgressBar() {
        const { classes } = this.props;
        return <div className={classes.loaderholder} >
            <Paper elevation={3} className={classes.loader}>
                <CircularProgress />
                <div className={classes.loadingtext}>Loading...</div>
            </Paper>
        </div>
    }

    render() {
        const { classes } = this.props;
        return (

            <React.Fragment>
                <div className={classes.root}>

                    <TermsOfService
                        title='Terms of Service'
                        openModal={this.state.openTerms}
                        showAction={false}
                        handleCloseCallback={() => { this.handleCloseModal('openTerms') }} />

                    <div className={classes.sideBar} />

                    <main className={classes.main}>
                        {this.renderContent()}
                    </main>
                </div>
                {(this.props.signin.signinProgress || this.props.userDetails.getUserDetailsProgress) ? this.renderProgressBar() : null}
            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.login,
        error: state.homeReducer.error,
        trmsOfSvcContent: state.legalsReducer.trmsOfService.content,
        trmsOfSvcfetching: state.legalsReducer.trmsOfService.fetching,
        features: state.homeReducer.features,
        signin: state.cognitoLoginReducer.signin,
        userDetails: state.userDetailsReducer.userDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTrmsOfSvcContent: () => dispatch({ type: FETCH_TRM_OF_SVC_REQUEST })
    };
};

export const LoginComponentTest = connect(mapStateToProps, mapDispatchToProps)(Login)
export default reduxForm({ form: 'Login' })(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login)));

