import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fab, Link } from '@material-ui/core';
import TextInput from '../../../../shared/core/TextInput';
import Password from './Password';
import { Redirect, withRouter, RouteProps, RouteComponentProps } from "react-router-dom";
import UserType from '../../../../shared/core/UserType';
import * as CognitoActionTypes from '../../../../redux/constants/shared/cognitoLoginConstants';
import * as UserDetailsActionTypes from '../../../../redux/constants/shared/UserDetailsConstants';
import * as AppStateActionTypes from '../../../../redux/constants/shared/appStatesConstants';

type SignInProps = {
    classes: any;
    loading?: any;
    features: any;
    signin: any;
    CognitoLoginError: any;
    CognitoLoginStateUpdate: any;
    signInAction: any;
    cognitoLogin: any;
    onLoading: any;
    GetUserDetailsAction: any;
    SignupResendCodeAction: any;
    location: any;
    userDetails: any;
    endpoint: string;
}

type SignInPropsWithRouter = SignInProps & RouteProps & RouteComponentProps<{}>

enum loginState {
    signinState,
    mfaRequired,
    signup,
    signupConfirm,
    welcome,
    resetPassword,
    resetPasswordConfirm,
    resetPasswordSuccess,
    redirect,
    signupPrefilled,
    loginPrefilled
}

export const styles = (theme: any) => ({

    mainContainer:
    {
        paddingTop: theme.spacing(3),
        minHeight: 390,
        minWidth: 350,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none'
        }
    },
    title: {
        fontSize: '1.9rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(2),
        fontWeight: 'lighter' as 'lighter'
    },
    description: {
        fontSize: '0.875rem',
        fontFamily: theme.homefontfamilyregular,
        display: 'flex',
        justifyContent: 'left',
        paddingBottom: theme.spacing(1),
        fontWeight: 300
    },
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center'
    },
    row: {
        display: 'flex',
        paddingTop: theme.spacing(2),
        flexDirection: 'column' as 'column',
        // justifyContent: 'space-evenly'
    },
    fabBtn: {
        minWidth: 120,
        color: '#FFFFFF',
        fontWeight: 'normal' as 'normal',
        letterSpacing: 1.25,
        borderRadius: 5,
        fontFamily: theme.homefontfamilyregular,
        boxShadow: theme.fabbuttonmobileshadowcolor,
        marginBottom: '0.5rem',

        '&:hover': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:active': {
            backgroundColor: theme.loginbuttonbgcolor,
        },
        '&:focus': {
            boxShadow: theme.fabbuttonmobileshadowcolor,
        },
        textTransform: 'none' as 'none',
        fontSize: 14,
        backgroundColor: theme.loginbuttonbgcolor,
    },
    signUpLink: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center'
    },
    forgotPasswordLink:
    {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '0.15em',
        paddingTop: '0.25em'
    },
    errorMessage: {
        color: 'red',
        fontSize: '13px',
        textAlign: 'center' as 'center'
    },
    inputholder:
    {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap' as 'wrap',
        alignItems: 'center',
        minHeight: 76
    },
    passwordTB:
    {
        flexWrap: 'wrap' as 'wrap',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }
});

type SignInState = {
    user: UserType,
    readOnly?: boolean,
    disabled?: boolean,
    userName?: string,
    password?: string,
    isUserNameErr?: boolean,
    validpassword?: boolean,
    errorMessage?: string,
    signUpCode?: string,
    loginCode?: string
}

class SignIn extends Component<SignInPropsWithRouter, SignInState> {

    initState(defaultUser?: any) {
        this.setState({
            user: defaultUser ? defaultUser : null,
            readOnly: defaultUser ? true : false,
            disabled: defaultUser ? true : false,
            userName: defaultUser ? defaultUser.email : '',
            password: '',
            isUserNameErr: false,
            validpassword: true,
        });
    }

    componentDidMount() {
        let { GetUserDetailsAction, location } = this.props;
        this.initState();
        if (!this.props.features['passwordLess']) {
            document.addEventListener('keydown', this.selectOptionByKeys);
        }
        const signupCode = this.getParameterByName(location, 'signUpCode'); // new URLSearchParams(location.search).get('signUpCode');
        const loginCode = this.getParameterByName(location, 'loginCode'); //new URLSearchParams(location.search).get('loginCode');
        if (signupCode) {
            this.setState({ signUpCode: signupCode });
            GetUserDetailsAction(signupCode);
        } else if (loginCode) {
            GetUserDetailsAction(loginCode);
            this.setState({ loginCode: loginCode });
        }
        this.saveRedirectionParams(location.search);

    }

    saveRedirectionParams(search: string) {
        const urlSearchParams: URLSearchParams = new URLSearchParams(search);
        const urlParamsObj: {[key: string]: any} = {};
        urlSearchParams.forEach((value, key) => {
            if (!([ 'signUpCode', 'loginCode'].includes(key))) {
                urlParamsObj[key] = value;
            }
        });
        sessionStorage.setItem('redirectionParams', JSON.stringify(urlParamsObj));
    }

    getParameterByName(location: any, name: string) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    selectOptionByKeys = (e: any) => {
        switch (e.keyCode) {
            case 13:
                this.onSignIn()
                break;
        }
    }

    componentWillMount() {
        this.initState();
    }

    componentDidUpdate(prevProps: any) {
        let { cognitoLogin, signin, userDetails } = this.props;
        if (prevProps.signin.signinProgress != signin.signinProgress && !signin.signinProgress) {
            // Check if there are any errors
            //&& signin.error.data.message.length>0 && signin.error.data.message[0] == 'UserNotConfirmedException'
            if (signin.error) {
                if (signin.error.status === 401 && signin.error.data.message != null && signin.error.data.message == 'UserNotConfirmedException')
                    this.props.CognitoLoginError('Your account has not been confirmed yet.');
                else if (signin.error.status === 500)
                    this.props.CognitoLoginError('Something went wrong. Please try again later.');
                else
                    this.props.CognitoLoginError('Invalid Username or Password');
            }
            else {
                if (signin.mfaRequired) {
                    this.props.CognitoLoginStateUpdate(loginState.mfaRequired);
                    this.props.CognitoLoginError(null);
                    this.props.history.push(`/userauth/userlogin`);
                }
                else {
                    this.props.CognitoLoginStateUpdate(loginState.redirect);
                    this.props.CognitoLoginError(null);
                    this.props.history.push(`/userauth/userlogin`);
                }
            }
        }

        if (prevProps.userDetails.getUserDetailsProgress != userDetails.getUserDetailsProgress && !userDetails.getUserDetailsProgress) {
            if (userDetails.error) {
                this.props.CognitoLoginStateUpdate(loginState.signupPrefilled);
                if (this.state.signUpCode)
                    this.props.CognitoLoginError('Signup Code Invalid/Expired.')
                else
                    this.props.CognitoLoginError("Sorry! This link has either expired or is invalid.")
            }
            else if (userDetails.getUserDetailsResponse.status === 'UNCONFIRMED') {

                this.props.CognitoLoginStateUpdate(loginState.signupConfirm, userDetails.getUserDetailsResponse.email);
                this.props.CognitoLoginError(null);
                this.onSignupResendCode(userDetails.getUserDetailsResponse.email);
                this.props.history.push(`/userauth/userlogin`);
            }

            else if (this.state.signUpCode && userDetails.getUserDetailsResponse.status === 'PENDING') {
                if (userDetails.getUserDetailsResponse.userIDP != null && userDetails.getUserDetailsResponse.userIDP == 'idaas') {
                    let email = userDetails.getUserDetailsResponse.email
                    if (userDetails.getUserDetailsResponse.userStatus === 'PENDING')
                        window.location.href = `/auth-login/idaas-signup?emailRO=${encodeURIComponent(email)}`;
                    else
                        window.location.href = `/auth-login/idaas`;
                }
                else {
                    this.props.CognitoLoginStateUpdate(loginState.signupPrefilled);
                    this.props.CognitoLoginError(null);
                    this.props.history.push(`/userauth/userlogin`);
                }
            }
            else if (this.state.signUpCode && userDetails.getUserDetailsResponse.status === 'ACTIVE') {
                if (userDetails.getUserDetailsResponse.userIDP != null && userDetails.getUserDetailsResponse.userIDP == 'idaas')
                    window.location.href = `/auth-login/idaas`;
                else
                    this.props.history.push(`/userauth/userlogin`);
            }
            else if (this.state.loginCode) {
                let defaultUser = new UserType(userDetails.getUserDetailsResponse);
                this.initState(defaultUser);
                this.props.CognitoLoginError(null);
                if (this.props.features['passwordLess'])
                    if (!defaultUser) this.props.CognitoLoginError("Sorry! This link has either expired or is invalid.")
            }
        }
    }

    onSignupResendCode = (username: any) => {
        this.props.SignupResendCodeAction(username);
    }

    handleuserNameChange = (id: any, value: any) => {
        this.setState({ userName: value });
    }

    handlePasswordChange = (event: any, prop: any) => {
        this.setState({ password: event.target.value });
    }

    onSignIn = () => {
        //Check if username and password 
        if (this.state.user == null && this.props.features['passwordLess'])
            return;
        if (!this.props.features['passwordLess'] && this.state.userName === '')
            this.setState({ isUserNameErr: true })
        if (!this.props.features['passwordLess'] && this.state.password === '')
            this.setState({ validpassword: false })

        if ((!this.props.features['passwordLess'] && this.state.userName != '') ||
            ((this.props.features['passwordLess'] && this.state.userName != ''))) {
            this.props.CognitoLoginStateUpdate(null, this.state.userName, this.state.password);
            this.props.signInAction(this.state.userName, this.state.password, this.props.endpoint);
        }

    }

    onPasswordReset = () => {
        this.props.CognitoLoginStateUpdate(loginState.resetPassword, this.state.userName);
        this.props.CognitoLoginError(null);
        this.props.history.push(`/userauth/userlogin`);
    }

    onSignupSelect = () => {
        this.props.CognitoLoginStateUpdate(loginState.signup);
        this.props.CognitoLoginError(null);
        this.props.history.push(`/userauth/userlogin`);
    }

    isValidPassword = () => {
        const regex = "[^\s]";
        if (this.state.password != null &&
            this.state.password.match(regex))
            this.setState({
                validpassword: true
            })
        else {
            this.setState({
                validpassword: false
            })
        }
    }


    render() {
        const { classes, cognitoLogin, features, signin } = this.props;
        return (
            <React.Fragment>
                {this.props.cognitoLogin.errorMessage && this.props.cognitoLogin.errorMessage === 'Login Code Invalid/Expired.' ?
                    < Redirect to='/error' /> : null}
                {this.props.endpoint != null && !this.props.endpoint.includes("idaas") && <div className={classes.container} data-test='SignIn'>
                    <div className={classes.container}>
                        <div className={classes.inputholder}>
                            <TextInput
                                forceValidate={this.state.isUserNameErr}
                                size="medium"
                                isFocus={false}
                                id="username"
                                validateRegex="[^\s]"
                                handleInPutChange={this.handleuserNameChange}
                                assistiveText={"Enter Email"}
                                label={"Email"}
                                defaultValue={this.state.userName}
                                length={100} >
                            </TextInput>
                        </div>
                        {
                            Boolean(!features['passwordLess']) &&
                            <div className={classes.passwordTB}>
                                <Password
                                    size="medium"
                                    id="password"
                                    guptshabd={this.state.password}
                                    handlePasswordChange={this.handlePasswordChange}
                                    validpassword={this.state.validpassword}
                                    isValidPassword={this.isValidPassword}
                                    helperText="Enter Password"
                                />
                            </div>
                        }
                        <Typography className={classes.errorMessage}>{this.props.cognitoLogin.errorMessage}</Typography>
                        {
                            Boolean(!features['passwordLess']) &&
                            <Link data-test="forget-pw-btn" className={classes.forgotPasswordLink} onClick={() => this.onPasswordReset()} >Forget Password?</Link>
                        }
                    </div>
                    <div className={classes.row}>
                        <Fab data-test='passwordLess-btn'
                            disabled={(this.props.features['passwordLess'] && !this.state.user)}
                            variant='extended' color='primary' className={classes.fabBtn} type="submit"
                            onClick={() => this.onSignIn()}>
                            LOGIN
                        </Fab>

                        {Boolean(features['proxySignup']) &&
                            <div data-test='proxySignup-btn'
                                className={classes.signUpLink} onClick={() => this.onSignupSelect()}>
                                <Link>CREATE A NEW ACCOUNT</Link>
                            </div>
                        }
                    </div>
                </div>}
                {this.props.endpoint != null && this.props.endpoint.includes("idaas") && <Typography className={classes.errorMessage}>{this.props.cognitoLogin.errorMessage}</Typography>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        features: state.homeReducer.features,
        signin: state.cognitoLoginReducer.signin,
        cognitoLogin: state.appUIStateReducer.cognitoLogin,
        userDetails: state.userDetailsReducer.userDetails,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        signInAction: (username: any, password: any, endpoint: any) => dispatch({
            type: CognitoActionTypes.COGNITO_API_LOGIN_REQUEST,
            username: username,
            password: password,
            endpoint: endpoint
        }),
        CognitoLoginStateUpdate: (state?: any, userName?: any, password?: null) => dispatch({
            type: AppStateActionTypes.UPDATE_COGNITO_LOGIN_STATE,
            state: state,
            userName: userName,
            password: password
        }),
        CognitoLoginError: (errorMessage: any) => dispatch({
            type: AppStateActionTypes.UPDATE_COGNITO_ERROR,
            errorMessage: errorMessage
        }),
        GetUserDetailsAction: (signupCode: any) => dispatch({
            type: UserDetailsActionTypes.USER_DETAILS_REQUEST,
            signupCode: signupCode
        }),
        SignupResendCodeAction: (username: string) =>
            dispatch({
                type: CognitoActionTypes.COGNITO_API_SIGNUP_RESEND_CODE,
                username: username
            })
    }
}

export const SignInComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SignIn));

export default withRouter(SignInComponent);