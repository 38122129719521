import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ItemsActionTypes from '../../../redux/constants/personalVault/itemsConstants';
import * as AppUIStateActionTypes from '../../../redux/constants/shared/appStatesConstants';
import { TextField, Button } from '@material-ui/core';

import './AddFolder.scss';

type AddFolderProps = {
    updateCommonModal: any;
    selectedItem: any,
    isRename: any,
    updateItemAPI: any,
    foldersPath: any
    createFolderAPI: any,
    createdFolderResponse: any,
    createdFolderResponseError: any,
    handleModalClose: any
}

type AddFolderState = {}

class AddFolder extends Component<AddFolderProps, AddFolderState> {
    state = {
        headerTitle: 'Add a Folder',
        actionButton: 'Add',
        characterLimit: 50,
        errorMessage: '',
        itemType: '',
        allowedName: '^[a-zA-Z0-9 ()/@&\'\".,-]*$',
        folderName: '',
        folderCode: null
    }
    
    componentDidMount() {
        const { selectedItem, isRename } = this.props;
        if (selectedItem != null && isRename) {
            let itemType = selectedItem.itemType;
            let itemName = selectedItem.itemName;
            let itemCode = selectedItem.itemCode;
            switch (itemType) {
                case 'FOLDER': this.setState({ headerTitle: 'Rename folder', itemType: 'folder' }); break;
                case 'FILE': this.setState({ headerTitle: 'Rename file', itemType: 'file' }); break;
            }
            this.setState({ folderName: itemName, folderCode: itemCode, actionButton: 'Save' })
        }
    }

    setErrorMessage = (errCode: any) => {
        switch (errCode) {
            case 675:
                this.setState({ errorMessage: 'A ' + this.state.itemType + ' with same name already exists' });
                break;
            default:
                this.setState({ errorMessage: 'There was an issue. Please try again' })
        }
    }


    handleCancelClick = () => {
        this.props.updateCommonModal(false, null);
    }

    renameFolder = (newFolderName: string, folderCode: any) => {
        let currentFolder = this.props.foldersPath[this.props.foldersPath.length - 1];
        this.props.updateItemAPI(newFolderName, this.state.folderCode, currentFolder.itemCode, '')
    }

    handleSaveClick = () => {
        const { isRename } = this.props;
        var error = 0
        if (this.state.folderName.length > this.state.characterLimit) {
            error = 1
            this.setState({ errorMessage: "Folder name cannot be longer than 50 characters" })
        }
        else if (!this.state.folderName.match(this.state.allowedName)) {
            error = 1
            this.setState({ errorMessage: "This folder name is not allowed" })
        }
        else {
            if (!isRename) {
                this.createFolder(this.state.folderName);
                if (this.props.createdFolderResponseError === null) {
                    this.props.updateCommonModal(false, null);
                    // this.props.handleModalClose(true, this.props.createdFolderResponse.itemCode);
                }
            }
            else {
                this.renameFolder(this.state.folderName, this.state.folderCode);
                if (this.props.createdFolderResponseError != null) {
                    this.props.updateCommonModal(false, null);
                }
            }
        }
    }

    createFolder(folderName: string) {
        const { createFolderAPI } = this.props
        let currentFolder = this.props.foldersPath[this.props.foldersPath.length - 1];
        createFolderAPI(folderName, currentFolder.itemCode)
    }

    handleChange = (event: any) => {
        this.setState({ errorMessage: '' })
        this.setState({ folderName: event.target.value });
    };

    renderProgressBar() {
        return (
            <div>
                {/* <ProgressBar></ProgressBar> */}
            </div>
        )
    }

    render() {
        return(
            <div className='add-folder-wrapper'>
                <div className='add-folder-title'>Add a Folder</div>
                <div>
                    <div className='folder-input-wrapper'>
                        <TextField id="outlined-basic" className='folder-input' label="New Folder" variant="outlined" value={this.state.folderName}
                            onChange={this.handleChange} autoFocus />
                    </div>
                </div>
                <div className='add-folder-action-bar'>
                    <Button onClick={this.handleCancelClick} className='add-folder-btn-cancel'>Cancel</Button>
                    <Button className='add-folder-btn-save' onClick={this.handleSaveClick} variant="contained" color="primary">{this.state.actionButton}</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        createdFolderResponse: state.itemsReducer.createdFolderInfo.response,
        createdFolderResponseError: state.itemsReducer.createdFolderInfo.error,
        folderAPIfetching: state.itemsReducer.createdFolderInfo.fetching,
        updateItemProgress: state.itemsReducer.updateItem.updating,
        updateItemResponseError: state.itemsReducer.updateItem.error,
        foldersPath: state.itemsReducer.breadcrumbInfo.folders
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createFolderAPI: (folderName: string, currentFolderCode: string) => dispatch({ 
            type: ItemsActionTypes.CREATE_FOLDER_API_CREATE_REQUEST, 
            folderName, 
            currentFolderCode 
        }),
        updateItemAPI: (itemName: string, itemCode: string, destinationCode: string, description: string) => dispatch({ 
            type: ItemsActionTypes.FOLDER_API_UPDATE_ITEM, 
            itemName, 
            itemCode, 
            destinationCode, 
            description 
        }),
        updateCommonModal: (openState: boolean, content: any) => dispatch({
            type: AppUIStateActionTypes.UPDATE_COMMON_MODAL,
            openState: openState,
            content: content
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFolder)