import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import FileDetailMenu from "../../../pages/shared/fileDetail/components/fileDetailMenu/FileDetailMenu"
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { useState, useEffect } from 'react';

const useStyles = makeStyles((theme: any) => ({
  list: {
    width: 340,
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth
    },
  }
}));

export default function CommonDrawer(props: any) {
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    setisOpen(props.isOpen)

  });

  const classes = useStyles();

  const toggleDrawer = (open: any) => (event: any) => {
    setisOpen(false)
  };

  const drawerContent = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton title="Close" aria-label="Close Menu" className='menu-close-btn' onClick={props.handleClose}>
        <CloseIcon />
      </IconButton>
      <FileDetailMenu />
    </div>
  );

  return (
    <div data-test='commonDrawer'>
      <React.Fragment key={'right'}>
        <Drawer anchor={'right'} open={isOpen} onClose={toggleDrawer(false)}>
          {drawerContent()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

