import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  error: {
    marginTop: 64,
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVar: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    // fontFamily: 'Lato',
    fontSize: '14px'
  },
});


class ErrorSnackbar extends React.Component {

  state = {
    snackBarOpen : true
  }

  handleClose = () => {
    this.setState({snackBarOpen: false})
    this.props.handleCloseErrorDisplay()
  }

  render() {
    const { error, classes } = this.props;

    let errorMessage;
    if (error) {
      if (error.response && error.response.data && error.response.data.errMsg) {
        errorMessage = error.response.data.errMsg;
        } 
      else if (error.response.statusText) {
        errorMessage = error.response.statusText;
      }else{
        errorMessage = "Unknown Error occured, please try again!"
      }
    }
      
      return (
        <Snackbar data-test='ErrorSnackBar'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.state.snackBarOpen}
        >
            <SnackbarContent
            className={classNames(classes.error)}
            message={
                <span className={classes.message}>
                <ErrorIcon className={classNames(classes.icon, classes.iconVar)} />
                {errorMessage}
                </span>
            }
            action={
                <IconButton
                data-test='ErrorSnackBarButton'
                color='inherit'
                onClick={this.handleClose}
                >
                <CloseIcon className={classes.icon} />
                </IconButton>
            }
            />
        </Snackbar>
      );
    
  }
}

export default withStyles(styles)(ErrorSnackbar);