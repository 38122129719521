import React, { Component } from 'react';
import moment from 'moment-timezone';
import { ReactComponent as FidelityLogoIcon } from '../../../assets/logo-fidelity-white.svg';
import {con} from '../../../shared/config/config';
import FidelityLogoIconCognito from '../../../assets/logo-fidelity.png';

export const getFullName = (contact) => {
    const name = contact.lastName + ', '
        + contact.firstName
        + (contact.middleName ? (', ' + contact.middleName) : '');
    return name;
};

export const getAddedToGroup = (contact) => {
    if (contact.addedToGroup) {
        return contact.addedToGroup.substring(0, 10);
    }
    else {
        return 'N/A'
    }
};

export const getPhoneNumber = (contact) => {
    if (contact.phoneNumber) {
        switch (contact.countryPhoneCode) {
            case 1:
                let code = contact.phoneNumber.substring(0, 3)
                let first = contact.phoneNumber.substring(3, 6);
                let last = contact.phoneNumber.substring(6,);
                let number = code + '-' + first + '-' + last;
                return number;
            default:
                return null;
        }
    }
    else {
        return 'N/A'
    }
};

export const getEmail = (contact) => {
    if (contact.email) {
        return contact.email
    }
    else {
        return 'N/A'
    }
}

export const getLastLogin = (contact) => {
    if (!contact.lastLogin) {
        return 'Never logged In'
    }
    let date = contact.lastLogin.substring(0, 10);
    let hour = contact.lastLogin.substring(11, 13);
    let time = 'AM';
    if (parseInt(hour) > 12) {
        hour = hour - 12;
        time = 'PM';
    }
    let minutes = contact.lastLogin.substring(13, 16);
    let finalTime = date + ', ' + hour + minutes + time + ' EST'
    return finalTime;
}

export const convertPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString.includes('+')) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return phoneNumberString;
    } else {
        return phoneNumberString;
    }
}

export const formattedDateTime = (date) => {
    return moment.utc((date)).tz("America/New_York").format('MMM-D-YYYY h:mm A') + ' ET';
}

export const formattedDate = (date) => {
    return moment.utc((date)).tz("America/New_York").format('MMM-D-YYYY')
}

export const selectLogo = (product) => {
    let env = con.ENVIRONMENT.toLowerCase(); 
    switch (product) {
        case ("charitable"):
            if (env == "dev" || env == "qa") {
                return  <img src = {`https://cdn.${env}.app.fidsafe.com/public/charitable-logo.jpg`}/>
            } else {
                return <img src = {`https://cdn.app.fidsafe.com/public/charitable-logo.jpg`}/>
            }
        case ("catchlt"):
            if (env == "dev" || env == "qa") {
                return  <img src = {`https://cdn.${env}.app.fidsafe.com/public/catchlt-logo.jpg`}/>
            } else {
                return <img src = {`https://cdn.app.fidsafe.com/public/catchlt-logo.jpg`}/>

            }
        default: return <img style={{width: '200px'}} src={FidelityLogoIconCognito} />
    } 
}

export const selectCognitoPageLogo = (product) => {
    let env = con.ENVIRONMENT.toLowerCase(); 
    switch (product) {
        case ("charitable"):
            if (env == "dev" || env == "qa") {
                return  <img src = {`https://cdn.${env}.app.fidsafe.com/public/charitable-logo.jpg`}/>
            } else {
                return <img src = {`https://cdn.app.fidsafe.com/public/charitable-logo.jpg`}/>
            }
        case ("catchlt"):
            if (env == "dev" || env == "qa") {
                return  <img src = {`https://cdn.${env}.app.fidsafe.com/public/catchlt-logo.jpg`}/>
            } else {
                return <img src = {`https://cdn.app.fidsafe.com/public/catchlt-logo.jpg`}/>
            }
        default: return <img style={{width: '200px'}} src={FidelityLogoIconCognito} />
    } 
}
