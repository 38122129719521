import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { ReactComponent as LogoutIcon } from '../../../assets/logout-white.svg';
import './CvToolbarActions.scss';
import { ReactComponent as ProfileIcon } from '../../../assets/White.svg'
import { con } from '../../../shared/config/config'

type ToolbarActionsProps = {
    openSearchbar: any,
    showSearchIcon: boolean,
    onLogoutClick: any,
    loggedIn: any,
    onRightSlide: any,
}

type ToolbarActionsStates = {}

class CvToolbarActions extends Component<ToolbarActionsProps, ToolbarActionsStates> {
    // handleToggleSearchbar = (open: boolean) => {
    //     this.props.openSearchbar(open)
    // }
    state = {
        hideLogoutButton:false
    };

    componentDidMount() {
        const redirectDescriminator = con.REDIRECTION;
        if(redirectDescriminator === 'advisor') {
            this.setState({hideLogoutButton : true});
        }
    }

    logout = () => {
        this.props.onLogoutClick();
    }

    slideDrawer = () => {
        this.props.onRightSlide();
    }

    render() {
        return (
            <div data-test='CvToolbarActions'>
                {this.props.showSearchIcon &&
                    <div className='actions-wrapper'>
                        {/*  <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={() => this.handleToggleSearchbar(true)}
                            edge='start'
                            className='menu-button'>
                            <SearchIcon />
                        </IconButton> */ }
                        {this.props.loggedIn.user_profile != null &&
                            <>
                                <div>Welcome {this.props.loggedIn.user_profile.firstName}</div>
                            </>
                        }
                         <IconButton data-test='menuBtn'
                            color='inherit'
                            aria-label='My Profile'
                            title='My Profile'
                            onClick={() => this.slideDrawer()}
                            edge='start'
                            className='menu-button'>
                            {/* <ExitToAppIcon /> */}
                            {/* <img src={logoutIcon}/> */}
                            <ProfileIcon />
                        </IconButton>

                        {!this.state.hideLogoutButton && <IconButton data-test='logoutBtn'
                            color='inherit'
                            aria-label='Log Out'
                            title='Log Out'
                            onClick={() => this.logout()}
                            edge='start'
                            className='menu-button'>
                            {/* <ExitToAppIcon /> */}
                            {/* <img src={logoutIcon}/> */}
                            <LogoutIcon />
                        </IconButton>
                        }
                    </div>
                }
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.login,
        //   selectedGroup: state.appUIStateReducer.selectedGroup,
    };
}

const mapDispatchToProps = () => {
    return {
        //   updateBreadcrumbItem: (itemName: any, itemLink: any) => dispatch({
        //     type: AppUIStateActionTypes.UPDATE_BREADCRUMB_ROOT_ITEM,
        //     itemName: itemName,
        //     itemLink: itemLink
        //   })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CvToolbarActions);
