import React, { useState, useEffect, useRef } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import { Card, Checkbox, CircularProgress, IconButton } from '@material-ui/core';
import fileIcon from '../../../assets/file-grey.svg';
import folderIcon from '../../../assets/folder-black.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as AlertAttention } from '../../../assets/alert-attention.svg';
import formIcon from '../../../assets/smart-form-icon.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { ItemCeremonyStatus } from '../../../shared/core/CeremonyStatus';
import CancelIcon from '@material-ui/icons/Cancel';
import { formattedDateTime } from '../../core/utils/functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
            backgroundColor: '#F9F9F9',
            padding: '7px',
            flexDirection: 'row',
            alignContent: 'flex-start'
        },
        toolBar: {
            backgroundColor: '#fff',
            padding: '0 3px',
            borderBottom: '1px solid #c5c5c5'
        },
        fileCard: {
            width: '268px !important',
            height: '120px !important',
            margin: '7px',
            transition: 'all ease 0.3s',
            display: 'flex',
            padding: '20px 16px 20px 0 !important',
            flexDirection: 'row',
            '&:hover': {
                transform: 'scale(1.02)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100% !important',
                padding: '8px 8px 8px 0 !important',
                height: '90px !important',
            },
            [theme.breakpoints.between('sm', 'md')]: {
                // width: 'calc(50% - 30px) !important',
                height: '90px !important'
            }
        },
        fileCardSelected: {
            backgroundColor: theme.palette.primary.main[500],
        },
        leftSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
        rightSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        },
        contentWrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between'
        },
        fileIconWrapper: {
            margin: '0 6px 0 16px'
        },
        typeIcon: {
            marginRight: '8px',
            width: '40px'
        },
        checkbox: {
            marginLeft: '10px',
            marginTop: '4px'
        },
        fileTitle: {
            width: '100%',
            maxWidth: '185px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 700,
            fontSize: 14,
            margin: 0,
            textTransform: 'capitalize',
            color: 'rgba(0, 0, 0, 0.72)',
            cursor: 'pointer',
            '&:hover': {
                color: '#0E67A9',
            }
        },
        momentContent: {
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '20px',
            color: 'rgba(68,70,87,0.75)',
            margin: 0
        },
        sizeContent: {
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: '20px',
            color: 'rgba(68,70,87,0.75)',
            margin: 0,
        },
        permissionBtn: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem',
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            fontFamily: 'Fidelity Sans',
            marginTop: '1rem'
        },
        permissionStatic: {
            textTransform: 'capitalize',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: '.875rem',
            display: 'flex',
            width: 'calc(100% - 16px)',
            justifyContent: 'flex-end',
            padding: '8px 8px',
            marginTop: '1rem'
        },
        progressbar: {
            height: '4px'
        },
        loadingContext: {
            color: '#8a8a8a',
            fontWeight: 700,
            fontSize: '11px',
            marginLeft: '10px'
        },
        loadingWrapper: {
            margin:20,
            width: '100%',
            textAlign: 'center',
            height:'1rem'
            
        },
        iconBtn:
        {
            width: '20px',
            height: '20px',
        },
        iconholder:
        {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
            //padding: '0.8rem'
        }
    }),
);

interface fileType {
    itemCode: string,
    itemName: string,
    itemType: string,
    size: number,
    mimeType: string,
    title: string,
    createdAt: string,
    modifiedAt: string,
    owner: OwnerType,
    status: string,
    permissionName: string,
    ceremonyStatus: string
}

interface OwnerType {
    grpName: string,
    grpCode: string,
    userCode: string,
    userFirstName: string,
    userLastName: string
}

type CommonFileGridListProps = {
    files: fileType[],
    allowChangePermission: boolean,
    onSelection: any,
    showOnwer: boolean,
    tableMarginMinHeight?: number,
    tableMarginMaxHeight?: number,
    showPermission: boolean,
    showFileSize: boolean,
    showUploaded: boolean,
    showMeAsOnwer: boolean,
    onFileOpen: any,
    onFolderOpen: any,
    filesFetching: boolean,
    fetchMoreData?: any,
    lazyLoading?: boolean,
    noMoreData?: boolean,
    resetSelection?: boolean,
    allFetched?: boolean,
    onSmartFormOpen?: any,
    esignStatusOpen?: any
}

const CommonFileGridList = (props: CommonFileGridListProps) => {
    const classes = useStyles();
    const [permission, setPermission] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<string[]>([]);
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const gridContainer: any = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (props.resetSelection) {
            setSelected([])
            props.onSelection([]);
        }
    }, [props.resetSelection, props.allFetched])

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changePermission = (permission: string) => {
        setPermission(permission);
        handleClose();
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = props.files.map(n => n.itemCode);
            setSelected(newSelecteds);
            props.onSelection(newSelecteds);
        }
        else {
            setSelected([]);
            props.onSelection([]);
        }
    };

    const itemOnClick = (event: React.MouseEvent<unknown>, name: string, type: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        props.onSelection(newSelected);
    }

    const itemOnOpen = (event: React.MouseEvent<unknown>, itemCode: string, type: string, file: any) => {
        if (type === 'FILE') {
            props.onFileOpen(file)
        } else if (type === 'FORM') {
            event.stopPropagation();
            props.onSmartFormOpen(file);
        } else {
            event.stopPropagation();
            props.onFolderOpen(file)
        }
        setSelected([]);
        props.onSelection([]);
    }

    const sizeConverter = (size: number) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '-';
        let i = Math.floor(Math.log(size) / Math.log(1024));
        return Math.round(size / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    return (
        <>
            {!props.filesFetching &&
                <>
                    <div className={classes.toolBar} data-test='commonFileGridList'>
                        {props.files.length > 0 && <Checkbox color="primary"
                            data-test="checkboxTool"
                            indeterminate={selected.length > 0 && selected.length < props.files.length}
                            checked={props.files.length > 0 && selected.length === props.files.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all files' }}
                        />
                        }
                    </div>
                    <div className={classes.root} ref={gridContainer}>
                        <GridList cellHeight={160} data-test="sectionDiv">
                            {props.files.map((file, index: number) => {
                                const isItemSelected = isSelected(file.itemCode);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <Card className={`${classes.fileCard} ${isItemSelected ? classes.fileCardSelected : null}`}
                                        key={index}
                                        data-test="cardItemVal"
                                        onClick={event => itemOnClick(event, file.itemCode, file.itemType)}
                                        role="checkbox"
                                    >
                                        <section className={classes.leftSection}>
                                            <div className={classes.fileIconWrapper}>
                                                {file.ceremonyStatus === ItemCeremonyStatus.REQUESTED &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Requested">
                                                        <IconButton aria-label="Signing Ceremony Requested" data-test="requested" onClick={(event) => { props.esignStatusOpen(file); event.stopPropagation(); }}>
                                                            <AlertAttention aria-label="Signing Ceremony Requested" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.ceremonyStatus === ItemCeremonyStatus.IN_PROGRESS &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Requested">
                                                        <IconButton aria-label="Signing Ceremony Requested" data-test="progress" onClick={(event) => { props.esignStatusOpen(file); event.stopPropagation(); }}>
                                                            <AlertAttention aria-label="Signing Ceremony Requested" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.ceremonyStatus === ItemCeremonyStatus.CANCELLED &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Cancelled">
                                                        <IconButton aria-label="Signing Ceremony Cancelled" data-test="cancelled" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                            <ErrorOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.ceremonyStatus === ItemCeremonyStatus.WAITING_SIGNED_DOC &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Waiting Signed Document">
                                                        <IconButton aria-label="Waiting Signed Document" data-test="waiting" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                            <AlertAttention color="primary" aria-label="Waiting Signed Document" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.ceremonyStatus === ItemCeremonyStatus.AGREED &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Agreed">
                                                        <IconButton aria-label="Signing Ceremony Agreed" data-test="agreed" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                            <CheckCircleIcon color="primary" aria-label="Signing Ceremony Agreed" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.ceremonyStatus === ItemCeremonyStatus.FAILED &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Failed">
                                                        <IconButton aria-label="Signing Ceremony Failed" data-test="failed" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                            <CancelIcon color="error" aria-label="Signing Ceremony Failed" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.ceremonyStatus === ItemCeremonyStatus.DECLINED &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Declined">
                                                        <IconButton aria-label="Signing Ceremony Declined" data-test="declined" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                            <CancelOutlinedIcon aria-label="Signing Ceremony declined" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                 {file.ceremonyStatus === ItemCeremonyStatus.REVOKED &&
                                                    <Tooltip disableFocusListener disableTouchListener title="Signing Ceremony Cancelled">
                                                        <IconButton aria-label="Signing Ceremony Declined" data-test="declined" onClick={(event) => { props.esignStatusOpen(file, 'CEREMONY_REQUESTED_INSTUSER'); event.stopPropagation(); }}>
                                                            <CancelOutlinedIcon aria-label="Signing Ceremony declined" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {file.itemType === 'FILE' && typeof file.ceremonyStatus === 'undefined' &&
                                                    <div className={classes.iconholder}>
                                                        <img className={classes.typeIcon} src={fileIcon} />
                                                    </div>
                                                }
                                                {file.itemType === 'FOLDER' && typeof file.ceremonyStatus === 'undefined' &&
                                                    <div className={classes.iconholder}>
                                                        <img className={classes.typeIcon} src={folderIcon} />
                                                    </div>
                                                }
                                                {file.itemType === 'FORM' && typeof file.ceremonyStatus === 'undefined' &&
                                                    <div className={classes.iconholder}>
                                                        <img className={classes.typeIcon} src={formIcon} />
                                                    </div>
                                                }
                                            </div>
                                            <Checkbox color='primary' className={classes.checkbox}
                                                checked={isItemSelected}
                                                data-test="checkboxCardItem"
                                                onChange={(e: any) => itemOnClick(e, file.itemCode, file.itemType)}
                                                inputProps={{ 'aria-labelledby': labelId }} />
                                        </section>
                                        <section className={classes.rightSection}>
                                            <div className={classes.contentWrapper}>
                                                <p className={classes.fileTitle} data-test="fileCheckBox" onClick={event => itemOnOpen(event, file.itemCode, file.itemType, file)}>
                                                    {file.itemName}
                                                </p>
                                                {props.showFileSize &&
                                                    <p className={classes.sizeContent} data-test="sizeConverterTest">
                                                        File size: {file.size === 0 ? '-' : sizeConverter(file.size)}
                                                    </p>
                                                }
                                                {/* <p className={ classes.momentContent }>Modified: <Moment className={ classes.momentContent } format="MMM DD, YYYY">{file.modifiedAt}</Moment></p> */}
                                                <p className={classes.momentContent}>Modified: <span className={classes.momentContent}>{formattedDateTime(file.modifiedAt)}</span></p>
                                            </div>
                                            {props.showOnwer &&
                                                <p className={classes.momentContent}>Owner: {file.owner.userFirstName} {file.owner.userLastName}</p>
                                            }
                                            {props.showPermission && !props.allowChangePermission &&
                                                <span className={classes.permissionStatic}>{file.permissionName === 'VIEW' ? 'View' : 'View & Download'}</span>
                                            }
                                            {props.showPermission && props.allowChangePermission &&
                                                <>
                                                    <Button onClick={openMenu} className={classes.permissionBtn} >
                                                        {file.permissionName === 'VIEW' ? 'View' : 'View & Download'} <ArrowDropDownIcon />
                                                    </Button>
                                                    <Menu
                                                        id="permission-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleClose}>
                                                        <MenuItem onClick={() => changePermission('VIEW')}>View</MenuItem>
                                                        <MenuItem onClick={() => changePermission('DOWNLOAD')}>View & Download</MenuItem>
                                                    </Menu>
                                                </>
                                            }
                                        </section>
                                    </Card>
                                )
                            })}
                        </GridList>
                    </div>
                </>
            }
            {props.filesFetching &&
                <>
                    <LinearProgress data-test='commonFileGridListDataFetching' className={classes.progressbar} variant="query" />
                </>
            }
        </>

    )
}

export default CommonFileGridList;