import {
    FETCH_THEME_S3_REQUEST,
    FETCH_THEME_S3_SUCCESSFUL,
    FETCH_THEME_S3_FAILURE,
    UPDATE_THEME_LOCAL
} from '../../constants/shared/themeConstants';
import { cvTheme } from './cvTheme';
import { pvTheme } from './pvTheme';

const initialState = {
    themeFile: {
        fetching: true,
        content: '',
        error: null,
    },

};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_THEME_S3_REQUEST:
            return { ...state, themeFile: { fetching: true, content: '', error: null } }

        case FETCH_THEME_S3_SUCCESSFUL:
            return { 
                ...state, 
                themeFile: { 
                    fetching: false, 
                    content: action.responseInfo.data, 
                    error: null 
                } }

        case FETCH_THEME_S3_FAILURE:
            const errorInfo = { response: { data: { errMsg: action.responseInfo } } }
            return { ...state, themeFile: { fetching: false, content: '', error: { ...errorInfo, action: FETCH_THEME_S3_FAILURE } } }

        case UPDATE_THEME_LOCAL:
            return {
                ...state,
                themeFile: {
                    fetching: false, 
                    content: action.vaultName === 'cv' ? cvTheme : pvTheme,
                    error: null
                }    
            }

        default:
            return state;
    }
}
