import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';
import moment from 'moment-timezone';

import './UserFilterBox.scss';


interface TopicType {
    stCode: string,
    categoryName: string,
    createdAt: string,
    modifiedAt: string,
    categoryIconColor: string,
    catgeoryIconLabel: string,
    sharedTopicItemsCount: number,
    sharedTopicUsersCount: number,
}

interface groupType {
    groupCode: string,
    groupName: string,
    topics: TopicType[]
}


type FilterBoxProps = {
    filterTopics: any,
    resetFilter: any,
    groups: groupType[],
    filterselected: any
}

type FilterBoxStates = {

}

class UserFilterBox extends Component<FilterBoxProps, FilterBoxStates> {
    state = {
        cat: [],
        created: null,
        modified: null,
        timeStampOptions: [
            { title: 'Within 24 Hours', value: 'lastDay' },
            { title: 'Within Week', value: 'lastWeek' },
            { title: 'Within Month', value: 'lastMonth' },
            { title: 'Within Year', value: 'lastYear' },
        ]
    }

    componentDidMount() {
        const yesterday = moment().subtract(24, 'hours').toDate();
        const lastWeek = moment().startOf('day').subtract(1, 'weeks').toDate();
        const lastMonth = moment().startOf('day').subtract(1, 'months').toDate();
        const lastYear = moment().startOf('day').subtract(1, 'years').toDate();
        this.setState({
            timeStampOptions: [
                { title: 'Within 24 Hours', value: yesterday },
                { title: 'Within Week', value: lastWeek },
                { title: 'Within Month', value: lastMonth },
                { title: 'Within Year', value: lastYear }
            ]
        })
    }

    filterOnChange = (e: any, val: any, id: string) => {
        switch (id) {
            case 'GROUP':
                this.setState({ cat: val }, () => {
                    this.props.filterTopics(id, this.state.cat, this.state.created, this.state.modified);
                    if (this.state.cat.length > 0 || this.state.created != null || this.state.modified
                        != null)
                        this.props.filterselected(true);
                    else
                        this.props.filterselected(false);
                });
                break;
            case 'CREATED':
                this.setState({ created: val }, () => {
                    this.props.filterTopics(id, this.state.cat, this.state.created, this.state.modified);
                    if (this.state.cat.length > 0 || this.state.created != null || this.state.modified
                        != null)
                        this.props.filterselected(true);
                    else
                        this.props.filterselected(false);
                });
                break;
            case 'MODIFIED':
                this.setState({ modified: val }, () => {
                    this.props.filterTopics(id, this.state.cat, this.state.created, this.state.modified);
                    if (this.state.cat.length > 0 || this.state.created != null || this.state.modified
                        != null)
                        this.props.filterselected(true);
                    else
                        this.props.filterselected(false);
                });
                break;
        }

    }

    resetFilter = () => {
        this.setState({
            cat: [],
            created: null,
            modified: null,
        }, () => {
            this.props.resetFilter()
        })
    }

    render() {

        // let autoCompleteProps = null;
        // if (this.state.cat !== null)
        //     autoCompleteProps = { value: this.state.cat }

        return (
            <div className="filter-wrapper" data-test='UserFilterBox'>
                <div className="filter-group">
                    <Autocomplete data-test='autocomplete-field'
                        id="category-select"
                        className="filter"
                        size="medium"
                        limitTags={1}
                        ChipProps={{ 'size': 'small' }}
                        multiple
                        filterSelectedOptions
                        value={this.state.cat}
                        options={this.props.groups}
                        getOptionLabel={(option) => option.groupName.length >= 15 ? option.groupName.substring(0, 15) + "..." : option.groupName}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                {/*     <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              /> */}
                                {option.groupName}
                            </React.Fragment>
                        )}
                        onChange={(e: any, val: any) => this.filterOnChange(e, val, 'GROUP')}
                        renderInput={(params) => <TextField className="filter-input" {...params} label="Product Group" variant="outlined" />}
                    />
                </div>
                <div className="filter-group1">
                    <Autocomplete data-test='autocomplete-created'
                        id="created-select"
                        className="filter"
                        size="medium"
                        options={this.state.timeStampOptions}
                        onChange={(e: any, val: any) => this.filterOnChange(e, val, 'CREATED')}
                        getOptionLabel={(option) => option.title}
                        value={this.state.created}
                        renderInput={(params) => <TextField className="filter-input" {...params} label="Created" variant="outlined" />}
                    />
                    <Autocomplete data-test='autocomplete-modified'
                        id="modified-select"
                        className="filter"
                        size="medium"
                        options={this.state.timeStampOptions}
                        onChange={(e: any, val: any) => this.filterOnChange(e, val, 'MODIFIED')}
                        getOptionLabel={(option) => option.title}
                        value={this.state.modified}
                        renderInput={(params) => <TextField className="filter-input" {...params} label="Modified" variant="outlined" />}
                    />
                    <Button data-test='reset-btn' color="primary" className="reset-btn" onClick={this.resetFilter}>
                        RESET
                </Button>
                </div>
            </div>
        )
    }
}

export default UserFilterBox;
