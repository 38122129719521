import * as GroupDetailActionTypes from '../../constants/personalVault/groupDetailConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    groupItemsSharedByMe: {
        fetching: false,
        files: [],
        error: null
    }
}

function groupItemsSharedByMe (state = initState.groupItemsSharedByMe, action) {
    switch (action.type) {
        case GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_REQUEST:
            return {
                ...state,
                fetching: true,
                files: []
            };
        case GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_SUCCESS:
            return {
                ...state,
                fetching: false,
                files: action.files
            };
        case GroupDetailActionTypes.GROUP_DETAIL_GET_ITEM_SHARED_BY_ME_FAILURE:
            return {
                ...state,
                fetching: false,
                files: [],
                error: {
                    ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND
                }
            };
        default:
            return state;
    }
}

export default groupItemsSharedByMe;