import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { RouteProps, RouteComponentProps } from "react-router";

type AuthorizationRouteProps = {
    loggedIn?: any;
    authKey: any;
    children?: never[];
    redirectTo: any;
}

type ConditionalRoutrProps = AuthorizationRouteProps & RouteProps & RouteComponentProps<{}>

type RouteComponent = React.StatelessComponent<RouteComponentProps<{}>> | React.ComponentClass<any>


const ConditionalRoute = (props: ConditionalRoutrProps) => {
    const [returnedRoute, setReturnedRoute] = useState(<div></div>);
    useEffect(() => {
        const { loggedIn, authKey, redirectTo, component, ...rest } = props;
        let hasRole = false;
        hasRole = [...loggedIn.authorizations[authKey]].filter(
            anAuth => loggedIn.user_profile.roles.indexOf(anAuth) >= 0
        ).length > 0;
       return setReturnedRoute(
            hasRole ? (
                <Route data-test='routeComp' {...rest} render={renderFn(component)} />
            ) : (
                    <Redirect data-test='redirectComp'  to={redirectTo} />
                )
        );
    }, [props.authKey, props.loggedIn, props.history, props.path]);
    return <>{returnedRoute}</>;
}

const renderFn = (Component?: RouteComponent) => (props: RouteComponentProps) => {
    if (!Component) {
        return null
    }
    else
        return <Component {...props} />
}

const mapStateToProps = (state: any, ownProps: RouteProps) => {
    return {
        loggedIn: state.login,
        ...ownProps
    }
}

export const ConditionalRouteComponent = connect(
    mapStateToProps,
    null
)(ConditionalRoute);

export default withRouter(ConditionalRouteComponent);