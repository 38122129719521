import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { IconButton, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import formIcon from '../../../../../assets/pvd-file-form.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CommonModal from '../../../../../shared/ui/commonModal/commonModal';
import ESignConfirm from '../../../../../shared/ui/signingCermony/enduser/eSignConfirm/ESignConfirm';
import { ItemCeremonyStatus } from '../../../../../shared/core/CeremonyStatus';
import * as CeremonyActionTypes from '../../../../../redux/constants/shared/eSignConstants';

type docuSignEmbedProps = {
    classes: any;
    ceremonyToken: any;
    onClose: any;
    item: any;
    ceremonyDetails: any;
    loggedIn: any;
    updateCeremony: any;
    clearCeremonyToken:any;
}

export const styles = (theme: any) => ({
    pagewrapper: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#505050',
        zIndex: 1300,
        display: 'flex',
        flexDirection: 'column' as 'column',
        height: '100%',
    },
    actionBar: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#333638',
        color: 'white',
        fontSize: 14,
        fontWeight: 700,
        padding: '1rem',
        position: 'fixed' as 'fixed',
        top: 0,
        width: 'calc(100% - 2rem)',
        zIndex: 99
    },
    btnIcon: {
        color: 'white'
    },
    actionTitle: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
    },
    typeIcon: {
        marginRight: '.5em'
    },
    iframeEle:
    {
        marginTop: '80px',
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        backgroundColor: '#505050',
        [theme.breakpoints.up('768')]: {
            minHeight: '100%',
        },
    },
    loaderholder: {
        backgroundColor: '#505050',
        height: '95%',
        width: "100%",
        marginTop: '80px',
        position: 'fixed' as 'fixed',
        zIndex: 999,
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
      },
      loader: {
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: "center",
        justifyContent: "center",
      },
      loadingtext: {
        margin: "1rem",
        fontSize: "1rem",
        color: '#fafafa'
      }
});

class DocuSignEmbed extends Component<docuSignEmbedProps> {
    interval: any;
    myRef: any;

    state = {
        isModalOpen: false,
        modalName: '',
        loading: true
    }
    onClickBackButton: boolean = false;

    constructor(props: any) {
        super(props);
        this.myRef = React.createRef();
    }
    i: number = 0;

    onUnload = (e: any) => {
        e.preventDefault();
        if (!this.onClickBackButton) {
            if (window.confirm("Are you sure you want to leave? All provided details may be lost.")) {
                this.onClickBackButton = true;
                window.history.back();
            } else {
                window.history.pushState(null, "", window.location.pathname);
                this.onClickBackButton = false;
            }
        }
    }

    successCall(accepted: boolean) {
        let ceremonyDet = this.props.ceremonyDetails.content.find((ceremony: any) => ceremony.userCode === this.props.loggedIn.user_profile.userCode);
        let status = accepted ? ItemCeremonyStatus.WAITING_SIGNED_DOC : ItemCeremonyStatus.DECLINED;
        this.props.updateCeremony(ceremonyDet.stcCode, status);
    }

    handleIframeTask = (e: any) => {
        if (e.origin != window.location.origin) {
            return;
        }
        if (e.data == "SUCCESS") {
            this.successCall(true);
            this.props.onClose(true, e.data);
        }
        if (e.data == "DECLINE") {
            this.successCall(false);
            this.props.onClose(true, e.data);
        }
        if (e.data == "CANCEL") {
            this.props.onClose(false, e.data);
        }
    };

    componentDidMount() {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener("popstate", this.onUnload);
        window.addEventListener("message", this.handleIframeTask);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.onUnload);
        window.removeEventListener("message", this.handleIframeTask);
        clearInterval(this.interval);
        this.props.clearCeremonyToken()
    }

    handleBack = () => {
        this.setState({ isModalOpen: true, modalName: 'confirm' });
    }

    renderConfirmationModal = () => {
        return (
            <CommonModal
                modalOpen={this.state.isModalOpen}
                modalClose={this.onConfirmation}
                content={
                    <ESignConfirm
                        title='Are you sure you want to close?'
                        subTitle="Closing this window will stop the request. You'll be required to start again to complete."
                        buttonTitle="CLOSE AND STOP"
                        onClose={this.onConfirmation}>
                    </ESignConfirm>}
            />
        )
    }

    onConfirmation = (abortSigning: boolean) => {
        if (abortSigning) {
            this.setState({ isModalOpen: false });
            this.props.onClose(false);
        } else {
            this.setState({ isModalOpen: false });
        }
    }

    docusignLoaded = () => {
        this.setState({ loading: false })
    }

    handleActionDialogClose = () => {
        this.setState({ isModalOpen: false })
    }

    render() {
        const { classes } = this.props;
        return (
            <div data-test='docusignembed-component' className={classes.pagewrapper}>
                <div className={classes.actionBar}>
                    <div className={classes.actionTitle}>
                        <IconButton data-test='files-btn' onClick={() => this.handleBack()} aria-label='go back'>
                            <ArrowBackIcon className={classes.btnIcon} />
                        </IconButton>
                        <img className={classes.typeIcon} src={formIcon} />
                        <span>{this.props.item != null ? this.props.item.itemName : ''}</span>
                    </div>
                </div>
                {this.state.isModalOpen && this.state.modalName === 'confirm' ? this.renderConfirmationModal() : null}
                {
                    this.state.loading &&
                    <div data-test='docusign-loader' className={classes.loaderholder} >
                        <Paper elevation={3} style={{backgroundColor: 'transparent', boxShadow: 'none'}} className={classes.loader}>
                            <CircularProgress color='secondary' />
                            <div className={classes.loadingtext}>Please wait while loading...</div>
                        </Paper>
                    </div>
                }
                {(this.props.ceremonyToken.error === null && this.props.ceremonyToken.requesting === false &&
                    this.props.ceremonyToken.content !== null && this.props.ceremonyToken.esignEmbadedUrl) ?
                    <div className={classes.iframeEle}>
                        <iframe onLoad={this.docusignLoaded} id="docusign_iframe" frameBorder="0" style={{ width: '100%' }} src={this.props.ceremonyToken.esignEmbadedUrl} ref={this.myRef} />
                    </div>
                    : this.props.ceremonyToken.error !== null ?
                        <div> Error in opening the file</div>
                        : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        ceremonyToken: state.eSignReducer.ceremonyToken,
        ceremonyDetails: state.eSignReducer.ceremonyDetails,
        loggedIn: state.login,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateCeremony: (ceremonyCode: string, ceremonyStatus: string) =>
            dispatch({
                type: CeremonyActionTypes.CEREMONY_API_STATUS_CHANGE,
                ceremonyCode: ceremonyCode,
                ceremonyStatus: ceremonyStatus
            }),
         clearCeremonyToken:() =>
         dispatch({
            type:CeremonyActionTypes.CEREMONY_API_INITIATION_CLEAR_TOKEN
         })   
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocuSignEmbed));