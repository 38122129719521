import React, { Component } from 'react';

class Searchbar extends Component<{}, {}> {
    render() {
        return(
            <div data-test='SearchbarComponent'>Searchbar Component Placeholder</div>
        )
    }
}

export default Searchbar;