import { takeLatest, call, put, all, select } from "redux-saga/effects";
import * as InstUserActionTypes from "../../constants/customerVault/instUserConstants";
import * as appActionTypes from "../../constants/shared/appStatesConstants";
import {
  getInstUsers,
} from "../../../shared/core/utils/apiFunctions";

export function* instUserWatcher() {
  yield takeLatest(InstUserActionTypes.ADMIN_API_GET_INSTUSERS_REQUEST, instUsersSaga);
}

function* instUsersSaga() {
  try {
    const getAppState = (state) => state.appUIStateReducer;
    const { appInfo } = yield all({
      appInfo: select(getAppState),
    });
    if (appInfo !== undefined) {
      let grpCode = appInfo.selectedGroup.grpCode;
      const users = yield call(
        getInstUsers,
        grpCode,
      );
      yield put({
        type: InstUserActionTypes.ADMIN_API_GET_INSTUSERS_SUCCESS,
        users: users,
      });
    }
  } catch (error) {
    if (error.response != null && error.response.data != null) {
      let errorResponse = error.response.data;
      if (errorResponse.status === 403 || errorResponse.status === 401)
        yield put({
          type: appActionTypes.APP_STATE_GENERIC_ERROR,
          errorResponse,
        });
      else
        yield put({ type: InstUserActionTypes.ADMIN_API_GET_INSTUSERS_FAILURE, error });
    } else {
      yield put({ type: InstUserActionTypes.ADMIN_API_GET_INSTUSERS_FAILURE, error });
    }
  }
}

export default instUserWatcher;
