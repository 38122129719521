import { takeLatest, call, put, all, select, takeEvery } from 'redux-saga/effects';
import * as AppUIStateActionTypes from '../../constants/shared/appStatesConstants';
import { getContactsInGroupAPI } from '../../../shared/core/utils/apiFunctions';

export function* appUIStatesWatcher() {
    yield takeLatest(AppUIStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_REQUEST, getGroupContactsSaga);
}

function* getGroupContactsSaga(action) {
    try {
        let selectedGroupContacts = yield call(
            getContactsInGroupAPI,
            action.groupCode
        )
        
        yield put({
            type: AppUIStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_SUCCESS,
            selectedGroupContacts: selectedGroupContacts.data.content
        })

    } catch(err) {
        yield put({
            type: AppUIStateActionTypes.GROUP_DETAIL_API_GET_CONTACTS_FAILURE,
            err
        })
    }
}

export default appUIStatesWatcher;
export { getGroupContactsSaga };