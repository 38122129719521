import React from 'react';

const SharedIcon = (props) => {
    return <svg width="24px" height="22px" >
        <style>{`.st5{stroke:${props.stroke}}`}
        </style>
        <g id="Page-10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="Share-/-share" transform="translate(0.000000, -1.000000)" className="st5">
            <g id="Group" transform="translate(1.000000, 2.000000)">
                <g id="share" strokeWidth="1.5">
                    <circle id="Oval" cx="4.25" cy="9.25" r="3.75"></circle>
                    <circle id="Oval" cx="17.75" cy="4" r="3.75"></circle>
                    <circle id="Oval" cx="17.75" cy="16" r="3.75"></circle>
                    <line x1="7.746" y1="7.891" x2="14.254" y2="5.36" id="Shape"></line>
                    <line x1="7.605" y1="10.928" x2="14.395" y2="14.323" id="Shape"></line>
                </g>
            </g>
        </g>
    </g>
    </svg>
}
export default SharedIcon