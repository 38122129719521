import React, { Component } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { DialogContent } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // minWidth: '400px'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: `${theme.spacing(2)}px`,
      margin: theme.spacing(1),
      position: 'relative',
      borderRadius: '3px',
      maxWidth: '800px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%'
      }
    },
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        color: '#444657'
    }
  }),
);

const CommonModal = (props : any) => {
    const classes = useStyles();

    return (
        <>
            <Modal data-test='commonModal'
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={ props.modalOpen }
                onClose={ props.modalClose }
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 300,
                }}>
                <Fade in={ props.modalOpen }>
                    <div className={classes.paper}>
                        <IconButton disableRipple aria-label="close-btn" className={classes.closeBtn} onClick={ props.modalClose }>
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                          { props.content }
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default CommonModal;