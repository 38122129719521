import { combineReducers } from 'redux';
import * as ContactActionTypes from '../../constants/personalVault/contactsConstants';
import * as InvitationActionTypes from '../../constants/personalVault/invitationConstants';
import * as ContactDetailActionTypes from '../../constants/personalVault/contactDetailConstants';
import * as ErrorTypes from '../../../shared/errors/ErrorMessages';

const initState = {
    contacts: {
        fetching: false,
        contactsData: {
            content: [],
            page: {},
            sort: []
        },
        updating: false,
        error: null
    },
    groups: {
        fetching: false,
        groupsData: {
            content: [],
            page: {},
            sort: []
        },
        updating: false,
        error: null
    }
}

function contacts(state = initState.contacts, action) {
    switch (action.type) {
        // Get Contacts
        case ContactActionTypes.CONTACT_API_GET_CONTACTS_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case ContactActionTypes.CONTACT_API_GET_CONTACTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                contactsData: { ...action.contacts.data }
            };
        case ContactActionTypes.CONTACT_API_GET_CONTACTS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };

        // Delete Contacts
        case ContactActionTypes.CONTACT_API_DELETE_CONTACTS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case ContactActionTypes.CONTACT_API_DELETE_CONTACTS_SUCCESS:
            const copy_contacts = { ...state }
            const deletedContacts = action.deletedContacts;
            const filtered_contacts = copy_contacts.contactsData.content.filter(contact => {
                return contact.contactCode !== deletedContacts;
            })
            return {
                ...state,
                fetching: false,
                contactsData: {
                    ...state.contactsData,
                    content: filtered_contacts
                },
            };
        case ContactActionTypes.CONTACT_API_ADD_CONTACT_REQUEST:
            return {
                ...state,
                updating: true,
                error: null
            };
        case ContactActionTypes.CONTACT_API_ADD_CONTACT_SUCCESS:
            return {
                ...state,
                updating: false,
                contactsData:
                {
                    ...state.contactsData,
                    content: [...state.contactsData.content, action.addContact.data]
                },
                error: null
            };
        case ContactActionTypes.CONTACT_API_ADD_CONTACT_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.error
            };
        case ContactActionTypes.CONTACT_API_DELETE_CONTACTS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };

        default:
            return state;
    }
}

function groups(state = initState.groups, action) {
    switch (action.type) {
        case ContactActionTypes.GROUP_API_GET_GROUPS_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case ContactActionTypes.GROUP_API_GET_GROUPS_SUCCESS:
            return {
                ...state,
                fetching: false,
                groupsData: { ...action.groups.data }
            };
        case ContactActionTypes.GROUP_API_GET_GROUPS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: {
                    ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND
                }
            };

        // Delete Groups 
        case ContactActionTypes.GROUP_API_DELETE_GROUPS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case ContactActionTypes.GROUP_API_DELETE_GROUPS_SUCCESS:
            const copy_groups = { ...state }
            const deletedGroups = action.deletedGroups;
            const filtered_groups = copy_groups.groupsData.content.filter(group => {
                return group.cgCode !== deletedGroups;
            })
            return {
                ...state,
                fetching: false,
                groupsData: {
                    ...state.groupsData,
                    content: filtered_groups
                }
            };
        case ContactActionTypes.GROUP_API_DELETE_GROUPS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: { ...action.error, action: ErrorTypes.SDV_404_NOT_FOUND }
            };
        case ContactActionTypes.GROUP_API_ADD_GROUPS_REQUEST:
            return {
                ...state,
                updating: true,
                error: null
            };
        case ContactActionTypes.GROUP_API_ADD_GROUPS_SUCCESS:
            return {
                ...state,
                updating: false,
                groupsData:
                {
                    ...state.groupsData,
                    content: [...state.groupsData.content, action.addContactGroup.data]
                },
                error: null
            };
        case ContactActionTypes.GROUP_API_ADD_GROUPS_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.error
            };
        default:
            return state;
    }
}

export default combineReducers({
    contacts,
    groups
})